import React from 'react';

interface SlackIconProps {
  width?: string
}

const SlackIcon = ({
  width = '40',
}: SlackIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={width}
    viewBox="0 0 172 172"
    style={{ fill: '#000000' }}
  >
    <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
      <path d="M0,172v-172h172v172z" fill="none" />
      <path d="M34.4,172c-18.9986,0 -34.4,-15.4014 -34.4,-34.4v-103.2c0,-18.9986 15.4014,-34.4 34.4,-34.4h103.2c18.9986,0 34.4,15.4014 34.4,34.4v103.2c0,18.9986 -15.4014,34.4 -34.4,34.4z" fill="#4a154b" />
      <g>
        <path d="M111.44167,39.775c0,-6.33247 -5.1342,-11.46667 -11.46667,-11.46667c-6.33247,0 -11.46667,5.1342 -11.46667,11.46667c0,3.5948 0,27.9242 0,31.53333c0,6.33247 5.1342,11.46667 11.46667,11.46667c6.33247,0 11.46667,-5.1342 11.46667,-11.46667c0,-3.60913 0,-27.93853 0,-31.53333z" fill="#33d375" />
        <path d="M140.10833,71.30833c0,6.33247 -5.1342,11.46667 -11.46667,11.46667c-3.42567,0 -11.46667,0 -11.46667,0c0,0 0,-8.55987 0,-11.46667c0,-6.33247 5.1342,-11.46667 11.46667,-11.46667c6.33247,0 11.46667,5.1342 11.46667,11.46667z" fill="#33d375" />
        <path d="M39.775,56.975c-6.33247,0 -11.46667,5.1342 -11.46667,11.46667c0,6.33247 5.1342,11.46667 11.46667,11.46667c3.5948,0 27.9242,0 31.53333,0c6.33247,0 11.46667,-5.1342 11.46667,-11.46667c0,-6.33247 -5.1342,-11.46667 -11.46667,-11.46667c-3.60913,0 -27.93853,0 -31.53333,0z" fill="#40c4ff" />
        <path d="M71.30833,28.30833c6.33247,0 11.46667,5.1342 11.46667,11.46667c0,3.42567 0,11.46667 0,11.46667c0,0 -8.55987,0 -11.46667,0c-6.33247,0 -11.46667,-5.1342 -11.46667,-11.46667c0,-6.33247 5.1342,-11.46667 11.46667,-11.46667z" fill="#40c4ff" />
        <path d="M56.975,128.65887c0,6.32387 5.1342,11.44947 11.46667,11.44947c6.33247,0 11.46667,-5.1256 11.46667,-11.44947c0,-3.58907 0,-27.88407 0,-31.48747c0,-6.32387 -5.1342,-11.44947 -11.46667,-11.44947c-6.33247,0 -11.46667,5.1256 -11.46667,11.44947c0,3.6034 0,27.8984 0,31.48747z" fill="#e91e63" />
        <path d="M28.30833,97.1714c0,-6.32387 5.1342,-11.44947 11.46667,-11.44947c3.42567,0 11.46667,0 11.46667,0c0,0 0,8.54553 0,11.44947c0,6.32387 -5.1342,11.44947 -11.46667,11.44947c-6.33247,0 -11.46667,-5.1256 -11.46667,-11.44947z" fill="#e91e63" />
        <path d="M128.64167,111.44167c6.33247,0 11.46667,-5.1342 11.46667,-11.46667c0,-6.33247 -5.1342,-11.46667 -11.46667,-11.46667c-3.5948,0 -27.9242,0 -31.53333,0c-6.33247,0 -11.46667,5.1342 -11.46667,11.46667c0,6.33247 5.1342,11.46667 11.46667,11.46667c3.60627,0 27.93853,0 31.53333,0z" fill="#ffc107" />
        <path d="M97.10833,140.10833c-6.33247,0 -11.46667,-5.1342 -11.46667,-11.46667c0,-3.42567 0,-11.46667 0,-11.46667c0,0 8.55987,0 11.46667,0c6.33247,0 11.46667,5.1342 11.46667,11.46667c0,6.33247 -5.1342,11.46667 -11.46667,11.46667z" fill="#ffc107" />
      </g>
      <path d="M0,172v-172h172v172z" fill="none" />
      <path d="M3.44,168.56v-165.12h165.12v165.12z" fill="none" />
    </g>

  </svg>

);

SlackIcon.defaultProps = {
  width: '40',
};

export default SlackIcon;
