import React from 'react';
import styled from 'styled-components';
import { blue6, darkBlue4 } from '../../../../../shared/colors/COLORS';
import ArrowRight2 from '../../../../../shared/icons/ArrowRight2';
import EnableCrossIcon from '../../../../../shared/icons/EnableCrossIcon';
import { SharePlatforms } from '../../../../../shared/types/types';
import { uiTextMedium } from '../../../../../shared/typography';
import {
  APPS_VIEW, NOTION_SHARE_VIEW, ShareModalMode, SHARE_VIEW, SLACK_SHARE_VIEW, TRELLO_SHARE_VIEW,
} from '../utils/ShareModalTypes';

interface Props {
  platform: SharePlatforms,
  mode: ShareModalMode | 'coming_soon'
  onClick: () => void,
}

const ActionButton = styled.button`
  background: none;
  border: none;
  color: ${blue6};
  ${uiTextMedium};
  cursor: pointer;
  text-transform: capitalize;
`;

const TextContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const ComingSoonText = styled.div`
  ${uiTextMedium}
`;

const ArrowContainer = styled.div`
  cursor: pointer;
`;

export default function PlatformAction({ platform, mode, onClick }: Props) {
  switch (mode) {
    case SHARE_VIEW:
      return (
        <ArrowContainer onClick={onClick}>
          <ArrowRight2 fill={darkBlue4} />
        </ArrowContainer>
      );
    case APPS_VIEW:
      return (
        <TextContainer onClick={onClick}>
          <EnableCrossIcon />
          <ActionButton>Enable</ActionButton>
        </TextContainer>
      );
    case NOTION_SHARE_VIEW:
    case SLACK_SHARE_VIEW:
    case TRELLO_SHARE_VIEW:
      return (
        <TextContainer onClick={onClick}>
          <ActionButton>
            Manage
            {' '}
            {platform}
          </ActionButton>
        </TextContainer>
      );
    case 'coming_soon':
      return (<ComingSoonText>Coming Soon</ComingSoonText>);
    default:
      return (<></>);
  }
}
