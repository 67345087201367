class AlgoliaAPICore {
  /**
   * I.e.
   *
   * `generateSearchFilter(['id1', 'id2'])` =>
   * `googleData.content.organizer.email:id1 OR googleData.content.organizer.email:id2`
   */
  static generateSearchFilter(calendarIds: string[]): string {
    const filterString = calendarIds.map((calendarId) => `googleData.content.organizer.email:${calendarId}`).join(' OR ');
    return filterString;
  }

  static generateSearchFilterForContentIdsCalendarId(calendarIds: string[]): string {
    const filterString = calendarIds.map((calendarId) => `googleData.ids.calendarId:${calendarId}`).join(' OR ');
    return filterString;
  }
}

export default AlgoliaAPICore;
