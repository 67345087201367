import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 0 0 60px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row nowrap;
  column-gap: 10px;
`;

export default Container;

/* for later use
export const SkipOptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const SkipOption = styled.button`
  all: unset;

  ${uiTextMedium};
  color: ${darkBlue4};

  padding: 24px 0 40px 0;

  &:hover {
    cursor: pointer;
  }
`;
*/
