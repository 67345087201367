import React from 'react';
import { COLORS } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const AddUser = ({ fill }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.87737 2C6.92783 2 4.55937 4.36811 4.55937 7.318C4.55937 10.2672 6.92815 12.636 9.87737 12.636C12.8273 12.636 15.1954 10.2675 15.1954 7.318C15.1954 4.36779 12.8276 2 9.87737 2ZM9.87737 3.5C11.9992 3.5 13.6954 5.19621 13.6954 7.318C13.6954 9.43916 11.9988 11.136 9.87737 11.136C7.75658 11.136 6.05937 9.43879 6.05937 7.318C6.05937 5.19659 7.75621 3.5 9.87737 3.5ZM9.87737 14.4563C4.84736 14.4563 2.00037 15.4108 2.00037 18.1153C2.00037 20.8228 4.85999 21.7953 9.87737 21.7953L10.5209 21.7898C15.1484 21.7089 17.7544 20.7226 17.7544 18.1363C17.7544 15.4876 15.0177 14.4994 10.2015 14.4577L9.87737 14.4563ZM9.87737 15.9563C14.0788 15.9563 16.2544 16.6961 16.2544 18.1363C16.2544 19.5675 14.0842 20.2953 9.87737 20.2953C5.6759 20.2953 3.50037 19.5555 3.50037 18.1153C3.50037 16.7222 5.55373 15.9961 9.54065 15.9579L9.87737 15.9563ZM19.2041 7.9192C19.5838 7.9192 19.8976 8.20135 19.9472 8.56743L19.9541 8.6692V9.9241H21.25C21.6642 9.9241 22 10.2599 22 10.6741C22 11.0538 21.7178 11.3676 21.3517 11.4173L21.25 11.4241H19.9541V12.6792C19.9541 13.0934 19.6183 13.4292 19.2041 13.4292C18.8244 13.4292 18.5106 13.147 18.4609 12.781L18.4541 12.6792V11.4241H17.16C16.7458 11.4241 16.41 11.0883 16.41 10.6741C16.41 10.2944 16.6921 9.98061 17.0582 9.93095L17.16 9.9241H18.4541V8.6692C18.4541 8.25499 18.7899 7.9192 19.2041 7.9192Z" fill={fill} />
  </svg>
);

AddUser.defaultProps = {
  fill: COLORS.gray1,
};

export default AddUser;
