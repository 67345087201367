import { ensureNotUndefined } from '../../../utils/array';

export const getTabButtonStartingPosition = (buttons: HTMLElement[]): number => {
  const buttonIndex = buttons
    .findIndex((button) => button.dataset.isSelected === 'true');

  // if tab not found hide the indicator
  if (buttonIndex === -1) return 0;

  const firstButtonLeftOffset = buttons[0].offsetLeft;

  const startPosition = buttons.slice(0, buttonIndex)
    .map((button) => Number(
      ensureNotUndefined(window.getComputedStyle(button).marginRight.match(/\d+/))[0],
    ) + button.offsetWidth)
    .reduce((pre, cur) => pre + cur, 0) + firstButtonLeftOffset;

  return startPosition;
};

export const getTabButtonWidth = (buttons: HTMLElement[]): number => {
  const buttonIndex = buttons
    .findIndex((button) => button.dataset.isSelected === 'true');

  // if tab not found hide the indicator
  if (buttonIndex === -1) return 0;

  return buttons[buttonIndex].offsetWidth;
};

export const makeCurrentNoteTabText = (title: string) => {
  if (title.length === 0) {
    return 'Current Note';
  }
  const maxCharacters = calculateMaxCharactersInNoteTab();

  if (title.length > maxCharacters) {
    return `${title.substring(0, maxCharacters)}...`;
  }
  return title;
};

const calculateMaxCharactersInNoteTab = () => {
  if (window.innerWidth < 470) {
    return 10;
  }
  if (window.innerWidth < 480) {
    return 11;
  }
  if (window.innerWidth < 500) {
    return 12;
  }
  if (window.innerWidth < 520) {
    return 13;
  }
  if (window.innerWidth < 540) {
    return 14;
  }
  if (window.innerWidth < 560) {
    return 15;
  }
  if (window.innerWidth < 580) {
    return 16;
  }
  if (window.innerWidth < 600) {
    return 17;
  }
  return 25;
};
