import React from 'react';
import styled from 'styled-components';
import { header200 } from '../../../../../shared/typography';
import { NOTION } from '../../../../../utils/enums';
import { ModalLineLocal } from '../utils/commonStyles';
import {
  APPS_VIEW, NotesSwitchData, NOTION_SHARE_VIEW, ShareModalMode,
} from '../utils/ShareModalTypes';
import NotesSwitch from './NotesSwitch';
import Platform from './Platform';

const SectionText = styled.h2`
  ${header200};
  margin-bottom: 16px;
`;

interface Props{
  notesSwitchData: NotesSwitchData,
  setNotesSwitchData: React.Dispatch<React.SetStateAction<NotesSwitchData>>,
  setMode: React.Dispatch<React.SetStateAction<ShareModalMode>>,
}

export default function NotionView({ notesSwitchData, setNotesSwitchData, setMode }:Props) {
  return (
    <>
      <SectionText>Notes will be saved inside</SectionText>
      <Platform platform={NOTION} mode={NOTION_SHARE_VIEW} iconSize="26" customText="Shepherd Notes" onClick={() => { setMode(APPS_VIEW); }} />
      <ModalLineLocal bottomMargin />
      <SectionText>Select to share</SectionText>
      <NotesSwitch notesSwitchData={notesSwitchData} setNotesSwitchData={setNotesSwitchData} />
    </>
  );
}
