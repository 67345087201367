// The 6-variants (i.e. darkBlue6) of the colours
// below are the ones used in our logo, which is
// also those we should try to use throughout our
// application

export const darkBlue10 = '#091639';
export const darkBlue9 = '#0B1A43';
export const darkBlue8 = '#0C1E4C';
export const darkBlue7 = '#0E2156';
export const darkBlue6 = '#0F255F';
export const darkBlue5 = '#3F517F';
export const darkBlue4 = '#6F7C9F';
export const darkBlue3 = '#8792AF';
export const darkBlue2 = '#B7BECF';
export const darkBlue1 = '#E7E9EF';

export const red10 = '#331215';
export const red9 = '#66242A';
export const red8 = '#98353E';
export const red7 = '#CB4753';
export const red6 = '#FE5968';
export const red5 = '#FE7A86';
export const red4 = '#FE9BA4';
export const red3 = '#FFACB4';
export const red2 = '#FFCDD2';
export const red1 = '#FFEEF0';

export const green10 = '#092C20';
export const green9 = '#135840';
export const green8 = '#1C8361';
export const green7 = '#26AF81';
export const green6 = '#2FDBA1';
export const green5 = '#59E2B4';
export const green4 = '#82E9C7';
export const green3 = '#97EDD0';
export const green2 = '#C1F4E3';
export const green1 = '#EAFBF6';

export const purple10 = '#1A1C3E';
export const purple9 = '#232552';
export const purple8 = '#35387B';
export const purple7 = '#464AA4';
export const purple6 = '#585DCD';
export const purple5 = '#797DD7';
export const purple4 = '#9B9EE1';
export const purple3 = '#ACAEE6';
export const purple2 = '#CDCEF0';
export const purple1 = '#EEEFFA';

export const cyan10 = '#1E434C';
export const cyan9 = '#285966';
export const cyan8 = '#3D8598';
export const cyan7 = '#51B2CB';
export const cyan6 = '#65DEFE';
export const cyan5 = '#84E5FE';
export const cyan4 = '#A3EBFE';
export const cyan3 = '#B2EFFF';
export const cyan2 = '#D1F5FF';
export const cyan1 = '#F0FCFF';

export const yellow10 = '#4D3D00';
export const yellow9 = '#665100';
export const yellow8 = '#997A00';
export const yellow7 = '#CCA200';
export const yellow6 = '#FFCB00';
export const yellow5 = '#FFD533';
export const yellow4 = '#FFE066';
export const yellow3 = '#FFE580';
export const yellow2 = '#FFEFB3';
export const yellow1 = '#FFFAE6';

export const blue10 = '#022B48';
export const blue9 = '#023960';
export const blue8 = '#03568F';
export const blue7 = '#0472BF';
export const blue6 = '#058FEF';
export const blue5 = '#37A5F2';
export const blue4 = '#69BCF5';
export const blue3 = '#82C7F7';
export const blue2 = '#B4DDFA';
export const blue1 = '#E6F4FD';

export const gray10 = '#000000';
export const gray9 = '#262626';
export const gray8 = '#595959';
export const gray7 = '#8C8C8C';
export const gray6 = '#BFBFBF';
export const gray5 = '#D9D9D9';
export const gray4 = '#E8E8E8';
export const gray3 = '#F5F5F5';
export const gray2 = '#FAFAFA';
export const gray1 = '#FFFFFF';

// Layout

export const surface = '#1B2124';
export const surfaceLight = '#2D3336';
export const layoutGrey = '#F0F2F5';
export const layoutWhite = '#F7F8F8';

// Overlays
export const overlaySurface = 'rgba(27, 33, 36, 0.4)';

// Error
export const error1 = '#FEF3F2';
export const error2 = '#FEE4E2';
export const error3 = '#FECDCA';
export const error4 = '#FDA29B';
export const error5 = '#F97066';
export const error6 = '#F04438';
export const error7 = '#D92D20';
export const error8 = '#B42318';
export const error9 = '#912018';
export const error10 = '#7A271A';

// Warning
export const warning1 = '#FFFAEB';
export const warning2 = '#FEF0C7';
export const warning3 = '#FEDF89';
export const warning4 = '#FEC84B';
export const warning5 = '#FDB022';
export const warning6 = '#F79009';
export const warning7 = '#DC6803';
export const warning8 = '#B54708';
export const warning9 = '#93370D';
export const warning10 = '#7A2E0E';

export const COLORS = {
  darkBlue10: '#091639',
  darkBlue9: '#0B1A43',
  darkBlue8: '#0C1E4C',
  darkBlue7: '#0E2156',
  darkBlue6: '#0F255F',
  darkBlue5: '#3F517F',
  darkBlue4: '#6F7C9F',
  darkBlue3: '#8792AF',
  darkBlue2: '#B7BECF',
  darkBlue1: '#E7E9EF',

  red10: '#331215',
  red9: '#66242A',
  red8: '#98353E',
  red7: '#CB4753',
  red6: '#FE5968',
  red5: '#FE7A86',
  red4: '#FE9BA4',
  red3: '#FFACB4',
  red2: '#FFCDD2',
  red1: '#FFEEF0',

  green10: '#092C20',
  green9: '#135840',
  green8: '#1C8361',
  green7: '#26AF81',
  green6: '#2FDBA1',
  green5: '#59E2B4',
  green4: '#82E9C7',
  green3: '#97EDD0',
  green2: '#C1F4E3',
  green1: '#EAFBF6',

  purple10: '#1A1C3E',
  purple9: '#232552',
  purple8: '#35387B',
  purple7: '#464AA4',
  purple6: '#585DCD',
  purple5: '#797DD7',
  purple4: '#9B9EE1',
  purple3: '#ACAEE6',
  purple2: '#CDCEF0',
  purple1: '#EEEFFA',

  cyan10: '#1E434C',
  cyan9: '#285966',
  cyan8: '#3D8598',
  cyan7: '#51B2CB',
  cyan6: '#65DEFE',
  cyan5: '#84E5FE',
  cyan4: '#A3EBFE',
  cyan3: '#B2EFFF',
  cyan2: '#D1F5FF',
  cyan1: '#F0FCFF',

  yellow10: '#4D3D00',
  yellow9: '#665100',
  yellow8: '#997A00',
  yellow7: '#CCA200',
  yellow6: '#FFCB00',
  yellow5: '#FFD533',
  yellow4: '#FFE066',
  yellow3: '#FFE580',
  yellow2: '#FFEFB3',
  yellow1: '#FFFAE6',

  blue10: '#022B48',
  blue9: '#023960',
  blue8: '#03568F',
  blue7: '#0472BF',
  blue6: '#058FEF',
  blue5: '#37A5F2',
  blue4: '#69BCF5',
  blue3: '#82C7F7',
  blue2: '#B4DDFA',
  blue1: '#E6F4FD',

  gray10: '#000000',
  gray9: '#262626',
  gray8: '#595959',
  gray7: '#8C8C8C',
  gray6: '#BFBFBF',
  gray5: '#D9D9D9',
  gray4: '#E8E8E8',
  gray3: '#F5F5F5',
  gray2: '#FAFAFA',
  /** White `#FFFFFF` */
  gray1: '#FFFFFF',
  grayText: '#9A9A9A',

  surface: '#1B2124',
  surfaceLight: '#2D3336',
  layoutGrey: '#F0F2F5',
  layoutWhite: '#F7F8F8',
  overlaySurface: 'rgba(27, 33, 36, 0.4)',
  white: '#FFFFFF',
  learnMorePurple: '#7B61FF',
  learnMoreGrey: '#636363',
  upgradeNowPurple: '#8569DE',
  darkBorder: '#8C8C8C',
  editorWorkspaceMember: '#DCFFF3',
  workspaceViewer: '#FFF9BF',
  workspacePurple: '#7175E1',

  error1: '#FEF3F2',
  error2: '#FEE4E2',
  error3: '#FECDCA',
  error4: '#FDA29B',
  error5: '#F97066',
  error6: '#F04438',
  error7: '#D92D20',
  error8: '#B42318',
  error9: '#912018',
  error10: '#7A271A',

  warning1: '#FFFAEB',
  warning2: '#FEF0C7',
  warning3: '#FEDF89',
  warning4: '#FEC84B',
  warning5: '#FDB022',
  warning6: '#F79009',
  warning7: '#DC6803',
  warning8: '#B54708',
  warning9: '#93370D',
  warning10: '#7A2E0E',
};

export type ColorType = keyof typeof COLORS;
