import { GoogleAttendee, PublicUserDataV2 } from '../types/types';

interface IPublicUsers {
  users: Map<string, PublicUserDataV2>;
  // eslint-disable-next-line no-unused-vars
  add: (users: PublicUserDataV2[]) => void;
  // eslint-disable-next-line no-unused-vars
  getGoogleAttendeesNotAlreadyInList: (googleAttendees: GoogleAttendee[]) => GoogleAttendee[];
  // eslint-disable-next-line no-unused-vars
  getUsersAlsoInGoogleAttendees: (googleAttendees: GoogleAttendee[]) => PublicUserDataV2[];
}

/**
 * PublicUserDataV2 users
 */
class PublicUsers implements IPublicUsers {
  users: Map<string, PublicUserDataV2>;

  constructor() {
    this.users = new Map();
  }

  /** Get the list of all Users `<PublicUserDataV2[]>` */
  public get value(): PublicUserDataV2[] {
    return Array.from(this.users.values());
  }

  add(users: PublicUserDataV2[]) {
    users.forEach((user) => {
      this.users.set(user.data.email, user);
    });
  }

  getGoogleAttendeesNotAlreadyInList(googleAttendees: GoogleAttendee[]) {
    const usersNotAlreadyInList = googleAttendees.filter(
      (candidate) => !this.users.has(candidate.email),
    );
    return usersNotAlreadyInList;
  }

  getUsersAlsoInGoogleAttendees(googleAttendees: GoogleAttendee[]) {
    const googleAttendeesEmails = googleAttendees.map((attendee) => attendee.email);
    return this.value
      .filter((user) => googleAttendeesEmails.includes(user.data.email));
  }

  getUsersNotAlreadyInList(emails: string[]) {
    const usersNotAlreadyInList = emails.filter(
      (candidate) => !this.users.has(candidate),
    );
    return usersNotAlreadyInList;
  }

  getUsersByEmails(emails: string[]) {
    return this.value
      .filter((user) => emails.includes(user.data.email));
  }
}

export default PublicUsers;
