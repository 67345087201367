import { CloseSidebarWindowMessage } from '../../shared/types/types';

const CLOSE_SIDEBAR_WINDOW_MESSAGE_TYPE = 'TOGGLE_SIDEBAR';

const SendCloseSidebarWindowMessage = () => {
  const message:CloseSidebarWindowMessage = {
    type: CLOSE_SIDEBAR_WINDOW_MESSAGE_TYPE,
    showing: false,
  };

  window.parent.postMessage(message, '*');
};

export default SendCloseSidebarWindowMessage;
