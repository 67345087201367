import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 35px;
  height: 17.87px;
  cursor: pointer;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(180deg, #E175FF 0%, #B6ACFF 100%);
  border-radius: 4px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: white;
  text-align: center;

  :hover {
    animation: pulse 1s infinite;
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;

interface Props {
  onClick: () => void;
}

const FreePlanTagAtom = ({ onClick }: Props) => (
  <Container onClick={onClick}>Free</Container>
);

export default FreePlanTagAtom;
