import * as Sentry from '@sentry/browser';
import { Dispatch, SetStateAction } from 'react';
import {
  DocumentData,
  DocumentSnapshot,
  Transaction, collection, doc, runTransaction,
  onSnapshot, setDoc, getDoc,
} from 'firebase/firestore';
import { MeetingAnalyticsData } from '../shared/types/types';
import INIT_MEETING_ANALYTICS_DATA from '../utils/analytics/analyticsDataObjects';
import ConsoleImproved from '../shared/classes/ConsoleImproved';
import { firestore } from '../utils/firebase';
import { COLLECTIONS } from './FirebaseConstants';

// const meetingAnalyticsCollectionRef = firestore()
//   .collection('analytics')
//   .doc('meetingsAnalytics')
//   .collection('meetingsAnalytics');
const meetingAnalyticsCollectionRef = collection(firestore, 'analytics/meetingsAnalytics/meetingsAnalytics');

// const userCollectionRef = firestore()
//   .collection(COLLECTIONS.USERS);
const usersCollectionRef = collection(firestore, COLLECTIONS.USERS);

export const getAnalyticsDataByMeetingId = async (
  meetingId: string,
): Promise<MeetingAnalyticsData> => {
  // const docRef = meetingAnalyticsCollectionRef.doc(meetingId);
  const docRef = doc(meetingAnalyticsCollectionRef, meetingId);
  const document = await getDoc(docRef);
  if (!document.exists()) {
    ConsoleImproved.log(`Didn't find analytics data for meetingId: ${meetingId}`);
    return INIT_MEETING_ANALYTICS_DATA;
  }
  const meetingAnalyticsData = mapDatabaseMeetingAnalyticsDataToMeetingAnalyticsData(
    document.data(),
  );
  return meetingAnalyticsData;
};

// export const dbListenForMeetingAnalyticsData = (
//   meetingId: string, stateSetter: Dispatch<SetStateAction<MeetingAnalyticsData>>,
// ) => meetingAnalyticsCollectionRef
//   .doc(meetingId)
export const dbListenForMeetingAnalyticsData = (
  meetingId: string, stateSetter: Dispatch<SetStateAction<MeetingAnalyticsData>>,
) => onSnapshot(doc(meetingAnalyticsCollectionRef, meetingId), async (document) => {
  if (!document.exists()) {
    console.log('Initialize new meeting analytics doc');
    // TODO: Add eventId info to analyticsData so we can easier track which meetings have
    // notes in them or not when we show them in the all notes dashboard
    // meetingAnalyticsCollectionRef.doc(meetingId).set(INIT_MEETING_ANALYTICS_DATA);
    await setDoc(doc(meetingAnalyticsCollectionRef, meetingId), INIT_MEETING_ANALYTICS_DATA);
  } else {
    stateSetter(mapDatabaseMeetingAnalyticsDataToMeetingAnalyticsData(document.data()));
    console.log(`Got new Analytics data, for ${meetingId}`, document.data());
  }
}, (error) => {
  console.error('Something went wrong while listening to analytics data');
  console.error(error);
});

const mapDatabaseMeetingAnalyticsDataToMeetingAnalyticsData = (
  databaseData: any,
): MeetingAnalyticsData => {
  const data: MeetingAnalyticsData = {
    hasUsedShepherd: databaseData?.hasUsedShepherd ?? false,
    hasAgenda: databaseData?.hasAgenda ?? false,
    hasSharedNotes: databaseData?.hasSharedNotes ?? false,
    hasPrivateNotes: databaseData?.hasPrivateNotes ?? false,
    users: databaseData?.users ?? [],
  };
  return data;
};

export const dbUpdateMeetingAnalyticsData = (
  meetingId: string,
  onSuccess: any,
  fieldTestFunction: AnalyticsDataFieldTestFunc,
  fieldUpdateFunc: AnalyticsDataFieldUpdateFunc,
) => {
  // const docRef = meetingAnalyticsCollectionRef.doc(meetingId);
  const docRef = doc(meetingAnalyticsCollectionRef, meetingId);
  return runTransaction(firestore,
    (transaction: Transaction) => transaction.get(
      docRef,
    ).then((document) => {
      if (fieldTestFunction(document)) {
        // test if field exists/is true, if not exists/false means no log,
        // therefore okay to log
        transaction.update(
          docRef,
          fieldUpdateFunc(document),
        );
        return '';
      }
      throw new Error('transaction failed, event already logged');
    })).then(() => {
    console.log('Transaction successfully committed!');
    console.log(fieldTestFunction);
    onSuccess();
  }).catch((error) => {
    if (error?.message?.includes('transaction failed, event already logged')) {
      console.log('Transaction failed, event already logged, not logging');
      return;
    }
    console.error('Transaction failed: ', error);
    console.error(fieldTestFunction);
    Sentry.captureException(error);
  });
};

const scratchpadTestFunctionHasUsedScratchpad = (document: DocumentSnapshot<DocumentData>) => {
  const data = document.data();
  return data?.meta?.analytics?.hasUsedScratchpad ?? false;
};

const scratchpadUpdateFunction = (
) => ({ 'meta.analytics.hasUsedScratchpad': true });

export const dbUpdateUserWithUsedScratchpad = (
  userId: string,
  onSuccess: () => void,
) => {
  const docRef = doc(usersCollectionRef, userId);
  return runTransaction(firestore,
    (transaction: Transaction) => transaction.get(
      docRef,
    ).then((document) => {
      if (scratchpadTestFunctionHasUsedScratchpad(document)) {
        throw new Error('transaction failed, event already logged');
      }
      // test if field exists/is true, if not exists/false means no log,
      // therefore okay to log
      ConsoleImproved.log('Inside update sc', document.data());

      transaction.update(
        docRef,
        scratchpadUpdateFunction(),
      );
      return '';
    })).then(() => {
    console.log('Transaction successfully committed for Scratchpad!');
    onSuccess();
  }).catch((error) => {
    if (error?.message?.includes('transaction failed, event already logged')) {
      console.log('Transaction failed, scratchpad event already logged, not logging');
      return;
    }
    console.error('Transaction failed, in scratchpad: ', error);
    Sentry.captureException(error);
  });
};

export interface AnalyticsDataFieldTestFunc {
  // (docRef: DocumentSnapshot<DocumentData>): boolean
  // eslint-disable-next-line no-unused-vars
  (docRef: any): boolean
}

export interface AnalyticsDataFieldUpdateFunc {
  // eslint-disable-next-line no-unused-vars
  (docRef: any): any
}
