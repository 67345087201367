import { User } from '../../shared/types/types';
import { StripeCheckoutSessionOrigin } from '../Stripe/StripeTypes';
import SlackBoltCore from './SlackBoltCore';

class SlackBoltAPI extends SlackBoltCore {
  static listConversations = async (): Promise<any> => SlackBoltCore.coreListConversations()

  static logMessage = async (message: string): Promise<any> => SlackBoltCore.coreLogMessage(message)

  static logClickedTrialExtension = async (
    user: User,
    origin: StripeCheckoutSessionOrigin,
    origin2: 'trialEndedModal' | 'cancelPage' | 'quickSettings',
  ): Promise<any> => {
    const originWithCancel = `${origin}-${origin2}`;
    SlackBoltAPI.logMessage(`User ${user.data.name} (${user.data.email}) clicked trial extension - origin: ${originWithCancel} - userId: ${user.userId}`);
  }

  static logInvite = async (
    workspaceName: string, email: string, reporterEmail: string,
  ): Promise<any> => {
    const text = `User ${reporterEmail} invited ${email} to workspace ${workspaceName}`;
    SlackBoltAPI.logMessage(text);
  };

  static logAcceptedInviteToWorkspace = async (
    userEmail: string, workspaceName: string, workspaceId: string,
  ): Promise<any> => {
    const text = `User ${userEmail} accepted invite to workspace ${workspaceName} (${workspaceId})`;
    SlackBoltAPI.logMessage(text);
  };

  static logDeclinedInviteToWorkspace = async (
    userEmail: string, workspaceName: string, workspaceId: string,
  ): Promise<any> => {
    const text = `User ${userEmail} declined invite to workspace ${workspaceName} (${workspaceId})`;
    SlackBoltAPI.logMessage(text);
  };

  static logCreatedWorkspace = async (
    workspaceName: string, reporterEmail: string,
  ): Promise<any> => {
    const text = `User ${reporterEmail} created workspace ${workspaceName}`;
    SlackBoltAPI.logMessage(text);
  };

  static logDeletedWorkspace = async (
    workspaceName: string, reporterEmail: string,
  ): Promise<any> => {
    const text = `User ${reporterEmail} deleted workspace ${workspaceName}`;
    SlackBoltAPI.logMessage(text);
  }
}

export default SlackBoltAPI;
