import React from 'react';
import styled, { css } from 'styled-components';
import { ColorDropdownColors } from './ColorDropdownUtils';
import {
  gray2, gray4, gray6, darkBlue3, surface, gray1,
} from '../../../../colors/COLORS';

const Container = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ColorCircleProps {
  color: ColorDropdownColors,
  isSelected: boolean,
}

const SelectedCss = css`
  width: 16px;
  height: 16px;
  border: none;
  /* Set box shadow outside this css block to match ColorCircleAtom color */
  outline: none;
  transition: 0.1s;
  border: 1px solid white;
`;

const outlineColorSuggester = (color: ColorDropdownColors) => {
  const lighterColors = [surface, gray1, gray2, gray4, gray6];
  return lighterColors.indexOf(color) > 0 ? darkBlue3 : color;
};

const ColorCircle = styled.div<ColorCircleProps>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${(props) => props.color};

  :hover  {
    width: 16px;
    height: 16px;
    border: none;
    outline: none;
    transition: 0.1s;
    border: 1px solid white;
    ${(props) => props.isSelected && SelectedCss}
    box-shadow: 0 0 0 1.5px ${(props) => outlineColorSuggester(props.color)};
  }

  ${(props) => props.isSelected && SelectedCss}
  ${(props) => props.isSelected && css`box-shadow: 0 0 0 1.5px ${outlineColorSuggester(props.color)}`}
`;

export interface ColorDropdownColorCircleProps {
  color: ColorDropdownColors,
  isSelected: boolean,
  // eslint-disable-next-line no-unused-vars
  onClick: (color: ColorDropdownColors) => void,
}

const ColorDropdownColorCircleAtom = ({
  color,
  isSelected,
  onClick,
}: ColorDropdownColorCircleProps) => {
  const handleClick = () => {
    onClick(color);
  };

  return (
    <Container
      onMouseDown={(e) => {
        e.preventDefault();
        handleClick();
        e.stopPropagation();
      }}
    >
      <ColorCircle color={color} isSelected={isSelected} />
    </Container>
  );
};

export default ColorDropdownColorCircleAtom;
