import React, { Dispatch, SetStateAction, KeyboardEventHandler } from 'react';
import styled from 'styled-components';
import { gray2, gray3 } from '../../../colors/COLORS';
import { header200, uiText } from '../../../typography';

const FieldHeader = styled.div`
  margin: 24px 0px 0px 24px;
  ${header200};
`;

const FieldInput = styled.input`
  background: ${gray2};
  border: none;
  border-radius: 10px;
  width: 409px;
  height: 40px;
  margin: 8px 24px 32px 24px;
  border: 2px solid ${gray3};
  box-sizing: border-box;
  padding: 0px 0px 0px 8px;
  ${uiText};

  ::placeholder{
    margin: 10px 0px 10px 8px;
    margin-left: 8px;
  }

  :focus{
    outline: none;
  }
`;

interface Props {
  title: string
  setTitle: Dispatch<SetStateAction<string>>
  handleSave: () => void
}

const NameField = ({ title, setTitle, handleSave }:Props) => {
  const handleTitleChange = (event:any) => {
    setTitle(event.target.value);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div>
      <FieldHeader>
        Template name
      </FieldHeader>
      <FieldInput
        value={title}
        placeholder="Enter template name"
        onKeyDown={handleKeyDown}
        onChange={handleTitleChange}
      />
    </div>
  );
};

export default NameField;
