import React from 'react';

const EnableCrossIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8092 6.61791C10.8092 5.96012 11.3425 5.42687 12.0003 5.42687C12.5983 5.42687 13.0933 5.86757 13.1784 6.44191L13.1913 6.61791V17.38C13.1913 18.0378 12.6581 18.5711 12.0003 18.5711C11.4023 18.5711 10.9072 18.1304 10.8221 17.556L10.8092 17.38V6.61791Z" fill="#058FEF" />
    <path d="M5.42206 11.9994C5.42212 11.4014 5.86287 10.9064 6.43722 10.8214L6.61322 10.8085L17.3855 10.8096C18.0433 10.8097 18.5764 11.343 18.5764 12.0008C18.5763 12.5988 18.1356 13.0938 17.5612 13.1788L17.3852 13.1917L6.61298 13.1906C5.95518 13.1905 5.42199 12.6572 5.42206 11.9994Z" fill="#058FEF" />
  </svg>

);

export default EnableCrossIcon;
