import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface CheckListProps {
  fill?: string;
  width?: number;
  height?: number;
}

const CheckListIcon = ({
  fill,
  width,
  height,
}: CheckListProps) => (
  <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 11.03,4.7197 c 0.293,0.2929 0.293,0.7677 0,1.0606 L 6.75,10.0607 3.97,7.2803 c -0.293,-0.2929 -0.293,-0.7677 0,-1.0606 0.293,-0.2929 0.768,-0.2929 1.06,0 L 6.75,7.9393 9.97,4.7197 c 0.293,-0.2929 0.768,-0.2929 1.06,0 z M 10.769,1.5 H 4.231 C 3.334,1.5 2.683,1.8118 2.251,2.2662 1.812,2.7277 1.5,3.4445 1.5,4.4199 v 6.1602 c 0,0.978 0.312,1.6944 0.749,2.1548 C 2.68,13.188 3.331,13.5 4.231,13.5 h 6.538 c 0.9,0 1.551,-0.312 1.982,-0.7651 0.437,-0.4604 0.749,-1.1768 0.749,-2.1548 V 4.4199 C 13.5,3.4419 13.188,2.7255 12.751,2.2651 12.32,1.812 11.669,1.5 10.769,1.5 Z M 4.231,0 h 6.538 C 13.313,0 15,1.8125 15,4.4199 v 6.1602 C 15,13.1875 13.313,15 10.769,15 H 4.231 C 1.688,15 0,13.1875 0,10.5801 V 4.4199 C 0,1.8149 1.692,0 4.231,0 Z"
      fill={fill}
    />
  </svg>
);

CheckListIcon.defaultProps = {
  fill: darkBlue4,
  width: 15,
  height: 15,
};

export default CheckListIcon;
