import React from 'react';
import styled from 'styled-components';
import GoogleMeetLogo from '../../icons/GoogleMeetLogo';
import ZoomLogo from '../../icons/ZoomIcon';
import { ConferenceData } from '../../types/types';
import ReactTooltip from '../tooltip/ReactTooltip';

const Container = styled.div`
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

interface Props {
  conferenceData: ConferenceData,
}

const ConferenceIcon = ({ conferenceData }: Props) => {
  if (conferenceData.type === 'undefined') return null;

  const Icon = conferenceData.type === 'googleMeet' ? GoogleMeetLogo : ZoomLogo;

  const onClick = () => {
    // Open video conference in a new tab
    window.open(conferenceData.link, '_blank');
  };

  return (
    <Container onClick={onClick}>
      <ReactTooltip tip="Go to the meeting" place="top">
        <Icon />
      </ReactTooltip>
    </Container>
  );
};

export default ConferenceIcon;
