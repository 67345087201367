import React from 'react';
import styled from 'styled-components';
import { darkBlue1 } from '../../colors/COLORS';

const Line = styled.div`
  height: 1px;
  background-color: ${darkBlue1};
`;

const LineAtom = () => (
  <Line />
);

export default LineAtom;
