import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface FontSizeProps {
  fill?: string;
  width?: number;
  height?: number;
}

const FontSizeIcon = ({
  fill,
  width,
  height,
}: FontSizeProps) => (
  <svg width={width} height={height} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 6,1.5 V 9.75 C 6,10.1642 5.6643,10.5 5.25,10.5 4.8358,10.5 4.5,10.1642 4.5,9.75 V 1.5 H 0.75 C 0.3358,1.5 0,1.1642 0,0.75 0,0.3358 0.3358,0 0.75,0 h 9 c 0.4143,0 0.75,0.3358 0.75,0.75 0,0.4142 -0.3357,0.75 -0.75,0.75 z M 12,6 v 3.75 c 0,0.4142 -0.3357,0.75 -0.75,0.75 -0.4142,0 -0.75,-0.3358 -0.75,-0.75 V 6 H 9 C 8.5858,6 8.25,5.6642 8.25,5.25 8.25,4.8358 8.5858,4.5 9,4.5 h 4.5 c 0.4143,0 0.75,0.3358 0.75,0.75 C 14.25,5.6642 13.9143,6 13.5,6 Z"
      fill={fill}
    />
  </svg>
);

FontSizeIcon.defaultProps = {
  fill: darkBlue4,
  width: 14,
  height: 11,
};

export default FontSizeIcon;
