import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../shared/colors/COLORS';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import { uiText } from '../../../shared/typography';

const Container = styled.div`
  padding: 5px 10px;
  border: 1px solid ${COLORS.gray6};
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  
  ${uiText}
  color: #3c4043;

  &:hover {
    background-color: #f1f3f4;
  }
`;

interface Props {
  onClick: () => void;
}

const GoToTodayButtonMolecule = ({ onClick }: Props) => (
  <ReactTooltip tip="Scroll to today" place="left" multiline>
    <Container onClick={onClick}>Today</Container>
  </ReactTooltip>
);

export default GoToTodayButtonMolecule;
