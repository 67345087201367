import { Moment } from 'moment';
import {
  AssigneeV2, AuthState, TaskStatus,
} from '../../../../types/types';
import { MeetingDataI } from '../../../../types/MeetingData';

/* eslint-disable no-unused-vars */
interface ReactMethods {
  setStatusMenuShow: (
    value: boolean,
    statusValue: string,
    statusFnValue: (ts: TaskStatus) => void,
    top: number,
    left:number,
  ) => void;

  calendarMenuShow: (
    value: boolean,
    updateDate: (d: Date | Moment | null) => void,
    due: Date | Moment | null,
    top: number,
    left:number,
  ) => void;

  assigneeMenuShow: (
    value: boolean,
    assigneeFnValue: (a: AssigneeV2 | null) => void,
    assignee: AssigneeV2 | null,
    top: number,
    left:number,
  ) => void;

  getCurrentUser: () => AuthState | null;

  getCurrentMeetingData: () => (MeetingDataI & {name: string} | null);

  getCalendarMenuShow: () => boolean;

  rotateCalendarMenuUp: () => void;

  rotateCalendarMenuDown: () => void;

  getSelectedDate: () => Date | Moment | null;

  setDate: () => void;

  setTaskModalShow: (
    value: boolean,
    taskId: string,
  ) => void;

  setCalendarMenuIndex: (index: number) => void;
}

class TaskAdapter {
  public statusMenuShow: (
    value: boolean,
    statusValue: string,
    statusFnValue: (ts: TaskStatus) => void,
    top: number,
    left:number,
  ) => void = () => null;

  public calendarMenuShow: (
    value: boolean,
    updateDate: (d: Date | Moment | null) => void,
    due: Date | Moment | null,
    top: number,
    left:number,
  ) => void = () => null;

  public getCalendarMenuShow: () => boolean = () => false;

  public assigneeMenuShow: (
    value: boolean,
    updateAssignee: (a: AssigneeV2 | null) => void,
    assignee: AssigneeV2 | null,
    top: number,
    left:number,
  ) => void = () => null;

  public getCurrentUser: () => (AuthState | null) = () => null;

  public getCurrentMeetingData: () => (MeetingDataI & {name: string} | null) = () => null;

  public rotateCalendarMenuUp: () => void = () => null;

  public rotateCalendarMenuDown: () => void = () => null;

  public getSelectedDate: () => Date | Moment | null = () => null;

  public setDate: () => void = () => null;

  public setTaskModalShow: (
    value: boolean,
    taskId: string,
  ) => void = () => null;

  public setCalendarMenuIndex: (index: number) => void = () => null;

  set reactMethods({
    setStatusMenuShow,
    calendarMenuShow,
    assigneeMenuShow,
    getCurrentUser,
    getCurrentMeetingData,
    getCalendarMenuShow,
    rotateCalendarMenuUp,
    rotateCalendarMenuDown,
    getSelectedDate,
    setDate,
    setTaskModalShow,
    setCalendarMenuIndex,
  }: ReactMethods) {
    this.statusMenuShow = setStatusMenuShow;
    this.calendarMenuShow = calendarMenuShow;
    this.assigneeMenuShow = assigneeMenuShow;
    this.getCurrentUser = getCurrentUser;
    this.getCurrentMeetingData = getCurrentMeetingData;
    this.getCalendarMenuShow = getCalendarMenuShow;
    this.rotateCalendarMenuUp = rotateCalendarMenuUp;
    this.rotateCalendarMenuDown = rotateCalendarMenuDown;
    this.getSelectedDate = getSelectedDate;
    this.setDate = setDate;
    this.setTaskModalShow = setTaskModalShow;
    this.setCalendarMenuIndex = setCalendarMenuIndex;
  }
}

export default new TaskAdapter();
