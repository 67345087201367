import { captureException } from '@sentry/react';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import PortalSessionData from './PortalSession';
import { StripeCheckoutData } from './StripeTypes';
import CheckoutSessionData from './CheckoutSession';
import { StripeConstants } from './StripeConstants';
import CloudFunctions from '../../database/CloudFunctions';

abstract class StripeCoreAPI {
  /**
   * TODO: Make this into a transaction where if we don't have a customer id, we set a flag
   * from false to true. If we are the function to successfully update the value
   * we know we can create the stripe customer
   */
  protected static async createCustomer(userId: string) {
    try {
      const response = await CloudFunctions()
        .createCustomer({ userId, isTest: StripeConstants.isTest });
      return response?.data ?? {};
    } catch (error: any) {
      console.error('Error in StripeCoreAPI.createCustomer', { error, userId });
      captureException(error, { extra: { functionName: 'StripeCoreAPI.createCustomer', userId } });
      throw error;
    }
  }

  protected static async getSubscription(subscriptionId: string) {
    try {
      if (subscriptionId.length === 0) {
        ConsoleImproved.log('Not valid subscriptionId ', subscriptionId);
        return {};
      }

      const result = await CloudFunctions()
        .getSubscription({ subscriptionId });
      console.log(result);
      return result.data;
    } catch (error: any) {
      console.error('Error in StripeCoreAPI.getSubscription', { error, subscriptionId });
      captureException(error, { extra: { functionName: 'StripeCoreAPI.getSubscription', subscriptionId } });
      throw error;
    }
  }

  protected static async cancelSubscription(subscriptionId: string) {
    try {
      if (subscriptionId.length === 0) {
        ConsoleImproved.log('Not valid subscriptionId ', subscriptionId);
        return {};
      }

      const result = await CloudFunctions().cancelSubscription({ subscriptionId });
      console.log(result);
      return result.data;
    } catch (error: any) {
      console.error('Error in StripeCoreAPI.cancelSubscription', { error, subscriptionId });
      captureException(error, { extra: { functionName: 'StripeCoreAPI.cancelSubscription', subscriptionId } });
      throw error;
    }
  }

  protected static createPortalSessionCore = async (
    customerId: string, isTest: boolean = false,
  ) => {
    try {
      if (customerId.length === 0) {
        ConsoleImproved.log('Not valid customerId ', customerId);
        return {};
      }
      const portalData = new PortalSessionData(customerId);
      portalData.validate();

      const result = await CloudFunctions()
        .createPortalSession({ ...portalData.getData(), isTest });
      console.log(result);
      return result.data as any;
    } catch (error: any) {
      console.error('Error in StripeCoreAPI.createPortalSessionCore', { error, customerId });
      captureException(error, { extra: { functionName: 'StripeCoreAPI.createPortalSessionCore', customerId } });
      throw error;
    }
  }

  protected static listSubscriptionsCore = async () => {
    try {
      const result = await CloudFunctions().listSubscriptions();
      console.log(result);
      return result.data as any;
    } catch (error: any) {
      console.error('Error in StripeCoreAPI.listSubscriptionsCore', { error });
      captureException(error, { extra: { functionName: 'StripeCoreAPI.listSubscriptionsCore' } });
      throw error;
    }
  }

  static createCheckoutSession = async (sessionData: StripeCheckoutData) => {
    try {
      const result = await CloudFunctions().createSubscribeCheckoutSession(sessionData);
      return result.data;
    } catch (error: any) {
      console.error('Error in StripeCoreAPI.createCheckoutSession', { error, sessionData });
      captureException(error, { extra: { sessionData, functionName: 'StripeCoreAPI.createCheckoutSession' } });
      throw error;
    }
  }

  protected static createCheckoutSessionForAddingPaymentMethodCore = async (customerId: string) => {
    const sessionData = {
      customerId,
      isTest: StripeConstants.isTest,
    };

    try {
      const result = await CloudFunctions()
        .createCheckoutSessionForAddingPaymentMethod(sessionData);
      return result.data;
    } catch (error: any) {
      console.error('Error in StripeCoreAPI.createSubscribeCheckoutSession', { error, sessionData });
      captureException(error, { extra: { sessionData, functionName: 'StripeCoreAPI.createCheckoutSession' } });
      throw error;
    }
  }

  protected static createCheckoutSessionForSingleChargeCore = async (
    checkoutSessionData: CheckoutSessionData,
  ) => {
    try {
      const result = await CloudFunctions()
        .createCheckoutSessionForSingleCharge(checkoutSessionData.getJSON());
      return result.data;
    } catch (error: any) {
      console.error('Error in StripeCoreAPI.createSubscribeCheckoutSession', { error, payload: checkoutSessionData.getJSON() });
      captureException(error, { extra: { payload: checkoutSessionData.getJSON(), functionName: 'StripeCoreAPI.createCheckoutSession' } });
      throw error;
    }
  }

  static createTrialExtensionCheckoutSession = async (sessionData: any) => {
    try {
      const result = await CloudFunctions().createTrialExtensionCheckoutSession(sessionData);
      return result.data;
    } catch (error: any) {
      console.error('Error in StripeCoreAPI.createTrialExtensionCheckoutSession', { error, sessionData });
      captureException(error, { extra: { sessionData, functionName: 'StripeCoreAPI.createTrialExtensionCheckoutSession' } });
      throw error;
    }
  }

  static updateSubscriptionQuantityCore = async (
    subscriptionId: string,
    subscriptionItemId: string,
    quantity: number,
  ) => {
    try {
      const result = await CloudFunctions()
        .updateQuantityOnSubscription({ subscriptionId, subscriptionItemId, quantity });
      console.log(`Result in updateSubscriptionQuantityCore: ${result}`);
      return result.data;
    } catch (error: any) {
      console.error('Error in StripeCoreAPI.updateSubscriptionQuantityCore', { error, subscriptionId, quantity });
      captureException(error, { extra: { subscriptionId, quantity, functionName: 'StripeCoreAPI.updateSubscriptionQuantityCore' } });
      return {};
    }
  }
}

export default StripeCoreAPI;
