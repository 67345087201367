import React, { useContext } from 'react';
import { MeetingData } from '../../../../shared/types/MeetingData';
import { getHourAndMinutes } from '../../../../utils/dateUtils/date';
import { UserDataContext } from '../../../../App';
import SpotSeparator from '../../../../shared/icons/SpotSeparator';
import { MeetingUtils } from '../../../../utils/meetings/meetingsUtils';

interface Props {
  meetingData: MeetingData,
}

const StartAndEndTime = ({ meetingData }: Props) => {
  const { settings } = useContext(UserDataContext);
  if (MeetingUtils.isFullDayMeeting(meetingData)) return null;

  const startHourAndMinutes = getHourAndMinutes(meetingData.date.start.date, settings.clockFormat);
  const endHourAndMinutes = getHourAndMinutes(meetingData.date.end.date, settings.clockFormat);
  return (
    <>
      <SpotSeparator />
      {startHourAndMinutes}
      {' '}
      -
      {' '}
      {endHourAndMinutes}
    </>
  );
};

export default StartAndEndTime;
