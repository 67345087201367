import React from 'react';
import ButtonOutline from '../../../shared/components/buttons/ButtonOutline/ButtonOutline';
import Font from '../../../shared/typography/Font';

interface Props {
  onClick: () => void;
  variant?: 'default' | 'trial';
}

const LearnMoreButtonAtom = ({ onClick, variant = 'default' }: Props) => (
  <ButtonOutline onClick={onClick} padding={variant === 'default' ? '0px 8px' : '2px 8px'} id="LearnMore">
    <Font font={variant === 'default' ? 'uiTextS' : 'small'} color="gray7">
      Learn more
    </Font>
  </ButtonOutline>
);

export default LearnMoreButtonAtom;
