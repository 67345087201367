import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useIntercom } from 'react-use-intercom';
import { UserDataContext } from '../../../../../App';
import UserAPI from '../../../../../database/User/UserAPI';
import ROUTES from '../../../../../routes/ROUTES_CONSTANTS';
import BillingOnboardingStepTemplate from './BillingOnboardingStepTemplate';

const BillingOnboardingStepCtrl = () => {
  const userData = useContext(UserDataContext);
  const navigate = useNavigate();
  const { trackEvent } = useIntercom();

  const handleClickTrial = async () => {
    console.log('Clicked Trial');
    await UserAPI.Billing.startTrial(userData, 'onboarding', trackEvent);
    setTimeout(() => {
      navigate(ROUTES.onboardingFirstMeetingPath);
    }, 200);
  };

  return (
    <BillingOnboardingStepTemplate
      onClickTrial={handleClickTrial}
    />
  );
};

export default BillingOnboardingStepCtrl;
