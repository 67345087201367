/* eslint-disable no-unused-vars */
import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { euFunctions } from '../utils/firebase';

interface ReturnT {
  // Key is a string, value is a function
  [key: string]: (data?: any) => Promise<HttpsCallableResult<unknown>>
}

/**
 * List of all Cloud Functions we can call
 */
const CloudFunctions = () => ({
  sendWelcomeEmailV2: (data: any) => httpsCallable(euFunctions, 'sendWelcomeEmailV2')(data),

  sendBasicEmail2V2: httpsCallable(euFunctions, 'sendBasicEmail2V2'),

  sendWorkspaceInviteEmailFunctionV3: httpsCallable(euFunctions, 'sendWorkspaceInviteEmailV3'),

  sendNewUserInviteAndWorkspaceInviteV3: httpsCallable(euFunctions, 'sendNewUserInviteAndWorkspaceInviteV3'),

  sendInviteMembersEmailsV2: httpsCallable(euFunctions, 'sendInviteMembersEmailsV3'),

  sendMeetingNotes: httpsCallable(euFunctions, 'sendMeetingNotesV2'),

  sendQuickFeedback: httpsCallable(euFunctions, 'sendQuickFeedbackV2'),

  searchMeetingsByGoogleMeetId: httpsCallable(euFunctions, 'searchMeetingsByGoogleMeetIdV2'),

  searchMeetingsByEventAndCalendarId: httpsCallable(euFunctions, 'searchMeetingsByEventAndCalendarIdV2'),

  getMeetingsByDataEventIds: httpsCallable(euFunctions, 'getMeetingsByDataEventIds'),

  getMeetingsByDataEventIdsV3: httpsCallable(euFunctions, 'getMeetingsByDataEventIdsV3'),

  searchUserSettingByEmail: httpsCallable(euFunctions, 'searchUserSettingByEmailV2'),

  searchUserDataByEmail: httpsCallable(euFunctions, 'searchUserDataByEmailV2'),

  getNotionAccessToken: httpsCallable(euFunctions, 'getNotionAccessTokenV2'),

  saveNotionDataToUser: httpsCallable(euFunctions, 'saveNotionDataToUserV2'),

  // getNotionWorkspacePagesV2: httpsCallable(euFunctions, 'getNotionWorkspacePagesV2'),

  getNotionWorkspacePagesV4: httpsCallable(euFunctions, 'getNotionWorkspacePagesV4'),

  getNotionSubPages: httpsCallable(euFunctions, 'getNotionSubPagesV2'),

  createNotionPage: httpsCallable(euFunctions, 'createNotionPageV2'),

  getTrelloWorkspaces: httpsCallable(euFunctions, 'getTrelloWorkspacesV2'),

  getTrelloBoards: httpsCallable(euFunctions, 'getTrelloBoardsV2'),

  getTrelloBoardMembers: httpsCallable(euFunctions, 'getTrelloBoardMembersV2'),

  getTrelloBoardList: httpsCallable(euFunctions, 'getTrelloBoardListV2'),

  sendTaskToTrelloBoard: httpsCallable(euFunctions, 'sendTaskToTrelloBoardV2'),

  searchPreviousMeetingsByRecurringEventId: httpsCallable(euFunctions, 'searchPreviousMeetingsByRecurringEventIdV2'),

  searchAssigneeDataByEmails: httpsCallable(euFunctions, 'searchAssigneeDataByEmailsV2'),

  updateTaskInTrelloBoard: httpsCallable(euFunctions, 'updateTaskInTrelloBoardV2'),

  subscribeTrelloWebhook: httpsCallable(euFunctions, 'subscribeTrelloWebhookV2'),

  slackGetDirectMessagesV4: httpsCallable(euFunctions, 'slackGetDirectMessagesV4'),

  sendSlackNotificationMeetingStartsSoon: httpsCallable(euFunctions, 'sendSlackNotificationMeetingStartsSoonV2'),

  sendSlackNotificationMentionedInMeeting: httpsCallable(euFunctions, 'sendSlackNotificationMentionedInMeetingV2'),

  sendMessageToSlackV3: httpsCallable(euFunctions, 'sendMessageToSlackV3'),

  deleteTrelloCard: httpsCallable(euFunctions, 'deleteTrelloCardV2'),

  trelloWebhookListener: httpsCallable(euFunctions, 'trelloWebhookListenerV2'),

  getAllTypesSlackChannels: httpsCallable(euFunctions, 'getAllTypesSlackChannelsV2'),

  getAllSlackChannelsV6: httpsCallable(euFunctions, 'getAllSlackChannelsV6'),

  searchPublicUserDataV2ByEmail: httpsCallable(euFunctions, 'searchPublicUserDataV2ByEmailV2'),

  searchPublicUserDataV2ByEmailsV2: httpsCallable(euFunctions, 'searchPublicUserDataV2ByEmailsV2'),

  generateNotesPdf: httpsCallable(euFunctions, 'generateNotesPdfV2'),

  sendSlackNotification: httpsCallable(euFunctions, 'sendSlackNotification'),

  // This will send to "Shepherd" app
  sendSlackNotificationV2: httpsCallable(euFunctions, 'sendSlackNotificationV3'),

  getSlackData: httpsCallable(euFunctions, 'getSlackDataV2'),

  getInviteData: httpsCallable(euFunctions, 'getInviteDataV2'),

  createOrUpdateInviteDataV2: httpsCallable(euFunctions, 'createOrUpdateInviteDataV3'),

  addInviteDataToUserData: httpsCallable(euFunctions, 'addInviteDataToUserDataV2'),

  updateInviteDataSignedUpStatusToTrue: httpsCallable(euFunctions, 'updateInviteDataSignedUpStatusToTrueV2'),

  updateInviteeSignedUpStatusInUserDataToTrue: httpsCallable(euFunctions, 'updateInviteeSignedUpStatusInUserDataToTrueV2'),

  gapiListUpcomingEvents: httpsCallable(euFunctions, 'gapiListUpcomingEventsV2'),

  gapiFindEvents: httpsCallable(euFunctions, 'gapiFindEventsV2'),

  gapiGetMeeting: httpsCallable(euFunctions, 'gapiGetMeetingV2'),

  gapiGetMeetingsInCalanderInTimeFrame: httpsCallable(euFunctions, 'gapiGetMeetingsInCalanderInTimeFrameV2'),

  gapiGetMyCalendarIds: httpsCallable(euFunctions, 'gapiGetMyCalendarIdsV2'),

  gapiInsertMeeting: httpsCallable(euFunctions, 'gapiInsertMeetingV2'),

  gapiUpdateMeetingDescription: httpsCallable(euFunctions, 'gapiUpdateMeetingDescriptionV2'),

  saveNotificationDetails: httpsCallable(euFunctions, 'saveNotificationDetailsV2'),

  getGoogleTokens: httpsCallable(euFunctions, 'getGoogleTokensV2'),

  getGoogleTokens2: httpsCallable(euFunctions, 'getGoogleTokens2V2'),

  initiateStage2v2: httpsCallable(euFunctions, 'initiateStage2V3'),

  stopGoogleCalendarWebhook: httpsCallable(euFunctions, 'stopGoogleCalendarWebhookV2'),

  checkNotificationPermission: httpsCallable(euFunctions, 'checkNotificationPermissionV2'),

  sendDataToMixPanel: httpsCallable(euFunctions, 'sendDataToMixPanelV2'),

  /**
   * Is not deployed. It must have been deleted or been under another name
   */
  sendTaskChangeNotification: httpsCallable(euFunctions, 'sendTaskChangeNotification'),

  setupPeopleInMixPanelV2: httpsCallable(euFunctions, 'setupPeopleInMixPanelV2'),

  setupPeoplePropertiesInMixPanelv2: httpsCallable(euFunctions, 'setupPeoplePropertiesInMixPanelV3'),

  updateMeetingsFromGAPItoDB: httpsCallable(euFunctions, 'updateMeetingsFromGAPItoDBV2'),

  filterMeetingHavingNotesV2: httpsCallable(euFunctions, 'filterMeetingHavingNotesV2'),

  algoliaSearch: httpsCallable(euFunctions, 'algoliaSearchV2'),

  createNewTag: httpsCallable(euFunctions, 'createNewTagV2'),

  addTagToMeeting: httpsCallable(euFunctions, 'addTagToMeetingV2'),

  createCustomer: httpsCallable(euFunctions, 'createCustomerV2'),

  createSubscribeCheckoutSession: httpsCallable(euFunctions, 'createSubscribeCheckoutSessionV2'),

  createCheckoutSessionForAddingPaymentMethod: httpsCallable(euFunctions, 'createCheckoutSessionForAddingPaymentMethodV2'),

  createCheckoutSessionForSingleCharge: httpsCallable(euFunctions, 'createCheckoutSessionForSingleChargeV2'),

  testCreateSubscription: httpsCallable(euFunctions, 'testCreateSubscriptionV2'),

  createTrialExtensionCheckoutSession: httpsCallable(euFunctions, 'createTrialExtensionCheckoutSessionV2'),

  getSubscription: httpsCallable(euFunctions, 'getSubscriptionV2'),

  updateQuantityOnSubscription: httpsCallable(euFunctions, 'updateQuantityOnSubscriptionV2'),

  createPortalSession: httpsCallable(euFunctions, 'createPortalSessionV2'),

  cancelSubscription: httpsCallable(euFunctions, 'cancelSubscriptionV2'),

  listSubscriptions: httpsCallable(euFunctions, 'listSubscriptionsV2'),

  intercomUpdateSubscription: httpsCallable(euFunctions, 'intercomUpdateSubscriptionV2'),

  slackBoltListConversations: httpsCallable(euFunctions, 'slackBoltListConversationsV2'),

  slackBoltLogMessage: httpsCallable(euFunctions, 'slackBoltLogMessageV2'),

  getInvites: httpsCallable(euFunctions, 'getInvitesV2'),

  acceptWorkspaceInvite: httpsCallable(euFunctions, 'acceptWorkspaceInviteV2'),

  considerSeatsForWorkspaceSubscription: httpsCallable(euFunctions, 'considerSeatsForWorkspaceSubscriptionV2'),

  declineWorkspaceInvite: httpsCallable(euFunctions, 'declineWorkspaceInviteV2'),

  removeYourselfFromWorkspace: httpsCallable(euFunctions, 'removeYourselfFromWorkspaceV2'),

  addTwoNumbers: httpsCallable(euFunctions, 'addTwoNumbers'),
});

export default CloudFunctions;
