import styled from 'styled-components';
import { TabList } from 'react-tabs';

interface styleProps {
  padding?: string
}

export const TabButtonsContainerStyles = styled.div<styleProps>`
  .react-tabs__tab-list {
    margin: 0;
    padding: ${({ padding }) => (padding)};
    display: flex;
  }
`;

TabButtonsContainerStyles.defaultProps = {
  padding: '0',
};

const TabButtonsContainer = TabList;

export default TabButtonsContainer;
