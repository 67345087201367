import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../../../pages/onboarding/welcome/utils/animations';
import { SIDEBAR_WIDTH } from '../../../../utils/globalStyle';
import { darkBlue4, gray1 } from '../../../colors/COLORS';
import ShepherdLogo from '../../../icons/shepherd-logo';
import { uiTextMedium } from '../../../typography';

interface Props {
  text: string
}

/**
 * Page loader with Shepherd logo showed while the meetingData is fetched
*/
const MeetingLoadingPage = ({ text }: Props) => (
  <Container>
    <FadeInContainer>
      <ShepherdLogo width={267} spin fadeIn={false} />
      <Text>{text}</Text>
    </FadeInContainer>
  </Container>
);

export default MeetingLoadingPage;

const Container = styled.div`
  background: ${gray1};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1280px){
    width: 100%;
  }
  @media only screen and (max-width: 1280px){
    width: ${SIDEBAR_WIDTH};
  }
  height: 86vh;
`;

const FadeInContainer = styled.div`
  /* Fade in with delay, if not the screen will 'flicker' when the user loads a meeting since multiple loading
  animations will play quickly after each other */
  opacity: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} ease-in-out forwards 3s;
  animation-delay: 2s;
`;

const Text = styled.p`
  ${uiTextMedium};
  color: ${darkBlue4};
  margin-top: 16px;
`;
