import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import OverlayScrollbars from 'overlayscrollbars';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import initializeMixpanel from './utils/mixpanel';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import './index.css';
import packageJson from '../package.json';
import { StripeConstants } from './external/Stripe/StripeConstants';
import { getEnvironment } from './utils/analytics/sentry';

Sentry.init({
  dsn: 'https://2fb0780bc139436bbcbc93d2b7e2c696@o927752.ingest.sentry.io/5877271',
  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
  release: `website@${packageJson.version}`,
  environment: getEnvironment(),

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  normalizeDepth: 7,
});

initializeMixpanel();

const stripeKey = StripeConstants.isTest
  ? StripeConstants.TEST.TEST_PUBLIC_KEY : StripeConstants.PUCLIC_KEY;
const stripePromise = loadStripe(stripeKey);

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>,
  document.getElementById('root'),
);

OverlayScrollbars(document.body, {
  nativeScrollbarsOverlaid: {
    initialize: false,
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
