import { DocumentData, DocumentSnapshot } from 'firebase/firestore';
import { cfSearchPublicUserDataV2ByEmailsV2 } from '../../external/publicUserData/PublicUserDataAPI';
import {
  DatabaseTaskItem, PublicUserDataV2, TaskItem, TaskItemVersion,
} from '../../shared/types/types';
import { dateISOObject } from '../../utils/dateUtils/date';
import { removeDuplicates } from '../../utils/strings';
import { emptyPublicUserDataV2 } from '../../utils/user/publivUserDataV2/PublicUserDataV2Utils';

/**
 * Will return a TaskItem without assignee and reporter in the PublicUserDataV2 format
 */
export const mapDatabaseTaskItemToTaskItem = (
  taskId: string, databaseData: any,
): TaskItem => {
  const newTask: TaskItem = {
    assignee: databaseData?.assignee ?? emptyPublicUserDataV2,
    reporter: databaseData?.assignee ?? emptyPublicUserDataV2,
    taskId,
    version: databaseData.version ?? 1,
    date: {
      created: {
        date: databaseData.date?.created?.date ?? '',
        timestamp: databaseData.date?.created?.timestamp ?? 0,
      },
      updated: {
        date: databaseData.date?.updated?.date ?? '',
        timestamp: databaseData.date?.updated?.timestamp ?? 0,
      },
      dueDate: {
        type: databaseData.date?.dueDate?.type ?? 'date',
        date: {
          date: databaseData.date?.dueDate?.date?.date ?? '',
          timestamp: databaseData.date?.dueDate?.date?.timestamp ?? 0,
        },
        meeting: {
          meetingId: databaseData.date?.dueDate?.meeting?.meetingId ?? '',
          startDate: {
            date: databaseData.date?.dueDate?.meeting?.startDate?.date ?? '',
            timestamp: databaseData.date?.dueDate?.meeting?.startDate?.timestamp ?? 0,
          },
          name: databaseData.date?.dueDate?.meeting?.name ?? '',
        },
      },
    },
    data: {
      status: databaseData.data?.status ?? 'todo',
      completed: databaseData.data?.completed ?? false,
      assignee: databaseData.data?.assignee ?? {
        userId: '',
        name: '',
        email: '',
        photoUrl: '',
      },
      reporter: databaseData.data?.reporter ?? {
        userId: '',
        name: '',
        email: '',
        photoUrl: '',
      },
      title: databaseData.data?.title ?? '',
      description: databaseData.data?.description ?? '',
      isPrivate: databaseData.data?.isPrivate ?? false,
      isViewed: databaseData.data?.isViewed ?? false,
    },
    integrations: {
      trello: {
        trelloTaskId: databaseData?.integrations?.trello?.trelloTaskId ?? '',
        isTrelloSyncEnabled: databaseData?.integrations?.trello?.isTrelloSyncEnabled ?? false,
      },
      slack: {
        isOverdueNotificationSent: databaseData
          ?.integrations?.slack?.userNotifiedOverdue ?? true,
      },
    },
    meeting: {
      meetingId: databaseData.meeting?.meetingId ?? '',
      startDate: {
        date: databaseData.meeting?.startDate?.date ?? '',
        timestamp: databaseData.meeting?.startDate?.timestamp ?? 0,
      },
      tags: databaseData.meeting?.tags ?? [],
      name: databaseData.meeting?.name ?? '',
    },
    order: {
      privateIndex: databaseData.order?.privateIndex ?? NaN,
      privatePrevTaskId: databaseData.order?.privatePrevTaskId ?? '',
      privateNextTaskId: databaseData.order?.privateNextTaskId ?? '',
      meetingIndex: databaseData.order?.meetingIndex ?? NaN,
      meetingPrevTaskId: databaseData.order?.meetingPrevTaskId ?? '',
      meetingNextTaskId: databaseData.order?.meetingNextTaskId ?? '',
    },
    permissions: databaseData.permissions ?? 'editor',
  };
  return newTask;
};

/**
 * Step 1. Map the snapshot to a list of task items without the AssigneeV2 and ReporterV2
 *
 * Step 2. Map the tasks without the AssigneeV2 and ReporterV2 to a list of task items
 * with the AssigneeV2 and ReporterV2
 */
export const mapDocumentsToTaskItems = async (
  docs: DocumentSnapshot<DocumentData>[],
): Promise<TaskItem[]> => {
  const tasksWithoutPublicUserDataV2 = docs.map(
    (taskSnapshot) => mapDatabaseTaskItemToTaskItem(taskSnapshot.id, taskSnapshot.data()),
  );

  return mapTasksWithoutPublicUserDataV2ToTasksWithPublicUserDataV2(tasksWithoutPublicUserDataV2);
};

/**
 * Step 1. Get assignee and reporter emails
 *
 * Step 2. Get PublicUserDataV2[] from emails
 *
 * Step 3. Map tasks using AssigneeV2 and ReporterV2
 */
export const mapTasksWithoutPublicUserDataV2ToTasksWithPublicUserDataV2 = async (
  tasksWithoutAssigneeV2AndReporterV2: TaskItem[],
): Promise<TaskItem[]> => {
  const assigneeAndReporterEmails = getAssigneeAndReporterEmailsFromTasks(
    tasksWithoutAssigneeV2AndReporterV2,
  );
  const assigneeAndReporterPublicUserDataV2 = await cfSearchPublicUserDataV2ByEmailsV2(
    assigneeAndReporterEmails,
  );
  const taskItems = mapTasksToTaskItemWithPublicUserDataV2Array(
    tasksWithoutAssigneeV2AndReporterV2, assigneeAndReporterPublicUserDataV2,
  );
  return taskItems;
};

export const defaultDatabaseTaskItem = (version: TaskItemVersion) => {
  const newTask: DatabaseTaskItem = {
    assignee: {
      userId: '',
      name: '',
      email: '',
      photoUrl: '',
    },
    reporter: {
      userId: '',
      name: '',
      email: '',
      photoUrl: '',
    },
    version,
    date: {
      created: dateISOObject(),
      updated: dateISOObject(),
      dueDate: {
        type: 'noDueDate',
        date: {
          date: '',
          timestamp: 0,
        },
        meeting: {
          meetingId: '',
          startDate: {
            date: '',
            timestamp: 0,
          },
          name: '',
        },
      },
    },
    data: {
      status: 'todo',
      completed: false,
      assignee: {
        userId: '',
        name: '',
        email: '',
        photoUrl: '',
      },
      reporter: {
        userId: '',
        name: '',
        email: '',
        photoUrl: '',
      },
      title: '',
      description: '',
      isPrivate: false,
      isViewed: false,
    },
    integrations: {
      trello: {
        trelloTaskId: '',
        isTrelloSyncEnabled: false,
      },
      slack: {
        isOverdueNotificationSent: false,
      },
    },
    meeting: {
      meetingId: '',
      startDate: {
        date: '',
        timestamp: 0,
      },
      tags: [],
      name: '',
    },
    order: {
      privateIndex: NaN,
      privatePrevTaskId: '',
      privateNextTaskId: '',
      meetingIndex: NaN,
      meetingPrevTaskId: '',
      meetingNextTaskId: '',
    },
    permissions: 'editor',
  };
  return newTask;
};

export const emptyTaskItem: TaskItem = {
  assignee: emptyPublicUserDataV2,
  reporter: emptyPublicUserDataV2,
  taskId: '',
  version: 2,
  date: {
    created: dateISOObject(),
    updated: dateISOObject(),
    dueDate: {
      type: 'noDueDate',
      date: {
        date: '',
        timestamp: 0,
      },
      meeting: {
        meetingId: '',
        startDate: {
          date: '',
          timestamp: 0,
        },
        name: '',
      },
    },
  },
  data: {
    status: 'todo',
    completed: false,
    assignee: {
      userId: '',
      name: '',
      email: '',
      photoUrl: '',
    },
    reporter: {
      userId: '',
      name: '',
      email: '',
      photoUrl: '',
    },
    title: '',
    description: '',
    isPrivate: false,
    isViewed: false,
  },
  integrations: {
    trello: {
      trelloTaskId: '',
      isTrelloSyncEnabled: false,
    },
    slack: {
      isOverdueNotificationSent: false,
    },
  },
  meeting: {
    meetingId: '',
    startDate: {
      date: '',
      timestamp: 0,
    },
    tags: [],
    name: '',
  },
  order: {
    privateIndex: NaN,
    privatePrevTaskId: '',
    privateNextTaskId: '',
    meetingIndex: NaN,
    meetingPrevTaskId: '',
    meetingNextTaskId: '',
  },
  permissions: 'editor',
};

function getAssigneeAndReporterEmailsFromTasks(tasks: TaskItem[]) {
  const assigneeEmails = tasks
    .filter((task: TaskItem) => task.data.assignee.email.length !== 0)
    .map((task: TaskItem) => task.data.assignee.email);
  const reporterEmails = tasks
    .filter((task: TaskItem) => task.data.reporter.email.length !== 0)
    .map((task: TaskItem) => task.data.reporter.email);
  return removeDuplicates(assigneeEmails.concat(reporterEmails));
}

/**
 * Using the list of PublicUserDataV2, map the tasks and for each task will find
 * the associated assignee and reporter, and add them to the task, and return the list of tasks.
 */
const mapTasksToTaskItemWithPublicUserDataV2Array = (
  tasks: TaskItem[],
  users: PublicUserDataV2[],
) => tasks.map(
  (task) => mapTaskToTaskItemWithPublicUserDataV2Array(task, users),
);

const mapTaskToTaskItemWithPublicUserDataV2Array = (
  task: TaskItem, users: PublicUserDataV2[],
) => {
  const assigneeUser = users.find((user) => user.data.email === task.data.assignee.email)
    ?? emptyPublicUserDataV2;
  const reporterUser = users.find((user) => user.data.email === task.data.reporter.email)
    ?? emptyPublicUserDataV2;
  return {
    ...task,
    assignee: assigneeUser,
    reporter: reporterUser,
  } as TaskItem;
};
