/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import Font from '../../../shared/typography/Font';
import ButtonSmall from '../../../shared/components/button-small';
import { COLORS } from '../../../shared/colors/COLORS';
import womanLeaningOnTable from '../../../shared/img/woman-leaning-on-table.png';
import peopleAroundTable from '../../../shared/img/people-around-table.png';
import { TeamOrPrivate } from '../../../shared/types/types';
import Logger from '../../../utils/analytics/Logger/Logger';
import { UserDataContext } from '../../../App';

const Container = styled.div`
  padding: 64px 15px;
  img {
    border-radius: 8px;
  }

  user-select: none;
`;

const HowAreYouPlanningOnUsingShepherdContainer = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  & > *:first-child {
    margin-bottom: 16px;
  }
`;

interface CardProps {
  selected: SelectedCard,
  current: SelectedCard,
}

const OptionCardContainer = styled.div<CardProps>`
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;

  border: 3px solid ${COLORS.gray5};
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.gray3};
    border: 3px solid ${COLORS.gray6};
  }

  /* If selected */
  ${(props) => (props.selected === props.current && css`
    background-color: ${COLORS.gray3};
    border: 3px solid ${COLORS.blue6};
    &:hover {
      background-color: ${COLORS.gray3};
      border: 3px solid ${COLORS.blue6};
    }
  `)}
`;

const OptionCardLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  img {
    height: 155px;
  }
`;

const OptionCardRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > *:first-child {
    margin-bottom: 16px;
  }
`;

const ContinueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 120px; */

  button {
    width: 120px;
  }
`;

type SelectedCard = TeamOrPrivate | null;

interface WorkspaceOnboardingTemplateProps {
  onClickContinue: (selected: TeamOrPrivate) => void;
}

const WorkspaceOnboardingTemplate = ({ onClickContinue }: WorkspaceOnboardingTemplateProps) => {
  const userData = useContext(UserDataContext);
  const [selected, setSelected] = React.useState<SelectedCard>(null);

  const handleSelect = (newSelected: SelectedCard) => () => {
    if (selected === newSelected) {
      setSelected(null);
      return;
    }
    setSelected(newSelected);
  };

  const handleClickContinue = () => {
    if (selected === null) {
      return;
    }
    Logger.Workspaces.logClickTeamOrPersonalWorkspaceInOnboarding(userData.userId, selected);
    onClickContinue(selected);
  };

  return (
    <Container>
      <HowAreYouPlanningOnUsingShepherdContainer>
        <Font font="header900">How are you planning on using Shepherd?</Font>
        <Font font="uiTextM" color="gray7">Well design your setup for you accordingly</Font>
      </HowAreYouPlanningOnUsingShepherdContainer>
      <OptionCardContainer current="team" selected={selected} onClick={handleSelect('team')}>
        <OptionCardLeftContainer>
          <img src={peopleAroundTable} alt="People standing around table" />
        </OptionCardLeftContainer>
        <OptionCardRightContainer>
          <Font font="header500">As a team</Font>
          <Font font="messageText" color="gray7">Take notes with your teammates</Font>
        </OptionCardRightContainer>
      </OptionCardContainer>
      <OptionCardContainer current="personal" selected={selected} onClick={handleSelect('personal')}>
        <OptionCardLeftContainer>
          <img src={womanLeaningOnTable} alt="Woman leaning on table" />
        </OptionCardLeftContainer>
        <OptionCardRightContainer>
          <Font font="header500">For personal notes</Font>
          <Font font="messageText" color="gray7">Take quick notes on your own</Font>
        </OptionCardRightContainer>
      </OptionCardContainer>
      <ContinueContainer>
        <ButtonSmall onClick={handleClickContinue} isDisabled={!selected} text="Continue" />
      </ContinueContainer>
    </Container>
  );
};

export default WorkspaceOnboardingTemplate;
