import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { darkBlue1, gray9 } from '../../../../shared/colors/COLORS';
import { header700, uiText } from '../../../../shared/typography';
import ImageSource from '../../../../shared/img/laying_around.png';

const NoTemplatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 488px;
  padding: 30px 0px;
  border: 2px dashed ${darkBlue1};
  border-radius: 24px;
  cursor: pointer;
`;

const NoTemplatesHeader = styled.h1`
  ${header700};
  margin-bottom: 0px;
`;

const CreateTemplatesText = styled.h3`
  ${uiText};
  margin-top: 12px;
  max-width: 370px;
  text-align: center;
  color: ${gray9};
`;

interface Props {
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>
}

const EmptyTemplateView = ({ setIsTemplatesOpen }: Props) => {
  const closeTemplatesView = () => {
    setIsTemplatesOpen(false);
  };

  return (
    <NoTemplatesContainer onClick={closeTemplatesView}>
      <img src={ImageSource} alt="No User Templates" />
      <NoTemplatesHeader>No templates saved here yet!</NoTemplatesHeader>
      <CreateTemplatesText>
        Create a new template in the text editor and save it to use again later.
      </CreateTemplatesText>
    </NoTemplatesContainer>
  );
};

export default EmptyTemplateView;
