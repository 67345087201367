import React, { useEffect } from 'react';
import styled from 'styled-components';
import PageContainer from '../all-pages/PageContainer';
import { HorizontalLine } from '../meeting/HorizontalLine';
import ScratchpadExplanation from './ScratchpadExplanation';
import ProseMirrorComponentV2 from '../../../src/shared/components/prosemirror/index';
import ScratchpadTitle from './ScratchpadTitle';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { CSS_CONSTANTS } from '../../shared/CSS/CSS_Constants';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > * {
    width: 100%;
  }
`;

const ProseMirrorContainer = styled.div`
  width: 100%;
`;

const TopInfoPadding = styled.div`
  width: 100%;
  max-width: ${CSS_CONSTANTS.MAX_WIDTH_CONTENT};
  padding: 20px 20px 16px 20px;
`;

interface Props {
  notePath: string,
}

const ScratchpadTemplate = ({ notePath }: Props) => {
  useEffect(() => {
    ConsoleImproved.log('notepath', notePath);
  }, [notePath]);

  return (
    <PageContainer>
      <HeaderContainer>
        <TopInfoPadding>
          <ScratchpadTitle title="Scratchpad" />
          <ScratchpadExplanation />
        </TopInfoPadding>
        <HorizontalLine />
      </HeaderContainer>
      <ProseMirrorContainer>
        <ProseMirrorComponentV2
          path={notePath}
          meetingSection="scratchpad"
          displayNotes
        />
      </ProseMirrorContainer>
    </PageContainer>
  );
};

export default ScratchpadTemplate;
