import React, { useContext, useState } from 'react';
import styled from 'styled-components';
// import { useNavigate } from 'react-router-dom-v5-compat';
import { AuthContext, UserDataContext } from '../../../App';
import { COLORS } from '../../../shared/colors/COLORS';
import { Page } from '../../../shared/types/types';
import { MeetingData } from '../../../shared/types/MeetingData';
import { OPEN_EVENT } from '../../../utils/analytics/enums';
import { logEvent, logMainTabMenuEvent } from '../../../utils/analytics/eventLogger';
import { INTERCOM_CONSTANTS } from '../../../utils/constants';
import { ALL_PAGES } from '../../../utils/enums';
import PageTabButtonAtom from './PageTabButtonAtom';
import ConsoleImproved from '../../../shared/classes/ConsoleImproved';
// import GoogleAPI from '../../../external/GoogleAPI/GoogleAPI';
import { makeCurrentNoteTabText } from './utils';
import SentryAPI from '../../../utils/analytics/SentryAPI';

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* white-space: nowrap; */
  width: 100%;
  background: ${COLORS.surface};
  padding: 6px 0 0;
`;

interface Props {
  page: Page,
  // eslint-disable-next-line no-unused-vars
  setPage: (page: Page) => void,
  meetingData: MeetingData,
}

const Navbar = ({ page, setPage, meetingData }: Props) => {
  const authState = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  // const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [isHover, setIsHover] = useState<boolean>(false);
  // const [isQuickNotesCurrentlyClicked,
  // setIsQuickNotesCurrentlyClicked] = useState<boolean>(false);
  const handleAllTasksClicked = () => {
    SentryAPI.addBreadcrumb({ category: 'click', message: 'All Tasks clicked in navbar', level: 'info' });
    setPage(ALL_PAGES.ALL_TASKS);
    logMainTabMenuEvent(authState.userId, OPEN_EVENT, ALL_PAGES.ALL_TASKS);
  };

  const handleCurrentMeetingClicked = () => {
    SentryAPI.addBreadcrumb({ category: 'click', message: 'Current Meeting clicked in navbar', level: 'info' });
    setPage('currentMeeting');
    logMainTabMenuEvent(authState.userId, OPEN_EVENT, 'currentMeeting');
  };

  // eslint-disable-next-line no-unused-vars
  const handleAllNotesClicked = () => {
    SentryAPI.addBreadcrumb({ category: 'click', message: 'All Notes clicked in navbar', level: 'info' });
    setPage(ALL_PAGES.ALL_NOTES);
    logMainTabMenuEvent(authState.userId, OPEN_EVENT, ALL_PAGES.ALL_NOTES);
  };

  // const handleClickNew = async () => {
  //   ConsoleImproved.log('Clicked new');
  //   if (isQuickNotesCurrentlyClicked) return;
  //   setIsQuickNotesCurrentlyClicked(true);
  //   Mixpanel.log(userData.userId, 'click', { button: 'newQuickNote' });
  //   await GoogleAPI.createNewMeeting
  // ('Quick Note', 'Quick note made by Shepherd', userData.userId, history);
  //   setIsQuickNotesCurrentlyClicked(false);
  // };

  const handleClickScratchpad = () => {
    ConsoleImproved.log('Clicked scratchpad');
    SentryAPI.addBreadcrumb({ category: 'click', message: 'Scratchpad clicked in navbar', level: 'info' });
    setPage(ALL_PAGES.SCRATCHPAD);
    logEvent(userData.userId, 'click', { button: 'scratchpad' });
  };

  // const quickNotesTabText = isQuickNotesCurrentlyClicked ? 'Creating...' : '+ New    ';
  const currentNoteTabText = makeCurrentNoteTabText(meetingData.data.title);

  return (
    <ButtonsContainer>
      <PageTabButtonAtom
        id={INTERCOM_CONSTANTS.all_notes_id}
        text="All Notes"
        onClick={handleAllNotesClicked}
        isSelected={page === ALL_PAGES.ALL_NOTES}
        tooltipText=""
        // tooltipText="Dashboard of all your notes"
        setIsHover={setIsHover}
      />
      <PageTabButtonAtom
        text="All Tasks"
        onClick={handleAllTasksClicked}
        isSelected={page === ALL_PAGES.ALL_TASKS}
        // tooltipText="Dashboard of all your tasks"
        tooltipText=""
        setIsHover={setIsHover}
      />
      <PageTabButtonAtom
        id={INTERCOM_CONSTANTS.current_meeting_id}
        text={currentNoteTabText}
        onClick={handleCurrentMeetingClicked}
        isSelected={page === 'currentMeeting'}
        // tooltipText="Current meeting notes and tasks"
        tooltipText=""
        setIsHover={setIsHover}
      />
      {/* <PageTabButtonAtom
        id="QuickNotes"
        text={quickNotesTabText}
        onClick={handleClickNew}
        isSelected={false}
        tooltipText="Create a new quick note"
        setIsHover={setIsHover}
      /> */}
      <PageTabButtonAtom
        id={INTERCOM_CONSTANTS.scratchpad}
        text="Scratchpad"
        onClick={handleClickScratchpad}
        isSelected={page === 'scratchpad'}
        tooltipText="Write down any quick notes"
        setIsHover={setIsHover}
      />
    </ButtonsContainer>
  );
};

export default Navbar;
