import React from 'react';
import { OperatingSystem } from '../../../types/types';
import ShortcutCommandButton from './ShortcutCommandButton';

interface Props {
  operatingSystem: OperatingSystem;
}

const MacOrWindowsCtrlIcon = ({ operatingSystem }: Props) => {
  if (operatingSystem === 'Mac') {
    return (
      <ShortcutCommandButton command="⌘" />
    );
  }
  return (
    <ShortcutCommandButton command="Ctrl" />
  );
};

export default MacOrWindowsCtrlIcon;
