import React from 'react';
import styled from 'styled-components';
import ProfileIcon from '..';
import { darkBlue1, gray1 } from '../../../colors/COLORS';
import { AttendeeV2 } from '../../../types/types';
import InviteTextAtom from './InviteTextAtom';
import OhNoTextAtom from './OhNoTextAtom';

const Container = styled.div`
  position: absolute;
  right: 20px;
  z-index: 100;

  padding: 12px;
  
  background: ${gray1};
  border-radius: 14px;
  border: 1px solid ${darkBlue1};
  box-shadow: 0px 2px 8px rgba(27, 33, 36, 0.16);
`;

const InviteContainer = styled.div`
  margin-left: 12px;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px; // In the design it is 4, but since the attendee icon
    // doesn't  have any top padding, we add 4 to make it look better.
  margin-left: 4px;

  div > {
    :last-child {
      margin-left: -2px;
    }
  }
`;

interface Props {
  isOpen: boolean,
  attendees: AttendeeV2[],
  handleClose: () => void,
  onInviteClick: () => void,
}

const ExtraAttendeesPopup = ({
  isOpen, attendees, handleClose, onInviteClick,
}: Props) => {
  if (!isOpen || attendees.length === 0) return null;

  return (
    <Container onMouseLeave={handleClose}>
      <OhNoTextAtom />
      <IconsContainer>
        {attendees.map((attendee) => (
          <ProfileIcon
            user={attendee}
            key={attendee.data.email}
          />
        ))}
        <InviteContainer onClick={onInviteClick}>
          <InviteTextAtom />
        </InviteContainer>
      </IconsContainer>
    </Container>
  );
};

export default ExtraAttendeesPopup;
