import React from 'react';
import styled from 'styled-components';
import Font from '../../../../shared/typography/Font';

const Container = styled.div`
  width: 310px;
  display: flex;
  align-items: center;

  #forOnlySevenDollars3 {
    margin-bottom: 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
`;

const LearnMoreFreePlanText = () => (
  <Container>
    <Font font="uiTextM" id="forOnlySevenDollars3" color="learnMoreGrey">
      On our
      {' '}
      <b>Free plan</b>
      ,
      you will be able to use all features of the app, but will be limited to the following:
    </Font>
  </Container>
);

export default LearnMoreFreePlanText;
