export interface DropdownOffset {
  left: number;
  top: number;
}

/**
   * Returns the offset to the desired DOMRect
   *
   * @param input the DOMRect to offset
   * @param offsetTo the DOMRect to offset to
   * @returns the offset
   */
export const offsetTo = (
  {
    width,
    height,
  }: DOMRect, // input
  {
    x: ol,
    y: ot,
  }: DOMRect, // offset to this position
): DOMRect => new DOMRect(
  ol,
  ot,
  width,
  height,
);

/**
 * Returns the offset to be applied on input so that it is bounded by bound
 *
 * @param {DOMRect} input the getBoundingClientRect() of the element to generate offset for
 * @param {DOMRect} bound the element to bound the input in
 */
export const offsetBoundByproduct = (
  {
    width: iw,
    height: ih,
    left: il,
    top: it,
  }: DOMRect, // input
  {
    right: br,
    bottom: bb,
  }: DOMRect, // bound
): DropdownOffset => {
  const overflowLeft = Math.max(il + iw - br, 0);
  const overflowTop = Math.max(it + ih - bb, 0);
  return {
    left: -overflowLeft,
    top: -overflowTop,
  };
};

export const boundAndApply = (rect: DOMRect, boundIn: DOMRect) => {
  if (rect === null) { return null; }
  const offset = offsetBoundByproduct(rect, boundIn);
  return new DOMRect(
    rect.x + offset.left,
    rect.y + offset.top,
    rect.width,
    rect.height,
  );
};
