import React from 'react';
import styled from 'styled-components';
import ButtonSmall from '../../../../../shared/components/button-small';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px 35px;

  border-radius: 8px;
  /* width: 100%; */

  box-shadow: 0px 2px 8px rgba(27, 33, 36, 0.16);

  #freeTrial {
    font-weight: bold;
  }

  > *:last-child {
    font-size: 12px;
  };
  font-size: 11px;
`;

interface PaymentFailedButtonCardMolProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onClickTryAgain: () => void,
}

const PaymentFailedButtonCardMol = ({
  onClickTryAgain,
}: PaymentFailedButtonCardMolProps) => (
  <Container>
    <ButtonSmall padding="11px 20px" text="Try Again" onClick={onClickTryAgain} />
  </Container>
);

export default PaymentFailedButtonCardMol;
