import React from 'react';
import { COLORS } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const DownQuote = ({ fill }: Props) => (
  <svg width="36" height="27" viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4363 2.65334L15.6321 0.308594C5.71404 2.47298 0.352905 10.8599 0.352905 17.8942C0.352905 23.3051 3.56958 26.1909 7.14367 26.1909C10.8965 26.1909 13.6664 23.3051 13.6664 19.8782C13.6664 17.1727 11.9687 15.1887 9.91359 14.0163C8.57331 13.2047 7.76914 12.1225 8.2159 10.1385C9.02007 7.07228 11.5219 4.3668 16.4363 2.65334ZM19.5636 17.8942C19.5636 23.3051 22.7803 26.1909 26.3544 26.1909C30.1072 26.1909 32.8771 23.3051 32.8771 19.8782C32.8771 17.1727 31.1794 15.1887 29.1243 14.0163C27.784 13.2047 26.9799 12.1225 27.4266 10.1385C28.2308 7.07228 30.7327 4.3668 35.647 2.65334L34.8429 0.308594C24.9248 2.47298 19.5636 10.8599 19.5636 17.8942Z" fill={fill} />
  </svg>
);

DownQuote.defaultProps = {
  fill: COLORS.darkBlue1,
};

export default DownQuote;
