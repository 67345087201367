import React, { useContext, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import ErrorPage from '../../shared/components/error-page';
import { logPageEvent } from '../../utils/analytics/eventLogger';
import { GOOGLE_CALENDAR_ERROR_PAGE } from '../../utils/analytics/enums';
import Scrollbar from '../../shared/components/scrollbar';
import { AuthContext } from '../../App';
import GoogleCalendarErrorPageTemplate from './GoogleCalendarErrorPageTemplate';

export interface Props {
  email: string;
  retry: () => void;
}

const GoogleCalendarErrorPage = ({ email, retry }: Props) => {
  // If you don't have access to the meeting in Google Calendar,
  // and the meeting doesn't exist in Shepherd, this page will show
  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);

  useEffect(() => {
    logPageEvent(authState.userId, trackEvent, GOOGLE_CALENDAR_ERROR_PAGE);
  }, []);

  return (
    <>
      <Scrollbar maxHeight="100vh">
        <ErrorPage>
          <GoogleCalendarErrorPageTemplate email={email} retry={retry} />
        </ErrorPage>
      </Scrollbar>
    </>
  );
};

export default GoogleCalendarErrorPage;
