import React from 'react';
import { gray1, gray3, green6 } from '../../colors/COLORS';

interface Props {
  fill?: string,
}
const CheckIcon = ({ fill }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="18" height="18" rx="3" fill={fill} />
    <path d="M7 10.6667L9.13043 13L14 7" stroke={gray1} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="1" y="1" width="18" height="18" rx="3" stroke={gray3} strokeWidth="2" />
  </svg>
);

CheckIcon.defaultProps = {
  fill: green6,
};

export default CheckIcon;
