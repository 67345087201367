/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import Font from '../../../shared/typography/Font';
import ButtonSmall from '../../../shared/components/button-small';
import { COLORS } from '../../../shared/colors/COLORS';
import InviteByEmailInputCtrl from '../../../shared/components/input/InviteByEmailInput/InviteByEmailInputCtrl';
import WorkspaceData from '../../../database/Workspaces/WorkspaceData';
import WorkspaceMemberRowMol from '../../../shared/components/quick-settings/workspaces/WorkspaceMemberRowMol';
import WorkspaceMember from '../../../database/Workspaces/WorkspaceMember';
import { WorkspacePermissionExpanded } from '../../../shared/types/types';
import WorkspaceLogo from '../../../shared/icons/WorkspaceLogo';
import { INTERCOM_CONSTANTS } from '../../../utils/constants';

const Container = styled.div`
  padding: 64px 48px;
  img {
    border-radius: 8px;
  }
  user-select: none;
`;

const HowAreYouPlanningOnUsingShepherdContainer = styled.div`
  margin-bottom: 172px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > *:first-child {
    margin-bottom: 16px;
  }
`;

const ContinueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 120px; */

  button {
    width: 120px;
  }
`;

const MiddleContainer = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WorkspaceNameContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > *:first-child {
    margin-left: 4px;
  }

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }

`;

const SharableLinkContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > *:first-child {
    margin-right: 8px;
  }

  padding: 4px 4px 8px;

  &:hover {
    cursor: pointer;
    border-radius: 4px;
    background-color: ${COLORS.blue1};
  }
`;

const InvitedMembersContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px 0 4px;
  margin: 16px 0;

  /* Not last child */
  & > div {
    margin-top: 8px;
  }
`;

const LearnMoreAboutWorkspacesContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* padding: 0px 8px; */

  &:hover {
    /* cursor: pointer; */
    /* opacity: 0.8; */
    /* border-radius: 8px; */
    /* background-color: ${COLORS.gray4}; */
  }
`;

interface WorkspaceOnboardingTemplateProps {
  onClickContinue: (newWorkspaceName: string) => void;
  invitedMembers: WorkspaceMember[];
  selectedWorkspace: WorkspaceData;
  // eslint-disable-next-line react/no-unused-prop-types
  onSetMemberPermission: (email: string, newPermission: WorkspacePermissionExpanded) => void;
}

const InviteTeammatesToWorkspaceTemplate = (
  {
    onClickContinue, invitedMembers,
    onSetMemberPermission, selectedWorkspace,
  }: WorkspaceOnboardingTemplateProps,
) => {
  const [workspaceName, setWorkspaceName] = useState('');
  const { showArticle } = useIntercom();

  const handleClickContinue = () => {
    if (!workspaceName) return;
    onClickContinue(workspaceName);
  };

  useEffect(() => {
    if (!selectedWorkspace) return;
    setWorkspaceName(selectedWorkspace.name);
  }, [selectedWorkspace]);

  const handleClickWorkspaceFAQ = () => {
    showArticle(INTERCOM_CONSTANTS.articles.workspaceFAQ);
  };

  const isWorkspaceNameValid = workspaceName.length > 2;

  return (
    <Container>
      <HowAreYouPlanningOnUsingShepherdContainer>
        <Font font="header900">Invite teammates</Font>
        <Font font="uiTextM" color="gray7">Get the most out of Shepherd by inviting your teammates to a Workspace.</Font>
        <LearnMoreAboutWorkspacesContainer onClick={handleClickWorkspaceFAQ}>
          <Font font="uiTextM" color="blue4" isButton>Learn more about Workspaces.</Font>
        </LearnMoreAboutWorkspacesContainer>
      </HowAreYouPlanningOnUsingShepherdContainer>
      <MiddleContainer>
        <WorkspaceLogo name={workspaceName} />
        <Font font="header400">{workspaceName}</Font>
        <InvitedMembersContainer>
          <Font font="header400">Invites</Font>
          {invitedMembers.map((member) => {
            return (
              <WorkspaceMemberRowMol
                key={member.userId + member.email}
                member={member}
                workspace={selectedWorkspace}
                setMemberPermission={onSetMemberPermission}
                setIsPermissionsOpen={() => {}}
              />
            );
          })}
          {invitedMembers.length === 0 && (
            <Font font="defaultSmall" color="gray7">No invited members yet</Font>
          )}
        </InvitedMembersContainer>
        <WorkspaceNameContainer>
          <Font font="header400">Send Invites</Font>
          <InviteByEmailInputCtrl onReset={() => {}} showReset={false} />
          {/* <SharableLinkContainer>
            <TextLinkIcon fill={COLORS.blue6} />
            <Font font="uiTextS" color="blue6">Copy sharable link</Font>
          </SharableLinkContainer> */}
        </WorkspaceNameContainer>
      </MiddleContainer>
      <ContinueContainer>
        <ButtonSmall onClick={handleClickContinue} text="Continue" isDisabled={!isWorkspaceNameValid} />
      </ContinueContainer>
    </Container>
  );
};

export default InviteTeammatesToWorkspaceTemplate;
