import { ResolvedState } from '../../shared/types/types';

/**
 * The constructor assumes that resolvedData resolve state array is
 * first slack, then email and then in app notification.
 */
class MentionedStatus {
  isSentToSlack: boolean;

  isSentToEmail: boolean;

  isSentAppNotification: boolean;

  constructor(resolvedData: ResolvedState[]) {
    const list = resolvedData.map((item) => item.toString());
    this.isSentToSlack = list[0] === 'resolved';
    this.isSentToEmail = list[1] === 'resolved';
    this.isSentAppNotification = list[2] === 'resolved';
  }
}

export default MentionedStatus;
