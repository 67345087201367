import { ResolvedState } from '../../shared/types/types';
import { PENDING, REJECTED, RESOLVED } from '../enums';

/**
 * A class that wraps the ResolvedState enum, and adds some helper methods
 * making it easier to work with the enum
 *
 * @example
 * ```
 * const resolvedStateSmart = ResolvedStateSmart.RESOLVED;
 * if (resolvedStateSmart.isNotResolved) return;
 * // positive path
 * ```
 */
class ResolvedStateSmart {
  value: ResolvedState;

  constructor(value: ResolvedState) {
    this.value = value;
  }

  get isResolved(): boolean {
    return this.value === RESOLVED;
  }

  get isNotResolved(): boolean {
    return this.value !== RESOLVED;
  }

  get isRejected(): boolean {
    return this.value === REJECTED;
  }

  get isPending(): boolean {
    return this.value === PENDING;
  }

  static get REJECTED(): ResolvedStateSmart {
    return new ResolvedStateSmart(REJECTED);
  }

  static get RESOLVED(): ResolvedStateSmart {
    return new ResolvedStateSmart(RESOLVED);
  }

  static get PENDING(): ResolvedStateSmart {
    return new ResolvedStateSmart(PENDING);
  }
}

export default ResolvedStateSmart;
