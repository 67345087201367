import GapiHtmlLink from '../../external/GoogleAPI/GapiHtmlLink';
import {
  GapiMeetingData, GapiResponseStatus, GoogleAttendee,
} from '../../shared/types/types';
import { MeetingData } from '../../shared/types/MeetingData';
import { GAPI_RESPONSE_TYPE } from '../../utils/enums';

const mapGapiResultToGapiMeetingData = (
  gapiResult: any,
): GapiMeetingData => {
  const gapiMeetingData: GapiMeetingData = {
    resolvedState: 'resolved',
    updated: gapiResult?.updated ?? '',
    summary: gapiResult?.summary ?? '',
    description: gapiResult?.description ?? '',
    status: gapiResult?.status ?? '',
    start: {
      dateTime: gapiResult?.start?.dateTime ?? '',
      timeZone: gapiResult?.start?.timeZone ?? '',
      date: gapiResult?.start?.date ?? '',
    },
    end: {
      dateTime: gapiResult?.end?.dateTime ?? '',
      timeZone: gapiResult?.end?.timeZone ?? '',
      date: gapiResult?.end?.date ?? '',
    },
    sequence: gapiResult?.sequence ?? 0,
    reminders: {
      useDefault: gapiResult?.reminders?.useDefault ?? false,
    },
    organizer: {
      email: gapiResult?.organizer?.email ?? '',
      self: gapiResult?.organizer?.self ?? false,
    },
    kind: gapiResult?.kind ?? '',
    id: gapiResult?.id ?? '',
    htmlLink: new GapiHtmlLink(gapiResult?.htmlLink ?? '').value,
    iCalUID: gapiResult?.iCalUID ?? '',
    eventType: gapiResult?.eventType ?? '',
    recurringEventId: gapiResult?.recurringEventId ?? '',
    recurrence: gapiResult?.recurrence ?? [],
    etag: gapiResult?.etag ?? '',
    creator: {
      email: gapiResult?.creator?.email ?? '',
      self: gapiResult?.creator?.self ?? false,
    },
    created: gapiResult?.created ?? '',
    conferenceData: {
      conferenceId: gapiResult?.conferenceData?.conferenceId ?? '',
      conferenceSolution: {
        iconUri: gapiResult?.conferenceData?.conferenceSolution?.iconUri ?? '',
        key: {
          type: gapiResult?.conferenceData?.conferenceSolution?.key?.type ?? '',
        },
        name: gapiResult?.conferenceData?.conferenceSolution?.name ?? '',
      },
      entryPoints: gapiResult?.conferenceData?.entryPoints ?? [],
      signature: gapiResult?.conferenceData?.signature ?? '',
    },
    attendees: mapGapiRawAttendeeToGoogleAttendee(gapiResult?.attendees ?? []),
  };
  return gapiMeetingData;
};

export const mapGapiRawAttendeeToGoogleAttendee = (attendees: any[]): GoogleAttendee[] => {
  if (attendees?.length === 0) return [];
  return attendees.map((attendee) => ({
    email: attendee?.email ?? '',
    displayName: attendee?.displayName ?? '',
    organizer: attendee?.organizer ?? false,
    responseStatus: mapResponseStatus(attendee?.responseStatus ?? ''),
    comment: attendee?.comment ?? '',
  } as GoogleAttendee));
};

export const mapResponseStatus = (responseStatus: string): GapiResponseStatus => {
  if (responseStatus === 'accepted') return GAPI_RESPONSE_TYPE.ACCEPTED;
  if (responseStatus === 'declined') return GAPI_RESPONSE_TYPE.DECLINED;
  if (responseStatus === 'tentative') return GAPI_RESPONSE_TYPE.TENTATIVE;
  if (responseStatus === 'needsAction') return GAPI_RESPONSE_TYPE.NEEDS_ACTION;
  return GAPI_RESPONSE_TYPE.UNKNOWN;
};

export const mapMeetingDataToGapiMeeting = (
  meetingData: MeetingData,
): GapiMeetingData => {
  const gapiMeetingData: GapiMeetingData = {
    resolvedState: 'resolved',
    updated: '',
    summary: meetingData.data.title ?? '',
    description: meetingData.data.description ?? '',
    status: meetingData.googleData.content?.status ?? '',
    start: {
      dateTime: meetingData.date?.start?.date ?? '',
      timeZone: '',
      date: meetingData.date?.start?.date ?? '',
    },
    end: {
      dateTime: meetingData.date.end.date ?? '',
      timeZone: '',
      date: meetingData.date.end.date ?? '',
    },
    sequence: 0,
    reminders: {
      useDefault: false,
    },
    organizer: {
      email: '',
      self: false,
    },
    kind: '',
    id: meetingData.googleData.ids.eventId ?? '',
    htmlLink: meetingData.googleData.ids.htmlLink ?? '',
    iCalUID: meetingData.googleData.ids.calendarId ?? '',
    eventType: '',
    recurringEventId: '',
    recurrence: [],
    etag: '',
    creator: {
      email: '',
      self: false,
    },
    created: meetingData.date.created.date ?? '',
    conferenceData: {
      conferenceId: '',
      conferenceSolution: {
        iconUri: '',
        key: {
          type: '',
        },
        name: '',
      },
      entryPoints: [],
      signature: '',
    },
    attendees: [],
  };
  return gapiMeetingData;
};

export default mapGapiResultToGapiMeetingData;

export const rejectedGapiMeetingData: GapiMeetingData = {
  ...mapGapiResultToGapiMeetingData({}),
  resolvedState: 'rejected',
};

export const pendingGapiMeetingData: GapiMeetingData = {
  ...mapGapiResultToGapiMeetingData({}),
  resolvedState: 'pending',
};

export const makeDummyGapiMeetingData = (title: string): GapiMeetingData => ({
  ...mapGapiResultToGapiMeetingData({}),
  resolvedState: 'resolved',
  summary: title,
} as GapiMeetingData);

export const makeMultipleDummyGapiMeetingData = (
  titles: string[],
): GapiMeetingData[] => titles.map((title) => makeDummyGapiMeetingData(title));

export const isGapiMeetingToday = (gapiMeeting: GapiMeetingData): boolean => {
  const today = new Date();
  const gapiStartDate = new Date(gapiMeeting.start.dateTime);
  return (
    today.getFullYear() === gapiStartDate.getFullYear()
    && today.getMonth() === gapiStartDate.getMonth()
    && today.getDate() === gapiStartDate.getDate()
  );
};

export const isGapiMeetingInTheFuture = (gapiMeeting: GapiMeetingData): boolean => {
  const today = new Date();
  const gapiStartDate = new Date(gapiMeeting.start.dateTime);
  return gapiStartDate > today;
};
