import { useStripe } from '@stripe/react-stripe-js';
import React, { useContext } from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import StripeAPI from '../../../../external/Stripe/StripeAPI';
import { StripeConstants } from '../../../../external/Stripe/StripeConstants';
import BillingModalLearnMore from '../../../../pages/all-pages/Billing/LearnMore/BillingModalLearnMore';
import { toastDanger } from '../../../../utils/notifications';
import ConsoleImproved from '../../../classes/ConsoleImproved';
import FreePlanDropdownOrgSecond from './FreePlanDropdown/FreePlanDropdownOrgSecond';
import FreePlanTagAtom from './FreePlanTagAtom';

const Container = styled.div`
  position: relative;
  span {
    user-select: none;
  }
`;

const TagContainer = styled.div`
  /* margin-left: 12px; */
`;

const DropdownContainer = styled.div`
  position: absolute;

  top: 27px;
  left: -122px;
  z-index: 99999;
`;

const FreePlanTagCtrl = () => {
  const userData = useContext(UserDataContext);

  const stripe = useStripe();
  const { trackEvent } = useIntercom();
  const [isHovered, setIsHovered] = React.useState(false);
  const [isLearnMoreOpen, setIsLearnMoreOpen] = React.useState(false);

  const handleClickSubscribe = async () => {
    try {
      await StripeAPI.createAndRedirectToCheckoutSession(
        userData, StripeConstants.CHECKOUT_ORIGINS.DuringFreePlan,
        userData.workspace.numberOfMembers, trackEvent, stripe,
      );
    } catch (error: any) {
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const toggleHovered = () => {
    setIsHovered(!isHovered);
  };

  const handleClickLearnMore = () => {
    setIsLearnMoreOpen(true);
  };

  const handleClickBack = () => {
    setIsLearnMoreOpen(false);
  };

  if (!userData.isOnFreePlan()) {
    return null;
  }

  return (
    <Container>
      <TagContainer onMouseEnter={() => setIsHovered(true)}>
        <FreePlanTagAtom onClick={toggleHovered} />
      </TagContainer>
      <DropdownContainer>
        <FreePlanDropdownOrgSecond
          isOpen={isHovered}
          onClose={() => { setIsHovered(false); }}
          onClickSubscribe={handleClickSubscribe}
          onClickLearnMore={handleClickLearnMore}
        />
      </DropdownContainer>
      <BillingModalLearnMore
        isOpen={isLearnMoreOpen}
        onClickBack={handleClickBack}
        onClickOutside={handleClickBack}
      />
    </Container>
  );
};

export default FreePlanTagCtrl;
