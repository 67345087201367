/* eslint-disable no-unused-vars */
import { uuid4 } from '@sentry/utils';
import { StorageReference, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../../../../utils/firebase';

interface UploadParams {
  file: File,
  getStorageRef: (path: string) => StorageReference;
  onCompleted?: (storageRef: StorageReference) => any;
  onError?: (error: Error) => any;
  onProgress?: (progress: number, total?: number, transferred?: number) => any;
  path: string;
}

export default function startFirebaseUpload({
  file,
  getStorageRef,
  onCompleted,
  onError,
  onProgress,
  path,
}: UploadParams) {
  const fileCode = uuid4();
  const storageRef = ref(storage, path);
  const fileRef = ref(storageRef, `${fileCode}-${file.name}`);
  const uploadTask = uploadBytesResumable(fileRef, file);
  // const uploadTask = getStorageRef(path)
  //   .child(`/${fileCode}-${file.name}`)
  //   .put(file);

  uploadTask.on('state_changed',
    (snapshot) => {
      if (typeof onProgress === 'function') {
        onProgress(
          snapshot.bytesTransferred / snapshot.totalBytes,
          snapshot.totalBytes,
          snapshot.bytesTransferred,
        );
      }
    },
    (error) => {
      if (typeof onError === 'function') {
        onError(error);
      }
    },
    async () => {
      if (typeof onCompleted === 'function') {
        await onCompleted(uploadTask.snapshot.ref);
      }
    });
}
