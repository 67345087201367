import React from 'react';
import styled from 'styled-components';
import { gray1 } from '../../../colors/COLORS';

const TextEditorSolidGradient = styled.div`
  width: 100%;
  height: 60px;

  background: ${gray1};
`;

const TextEditorGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 144px;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 56.25%);

  z-index: 3;
  pointer-events: none;
`;

const TextEditorBottomOverlay = () => (
  <>
    <TextEditorSolidGradient />
    <TextEditorGradient />
  </>
);

export default TextEditorBottomOverlay;
