import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface CreateNewTaskProps {
  fill?: string;
  width?: number;
  height?: number;
}

const CreateNewTaskIcon = ({
  fill,
  width,
  height,
}: CreateNewTaskProps) => (
  <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 11.074837,4.9838463 c 0.29289,0.292893 0.29289,0.767767 0,1.06066 L 6.794508,10.324836 4.014178,7.5445063 c -0.292894,-0.29289 -0.292894,-0.76777 0,-1.06066 0.292893,-0.29289 0.76777,-0.29289 1.06066,0 l 1.71967,1.71967 3.21967,-3.21967 c 0.29289,-0.292893 0.76777,-0.292893 1.060659,0 z m -0.261231,-3.43934 H 4.2754063 c -0.89691,0 -1.54842,0.31181 -1.98005,0.76618 -0.43847,0.46156 -0.75085,1.17831 -0.75085,2.1537 v 6.1602197 c 0,0.978 0.3121,1.6944 0.74936,2.1548 0.43028,0.4531 1.08125,0.7651 1.98154,0.7651 h 6.5381997 c 0.9003,0 1.5513,-0.312 1.9815,-0.7651 0.4373,-0.4604 0.7494,-1.1768 0.7494,-2.1548 V 4.4643863 c 0,-0.97796 -0.3121,-1.69434 -0.7494,-2.15481 -0.4302,-0.45311 -1.0812,-0.76507 -1.9815,-0.76507 z M 4.2754063,0.04450626 h 6.5381997 c 2.5435,0 4.2309,1.81249004 4.2309,4.41988004 v 6.1602197 c 0,2.6074 -1.6874,4.4199 -4.2309,4.4199 H 4.2754063 c -2.54346,0 -4.23090004,-1.8125 -4.23090004,-4.4199 V 4.4643863 c 0,-2.60495 1.69190004,-4.41988004 4.23090004,-4.41988004 z"
      fill={fill}
    />
  </svg>
);

CreateNewTaskIcon.defaultProps = {
  fill: darkBlue4,
  width: 15,
  height: 15,
};

export default CreateNewTaskIcon;
