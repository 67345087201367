import { EditorState, Plugin } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export type PlaceHolderPluginState = {
  id: string;
} & {
  [K: string]: any;
}

export default new Plugin({
  state: {
    init() {
      return DecorationSet.empty;
    },
    apply(tr, set: DecorationSet) {
      let modifiedSet = set.map(tr.mapping, tr.doc);

      const action = tr.getMeta(this);

      if (action && action.add) {
        const widget = document.createElement('placeholder');
        const decoration = Decoration.widget(
          action.add.pos, widget,
          { id: action.add.id },
        );
        modifiedSet = modifiedSet.add(tr.doc, [decoration]);
      } else if (action && action.remove) {
        modifiedSet = set.remove(set.find(undefined, undefined,
          (spec) => spec.id === action.remove.id));
      }

      return modifiedSet;
    },
  },
  props: {
    decorations(state: EditorState) {
      return this.getState(state);
    },
  },
});
