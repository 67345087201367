import { ShortcutInfo } from '../../../types/types';

export const windowsBasicShortcuts:ShortcutInfo[] = [
  { name: 'Open/Close Sidebar', commands: ['Alt', 'x'] },
  { name: 'Mention User', commands: ['@', 'Username'] },
  { name: 'Create New Task', commands: ['Alt', 't'] },
  { name: 'Insert GIF', commands: ['/', 'Gif'] },
  { name: 'Insert Emoji', commands: [':', 'Keyword'] },
  { name: 'Insert Timestamp', commands: ['/', 'Time'] },
];

/* removed for now since doesn't work when cursor is within text editor
{ name: 'Move Between Tabs', commands: ['Ctrl', 'left-arrow', 'right-arrow'] }, */

export const windowsCommonActionShortcuts:ShortcutInfo[] = [
  { name: 'Copy', commands: ['Ctrl', 'c'] },
  { name: 'Cut', commands: ['Ctrl', 'x'] },
  { name: 'Paste', commands: ['Ctrl', 'v'] },
  { name: 'Paste Without Formatting', commands: ['Ctrl', 'Shift', 'v'] },
  { name: 'Undo', commands: ['Ctrl', 'z'] },
  { name: 'Redo', commands: ['Ctrl', 'Shift', 'z'] },
  { name: 'Insert Link', commands: ['Ctrl', 'k'] },
  { name: 'Print', commands: ['Ctrl', 'p'] },
  { name: 'Find', commands: ['Ctrl', 'f'] },
  { name: 'Bulleted List', commands: ['-', 'Spacebar'] },
  { name: 'Indent Bullet List', commands: ['Tab'] },
];

export const windowsTextFormattingShortcuts:ShortcutInfo[] = [
  { name: 'Bold', commands: ['Ctrl', 'b'] },
  { name: 'Italicize', commands: ['Ctrl', 'i'] },
  { name: 'Underline', commands: ['Ctrl', 'u'] },
  { name: 'Strikethrough', commands: ['Alt', 'Shift', '5'] },
  { name: 'Copy Text Formatting', commands: ['Ctrl', 'Alt', 'c'] },
  { name: 'Paste Text Formatting', commands: ['Ctrl', 'Alt', 'v'] },
];

export const windowsTextSelectionWithKeyboardShortcuts:ShortcutInfo[] = [
  { name: 'Select All', commands: ['Ctrl', 'a'] },
  { name: 'Extend Selection One Character', commands: ['Ctrl', 'left-arrow', 'right-arrow'] },
  { name: 'Extend Selection One Line', commands: ['Ctrl', 'left-arrow', 'right-arrow'] },
  { name: 'Extend Selection to the End of the Line', commands: ['Shift', 'End'] },
  { name: 'Extend Selection to the Beginning of the Document', commands: ['Ctrl', 'Shift', 'Home'] },
  { name: 'Extend Selection to the End of the Document', commands: ['Ctrl', 'Shift', 'End'] },
];

export const windowsTextSelectionWithCursorShortcuts:ShortcutInfo[] = [
  { name: 'Select Word', commands: ['Double-click'] },
  { name: 'Select Paragraph', commands: ['Triple-click'] },
];
