import React from 'react';
import styled from 'styled-components';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import GoogleIcon from '../../shared/icons/google';
import Font from '../../shared/typography/Font';
import OnboardingSubmitButton from '../onboarding/personal/components/onboarding-submit-button';
import PrivacyModal from './PrivacyModal';
import { BUTTON_TEXT } from './utils/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const InnerContainer = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 50px;
  padding-bottom: 50px; // So Intercom bubble doesn't cover the bottom of the page

  > span {
    margin-bottom: 20px;
  }
`;

const A = styled.a`
  margin-top: 20px;
  margin-bottom: 20px;
  video {
    width: 350px;
  }
`;

const BackContainer = styled.div`
  margin-top: 20px;
  width: 350px;
  display: flex;
  justify-content: center;
`;

const GoogleButtonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  width: 250px;
`;

const ModalContainer = styled.div`
  display: contents;
`;

interface Props {
  onClick: () => void,
  onBackClick: () => void,
}

const CalendarPermissions = ({ onClick, onBackClick }: Props) => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = React.useState(false);

  const openModal = () => {
    ConsoleImproved.log('Clicked open modal');
    setIsPrivacyModalOpen((prev) => !prev);
  };

  return (
    <Container>
      <InnerContainer>
        <Font font="header700">Calendar Permissions</Font>
        <A href="https://gyazo.com/900c02a1e21b8968f0f1da53efd6fcf8">
          <video autoPlay muted loop controls><source src="https://i.gyazo.com/900c02a1e21b8968f0f1da53efd6fcf8.mp4" type="video/mp4" /></video>
        </A>
        <Font font="uiTextMedium">
          The calendar permissions are only used to be able to view your events
          inside Shepherd. We
          {' '}
          <u>do not</u>
          {' '}
          edit or delete any of your events.
          {' '}
          <ModalContainer>
            <Font font="uiTextMedium" color="blue3" onClick={openModal} isButton>Learn more</Font>
          </ModalContainer>
        </Font>
        <GoogleButtonContainer>
          <OnboardingSubmitButton
            text={BUTTON_TEXT}
            loading={false}
            onClick={onClick}
            hasIcon
            Icon={GoogleIcon}
          />
        </GoogleButtonContainer>
        <BackContainer>
          <Font font="messageText" color="blue4" onClick={onBackClick} isButton>Back</Font>
        </BackContainer>
      </InnerContainer>
      <PrivacyModal isOpen={isPrivacyModalOpen} setIsOpen={openModal} />
    </Container>
  );
};

export default CalendarPermissions;
