import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import {
  dateToTaskDueDateFormat, getWeekday, getDayAndMonth, timeFromNowToDueDate,
} from '../../../utils/dateUtils/date';
import { blue6, gray7 } from '../../colors/COLORS';
import DeleteCrossIcon from '../../icons/DeleteCrossIcon';
import { TaskItem } from '../../types/types';
import { uiText } from '../../typography';
import { resetTaskDueDate } from './TaskModalUtils';

const TextContainer = styled.div`
  ${uiText};
  margin: 0;
  cursor: pointer;
  h2{
    display: inline;  
    ${uiText};
    color: ${blue6};
    margin-right: 8px;
  }
  span{
    color: ${gray7};
  }
`;

const AddDate = styled.div`
  ${uiText};
  color: ${gray7};
  cursor: pointer;
  :hover {
    color: ${blue6};
  }
`;

const Container = styled.div`
  width: 375px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const H2 = styled.span`
  ${uiText};
  color: ${blue6} !important;
  cursor: pointer;
  margin: 0 0 0 8px;
`;

interface Props {
  date: string,
  onDateRemove: Dispatch<SetStateAction<TaskItem>>,
  onClick: () => void,
}

const TaskDetailsDateLabel = ({ date, onDateRemove, onClick }: Props) => {
  const isDateSet = date.length > 0;
  const dateJs = new Date(date);
  const formattedDate = dateToTaskDueDateFormat(dateJs);
  const dueDateNumber = timeFromNowToDueDate(dateJs);
  const dayString = dueDateNumber > 1 ? 'days' : 'day';
  const dueDate = dueDateNumber === 0 ? '(today)' : `in ${dueDateNumber.toString()} ${dayString}`;

  const handleDateRemove = (event: any) => {
    event.stopPropagation();
    resetTaskDueDate(onDateRemove);
  };

  if (isDateSet) {
    return (
      <Container>
        <TextContainer>
          Due on
          <H2 onClick={onClick}>{`${getWeekday(formattedDate)} ${getDayAndMonth(formattedDate)} `}</H2>
          <span>
            {dueDate}
          </span>
        </TextContainer>
        <IconContainer>
          <DeleteCrossIcon onClick={(event) => handleDateRemove(event)} />
        </IconContainer>
      </Container>
    );
  }
  return (
    <AddDate onClick={onClick}>Add Due Date</AddDate>
  );
};

export default TaskDetailsDateLabel;
