import React, {
  useEffect, useRef, useState, FocusEventHandler, KeyboardEventHandler,
} from 'react';
import styled from 'styled-components';
import ReactTooltip from '../tooltip/ReactTooltip';
import { uiText } from '../../typography';
import { layoutWhite } from '../../colors/COLORS';

interface IsPrivateProps {
  isPrivate: boolean,
}

const Container = styled.div<IsPrivateProps>`
  flex: 1; // for some reason, this is needed to make the text width work
  width: 10px; // for some reason, this is needed to make the text width work
  ${({ isPrivate }) => isPrivate && 'margin-right: 16px'}
`;

const TextContainer = styled.span`
  ${uiText}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
`;

const Input = styled.input<IsPrivateProps>`
  ${uiText}
  width: 10px;
  flex: 1;
  box-sizing: border-box;
  ${({ isPrivate }) => isPrivate && 'margin-right: 16px'}
  outline-color: ${layoutWhite};
`;

interface Props {
  text: string,
  isPrivate: boolean,
  // eslint-disable-next-line no-unused-vars
  updateTitle: (title: string) => void,
}

const TaskCardTitle = ({ text, isPrivate, updateTitle }: Props) => {
  const textRef = useRef<HTMLSpanElement>(document.createElement('span'));
  const inputRef = useRef<HTMLInputElement>(document.createElement('input'));
  const [displayText, setDisplayText] = useState<string>(text);
  const [isTextOverflow, setTextOverflow] = useState<boolean>(false);
  const [isEditing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    setDisplayText(text);
  }, [text]);

  useEffect(() => {
    setTextOverflow(textRef.current.scrollWidth > textRef.current.offsetWidth);
  }, [textRef, text]);

  useEffect(() => {
    if (!isEditing) return;
    inputRef.current.focus();
  }, [isEditing]);

  const enterEditMode = () => {
    setEditing(true);
  };

  const exitEditMode = (finalText: string, update: boolean = true) => {
    // force user to input something task title tile
    const trimmedText = finalText.trim();
    if (trimmedText.length === 0 && update) return;
    setEditing(false);
    if (!update) return;
    setDisplayText(trimmedText);
    updateTitle(trimmedText);
  };

  const onClickTitle = () => {
    enterEditMode();
  };

  const onInputBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    exitEditMode(event.target.value);
  };

  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Escape') {
      exitEditMode('', false);
    } else if (event.key === 'Enter') {
      const inputElement: any = event.target;
      exitEditMode(inputElement.value);
    }
  };

  if (isEditing) {
    return (
      <Input
        isPrivate={isPrivate}
        type="text"
        ref={inputRef}
        defaultValue={displayText}
        onBlur={onInputBlur}
        onKeyDown={onInputKeyDown}
      />
    );
  }

  if (isTextOverflow) {
    return (
      <Container isPrivate={isPrivate}>
        <ReactTooltip tip={displayText} place="bottom">
          <TextContainer ref={textRef} onClick={onClickTitle}>
            {displayText}
          </TextContainer>
        </ReactTooltip>
      </Container>
    );
  }

  return (
    <TextContainer ref={textRef} onClick={onClickTitle}>
      {displayText}
    </TextContainer>
  );
};

export default TaskCardTitle;
