/* eslint-disable no-unused-vars */
import React from 'react';
import {
  addColumnBefore, addColumnAfter, deleteColumn, addRowBefore, addRowAfter,
  deleteRow, deleteTable, mergeCells, splitCell, setCellAttr,
} from '@meetshepherd/prosemirror-tables';
import { EditorState, Transaction } from 'prosemirror-state';
import TableIcon from '../../../components/icons/table';
import TableColorIcon from '../../../components/icons/table-color';
import AddColumnBeforeIcon from '../../../components/icons/add-column-before';
import AddColumnAfterIcon from '../../../components/icons/add-column-after';
import DeleteColumnIcon from '../../../components/icons/delete-column';
import AddRowBeforeIcon from '../../../components/icons/add-row-before';
import AddRowAfterIcon from '../../../components/icons/add-row-after';
import DeleteRowIcon from '../../../components/icons/delete-row';
import DeleteTableIcon from '../../../components/icons/delete-table';
import MergeCellsIcon from '../../../components/icons/merge-cells';
import SplitCellsIcon from '../../../components/icons/split-cells';
import { insertNonNestableTable } from '../helpers/table-utils';
import tableControlsAdapter from '../../adapters/table-controls-adapter';

export default [
  {
    label: 'Insert Table',
    callback: insertNonNestableTable,
    icon: <TableIcon />,
    id: 'insert-table',
  },
  {
    colorPicker: true,
    label: 'Add color',
    callback: (color: string | null, state: any, dispatch: any) => setCellAttr('background', color)(state, dispatch),
    icon: <TableColorIcon />,
  },
  {
    label: 'Add Column Before',
    callback: addColumnBefore,
    icon: <AddColumnBeforeIcon />,
  },
  {
    label: 'Add Column After',
    callback: addColumnAfter,
    icon: <AddColumnAfterIcon />,
  },
  {
    label: 'Delete Column',
    callback: deleteColumn,
    icon: <DeleteColumnIcon />,
  },
  {
    label: 'Add Row Before',
    callback: addRowBefore,
    icon: <AddRowBeforeIcon />,
  },
  {
    label: 'Add Row After',
    callback: addRowAfter,
    icon: <AddRowAfterIcon />,
  },
  {
    label: 'Delete Row',
    callback: deleteRow,
    icon: <DeleteRowIcon />,
  },
  {
    label: 'Delete Table',
    callback: deleteTable,
    icon: <DeleteTableIcon />,
  },
  {
    label: 'Merge Cells ',
    callback: mergeCells,
    icon: <MergeCellsIcon />,
  },
  {
    label: 'Split Cell',
    callback: splitCell,
    icon: <SplitCellsIcon />,
  },
] as Array<Record<string, any>>;

export const TableRowActions = [
  {
    label: 'Add row below',
    // This is weird, but it has to be reversed here
    callback: addRowAfter,
    icon: <AddRowBeforeIcon />,
  },
  {
    label: 'Add row above',
    // This is weird, but it has to be reversed here
    callback: addRowBefore,
    icon: <AddRowAfterIcon />,
  },
  {
    label: 'Merge cells ',
    callback: mergeCells,
    icon: <MergeCellsIcon />,
  },
  {
    label: 'Split cell',
    callback: splitCell,
    icon: <SplitCellsIcon />,
  },
  {
    label: 'Delete row',
    callback: deleteRow,
    labelColor: '#F04438',
    icon: <DeleteRowIcon fill="#F04438" />,
  },
] as Array<Record<string, any>>;

export const TableColActions = [
  {
    label: 'Add column before',
    callback: addColumnBefore,
    icon: <AddColumnBeforeIcon />,
  },
  {
    label: 'Add column after',
    callback: addColumnAfter,
    icon: <AddColumnAfterIcon />,
  },
  {
    label: 'Merge cells ',
    callback: mergeCells,
    icon: <MergeCellsIcon />,
  },
  {
    label: 'Split cell',
    callback: splitCell,
    icon: <SplitCellsIcon />,
  },
  {
    label: 'Delete column',
    callback: deleteColumn,
    labelColor: '#F04438',
    icon: <DeleteColumnIcon fill="#F04438" />,
  },
] as Array<Record<string, any>>;

export const ColorPickerNode = [
  {
    colorPicker: true,
    label: 'Add color',
    callback: (color: string | null, state: any, dispatch: any) => setCellAttr('background', color)(state, dispatch),
    icon: <TableColorIcon />,
  },
] as Array<Record<string, any>>;

export const ColorPickerTableAction = {
  colorPicker: true,
  label: 'Add color',
  callback: (color: string | null, state: any, dispatch: any) => setCellAttr('background', color)(state, dispatch),
  icon: <TableColorIcon />,
};

export const TableSelectionActions = [
  {
    label: 'Add column before',
    callback: addColumnBefore,
    icon: <AddColumnBeforeIcon />,
  },
  {
    label: 'Add column after',
    callback: addColumnAfter,
    icon: <AddColumnAfterIcon />,
  },
  {
    label: 'Delete column',
    callback: deleteColumn,
    labelColor: '#F04438',
    icon: <DeleteColumnIcon fill="#F04438" />,
  },
  {
    label: 'Add row below',
    callback: addRowAfter,
    icon: <AddRowBeforeIcon />,
  },
  {
    label: 'Add row above',
    callback: addRowBefore,
    icon: <AddRowAfterIcon />,
  },
  {
    label: 'Delete row',
    callback: deleteRow,
    labelColor: '#F04438',
    icon: <DeleteRowIcon fill="#F04438" />,
  },
  {
    label: 'Delete table',
    callback: (state: EditorState, dispatch?: ((tr: Transaction) => void) | undefined) => {
      tableControlsAdapter.closePreviewControls();
      return deleteTable(state, dispatch);
    },
    labelColor: '#F04438',
    icon: <DeleteTableIcon fill="#F04438" />,
  },
  {
    label: 'Merge cells',
    callback: mergeCells,
    icon: <MergeCellsIcon />,
  },
  {
    label: 'Split cells',
    callback: splitCell,
    icon: <SplitCellsIcon />,
  },
  {
    label: 'Left align',
    callback: (state: any, dispatch: any) => setCellAttr('text-align', 'left')(state, dispatch),
    icon: <SplitCellsIcon />,
  },
  {
    label: 'Center align',
    callback: (state: any, dispatch: any) => setCellAttr('text-align', 'center')(state, dispatch),
    icon: <SplitCellsIcon />,
  },
  {
    label: 'Right align',
    callback: (state: any, dispatch: any) => setCellAttr('text-align', 'right')(state, dispatch),
    icon: <SplitCellsIcon />,
  },
  // TODO text alignment
] as Array<Record<string, any>>;
