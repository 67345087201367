import { useEffect, useState } from 'react';

import { WindowMessage } from '../../shared/types/types';

function useWindowMessageReceiver<T>(defaultValue: T, type: string): { data: T } {
  const [data, setData] = useState<T>(defaultValue);

  useEffect(() => {
    window.addEventListener('message', (event: MessageEvent) => {
      const receivedMessage: WindowMessage = event.data;
      if (!isEventValid(event, type)) return;
      setData(receivedMessage.data);

      const ackMessage: WindowMessage = {
        id: receivedMessage.id,
        type: receivedMessage.type,
        sequence: receivedMessage.sequence,
        ack: true,
        data: undefined,
      };
      console.log(event.data);
      // TODO restrict origin
      window.parent.postMessage(ackMessage, '*');
    });
    // console.log('%cbind listener', 'color:red;');
  }, []);

  return { data };
}

export default useWindowMessageReceiver;

/** We check the type, which filters quite a lot. Can be `sidebar_state` */
const isEventValid = (event: MessageEvent, type: string) => event.data.type === type
  && event.data.id?.length > 0
  && event.data.sequence !== undefined;
  // && checkOrigin(event.origin); // We don't want to filter if they have been sent from
  // calendar or meet since now we have Shepherd in all URLs and sometimes we open
  // the sidebar from other pages
