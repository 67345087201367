import React, { Dispatch, HTMLProps, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../../shared/colors/COLORS';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import { header400 } from '../../../shared/typography';

const Container = styled.div<ContainerProps>`
  display: flex;
  cursor: pointer;
  border-radius: 6px;
  background: ${COLORS.surface};

  padding: 10px 12px 8px 12px; // Higher top padding to account for bottom border

  &:hover {
    background: ${COLORS.gray8};
  }

  ${({ isSelected }) => (!isSelected && css`
    &:hover {
      span {
        color: ${COLORS.gray4};
      } 
    } 
  `)}

  border-bottom: 4px solid ${COLORS.surface};
  border-color: rgba(111,111,111,0); // Make border transparent
  // to avoid the border from being visible when the button is not selected
  ${({ isSelected }) => (isSelected && css`
    /* border-bottom: 4px solid ${COLORS.blue6}; */
    border-radius: 4px 4px 0px 0px;
    transition: all 0.3s ease-in-out;
  `)}
`;

const BlueUnderLineBox = styled.div<ContainerProps>`
  height: 6px;
  width: 100%;
  background: ${COLORS.blue6};
  border-radius: 0px 0px 2px 2px;
  transition: all 0.3s ease-in-out;
  ${({ isSelected }) => (!isSelected && css`
    /* height: 0px; */
    background: ${COLORS.surface};
  `)}
`;

const SpacingContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
`;

const Text = styled.span<ContainerProps>`
  color: ${COLORS.gray1};
  ${header400};
  user-select: none;
`;

interface ContainerProps {
  isSelected: boolean,
}

interface Props {
  text: string,
  isSelected: boolean,
  tooltipText: string,
  setIsHover: Dispatch<SetStateAction<boolean>>,
  // eslint-disable-next-line react/require-default-props
  id?: string,
}

const PageTabButtonAtom = ({
  text, onClick, isSelected, tooltipText, setIsHover, id = 'hei',
}: Props & HTMLProps<HTMLElement>) => {
  const onMouseEnter = () => isSelected && setIsHover(true);

  const onMouseLeave = () => isSelected && setIsHover(false);

  return (
    <ReactTooltip tip={tooltipText} place="top">
      <SpacingContainer
        data-is-selected={isSelected}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isSelected={isSelected}
        id={id ?? 'unknown'}
      >
        <Container onClick={onClick} isSelected={isSelected}>
          <Text isSelected={isSelected}>{text}</Text>
        </Container>
        <BlueUnderLineBox isSelected={isSelected} />
      </SpacingContainer>
    </ReactTooltip>
  );
};

export default PageTabButtonAtom;
