import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { AuthContext, UserDataContext } from '../../../../App';
import Modal from '../../../../shared/components/Modal';
import {
  CALENDAR_SHARE_VIEW,
  EMAIL_SHARE_VIEW, NotesData, NotesSwitchData, NOTION_SHARE_VIEW, PlatformData,
  PlatformsMultiInputsData,
  ShareModalMode, SHARE_VIEW, SLACK_SHARE_VIEW, TRELLO_SHARE_VIEW,
} from './utils/ShareModalTypes';
import ModalBody from './Molecule/ModalBody';
import ModalFooterButtons from './Molecule/ModalFooterButtons';
import {
  defaultNotesSwitchData,
  generateShareNotesData,
  getModalTitle, getNotesSwitchData, getPlatformData, isSendButtonEnabled,
  mapModeToPlatformName, shareNotesToGoogleCalendarEventPrefix,
} from './utils';
import MeetingDataContext from '../../context/MeetingDataContext';
import {
  ResolvedState, SharePlatforms, TaskItem,
} from '../../../../shared/types/types';
import { makeSubstringsBold } from '../../../../utils/strings';
import { getUTCString } from '../../../../utils/dateUtils/date';
import {
  generateLog,
  generateNotionPage, generateSlackMessage, getMeetingSectionToShare, sendEmails, sendToTrello,
} from '../../../../utils/meetings/ShareNotesUtils';
import { toastDanger, toastSuccess } from '../../../../utils/notifications';
import { EmailRecipient } from '../ShareNotesModal';
import PreviewEmailModal from '../PreviewEmailModal';
import {
  EMAIL, GOOGLE_CALENDAR, NOTION, REJECTED, RESOLVED, SLACK, TRELLO,
} from '../../../../utils/enums';
import { appendTextToGoogleEventDescription } from '../../../../utils/google/GoogleCalendarAPI';
import { DropdownItem, mapDropdownItemToSlackChannel, mapSlackChannelToDropdownItem } from '../../../../shared/components/dropdown/utils';
import { assembleEmailHTML } from '../../../../external/Email/emailUtils';

const Container = styled.div`
 padding: 24px 24px 0px 24px ;
`;

const ModalFooterContainer = styled.div``;

interface Props {
  isOpen: boolean,
  handleCloseClick: () => void,
  taskItems: TaskItem[],
  notes: NotesData,
  setTriggerConfetti: Dispatch<SetStateAction<boolean>>,
  // eslint-disable-next-line no-unused-vars
  setCustomMessage: (message: string) => void,
}

const ShareNotesModal = ({
  isOpen, handleCloseClick, taskItems,
  notes, setTriggerConfetti, setCustomMessage,
}: Props) => {
  const { trackEvent } = useIntercom();

  const userData = useContext(UserDataContext);
  const { integrations } = userData;
  const authData = useContext(AuthContext);
  const { googleData: { ids: { eventId } } } = useContext(MeetingDataContext);
  const {
    version, data: { title, description },
    date: { start, end },
  } = useContext(MeetingDataContext);

  const [mode, setMode] = useState<ShareModalMode>(SHARE_VIEW);
  const [platformData, setPlatformData] = useState<PlatformData[]>([]);
  const [isExportButtonLoading, setIsExportButtonLoading] = useState<boolean>(false);
  const [isEmailPreviewModalOpen, setIsEmailPreviewModalOpen] = useState<boolean>(false);
  const [notesSwitchData, setNotesSwitchData] = useState<NotesSwitchData>(defaultNotesSwitchData);

  const [taskToSend, setTaskToSend] = useState<TaskItem[]>([]);
  const [emailRecipients, setEmailRecipients] = useState<EmailRecipient[]>([]);
  const [slackChannelsToReceive, setSlackChannelsToReceive] = useState<DropdownItem[]>([]);

  const platformsMultiInputsData: PlatformsMultiInputsData = {
    emailRecipients,
    setEmailRecipients,
    slackChannelsToReceive,
    setSlackChannelsToReceive,
    taskItems,
    taskToSend,
    setTaskToSend,
  };

  useEffect(() => {
    const updatedPlatformData = getPlatformData(integrations, setMode);
    setPlatformData(updatedPlatformData);
    const slackDefaultChannel = userData?.integrations?.slack[0]?.defaultChannels ?? [];
    if (slackDefaultChannel.length !== 0) {
      setSlackChannelsToReceive(mapSlackChannelToDropdownItem(slackDefaultChannel));
    }
  }, [integrations]);

  useEffect(() => {
    const updatedNotesSwitchData = getNotesSwitchData(version, notes.shared, notes.my, taskItems);
    setNotesSwitchData(updatedNotesSwitchData);
  }, [version, notes, taskItems]);

  const setupShareNotesData = (emailsToSend: EmailRecipient[] = []) => {
    const { isSharedNotesChecked, isPersonalNotesChecked, isTasksChecked } = notesSwitchData;
    const emails = emailsToSend.map(({ displayValue }) => ({ email: displayValue }));
    const emailHeadHtml = mode === CALENDAR_SHARE_VIEW ? '' : makeSubstringsBold(notes.head, [title, getUTCString(start.date)]);
    const notesAndTasksHtml = assembleEmailHTML(
      isSharedNotesChecked, isPersonalNotesChecked,
      isTasksChecked, emailHeadHtml,
      notes.shared, notes.my,
      taskItems,
    );
    return generateShareNotesData(
      authData,
      notesAndTasksHtml, title, start, notesSwitchData,
      emailHeadHtml, notes.head, notes.shared,
      notes.my, taskItems, emails,
    );
  };

  const handleExport = () => {
    setIsExportButtonLoading(true);
    const promises: Promise<any>[] = [];
    const platformsSharedToForLogging: SharePlatforms[] = [];

    if (mode === NOTION_SHARE_VIEW) {
      platformsSharedToForLogging.push(NOTION);
      const shareNotesData = setupShareNotesData();
      generateNotionPage(shareNotesData, integrations.notion, promises);
    }

    if (mode === EMAIL_SHARE_VIEW) {
      platformsSharedToForLogging.push(EMAIL);
      const shareNotesData = setupShareNotesData(emailRecipients);
      sendEmails(userData, shareNotesData, trackEvent, promises);
    }

    if (mode === SLACK_SHARE_VIEW) {
      platformsSharedToForLogging.push(SLACK);
      const shareNotesData = setupShareNotesData();
      const selectedSlackChannels = mapDropdownItemToSlackChannel(slackChannelsToReceive);
      generateSlackMessage(
        shareNotesData,
        selectedSlackChannels,
        integrations.slack[0].userAccessToken,
        promises,
      );
    }

    if (mode === TRELLO_SHARE_VIEW) {
      platformsSharedToForLogging.push(TRELLO);
      sendToTrello(true, taskToSend, integrations.trello, promises);
    }

    if (mode === CALENDAR_SHARE_VIEW) {
      platformsSharedToForLogging.push(GOOGLE_CALENDAR);
      const shareNotesData = setupShareNotesData();
      shareNotesData.html = shareNotesToGoogleCalendarEventPrefix + shareNotesData.html;
      promises.push(appendTextToGoogleEventDescription(eventId,
        authData.email, description, shareNotesData.html));
    }

    Promise.all(promises)
      .then(() => {
        handleLog(RESOLVED, platformsSharedToForLogging);
        setTriggerConfetti(true);
        toastSuccess('Success', `Notes successfully sent to ${mapModeToPlatformName(mode)}!`);
      })
      .catch((error: any) => {
        handleLog(REJECTED, platformsSharedToForLogging);
        console.error('error in sending', error);
        Sentry.captureException(error);
        toastDanger('Error', 'Something went wrong while sharing notes. If this persists, please contact customer support');
      })
      .finally(() => {
        setIsExportButtonLoading(false);
        handleClose();
      });
  };

  const handleLog = (status: ResolvedState, platformsToShare: SharePlatforms[]) => {
    const meetingSectionToShare = getMeetingSectionToShare(
      notesSwitchData.isSharedNotesChecked, notesSwitchData.isPersonalNotesChecked,
      notesSwitchData.isTasksChecked,
    );
    generateLog(authData.userId, platformsToShare, meetingSectionToShare, start, end, status);
  };

  const handleClose = () => {
    setIsEmailPreviewModalOpen(false);
    setMode(SHARE_VIEW);
    handleCloseClick();
  };

  const onPreviewClick = () => {
    setIsEmailPreviewModalOpen(true);
  };

  const isExportButtonDisabled = !isSendButtonEnabled(
    mode, platformsMultiInputsData, notesSwitchData,
  );

  return (
    <>
      <Modal
        title={getModalTitle(mode)}
        isOpen={isOpen}
        setModalClosed={handleClose}
        isScrollbarEnabled
      >
        <Container>
          <ModalBody
            mode={mode}
            setMode={setMode}
            platformData={platformData}
            platformsMultiInputsData={platformsMultiInputsData}
            notesSwitchData={notesSwitchData}
            setNotesSwitchData={setNotesSwitchData}
            customMessage={notes.head}
            setCustomMessage={setCustomMessage}
          />
        </Container>
        <ModalFooterContainer>
          <ModalFooterButtons
            mode={mode}
            setMode={setMode}
            handleExport={handleExport}
            isExportLoading={isExportButtonLoading}
            isExportButtonDisabled={isExportButtonDisabled}
            onPreviewClick={onPreviewClick}
          />
        </ModalFooterContainer>
      </Modal>
      <PreviewEmailModal
        isModalOpen={isEmailPreviewModalOpen}
        loading={isExportButtonLoading}
        onBackClick={() => { setIsEmailPreviewModalOpen(false); }}
        onSendClick={handleExport}
        notes={notes}
        notesSwitchData={notesSwitchData}
        customMessage={notes.head}
        taskItems={taskItems}
      />
    </>

  );
};

export default ShareNotesModal;
