import { PublicUserDataV2, ResolvedState, SlackNotificationsSettings } from '../../shared/types/types';
import { mapDatabaseUserDataToPublicUserDataV2 } from '../../utils/user/UserDataUtils';

class PublicUserDataV2Class {
  resolvedState: ResolvedState;

  userId: string;

  isShepherdUser: boolean;

  data: {
    name: string;
    email: string;
    firstName: string;
    lastName: string;
    photoUrl: string;
  }

  external: {
    email: {
      receiveTaskEmail: boolean;
    },
    slack: {
      hasEnabledSlack: boolean;
      notifications: SlackNotificationsSettings;
    },
    trello: {
      isTrelloEnabled: boolean;
      isAutoSyncEnabled: boolean;
      isAllowOtherToSyncEnabled: boolean;
      version: number;
    }
  }

  constructor(user: PublicUserDataV2) {
    this.resolvedState = user.resolvedState;
    this.userId = user.userId;
    this.isShepherdUser = user.isShepherdUser;
    this.data = user.data;
    this.external = user.external;
  }

  /**
   * Note: Not to be used in production
   */
  static createDummyUser = (
    name: string, email: string, photoUrl: string, userId: string,
  ): PublicUserDataV2 => {
    const user = mapDatabaseUserDataToPublicUserDataV2({}, userId);
    user.data.name = name;
    user.data.email = email;
    user.data.photoUrl = photoUrl;
    return user;
  };

  static getUserByUserId = async (userId: string): Promise<PublicUserDataV2Class> => {
    const user = await PublicUserDataV2Class.createDummyUser('name', 'email', 'photoUrl', userId);
    return new PublicUserDataV2Class(user);
  };
}

export default PublicUserDataV2Class;
