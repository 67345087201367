/* eslint-disable */
/*

   This file is in JS purposely, dependencies were giving error on TS.

   Logic here creates a custom markdown parser which grabs each token in MD version 
   of the notes and converts it to markdown slack supported block format.

   Lib majorly used here are markdown-it and markdown-it-block-image ( plugin to support block image)
   Doc: https://markdown-it.github.io/markdown-it/#Renderer.prototype.rules

   In short this code here creates custom rules to convert different markdown syntax to 
   slack supported syntax so it can be sent as a slack blocks at the end
   Doc for slack Blocks : https://api.slack.com/block-kit

*/

import MarkdownIt from 'markdown-it';
import markdownItBlockImage from 'markdown-it-block-image';
import {blockHeadingClose, blockHeadingOpen, hr} from './customBlocks'


/*

  Code here initializes  MarkdownIt parser and uses a preset 
  named `commonmark` and enables few text parsing styles.

  Uses markdownItBlockImage plugin to support block image format

*/

const customMD = new MarkdownIt('commonmark', {
  breaks: true,
  html: false,
  xhtmlOut: true,
  linkify: true,
});

customMD.enable(['strikethrough', 'linkify'])
.use(markdownItBlockImage, {
  outputContainer: 'div',
  containerClassName: null,
});

/*

  fallbackRenderer is called when any of our custom parsing fails, 
  this can happen when some invalid characters are encountered during 
  parsing or text styles which are not supported then fallbackRenderer
  converts it into default text formatting

*/

const fallbackRenderer = (tokens, idx, opts, _, self) => self.renderToken(tokens, idx, opts);


// Custom Rules for parsing 

const { blockquote_open, blockquote_close, image } = customMD.renderer.rules;


customMD.renderer.rules.heading_open = blockHeadingOpen;
customMD.renderer.rules.heading_close = blockHeadingClose;

customMD.renderer.rules.hr = hr;

customMD.renderer.rules.blockquote_open = function (...args) {
  customMD.renderer.rules.heading_open = () => '<p><b>';
  customMD.renderer.rules.heading_close = () => '</b></p>';
  customMD.renderer.rules.hr = () => '<p>────────────────────</p>';

  return (blockquote_open || fallbackRenderer).apply(this, args);
};

customMD.renderer.rules.blockquote_close = function (...args) {
  customMD.renderer.rules.heading_open = blockHeadingOpen;
  customMD.renderer.rules.heading_close = blockHeadingClose;
  customMD.renderer.rules.hr = hr;

  return (blockquote_close || fallbackRenderer).apply(this, args);
};

customMD.renderer.rules.image = () => '';

customMD.renderer.rules['block-image_open'] = () => {
  customMD.renderer.rules.image = (tokens, idx, options, env, self) => {
    const token = tokens[idx];
    const { renderToken } = self;

    token.tag = 'Image';

    try {
      self.renderToken = (tokens, idx, opts) => {
        const token = tokens[idx];
        token.attrSet(
          'alt',
          token.attrGet('alt') || token.attrGet('title') || 'Image',
        );

        return renderToken.call(self, tokens, idx, opts);
      };

      return image(tokens, idx, options, env, self);
    } catch{
      console.log('invalid img tag in md')
    }
    finally {
      self.renderToken = renderToken;
    }
  };
  return '';
};

customMD.renderer.rules['block-image_close'] = () => {
  customMD.renderer.rules.image = () => '';
  return '';
};

export default customMD
