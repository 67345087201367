import {
  blue6, cyan6, green6, purple6, red6,
} from '../shared/colors/COLORS';

// ======= AUTH STATE ========
export const LOGGED_IN_STATE = 'loggedIn';
export const LOGGED_OUT_STATE = 'loggedOut';
export const UNRESOLVED_STATE = 'unresolved';

// ======= MEETING SECTION TYPES  ========
/**
 * Within a meeting, you can be in different note tabs.
 * You can write both shared and private notes, and
 * look at tasks. This is the second layer of navigation
 * in the app. The top layer is pages
 *  (all_notes / current meeting / scratchpad)
 */
export const MEETING_SECTION = {
  AGENDA: 'agenda',
  SHARED_NOTES: 'shared',
  /** Also known as `my notes` */
  PERSONAL_NOTES: 'private',
  TASK: 'task',
  PRIVATE_NOTES: 'secret',
} as const;

// ======= TASK PAGES  ========
export const ALL_TASKS_PAGE = 'allTasks';
export const MEETING_PAGE = 'meeting';

// ======= TASK TABS  ========
export const TASK_TAB = {
  TODO: 'todo',
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed',
  OVERDUE: 'overdue',
} as const;

// ======= USER CENTER SECTION TYPES  ========
export const ALL_PAGES = {
  ALL_TASKS: 'all_tasks',
  // TODO: Maybe remove Meetings since i don't think we use it
  MEETINGS: 'meetings',
  CURRENT_MEETING: 'currentMeeting',
  PREV_MEETING: 'prevMeeting',
  TAGS: 'tags',
  ALL_NOTES: 'all_notes',
  SCRATCHPAD: 'scratchpad',
  BILLING: 'billing',
  // TODO: I think we can delete this
  JUST_SUBSCRIBED: 'just_subscribed',
} as const;

// ======= WINDOW MESSAGE TYPES  ========
export const SIDEBAR_STATE = 'SIDEBARE_STATE';
export const SIDEBAR_DISABLED_DOMAIN = 'SIDEBAR_DISABLED_DOMAIN';
export const REDIRECT_ROUTE = 'REDIRECT_ROUTE';
export const BUTTON_EVENT = 'BUTTON_EVENT';
export const PREVIOUS_MEETING_STATE = 'PREVIOUS_MEETING_STATE';

// ======= DEFAULT SECTION  ========
export const DEFAULT_SECTION = MEETING_SECTION.SHARED_NOTES;

// ======= TASK NOTIFICATION TYPES  ========
export const TASK_NOTIFICATION_ASSIGN = 'assign';
export const TASK_NOTIFICATION_DELETE = 'delete';
export const TASK_NOTIFICATION_UPDATE = 'update';

// ======= TASK UPDATE FIELDS  ========
export const TASK_UPDATE_FIELD_TITLE = 'title';
export const TASK_UPDATE_FIELD_DUE_DATE = 'dueDate';
export const TASK_UPDATE_FIELD_STATUS = 'status';
export const TASK_UPDATE_FIELD_DESCRIPTION = 'description';

// ============ Leaf Hex Colors ===============

export const PURPLE = purple6;
export const GREEN = green6;
export const CYAN = cyan6;
export const RED = red6;
export const BLUE = blue6;

// ============ Resolve State ===============

export const PENDING = 'pending';
export const RESOLVED = 'resolved';
export const REJECTED = 'rejected';

// ============ Sharing Platforms  ===============

export const EMAIL = 'email';
export const SLACK = 'slack';
export const NOTION = 'notion';
export const TRELLO = 'trello';
export const ASANA = 'asana';
export const GOOGLE_DOCS = 'googleDocs';
export const JIRA = 'jira';
export const GOOGLE_CALENDAR = 'googleCalendar';

// ============ Notification  ===============
export const SYNCED = 'synced';
export const NOT_SYNCED = 'not-synced';
export const NOTIFICATION = 'notification';

// ============ Devices  ===============
export const CHROME_EXTENSION = 'chrome_extension';
export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';

// ============ Browser  ===============
export const CHROME = 'chrome';
export const OPERA = 'opera';
export const EDGE = 'edge';
export const SAFARI = 'safari';
export const FIREFOX = 'firefox';
export const IE = 'internet_explorer';
export const UNKNOWN = 'unknown';

// ============ GapiResponseType  ===============
export const GAPI_RESPONSE_TYPE = {
  DECLINED: 'declined',
  NEEDS_ACTION: 'needsAction',
  ACCEPTED: 'accepted',
  TENTATIVE: 'tentative',
  /** If we can't detect the response status we set it to this */
  UNKNOWN: 'unknown',
};
