import moment from 'moment';
import { Quote } from '../../../shared/types/types';

export const quotes: Quote[] = [
  {
    author: '- Charles W. Scharf',
    quote: `Meetings should have as few
people as possible, but all the
right people.`,
  },
  {
    author: '- Stephen Covey',
    quote: `The key is not to prioritize
what’s on your schedule, but to
schedule your priorities`,
  },
  {
    author: '- Robin Sharma',
    quote: `Do not confuse activity with
productivity. Many people are
simply busy being busy`,
  },
  {
    author: '- Brian Tracy',
    quote: `Effective performance
is preceded by painstaking
preparation`,
  },
  {
    author: '- Tim Ferriss',
    quote: `Focus on being productive
instead of busy`,
  },
];

export const getQuoteForCertainDay = () => {
  const dayOfTheWeek = moment().isoWeekday() % 5; // so that it is always between 0 and 4
  return quotes[dayOfTheWeek];
};

export const emptyQuote: Quote = {
  author: '',
  quote: '',
};
