import React from 'react';
import styled from 'styled-components';
import { blue1, blue6 } from '../../../colors/COLORS';
import { header500 } from '../../../typography';
import SectionShortcutOptions from './SectionShortcutOptions';
import { ShortcutInfo } from '../../../types/types';

const ShortcutSectionTitle = styled.div`
  width: 100%;
  height: 56px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-left: 24px;

  background-color: ${blue1};

  ${header500};
  color: ${blue6};
`;

interface ShortcutSectionProps {
  title: string,
  shortcuts: ShortcutInfo[]
}

const ShortcutSection = ({ title, shortcuts }: ShortcutSectionProps) => (
  <>
    <ShortcutSectionTitle>
      {title}
    </ShortcutSectionTitle>
    <SectionShortcutOptions
      shortcuts={shortcuts}
    />
  </>
);

export default ShortcutSection;
