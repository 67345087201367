import styled from 'styled-components';
import { messageText } from '../../typography';

const ModalText = styled.p`
  ${messageText};
  margin: 0;
  margin-bottom: 16px;
`;

export default ModalText;
