import React from 'react';
import styled from 'styled-components';
import Font from '../../../typography/Font';

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  disabledSites: string[],
  // eslint-disable-next-line no-unused-vars
  onRemoveDisabledSite: (site: string) => void,
}

const DisabledSitesList = ({ disabledSites, onRemoveDisabledSite }: Props) => {
  if (disabledSites.length === 0) {
    return (
      <ItemContainer>
        <Font font="messageText"><i>No disabled sites</i></Font>
      </ItemContainer>
    );
  }

  return (
    <>
      {disabledSites.map((site) => (
        <ItemContainer key={site}>
          <Font font="defaultSmall" color="gray7">
            {site}
          </Font>
          <Font font="messageText" color="red6" onClick={() => onRemoveDisabledSite(site)} isButton>Remove</Font>
        </ItemContainer>
      ))}
    </>
  );
};

export default DisabledSitesList;
