import React from 'react';
import styled from 'styled-components';
import Font from '../../../../../shared/typography/Font';
import ForOnly7DollarsYouWillGetTextAtom from '../../../../all-pages/Billing/FirstPromptForExistingUsers/ForOnly7DollarsYouWillGetText';
import PremiumFeaturesListMol from '../../../../all-pages/Billing/FirstPromptForExistingUsers/PremiumFeaturesListMol';
import BillingModalLearnMore from '../../../../all-pages/Billing/LearnMore/BillingModalLearnMore';
import OnboardingWelcomeScreensModel from '../../utils/onboarding-welcome-screens-model';
import StartTrialCardMol from './StartTrialCardMol';
import {
  ImageDiv, Subtitle, Title, TitleContainer, VideoIntroductionScreenContainer,
} from './utils/styles';

const ImageContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 24px 0;
  @media (max-height: 920px) {
    display: none;
  };
`;

const FeaturesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  #forOnlySevenDollars {
    background: linear-gradient(180deg, #6772C8 0%, #D691E5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    margin-bottom: 25px;

    font-weight: 600;
  }

  margin-bottom: -5px;
`;

const DisplayNoneContainer = styled.div`
  @media (max-height: 690px) {
    display: none;
  };
  margin-bottom: 20px;
`;

const DisplayTwoContainer = styled.div`
  @media (max-height: 640px) {
    display: none;
  };
`;

const CardContainer = styled.div`
  display: flex;
  margin-top: 21px;
  max-width: 370px;
  min-width: 350px;
`;

const DisplayThreeContainer = styled.div`
  @media (max-height: 640px) {
    display: none;
  };
`;

interface BillingOnboardingStepTemplateProps {
  onClickTrial: () => void,
}

const BillingOnboardingStepTemplate = ({
  onClickTrial,
}: BillingOnboardingStepTemplateProps) => {
  const { title } = OnboardingWelcomeScreensModel.billing;
  const [isLearnMoreOpen, setIsLearnMoreOpen] = React.useState(false);

  const handleClickMore = () => {
    setIsLearnMoreOpen(!isLearnMoreOpen);
  };

  // Note: At the bottom of the page, from a different component,
  // there will be two buttons with "Free plan" and "Premium"

  return (
    <VideoIntroductionScreenContainer>
      <ImageContainer>
        <ImageDiv />
      </ImageContainer>
      <TitleContainer>
        <Title>{title}</Title>
        <Subtitle>
          Subscribe to our Premium plan with full access to all features
          {' '}
          <u>
            without any
          </u>
          {' '}
          limitations:
        </Subtitle>
      </TitleContainer>
      <FeaturesContainer>
        <DisplayNoneContainer>
          <ForOnly7DollarsYouWillGetTextAtom />
        </DisplayNoneContainer>
        <DisplayTwoContainer>
          <PremiumFeaturesListMol enableLearnMore onClickLearnMore={handleClickMore} />
        </DisplayTwoContainer>
      </FeaturesContainer>
      <DisplayThreeContainer>
        <Font color="gray6" font="messageText">Or</Font>
      </DisplayThreeContainer>
      <CardContainer>
        <StartTrialCardMol onClickTrial={onClickTrial} />
      </CardContainer>
      <BillingModalLearnMore isOpen={isLearnMoreOpen} onClickBack={handleClickMore} />
    </VideoIntroductionScreenContainer>
  );
};

export default BillingOnboardingStepTemplate;
