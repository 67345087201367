import React from 'react';
import styled from 'styled-components';
import SubTitleAtom from './SubTitleAtom';
import TitleAtom from './TitleAtom';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  & > span:first-of-type {
    margin-bottom: 20px;
  }
`;

interface Props {
  firstName: string,
}

const WelcomeBackMolecule = ({ firstName }: Props) => {
  const subtitleText = '👈  Select a meeting in ‘All notes’ or your calendar to take notes';

  return (
    <Container>
      <TitleAtom firstName={firstName} />
      <SubTitleAtom text={subtitleText} />
    </Container>
  );
};

export default WelcomeBackMolecule;
