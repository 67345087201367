import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { gray10, gray8 } from '../../../../../shared/colors/COLORS';
import FireworkConfetti from '../../../../../shared/confetti/FireworkConfetti';
import { header900, uiTextM } from '../../../../../shared/typography';
import HandGestureIcon from '../../images/integrations/HandGestureIcon';
import OnboardingWelcomeScreensModel from '../../utils/onboarding-welcome-screens-model';
import AllIntegrations from './components/AllIntegrations';

const ConnectIntegrations = () => {
  const { title, subtitle } = OnboardingWelcomeScreensModel.integration;
  const [isConfettiActive, setIsConfettiActive] = useState<boolean>(false);

  useEffect(() => {
    if (isConfettiActive) {
      setTimeout(() => {
        setIsConfettiActive(false);
      }, 4000);
    }
  }, [isConfettiActive]);

  return (
    <Container>
      <ImageContainer>
        <HandGestureIcon />
      </ImageContainer>
      <TitleContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>
      <AllIntegrations setIsConfettiActive={setIsConfettiActive} />
      <FireworkConfetti shouldTrigger={isConfettiActive} />
    </Container>
  );
};

export default ConnectIntegrations;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 125px - 80px);

  @media (max-height: 1025px) {
    transform: scale(0.95);
    transform-origin: top center;
  };

  @media (max-height: 975px) {
    transform: scale(0.90);
    transform-origin: top center;
  };
`;

const ImageContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 60px 0 20px 0;
  @media (max-height: 780px) {
    padding: 0;
  };
  @media (max-height: 712px) {
    display: none;
  };
`;

const TitleContainer = styled.div`
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  padding: 0 0 40px 0;

  @media (max-height: 712px) {
    padding-top: 40px;
  };
  @media (max-height: 520px) {
    padding: 10px;
  };
`;

const Title = styled.div`
  text-align: center;
  ${header900}
  color: ${gray10};
`;

const Subtitle = styled.div`
  ${uiTextM}
  color: ${gray8};

  margin-top: 15px;

  @media (max-height: 560px) {
    display: none;
  };
`;
