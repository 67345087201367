import { useStripe } from '@stripe/react-stripe-js';
import React, { useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { UserDataContext } from '../../../App';
import UserAPI from '../../../database/User/UserAPI';
import StripeAPI from '../../../external/Stripe/StripeAPI';
import { StripeConstants } from '../../../external/Stripe/StripeConstants';
import ConsoleImproved from '../../../shared/classes/ConsoleImproved';
import { RESOLVED } from '../../../utils/enums';
import useGetQueryParam from '../../../utils/hook/useGetParams';
import { QueryParameters } from '../../../utils/meetings/QueryUtils';
import { toastDanger } from '../../../utils/notifications';
import BillingModalFirstPromptExistingUsers from './FirstPromptForExistingUsers/BillingModalFirstPromptExistingUsers';
import BillingModalLearnMore from './LearnMore/BillingModalLearnMore';
import BillingModalHasStartedTrial from './TrialJustStartedModal/BillingModalHasStartedTrial';
import BillingModalWatchDemoOrganism from './WatchDemoModal/BillingModalWatchDemoOrg';

export type BillingModalStatus = 'closed' | 'firstPrompt' | 'trialJustStarted' | 'LearnMore' | 'WatchDemo';

const BillingModalCtrl = () => {
  const userData = useContext(UserDataContext);
  const stripe = useStripe();
  const { trackEvent } = useIntercom();
  const [status, setStatus] = useState<BillingModalStatus>('closed');

  useEffect(() => {
    if (userData.resolvedState !== RESOLVED) return;
    if (userData.isBillingInitialized()) {
      setStatus('closed');
      return;
    }
    console.log('firstPrompt', { userData, userSmart: userData });
    setStatus('firstPrompt');
  }, [userData]);

  useEffect(() => {
    if (userData.resolvedState !== RESOLVED) return;
    const upgradeNow = useGetQueryParam(QueryParameters.REDIRECTO_TO_UPGRADE);
    if (upgradeNow !== 'true') return;
    handleClickSubscribe();
    // eslint-disable-next-line no-restricted-globals
  }, [location, userData.resolvedState]);

  const handleClickTryForFree = () => {
    setStatus('trialJustStarted');
  };

  const handleClickSubscribe = async () => {
    ConsoleImproved.log('Clicked Subscribe', userData);
    try {
      await StripeAPI.createAndRedirectToCheckoutSession(
        userData, StripeConstants.CHECKOUT_ORIGINS.ExistingUserBeforeTrial,
        userData.workspace.numberOfMembers, trackEvent, stripe,
      );
    } catch (error: any) {
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const handleClickCloseInTrialJustStarted = async () => {
    await UserAPI.Billing.startTrial(userData, 'first_prompt_existing_users', trackEvent);
    setStatus('closed');
  };

  const handleClickWatchDemo = () => setStatus('WatchDemo');

  const handleClickBackFromWatchDemo = () => setStatus('trialJustStarted');

  if (status === 'firstPrompt') {
    return (
      <BillingModalFirstPromptExistingUsers
        isOpen
        onClickTrial={handleClickTryForFree}
        onClickSubscribe={handleClickSubscribe}
        onClickLearnMore={() => setStatus('LearnMore')}
      />
    );
  }

  if (status === 'trialJustStarted') {
    return (
      <BillingModalHasStartedTrial
        isOpen
        onClose={handleClickCloseInTrialJustStarted}
        onClickWatchModal={handleClickWatchDemo}
      />
    );
  }

  if (status === 'WatchDemo') {
    return (
      <BillingModalWatchDemoOrganism
        isOpen
        onClickBack={handleClickBackFromWatchDemo}
      />
    );
  }

  if (status === 'LearnMore') {
    return (
      <BillingModalLearnMore isOpen onClickBack={() => setStatus('firstPrompt')} />
    );
  }

  return null;
};

export default BillingModalCtrl;
