import EmailAPI from '../../external/Email/EmailAPI';
import MentionAPI from '../../external/Mentions/MentionAPI';
import SlackBoltAPI from '../../external/SlackBolt/SlackBoltAPI';
import SmartReturnData from '../../shared/classes/SmartReturnData';
import UserSmart from '../../shared/classes/UserSmart';
import { User, WorkspaceDb, WorkspacePermissionExpanded } from '../../shared/types/types';
import Logger from '../../utils/analytics/Logger/Logger';
import UserWorkspacesAPI from '../User/UserWorkspacesAPI';
import WorkspaceCoreAPI from './WorkspaceCoreAPI';
import WorkspaceData from './WorkspaceData';
import WorkspacesUtils from './WorkspacesUtils';

/**
 * Database access to the `/workspaces` collection
 */
class WorkspaceAPI extends WorkspaceCoreAPI {
  /**
   * Get all workspaces where `email` is a member
   */
  static getWorkspaces = async (email: string): Promise<WorkspaceData[]> => {
    console.log('getWorkspaces', email);
    return WorkspaceCoreAPI.coreGetWorkspaces(email);
  }

  static getWorkspaceByWorkspaceId = async (workspaceId: string, userId: string, user: User):
  Promise<SmartReturnData<WorkspaceData>> => {
    Logger.Workspaces.logGetWorkspace(workspaceId, userId);
    return WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId(workspaceId, user);
  }

  static listenToMyWorkspaces = (
    // eslint-disable-next-line no-unused-vars
    email: string, callback: (workspaces: WorkspaceData[]) => void,
    // eslint-disable-next-line no-unused-vars
    setIsLoading: (isLoading: boolean) => void,
  ) => {
    console.log('listenToMyWorkspaces', email);
    return WorkspaceCoreAPI.coreListenToMyWorkspaces(email, callback, setIsLoading);
  }

  static listenToWorkspace = (
    workspaceId: string,
    // eslint-disable-next-line no-unused-vars
    callback: (workspace: WorkspaceData) => void, setIsLoading: (isLoading: boolean) => void,
    user: User,
  ) => {
    if (workspaceId === 'pending') return () => {};
    console.log('listenToWorkspace', workspaceId);
    return WorkspaceCoreAPI.coreListenToWorkspace(workspaceId, callback, setIsLoading, user);
  };

  static createWorkspace = async (workspace: WorkspaceDb, userId: string) => {
    console.log('createWorkspace', workspace);
    Logger.Workspaces.logCreateWorkspace(userId);
    return WorkspaceCoreAPI.coreCreateWorkspace(workspace);
  }

  static createWorkspaceSimple = async (
    newWorkspaceName: string, creatorEmail: string, userId: string,
  ) => {
    const newWorkspace = WorkspacesUtils.createNewWorkspace(newWorkspaceName, creatorEmail);
    Logger.Workspaces.logCreateWorkspace(userId);
    SlackBoltAPI.logCreatedWorkspace(newWorkspaceName, creatorEmail);
    return WorkspaceCoreAPI.coreCreateWorkspace(newWorkspace);
  }

  static deleteWorkspace = async (workspaceId: string, user: UserSmart) => {
    console.log('deleteWorkspace', workspaceId);
    Logger.Workspaces.logDeletedWorkspace(user.userId);
    const workspace = await WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId(workspaceId, user);
    SlackBoltAPI.logDeletedWorkspace(workspace.value.name, user.email);
    await WorkspaceCoreAPI.coreDeleteWorkspace(workspaceId);
    // eslint-disable-next-line max-len
    setTimeout(() => UserWorkspacesAPI.resetSelectedWorkspaceIdIfItsGoingToBeDeleted(user, workspaceId), 2000);
  }

  static inviteMemberToWorkspace = async (
    workspaceId: string, workspaceName: string, email: string,
    recipientName: string, reporterEmail: string, reporterName: string,
    userId: string,
  ) => {
    console.log('inviteMemberToWorkspace', { workspaceId, email });
    SlackBoltAPI.logInvite(workspaceName, email, reporterEmail);

    const result = await WorkspaceCoreAPI.coreInviteMemberToWorkspace(workspaceId, email);
    if (result.isResolved) {
      Logger.Workspaces.logInviteMember(workspaceId, userId);

      const innerResult = await MentionAPI.sendWorkspaceInvitation(
        email, reporterEmail, workspaceId, workspaceName,
      );
      console.log('innerResult', innerResult);
      const emailResponse = await EmailAPI.sendWorkspaceInvite(
        email, recipientName, workspaceName, reporterName, reporterEmail,
      );
      console.log('emailResponse', emailResponse);
    }
    return result;
  }

  /**
   * If you want to remove someone you set the newPermission to 'null'
   */
  static setPermissionsOfMember = async (
    workspaceId: string, memberEmail: string, newPermission: WorkspacePermissionExpanded,
    userId: string,
  ) => {
    console.log('changePermissionsOfMember', { workspaceId, memberEmail, newPermission });
    await Logger.Workspaces.logChangePermission(workspaceId, memberEmail, newPermission, userId);
    await WorkspaceCoreAPI.coreChangePermissionsOfMember(workspaceId, memberEmail, newPermission);
    if (newPermission === null) {
      await WorkspaceAPI.considerSeatsForWorkspaceSubscription(workspaceId);
    }
  }

  static getInvitesByEmail = async (userId: string) => {
    const result = await WorkspaceCoreAPI.coreGetInvitesByEmail();
    Logger.Workspaces.logGetInvitesByEmail(userId);
    console.log('Result of getInvitesByEmail', result);
    return result;
  }

  static acceptInvite = async (workspaceId: string, userId: string) => {
    const result = await WorkspaceCoreAPI.coreAcceptInvite(workspaceId);
    Logger.Workspaces.logAcceptInvite(workspaceId, userId);
    console.log('Result of acceptInvite', result);
    return result;
  }

  static declineInvite = async (workspaceId: string, userId: string) => {
    const result = await WorkspaceCoreAPI.coreDeclineInvite(workspaceId);
    Logger.Workspaces.logDeclineInvite(workspaceId, userId);
    console.log('Result of declineInvite', result);
    return result;
  }

  static considerSeatsForWorkspaceSubscription = async (workspaceId: string) => {
    const result = await WorkspaceCoreAPI.considerSeatsForWorkspaceSubscription(workspaceId);
    console.log('Result of considerSeatsForWorkspaceSubscription', result);
    return result;
  }

  static removeYourselfFromWorkspace = async (workspaceId: string) => {
    const result = await WorkspaceCoreAPI.removeYourselfFromWorkspace(workspaceId);
    console.log('Result of removeYourselfFromWorkspace', result);
    return result;
  }
}

export default WorkspaceAPI;
