import { createContext } from 'react';
import { MeetingData } from '../../../types/MeetingData';
import { rejectedMeetingData } from '../../../../database/utils/templateMeetingData';

/**
 * We initialize it to `rejectedMeetingData`, since if we actually are in
 * a previous meeting, we will immediately set the value to `pendingMeetingData`
 * in
 * `src/shared/components/previous-meeting-notes/index.tsx`
 * with this code:
 * ```tsx
 * const [previousMeetingData, setPreviousMeetingData] = useState<MeetingData>(pendingMeetingData);
 * <PreviousMeetingDataContext.Provider value={previousMeetingData}>
 * ```
 * This way we can in our hook, `usePreviousMeetingData`,
 *  decide if we are in a previous meeting or not
 */
const PreviousMeetingDataContext = createContext<MeetingData>(rejectedMeetingData);

export default PreviousMeetingDataContext;
