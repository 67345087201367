import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface MagnifyingGlassProps {
  fill?: string;
  width?: number;
  height?: number;
}

const MagnifyingGlassIcon = ({
  fill,
  width,
  height,
}: MagnifyingGlassProps) => (
  <svg width={width} height={height} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 10.90465,10.15135 7.3541,6.60078 C 7.90508,5.88848 8.20313,5.01758 8.20313,4.10156 8.20313,3.00508 7.7752,1.97695 7.00137,1.20176 6.22754,0.426562 5.19668,0 4.10156,0 3.00645,0 1.97559,0.42793 1.20176,1.20176 0.426562,1.97559 0,3.00508 0,4.10156 c 0,1.09512 0.42793,2.12598 1.20176,2.89981 0.77383,0.77519 1.80332,1.20176 2.8998,1.20176 0.91602,0 1.78555,-0.29805 2.49785,-0.84766 l 3.55064,3.54918 c 0.0104,0.0105 0.0227,0.0187 0.0363,0.0244 0.0136,0.0056 0.0282,0.0085 0.0429,0.0085 0.0148,0 0.0294,-0.0029 0.043,-0.0085 0.0136,-0.0057 0.0259,-0.0139 0.0363,-0.0244 l 0.5961,-0.5947 c 0.0105,-0.0104 0.0187,-0.0228 0.0244,-0.0364 0.0056,-0.0136 0.0085,-0.0282 0.0085,-0.0429 0,-0.0147 -0.0029,-0.0293 -0.0085,-0.0429 -0.0057,-0.0136 -0.0139,-0.026 -0.0244,-0.0364 z M 6.26719,6.26719 C 5.6875,6.84551 4.91914,7.16406 4.10156,7.16406 3.28398,7.16406 2.51563,6.84551 1.93594,6.26719 1.35762,5.6875 1.03906,4.91914 1.03906,4.10156 c 0,-0.81758 0.31856,-1.5873 0.89688,-2.16562 0.57969,-0.57832 1.34804,-0.89688 2.16562,-0.89688 0.81758,0 1.58731,0.31719 2.16563,0.89688 0.57832,0.57969 0.89687,1.34804 0.89687,2.16562 0,0.81758 -0.31855,1.58731 -0.89687,2.16563 z"
      fill={fill}
    />
  </svg>
);

MagnifyingGlassIcon.defaultProps = {
  fill: darkBlue4,
  width: 11,
  height: 11,
};

export default MagnifyingGlassIcon;
