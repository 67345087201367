/* eslint-disable indent */
import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom-v5-compat';
import { makeMeetingUrl } from '../../utils/meetings/meetingsUtils';
import { AuthContext, UserDataContext } from '../../App';
import {
  GapiMeetingData, MeetingAnalyticsData,
  Shortcut, Page, MeetingSection,
  TemplateData, User,
  TaskTab, TaskItems, PrivateNoteData, RelevantMeetingsData,
} from '../../shared/types/types';
import { MeetingData } from '../../shared/types/MeetingData';
import dbListenToMeetingData from '../../database/Meetings/firebaseMeetingAPI';
import { logExternalSourceVisit, logPageEvent, mapReferenceTrackingToExternalSource } from '../../utils/analytics/eventLogger';
import { dbListenForMeetingAnalyticsData } from '../../database/firebaseAnalyticsAPI';
import INIT_MEETING_ANALYTICS_DATA from '../../utils/analytics/analyticsDataObjects';
import { dbListenToTasksForMeeting } from '../../database/firebaseTasksAPI';
import { getUnviewedTasksAssignedToMe } from '../../utils/tasks/tasksUtils';
import { leftKeyNavigation, rightKeyNavigation } from '../../shared/components/firepad/shortcutUtils';
import { pendingGapiMeetingData } from '../../database/utils/gapiMeetingDataUtils';
import { pendingMeetingData } from '../../database/utils/templateMeetingData';
import { dbListenToPrivateNotesData } from '../../database/firebasePrivateNotesAPI';
import { SidebarStateContext } from '../../ListenToSidebarMessageContainer';
import { MEETING_PAGE, UPDATE_EVENT } from '../../utils/analytics/enums';
import { updateFriendListV2 } from '../../../src/external/FriendListV2/dbFriendListV2Api';
import MeetingPageTemplate from './MeetingPageTemplate';
import MeetingLoadingPage from '../../shared/components/loading/shepherd-logo-loading-page/MeetingLoadingPage';
import useGetQueryParam from '../../utils/hook/useGetParams';
// import useFirstUserLoadEffect from '../../utils/user/useFirstUserLoadEffect';
import GAPI from '../../external/GoogleAPI/GAPI';
import DashboardWelcomePage from '../dashboard-welcome-page';
import { REJECTED, RESOLVED, ALL_PAGES } from '../../utils/enums';
import { toastWarning } from '../../utils/notifications';
import resetTitle from './MeetingHelperFunctions';
import Mixpanel from '../../utils/analytics/Mixpanel';
import { shouldWeLog } from '../../utils/analytics/functions';
import AlgoliaAPI from '../../database/Algolia/AlgoliaAPI';
import RelMeetUtils from '../../shared/components/MeetingDetailsHeader/RelevantMeetingComponent/RelevantMeetingsUtils';
import UsageLimitModalCtrl from '../all-pages/Billing/UsageLimitModal/UsageLimitModalCtrl';
import ROUTES from '../../routes/ROUTES_CONSTANTS';
import UserAPI from '../../database/User/UserAPI';

type Props = {
  meetingId: string,
  page: Page,
  // eslint-disable-next-line no-unused-vars
  setPage: (page: Page) => void,
  taskTab: TaskTab,
  setTaskTab: Dispatch<SetStateAction<TaskTab>>,
  taskItems: TaskItems,
  setTaskItems: Dispatch<SetStateAction<TaskItems>>,
  meetingTab: MeetingSection,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
  isTemplatesOpen: boolean,
  isEditTemplateEnabled: boolean,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
  selectedTemplateToEdit: TemplateData,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  isRecurringMeeting: boolean,
  meetingData: MeetingData,
  setMeetingData: Dispatch<SetStateAction<MeetingData>>,
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>,
  setPrivateNoteOpen: Dispatch<SetStateAction<boolean>>,
  activePrivateNoteId: string,
  /* eslint-disable-next-line */
  onClickSelectPrivateNote: (chatId: string) => void,
  privateNotes: PrivateNoteData[],
  setPrivateNotes: Dispatch<SetStateAction<PrivateNoteData[]>>,
}

const Meeting = ({
  meetingId,
  page,
  setPage,
  taskTab,
  setTaskTab,
  taskItems,
  setTaskItems,
  meetingTab,
  setMeetingTab,
  isTemplatesOpen,
  isEditTemplateEnabled,
  setIsTemplatesOpen,
  setSelectedTemplateToEdit,
  selectedTemplateToEdit,
  setCreateTaskModalOpen,
  isRecurringMeeting,
  meetingData,
  setMeetingData,
  setProseMirrorEditorView,
  setPrivateNoteOpen,
  privateNotes,
  setPrivateNotes,
  activePrivateNoteId,
  onClickSelectPrivateNote,
}: Props) => {
  const referenceTracking: string = useGetQueryParam('ref');
  const authState = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const [meetingAnalyticsData,
    setMeetingAnalyticsData] = useState<MeetingAnalyticsData>(INIT_MEETING_ANALYTICS_DATA);
  const [gapiMeetingData, setGapiMeetingData] = useState<GapiMeetingData>(pendingGapiMeetingData);
  // indicates if data in notes/tasks is synchronized
  const [membersInvited, setMembersInvited] = useState<boolean>(false);
  const [relevantMeetingsData, setRelevantMeetingsData] = useState<RelevantMeetingsData>(
    RelMeetUtils.pendingData,
  );
  const [isUsageLimitModalOpen, setIsUsageLimitModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const isSidebarOpen = useContext(SidebarStateContext);
  const meetingDataVersion = meetingData.version;

  const { trackEvent } = useIntercom();

  const isLinkedFromExternalSource = (reference: string) => {
    const externalSource = mapReferenceTrackingToExternalSource(reference);

    if (externalSource === 'unknown') return false;
    return true;
  };
  useHotkeys('ctrl+right', () => rightKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('option+right', () => rightKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('ctrl+left', () => leftKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('option+left', () => leftKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('alt+t', () => setCreateTaskModalOpen(true), {}, [meetingTab, meetingDataVersion]);

  useEffect(() => {
    if (!(isValidMeetingId(meetingId) && authState.userState === 'loggedIn')) return () => { };

    const unsub1 = dbListenToPrivateNotesData(
      meetingId, authState.userId, authState.email, setPrivateNotes,
    );
    setGapiMeetingData(pendingGapiMeetingData);
    setMeetingData(pendingMeetingData);
    const unsub2 = dbListenToMeetingData(
      meetingId, authState.userId, setMeetingData, setGapiMeetingData,
    );
    return () => {
      if (unsub1) unsub1();
      if (unsub2) unsub2();
    };
  }, [meetingId, authState]);

  useEffect(() => {
    if (!(isValidMeetingId(meetingId) && isValidEmail(userData.data.email))) return () => { };
    return dbListenToTasksForMeeting(meetingId, userData.data.email, setTaskItems);
  }, [meetingId, userData.data.email]);

  useEffect(() => {
    if (!isValidEventAndCalendarIdAndUserData(meetingData, userData)) return;
    GAPI.getGapiDataForMeeting(meetingData, userData, setGapiMeetingData);
  }, [meetingData.googleData.ids.eventId, meetingData.attendees.resolvedState,
    userData.resolvedState]);

  // TODO: Check if this is needed or not. Maybe I had a good reason to have it here that
  // I have yet to remember.
  // useFirstUserLoadEffect(() => {
  //   const meetingSectionQueryParam = useGetQueryParam('meetingSection');
  //   if (isValidMeetingSection(meetingSectionQueryParam)) return;
  //   setMeetingSectionCarefully(
  //     userData.settings.defaultUserTab, meetingData.version, setMeetingTab,
  //   );
  // }, [userData]);

  useEffect(() => {
    if (!membersInvited) return;
    setTimeout(() => {
      setMembersInvited(false);
    }, 5000);
  }, [membersInvited]);

  useEffect(() => {
    updateFriendListV2(meetingData, userData);
  }, [meetingData, userData]);

  useEffect(() => {
    if (meetingId.length === 0) return () => { };
    return dbListenForMeetingAnalyticsData(meetingId, setMeetingAnalyticsData);
  }, [meetingId]);

  useEffect(() => {
    if (!shouldWeLog(userData, isSidebarOpen)) return;
    logAnalytics();
  }, [meetingId, authState, isSidebarOpen]);

  useEffect(() => {
    if (!shouldWeLog(userData, isSidebarOpen)) return;
    setIsUsageLimitModalOpen(false);
    // 1. If on free plan, and is more than 9 meetings last 30 days, and meeting
    // is not in meeting used list, then open modal

    UserAPI.Billing
      .openPayWallIfUserIsOnFreePlanAndExceededMeetings(
        userData, meetingData, setIsUsageLimitModalOpen,
      );
  }, [meetingData.meetingId, userData.billing]);

  useEffect(() => {
    if (!isLinkedFromExternalSource(referenceTracking)) return;
    logExternalSourceVisit(referenceTracking, trackEvent);
  }, [referenceTracking]);

  /* later to set user in groups

  useEffect(() => {
    if (sidebarState && meetingData.meetingId.length !== 0) {
                setMixpanelMeetingGroup(meetingData);
    }
  }, [sidebarState, meetingData.meetingId]); */

  const logAnalytics = () => {
    logPageEvent(authState.userId, trackEvent, MEETING_PAGE, meetingId);
  };

  const closeTemplatesView = () => {
    setIsTemplatesOpen(false);
  };

  const handleShortcutTrigger = (shortcut: Shortcut) => {
    if (shortcut === 'navigateLeft') {
      leftKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion);
    }
    if (shortcut === 'navigateRight') {
      rightKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion);
    }
    if (shortcut === 'openCreateTask') {
      setCreateTaskModalOpen(true);
    }
  };

  const updateTitle = async (newTitle: string) => {
    if (newTitle === gapiMeetingData.summary) return;
    Mixpanel.log(userData.userId, UPDATE_EVENT, {
      field: 'title', length: newTitle.length, source: 'InMeeting', isQuickNote: meetingData.data.title.includes('Quick Note'),
    });
    const response = await GAPI.updateTitle(newTitle, gapiMeetingData, meetingData.meetingId);
    if (response === REJECTED) {
      toastWarning('Failed to update title', 'Please try again. If this error persists, please contact support.', 20000);
      // Resetting old title in meetingData to prevent UI from showing wrong title
      resetTitle(meetingData, setMeetingData);
    }
  };

  useEffect(() => {
    if (meetingData.resolvedState !== RESOLVED) return;
    setRelevantMeetingsData(RelMeetUtils.pendingData);

    AlgoliaAPI.findRelevantMeetings(meetingData, userData, setRelevantMeetingsData);
  }, [meetingData.resolvedState, meetingData.data.title, authState]);

  const clickRelevantMeeting = (meeting: MeetingData) => {
    console.log('clicking relevant meeting');
    console.log(meeting);
    navigate(makeMeetingUrl(meeting.meetingId));
  };

  const handleClickFree = () => {
    navigate(ROUTES.dashboardPath);
    setPage(ALL_PAGES.ALL_NOTES);
  };

  const unviewedTasksAssignedToMe = getUnviewedTasksAssignedToMe(taskItems.tasks, authState.email);
  const calendarError = gapiMeetingData.resolvedState === 'rejected';
  const attendeesLoading = meetingData.attendees.resolvedState === 'pending';

  if (page !== ALL_PAGES.CURRENT_MEETING) return null;

  if (meetingId.length === 0) return <DashboardWelcomePage />;

  if (meetingData.resolvedState === 'pending') {
    return <MeetingLoadingPage text="Fetching Meeting Data..." />;
  }

  return (
    <>
      <MeetingPageTemplate
        setPage={setPage}
        taskTab={taskTab}
        setTaskTab={setTaskTab}
        meetingData={meetingData}
        meetingCreator={gapiMeetingData.creator.email}
        meetingAnalyticsData={meetingAnalyticsData}
        attendeesLoading={attendeesLoading}
        calendarError={calendarError}
        setMembersInvited={setMembersInvited}
        closeTemplatesView={closeTemplatesView}
        unviewedTasksAssignedToMe={unviewedTasksAssignedToMe}
        meetingDataVersion={meetingDataVersion}
        authState={authState}
        meetingId={meetingId}
        handleShortcutTrigger={handleShortcutTrigger}
        membersInvited={membersInvited}
        taskItems={taskItems}
        meetingTab={meetingTab}
        setMeetingTab={setMeetingTab}
        isTemplatesOpen={isTemplatesOpen}
        setIsTemplatesOpen={setIsTemplatesOpen}
        setCreateTaskModalOpen={setCreateTaskModalOpen}
        isRecurringMeeting={isRecurringMeeting}
        setProseMirrorEditorView={setProseMirrorEditorView}
        setPrivateNoteOpen={setPrivateNoteOpen}
        privateNotes={privateNotes}
        setPrivateNotes={setPrivateNotes}
        activePrivateNoteId={activePrivateNoteId}
        onClickSelectPrivateNote={onClickSelectPrivateNote}
        isEditTemplateEnabled={isEditTemplateEnabled}
        setSelectedTemplateToEdit={setSelectedTemplateToEdit}
        selectedTemplateToEdit={selectedTemplateToEdit}
        updateTitle={updateTitle}
        relevantMeetings={relevantMeetingsData}
        clickRelevantMeeting={clickRelevantMeeting}
      />
      <UsageLimitModalCtrl isOpen={isUsageLimitModalOpen} onClickFree={handleClickFree} />
    </>
  );
};

export default Meeting;

function isValidMeetingId(meetingId: string) {
  return meetingId.length > 10;
}

function isValidEmail(email: string) {
  return email.length > 4;
}

const isValidEventAndCalendarIdAndUserData = (
  meetingData: MeetingData,
  userData: User,
) => {
  if (!isValidMeetingId(meetingData.meetingId)) return false;
  if (meetingData.resolvedState !== 'resolved') return false;
  if (meetingData.googleData.ids.eventId.length === 0
    || meetingData.googleData.ids.calendarId.length === 0) return false;
  if (userData.resolvedState !== 'resolved') return false;
  if (meetingData.attendees.resolvedState !== 'resolved') return false;
  return true;
};
