import React from 'react';
import { COLORS } from '../colors/COLORS';

interface Props {
  size?: number,
  color?: string,
}

const InfoQuestionIcon = ({ size, color }: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.4758 12.522C11.4758 12.354 11.5318 12.21 11.6438 12.09C11.7638 11.97 11.9078 11.91 12.0758 11.91C12.4198 11.902 12.7278 11.838 12.9998 11.718C13.2718 11.598 13.4878 11.418 13.6478 11.178C13.8078 10.938 13.8878 10.638 13.8878 10.278C13.8878 9.96603 13.8158 9.69803 13.6718 9.47403C13.5358 9.24203 13.3478 9.06203 13.1078 8.93403C12.8678 8.79803 12.5958 8.73003 12.2918 8.73003C12.0278 8.73003 11.7838 8.78203 11.5598 8.88603C11.3358 8.99003 11.1358 9.12603 10.9598 9.29403C10.8558 9.39003 10.7278 9.46203 10.5758 9.51003C10.4318 9.55003 10.2838 9.51403 10.1318 9.40203C9.97176 9.29003 9.88376 9.15003 9.86776 8.98203C9.85176 8.81403 9.91176 8.65803 10.0478 8.51403C10.3198 8.21003 10.6518 7.97003 11.0438 7.79403C11.4358 7.61803 11.8518 7.53003 12.2918 7.53003C12.8118 7.53003 13.2838 7.65003 13.7078 7.89003C14.1318 8.13003 14.4678 8.45403 14.7158 8.86203C14.9638 9.27003 15.0878 9.74203 15.0878 10.278C15.0878 10.646 15.0278 10.982 14.9078 11.286C14.7878 11.59 14.6158 11.854 14.3918 12.078C14.1678 12.302 13.9078 12.482 13.6118 12.618C13.3238 12.754 13.0078 12.842 12.6638 12.882C12.6478 12.89 12.6398 12.894 12.6398 12.894C12.6478 12.894 12.6598 12.894 12.6758 12.894V13.614C12.6758 13.782 12.6158 13.926 12.4958 14.046C12.3838 14.158 12.2438 14.214 12.0758 14.214C11.8998 14.214 11.7558 14.158 11.6438 14.046C11.5318 13.926 11.4758 13.782 11.4758 13.614V12.522Z" fill={color} />
    <path d="M11.995 15.7961H12.005" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.47779 6.47779 2 12 2C17.5229 2 22 6.47745 22 12C22 17.5225 17.5229 22 12 22C6.47779 22 2 17.5222 2 12ZM20.5 12C20.5 7.30586 16.6944 3.5 12 3.5C7.30621 3.5 3.5 7.30621 3.5 12C3.5 16.6938 7.30621 20.5 12 20.5C16.6944 20.5 20.5 16.6941 20.5 12Z" fill={color} />
  </svg>
);

InfoQuestionIcon.defaultProps = {
  size: 24,
  color: COLORS.gray1,
};

export default InfoQuestionIcon;
