import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useIntercom } from 'react-use-intercom';
import * as Sentry from '@sentry/browser';
import { TaskItem, TrelloData, Page } from '../../types/types';
import TaskDropdown from './TaskDropdown';
import TaskOperationButton from './TaskOperationButton';
import TaskOperationDropdownOptions from './TaskOperationDropdownOptions';
import TaskDeletionConfirmModal from './TaskDeletionConfirmModal';
import { dbCreateTask, dbUpdateTask } from '../../../database/firebaseTasksAPI';
import { AuthContext, UserDataContext } from '../../../App';
import { toastDanger, toastInfo } from '../../../utils/notifications';
import TaskDetailsModal from '../task-modal/TaskDetailsModal';
import { logTasksUserAction } from '../../../utils/analytics/eventLogger';
import { getDuplicatedTaskWithNewUpdatedTime } from '../../../utils/tasks/tasksUtils';
import { updateTrelloCard } from '../../../utils/trello/trelloUtils';
import {
  EDIT_EVENT, GO_TO_MEETING_FROM_TASK,
  TASKS_MULTIPLE_FIELD, TASKS_SETTINGS_LOCATION,
} from '../../../utils/analytics/enums';
import { slackCoreAPISendNotificationForTaskUpdate } from '../../../utils/slack/SlackCoreAPI';
import EmailAPI from '../../../external/Email/EmailAPI';
import usePreviousMeetingData from '../../../utils/hook/usePreviousMeetingData';
import QueryUtils, { QueryParameters } from '../../../utils/meetings/QueryUtils';

interface Props {
  taskItem: TaskItem,
  // eslint-disable-next-line no-unused-vars
  setPage: (page: Page) => void,
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
  disableGoToMeeting?: boolean,
  enable?: boolean,
}

const TaskSettingsThreeDots = ({
  taskItem, disableAssigneeSelect, setPage,
  disableCalendarSelect, disableGoToMeeting, enable,
}: Props) => {
  const [isTaskDetailsOpen, setTaskDetailsOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDetailsDropdownOpen, setIsDetailsDropdownOpen] = useState<boolean>(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const userData = useContext(UserDataContext);
  const openTaskDetailsModal = () => setTaskDetailsOpen(true);
  const navigate = useNavigate();
  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);
  const { isInPreviousMeeting } = usePreviousMeetingData();

  const handleDuplicateClick = () => {
    const duplicateTask = getDuplicatedTaskWithNewUpdatedTime(taskItem);
    dbCreateTask(authState, duplicateTask, trackEvent, true)
      .then(() => {
        EmailAPI
          .cfSendTaskAssignNotificationEmail(duplicateTask, userData.data.name, userData.data.email)
          .catch((error) => toastDanger('Failed to send notification', error));
      });
  };

  const handleGoToMeetingClick = () => {
    const taskMeetingId = taskItem.meeting.meetingId;
    const currentMeetingId = window.location.pathname.split('/')[2];
    if (taskMeetingId === currentMeetingId) {
      toastInfo('Already in meeting', 'You are already in the meeting the task was made in');
      return;
    }
    if (isInPreviousMeeting) {
      toastInfo('Cannot redirect to meeting', 'We cannot redirect to a different meeting in the previous meeting view. Please switch to the main window and retry the action to redirect to the meeting.');
      return;
    }
    console.log(`Redirecting to meeting ${taskMeetingId}`);
    setPage('currentMeeting');
    navigate(`/meeting/${taskMeetingId}?${QueryParameters.MEETING_SECTION}=task&${QueryUtils.taskTabParameter(taskItem)}`);
    logTasksUserAction(authState.userId, GO_TO_MEETING_FROM_TASK, TASKS_SETTINGS_LOCATION, '', trackEvent);
  };

  const handleUpdateTaskItem = (
    newTaskItem: TaskItem,
    trelloData: TrelloData = userData.integrations.trello,
    isTrelloSyncChecked: boolean,
    callback: any,
    updatedSection: string,
  ) => {
    const newTaskItemToUpdate: TaskItem = newTaskItem;
    newTaskItemToUpdate.integrations.trello.isTrelloSyncEnabled = isTrelloSyncChecked;
    dbUpdateTask(taskItem.taskId, newTaskItemToUpdate, callback).then(() => {
      updateTrelloCard(trelloData, newTaskItemToUpdate as TaskItem, 'many', 'all');
      toastInfo('Updated', 'Task updated');
      EmailAPI.cfSendTaskUpdateNotificationEmail(newTaskItem, userData)
        .catch((error) => toastDanger('Failed to send notification', error));
      slackCoreAPISendNotificationForTaskUpdate(
        newTaskItemToUpdate,
        authState,
        updatedSection,
      );
      logTasksUserAction(
        authState.userId, EDIT_EVENT,
        TASKS_SETTINGS_LOCATION, TASKS_MULTIPLE_FIELD, trackEvent,
      );
    }).catch((error) => {
      console.log(taskItem.taskId);
      console.log(taskItem);
      console.error(error);
      Sentry.captureException(error);
      toastDanger('Task Could Not Be Updated', error.message);
    });
  };

  if (!enable) return <></>;

  return (
    <>
      <TaskDropdown
        options={TaskOperationDropdownOptions(
          {
            id: taskItem.taskId,
            onDeleteClick: openDeleteModal,
            onDuplicateClick: handleDuplicateClick,
            onTaskDetailsClick: openTaskDetailsModal,
            onGoToMeetingClick: handleGoToMeetingClick,
            disableGoToMeeting: disableGoToMeeting || taskItem.meeting.meetingId.length === 0,
          },
        )}
        willCloseAfterClick
        setIsOpen={setIsDetailsDropdownOpen}
      >
        <TaskOperationButton isOpen={isDetailsDropdownOpen} />
      </TaskDropdown>
      <TaskDetailsModal
        taskItem={taskItem}
        updateTaskItem={handleUpdateTaskItem}
        isOpen={isTaskDetailsOpen}
        setOpen={setTaskDetailsOpen}
        disableCalendarSelect={disableCalendarSelect}
        disableAssigneeSelect={disableAssigneeSelect}
        openDeleteModal={openDeleteModal}
      />
      <TaskDeletionConfirmModal
        isOpen={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        taskItem={taskItem}
        analyticsFrom={TASKS_SETTINGS_LOCATION}
      />
    </>
  );
};

TaskSettingsThreeDots.defaultProps = {
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
  disableGoToMeeting: false,
  enable: true,
};

export default TaskSettingsThreeDots;
