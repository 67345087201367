/* eslint-disable react/static-property-placement */
import React, { RefObject } from 'react';
import styled, { css } from 'styled-components';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { COLORS } from '../../../shared/colors/COLORS';
import { GapiMeetingData, ScrollState } from '../../../shared/types/types';
import DayLabelAtom, { getDay } from '../DayLableAtom';
import AllNotesBottomButtonToFetchMoreMeetings from './AllNotesBottomButtonToFetchMoreMeetings';
import NoteItemMolecule from './NoteItemMolecule';
import { CSS_CONSTANTS } from '../../../shared/CSS/CSS_Constants';
import { StringUtils } from '../../../utils/strings';
import Font from '../../../shared/typography/Font';

/**
 * We need this container to assign the id
 * so that when we scroll we leave a bit of space
 * on the top of the day label
 */
const DayContainerTop = styled.div`
  max-width: ${CSS_CONSTANTS.MAX_WIDTH_CONTENT};
  padding-top: 24px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding-bottom: 20px;
`;

interface DayContainerProps {
  day: string,
}

const DayContainerInner = styled.div<DayContainerProps>`

  ${({ day }) => getDay(day) === 'Today' && css` 
    box-shadow: 0px 2px 8px rgb(27 33 36 / 16%);
    margin-left: 5px;
    margin-right: 24px;
    /* We need the padding to see the box shadow */
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 10px;
    /* background-color: ${COLORS.layoutWhite}; */
    background-color: ${COLORS.blue1};
  `}

  border-radius: 18px;

  #noMeetings {
    margin-left: 8px;
  }
`;

interface Props {
  isFetchMoreMeetingsBtnEnable: boolean;
  groupedNotes: { [date: string]: GapiMeetingData[] };
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onAction: (eventId: string, calendarId: string, startDateTime: string) => void,
  // eslint-disable-next-line no-unused-vars, react/no-unused-prop-types
  onScroll: (status: ScrollState) => void,
  onClickFetchMoreMeetings: () => void;
}

export class NotesListOrganism extends React.Component<Props, any> {
  scrollRef: RefObject<OverlayScrollbarsComponent>;

  props: Props;

  constructor(props: Props) {
    super(props);
    this.props = props;
    this.scrollRef = React.createRef<OverlayScrollbarsComponent>();
  }

  onScrollStop = () => {
    const { onScroll } = this.props;
    if (this?.scrollRef?.current!.osInstance()?.scroll().position.y === 0) {
      onScroll('scrollTop');
    } else if (
      this?.scrollRef?.current!.osInstance()?.scroll().position.y
      === this?.scrollRef?.current!.osInstance()?.scroll().max.y) {
      onScroll('scrollBottom');
    } else {
      onScroll('scrollUpdate');
    }
  }

  render() {
    const {
      groupedNotes, onAction, isLoading, onClickFetchMoreMeetings, isFetchMoreMeetingsBtnEnable,
    } = this.props;

    const sortedKeys = Object.keys(groupedNotes)
      .sort(StringUtils.sortStringsAlphabetically)
      .reverse();
    return (
      <OverlayScrollbarsComponent
        id="AllNotesContainer"
        ref={this.scrollRef}
        options={{
          paddingAbsolute: true,
          callbacks: {
            onScrollStop: this.onScrollStop,
          },
        }}
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <PageContainer>
          {sortedKeys.map((eachDay) => (
            <DayContainerTop key={eachDay} id={getDay(eachDay)} style={{ width: '100%' }}>
              <DayContainerInner day={eachDay} key={eachDay}>
                <DayLabelAtom date={eachDay} />
                {groupedNotes[eachDay].map((note) => (
                  <NoteItemMolecule
                    key={note.id}
                    note={note}
                    date={eachDay}
                    onAction={onAction}
                  />
                ))}
                {groupedNotes[eachDay].length === 0 && (
                  <Font font="defaultSmall" id="noMeetings" color="gray6">
                    No meetings
                  </Font>
                )}
              </DayContainerInner>
            </DayContainerTop>
          ))}
          {isFetchMoreMeetingsBtnEnable && (
          <AllNotesBottomButtonToFetchMoreMeetings
            isLoading={isLoading}
            onClick={onClickFetchMoreMeetings}
          />
          )}
        </PageContainer>
      </OverlayScrollbarsComponent>
    );
  }
}

export default NotesListOrganism;
