import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface UnorderedListProps {
  fill?: string;
  width?: number;
  height?: number;
}

const UnorderedListIcon = ({
  fill,
  width,
  height,
}: UnorderedListProps) => (
  <svg width={width} height={height} viewBox="0 0 15 12.5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 1.3000695,0.05006954 c -0.69034996,0 -1.24999996,0.55965 -1.24999996,1.24999996 0,0.69036 0.55965,1.25 1.24999996,1.25 0.69036,0 1.25,-0.55964 1.25,-1.25 0,-0.69034996 -0.55964,-1.24999996 -1.25,-1.24999996 z m 4,0.5 c -0.41421,0 -0.75,0.33579 -0.75,0.74999996 0,0.41422 0.33579,0.75 0.75,0.75 H 14.30007 c 0.4142,0 0.75,-0.33578 0.75,-0.75 0,-0.41420996 -0.3358,-0.74999996 -0.75,-0.74999996 z m -4,4.49999996 c -0.69034996,0 -1.24999996,0.55965 -1.24999996,1.25 0,0.69036 0.55965,1.25 1.24999996,1.25 0.69036,0 1.25,-0.55964 1.25,-1.25 0,-0.69035 -0.55964,-1.25 -1.25,-1.25 z m 4,0.5 c -0.41421,0 -0.75,0.33579 -0.75,0.75 0,0.41422 0.33579,0.75 0.75,0.75 H 14.30007 c 0.4142,0 0.75,-0.33578 0.75,-0.75 0,-0.41421 -0.3358,-0.75 -0.75,-0.75 z m -4,4.5000005 c -0.69034996,0 -1.24999996,0.5597 -1.24999996,1.25 0,0.6904 0.55965,1.25 1.24999996,1.25 0.69036,0 1.25,-0.5596 1.25,-1.25 0,-0.6903 -0.55964,-1.25 -1.25,-1.25 z m 4,0.5 c -0.41421,0 -0.75,0.3358 -0.75,0.75 0,0.4142 0.33579,0.75 0.75,0.75 H 14.30007 c 0.4142,0 0.75,-0.3358 0.75,-0.75 0,-0.4142 -0.3358,-0.75 -0.75,-0.75 z"
      fill={fill}
    />
  </svg>
);

UnorderedListIcon.defaultProps = {
  fill: darkBlue4,
  width: 15,
  height: 12.5,
};

export default UnorderedListIcon;
