import React from 'react';
import styled from 'styled-components';
import { JiraIcon } from '../../../../shared/icons/integration-icons';
import GoogleDocsIcon from '../../../../shared/img/Google-Docs-Logo.png';
import {
  Logo,
  IntegrationContainer, InnerContainer, IntegrationText, ComingSoon,
} from './utils/commonStyles';
import AsanaIcon from '../../../../shared/img/AsanaIcon.png';
import ModalLine from '../../../../shared/components/Modal/ModalLine';
import { header200 } from '../../../../shared/typography';

const ComingSoonHeader = styled.h2`
  ${header200};
  margin-top: 24px;
`;

const ComingSoonApps = () => (
  <div>
    <ModalLine />
    <ComingSoonHeader>Almost here!</ComingSoonHeader>
    <IntegrationContainer>
      <InnerContainer>
        <Logo src={AsanaIcon} />
        <IntegrationText>Asana</IntegrationText>
      </InnerContainer>
      <InnerContainer>
        <ComingSoon>Coming soon</ComingSoon>
      </InnerContainer>
    </IntegrationContainer>
    <IntegrationContainer>
      <InnerContainer>
        <Logo src={GoogleDocsIcon} />
        <IntegrationText>Google docs</IntegrationText>
      </InnerContainer>
      <InnerContainer>
        <ComingSoon>Coming soon</ComingSoon>
      </InnerContainer>
    </IntegrationContainer>
    <IntegrationContainer>
      <InnerContainer>
        <JiraIcon width="28" />
        <IntegrationText>Jira</IntegrationText>
      </InnerContainer>
      <InnerContainer>
        <ComingSoon>Coming soon</ComingSoon>
      </InnerContainer>
    </IntegrationContainer>
  </div>
);
export default ComingSoonApps;
