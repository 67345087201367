/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';
import SentryAPI from '../analytics/SentryAPI';
import mapGapiResultToGapiMeetingData, { rejectedGapiMeetingData } from '../../database/utils/gapiMeetingDataUtils';
import GAPI from '../../external/GoogleAPI/GAPI';
import { GapiMeetingData, ResolvedState } from '../../shared/types/types';
import { GAPI_MEETING_FIELD, GET_EVENT } from '../analytics/enums';
import { logGapiEvent } from '../analytics/eventLogger';
import { consoleLogCouldntFindMeeting } from './GoogleCalendarAPI';
import { mapGapiInsertMeetingResponseToGoogleMeetingIdsObj, RejectedGoogleMeetingIdsObj } from './GoogleCalendarUtils';

declare let gapi: any;
// gapi = Google API

export const gapiListUpcomingEvents = () => {
  gapi.client.calendar.events.list({
    calendarId: 'asish@meetshepherd.com',
    timeMin: (new Date()).toISOString(),
    showDeleted: false,
    singleEvents: true,
    maxResults: 10,
    orderBy: 'startTime',
  }).then((response: any) => {
    console.log('Got list of upcoming events');
    console.log(response); // look in response.result.items for events
    return response.result.items;
  });
};

export const gapiSearchEvents = (
  query: string,
  emailId: string,
): GapiMeetingData[] => gapi.client.calendar.events.list({
  calendarId: emailId,
  q: query,
})
  .then((response: any) => {
    console.log('Got list of upcoming events');
    console.log(response); // look in response.result.items for events
    const gapiMeetings: GapiMeetingData[] = response.result.items.map(
      (item: any) => mapGapiResultToGapiMeetingData(item),
    );
    // const data = response.result.items.map((meeting: any)
    //  => `${meeting.summary} | ${moment(meeting?.start?.dateTime ?? '').format('LLL')}`);
    return gapiMeetings;
  }).catch((error: any) => {
    console.error('here', error);
    return [] as GapiMeetingData[];
  });

export const gapiCoreGetMeetingsInPeriod = async (
  minTime: string, maxTime: string,
): Promise<GapiMeetingData[]> => {
  const calendarIds = await GAPI.getMyCalendarIds();
  const promises = await calendarIds
    .map(
      (calendarId) => gapiCoreGetMeetingsInCalendarInPeriod(
        calendarId, minTime, maxTime,
      ),
    );
  const tempResult = await Promise.all(promises);
  const result = tempResult.flat();
  return result;
};

const gapiCoreGetMeetingsInCalendarInPeriod = async (
  calendarId: string,
  minTime: string,
  maxTime: string,
): Promise<GapiMeetingData[]> => gapi.client.calendar.events.list({
  calendarId,
  timeMin: minTime,
  timeMax: maxTime,
  showDeleted: false,
  singleEvents: true,
  maxResults: 100,
  orderBy: 'startTime',
})
  .then((response: any) => response.result.items.map(
    (event: any) => mapGapiResultToGapiMeetingData(event),
  ))
  .catch((error: any) => {
    captureException(error, {
      extra: {
        calendarId,
        minTime,
        maxTime,
        functionName: `${gapiCoreGetMeetingsInCalendarInPeriod.name}`,
      },
    });
    return [] as GapiMeetingData[];
  });

type ResultType = {
  result: GapiMeetingData
}

/**
 * @eventId the id of the event
 * @calendarId the id of the calendar
 * @number the number of attempts to get the event.
 * Typically we don't know which calendar the user have access to the event by,
 * so we have to try with multiple. number indices how many attemps we have made to get the event.
 * @returns the resolved and mapped meeting data if found,
 * or the rejected meeting data if not found.
 */
export const gapiCoreGetMeeting = async (eventId: string, calendarId: string = 'primary', number: number = 0, shouldLog: boolean): Promise<GapiMeetingData> => gapi.client.calendar.events.get({
  calendarId,
  eventId,
})
  .then(({ result }: ResultType) => {
    console.log(`Got GAPI meeting data, number: ${number}, calendarId: ${calendarId}, eventId: ${eventId}`);
    console.log(result);
    logGapiEvent(GET_EVENT, GAPI_MEETING_FIELD, true, number);
    const gapiEvent = mapGapiResultToGapiMeetingData(result);
    return gapiEvent;
  })
  .catch((error: any) => {
    if (shouldLog) {
      logGapiEvent(GET_EVENT, GAPI_MEETING_FIELD, false, number);
      consoleLogCouldntFindMeeting(eventId, calendarId, number, error);
      const exception = new Error(error?.error ?? error?.body?.error ?? error?.result?.error);
      const scope = new Sentry.Scope();
      scope.setTag('number', number);
      scope.setExtra('calendarId', calendarId);
      scope.setExtra('eventId', eventId);
      scope.setExtra('functionName', `${gapiCoreGetMeeting.name}`);
      Sentry.captureException(exception, scope);
    }
    return rejectedGapiMeetingData;
  });

export const gapiCoreUpdateMeetingDescription = async (
  eventId: string, calendarId: string = 'primary', description: string,
): Promise<ResolvedState> => gapi.client.calendar.events.patch({
  calendarId,
  eventId,
  description,
}).then((response: any) => {
  console.log('Updated meeting description');
  console.log(response);
  return 'resolved';
}).catch((error: any) => {
  console.error('Error updating meeting description');
  console.error(error);
  Sentry.captureException(error);
  return 'rejected';
});

/* using google instances api to get recurring events */
export const getRecurringEventData = (
  calendarId: string,
  eventId: string,
) => {
  const currentDateTime = (new Date()).toISOString();
  return gapi.client.calendar.events.instances({
    calendarId,
    eventId,
    timeMax: currentDateTime,
    showDeleted: true,
  })
    .then((result: any) => {
      SentryAPI.addBreadcrumb({
        category: 'gapi', message: 'getRecurringEventData called', level: 'info', data: { result, calendarId, eventId },
      });
      const meetingsFromGAPI = result.result.items;
      const mappedRecurringMeetings = meetingsFromGAPI.map(mapGapiResultToGapiMeetingData);
      return mappedRecurringMeetings;
    })
    .catch((error: any) => {
      console.error('Error in getRecurringEventData', error);
      const exception = new Error(error?.body?.error ?? error?.result?.error
         ?? error?.arguments[0]?.body?.error ?? error);
      Sentry.captureException(exception, { extra: { calendarId, eventId, functionName: 'getRecurringEventData' } });
      console.log(12, error);
      throw error;
    });
};

export const gapiInsertMeetingCore = async (
  event: any,
) => gapi.client.calendar.events.insert({
  calendarId: 'primary',
  resource: event,
}).then((response: any) => mapGapiInsertMeetingResponseToGoogleMeetingIdsObj(response))
  .catch((error: any) => {
    SentryAPI.captureExceptionAndConsoleError('gapiInsertMeetingCore', error, event);
    return RejectedGoogleMeetingIdsObj;
  });
