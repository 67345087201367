const BillingAnalyticsConstants = {
  /**
   * Event type for all billing related events
   */
  eventTypes: {
    billing: 'billing',
    click_subscribe: 'billing_click_subscribe',
    click_close_in_cancel_page: 'billing_click_close_in_cancel_page',
    close_paywall: 'billing_close_paywall',
    click_try_for_free: 'billing_click_try_for_free',
    click_start_trial: 'billing_click_start_trial',
  },
  origins: {
    existing_users_first_prompt: 'existing_users_first_prompt',
    trial_ended: 'trial_ended',
  },
} as const;

export default BillingAnalyticsConstants;
