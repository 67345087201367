import React from 'react';
import styled from 'styled-components';
import useFocus from '../../../../utils/hook/useFocus';
import {
  gray2, darkBlue2,
} from '../../../colors/COLORS';
import { InputItemContainer } from '../../integration-multi-input';
import ArrowIconMolecule from './ArrowIconMolecule';
import { DropdownItem } from '../utils';
import MultiSelectItemsMolecule from './MultiSelectItemsMolecule';

const DropdownInput = styled.div`
  width: 450px;
  height: auto;
  background-color: ${gray2};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;

  :hover {
    outline: 1px solid ${darkBlue2};
  }
`;

const DropdownInputContainer = styled.div`
  cursor: pointer;
  width: 450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputSearchContainer = styled.input`
  background-color: ${gray2};
  outline: none;
  border: none;
  width: 200px;
`;

interface Props{
  inputText: string,
  setInputText: React.Dispatch<React.SetStateAction<string>>
  isActive: boolean,
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>,
  selectedItems: DropdownItem[],
  setSelectedItems: React.Dispatch<DropdownItem[]>,
  isMultiSelect: boolean,
  placeholderText: string,
}

function DropdownInputFieldMolecule({
  inputText, setInputText, isActive, setIsActive,
  placeholderText, selectedItems, isMultiSelect, setSelectedItems,
}: Props) {
  const [inputRef, setInputFocus] = useFocus();

  const handleArrowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setIsActive(!isActive);
  };

  return (
    <DropdownInput>
      <DropdownInputContainer onClick={() => { setIsActive(true); setInputFocus(); }}>
        <InputItemContainer>
          <MultiSelectItemsMolecule
            isMultiSelect={isMultiSelect}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
          <InputSearchContainer
            ref={inputRef}
            value={inputText}
            onClick={() => { setIsActive(true); }}
            onChange={(e) => { setInputText(e.target.value); }}
            placeholder={placeholderText}
          />
        </InputItemContainer>
        <ArrowIconMolecule isActive={isActive} handleArrowClick={handleArrowClick} />
      </DropdownInputContainer>
    </DropdownInput>
  );
}

export default DropdownInputFieldMolecule;
