import React from 'react';
import { COLORS } from '../colors/COLORS';

interface Props {
  width?: number,
  height?: number,
  stroke?: string,
}

const originalWidth = 532;
const originalHeight = 2;

const HorizontalLine = ({
  width = originalWidth,
  height = originalHeight,
  stroke = COLORS.darkBlue1,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 520 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1L520 0.999955" stroke={stroke} strokeWidth="2" />
  </svg>
);

export default HorizontalLine;
