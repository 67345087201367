import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import Firepad from 'firepad';
import styled from 'styled-components';
import copy from 'copy-html-to-clipboard';
import { useIntercom } from 'react-use-intercom';
import { get, onChildChanged } from 'firebase/database';
import { getDbRef } from '../../../utils/firebase';
import TextBoxReadOnly from './read-only';
import FirepadContainer from './firepadContainer';
import { AuthContext } from '../../../App';
import { MeetingSection, Shortcut } from '../../types/types';
import { SidebarStateContext } from '../../../ListenToSidebarMessageContainer';
import MeetingAnalyticsDataContext from '../../../pages/meeting/context/MeetingAnalyticsDataContext';
import MeetingDataContext from '../../../pages/meeting/context/MeetingDataContext';
import triggerLogEvent from './analyticsLogging';
import { NotesFor } from '../../../utils/analytics/enums';

interface WrapperProps {
  isPreviousMeeting: boolean
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  // Be careful about this!
  // Will affect the horizontal scrolling
  height: ${(props:any) => (props.isPreviousMeeting ? '100%' : 'calc(100vh - 320px + 16px)')};
`;
interface FirepadProps {
  path: string;
  canEdit?: boolean,
  setIsSynchronized: Dispatch<SetStateAction<boolean>>,
  setHtml: Dispatch<SetStateAction<string>>,
  page: MeetingSection,
  isPreviousMeeting: boolean,
  // eslint-disable-next-line no-unused-vars
  onShortcutTrigger: (shortcut: Shortcut) => void,
  notesFor: NotesFor,
}

const FirepadComponent = ({
  path,
  canEdit,
  setIsSynchronized,
  setHtml,
  page,
  isPreviousMeeting,
  onShortcutTrigger,
  notesFor,
}: FirepadProps) => {
  const meetingAnalyticsData = useContext(MeetingAnalyticsDataContext);
  const isSidebarOpen = useContext(SidebarStateContext);
  const { userId } = useContext(AuthContext);
  const { meetingId, date: { start, end } } = useContext(MeetingDataContext);
  const { trackEvent } = useIntercom();

  const [html, setInnerHtml] = useState('');
  const [copyHtmlTrigger, setCopyHtmlTrigger] = useState<number>(0);
  const firepadId = `firepad-${path}`;

  useEffect(() => {
    setInnerHtml('');
  }, [page]);

  useEffect(() => {
    triggerLogEvent(
      isSidebarOpen, page,
      meetingAnalyticsData, html,
      meetingId, trackEvent,
      userId, start, end, notesFor,
    );
    // Page should not be in change list, since right when page change, html will still be populated
    // with a long string, which would have triggered an event
    // Now when the page change, the html will change to empty string
    // and we make sure to not trigger an event for the wrong note page
  }, [html, meetingAnalyticsData, meetingId, userId, isSidebarOpen]);

  const updateHtml = (newHtml: string) => {
    setInnerHtml(newHtml);
    setHtml(newHtml);
  };

  const focusFirepad = (firepad: any) => {
    // eslint-disable-next-line no-underscore-dangle
    firepad?.codeMirror_?.focus();
  };
  useEffect(() => {
    if (copyHtmlTrigger === 0) return;
    if (html.length === 0) return;
    copy(html, { asHtml: true, onlyHtml: true });
  }, [copyHtmlTrigger]);
  // Initialize the Firebase SDK.
  useEffect(() => {
    if (!window?.CodeMirror) return;
    setIsSynchronized(false);
    removeFirepadIfAlreadyExist(firepadId);
    const firepadRef = getDbRef(path);
    const codeMirror = window.CodeMirror(
      document.getElementById(firepadId) ?? document.createElement('a'),
      {
        lineWrapping: true,
        // see https://codemirror.net/doc/manual.html#keymaps
        extraKeys: {
          'Ctrl-C': () => {
            setCopyHtmlTrigger((prev) => prev + 1);
          },
          'Cmd-C': () => {
            setCopyHtmlTrigger((prev) => prev + 1);
          },
          'Alt-T': () => {
            onShortcutTrigger('openCreateTask');
          },
          'Option-T': () => {
            onShortcutTrigger('openCreateTask');
          },
          'Ctrl-Right': () => {
            onShortcutTrigger('navigateRight');
          },
          'Option-Right': () => {
            onShortcutTrigger('navigateRight');
          },
          'Ctrl-Left': () => {
            onShortcutTrigger('navigateLeft');
          },
          'Option-Left': () => {
            onShortcutTrigger('navigateLeft');
          },
        },
      },
    );
    // Create Firepad (with rich text toolbar and shortcuts enabled).
    const firepad = Firepad.fromCodeMirror(firepadRef, codeMirror, {
      richTextShortcuts: true,
      richTextToolbar: true,
      userId,
      readOnly: 'nocursor',
    });
    firepad.on('ready', () => {
      focusFirepad(firepad);
      onWrapperClickFocusFirepad(firepadId, focusFirepad, firepad);
      get(firepadRef).then(() => {
        updateHtml(firepad.getHtml());
        setIsSynchronized(true);
      });
      onChildChanged(firepadRef, () => {
        updateHtml(firepad.getHtml());
      });
      firepad.on('synced', (isSynced: boolean) => {
        // isSynced will be false immediately after the user edits the pad,
        // and true when their edit has been saved to Firebase.
        setIsSynchronized(isSynced);
      });
      if (firepad.isHistoryEmpty()) {
        firepad.setHtml(TemplateText);
      }
    });
  }, [path, canEdit]);
  // Get Firebase Database reference.
  if (!canEdit) return <TextBoxReadOnly html={html} />;
  return (
    <Wrapper isPreviousMeeting={isPreviousMeeting}>
      <FirepadContainer id={firepadId} />
    </Wrapper>
  );
};
FirepadComponent.defaultProps = {
  canEdit: true,
};
export default FirepadComponent;

const TemplateText = '<ul><li></li></ul>';
// eslint-disable-next-line no-unused-vars
type FocusFirepadType = (firepad: any) => void;
// To focus firepad when pressing the entire texteditor area
const onWrapperClickFocusFirepad = (firepadId: string,
  focusFirepad: FocusFirepadType, firepad: any) => {
  document?.getElementById(firepadId)
    ?.addEventListener('click', () => {
      focusFirepad(firepad);
    });
};
const removeFirepadIfAlreadyExist = (firepadId: string) => {
  // To make sure that not several firepad's is rendered at the same time
  const el = document.getElementById(firepadId);
  if (el && el.childNodes.length > 0) el.childNodes[0].remove();
};
