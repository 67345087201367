import React, {
  Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import MeetingDateAndTime from '../../../pages/meeting/components/meeting-date-and-time';
import WarningIcon from '../../icons/warning';
import { MeetingData } from '../../types/MeetingData';
import MeetingAttendeesList from '../meeting-attendees-list';
import ConferenceIcon from './ConferenceIcon';
import MeetingTitleOrg from './MeetingTitleOrg';
import { CSS_CONSTANTS } from '../../CSS/CSS_Constants';

const MeetingDetailContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  padding: 20px 16px 8px 16px;
  max-width: ${CSS_CONSTANTS.MAX_WIDTH_CONTENT};
`;

const TitleAndIconsContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  margin-bottom: 8px;

  & > div:first-child {
    width: 88%;
  }
`;

const DateAndAttendeesContainer = styled.div`
  height: 28px; // Need to set to fixed height because of meeting section tabs
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

interface Props {
  meetingData: MeetingData,
  meetingCreator: string,
  isRecurringMeeting: boolean,
  calendarError: boolean
  attendeesLoading: boolean,
  setMembersInvited: Dispatch<SetStateAction<boolean>>,
  // eslint-disable-next-line no-unused-vars
  updateTitle: (title: string) => void,
}

const MeetingDetailsHeaderOrg = ({
  meetingData, meetingCreator, isRecurringMeeting, calendarError, attendeesLoading, updateTitle,
  setMembersInvited,
}: Props) => (
  <MeetingDetailContainer>
    <TitleAndIconsContainer>
      <MeetingTitleOrg
        meetingData={meetingData}
        meetingCreator={meetingCreator}
        updateTitle={updateTitle}
      />
      <IconsContainer>
        <WarningIcon error={calendarError} />
        <ConferenceIcon conferenceData={meetingData.googleData.conferenceData} />
      </IconsContainer>
    </TitleAndIconsContainer>
    <DateAndAttendeesContainer>
      <FlexSpaceBetween>
        <MeetingDateAndTime isRecurringMeeting={isRecurringMeeting} />
      </FlexSpaceBetween>
      {/* <TagTemplate /> */}
      <MeetingAttendeesList
        attendees={meetingData.attendees.attendees}
        loading={attendeesLoading}
        setMembersInvited={setMembersInvited}
      />
    </DateAndAttendeesContainer>
  </MeetingDetailContainer>
);

export default MeetingDetailsHeaderOrg;
