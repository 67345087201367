const BREAKPOINTS = {
  /** X-Small - None - <576px
   *
   * Small - sm - >=576px */
  sm: '576px',
  /** Medium - md - >=768px */
  md: '768px',
  /** Large - lg - >=992px */
  lg: '992px',
  /** Extra large - xl - >=1200px */
  xl: '1200px',
  /** Extra extra large - xxl - >=1400px */
  xll: '1400px',
};

export const CSS_CONSTANTS = {
  MAX_WIDTH_FULL_PAGE: '1512px',
  /**
   * The max width of the main pages
   * Also edit index.css var --maxWidthContent
   */
  MAX_WIDTH_CONTENT: '800px',
  LEFT_MENU_SIDEBAR_WIDTH: '240px',
  LEFT_MENU_SIDEBAR_MAX_VISIBLE_WIDTH: '1280px',
};

export default BREAKPOINTS;
