import React, {
  Dispatch, SetStateAction, useContext, useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import Modal from '../../Modal';
import { AuthContext, UserDataContext } from '../../../../App';
import { trackUserInvites, sendInviteEmails } from './utils/functions';
import { SEND_INVITES_MODAL_TITLE } from './utils/constants';
import InviteMembersModalContent from './components/invite-members-modal-content-molecule';
import InviteMembersModalFooter from './components/invite-members-modal-footer-molecule';
import { EmailRecipient } from '../../../../pages/meeting/modal/ShareNotesModal';
import { INVITE_EMAIL } from '../../../../utils/analytics/enums';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setMembersInvited: Dispatch<SetStateAction<boolean>>,
}

const InviteMembersModal = ({
  isOpen, setIsOpen, setMembersInvited,
}: Props) => {
  const userData = useContext(UserDataContext);
  const authState = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [emailRecipients, setEmailRecipients] = useState<EmailRecipient[]>([]);
  const { trackEvent } = useIntercom();

  const closeModal = () => setIsOpen(false);

  const sendEmail = () => {
    setLoading(true);

    const emails = emailRecipients.map(({ displayValue }) => (displayValue));
    trackUserInvites(emails, INVITE_EMAIL, userData);
    sendInviteEmails(
      authState,
      emails,
      setEmailRecipients,
      closeModal,
      setMembersInvited,
      setLoading,
      trackEvent,
    );
  };

  return (
    <Modal isOpen={isOpen} setModalClosed={closeModal} title={SEND_INVITES_MODAL_TITLE}>
      <InviteMembersModalContent
        emailRecipients={emailRecipients}
        setEmailRecipients={setEmailRecipients}
      />
      <InviteMembersModalFooter
        loading={loading}
        emailRecipients={emailRecipients}
        onCancelClick={closeModal}
        onSubmitClick={sendEmail}
      />
    </Modal>
  );
};

export default InviteMembersModal;
