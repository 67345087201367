import React from 'react';
import { COLORS } from '../colors/COLORS';

interface Props {
  stroke?: string;
}

const IconRockOnHand = ({ stroke = COLORS.gray1 } : Props) => (
  <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.446 18.3505L21.4884 4.27998C21.579 3.05676 20.7759 1.94586 19.5859 1.64838C18.1267 1.28357 16.6749 2.26971 16.4761 3.76063L15.6499 9.95709" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.25595 12.3555L6.40081 7.22468C6.20234 6.03391 5.17207 5.16113 3.96487 5.16113C2.5127 5.16113 1.37399 6.40805 1.50546 7.85426L2.4597 18.3508" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.0526 14.7543V12.9557C12.0526 11.6313 10.979 10.5576 9.65447 10.5576C8.33002 10.5576 7.25635 11.6313 7.25635 12.9557V17.1525" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.8485 14.7543V12.3561C16.8485 11.0317 15.7749 9.95801 14.4504 9.95801C13.1259 9.95801 12.0522 11.0317 12.0522 12.3561V14.7543" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.4459 18.3506C19.8036 23.1468 16.4196 25.545 11.4529 25.545C6.48625 25.545 2.45996 23.1468 2.45996 18.3506" stroke={stroke} strokeWidth="2" />
    <path d="M14.0819 19.5502H10.8537C9.52924 19.5502 8.45557 18.4765 8.45557 17.152C8.45557 15.8275 9.52924 14.7539 10.8537 14.7539H15.6499C18.2988 14.7539 21.0457 17.152 19.8467 20.7492" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default IconRockOnHand;
