import Sstring from './sstring';

interface EventAndCalendarId {
  eventId: Sstring,
  calendarId: Sstring,
}

class UrlUtils {
  /**
   * Get's the event id and calendar id from the url.
   * I.e. if the url is:
   * "/google-calendar/3h0gusl2ho2gs95v1al99lledk/harald@meetshepherd.com"
   * then eventId is "3h0gusl2ho2gs95v1al99lledk" and
   * calendarId is "harald@meetshepherd.com"
   */
  static getEventIdAndCalendarIdFromUrl(url: string): EventAndCalendarId {
    if (!url.includes('google-calendar')) {
      return { eventId: Sstring.empty(), calendarId: Sstring.empty() };
    }
    const parts = url.split('/');
    const eventId = parts[parts.length - 2];
    const calendarId = parts[parts.length - 1];
    return { eventId: new Sstring(eventId), calendarId: new Sstring(calendarId) };
  }

  /**
   * Get the meetId from the url.
   * I.e. if the url is:
   * "/google-meet/wdj-ykto-qjv"
   * then meetId is "wdj-ykto-qjv"
   */
  static getMeetIdFromUrl = (url: string): string => {
    if (url.includes('google-meet') === false) return '';
    const parts = url.split('/');
    return parts[parts.length - 1];
  }
}

export default UrlUtils;
