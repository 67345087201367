import {
  Plugin, PluginKey,
} from 'prosemirror-state';
import lazyTransactionAdapter from '../adapters/lazy-transaction-adapter';

export const lazyTransactionsPKName = 'lazy_transactions';
export const lazyTransactionsPK = new PluginKey(lazyTransactionsPKName);

export default new Plugin({
  state: {
    init() {
      return null;
    },
    apply(tr, state) {
      const attr = tr.getMeta(lazyTransactionsPK);
      if (attr && attr.id) { lazyTransactionAdapter.clear((trAttr) => trAttr.id === attr.id); }
      return state;
    },
  },
  key: lazyTransactionsPK,
});
