import React from 'react';

interface Props {
  fill?: string,
}

const AddPlusIcon = ({ fill }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 3.54175C10.3452 3.54175 10.625 3.82157 10.625 4.16675V15.8334C10.625 16.1786 10.3452 16.4584 10 16.4584C9.65482 16.4584 9.375 16.1786 9.375 15.8334V4.16675C9.375 3.82157 9.65482 3.54175 10 3.54175Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4584 10C16.4584 10.3452 16.1786 10.625 15.8334 10.625L4.16671 10.625C3.82153 10.625 3.54171 10.3452 3.54171 10C3.54171 9.65482 3.82153 9.375 4.16671 9.375L15.8334 9.375C16.1786 9.375 16.4584 9.65482 16.4584 10Z" fill={fill} />
  </svg>

);

AddPlusIcon.defaultProps = {
  fill: 'white',
};

export default AddPlusIcon;
