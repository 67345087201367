import React from 'react';

const SearchIconWBG = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10C0 4.47715 4.47715 0 10 0H30C35.5228 0 40 4.47715 40 10V30C40 35.5228 35.5228 40 30 40H10C4.47715 40 0 35.5228 0 30V10Z" fill="#EEEFFA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.6115 10C14.3032 10 10 14.2082 10 19.3993C10 24.5903 14.3032 28.7985 19.6115 28.7985C21.8819 28.7985 23.9684 28.0287 25.613 26.7415L28.7371 29.7886L28.8202 29.8586C29.1102 30.0685 29.5214 30.0446 29.7839 29.7873C30.0726 29.5043 30.072 29.0459 29.7825 28.7636L26.6952 25.7523C28.2649 24.0794 29.2231 21.8487 29.2231 19.3993C29.2231 14.2082 24.9198 10 19.6115 10ZM19.6115 11.4477C24.1022 11.4477 27.7426 15.0078 27.7426 19.3993C27.7426 23.7908 24.1022 27.3508 19.6115 27.3508C15.1209 27.3508 11.4804 23.7908 11.4804 19.3993C11.4804 15.0078 15.1209 11.4477 19.6115 11.4477Z" fill="#585DCD" />
  </svg>

);

export default SearchIconWBG;
