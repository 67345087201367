/* eslint-disable no-useless-constructor */
import MeetingDataCore from './MeetingDataCore';
import {
  CoreMeetingData, ResolvedState, Permissions, UserPermissions, AttendeeV2,
} from './types';

export interface MeetingDataI extends CoreMeetingData {
  resolvedState: ResolvedState;
  meetingId: string;
  permissions: Permissions;
  userRole: UserPermissions;
  attendees: {
    attendees: AttendeeV2[];
    resolvedState: ResolvedState;
  };
}

export class MeetingData extends MeetingDataCore {
  hei: string;

  displayText: string;

  constructor(meetingId: string, databaseData: any, userId: string) {
    super(meetingId, databaseData, userId);
    this.hei = 'harald';
    if (this.googleData.content.status === 'cancelled') {
      this.displayText = `${this.googleData.content.summary} - (Cancelled)`;
      return;
    }
    this.displayText = this.googleData.content.summary;
  }

  static pending(): MeetingData {
    const meeting = new MeetingData('', {}, '');
    meeting.resolvedState = 'pending';
    meeting.attendees.resolvedState = 'pending';
    return meeting;
  }

  static rejected(): MeetingData {
    const meeting = new MeetingData('', {}, '');
    meeting.resolvedState = 'rejected';
    meeting.attendees.resolvedState = 'rejected';
    return meeting;
  }
}
