import * as Sentry from '@sentry/browser';
import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom-v5-compat';
import { captureException } from '@sentry/react';
import {
  DocumentData, DocumentSnapshot, addDoc, collection, getDocs, query, updateDoc, where,
  doc, onSnapshot,
} from 'firebase/firestore';
import { trace } from 'firebase/performance';
import { cfSearchPublicUserDataV2ByEmailsV2 } from '../../external/publicUserData/PublicUserDataAPI';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import {
  AttendeeV2,
  AuthState,
  ConferenceData,
  DatabaseMeetingData,
  GapiMeetingData,
  GoogleMeetingIds,
  SetMeetingDataType,
  ShepherdMeetingId,
  SimpleUserData,
} from '../../shared/types/types';
import { MeetingData } from '../../shared/types/MeetingData';
import { gapiGetMeeting, gapiInsertInstantMeeting } from '../../utils/google/GoogleCalendarAPI';
import { gapiCoreGetMeeting } from '../../utils/google/GoogleCalendarCore';
import log from '../../utils/logging';
import {
  getClosestMeeting, makeMeetingUrl,
  sortDatabaseMeetingDataByStartDate,
} from '../../utils/meetings/meetingsUtils';
import { handleDocDoesNotExist } from '../firebaseHandleError';
import { gapiAPIGetMeetingByMeetId } from '../gapiCalendarAPI';
import { rejectedGapiMeetingData } from '../utils/gapiMeetingDataUtils';
import { mapGoogleMeetingToDatabaseMeetingData, rejectedMeetingData } from '../utils/templateMeetingData';
import UserAPI from '../User/UserAPI';
import { COLLECTIONS, MEETING_PATH } from '../FirebaseConstants';
import { getOrMakeMeetingByEventAndCalendarId } from '../../pages/googleCalendar';
import { MeetingsCache } from '../../App';
import SentryAPI from '../../utils/analytics/SentryAPI';
import CloudFunctions from '../CloudFunctions';
import { firestore, performance } from '../../utils/firebase';

type DocType = DocumentSnapshot<DocumentData>;

const dbListenToMeetingData = (
  meetingId: string,
  userId: string,
  setMeetingData: SetMeetingDataType,
  setGapiMeetingData: Dispatch<SetStateAction<GapiMeetingData>>,
) => {
  ConsoleImproved.log(`Starting to listen to meeting with id: ${meetingId}`);

  return onSnapshot(doc(firestore, COLLECTIONS.MEETINGS, meetingId), async (document: DocType) => {
    if (!document.exists()) {
      setMeetingData(rejectedMeetingData);
      setGapiMeetingData(rejectedGapiMeetingData);
      return handleDocDoesNotExist(
        'Meeting does not exist',
        { meetingId, userId },
        () => { },
      );
    }

    const meetingData = new MeetingData(meetingId, document.data(), userId);
    setMeetingData(meetingData);

    const attendees = await cfGetAttendeesFromGoogleAttendees(meetingData.data.attendees);

    const updatedMeetingData = combineMeetingDataWithAttendees(meetingData, attendees);
    console.log(`Got new meeting data from Firebase, for '${meetingData.googleData.content.summary}'`, updatedMeetingData);
    SentryAPI.setContext('meetingData', updatedMeetingData);
    setMeetingData(updatedMeetingData);
    return null;
  }, (error) => {
    console.error(`Error in dbListenToMeetingData, error: '${error.message}'`, { error, meetingId, userId });
    captureException(error, { extra: { meetingId, userId, functionName: 'dbListenToMeetingData' } });
    setMeetingData(rejectedMeetingData);
    setGapiMeetingData(rejectedGapiMeetingData);
  });
};

// ) => firestore()
//   .collection(COLLECTIONS.MEETINGS)
//   .where(MEETING_PATH.googleData.ids.meetId, '==', googleMeetId)
//   .get()
export const dbGetMeetingsByGoogleMeetId = async (
  googleMeetId: string, userId: string,
) => getDocs(query(collection(firestore, COLLECTIONS.MEETINGS), where(MEETING_PATH.googleData.ids.meetId, '==', googleMeetId)))
  .then((docs) => {
    const meetings = docs.docs.map(
      (document) => new MeetingData(document.id, document.data(), userId),
    );
    console.log(meetings);
    return meetings;
  })
  .catch((error) => {
    console.error(`Error in dbGetMeetingsByGoogleMeetId ${error.message}`, { error, googleMeetId, userId });
    captureException(error, { extra: { googleMeetId, userId, functionName: 'dbGetMeetingsByGoogleMeetId' } });
  });

export const dbFindAndNavigateToMeetingByMeetId = async (
  googleMeetId: string, userId: string,
  navigate: NavigateFunction, setError: Dispatch<SetStateAction<boolean>>,
  authState: AuthState,
) => {
  // const trace = performance().trace('dbFindAndNavigateToMeeting');
  const tr = trace(performance, 'dbFindAndNavigateToMeeting');
  tr.start();
  const meetings: any = await CloudFunctions().searchMeetingsByGoogleMeetId({ googleMeetId })
    .then((newMeetings) => newMeetings.data)
    .catch((error) => {
      console.log('Error searching meeting by google meet id', error);
      Sentry.captureException(error);
      return [] as MeetingData[];
    });
  if (meetings.length === 0) {
    // Try to find meeting using GAPI
    // If they have not already created the meeting in Shepherd, it might still be
    // in their calendar.
    const meeting: GapiMeetingData = await gapiAPIGetMeetingByMeetId(googleMeetId);
    if (meeting.resolvedState === 'resolved') {
      ConsoleImproved.log('Actually found a meeting using GAPI');
      const meetingId = await getOrMakeMeetingByEventAndCalendarId(
        meeting.id, meeting.organizer.email, authState, () => { }, setError,
      );
      tr.stop();
      if (meetingId.length > 0) {
        navigate(makeMeetingUrl(meetingId));
      }
      return meetingId;
    }
    ConsoleImproved.log('Did not find a meeting using GAPI');
    log(`Could not find any meetings in the database with meetId: ${googleMeetId}`);
    try {
      // create a google calendar event for the meeting

      /*
      1. First, we’re using the gapiInsertInstantMeeting function to create a new meeting.
      2. Next, we’re using the gapiCoreGetMeeting function to get the newly created meeting.
      3. Then, we’re using the dbGetSimpleUserDataByUserId function to get the user’s data.
      4. Next, we’re using the dbAddMeeting function to add the meeting to the database.
      5. Finally, we’re using the makeMeetingUrl function to create a meeting URL.
      */
      const { eventId, calendarId }: GoogleMeetingIds = await gapiInsertInstantMeeting();
      const newlyCreatedMeeting = await gapiCoreGetMeeting(eventId, 'primary', 0, true);
      const user: SimpleUserData = await UserAPI.OtherUsers.dbGetSimpleUserDataByUserId(userId, 'admin');
      newlyCreatedMeeting.conferenceData.conferenceId = googleMeetId;
      const addMeetingResponse = await dbAddMeeting(
        mapGoogleMeetingToDatabaseMeetingData(newlyCreatedMeeting, calendarId, user),
      );
      if (addMeetingResponse.resolvedState === 'resolved') {
        const meetingUrl = makeMeetingUrl(addMeetingResponse.meetingId);
        log(`Created new meeting in database with meetingId: ${addMeetingResponse.meetingId} as an Instant Meeting`);
        navigate(meetingUrl);
        tr.stop();
        return addMeetingResponse.meetingId;
      }
    } catch (error: any) {
      tr.stop();
      console.error(`Error in dbFindAndNavigateToMeetingByMeetId ${error.message}`, {
        error, googleMeetId, userId, authState,
      });
      captureException(error, {
        extra: {
          googleMeetId, userId, authState, functionName: 'dbFindAndNavigateToMeetingByMeetId',
        },
      });
      setError(true);
      return '';
    }
  }
  log('Meet meetings', meetings);
  const closesMeeting = getClosestMeeting(meetings);
  navigate(makeMeetingUrl(closesMeeting.meetingId));
  tr.stop();
  return closesMeeting.meetingId;
};

export default dbListenToMeetingData;

/**
 * This is just using searching in the database by the EventId.
 * Since depending on who reads the event, different calendarIds might be used
 */
export const dbGetMeetingByEventAndCalendarId = async (
  eventId: string, calendarId: string,
) => {
  try {
    if (MeetingsCache.has(eventId)) {
      return MeetingsCache.get(eventId);
    }

    const tr = trace(performance, 'dbGetMeetingByEventAndCalendarId');
    tr.start();
    const meetings = await CloudFunctions()
      .searchMeetingsByEventAndCalendarId({ eventId, calendarId })
      .then((newMeetings) => newMeetings.data as any)
      .then((meetingsRaw) => meetingsRaw.map((meetingRaw: any) => new MeetingData(meetingRaw.meetingId, meetingRaw, '')));
    if (meetings.length === 0) return rejectedMeetingData;
    const closestMeeting = getClosestMeeting(meetings);
    tr.stop();
    MeetingsCache.add(eventId, closestMeeting);
    return closestMeeting;
  } catch (error) {
    console.error('Error searching meeting by google eventId ', { error, eventId, calendarId });
    Sentry.captureException(error, { extra: { eventId, calendarId, functionName: 'dbGetMeetingByEventAndCalendarId' } });
    return rejectedMeetingData;
  }
};

export const dbAddMeeting = (meetingData: DatabaseMeetingData) => addDoc(
  collection(firestore, COLLECTIONS.MEETINGS), meetingData,
)
  .then((docRef) => {
    console.log('Added new meeting', meetingData);
    return { resolvedState: 'resolved', meetingId: docRef.id } as ShepherdMeetingId;
  })
  .catch((error) => {
    SentryAPI.captureExceptionAndConsoleError('dbAddMeeting', error, meetingData);
    return { resolvedState: 'rejected', meetingId: '' } as ShepherdMeetingId;
  });

export const dbUpdateMeetingData = (newValue: any, meetingId: string, DATA_PATH: string) => {
  updateDoc(doc(firestore, COLLECTIONS.MEETINGS, meetingId), {
    [DATA_PATH]: newValue,
  })
    .then(() => {
      log(`Updated path: ${DATA_PATH} successfully, with value:`, newValue);
    })
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('dbUpdateMeetingData', error, newValue, meetingId, DATA_PATH);
    });
};

export const dbGetPreviousMeetingsByRecurringEventId = async (
  recurringEventId: string,
  currentStartTime: number,
  userEmail: string,
) => CloudFunctions()
  .searchPreviousMeetingsByRecurringEventId({ recurringEventId, currentStartTime })
  .then((results: any) => {
    if (results.data.length === 0) {
      return [];
    }
    const sortedMeetings = results.data.sort(sortDatabaseMeetingDataByStartDate);
    return sortedMeetings.slice(1).slice(-10);
  })
  .then(async (previousMeetings: MeetingData[]) => {
    // console.log('Prev Meeting Log:',
    //  { currentStartTime, previousMeetingsFromCf: previousMeetings });
    const validatedPreviousMeetings = await Promise.all(previousMeetings.map(async (
      meeting: MeetingData,
    ) => {
      ConsoleImproved.log("Validating meeting's start time");
      const userIsAttendee = await validateUserIsAttendeeInMeeting(
        meeting.googleData.ids.eventId,
        meeting.googleData.ids.calendarId,
        userEmail,
      );

      if (userIsAttendee) return meeting;

      return null;
    }));

    const filteredPreviousMeetings: MeetingData[] = validatedPreviousMeetings.filter((
      meeting,
    ) => meeting !== null) as MeetingData[] ?? [];

    // console.log('Prev Meeting Log:', { filteredPreviousMeetings });

    return filteredPreviousMeetings.reverse().slice(0, 5);
  })
  .catch((error) => {
    SentryAPI.captureExceptionAndConsoleError('dbGetPreviousMeetingsByRecurringEventId', error, recurringEventId, currentStartTime, userEmail);
    return [];
  });

export const validateUserIsAttendeeInMeeting = async (
  eventId: string,
  calendarId: string,
  userEmail: string,
) => {
  const meetingGapiData: GapiMeetingData = await gapiGetMeeting(
    eventId,
    calendarId,
    userEmail,
  );

  if (meetingGapiData.resolvedState === 'resolved') return true;

  return false;
};

export const cfGetAttendeesFromGoogleAttendees = async (
  googleAttendees: any[],
): Promise<AttendeeV2[]> => {
  const emails = googleAttendees.map((attendee) => (attendee?.email as string) ?? '');
  const publicUsers = await cfSearchPublicUserDataV2ByEmailsV2(emails);
  const attendees = publicUsers.map((publicUser) => ({
    ...publicUser,
    responseStatus: googleAttendees.filter((googleAttendee) => googleAttendee?.email === publicUser.data.email)[0]?.responseStatus ?? 'needsAction',
  } as AttendeeV2));
  ConsoleImproved.log('cfGetAttendeesFromGoogleAttendees', {
    atteneesFromGapiMeeting: googleAttendees, result: attendees, emails, publicUsers,
  });
  return attendees;
};

const combineMeetingDataWithAttendees = (
  meetingData: MeetingData, attendees: AttendeeV2[],
): MeetingData => ({
  ...meetingData,
  attendees: {
    attendees,
    resolvedState: 'resolved',
  },
} as MeetingData);

export const dbUpdateConferenceData = async (meetingId: string, conferenceData: ConferenceData) => {
  dbUpdateMeetingData(conferenceData, meetingId, MEETING_PATH.googleData.conferenceData);
};

/**
 * Takes in `userId` and list of google eventIds to return meetingData
 * if they are present in our DB and also have some notes written in them.
 */
export const dbGetMeetingsThatHaveNotes = async (userId: string, eventIds: string[]) => {
  if (eventIds.length === 0) return [] as MeetingData[];

  return CloudFunctions().filterMeetingHavingNotesV2({
    userId,
    eventIds,
  })
    .then((data: any) => {
      if (data.data.length === 0) return [] as MeetingData[];
      const meetingData: MeetingData[] = data.data.map(
        (meeting: any) => new MeetingData(meeting.id, meeting, userId),
      );
      return meetingData;
    })
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('dbGetMeetingsThatHaveNotes', error, userId, eventIds);
      return [] as MeetingData[];
    });
};
