import React, { useRef } from 'react';
import styled from 'styled-components';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import { gray1, darkBlue4, gray7 } from '../../../../shared/colors/COLORS';
import { uiText, uiTextMedium, small } from '../../../../shared/typography';
import { TemplateData } from '../../../../shared/types/types';
import SinglePlusIcon from '../../../../shared/icons/SinglePlusIcon';
import StrokeIcon from '../../../../shared/icons/StrokeIcon';
import DropDownOptions from './DropDownOptions';
import { DateToTemplateDateFormat } from '../../../../utils/dateUtils/date';

const TemplateContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: min-content;
  background: ${gray1};
  border-radius: 8px;
`;

const Name = styled.div`
  width: 100%;
  ${uiText};
`;

const CreatedOnDateContainer = styled.div`
  ${small};
  color: ${gray7};
  white-space: nowrap;
  line-height: 11px;
  margin-right: 30px;
`;

const InsertContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  &:hover {
    filter: brightness(50%);
  }
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;

  cursor: pointer;

  &:hover {
    filter: brightness(50%);
  }
`;

const TextContainer = styled.div`
  ${uiTextMedium};
  color: ${darkBlue4};
`;

const TemplateOptionsContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
`;

interface Props {
  section: string
  template: TemplateData
  // eslint-disable-next-line no-unused-vars
  handleInsert: (templateHtml: string) => void,
  // eslint-disable-next-line no-unused-vars
  handleEdit: (template:TemplateData)=> void
  // eslint-disable-next-line no-unused-vars
  handleDelete: (templateId: string) => void
}

const TemplateCard = ({
  section, template, handleInsert, handleEdit, handleDelete,
}: Props) => {
  const dropDownRef = useRef(null);
  const [isDropDownOpen, setIsDropDownOpen] = useDetectOutsideClick(dropDownRef, false);

  const toggleIsDropDownOpen = () => setIsDropDownOpen(!isDropDownOpen);

  const handleInsertClick = () => {
    handleInsert(template.template);
  };

  const handleDeleteClick = () => {
    handleDelete(template.templateId);
    setIsDropDownOpen(false);
  };

  const handleEditClick = () => {
    handleEdit(template);
  };

  return (
    <TemplateContainer>
      <Name>
        {template.data.title}
      </Name>
      {section !== 'shepherd' && (
        <CreatedOnDateContainer>
          {DateToTemplateDateFormat(template.date.created.date)}
        </CreatedOnDateContainer>
      )}
      <InsertContainer onClick={handleInsertClick}>
        <IconContainer>
          <SinglePlusIcon fill={darkBlue4} />
        </IconContainer>
        <TextContainer>
          Insert
        </TextContainer>
      </InsertContainer>
      {section !== 'shepherd' && (
        <TemplateOptionsContainer>
          <IconContainer onClick={toggleIsDropDownOpen}>
            <StrokeIcon fill={darkBlue4} />
          </IconContainer>
          <DropDownOptions
            isDropDownOpen={isDropDownOpen}
            dropDownRef={dropDownRef}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
          />
        </TemplateOptionsContainer>
      )}
    </TemplateContainer>
  );
};

export default TemplateCard;
