import React from 'react';
import styled from 'styled-components';
import Font from '../../typography/Font';
import InviteButtonAtom from './InviteButtonAtom';
import SubHeaderAtom from './SubHeaderAtom';
import TextInputAtomOld from './TextInputAtomOld';

const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

  span {
    :first-child {
      margin-bottom: 20px;
    }
    :nth-child(2) {
      margin-bottom: 16px;
    }
  }
`;

const InputFormContainer = styled.div`
  display: flex;
  width: 100%;
  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

interface Props {
  // eslint-disable-next-line no-unused-vars
  onKeyPress: (event: KeyboardEvent) => void;
  onInviteButtonClick: () => void;
  isInviteButtonDisabled: boolean;
  inputRef: any;
}

const SendEmailMolecule = ({
  onKeyPress, onInviteButtonClick, isInviteButtonDisabled, inputRef,
}: Props) => (
  <Container>
    <Font font="messageText">
      Shepherd is more valuable the more people you collaborate with.
      The true power of Shepherd comes when all participants can prepare,
      collaborate and follow up together.
      Invite your colleagues to unlock the full potential.
    </Font>
    <SubHeaderAtom text="Send an email invite" />
    <InputFormContainer>
      <TextInputAtomOld
        placeholder="Add email and press Enter to send"
        onKeyPress={onKeyPress}
        inputRef={inputRef}
      />
      <InviteButtonAtom isDisabled={isInviteButtonDisabled} onClick={onInviteButtonClick} />
    </InputFormContainer>
  </Container>
);

export default SendEmailMolecule;
