import React, { useState, createContext, useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

export const IsOnlineContext = createContext<boolean>(true);

const DetectInternetConnectionContainer = ({ children }:Props) => {
  const [isOnline, setIsOnline] = useState<boolean>(true);

  const updateNetwork = () => {
    setIsOnline(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  }, []);

  return (
    <IsOnlineContext.Provider value={isOnline}>
      {children}
    </IsOnlineContext.Provider>
  );
};

export default DetectInternetConnectionContainer;
