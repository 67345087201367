/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../shared/colors/COLORS';
import Font from '../../shared/typography/Font';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  width: 100%;
  span {
    color: ${COLORS.darkBlue4};
    font-size: 12px;
  }
`;

const ScratchpadExplanation = () => {
  const title = 'This is a private sticky note that always lives within the sidebar.';

  return (
    <Container>
      <Font font="small">{title}</Font>
    </Container>
  );
};

export default ScratchpadExplanation;
