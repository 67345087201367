import React from 'react';
import styled, { css } from 'styled-components';
import { detectDevice } from '../../../utils/analytics/functions';
import { CHROME_EXTENSION } from '../../../utils/enums';

interface ContainerProps {
  isEnabled: boolean;
}

const Container = styled.div<ContainerProps>`
  ${({ isEnabled }) => !isEnabled && css`display: none;`}
`;

interface Props {
  showInChromeExtension?: boolean;
  children: React.ReactNode;
}

const OnlyShowInChromeExtension = ({ showInChromeExtension = true, children }: Props) => {
  const isInChromeExtension = detectDevice(navigator.userAgent) === CHROME_EXTENSION;
  if (showInChromeExtension) {
    return (
      <Container isEnabled={isInChromeExtension}>{children}</Container>
    );
  }
  return (
    <Container isEnabled={!isInChromeExtension}>{children}</Container>
  );
};

export default OnlyShowInChromeExtension;
