import React from 'react';
import styled from 'styled-components';
import { blue6 } from '../../../../shared/colors/COLORS';
import { defaultSmall } from '../../../../shared/typography';

const Button = styled.div`
  ${defaultSmall};
  color: ${blue6};
  cursor: pointer;
`;

interface Props {
  // eslint-disable-next-line no-unused-vars
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  text: string,
}

const ActionButton = ({ onClick, text }: Props) => (
  <Button onClick={(e) => onClick(e)}>
    {text}
  </Button>
);

export default ActionButton;
