import React from 'react';
import styled from 'styled-components';
import BREAKPOINTS from '../../../shared/CSS/CSS_Constants';
import { header900, header800 } from '../../../shared/typography';

const Title = styled.span`
  ${header900}
  @media only screen and (max-width: ${BREAKPOINTS.sm}){
    ${header800}
  }
`;

interface Props {
  firstName: string,
}

const TitleAtom = ({ firstName }: Props) => (
  <Title>
    {`Welcome back, ${firstName}!`}
  </Title>
);

export default TitleAtom;
