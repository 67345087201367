import styled from 'styled-components';

export const ProfileBubbleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlusIconBubble = styled.div`
  margin-left: 0px;
`;

export const SecondProfileIconBubble = styled.div`
  margin-left: -5px;
`;
