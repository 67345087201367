import React, { useState } from 'react';
import styled from 'styled-components';
import { darkBlue3, blue6 } from '../../shared/colors/COLORS';
import RefreshArrow from '../../shared/icons/RefreshArrow';

const Container = styled.span`
  display: contents;
  cursor: pointer;
`;

interface Props{
  onClick?:()=>void;
}

const RefreshArrowAtom = ({ onClick }:Props) => {
  const [color, setColor] = useState(darkBlue3);
  const mouseEnter = () => setColor(blue6);
  const mouseLeave = () => setColor(darkBlue3);

  return (
    <Container onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={onClick}>
      <RefreshArrow size={20} color={color} />
    </Container>
  );
};

RefreshArrowAtom.defaultProps = {
  onClick: () => {},
};

export default RefreshArrowAtom;
