import { StripeConstants } from './StripeConstants';
import { StripeCheckoutData, StripeCheckoutSessionOrigin } from './StripeTypes';

/**
 * Contains the data needed by Stripe to create a checkout Session
 * https://stripe.com/docs/api/checkout/sessions/create
 *
 * @param stripeCustomerId - Stripe Customer Id
 * @param priceId - Stripe Price Id
 * @param successUrl - Url to redirect to if the payment succeeds
 * @param cancelUrl - Url to redirect to if the payment fails or you press "back arrow"
 */
class CheckoutSessionData implements StripeCheckoutData {
  stripeCustomerId: string;

  workspaceId: string;

  /** Price Id in Stripe */
  priceId: string;

  successUrl: string;

  /** Url for both if the payment fails or you press "back arrow" */
  cancelUrl: string;

  isTest: boolean;

  quantity: number;

  adjustableQuantity: boolean;

  constructor(
    customerId: string, priceId: string,
    successUrl: string, cancelUrl: string,
    origin: StripeCheckoutSessionOrigin,
    isTest = false,
    workspaceId = '',
    quantity = 1,
    adjustableQuantity = true,
  ) {
    const domain = window.location.origin; // i.e. https://app.meetshepherd.com
    this.stripeCustomerId = customerId;
    this.workspaceId = workspaceId;
    this.priceId = priceId;
    this.successUrl = `${domain}${successUrl}&${StripeConstants.ORIGIN_URL_PARAM_PATH}=${origin}`;
    this.cancelUrl = `${domain}${cancelUrl}&${StripeConstants.ORIGIN_URL_PARAM_PATH}=${origin}`;
    this.isTest = isTest;
    this.quantity = quantity;
    this.adjustableQuantity = adjustableQuantity;
  }

  /**
   * Get Data in JSON format, of interface StripeCheckoutData
   */
  public getJSON(): StripeCheckoutData {
    return {
      stripeCustomerId: this.stripeCustomerId,
      workspaceId: this.workspaceId,
      priceId: this.priceId,
      successUrl: this.successUrl,
      cancelUrl: this.cancelUrl,
      isTest: this.isTest,
      quantity: this.quantity,
      adjustableQuantity: this.adjustableQuantity,
    };
  }

  validate() {
    if (!this.stripeCustomerId) {
      console.error(`invalid-argument, stripeCustomerId is empty: "${this.stripeCustomerId}"`);
      throw new Error(`invalid-argument, stripeCustomerId is empty: "${this.stripeCustomerId}"`);
    }
    if (!this.priceId) {
      console.error(`invalid-argument, priceId is empty: "${this.priceId}"`);
      throw new Error(`invalid-argument, priceId is empty: "${this.priceId}"`);
    }
    if (!this.successUrl) {
      console.error(`invalid-argument, successUrl is empty: "${this.successUrl}"`);
      throw new Error(`invalid-argument, successUrl is empty: "${this.successUrl}"`);
    }
    if (!this.cancelUrl) {
      console.error(`invalid-argument, cancelUrl is empty: "${this.cancelUrl}"`);
      throw new Error(`invalid-argument, cancelUrl is empty: "${this.cancelUrl}"`);
    }
  }
}

export default CheckoutSessionData;
