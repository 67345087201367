class Sstring {
  value: string;

  constructor(value: string) {
    this.value = value;
  }

  isValid(): boolean {
    return Sstring.isString(this.value) && this.value.length > 0;
  }

  isNotValid(): boolean {
    return !this.isValid();
  }

  static isString(value: any): boolean {
    return typeof value === 'string';
  }

  /** Empty string '' */
  static empty(): Sstring {
    return new Sstring('');
  }
}

export default Sstring;
