import React from 'react';
import DropdownInputFieldArrow from '../dropdown-input-field-arrow-atom';
import SelectedDropdownOptions from '../selected-dropdown-options-molecule';
import MultiSelectInputContainer from './utils/styles';

interface MultiSelectInputProp {
  selectedOptions: Array<string>,
  placeholder: string,
  isDropdownOpen: boolean,
  /* eslint-disable-next-line */
  onRemoveSelectedOption: (event: any, valueToRemove: string) => void,
  /* eslint-disable-next-line */
  onInputFieldClick: (event: any) => void,
}

const MultiSelectInput = ({
  selectedOptions,
  placeholder,
  isDropdownOpen,
  onRemoveSelectedOption,
  onInputFieldClick,
}: MultiSelectInputProp) => (
  <MultiSelectInputContainer
    onClick={(e) => onInputFieldClick(e)}
    hasInput={selectedOptions.length > 0}
    isdropdownOpen={isDropdownOpen}
  >
    <SelectedDropdownOptions
      selectedOptions={selectedOptions}
      placeholder={placeholder}
      onRemoveSelectedOption={onRemoveSelectedOption}
      isDropdownOpen={isDropdownOpen}
    />
    <DropdownInputFieldArrow isDropdownOpen={isDropdownOpen} />
  </MultiSelectInputContainer>
);

export default MultiSelectInput;
