import React, { SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import DefaultMeetingTabDropdown from '../../default-tab-dropdown';
import { header200, header400 } from '../../../typography';
import { gray9 } from '../../../colors/COLORS';
import { AuthContext, UserDataContext } from '../../../../App';
import { toastDanger, toastSuccess } from '../../../../utils/notifications';
import { mapMeetingSectionToText } from '../../../../utils/meetings/meetingsUtils';
import { AMPMor24h, MeetingSection } from '../../../types/types';
import { logSettingsClicks } from '../../../../utils/analytics/eventLogger';
import { GENERAL_SETTINGS } from '../../../../utils/analytics/enums';
import Switch from '../../switch';
import UserAPI from '../../../../database/User/UserAPI';

const HeadText = styled.div`
  ${header400};
  color: ${gray9};
  margin-bottom: 20px;
`;

const SubHeadText = styled.div`
  ${header200};
  margin-bottom: 4px;
`;

const SwitchGroup = styled.div`
  margin-bottom: 16px;
`;

const GeneralSettings = () => {
  const { settings } = useContext(UserDataContext);
  const { userId } = useContext(AuthContext);
  const { trackEvent } = useIntercom();
  const meetingSectionPlaceholder = mapMeetingSectionToText(settings.defaultUserTab);

  const onDefaultNoteTabChange = (newTab: MeetingSection) => {
    const newSettings = {
      ...settings,
      defaultUserTab: newTab,
    };

    UserAPI.dbUserUpdateInfo(
      userId,
      { settings: newSettings },
    ).then(
      () => {
        toastSuccess('Updated', `Default tab set to ${mapMeetingSectionToText(newTab)}.`);
        logSettingsClicks(userId, GENERAL_SETTINGS, 'change_default_tab', trackEvent);
      },
    ).catch(() => {
      toastDanger('Failed', 'Failed to update default tab.');
    });
  };

  const handleChangeClockFormat = async (newValue: boolean) => {
    const newClockFormatValue: AMPMor24h = newValue ? '24h' : 'AMPM';
    await UserAPI.Settings.updateClockFormat(userId, newClockFormatValue);
  };

  return (
    <>
      <HeadText>General</HeadText>
      <SubHeadText>When meeting begins</SubHeadText>
      <DefaultMeetingTabDropdown
        placeholder={meetingSectionPlaceholder}
        label="This tab will be open by default when you use Shepherd"
        name="defaultTab"
        onChange={onDefaultNoteTabChange}
      />
      <SwitchGroup>
        <Switch
          label="24-hr time format"
          // eslint-disable-next-line react/jsx-boolean-value
          checked={settings.clockFormat === '24h'}
          setChecked={handleChangeClockFormat as React.Dispatch<SetStateAction<boolean>>}
        />
      </SwitchGroup>
    </>
  );
};

export default GeneralSettings;
