/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import InviteByEmailInput from './InviteByEmailInput';
import ResolvedStateSmartExpanded from '../../../../utils/ResolvedState/ResolvedStateSmartExpanded';
import { UserDataContext } from '../../../../App';
import WorkspaceAPI from '../../../../database/Workspaces/WorkspaceAPI';
import cfSearchPublicUserDataV2ByEmail from '../../../../external/publicUserData/PublicUserDataAPI';
import { toastInfo } from '../../../../utils/notifications';
import InitialInvitePromptAddExtraSeat from './InitialInvitePromptAddExtraSeat';
import UserBillingAPI from '../../../../database/User/UserBillingAPI';

const Container = styled.div``;

interface PromptStatus {
  isOpen: boolean,
  email: string,
}
interface InviteByEmailInputProps {
  onReset: () => void,
  showReset?: boolean,
}

const InviteByEmailInputCtrl = ({ onReset, showReset = true }: InviteByEmailInputProps) => {
  const userData = useContext(UserDataContext);
  const [status, setStatus] = useState<ResolvedStateSmartExpanded>(ResolvedStateSmartExpanded.NULL);
  const [promptStatus, setPromptStatus] = useState<PromptStatus>({ isOpen: false, email: '' });

  const handleClickInvite = async (email: string) => {
    console.log('InviteByEmailInputCtrl: handleClickInvite: email:', email);
    if (userData.getMyWorkspacePermission() !== 'admin') {
      toastInfo('Only admins can invite new members');
      return;
    }
    if (userData.workspace.isMemberOrInvited(email)) {
      toastInfo('User is already a member or invited yeah');
      return;
    }
    setStatus(ResolvedStateSmartExpanded.PENDING);

    if (userData.workspace.isOnPremium()
    && userData.billing.hasSeenInvitePromptToPremiumWorkspace === 0) {
      // The workspace is on premium, and you are now trying to add a new member,
      //  which will add an extra seat to the subscription
      setPromptStatus({
        isOpen: true,
        email,
      });
      return;
    }

    actuallyInvite(email);
  };

  const actuallyInvite = async (email: string) => {
    const toBeInvitedUser = await cfSearchPublicUserDataV2ByEmail(email);
    const workspaceId = userData.selectedWorkspaceId;
    const result = await WorkspaceAPI.inviteMemberToWorkspace(
      workspaceId, userData.workspace.name, email, toBeInvitedUser.data.firstName || '',
      userData.email, userData.name, userData.userId,
    );
    if (result.isError) {
      setStatus(ResolvedStateSmartExpanded.REJECTED);
    }
    if (result.isResolved) {
      setStatus(ResolvedStateSmartExpanded.RESOLVED);
      setTimeout(() => {
        setStatus(ResolvedStateSmartExpanded.NULL);
      }, 2000);
    }
  };

  const handleConfirmInvite = async () => {
    closePrompt();
    await UserBillingAPI.updateHasSeenInvitePrompt(userData);
    actuallyInvite(promptStatus.email);
  };

  const closePrompt = () => {
    setPromptStatus({ ...promptStatus, isOpen: false });
  };

  const handleClickBack = async () => {
    setStatus(ResolvedStateSmartExpanded.NULL);
    await UserBillingAPI.updateHasSeenInvitePrompt(userData);
    closePrompt();
  };

  const handleReset = () => {
    setStatus(ResolvedStateSmartExpanded.NULL);
    onReset();
  };

  return (
    <>
      <InviteByEmailInput
        onClickInvite={handleClickInvite}
        status={status}
        onReset={handleReset}
        showReset={showReset}
      />
      <InitialInvitePromptAddExtraSeat
        isOpen={promptStatus.isOpen}
        onClickBack={handleClickBack}
        onClickInvite={handleConfirmInvite}
      />
    </>
  );
};

export default InviteByEmailInputCtrl;
