import React from 'react';
import { GetMenuPropsOptions, GetPropsCommonOptions } from 'downshift';
import styled from 'styled-components';
import { OptionMenuContainer } from '.';
import { AssigneeV2 } from '../../types/types';
import Scrollbar from '../scrollbar';
import SearchableAssigneOptions from './SearchableAssignOptions';

const MiniOptionMenuContainer = styled(OptionMenuContainer)`
  position: inherit;
  padding: 8px 6px;
  & ul > li {
    padding: 8px 8px;
  }
  border-radius: 0 0 10px 10px;
  box-shadow: none;
`;

export interface Props {
  openOptions: boolean,
  isOpen: boolean
  candidates: AssigneeV2[],
  inputValue: string | null,
  getItemProps: any,
  highlightedIndex: number | null,
  profileIconSize: number
  getMenuProps: (
    // eslint-disable-next-line no-unused-vars
    options?: GetMenuPropsOptions | undefined,
    // eslint-disable-next-line no-unused-vars
    otherOptions?: GetPropsCommonOptions | undefined) => void
}

export default function MiniOptionsMolecule({
  openOptions, isOpen, candidates, inputValue,
  getMenuProps, getItemProps, highlightedIndex, profileIconSize,
}:Props) {
  return (
    <MiniOptionMenuContainer data-is-open={openOptions || isOpen}>
      <Scrollbar maxHeight="220px">
        <ul
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(typeof getMenuProps() === 'object' ? getMenuProps() : {})}
        >
          {(openOptions || isOpen)
            ? (
              <SearchableAssigneOptions
                candidates={candidates}
                inputValue={inputValue}
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                profileIconSize={profileIconSize}
              />
            )
            : null}
        </ul>
      </Scrollbar>
    </MiniOptionMenuContainer>
  );
}
