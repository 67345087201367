/* eslint-disable no-unused-vars */
import {
  useState,
} from 'react';
import { TableControlsInitState } from '../components/widgets/table-controls';
import tableControlsAdapter from '../logic/adapters/table-controls-adapter';

export default function useTableControls() {
  const [show, setShow] = useState<boolean>(false);

  const [contextMenu, setContextMenu] = useState(false);

  const [tableRect, setTableRect] = useState<DOMRect | null>(null);

  const [cellRect, setCellRect] = useState<DOMRect | null>(null);

  function openTableControls(state: TableControlsInitState) {
    setShow(true);
    setContextMenu(!!state.contextMenu);
    setTableRect(state.tableRect);
    setCellRect(state.cellRect);
  }

  function closeTableControls() {
    setShow(false);
    setContextMenu(false);
    setTableRect(null);
    setCellRect(null);
    setContextMenu(false);
  }

  tableControlsAdapter.reactMethods = {
    openPreviewControls: openTableControls,
    closePreviewControls: closeTableControls,
    openContextMenu: () => setContextMenu(true),
    closeContextMenu: () => setContextMenu(false),
  };

  return {
    tControlShow: [show, setShow] as const,
    tControlCtxMenu: [contextMenu, setContextMenu] as const,
    tControlTableRect: [tableRect, setTableRect] as const,
    tControlCellRect: [cellRect, setCellRect] as const,
  };
}
