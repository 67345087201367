import React from 'react';
import { surface } from '../colors/COLORS';

const IntegrationsIcon = () => (
  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18 2.75C16.7574 2.75 15.75 3.75736 15.75 5C15.75 5.39472 15.8516 5.76571 16.0302 6.08821C16.0398 6.10238 16.049 6.11697 16.0578 6.13198C16.0665 6.14688 16.0746 6.16195 16.0821 6.17717C16.478 6.82082 17.1889 7.25 18 7.25C19.2426 7.25 20.25 6.24264 20.25 5C20.25 3.75736 19.2426 2.75 18 2.75ZM15.2037 7.49874C15.8904 8.26666 16.8888 8.75 18 8.75C20.0711 8.75 21.75 7.07107 21.75 5C21.75 2.92893 20.0711 1.25 18 1.25C15.9289 1.25 14.25 2.92893 14.25 5C14.25 5.42095 14.3194 5.82571 14.4473 6.20345L8.79626 9.50126C8.10958 8.73334 7.11123 8.25 6 8.25C3.92893 8.25 2.25 9.92893 2.25 12C2.25 14.0711 3.92893 15.75 6 15.75C7.11139 15.75 8.10986 15.2665 8.79655 14.4984L14.4488 17.7921C14.3199 18.1711 14.25 18.5774 14.25 19C14.25 21.0711 15.9289 22.75 18 22.75C20.0711 22.75 21.75 21.0711 21.75 19C21.75 16.9289 20.0711 15.25 18 15.25C16.8905 15.25 15.8935 15.7319 15.2069 16.4978L9.55287 13.203C9.68069 12.8254 9.75 12.4208 9.75 12C9.75 11.579 9.68064 11.1743 9.55272 10.7965L15.2037 7.49874ZM7.91786 10.8228C7.92542 10.8381 7.93354 10.8531 7.94223 10.868C7.951 10.883 7.9602 10.8976 7.96981 10.9118C8.14836 11.2343 8.25 11.6053 8.25 12C8.25 12.3947 8.14836 12.7657 7.96982 13.0882C7.96012 13.1025 7.95083 13.1172 7.94199 13.1324C7.93339 13.1472 7.92535 13.1621 7.91787 13.1772C7.52195 13.8208 6.8111 14.25 6 14.25C4.75736 14.25 3.75 13.2426 3.75 12C3.75 10.7574 4.75736 9.75 6 9.75C6.8111 9.75 7.52195 10.1792 7.91786 10.8228ZM16.0036 17.9612C16.0271 17.9321 16.0486 17.9009 16.068 17.8676C16.0867 17.8355 16.1028 17.8026 16.1162 17.7691C16.5181 17.1554 17.2117 16.75 18 16.75C19.2426 16.75 20.25 17.7574 20.25 19C20.25 20.2426 19.2426 21.25 18 21.25C16.7574 21.25 15.75 20.2426 15.75 19C15.75 18.6253 15.8416 18.2719 16.0036 17.9612Z" fill="#1B2124" />
  </svg>
);

IntegrationsIcon.defaultProps = {
  fill: surface,
};

export default IntegrationsIcon;
