import { useStripe } from '@stripe/react-stripe-js';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useIntercom } from 'react-use-intercom';
import { UserDataContext } from '../../../../../App';
import UserAPI from '../../../../../database/User/UserAPI';
import StripeAPI from '../../../../../external/Stripe/StripeAPI';
import { StripeConstants } from '../../../../../external/Stripe/StripeConstants';
import ROUTES from '../../../../../routes/ROUTES_CONSTANTS';
import ConsoleImproved from '../../../../../shared/classes/ConsoleImproved';
import ButtonPremium from '../../../../../shared/components/buttons/ButtonPremium/ButtonPremium';
import NavigationButton from '../../../../../shared/components/buttons/navigation-button';
import {
  BACK_DIRECTION, LIGHT_BUTTON, NEXT_DIRECTION, DARK_BUTTON,
} from '../../../../../shared/components/buttons/navigation-button/utils/constants';
import { toastDanger } from '../../../../../utils/notifications';
import OnboardingConstants from '../../utils/OnboardingConstants';
import { ScreensNavigationData, ScreensNavigateFunctions } from '../../utils/OnboardingTypes';
import Container from './utils/styles';

interface Props {
  data: ScreensNavigationData,
  functions: ScreensNavigateFunctions
}

const WelcomeScreensNavigationButtons = ({
  data,
  functions,
}: Props) => {
  const { activeScreen } = data;
  const { navigateToPreviousScreen, navigateToNextScreen } = functions;
  const stripe = useStripe();
  const navigate = useNavigate();
  const { trackEvent } = useIntercom();
  const userData = useContext(UserDataContext);

  const isFirstScreen = activeScreen === OnboardingConstants.firstScreen;
  const isLastScreen = activeScreen === OnboardingConstants.lastScreen;

  /* for later use
  const renderDoItLater = () => {
    if (integrationScreen) {
      return (
        <SkipOptionContainer>
          <SkipOption onClick={navigateToLastStep}>
            Do it later
          </SkipOption>
        </SkipOptionContainer>
      );
    }

    return null;
  };
  */

  const handleClickSubscribe = async () => {
    try {
      await StripeAPI.createAndRedirectToCheckoutSession(
        userData, StripeConstants.CHECKOUT_ORIGINS.DuringOnboarding,
        userData.workspace.numberOfMembers, trackEvent, stripe,
      );
    } catch (error: any) {
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const handleClickFree = async () => {
    console.log('Clicked Free. This is the same as clicking start trial');
    await UserAPI.Billing.startTrial(userData, 'onboarding', trackEvent);
    setTimeout(() => {
      navigate(ROUTES.onboardingFirstMeetingPath);
    }, 200);
  };

  if (isFirstScreen) {
    return (
      <Container>
        <NavigationButton
          data={{ text: NEXT_DIRECTION }}
          functions={{ onClick: navigateToNextScreen }}
          styles={{ color: DARK_BUTTON }}
        />
      </Container>
    );
  }

  if (activeScreen === OnboardingConstants.screens.WORKSPACE_HOW_ARE_YOU_PLANNING_TO_USE_SHEPHERD) {
    return null;
    // Info: For now we don't show the back button to make the flow simpler
    // return (
    //   <Container>
    //     <NavigationButton
    //       data={{ text: BACK_DIRECTION }}
    //       functions={{ onClick: navigateToPreviousScreen }}
    //       styles={{ color: LIGHT_BUTTON }}
    //     />
    //   </Container>
    // );
  }

  if (isLastScreen) {
    return (
      <Container>
        <NavigationButton
          data={{ text: 'Free plan' }}
          functions={{ onClick: handleClickFree }}
          styles={{ color: LIGHT_BUTTON }}
        />
        <ButtonPremium onClick={handleClickSubscribe} padding="10px 65px">Premium</ButtonPremium>
      </Container>
    );
  }

  return (
    <Container>
      <NavigationButton
        data={{ text: BACK_DIRECTION }}
        functions={{ onClick: navigateToPreviousScreen }}
        styles={{ color: LIGHT_BUTTON }}
      />
      <NavigationButton
        data={{ text: NEXT_DIRECTION }}
        functions={{ onClick: navigateToNextScreen }}
        styles={{ color: DARK_BUTTON }}
      />
    </Container>
  );
};

export default WelcomeScreensNavigationButtons;
