import React from 'react';
import styled from 'styled-components';
import { gray1 } from '../../colors/COLORS';
import { TaskStatus } from '../../types/types';
import TaskStatusDropdownItem from './TaskStatusDropdownItem';

const StatusOptionListContainer = styled.div`
  position: absolute;
  // use translate instead of top
  transform: translateY(6px);
  padding: 16px;
  background-color: ${gray1};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 25;
`;

interface Props {
  status: TaskStatus
  // eslint-disable-next-line no-unused-vars
  updateStatus: (status: TaskStatus) => void,
}

const TaskStatusDropdownOptions = ({ status, updateStatus }: Props) => {
  const getClickHandler = (clickedStatus: TaskStatus) => () => {
    if (status === clickedStatus) return;
    updateStatus(clickedStatus);
  };

  return (
    <StatusOptionListContainer>
      <TaskStatusDropdownItem text="To do" status="todo" onSelect={getClickHandler('todo')} />
      <TaskStatusDropdownItem text="In progress" status="inProgress" onSelect={getClickHandler('inProgress')} />
      <TaskStatusDropdownItem text="Completed" status="completed" onSelect={getClickHandler('completed')} />
    </StatusOptionListContainer>
  );
};

export default TaskStatusDropdownOptions;
