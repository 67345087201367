import React from 'react';
import styled from 'styled-components';
import { surface, gray1 } from '../../../../../shared/colors/COLORS';
import { PublicUserDataV2 } from '../../../../../shared/types/types';
import ProfileIcon from '../../../../../shared/components/profile-icon/index';
import ExplanationAtom from './ExplanationAtom';
import TitleCreatedMolecule from './TitleCreatedMolecule';
import CloseIcon from '../../../../../shared/icons/closeIcon';
import ActionButtonsMolecule from './ActionButtonsMolecule';

const Container = styled.div`
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${gray1};
  box-shadow: 0px 14px 24px rgba(27, 33, 36, 0.16), 0px 8px 8px -4px rgba(27, 33, 36, 0.04);
  border-radius: 16px;
  padding: 16px;
`;

const ProfileIconContainer = styled.div`
  height: 96px;
  position: relative;
  display: flex;
  align-items: top;
  justify-content: center;
  margin-right: 16px;
  top: 0;
`;

const CloseIconContainer = styled.div`
  height: 96px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;

  svg {
    cursor: pointer;
  }
`;

const MiddleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  span {
    :nth-child(2) {
      margin-bottom: 10px;
      margin-top: 4px;
    }
  }
`;

interface Props {
  isOpen: boolean,
  setClosed: () => void,
  createdAgo: string,
  creator: PublicUserDataV2,
  viewChat: () => void,
}

const PrivateSpaceInvitedPopupOrganism = ({
  isOpen, setClosed, createdAgo, creator, viewChat,
}: Props) => {
  if (!isOpen) return null;
  const explanation = `${creator.data.name} has added you to a Private note`;

  return (
    <Container>
      <ProfileIconContainer>
        <ProfileIcon user={creator} size={40} />
      </ProfileIconContainer>
      <MiddleContainer>
        <TitleCreatedMolecule createdAgo={createdAgo} />
        <ExplanationAtom explanation={explanation} />
        <ActionButtonsMolecule setClosed={setClosed} viewChat={viewChat} />
      </MiddleContainer>
      <CloseIconContainer onClick={setClosed}>
        <CloseIcon fill={surface} height="18" width="18" />
      </CloseIconContainer>
    </Container>
  );
};

export default PrivateSpaceInvitedPopupOrganism;
