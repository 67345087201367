/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Font from '../../../shared/typography/Font';
import ButtonSmall from '../../../shared/components/button-small';
import TextInputAtom from '../../../shared/components/invite-members-modal/TextInputAtom';
import { TeamOrPrivate } from '../../../shared/types/types';
import { UserDataContext } from '../../../App';
import WorkspaceLogo from '../../../shared/icons/WorkspaceLogo';
import ButtonOutline from '../../../shared/components/buttons/ButtonOutline/ButtonOutline';
import { toastInfo } from '../../../utils/notifications';

const Container = styled.div`
  padding: 64px 48px;
  img {
    border-radius: 8px;
  }

  user-select: none;
`;

const HowAreYouPlanningOnUsingShepherdContainer = styled.div`
  margin-bottom: 172px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  width: 296px;

  & > *:first-child {
    margin-bottom: 16px;
  }
`;

const CreateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 120px; */

  & > *:first-child {
    margin-right: 16px;
  }
  & > *:nth-child(2) {
    width: 120px;
  }
`;

const MiddleContainer = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WorkspaceNameContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > *:first-child {
    margin-bottom: 10px;
    margin-left: 4px;
  }
`;

interface WorkspaceOnboardingTemplateProps {
  onClickCreate: (newWorkspaceName: string) => void;
  onClickBack: () => void;
  asTeamOrPersonal: TeamOrPrivate,
}

const CreateNewWorkspaceTemplate = ({
  onClickCreate,
  onClickBack,
  asTeamOrPersonal,
}: WorkspaceOnboardingTemplateProps) => {
  const userData = useContext(UserDataContext);
  const [workspaceName, setWorkspaceName] = useState('');

  const handleUpdateWorkspaceName = (newString: string) => {
    if (newString.length > 21) {
      toastInfo('Workspace name cannot be longer than 21 characters');
      return;
    }
    setWorkspaceName(newString);
  };

  useEffect(() => {
    if (asTeamOrPersonal === 'personal') {
      setWorkspaceName(`${userData.data.firstName} - Personal`);
    }
  }, [asTeamOrPersonal]);

  const handleClickCreate = () => {
    if (!workspaceName) return;
    onClickCreate(workspaceName);
  };

  const handleClickEnter = () => {
    setTimeout(() => {
      handleClickCreate();
    }, 100);
  };

  const isWorkspaceNameValid = workspaceName.length > 2;

  const title = asTeamOrPersonal === 'team' ? 'Create a team workspace' : 'Create a personal workspace';

  const description = asTeamOrPersonal === 'team' ? 'A shared space for your teams meeting notes' : 'A personal space for your meeting notes';

  return (
    <Container>
      <HowAreYouPlanningOnUsingShepherdContainer>
        <Font font="header900">{title}</Font>
        <Font font="uiTextM" color="gray7">{description}</Font>
      </HowAreYouPlanningOnUsingShepherdContainer>
      <MiddleContainer>
        <WorkspaceLogo name={workspaceName} />
        <WorkspaceNameContainer>
          <Font font="header400">Workspace Name</Font>
          <TextInputAtom value={workspaceName} onChange={handleUpdateWorkspaceName} onEnterKeyPress={handleClickEnter} placeholder="Add your new workspace name" />
        </WorkspaceNameContainer>
      </MiddleContainer>
      <CreateContainer>
        <ButtonOutline padding="8px 24px" onClick={onClickBack}>
          Back
        </ButtonOutline>
        <ButtonSmall onClick={handleClickCreate} text="Create" isDisabled={!isWorkspaceNameValid} />
      </CreateContainer>
    </Container>
  );
};

export default CreateNewWorkspaceTemplate;
