/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../shared/components/Modal';
import Font from '../../../../shared/typography/Font';
import BillingModalTitle from '../BillingModalTitle';
import WatchDemoCardMol from './WatchDemoCardMol';

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const MainContentContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
  }

  > *:last-child {
    margin-top: 25px;
    margin-bottom: 10px;
  }
`;

interface BillingModalHasStartedTrialProps {
  isOpen: boolean,
  onClose: () => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onClickWatchModal: () => void,
}

const BillingModalHasStartedTrial = ({
  isOpen,
  onClose,
  onClickWatchModal,
}: BillingModalHasStartedTrialProps) => (
  <Modal
    title=""
    isOpen={isOpen}
    setModalClosed={onClose}
    isExitButtonEnabled={false}
    isHeadless
    blurriness={2}
  >
    <Container>
      <BillingModalTitle title="You're all set!" showCloseIcon onClose={onClose} />
      <MainContentContainer>
        <Font font="uiTextS" color="gray7">
          <b>
            Your 14-day free trial has now started!
          </b>
        </Font>
        <br />
        <Font font="uiTextS" color="gray7">
          You have officially started your free trial with
          Shepherd Premium. Enjoy, and if you have any questions,
          please let us know!
        </Font>
        <WatchDemoCardMol onClickWatchDemo={onClickWatchModal} />
      </MainContentContainer>
    </Container>
    {/* <ModalLineLocal /> */}
    {/* <ModalFooterWrapper /> */}
  </Modal>
);

export default BillingModalHasStartedTrial;
