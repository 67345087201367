import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../colors/COLORS';

const Container = styled.div<StyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  /* border: 2px solid ${COLORS.darkBlue1}; */
  width: fit-content;
  cursor: pointer;

  padding: ${({ padding }) => ((padding && padding.length > 0) ? padding : '4.3px 14px')};

  :hover {
    background-color: ${COLORS.gray4};
    /* border: 2px solid ${COLORS.gray5}; */
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    transition: all 0.1s ease-in-out;
  }
`;

interface StyleProps {
  padding?: string,
}

interface ButtonWhiteProps extends StyleProps {
  onClick: () => void,
  id?: string,
  children: React.ReactNode,
}

const ButtonWhite = ({
  onClick, padding = '', id = '', children,
}: ButtonWhiteProps) => (
  <Container onClick={onClick} padding={padding} id={id}>
    {children}
  </Container>
);

export default ButtonWhite;
