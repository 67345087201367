/* eslint-disable max-len */
import isUrl from 'is-url';
import {
  defaultSettings,
  ImagePluginSettings,
  imagePluginKey,
  ImagePluginAction,
} from 'prosemirror-image-plugin';
import { NodeSpec, Schema } from 'prosemirror-model';
import { EditorState, Transaction } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { absolutePositionToRelativePosition, relativePositionToAbsolutePosition, ySyncPluginKey } from 'y-prosemirror';
import { getDownloadURL } from 'firebase/storage';
import { getStorageRef } from '../../../../../utils/firebase';
import dataURItoBlob from '../images/data-url-to-blob';
import startFirebaseUpload from '../images/start-firebase-upload';

// eslint-disable-next-line no-unused-vars
const ImageSpecOld: NodeSpec = {
  group: 'inline',
  inline: true,
  draggable: true,
  attrs: {
    src: {},
    alt: { default: null },
    title: { default: null },
    uploadId: { default: null }, /// added
    error: { default: null }, /// added
  },
  parseDOM: [
    {
      tag: 'img[src]',
      getAttrs(dom) {
        if (typeof dom === 'string') return {};
        return {
          src: (dom as Element).getAttribute('src'),
          title: (dom as Element).getAttribute('title'),
          alt: (dom as Element).getAttribute('alt'),
          uploadId: (dom as Element).getAttribute('uploadId'), /// added
          error: (dom as Element).getAttribute('error'), /// added
        };
      },
    },
  ],
  toDOM(node) {
    const {
      src, alt, title, uploadId, error,
    } = node.attrs; /// updated
    return ['img', {
      src, alt, title, uploadId, error,
    }]; /// updated
  },
};

const ImageSpec: NodeSpec = {
  content: 'inline*',
  attrs: {
    src: {
      default: null,
    },
    alt: {
      default: null,
    },
    height: {
      default: null,
    },
    width: {
      default: null,
    },
    maxWidth: {
      default: null,
    },
    align: {
      default: 'fullWidth',
    },
  },
  atom: true,
  group: 'block',
  draggable: true,
  parseDOM: [
    {
      tag: 'div.imagePluginRoot',
    },
  ],
};

const createDecorations = (state: EditorState) => {
  // @ts-ignore
  const updatedState: Array<{ id: object; pos: any }> = imagePluginKey.getState(state);
  const YState = ySyncPluginKey.getState(state);
  const decors = updatedState.map((i) => {
    const pos = relativePositionToAbsolutePosition(YState.doc, YState.type, i.pos, YState.binding.mapping);
    const widget = document.createElement('placeholder');
    const deco = typeof pos === 'number' ? Decoration.widget(pos, widget, {
      id: i.id,
    }) : undefined;
    return deco;
  });
  // @ts-ignore
  return DecorationSet.create(state.doc, decors.filter((i) => i)) || DecorationSet.empty;
};

interface StateValue {
  pos: any;
  id: object;
}

export const createState = <T extends Schema>() => ({
  init() {
    return [];
  },
  // @ts-ignore
  apply(tr: Transaction<T>, value: StateValue[], oldState: EditorState<T>, newState: EditorState<T>): StateValue[] {
    const action: ImagePluginAction = tr.getMeta(imagePluginKey);
    if (action?.type === 'add') {
      const YState = ySyncPluginKey.getState(newState);
      const relPos = absolutePositionToRelativePosition(action.pos, YState.type, YState.binding.mapping);
      return [...value, { id: action.id as object, pos: relPos }];
    } if (action?.type === 'remove') {
      return value.filter((i) => i.id !== action.id);
    }
    return value;
  },
});

const findPlaceholder = (state: EditorState, id: object) => {
  // @ts-ignore
  const decos: StateValue[] = imagePluginKey.getState(state);
  const found = decos?.find((i) => i.id === id);
  if (!found) return undefined;
  const YState = ySyncPluginKey.getState(state);
  const pos = relativePositionToAbsolutePosition(YState.doc, YState.type, found.pos, YState.binding.mapping);
  return typeof pos === 'number' ? pos : undefined;
};

const ImagePluginSettingsFactory = (path: string): ImagePluginSettings<any> => ({
  ...defaultSettings,
  uploadFile(fileOrUrl) {
    // eslint-disable-next-line no-unreachable
    return new Promise<string>((resolve) => {
      if (typeof fileOrUrl === 'string') {
        if (isUrl(fileOrUrl)) {
          resolve(fileOrUrl);
        } else {
          const blob = dataURItoBlob(fileOrUrl);

          startFirebaseUpload({
            file: new File([blob], 'shepherd-image'),
            getStorageRef,
            path,
            onCompleted: async (ref) => {
              const URL = await getDownloadURL(ref);
              resolve(URL);
            },
          });
        }
      } else {
        const file = fileOrUrl;
        startFirebaseUpload({
          file,
          getStorageRef,
          path,
          onCompleted: async (ref) => {
            const URL = await getDownloadURL(ref);
            resolve(URL);
          },
        });
      }
    });
  },
  isBlock: true,
  createDecorations,
  createState,
  findPlaceholder,
  hasTitle: false,
  maxSize: 520,
  extraAttributes: {
    width: null,
    height: null,
    maxWidth: null,
    align: 'fullWidth',
  },
  scaleImage: false,
});

export { ImagePluginSettingsFactory };

export default ImageSpec;
