import mixpanel from 'mixpanel-browser';
import { AuthState } from '../../shared/types/types';
import { MeetingData } from '../../shared/types/MeetingData';
import { setupPeopleInMixPanel } from '../analytics/eventLogger';
import { MIXPANEL_TOKEN } from '../constants';

const initializeMixpanel = () => {
  mixpanel.init(MIXPANEL_TOKEN);
};

export default initializeMixpanel;

export const setMixpanelUserAndLogAppStart = (authState: AuthState) => {
  mixpanel.identify(authState.userId);
  setupPeopleInMixPanel(authState);
};

export const setMixpanelMeetingGroup = (meetingData: MeetingData) => {
  mixpanel.get_group(
    'meeting_id',
    meetingData.meetingId,
  ).set({
    $title: meetingData.data.title,
  });
};

export const setMixpanelUserMeetingGroup = (meetingData: MeetingData) => {
  mixpanel.set_group('meeting_id', meetingData.meetingId);
};

export const setMixpanelUserProperty = (properties: any) => {
  mixpanel.people.set(properties);
};

export const setMixpanelUserAlias = (userId: string) => {
  mixpanel.alias(userId);
};
