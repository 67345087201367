/* eslint-disable arrow-body-style */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const Container = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DollarIcon = () => {
  return (
    <Container>
      <FontAwesomeIcon icon={solid('dollar-sign')} />
    </Container>
  );
};

export default DollarIcon;
