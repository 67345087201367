import React from 'react';
import { PrivateNoteData } from '../../../types/types';
import CreatePrivateNotesModal from '../../../../pages/meeting/private-notes/create-private-notes-modal';
import PrivateNoteInviteNotification from '../../../../pages/meeting/private-notes/private-note-invite-notif-popup';

interface Props {
  isPrivateNotesModalOpen: boolean,
  onClickClosePrivateNotesModal: () => void,
  isPrivateNoteInviteNotifOpen: boolean,
  onClickClosePrivateNoteInviteNotif: () => void,
  privateNotes: PrivateNoteData[],
  /* eslint-disable-next-line */
  onClickSelectPrivateNote: (chatId: string) => void,
}

const PrivateNotesModals = ({
  isPrivateNotesModalOpen,
  onClickClosePrivateNotesModal,
  isPrivateNoteInviteNotifOpen,
  onClickClosePrivateNoteInviteNotif,
  privateNotes,
  onClickSelectPrivateNote,
}: Props) => {
  if (isPrivateNotesModalOpen) {
    return (
      <CreatePrivateNotesModal
        isPrivateNotesModalOpen={isPrivateNotesModalOpen}
        onClickClosePrivateNotesModal={onClickClosePrivateNotesModal}
        onClickSelectPrivateNote={onClickSelectPrivateNote}
      />
    );
  }

  if (isPrivateNoteInviteNotifOpen) {
    return (
      <PrivateNoteInviteNotification
        privateNotes={privateNotes}
        isPrivateNoteInviteNotifOpen={isPrivateNoteInviteNotifOpen}
        onClickClosePrivateNoteInviteNotif={onClickClosePrivateNoteInviteNotif}
        onClickSelectPrivateNote={onClickSelectPrivateNote}
      />
    );
  }

  return null;
};

export default PrivateNotesModals;
