export const EVENTS = {
  workspaces: 'workspaces',
};

export const ACTIONS = {
  clickTeamOrPersonalDuringOnboarding: 'click_team_or_personal_during_onboarding',
  createWorkspace: 'create_workspace',
  deleteWorkspace: 'delete_workspace',
  getWorkspace: 'get_workspace',
  getInvitesByEmail: 'get_invites_by_email',
  inviteMember: 'invite_member',
  changePermission: 'change_permission',
  acceptInvite: 'accept_invite',
  declineInvite: 'decline_invite',
  pageViewInvite: 'page_view_invite',
  clickInvitesQuickSettings: 'click_invites_quick_settings',
  setMyWorkspaceId: 'set_my_workspace_id',
  createInitialPrivateWorkspace: 'create_initial_private_workspace',
  resetSelectedWorkspaceId: 'reset_selected_workspace_id',
};
