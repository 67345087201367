/* eslint-disable no-unused-vars */
import { uniqueId } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { cleanUrl } from '../urlUtils/getCleanUrls';
import { SIDEBAR_STATE } from '../enums';
import { WindowMessage } from '../../shared/types/types';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';

const validOrigins: string[] = [
  'https://calendar.google.com',
  'https://meet.google.com',
];

export const checkOrigin = (origin: string): boolean => validOrigins
  .map((validOrigin) => origin.startsWith(validOrigin))
  .reduce((pre, cur) => pre || cur);

// TODO use new hook
// eslint-disable-next-line max-len
export const listenToWindowRouteMessages = (setNewUrl: Dispatch<SetStateAction<string>>) => {
  let previousRoute = '';
  window.addEventListener('message', (event) => {
    // IMPORTANT: check the origin of the data!
    if (checkOrigin(event.origin)) {
      // if (event.origin.startsWith('http://your-first-site.com')) {
      // The data was sent from your site.
      // Data sent with postMessage is stored in event.data:
      const redirect = event.data?.redirect as string ?? '';
      ConsoleImproved.log('Raw redirect route', redirect);
      const cleanedRedirect = cleanRedirectRoute(redirect);
      if (!cleanedRedirect) return;

      if (previousRoute === cleanedRedirect) return;
      setNewUrl(cleanedRedirect);
      previousRoute = cleanedRedirect;
    } else {
      // The data was NOT sent from your site!
      // Be careful! Do not use it. This else branch is
      // here just for clarity, you usually shouldn't need it.
    }
  });
};

export const listenToSidebarStateMessage = (
  sidebarStateSetter: any,
) => {
  window.addEventListener('message', (event) => {
    if (checkOrigin(event.origin) && event.data.type === SIDEBAR_STATE
      && event.data.id === undefined) {
      sidebarStateSetter(event.data.isSidebarOpen);
      console.log(`sidebar message: ${event.data.isSidebarOpen}`);
    }
  });
};

export function sendWindowMessage<T>(
  data: T, type: string,
): Promise<WindowMessage> {
  return new Promise<WindowMessage>((resolve, reject) => {
    const CEWindow: Window = window.parent;
    const messageBody: WindowMessage = {
      data, id: uniqueId(), type, sequence: 0, ack: false,
    };
    try {
      CEWindow.postMessage(messageBody, '*');
      resolve(messageBody);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * If the calendarId contains a '%', we will change the calendarId to 'primary',
 * since react have issues dealing with '%' characters in routes.
 * @examples
 * `(/google-calendar/6vis6i9615irrb2s9lsrn0irkk_20220210T120000Z/
 * harald%meetshepherd.com@gtempaccount.com)`
 * => `/google-calendar/6vis6i9615irrb2s9lsrn0irkk_20220210T120000Z/primary`
 */
const cleanRedirectRoute = (newRoute: string): string => {
  const cleanedRoute = cleanUrl(newRoute);
  const parts = cleanedRoute.split('/');
  let calendarId = parts.pop() ?? '';
  if (calendarId.includes('%')) {
    calendarId = 'primary';
  }
  return `${parts.join('/')}/${calendarId}`;
};

export const isPageInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
