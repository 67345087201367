import React, { useContext, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { AuthContext } from '../../App';
import ErrorPage from '../../shared/components/error-page';
import { GOOGLE_MEET_ERROR_PAGE } from '../../utils/analytics/enums';
import { logPageEvent } from '../../utils/analytics/eventLogger';
import GoogleMeetErrorTemplate from './GoogleMeetErrorTemplate';

export interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  googleMeetId: string;
  retry: () => void;
}

const GoogleMeetErrorPage = ({ retry }: Props) => {
  // If there doesn't exist any meeting with the googleMeetId,
  // this page will be shown

  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);

  useEffect(() => {
    if (trackEvent) logPageEvent(authState.userId, trackEvent, GOOGLE_MEET_ERROR_PAGE);
  }, [trackEvent]);

  return (
    <>
      <ErrorPage>
        <GoogleMeetErrorTemplate retry={retry} />
      </ErrorPage>
    </>
  );
};

export default GoogleMeetErrorPage;
