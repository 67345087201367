import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

let lastPlaceNavigatedTo: string = '';

/**
  * Hacky way to make sure the Sidebar doesn't load twice,
  * first time when press a meeting in Googel Calendar
  * and the meeting card with the attendees opens, and then
  * another time when you close the meeting card.
  * The reason why it previously happened is when closing
  * the meeting card, it would trigger a "POP" event,
  * which tries to make the page go to the previous
  * page(same as pressing back button in browser).
  * The previous page/route would have been
  * "google-calendar/<event-id>/<calendar-id>" and would have
  * redirected the user back to the same meeting. The user
  * experience would have been that the screen flickers and reloads
  * the same page, which is not good.
 */
const useFixDoubleLoadingInGoogleCalendar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('Location updated to: ', location.pathname);
    // console.log('lastPlaceNavigatedTo: ', lastPlaceNavigatedTo);
    if (lastPlaceNavigatedTo !== location.pathname && location.pathname.includes('/meeting')) {
      navigate(location.pathname + location.search);
    }
    lastPlaceNavigatedTo = location.pathname;
  }, [location]);
};

export default useFixDoubleLoadingInGoogleCalendar;
