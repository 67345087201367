/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../shared/components/Modal';
import WatchDemoText from './WatchDemoText';
import BillingModalTitle from '../BillingModalTitle';
import WatchDemoBackButtonAtom from './WatchDemoBackButtonAtom';
import WatchDemoActualVideoMol from './WatchDemoActualVideoMol';

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const MainContentContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:nth-child(3) {
    margin-top: 15px;
  }

  span {
    text-align: center;
  }

  #announcement {
    margin-bottom: 15px;
    padding: 0 10px;
  }
`;

interface BillingModalWatchDemoProps {
  isOpen: boolean,
  onClickBack: () => void,
}

const BillingModalWatchDemoOrganism = ({
  isOpen,
  onClickBack,
}: BillingModalWatchDemoProps) => {
  return (
    <Modal
      title=""
      isOpen={isOpen}
      setModalClosed={() => { }}
      isExitButtonEnabled={false}
      isHeadless
      blurriness={2}
    >
      <Container>
        <BillingModalTitle title="Watch Demo" />
        <MainContentContainer>
          <WatchDemoText />
          <WatchDemoActualVideoMol />
          <WatchDemoBackButtonAtom
            onClickBack={onClickBack}
          />
        </MainContentContainer>
      </Container>
      {/* <ModalLineLocal /> */}
      {/* <ModalFooterWrapper /> */}
    </Modal>
  );
};

export default BillingModalWatchDemoOrganism;
