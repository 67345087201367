import { OnboardingScreens } from './OnboardingConstants';
import { WelcomeScreens } from './OnboardingTypes';

// TODO: Not sure quite yet how we should do this
export const mapIndexToWelcomeScreen = (index: number) => {
  switch (index) {
    // case 0:
    //   return OnboardingScreens.CALENDAR_SCREEN;
    // case 1:
    //   return OnboardingScreens.MEET_SCREEN;
    // case 2:
    //   return OnboardingScreens.TASKS_SCREEN;
    case 0:
      return OnboardingScreens.INTEGRATIONS_SCREEN;
    case 1:
      return OnboardingScreens.WORKSPACE_HOW_ARE_YOU_PLANNING_TO_USE_SHEPHERD;
    case 2:
      return OnboardingScreens.BILLING_SCREEN;
    // case 5:
      // return OnboardingScreens.BILLING_SCREEN;
    default:
      return OnboardingScreens.INTEGRATIONS_SCREEN;
  }
};

export const switchToNextWelcomeScreen = (welcomeScreen: WelcomeScreens) => {
  switch (welcomeScreen) {
    // case OnboardingScreens.CALENDAR_SCREEN:
    //   return OnboardingScreens.MEET_SCREEN;
    // case OnboardingScreens.MEET_SCREEN:
    //   return OnboardingScreens.TASKS_SCREEN;
    // case OnboardingScreens.TASKS_SCREEN:
    //   return OnboardingScreens.INTEGRATIONS_SCREEN;
    case OnboardingScreens.INTEGRATIONS_SCREEN:
      return OnboardingScreens.WORKSPACE_HOW_ARE_YOU_PLANNING_TO_USE_SHEPHERD;
    case OnboardingScreens.WORKSPACE_HOW_ARE_YOU_PLANNING_TO_USE_SHEPHERD:
      return OnboardingScreens.BILLING_SCREEN;
    default:
      return OnboardingScreens.INTEGRATIONS_SCREEN;
  }
};

export const switchToPreviousWelcomeScreen = (screen: WelcomeScreens) => {
  switch (screen) {
    // case OnboardingScreens.MEET_SCREEN:
    //   return OnboardingScreens.CALENDAR_SCREEN;
    // case OnboardingScreens.TASKS_SCREEN:
    //   return OnboardingScreens.MEET_SCREEN;
    // case OnboardingScreens.INTEGRATIONS_SCREEN:
    //   return OnboardingScreens.TASKS_SCREEN;
    // case OnboardingScreens.VIDEO_SCREEN:
    //   return OnboardingScreens.INTEGRATIONS_SCREEN;
    case OnboardingScreens.BILLING_SCREEN:
      return OnboardingScreens.INTEGRATIONS_SCREEN;
    default:
      return OnboardingScreens.INTEGRATIONS_SCREEN;
  }
};
