import { toastSuccess } from '../../utils/notifications';
import MentionedStatus from './MentionedStatus';

class ToastAPI {
  static showSuccessForMention(receiver: string, mentionedStatus: MentionedStatus) {
    if (mentionedStatus.isSentToSlack
      && mentionedStatus.isSentToEmail
      && mentionedStatus.isSentAppNotification) {
      toastSuccess('Notification Sent', `${receiver} has been notified by slack, email and in app notification`, 5000);
      return;
    }
    if (mentionedStatus.isSentToEmail
      && mentionedStatus.isSentAppNotification) {
      toastSuccess('Notification Sent', `${receiver} has been notified by email and in app notification`, 5000);
      return;
    }
    if (mentionedStatus.isSentToSlack
      && mentionedStatus.isSentAppNotification) {
      toastSuccess('Notification Sent', `${receiver} has been notified by slack and in app notification`, 5000);
      return;
    }
    if (mentionedStatus.isSentAppNotification) {
      toastSuccess('Notification Sent', `${receiver} has been notified by in app notification`, 5000);
      return;
    }
    toastSuccess('Notification Sent', `${receiver} has been notified`, 5000);
  }
}

export default ToastAPI;
