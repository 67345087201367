/* eslint-disable no-unused-vars */
import {
  EditorSchema, suggest, SuggestChangeHandlerProps, Suggester,
} from 'prosemirror-suggest';
import emoji from 'node-emoji';
import Fuse from 'fuse.js';
import emojiAdapter from './emoji-adapter';
import mentionsAdapter from './mentions-adapter';
import commandsAdapter from './command-adapter';
import { createNonShepherdUserIfEmailIsValidAndNotInAttendees } from '../../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import ConsoleImproved from '../../../../classes/ConsoleImproved';

let previousEmojisParams: SuggestChangeHandlerProps | null = null;

const suggestEmojis: (...args: any) => Suggester = () => ({
  char: ':', // The character to match against
  name: 'emoji-suggestion', // a unique name
  appendText: '', // Text to append to the created match
  suggestClassName: 'emoji-suggesting',

  onChange: (params) => {
    if (params.exitReason) {
      emojiAdapter.setShow(false);
      return;
    }
    const v = params.view;
    const query = params.query.full;
    const results = emoji.search(query).slice(0, 10);
    if (!results || results.length === 0) {
      emojiAdapter.setShow(false);
      return;
    }
    const position = v.coordsAtPos(v.state.selection.$anchor.pos);
    const identicalQuery = previousEmojisParams?.query.full === query;
    if (!identicalQuery) { emojiAdapter.setResults(results); }
    emojiAdapter.setPos(position.left, position.bottom);
    emojiAdapter.setShow(true);
    previousEmojisParams = params;
  },
});

let previousMentionsParams: SuggestChangeHandlerProps | null = null;

export interface IndexableMention {
  data: {
    name: string;
    email: string;
  };
}

const suggestMentions: (...args: any) => Suggester = () => ({
  char: '@', // The character to match against
  name: 'mentions-suggestion', // a unique name
  appendText: '', // Text to append to the created match
  suggestClassName: 'mentions-suggesting',
  supportedCharacters: /[\w\d_@._]+/,

  onChange: (params) => {
    if (params.exitReason) {
      mentionsAdapter.setShow(false);
      return;
    }

    const hasContent = !!params.text.full;
    const targetsForeigner = !!params.text.full.match(/^@.+@.*$/);
    if (targetsForeigner) {
      // allow for unknown users composed of emails
      mentionsAdapter.setShow(true);
      mentionsAdapter.setResults([]);
      return;
    }

    const v = params.view;
    const query = params.query.full;
    let results;
    const attendees = mentionsAdapter.indexableMentions;
    if (!query) {
      results = attendees.map((attendee: any) => ({ item: attendee }));
    } else {
      const candidates = [
        ...attendees,
        // createNonShepherdUserIfEmailIsValidAndNotInAttendees(query, attendees),
      ];
      results = (new Fuse(candidates, {
        keys: ['data.name', 'data.email'],
      })).search(query);
    }
    if (!results || results.length === 0) {
      if (hasContent) {
        // allow for unknown users
        mentionsAdapter.setShow(true);
        mentionsAdapter.setResults([]);
      } else {
        // if no text is present, hide the suggestions
        mentionsAdapter.setShow(false);
      }
      return;
    }

    const position = v.coordsAtPos(v.state.selection.$anchor.pos);
    const identicalQuery = previousMentionsParams?.query.full === query;
    if (!identicalQuery) { mentionsAdapter.setResults(results); }
    mentionsAdapter.setPos(position.left, position.bottom);
    mentionsAdapter.setShow(true);

    previousMentionsParams = params;
  },
});

let previousSuggestParams: SuggestChangeHandlerProps | null = null;

const suggestCommands: () => Suggester = () => ({
  char: '/', // The character to match against
  name: 'command-suggestion', // a unique name
  appendText: '', // Text to append to the created match
  suggestClassName: 'command-suggesting',

  onChange: (params) => {
    if (params.exitReason) {
      commandsAdapter.setShow(false);
      return;
    }

    const v = params.view;
    const query = params.query.full;

    let results: Fuse.FuseResult<unknown>[] = [];
    if (query === '') {
      results = commandsAdapter.getCommands().map((command: any, index: number) => ({
        item: command,
        refIndex: index,
      }));
    } else {
      results = (new Fuse(commandsAdapter.getCommands(), {
        keys: ['name', 'alias'],
      })).search(query);
    }

    if (!results || results.length === 0) {
      commandsAdapter.setShow(false);
      return;
    }

    const position = v.coordsAtPos(v.state.selection.$anchor.pos);
    const identicalQuery = previousSuggestParams?.query.full === query;
    if (!identicalQuery) {
      commandsAdapter.setResults(results);
    } else if (commandsAdapter.getResults().length === 0) {
      commandsAdapter.setResults(results);
    }
    commandsAdapter.setPos(position.left, position.bottom);
    commandsAdapter.setShow(true);
    previousSuggestParams = params;
  },
});

export default suggest(suggestEmojis(), suggestMentions(), suggestCommands());
