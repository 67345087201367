import React from 'react';

interface Props {
  size?: number,
}

const ZoomLogo = ({ size }: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H8C3.58172 24 0 20.4183 0 16V8Z" fill="url(#paint0_linear_2139:9523)" />
    <path d="M4 8.4672C4 8.01387 4.3675 7.64637 4.82083 7.64637H13.4813C14.5823 7.64637 15.4748 8.53886 15.4748 9.63981V15.7144C15.4748 16.1678 15.1073 16.5353 14.6539 16.5353H5.99344C4.89249 16.5353 4 15.6428 4 14.5418V8.4672Z" fill="white" />
    <path d="M16.1212 10.5646C16.1212 10.2411 16.2697 9.93536 16.5239 9.73526L19.2409 7.59732C19.5486 7.3552 20 7.57439 20 7.96593V16.3441C20 16.7414 19.5369 16.9588 19.2313 16.7049L16.5023 14.4382C16.2609 14.2377 16.1212 13.9402 16.1212 13.6264V10.5646Z" fill="white" />
    <defs>
      <linearGradient id="paint0_linear_2139:9523" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
        <stop stopColor="#539DFF" />
        <stop offset="1" stopColor="#2777FC" />
      </linearGradient>
    </defs>
  </svg>

);

ZoomLogo.defaultProps = {
  size: 24,
};

export default ZoomLogo;
