import React, { useContext, useEffect, useState } from 'react';
import { UserDataContext } from '../../../../App';
import RecurringMeetingIcon from '../../../../shared/icons/RecurringMeetingIcon';
import MeetingDataContext from '../../context/MeetingDataContext';
import ReactTooltip from '../../../../shared/components/tooltip/ReactTooltip';
import getMeetingRecurrence from './utils/functions';
import MeetingRecurrenceContainer from './utils/styles';

interface Props {
  isRecurringMeeting: boolean,
}

const MeetingRecurrence = ({ isRecurringMeeting }: Props) => {
  const userData = useContext(UserDataContext);
  const meetingData = useContext(MeetingDataContext);

  const [meetingRecurrence, setMeetingRecurrence] = useState('');

  useEffect(() => {
    if (meetingData.resolvedState !== 'resolved') return;
    if (meetingData.attendees.resolvedState !== 'resolved') return;
    if (userData.resolvedState !== 'resolved') return;

    const { recurringEventId, calendarId } = meetingData.googleData.ids;
    const { email } = userData.data;
    getMeetingRecurrence(recurringEventId, calendarId, email, meetingData, setMeetingRecurrence);
  }, [meetingData, userData.data.email]);

  if (!isRecurringMeeting) return null;

  return (
    <MeetingRecurrenceContainer>
      <ReactTooltip tip={meetingRecurrence}>
        <RecurringMeetingIcon width="16" height="16" />
      </ReactTooltip>
    </MeetingRecurrenceContainer>
  );
};

export default MeetingRecurrence;
