/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../shared/components/Modal';
import AnnouncementTextAtom from './AnnouncementTextAtom';
import BillingModalTitle from '../BillingModalTitle';
import SuccessfulPaymentCardMol from './StartTrialOrSubscribeCardMol';

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const MainContentContainer = styled.div`
  padding: 16px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:last-child {
    margin-top: 15px;
  }

  span {
    text-align: center;
  }

`;

interface SuccessfulPaymentModalOrgProps {
  isOpen: boolean,
  isTrialExtension: boolean,
  onClickWatchDemo: () => void,
  onClickClose: () => void,
}

const SuccessfulPaymentModalOrg = ({
  isOpen,
  isTrialExtension,
  onClickWatchDemo,
  onClickClose,
}: SuccessfulPaymentModalOrgProps) => {
  return (
    <Modal
      title=""
      isOpen={isOpen}
      setModalClosed={() => { }}
      isExitButtonEnabled={false}
      isHeadless
      blurriness={2}
    >
      <Container>
        <BillingModalTitle title="Congratulations" showRockOnIcon />
        <MainContentContainer>
          <AnnouncementTextAtom isTrialExtension={isTrialExtension} />
          <SuccessfulPaymentCardMol
            onClickWatchDemo={onClickWatchDemo}
            onClickClose={onClickClose}
          />
        </MainContentContainer>
      </Container>
      {/* <ModalLineLocal /> */}
      {/* <ModalFooterWrapper /> */}
    </Modal>
  );
};

export default SuccessfulPaymentModalOrg;
