import React, {
  Dispatch, SetStateAction, useCallback, useContext, useRef, useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { AuthContext } from '../../../../App';
import { blue6, gray1 } from '../../../../shared/colors/COLORS';
import CreateTask from '../../../../shared/components/create-task';
import SaveTemplateModal from '../../../../shared/components/templates-modal';
import AddFileIcon from '../../../../shared/icons/AddFileIcon';
import FolderIcon from '../../../../shared/icons/FolderIcon';
import MoreIcon from '../../../../shared/icons/MoreIcon';
import { MeetingSection } from '../../../../shared/types/types';
import { MeetingData } from '../../../../shared/types/MeetingData';
import { OPEN_EVENT, TEMPLATES_BROWSE_MY_TEMPLATES_BUTTON_FIELD, TEMPLATES_SAVE_AS_TEMPLATE_BUTTON_FIELD } from '../../../../utils/analytics/enums';
import { logTemplateUserAction } from '../../../../utils/analytics/eventLogger';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import DropdownOption from '../share-dropdown/DropdownOption';
import SquareButtonMolecule from '../SquareButtonMolecule';

const Container = styled.div``;

const OptionWrapper = styled.div`
  ${(props: any) => props['data-is-hidden'] && 'display: none;'};
  position: absolute;
  transform: translateY(-100%);
  right: 12.5px;
  width: max-content;
  padding-bottom: 4px;
`;

const Options = styled.div`
  background-color: ${gray1};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  box-shadow: 0px 8px 16px rgba(27, 33, 36, 0.16), 0px 4px 6px -2px rgba(27, 33, 36, 0.06);
  border-radius: 8px;
`;

const ButtonColorModifier = styled.div`
  & > div > div:nth-child(2) > div {
    ${(props: any) => props['data-is-open'] && `background-color: ${blue6};`};
  }
`;

interface Props {
  meetingData: MeetingData,
  meetingSection: MeetingSection,
  isCreateTaskModalOpen: boolean,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
}

const MoreOptions = ({
  meetingData, meetingSection, isCreateTaskModalOpen, setCreateTaskModalOpen, setIsTemplatesOpen,
}: Props) => {
  const optionsRef = useRef(null);
  const [isOptionsOpen, setOptionsOpen] = useDetectOutsideClick(optionsRef, false);
  const [isSaveTemplateModalOpen, setIsSaveTemplateModalOpen] = useState(false);
  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);

  const handleButtonClick = useCallback(
    () => {
      setOptionsOpen(!isOptionsOpen);
    }, [],
  );

  const openSaveTemplateModal = () => {
    setIsSaveTemplateModalOpen(true);
    logTemplateUserAction(
      authState.userId, trackEvent, OPEN_EVENT,
      TEMPLATES_SAVE_AS_TEMPLATE_BUTTON_FIELD,
      meetingData.version, meetingData.meetingId,
    );
  };

  const closeSaveTemplateModal = () => {
    setIsSaveTemplateModalOpen(false);
  };

  const openBrowseTemplates = () => {
    setIsTemplatesOpen(true);
    setOptionsOpen(false);
    logTemplateUserAction(
      authState.userId, trackEvent,
      OPEN_EVENT, TEMPLATES_BROWSE_MY_TEMPLATES_BUTTON_FIELD,
      meetingData.version, meetingData.meetingId,
    );
  };

  return (
    <Container>
      <OptionWrapper data-is-hidden={!isOptionsOpen}>
        <Options ref={optionsRef}>
          <CreateTask
            meetingData={meetingData}
            isOpen={isCreateTaskModalOpen}
            setIsOpen={setCreateTaskModalOpen}
            btnType="full-btn"
          />
          <DropdownOption text="Insert template" icon={<AddFileIcon />} onClick={openBrowseTemplates} />
          <DropdownOption text="Save as template" icon={<FolderIcon />} onClick={openSaveTemplateModal} />
        </Options>
      </OptionWrapper>
      <ButtonColorModifier data-is-open={isOptionsOpen}>
        <SquareButtonMolecule icon={<MoreIcon />} onClick={handleButtonClick} tooltipText="More" />
      </ButtonColorModifier>
      <SaveTemplateModal
        isTemplateModalOpen={isSaveTemplateModalOpen}
        setModalClose={closeSaveTemplateModal}
        openTemplates={openBrowseTemplates}
        meetingSection={meetingSection}
      />
    </Container>
  );
};

export default MoreOptions;
