import React from 'react';
import styled from 'styled-components';
import { gray1 } from '../../colors/COLORS';

interface CssSpinnerProps2 {
  color?: string,
  size: number
  padding: number
  thickness?: number
}

const Spinner = styled.div<CssSpinnerProps2>`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => props.size - props.padding * 2}px;
    height: ${(props) => props.size - props.padding * 2}px;
    margin: ${(props) => props.padding}px;
    border: ${(props) => props.thickness}px solid ${(props) => props.color};
    border-radius: 50%;
    animation: spinner-rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes spinner-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export interface CssSpinnerProps {
  color?: string,
  size?: number
  padding?: number
  thickness?: number
}

const CssSpinner = ({
  color, size = 24, thickness, padding = 3,
}: CssSpinnerProps) => (
  <Spinner color={color} size={size} thickness={thickness} padding={padding}>
    <div />
    <div />
    <div />
    <div />
  </Spinner>
);

CssSpinner.defaultProps = {
  size: 24,
  color: gray1,
  thickness: 3,
  padding: 3,
};

export default CssSpinner;
