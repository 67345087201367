/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../shared/components/Modal';
import LearnMoreText from './LearnMoreText';
import BillingModalTitle from '../BillingModalTitle';
import LearnMoreBackButtonAtom from './LearnMoreBackButtonAtom';
import PremiumPlanText from './PremiumPlanText';
import PremiumFeaturesListMol from '../FirstPromptForExistingUsers/PremiumFeaturesListMol';
import LearnMoreLineAtom from './LearnMoreLineAtom';
import FreePlanTextAtom from './FreePlanTextAtom';
import LearnMoreFreePlanText from './LearnMoreFreePlanText';
import LearnMoreFreePlanBoxRestrictionsText from './LearnMoreFreePlanRestrictionsText';

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const MainContentContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
  }

  #forOnlySevenDollars {
    background: linear-gradient(180deg, #6772C8 0%, #D691E5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    margin-bottom: 25px;

    font-weight: 600;
  }

  #LearnMore {
    margin-top: 12px;
  }

  > *:first-child {
    margin-top: 12px;
    span {
      font-size: 22px;
    }
  }

  > *:nth-child(2) {
    margin-top: 15px;
  }

  > *:nth-child(3) {
    margin-top: 30px;
  }

  > *:nth-child(4) {
    margin-top: 10px;
  }

  > *:nth-child(5) {
    margin-top: 35px;
  }

  > *:nth-child(6) {
    margin-top: 15px;
  }

  > *:nth-child(8) {
    margin-top: 15px;
    margin-bottom: 5px;
  }


`;

interface BillingModalLearnMoreProps {
  isOpen: boolean,
  onClickBack: () => void,
  onClickOutside?: () => void,
}

const BillingModalLearnMore = ({
  isOpen,
  onClickBack,
  onClickOutside,
}: BillingModalLearnMoreProps) => {
  return (
    <Modal
      title=""
      isOpen={isOpen}
      setModalClosed={onClickOutside || (() => { })}
      isExitButtonEnabled={false}
      isHeadless
      blurriness={2}
    >
      <Container>
        <BillingModalTitle title="Learn More" />
        <MainContentContainer>
          <PremiumPlanText />
          <LearnMoreText />
          <PremiumFeaturesListMol />
          <LearnMoreLineAtom />
          <FreePlanTextAtom />
          <LearnMoreFreePlanText />
          <LearnMoreFreePlanBoxRestrictionsText />
          <LearnMoreBackButtonAtom
            onClickBack={onClickBack}
          />
        </MainContentContainer>
      </Container>
      {/* <ModalLineLocal /> */}
      {/* <ModalFooterWrapper /> */}
    </Modal>
  );
};

export default BillingModalLearnMore;
