import React, {
  useCallback, useContext, useEffect, useRef,
} from 'react';
import styled, { css } from 'styled-components';
import { UserDataContext } from '../../../../App';
import { COLORS, blue6, gray1 } from '../../../colors/COLORS';
import { WorkspacePermissionExpanded } from '../../../types/types';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import DropdownOption from '../../../../pages/all-pages/bottom-bar/share-dropdown/DropdownOption';
import WorkspacePermissionTag from './WorkspacePermissionTag';
import WorkspaceMember from '../../../../database/Workspaces/WorkspaceMember';
import CheckMarkIcon from '../../../icons/CheckMarkIcon';
import DustbinIcon from '../../../icons/DustbinIcon';
import { toastDanger, toastInfo } from '../../../../utils/notifications';
import WorkspaceData from '../../../../database/Workspaces/WorkspaceData';
import WorkspaceAPI from '../../../../database/Workspaces/WorkspaceAPI';

const Container = styled.div``;

type OptionsContainerProps = {
  isHidden: boolean
}

const OptionWrapper = styled.div<OptionsContainerProps>`
  ${({ isHidden }) => isHidden && css`
    display: none;
  `}

  position: absolute;
  transform: translateY(15%);
  right: 12.5px;
  width: max-content;
  padding-bottom: 4px;
  min-width: 145px;
`;

const Options = styled.div`
  background-color: ${gray1};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  box-shadow: 0px 8px 16px rgba(27, 33, 36, 0.16), 0px 4px 6px -2px rgba(27, 33, 36, 0.06);
  border-radius: 8px;

  > *:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const ButtonColorModifier = styled.div`
  & > div > div:nth-child(2) > div {
    ${(props: any) => props['data-is-open'] && `background-color: ${blue6};`};
  }
`;

interface Props {
  member: WorkspaceMember,
  workspace: WorkspaceData,
  // eslint-disable-next-line no-unused-vars
  setMemberPermission: (email: string, newPermission: WorkspacePermissionExpanded) => void,
  // eslint-disable-next-line no-unused-vars
  setIsPermissionsOpen: (isOpen: boolean) => void
}

const WorkspaceChangePermissionsDropdown = ({
  member, workspace, setMemberPermission, setIsPermissionsOpen,
}: Props) => {
  const optionsRef = useRef(null);
  const userData = useContext(UserDataContext);
  const [isOptionsOpen, setOptionsOpen] = useDetectOutsideClick(optionsRef, false);
  const isMemberMyself = member.userId === userData.userId;

  useEffect(() => {
    setIsPermissionsOpen(isOptionsOpen);
  }, [isOptionsOpen]);

  const handleButtonClick = useCallback(
    () => {
      setOptionsOpen(!isOptionsOpen);
    }, [],
  );

  const setPermission = (newPermission: WorkspacePermissionExpanded) => () => {
    if (member.permission === 'admin'
      && workspace.permissions.admins.length === 1
      && workspace.numberOfMembers > 1
      && newPermission !== 'admin') {
      toastDanger('There needs to be at least one admin in the workspace');
      return;
    }
    if (member.permission === newPermission) {
      toastInfo('This user already has this permission');
      return;
    }
    if (member.permission === 'admin'
      && workspace.numberOfMembers === 1
      && newPermission === null) {
      toastInfo('Deleting Workspace');
      WorkspaceAPI.deleteWorkspace(workspace.workspaceId, userData);
      return;
    }
    if (isMemberMyself && !userData.isWorkspaceEditor() && newPermission !== null) {
      toastInfo('You are not the admin of this workspace');
      // TODO: Need to call CF to remove myself from the Workspace,
      //  since you don't have permissions to do it directly
      return;
    }
    setMemberPermission(member.email, newPermission);
  };

  return (
    <Container>
      <OptionWrapper isHidden={!isOptionsOpen}>
        <Options ref={optionsRef}>
          {member.permission === 'invited' ? (
            <>
              <DropdownOption text="Invited" icon={member.permission === 'invited' && <CheckMarkIcon fill={COLORS.blue5} />} isWorkspace onClick={() => {}} />
              <DropdownOption text="Uninvite" icon={<DustbinIcon fill={COLORS.blue5} />} isWorkspace onClick={setPermission(null)} />
            </>
          ) : (
            <>
              <DropdownOption text="Member" icon={member.permission === 'member' && <CheckMarkIcon fill={COLORS.blue5} />} isWorkspace onClick={setPermission('member')} />
              <DropdownOption text="Admin" icon={member.permission === 'admin' && <CheckMarkIcon fill={COLORS.blue5} />} isWorkspace onClick={setPermission('admin')} />
              <DropdownOption text="Remove" icon={<DustbinIcon fill={COLORS.blue5} />} isWorkspace onClick={setPermission(null)} />
            </>
          )}
        </Options>
      </OptionWrapper>
      <ButtonColorModifier data-is-open={isOptionsOpen}>
        <WorkspacePermissionTag
          isEditor={userData.isWorkspaceEditor() || member.userId === userData.userId}
          member={member}
          onClick={handleButtonClick}
        />
      </ButtonColorModifier>
    </Container>
  );
};

// { /* <SquareButtonMolecule icon={<MoreIcon />}
// onClick={handleButtonClick} tooltipText="More" /> */ }

export default WorkspaceChangePermissionsDropdown;
