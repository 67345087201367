import React from 'react';
import { COLORS } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const InfoCircleFilled = ({ fill }:Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM12 4.92C12.3085 4.92 12.6101 5.01149 12.8667 5.18291C13.1232 5.35432 13.3232 5.59796 13.4413 5.88301C13.5593 6.16807 13.5902 6.48173 13.53 6.78434C13.4698 7.08695 13.3213 7.36492 13.1031 7.58309C12.8849 7.80126 12.607 7.94983 12.3043 8.01002C12.0017 8.07022 11.6881 8.03932 11.403 7.92125C11.118 7.80318 10.8743 7.60323 10.7029 7.34669C10.5315 7.09015 10.44 6.78854 10.44 6.48C10.44 6.06626 10.6044 5.66947 10.8969 5.37691C11.1895 5.08436 11.5863 4.92 12 4.92ZM14.88 18.48H9.6C9.34539 18.48 9.10121 18.3789 8.92118 18.1988C8.74114 18.0188 8.64 17.7746 8.64 17.52C8.64 17.2654 8.74114 17.0212 8.92118 16.8412C9.10121 16.6611 9.34539 16.56 9.6 16.56H11.28V11.28H10.32C10.0654 11.28 9.82121 11.1789 9.64118 10.9988C9.46114 10.8188 9.36 10.5746 9.36 10.32C9.36 10.0654 9.46114 9.82121 9.64118 9.64118C9.82121 9.46114 10.0654 9.36 10.32 9.36H12.24C12.4946 9.36 12.7388 9.46114 12.9188 9.64118C13.0989 9.82121 13.2 10.0654 13.2 10.32V16.56H14.88C15.1346 16.56 15.3788 16.6611 15.5588 16.8412C15.7389 17.0212 15.84 17.2654 15.84 17.52C15.84 17.7746 15.7389 18.0188 15.5588 18.1988C15.3788 18.3789 15.1346 18.48 14.88 18.48Z" fill={fill} />
  </svg>

);

InfoCircleFilled.defaultProps = {
  fill: COLORS.blue6,
};
export default InfoCircleFilled;
