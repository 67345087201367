import React from 'react';
import styled from 'styled-components';
import ButtonSmall from '../../../../../shared/components/button-small';
import ButtonOutline from '../../../../../shared/components/buttons/ButtonOutline/ButtonOutline';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 30px;

  border-radius: 8px;
  /* width: 100%; */

  box-shadow: 0px 2px 8px rgba(27, 33, 36, 0.16);

  #freeTrial {
    font-weight: bold;
  }

  > *:first-child {
    margin-right: 8px;
    font-weight: 700;
  };

  > *:last-child {
    font-size: 12px;
  };
  font-size: 11px;
`;

interface AreYouSureButtonsCardMolProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onClickTrial: () => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onClickSubscribe: () => void,
}

const AreYouSureButtonsCardMol = ({
  onClickTrial,
  onClickSubscribe,
}: AreYouSureButtonsCardMolProps) => (
  <Container>
    <ButtonOutline padding="8px 20px" onClick={onClickTrial}>
      Cancel
    </ButtonOutline>
    <ButtonSmall padding="10px 10px" text="Downgrade" onClick={onClickSubscribe} />
  </Container>
);

export default AreYouSureButtonsCardMol;
