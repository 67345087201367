/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  messageText, uiTextMedium,
} from '../../typography';
import { fadeIn } from '../../../pages/onboarding/welcome/utils/animations';
import {
  COLORS,
} from '../../colors/COLORS';
import { signOutOfGoogleAndFirebase } from '../../../utils/google/GoogleCalendarSetup';
import FeedbackModal from '../Modal/FeedbackModal';
import MessageIcon from '../../icons/MessageIcon';
import SettingsIcon18x18 from '../../icons/SettingsIcon18x18';
import StarIcon from '../../icons/StarIcon';
import ThumbsUp18x18 from '../../icons/ThumbsUp18x18';
import Chat18x18 from '../../icons/Chat18x18';
import HelpCenter from '../../icons/HelpCenter';
import KeyboardIcon from '../../icons/KeyboardIcon';
import CameraIcon from '../../icons/CameraIcon';
import ROUTES from '../../../routes/ROUTES_CONSTANTS';
import { AuthContext, UserDataContext } from '../../../App';
import InfoCircle from '../../icons/infoCircle';
import Font from '../../typography/Font';
import ConsoleImproved from '../../classes/ConsoleImproved';
import { Page } from '../../types/types';
import DollarIcon from './DollarIcon';
import IntegrationsIcon from '../../icons/IntegrationsIcon';
import QuickSettingsWorkspacesListCtrl from './workspaces/QuickSettingsWorkspacesListCtrl';
import ProfileCard from '../ProfileCard/ProfileCard';
import SendIcon from '../../icons/SendIcon';

const Container = styled.div``;

interface MenuProps {
  dataActive: boolean,
}

const Menu = styled.div<MenuProps>`
  background: ${COLORS.gray1};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 48px;
  right: 0;
  left: 10px;
  opacity: 0;
  visibility: ${({ dataActive }) => (dataActive ? 'visible' : 'hidden')};

  ${({ dataActive }) => (dataActive && css`
    animation: ${fadeIn} ease-in-out forwards 0.2s;
  `)};

  width: 276px;
  z-index: 5;
`;

const Links = styled.div`
  border-top: 2px solid ${COLORS.darkBlue1};
  border-bottom: 2px solid ${COLORS.darkBlue1};
  padding: 8px;
`;

const Setting = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${uiTextMedium};
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 8px;

  & > :first-child {
    margin-right: 8px;
  }

  :hover {
    background: ${COLORS.gray4};
  }
`;

const IntegrationsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: ${COLORS.gray4};
  }
`;

const NewIcon = styled.div`
  ${messageText};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 20px;
  background: ${COLORS.red6};
  color: ${COLORS.gray1};
  border-radius: 8px;
  margin-left: 29px;
`;

const Logout = styled.div`
  ${uiTextMedium};
  border-radius: 6px;
  color: ${COLORS.red6};
  cursor: pointer;
  margin: 8px;
  padding: 8px;

  :hover {
    background: ${COLORS.gray4};
  }
`;

const WorkspaceSection = styled.div`
  padding: 8px;
  /* display: flex; */
  /* flex-direction: column; */
  max-height: calc(100vh - 600px);
  min-height: 100px;
  overflow-y: auto;
`;

const ProfileCardContainer = styled.div`
  padding: 16px;
`;

type ToggleIntercomSource = 'chat_with_us' | 'help_us';

interface QuickSettingsMenuOrgProps {
  isQuickSettingsOpen: boolean,
  setIsQuickSettingsModalOpen: (isOpen: boolean) => void,
  dropdownRef: React.MutableRefObject<null>,
  openMembersModal: () => void,
  openSettingsModal: () => void,
  handleClickBilling: () => void,
  handleClickUpgradeToPremium: () => void,
  handleToggleIntegrationsModal: () => void,
  handleTutorialVideoClick: () => void,
  handleShortcutsModalOpen: () => void,
  handleFeedbackModalOpen: () => void,
  onClickManageBilling: () => void,
  isFeedbackModalOpen: boolean,
  closeFeedbackModal: () => void,
  toggleIntercom: (source: ToggleIntercomSource) => () => void
  isKnownUserId: boolean,
  openWorkspaceFAQ: () => void,
  toggleAboutModal: () => void,
handleClickExportNotes: () => void,
}

const QuickSettingsMenuOrg = ({
  isQuickSettingsOpen,
  setIsQuickSettingsModalOpen,
  dropdownRef,
  openMembersModal,
  openSettingsModal,
  handleClickBilling,
  handleClickUpgradeToPremium,
  handleToggleIntegrationsModal,
  handleTutorialVideoClick,
  handleShortcutsModalOpen,
  handleFeedbackModalOpen,
  onClickManageBilling,
  isFeedbackModalOpen,
  closeFeedbackModal,
  toggleIntercom,
  isKnownUserId,
  openWorkspaceFAQ,
  toggleAboutModal,
  handleClickExportNotes,

}: QuickSettingsMenuOrgProps) => {
  const userData = useContext(UserDataContext);
  const initial = userData.name[0];

  const closeQuickSettings = () => {
    setIsQuickSettingsModalOpen(false);
  };

  return (
    <Container>
      <Menu
        dataActive={isQuickSettingsOpen}
        ref={dropdownRef}
      >
        <div>
          <ProfileCardContainer>
            <ProfileCard user={userData.publicUserData} />
          </ProfileCardContainer>
          <WorkspaceSection>
            <QuickSettingsWorkspacesListCtrl
              onCloseQuickSettings={closeQuickSettings}
              onClickManageBilling={onClickManageBilling}
            />
          </WorkspaceSection>
          <Links>
            {/* <Setting>
              <UserIcon18x18 />
              My account
            </Setting> */}
            {/* <Setting>
              <BellIcon />
              Notifications
            </Setting> */}
            <Setting onClick={openWorkspaceFAQ}>
              <FontAwesomeIcon icon={solid('user-group')} color={COLORS.surface} style={{ marginRight: '8px', height: '15px', width: '15px' }} />
              Workspace FAQ
            </Setting>
            <Setting onClick={openMembersModal}>
              <FontAwesomeIcon icon={regular('paper-plane')} color={COLORS.surface} style={{ marginRight: '8px', height: '15px', width: '15px' }} />
              Invite members to Shepherd
            </Setting>
            <Setting onClick={openSettingsModal}>
              <SettingsIcon18x18 />
              Settings
            </Setting>
            <Setting onClick={handleClickBilling}>
              <DollarIcon />
              {/* <FontAwesomeIcon icon={solid('money-check-dollar')} /> */}
              {/* <i className="fa-solid fa-dollar-sign" /> */}
              Plan & Billing
            </Setting>
            {userData.isOnFreePlan() && (
              <IntegrationsContainer onClick={handleClickUpgradeToPremium}>
                <Setting>
                  <StarIcon />
                  Upgrade Workspace
                  <NewIcon>New</NewIcon>
                </Setting>
              </IntegrationsContainer>
            )}
            <IntegrationsContainer onClick={handleToggleIntegrationsModal}>
              <Setting>
                <IntegrationsIcon />
                Apps
              </Setting>
            </IntegrationsContainer>
            <Setting onClick={handleTutorialVideoClick}>
              <CameraIcon />
              Tutorial video
            </Setting>
            <Setting onClick={handleShortcutsModalOpen}>
              <KeyboardIcon />
              Keyboard shortcuts
            </Setting>
            <Setting onClick={handleFeedbackModalOpen}>
              <ThumbsUp18x18 />
              Give us feedback
            </Setting>
            <FeedbackModal
              isModalOpen={isFeedbackModalOpen}
              setModalClosed={closeFeedbackModal}
            />
            <Setting onClick={toggleIntercom('chat_with_us')}>
              <Chat18x18 />
              Chat with us
            </Setting>
            <Setting onClick={toggleIntercom('help_us')}>
              <HelpCenter />
              Help Center
            </Setting>
            <Setting onClick={handleClickExportNotes}>
              <SendIcon className="w-[18px] h-[18px]" fill="black" />
              Export notes
            </Setting>
            {isKnownUserId && (
              <Setting onClick={toggleAboutModal}>
                <InfoCircle fill={COLORS.surface} width="18" height="18" />
                About
              </Setting>
            )}
          </Links>
          <Logout onClick={() => { signOutOfGoogleAndFirebase(); }}>Log out</Logout>
        </div>
      </Menu>
    </Container>
  );
};

export default QuickSettingsMenuOrg;
