import { Dispatch, SetStateAction } from 'react';
import { DropdownItem } from '../../../../../shared/components/dropdown/utils';
import { SharePlatforms, TaskItem } from '../../../../../shared/types/types';
import { EmailRecipient } from '../../ShareNotesModal';

export const APPS_VIEW = 'appsView';
export const SHARE_VIEW = 'shareView';
export const NOTION_SHARE_VIEW = 'notionShareView';
export const SLACK_SHARE_VIEW = 'slackShareView';
export const TRELLO_SHARE_VIEW = 'trelloShareView';
export const EMAIL_SHARE_VIEW = 'emailShareView';
export const CALENDAR_SHARE_VIEW = 'calendarShareView';

export type ShareModalMode = typeof APPS_VIEW | typeof SHARE_VIEW | typeof NOTION_SHARE_VIEW |
  typeof SLACK_SHARE_VIEW | typeof TRELLO_SHARE_VIEW | typeof EMAIL_SHARE_VIEW
  | typeof CALENDAR_SHARE_VIEW

export interface PlatformData {
  platform: SharePlatforms,
  isActiveIntegration: boolean,
  isEnabled: boolean,
  onClick: () => void,
}

const HEAD = 'head';
const SHARED = 'shared';
const MY = 'my';

export type NotesDataKeys = typeof HEAD | typeof SHARED | typeof MY;

export interface NotesData {
  [HEAD]: string,
  [SHARED]: string,
  [MY]: string,
}

export interface NotesSwitchData {
  isSharedNotesChecked: boolean,
  isSharedNotesDisabled: boolean,
  isPersonalNotesChecked: boolean,
  isPersonalNotesDisabled: boolean,
  isTasksChecked: boolean,
  isTasksDisabled: boolean,
}

export interface PlatformsMultiInputsData {
  emailRecipients: EmailRecipient[],
  setEmailRecipients: Dispatch<SetStateAction<EmailRecipient[]>>,
  slackChannelsToReceive: DropdownItem[],
  setSlackChannelsToReceive: Dispatch<SetStateAction<DropdownItem[]>>,
  taskItems: TaskItem[],
  taskToSend: TaskItem[],
  setTaskToSend: Dispatch<SetStateAction<TaskItem[]>>
}

export class NotesDataUtils {
  static templateText = () => '<div class="tableWrapper"><table style="width: 0px;"><colgroup></colgroup><tbody><tr></tr></tbody></table></div>'

  static dummyNotesData = () => ({
    head: '',
    shared: '',
    my: '',
  } as NotesData)

  static isValidNewText = (newText: string, oldText: string) => {
    if (oldText === newText) return false;
    if (newText === '') return false;
    // if (newText === NotesDataUtils.templateText()) return false;

    return true;
  }
}

// TODO: Check if we need this type
export type DatabaseSimpleUser = {
  userId: string;
  email: string;
  name: string;
}
