import React from 'react';
import PrivateTaskEyeIcon from '../../icons/tasks/PrivateTaskEyeIcon';
import ReactTooltip from '../tooltip/ReactTooltip';

interface Props {
  isPrivate: boolean;
}

const TaskPrivateIcon = ({ isPrivate }: Props) => {
  if (!isPrivate) return null;
  return (
    <ReactTooltip tip="Private task" place="top">
      <PrivateTaskEyeIcon />
    </ReactTooltip>
  );
};

export default TaskPrivateIcon;
