import React from 'react';
import styled from 'styled-components';
import {
  gray1, gray4, gray7, gray9, gray8,
} from '../../colors/COLORS';
import { AssigneeV2 } from '../../types/types';
import { uiText } from '../../typography';
import ProfileIcon from '../profile-icon';
import { profileColors } from '../profile-icon/ProfileIconsRow';

const OptionContainer = styled.div`
  display: flex;
`;

const NoResultContainer = styled.div`
  padding: 14px 12px;
  ${uiText}
  color: ${gray8};
`;

const OptionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  & > div:nth-child(1) {
    ${uiText}
    color: ${gray9};
  }
  & > div:nth-child(2) {
    ${uiText}
    color: ${gray7};
  }
`;

interface Props {
  candidates: AssigneeV2[],
  inputValue: string | null,
  getItemProps: any,
  highlightedIndex: number | null,
  profileIconSize?: number
}

const SearchableAssigneOptions = ({
  candidates, inputValue, getItemProps, highlightedIndex, profileIconSize,
}: Props) => {
  const options = candidates.map((candidate) => ({
    value: candidate.data.email.toLowerCase(),
    name: candidate.data.name.toLowerCase(),
  }));
  const matchedOptions = options
    .filter((option) => !inputValue
    || option.value.includes(inputValue.toLowerCase())
    || option.name.includes(inputValue.toLowerCase()));
  if (matchedOptions.length === 0) {
    return (
      <NoResultContainer>
        No result
      </NoResultContainer>
    );
  }
  return (
    <>
      {
        matchedOptions.map((item, index) => (
          <li
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getItemProps({
              key: item.value,
              index,
              item,
              style: {
                backgroundColor:
                  highlightedIndex === index ? gray4 : gray1,
              },
            })}
            key={item.value}
          >
            <OptionContainer>
              <ProfileIcon
                user={
                  candidates.find(
                    (candidate) => candidate.data.email === item.value,
                  ) as AssigneeV2
                }
                color={profileColors[index]}
                key={item.value}
                size={profileIconSize}
              />
              <OptionTextContainer>
                <div>
                  {candidates.find(
                    (candidate) => candidate.data.email === item.value,
                  )?.data?.name ?? ''}
                </div>
                <div>
                  {item.value}
                </div>
              </OptionTextContainer>
            </OptionContainer>
          </li>
        ))
      }
    </>
  );
};

SearchableAssigneOptions.defaultProps = {
  profileIconSize: 40,
};

export default SearchableAssigneOptions;
