import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { gray4 } from '../../colors/COLORS';

interface Props {
  id?: string,
  children: ReactNode,
  onClick?: () => void,
}

const Container = styled.div`
  padding: 6px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-radius: 6px;
    background-color: ${gray4};
  }
`;

const TaskStatusDropdownItem = ({ id, children, onClick }: Props) => (
  <Container id={id} onClick={onClick}>
    {children}
  </Container>
);

TaskStatusDropdownItem.defaultProps = {
  id: '',
  onClick: () => { },
};

export default TaskStatusDropdownItem;
