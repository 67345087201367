import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import useNotificationStore from '../../../../zustand/useNotificationStore';
import { gray7, gray1 } from '../../../colors/COLORS';
import NotificationInboxIcon from '../../../icons/NotificationInboxIcon';
import ReactTooltip from '../../tooltip/ReactTooltip';

const IconContainer = styled.div`
  padding-top: 4px;
  cursor: pointer;
`;

interface Props {
  isHover: boolean,
  setIsHover: Dispatch<SetStateAction<boolean>>,
  setModalClosed: () => void,
}

export default function UpdateHubIcon({ isHover, setIsHover, setModalClosed }: Props) {
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);
  const { unreadNotificationCount } = useNotificationStore();
  const s = unreadNotificationCount !== 1 ? 's' : '';
  return (
    <ReactTooltip tip={`${unreadNotificationCount} unread notification${s}`}>
      <IconContainer
        id="updates-hub"
        onClick={setModalClosed}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <NotificationInboxIcon fill={isHover ? gray7 : gray1} />
      </IconContainer>
    </ReactTooltip>
  );
}
