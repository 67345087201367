import React from 'react';
import IntegrationsModal from '../../../../../shared/components/quick-settings/integrations/Modal';
import {
  APPS_VIEW, CALENDAR_SHARE_VIEW, EMAIL_SHARE_VIEW, NotesSwitchData,
  NOTION_SHARE_VIEW, PlatformData, PlatformsMultiInputsData, ShareModalMode,
  SHARE_VIEW, SLACK_SHARE_VIEW, TRELLO_SHARE_VIEW,
} from '../utils/ShareModalTypes';
import CalendarShareView from './CalendarShareView';
import EmailView from './EmailView';
import NotionView from './NotionView';
import ShareView from './ShareView';
import SlackView from './SlackView';
import TrelloView from './TrelloView';

interface Props {
  mode: ShareModalMode,
  setMode: React.Dispatch<React.SetStateAction<ShareModalMode>>,
  platformData: PlatformData[]
  platformsMultiInputsData: PlatformsMultiInputsData,
  notesSwitchData: NotesSwitchData
  setNotesSwitchData: React.Dispatch<React.SetStateAction<NotesSwitchData>>
  customMessage: string,
  // eslint-disable-next-line no-unused-vars
  setCustomMessage: (message: string) => void,
}

const ModalBody = ({
  mode, setMode, platformData,
  platformsMultiInputsData, notesSwitchData, setNotesSwitchData,
  customMessage, setCustomMessage,
}: Props) => {
  const {
    emailRecipients, setEmailRecipients,
    slackChannelsToReceive, setSlackChannelsToReceive,
    taskItems, taskToSend, setTaskToSend,
  } = platformsMultiInputsData;

  switch (mode) {
    case SHARE_VIEW:
      return <ShareView platformData={platformData} />;

    case APPS_VIEW:
      return (
        <IntegrationsModal
          isOpen
          setClosed={() => { setMode(SHARE_VIEW); }}
          setActivateConffetti={() => { }}
          activateConfetti={false}
        />
      );

    case NOTION_SHARE_VIEW:
      return (
        <NotionView
          notesSwitchData={notesSwitchData}
          setNotesSwitchData={setNotesSwitchData}
          setMode={setMode}
        />
      );

    case EMAIL_SHARE_VIEW:
      return (
        <EmailView
          notesSwitchData={notesSwitchData}
          setNotesSwitchData={setNotesSwitchData}
          emailRecipients={emailRecipients}
          setEmailRecipients={setEmailRecipients}
          customMessage={customMessage}
          setCustomMessage={setCustomMessage}
        />
      );

    case SLACK_SHARE_VIEW:
      return (
        <SlackView
          channelsToReceive={slackChannelsToReceive}
          setChannelsToReceive={setSlackChannelsToReceive}
          notesSwitchData={notesSwitchData}
          setNotesSwitchData={setNotesSwitchData}
          setMode={setMode}
          emailHeadTemplate={customMessage}
          setCustomMessage={setCustomMessage}
        />
      );

    case CALENDAR_SHARE_VIEW:
      return (
        <CalendarShareView
          notesSwitchData={notesSwitchData}
          setNotesSwitchData={setNotesSwitchData}
        />
      );

    case TRELLO_SHARE_VIEW:
      return (
        <TrelloView
          taskItems={taskItems}
          taskToSend={taskToSend}
          setTaskToSend={setTaskToSend}
          setMode={setMode}
        />
      );

    default:
      return <></>;
  }
};

export default ModalBody;
