import React from 'react';
import styled from 'styled-components';
import { Quote } from '../../../shared/types/types';
import AuthorAtom from './AuthorAtom';
import DownQuoteMarkAtom from './DownQuoteMarkAtom';
import QuoteAtom from './QuoteAtom';
import UpQuoteMarkAtom from './UpQuoteMarkAtom';

const OuterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-height: 880px) {
    display: none;
  }
`;

const QuoteContainer = styled.div`
  width: 440px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  * {
    :first-child {
      align-self: flex-start;
    }
    :last-child {
      align-self: flex-end;
    }
  }
`;

const AuthorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
`;

interface Props {
  quote: Quote,
}

const QuoteMolecule = ({ quote }: Props) => (
  <OuterContainer>
    <QuoteContainer>
      <DownQuoteMarkAtom />
      <QuoteAtom quote={quote.quote} />
      <UpQuoteMarkAtom />
    </QuoteContainer>
    <AuthorContainer>
      <AuthorAtom author={quote.author} />
    </AuthorContainer>
  </OuterContainer>
);

export default QuoteMolecule;
