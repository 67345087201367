import React from 'react';
import styled from 'styled-components';
import { SharePlatforms } from '../../../../../shared/types/types';
import { header200 } from '../../../../../shared/typography';
import {
  SLACK, NOTION, TRELLO, EMAIL, GOOGLE_DOCS, JIRA, ASANA, GOOGLE_CALENDAR,
} from '../../../../../utils/enums';

const Text = styled.h2`
  margin-left: 16px;
  ${header200};
  text-transform: capitalize;
`;

interface Props {
  platform: SharePlatforms,
  customText?: string | null
}

export default function PlatformText({ platform, customText = null }: Props) {
  const getText = () => {
    switch (platform) {
      case SLACK:
      case NOTION:
      case TRELLO:
      case EMAIL:
      case ASANA:
      case JIRA:
        return customText ?? platform;
      case GOOGLE_DOCS:
        return customText ?? 'Google Doc';
      case GOOGLE_CALENDAR:
        return customText ?? 'Add to Google Calendar event';
      default:
        return '';
    }
  };
  return (
    <>
      <Text>
        {getText()}
      </Text>
    </>
  );
}

PlatformText.defaultProps = {
  customText: '',
};
