import moment from 'moment';
import { NodeSpec } from 'prosemirror-model';

const TimestampSpec: NodeSpec = {
  attrs: {
    time: {
      default: null,
    },
  },
  defining: true,
  inline: true,
  isolating: true,
  content: 'text?',
  atom: true,
  group: 'inline',
  /**
   * Here an empty string of marks is given, since
   * it is not desirable to apply diverse and possibly
   * confusing styles to what should be a static
   * timestamp element.
   */
  marks: '',
  toDOM: (node) => [
    'div',
    {
      timestamp: node.attrs.time || 'Error.',
      class: 'resolved-timestamp',
      contenteditable: 'false',
    },
    node.attrs.time
      ? moment(parseInt(node.attrs.time, 10)).format('Do MMM YYYY [|] h:mm a')
      : 0, // Important, keep this hole here for backwards compatibility.
  ],
  parseDOM: [{
    tag: 'div[timestamp]',
    getAttrs: (dom) => ({
      timestamp: (dom as Element).getAttribute('timestamp') ?? '',
    }),
  }, {
    tag: 'span[timestamp]',
    getAttrs: (dom) => ({
      timestamp: (dom as Element).getAttribute('timestamp') ?? '',
    }),
  }],
};

export default TimestampSpec;
