/* eslint-disable no-unused-vars */
import { LinkPreviewInitState } from '../../components/widgets/link-preview';

interface ReactMethods {
  openPreviewDropdown?: (props: LinkPreviewInitState) => void;
  closePreviewDropdown?: () => void;
}

class LinkPreviewAdapter {
  // TODO click on editor panel should trigger the adaptor closePreviewDropdown
  public openPreviewDropdown: CallableFunction = (state: LinkPreviewInitState) => null;

  public closePreviewDropdown: CallableFunction = () => null;

  set reactMethods({
    openPreviewDropdown = (state: LinkPreviewInitState) => null,
    closePreviewDropdown = () => null,
  }: ReactMethods) {
    this.openPreviewDropdown = openPreviewDropdown;
    this.closePreviewDropdown = closePreviewDropdown;
  }
}

export default new LinkPreviewAdapter();
