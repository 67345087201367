import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import {
  overlaySurface, darkBlue4,
} from '../../colors/COLORS';

interface ModalProps {
  isOpen: boolean;
  setModalClosed: () => void
  children: ReactNode;
}

const ConfirmationModal = ({
  isOpen, setModalClosed, children,
}: ModalProps) => (
  <ReactModal
    ariaHideApp={false}
    isOpen={isOpen}
    onRequestClose={setModalClosed}
    style={{
      content: {
        background: darkBlue4,
        position: 'fixed',
        top: '10%',
        left: '50%',
        bottom: 'unset',
        right: 'unset',
        transform: 'translate(-50%, -50%)',
        width: 'fit-content',
        borderRadius: '8px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
        border: 0,
        padding: 0,
      },
      overlay: {
        background: overlaySurface,
        zIndex: 30,
      },
    }}
  >
    {children}
  </ReactModal>
);

export default ConfirmationModal;
