import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../shared/components/Modal';
import BillingModalTitle from '../BillingModalTitle';
import TrialEndedDowngradeButtonAtom from '../TrialEndedModal/TrialEndedDowngradeButtonAtom';
import AnnouncementTextTrialEndedOfferExtensionAtom from './AnnouncementTextTrialEndedOfferExtensionAtom';
import YesIdLikeToExtendButton from './YesIdLikeToExtendButton';
import OrHeadStraightToPremiumPlan from './OrHeadStraightToPremiumPlanAtom';
import UpgradeNowButton from './UpgradeNowButton';
import HorizontalLine from '../../../../shared/icons/HorizontalLine';

const Container = styled.div`
  border-radius: 10px 10px 0 0;
  margin-top: -1px;
  overflow: hidden;
`;

const MainContentContainer = styled.div`
  padding: 25px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
  }

  #announcement {
    margin-bottom: 25px;
    padding: 0 10px;
  }

  #forOnlySevenDollars {
    background: linear-gradient(180deg, #6772C8 0%, #D691E5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    margin-bottom: 25px;

    font-weight: 600;
  }

  #LearnMore {
    margin-top: 25px;
  }

  & > :nth-child(2) {
    margin-bottom: 25px;
  };
`;

const HorizontalLineContainer = styled.div`
  margin-left: -50px;
  height: 2px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 25px;
`;

interface TrialEndedOfferExtensionModalProps {
  isOpen: boolean,
  onClickFreePlan: () => void,
  onClickSubscribe: () => void,
  onClickExtend: () => void,
}

const TrialEndedOfferExtensionModal = ({
  isOpen,
  onClickFreePlan,
  onClickSubscribe,
  onClickExtend,
}: TrialEndedOfferExtensionModalProps) => (
  <Modal
    title=""
    isOpen={isOpen}
    setModalClosed={() => { }}
    isExitButtonEnabled={false}
    isHeadless
    blurriness={2}
  >
    <Container>
      <BillingModalTitle title="Free trial has ended" />
      <MainContentContainer>
        <AnnouncementTextTrialEndedOfferExtensionAtom />
        <YesIdLikeToExtendButton onClick={onClickExtend} />
        <OrHeadStraightToPremiumPlan />
        <UpgradeNowButton onClick={onClickSubscribe} />
      </MainContentContainer>
      <HorizontalLineContainer>
        <HorizontalLine />
      </HorizontalLineContainer>
    </Container>
    <ModalButtonContainer>
      <TrialEndedDowngradeButtonAtom onClick={onClickFreePlan} />
    </ModalButtonContainer>
  </Modal>
);

export default TrialEndedOfferExtensionModal;
