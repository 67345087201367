import {
  CREATE_EVENT, MENTION_EVENT, MENTION_FIELD, NOTE_SOURCE,
} from '../../utils/analytics/enums';
import { logEvent } from '../../utils/analytics/eventLogger';

class MentionLogger {
  static logNewMention(userId: string) {
    logEvent(userId, MENTION_EVENT, {
      action: CREATE_EVENT,
      field: MENTION_FIELD,
      source: NOTE_SOURCE,
    });
  }
}

export default MentionLogger;
