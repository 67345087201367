import React from 'react';
import ButtonContainer from './utils/styling';
import { NavigationButtonData, NavigationButtonFunctions, NavigationButtonStyles } from './utils/NavigationButtonTypes';

interface NavigationButtonProps {
  data: NavigationButtonData,
  functions: NavigationButtonFunctions,
  styles: NavigationButtonStyles
}

const NavigationButton = ({
  data, functions, styles,
}: NavigationButtonProps) => {
  const { text } = data;
  const { onClick } = functions;
  const { color } = styles;

  const handleClick = () => {
    onClick();
  };

  return (
    <ButtonContainer color={color} onClick={handleClick}>
      {text}
    </ButtonContainer>
  );
};

export default NavigationButton;
