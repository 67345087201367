/* eslint-disable no-unused-vars */

import tenorAPiKey from '../../../utils/tenorAPiKey';
import { MediaResources, MediaResource, ResponseToMediaResources } from './types';
import {
  TenorParameters,
  TenorResource,
  TenorResponse,
  TenorFetch,
  TenorRawFetch,
  TenorMediaType,
} from './types/tenor';

/**
 * Convert Tenor API parameters into URLSearchParams
 * @param unprepared Possibly unprepared TenorParameters
 * @returns URLSearchParams that can be later be used to construct the fetch URL
 */
export const tenorURLSearchParams = (unprepared: TenorParameters): URLSearchParams => {
  const key = unprepared.key ?? tenorAPiKey;
  const searchTerm = unprepared.q.replace(/\s+/g, '+');

  const mediaFilter = unprepared.mediaFilter ?? 'minimal';
  const contentfilter = unprepared.contentfilter ?? 'medium';

  const params = new URLSearchParams();

  params.append('key', key);
  params.append('q', searchTerm);

  params.append('media_filter', mediaFilter);
  params.append('contentfilter', contentfilter);

  return params;
};

/**
 * Default fetch, no catch
 * @param prepared prepared URLSearchParams, ready to fetch results
 * @returns Promise<any>
 */
export const tenorRawFetch: TenorRawFetch = (prepared) => fetch(`https://g.tenor.com/v1/search?${prepared.toString()}`, {
  method: 'GET',
});

/**
 * Type safe fetch, no catch
 * @param rawInput magic input that fires the search
 * @returns Promise<TenorResponse>
 */
export const tenorFetch: TenorFetch = (rawInput) => tenorRawFetch(tenorURLSearchParams({
  q: rawInput,
} as TenorParameters)).then((response) => response.json() as Promise<TenorResponse>);

/**
 * TenorResponse to MediaResources converter
 * @param response TenorResponse
 * @returns MediaResources
 */
export const tenorToMediaResources:
ResponseToMediaResources<TenorResponse> = (response) => {
  const array: MediaResources = response.results.map((resource: TenorResource): MediaResource => {
    const mediaRecord = resource.media[0];
    const media: TenorMediaType = mediaRecord.gif ?? mediaRecord.tinygif ?? mediaRecord.mp4 ?? {
      size: NaN,
      dims: [NaN, NaN],
      preview: '', // thumbnail
      url: '', // display & download
    } as TenorMediaType;
    return {
      id: resource.id,
      thumbnailURL: media.preview,
      webformatURL: media.url,
    } as MediaResource;
  });
  return array;
};
