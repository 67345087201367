import React, {
  Dispatch, SetStateAction, useEffect, useRef, useState, useContext,
} from 'react';

import styled from 'styled-components';
import {
  surface, darkBlue4,
} from '../../../../shared/colors/COLORS';
import CrossIcon from '../../../../shared/icons/cross';
import DownArrow from '../../../../shared/icons/downArrow';
import UpArrow from '../../../../shared/icons/UpArrow';
import { header200, messageText } from '../../../../shared/typography';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import { ResolvedState, SlackChannel } from '../../../../shared/types/types';
import {
  InputCrossContainer, CrossContainer, MultiInputContainer, ButtonContainer,
  InsideContainer, InputItemContainer, ArrowContainer, InputItem, Menu,
} from '../../../../shared/components/integration-multi-input';
import { AuthContext, UserDataContext } from '../../../../App';
import { isValidSlackChannel } from '../../../../utils/slack/slackUtils';
import Scrollbar from '../../../../shared/components/scrollbar';
import InputPlaceholder from '../integrations/InputPlaceholder';
import CssSpinner from '../../../../shared/components/css-spinner';

const DropdownNavButton = styled.button`
  padding: 6px 12px 6px 12px;
  width: fit-content;
  height: 32px;
  margin-right: 4px;
  border-radius: 10px;
  border: none;
  background: ${(props: MenuProps) => (props['data-active'] ? surface : 'none')};
  color: ${(props: MenuProps) => (props['data-active'] ? 'white' : darkBlue4)};
  ${header200};
  cursor: pointer;
`;

const LoaderContainer = styled.div`
  ${messageText}
  text-align: center;
`;

interface MenuProps {
  'data-active': boolean
}

interface Props {
  channels: SlackChannel[],
  isResolved: ResolvedState,
  isChecked: boolean,
  dms: SlackChannel[],
  setChannelsToReceive: Dispatch<SetStateAction<SlackChannel[]>>,
  channelsToReceive: SlackChannel[],
}

const SlackMultiInput = ({
  channels, isChecked, dms, setChannelsToReceive, channelsToReceive, isResolved,
}: Props) => {
  const defaultChannelDropdown = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(defaultChannelDropdown, false);

  const [navViewChannels, setNavViewChannels] = useState(true);

  const { userId } = useContext(AuthContext);
  const userData = useContext(UserDataContext);

  useEffect(() => {
    const defaultChannel = userData?.integrations?.slack[0]?.defaultChannels[0] ?? {
      id: '',
      name: '',
    };
    if (checkIfDefaultChannelIsAlreadyInInput(defaultChannel)) return;
    if (!isValidSlackChannel(defaultChannel)) return;
    if (channelsToReceive.length !== 0) return;
    setChannelsToReceive((prevState) => [...prevState,
      defaultChannel]);
  }, [userId]);

  const handleArrowClick = () => {
    setIsActive(!isActive);
  };

  const checkIfDefaultChannelIsAlreadyInInput = (defaultChannel: SlackChannel) => {
    if (channelsToReceive.filter((channel) => channel.id === defaultChannel?.id).length > 0) {
      return true;
    }
    return false;
  };

  const handleDropdownChannelClicked = (item: SlackChannel) => {
    if (channelsToReceive.filter((channel) => channel.id === item.id).length > 0) return;
    setChannelsToReceive([...channelsToReceive, item]);
    setIsActive(false);
  };

  const handleRemoveChannel = (
    item: SlackChannel,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    const tempChannels = channelsToReceive.filter((channel) => channel.name !== item.name);
    setChannelsToReceive(tempChannels);
  };

  const handleChannelsButtonClick = () => {
    setNavViewChannels(true);
  };

  const handleDmsButtonClick = () => {
    setNavViewChannels(false);
  };

  if (!isChecked) return null;

  const shouldDisplayPlaceholder = channelsToReceive.length === 0;
  const dropdownChannels = channels;

  return (
    <>
      <MultiInputContainer>
        <InsideContainer onClick={handleArrowClick}>
          <InputItemContainer>
            <InputPlaceholder shouldShow={shouldDisplayPlaceholder} placeholder="Select channel or DM" />
            {channelsToReceive.map((inputChannel) => (
              <InputCrossContainer key={inputChannel.id}>
                <InputItem>{inputChannel.name}</InputItem>
                <CrossContainer onClick={(event) => handleRemoveChannel(inputChannel, event)}>
                  <CrossIcon />
                </CrossContainer>
              </InputCrossContainer>
            ))}
          </InputItemContainer>
          <ArrowContainer onClick={handleArrowClick}>
            {isActive ? <UpArrow /> : <DownArrow />}
          </ArrowContainer>
        </InsideContainer>
      </MultiInputContainer>
      <Scrollbar maxHeight="29vh">
        <Menu
          data-active={isActive}
          ref={defaultChannelDropdown}
        >
          <ButtonContainer>
            <DropdownNavButton data-active={navViewChannels} onClick={handleChannelsButtonClick}>
              Channel
            </DropdownNavButton>
            <DropdownNavButton data-active={!navViewChannels} onClick={handleDmsButtonClick}>
              DMs
            </DropdownNavButton>
          </ButtonContainer>
          {isActive && (
            <ul>
              {
                navViewChannels ? (
                  dropdownChannels.map((channel) => (
                    <li key={channel.id}>
                      <button type="button" onClick={() => handleDropdownChannelClicked(channel)}>{channel.name}</button>
                    </li>
                  ))
                ) : (
                  dms.map((dm) => (
                    <li key={dm.id}>
                      <button type="button" onClick={() => handleDropdownChannelClicked(dm)}>{dm.name}</button>
                    </li>
                  ))
                )
              }
              {isResolved === 'pending'
              && (
                <LoaderContainer>
                  <CssSpinner color={surface} size={30} thickness={4} padding={4} />
                </LoaderContainer>
              )}
              {isResolved === 'rejected'
              && (
                <LoaderContainer>
                  <span>Something went wrong</span>
                </LoaderContainer>
              )}
            </ul>
          )}
        </Menu>
      </Scrollbar>
    </>
  );
};

export default SlackMultiInput;
