import React from 'react';
import styled from 'styled-components';
import Font from '../../../shared/typography/Font';
import { EmailRecipient } from './ShareNotesModal';

const Container = styled.div`
  cursor: pointer;
`;

export interface AddRemoveProps {
  emailRecipients: EmailRecipient[],
  handleAddAllEmails: () => void,
  handleRemoveEmails: () => void,
}

const AddRemoveEmailsButton = ({
  emailRecipients,
  handleAddAllEmails,
  handleRemoveEmails,
}: AddRemoveProps) => {
  const isEmailsInputEmpty = emailRecipients.length === 0;
  if (isEmailsInputEmpty) {
    return (
      <Container onClick={handleAddAllEmails}>
        <Font font="uiTextMediumS" color="blue6">Add all recipients</Font>
      </Container>
    );
  }
  return (
    <Container onClick={handleRemoveEmails}>
      <Font font="uiTextMediumS" color="blue6">Remove all</Font>
    </Container>
  );
};

export default AddRemoveEmailsButton;
