/* eslint-disable no-unused-vars */
import { captureException } from '@sentry/react';
import { mapDatabaseTaskItemToTaskItem, mapTasksWithoutPublicUserDataV2ToTasksWithPublicUserDataV2 } from '../../database/utils/mapTaskData';
import {
  DatabaseTaskItem, ResolvedState, TrelloData,
} from '../../shared/types/types';
import { sendTaskToTrelloBoard } from '../../utils/trello/trelloAPIs';

/**
 * API to interact with Trello.
 */
class TrelloAPI {
  /**
   * Creates a new task in the board of the user
   *
   * @param taskId Document id of the task in the `/task` collection
   */
  static async createTask(
    taskId: string,
    databaseTaskItem: DatabaseTaskItem,
    isTrelloEnabled: boolean,
    trelloData: TrelloData,
  ): Promise<ResolvedState> {
    if (!isTrelloEnabled) return 'rejected';

    const taskWithoutAssigneeV2 = mapDatabaseTaskItemToTaskItem(taskId, databaseTaskItem);
    const tasks = await mapTasksWithoutPublicUserDataV2ToTasksWithPublicUserDataV2(
      [taskWithoutAssigneeV2],
    );
    try {
      return await sendTaskToTrelloBoard(isTrelloEnabled, [...tasks],
        trelloData);
    } catch (error) {
      captureException(error);
      console.log('Error sending task to trello board');
      console.log(error);
      return 'rejected';
    }
  }
}

export default TrelloAPI;
