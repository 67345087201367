import React from 'react';
import { COLORS } from '../colors/COLORS';

interface Props {
  fill?: string,
  width?: string,
  height?: string,
}

const LockIcon = ({ fill, width, height }: Props) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.89383 1.66647C12.2722 1.66647 14.2121 3.54698 14.3076 5.90194L14.3113 6.084V7.39722C15.7918 7.9111 16.8545 9.31814 16.8545 10.9738V14.548C16.8545 16.6388 15.1599 18.333 13.0695 18.333H6.70199C4.61155 18.333 2.91699 16.6388 2.91699 14.548V10.9738C2.91699 9.31801 3.97987 7.91088 5.46049 7.39711L5.4605 6.0646C5.47117 3.62461 7.45704 1.65584 9.89383 1.66647ZM13.0613 6.084V7.18884H6.71049V6.06734L6.71533 5.90458C6.80745 4.23356 8.19662 2.90907 9.8911 2.91646C11.6428 2.91647 13.0613 4.33501 13.0613 6.084ZM13.0695 8.43884H6.70199C5.30186 8.43884 4.16699 9.5735 4.16699 10.9738V14.548C4.16699 15.9483 5.30186 17.083 6.70199 17.083H13.0695C14.4696 17.083 15.6045 15.9483 15.6045 14.548V10.9738C15.6045 9.5735 14.4696 8.43884 13.0695 8.43884ZM10.5052 11.7508C10.4638 11.4457 10.2023 11.2106 9.88591 11.2106C9.54073 11.2106 9.26091 11.4904 9.26091 11.8356V13.6864L9.26661 13.7712C9.308 14.0763 9.5695 14.3114 9.88591 14.3114C10.2311 14.3114 10.5109 14.0316 10.5109 13.6864V11.8356L10.5052 11.7508Z" fill={fill} />
  </svg>
);

LockIcon.defaultProps = {
  fill: COLORS.blue6,
  width: '20',
  height: '20',
};

export default LockIcon;
