import styled from 'styled-components';

const ProfileIconsContainer = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  column-gap: 5px;

  & > svg {
    padding: 0px;
  }
`;

export default ProfileIconsContainer;
