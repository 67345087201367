import { StripeConstants } from './StripeConstants';
import { StripeCheckoutSessionOrigin } from './StripeTypes';

class StripeUtils {
  static isValidStripeCheckoutOrigin = (
    candidate: string,
  ): candidate is StripeCheckoutSessionOrigin => Object.values(StripeConstants.CHECKOUT_ORIGINS)
    .some((validCheckoutOrigin) => validCheckoutOrigin === candidate);
}

export default StripeUtils;
