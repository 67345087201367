import { Dispatch, SetStateAction } from 'react';
import { MeetingData } from '../../shared/types/MeetingData';

const resetTitle = (
  meetingData: MeetingData,
  setMeetingData: Dispatch<SetStateAction<MeetingData>>,
) => {
  const originalTitle = meetingData.data.title;
  const copyMeeting = meetingData;
  copyMeeting.data.title = '';
  setMeetingData((prev) => {
    const copy = prev;
    copy.data.title = '';
    return copy;
  });
  setMeetingData((prev) => {
    const copy = prev;
    copy.data.title = originalTitle;
    return copy;
  });
};

export default resetTitle;
