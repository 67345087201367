import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const EditPenIcon = ({ size, color }: Props) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.30722 9.59629L9.38269 1.68035C10.4217 0.661888 12.1069 0.661888 13.1459 1.68035L14.2245 2.73767C15.2635 3.75613 15.2635 5.40804 14.2245 6.42651L6.11717 14.3737C5.65359 14.8281 5.02506 15.0831 4.36901 15.0831H1.04878C0.740346 15.0831 0.492487 14.834 0.500174 14.5317L0.583693 11.2477C0.600298 10.627 0.859392 10.0353 1.30722 9.59629ZM13.4484 3.49843L12.3698 2.44111C11.7594 1.84281 10.7691 1.84281 10.1588 2.44111L9.53186 3.05565L12.8218 6.27994L13.4484 5.66575C14.0588 5.06744 14.0588 4.09674 13.4484 3.49843ZM2.08332 10.3571L8.75577 3.81641L12.0457 7.0407L5.34108 13.6129L5.2519 13.6929C5.00559 13.8952 4.69352 14.0072 4.36901 14.0072L1.61121 14.0068L1.68089 11.2752C1.69012 10.9306 1.83438 10.6011 2.08332 10.3571ZM15.5 14.5452C15.5 14.2481 15.2543 14.0073 14.9512 14.0073H9.22583L9.15136 14.0122C8.8835 14.0478 8.67705 14.2729 8.67705 14.5452C8.67705 14.8423 8.92274 15.0832 9.22583 15.0832H14.9512L15.0257 15.0783C15.2935 15.0426 15.5 14.8176 15.5 14.5452Z" fill={color} />
  </svg>
);

EditPenIcon.defaultProps = {
  size: 16,
  color: '#1B2124',
};

export default EditPenIcon;
