import React from 'react';
import Font from '../../../../shared/typography/Font';
import ButtonWhite from '../../../../shared/components/buttons/ButtonWhite/ButtonWhite';

interface Props {
  onClick: () => void;
}

const TrialEndedDowngradeButtonAtom = ({ onClick }: Props) => (
  <ButtonWhite onClick={onClick} padding="0px 12px" id="LearnMore">
    <Font font="uiTextS" color="grayText">
      Downgrade to the free plan (with limitations)
    </Font>
  </ButtonWhite>
);

export default TrialEndedDowngradeButtonAtom;
