/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TaskStatus } from '../../../../types/types';
import TaskDropdown from '../../../task-card/TaskDropdown';
import TaskStatusDropdownOptions from '../../../task-card/TaskStatusDropdownOptions';
import useDetectClickOutside from '../../hooks/fixed-click-outside';

interface DropdownWrapperProps {
  show: boolean;
  top: number;
  left: number;
}

const DropdownWrapper = styled.div<DropdownWrapperProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  top: clamp(10px, ${({ top }) => top}px, calc(100vh - 188px));
  left: ${({ left }) => left}px;
`;

const DropdownOptions = styled.div`
`;

interface TaskStatusFloatingPanelProps {
  show: boolean,
  left: number,
  top: number,
  status: TaskStatus,
  updateStatus: (newStatus: TaskStatus) => void,
  closeContents: () => void,
}

const TaskStatusFloatingPanel = ({
  show,
  left,
  top,
  status,
  updateStatus,
  closeContents,
}: TaskStatusFloatingPanelProps) => {
  const ref = useDetectClickOutside({ onTriggered: () => { closeContents(); } });

  return (
    <DropdownWrapper
      show={show && left !== 0 && top !== 0}
      left={left}
      top={top}
      ref={ref}
    >
      <DropdownOptions
        onClick={() => { closeContents(); }}
      >
        {TaskStatusDropdownOptions({
          status,
          updateStatus,
        })}
      </DropdownOptions>
    </DropdownWrapper>
  );
};

export default TaskStatusFloatingPanel;
