import React from 'react';
import Font from '../../../../shared/typography/Font';

interface Props {
  isTrialExtension: boolean,
}
const AnnouncementTextAtom = ({ isTrialExtension = false }: Props) => (
  isTrialExtension ? (
    <>
      <Font font="uiTextS" color="gray7">
        <b>
          You
          {'\''}
          re all set!
        </b>
      </Font>
      <Font font="uiTextS" color="gray7">
        Your 7 day Premium extension is now live :)
      </Font>
    </>
  ) : (
    <>
      <Font font="uiTextS" color="gray7">
        <b>
          You
          {'\''}
          re all set!
        </b>
      </Font>
      <Font font="uiTextS" color="gray7">
        You are officially a member of our Premium club!
      </Font>
    </>
  )
);

export default AnnouncementTextAtom;
