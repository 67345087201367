import { Plugin } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

function isEditorEmpty(view: EditorView) {
  if (view.state.doc.textContent) return false;
  if (view.state.doc.childCount > 1) return false;
  const { firstChild } = view.state.doc;
  console.debug(firstChild);
  if (!firstChild) return true;
  switch (firstChild.type.name) {
    case 'paragraph':
      return firstChild.content.size === 0;
    case 'table':
      try {
        return (firstChild.content as any).content[0].content.size === 0;
      } catch (e) {
        return false;
      }
    case 'bullet_list':
      return false;
    case 'ordered_list':
      return false;
    case 'todo_list':
      return false;
    default:
      return true;
  }
}

/**
 * DO NOT PUT THIS PLUGIN IN SETUP!
 * It won't, by any means, break the editor,
 * but this needs to see the kind of note it
 * is being placed in (to display different
 * messages).
 *
 * So, this need to be used inside a React
 * component/hook, where it can receive that
 * information.
 */

export default function placeholder(text: string) {
  const update = (view: EditorView) => {
    if (!isEditorEmpty(view)) {
      view.dom.removeAttribute('data-placeholder');
    } else {
      // If it is empty, wait for a bit
      setTimeout(() => {
        // If it's still empty after waiting,
        // create the placeholder
        if (isEditorEmpty(view)) {
          view.dom.setAttribute('data-placeholder', text);
        }
      }, 300);
    }
  };

  return new Plugin({
    view(view) {
      update(view);
      return { update };
    },
  });
}
