import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../App';
import EmailTagMolecule from '../../shared/components/invite-members-modal/email-component/EmailTagMolecule';
import LineAtom from '../../shared/components/new-feaures-modal/LineAtom';
import RefresherPopupMolecule from '../dashboard-welcome-page/refresherPopup/RefresherPopupMolecule';
import WelcomeBackMolecule from '../dashboard-welcome-page/components/WelcomeBackMolecule';
import { SHOW_QUOTE_THRESHOLD } from '../dashboard-welcome-page/components/WelcomeConstants';
import RockHandAtom from '../dashboard-welcome-page/components/RockHandAtom';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  width: 100%;
  top: 175px;

  transform-origin: top center;

  @media (max-height: 1150px) {
    transform: scale(0.9);
    top: 170px;
  }

  @media (max-height: 1075px) {
    transform: scale(0.8);
    top: 160px;
  }

  @media (max-height: 1015px) {
    transform: scale(0.7);
    top: 150px;
  }

  @media (max-height: 960px) {
    top: 140px;
  }
`;

const WelcomeBackContainer = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  margin-bottom: 75px;
`;

const LineContainer = styled.div`
  width: 440px;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (max-height: ${SHOW_QUOTE_THRESHOLD}) {
    display: none;
  };
`;

const PopupContainer = styled.div`
  margin-top: 32px;
`;

const IvanTestingPage = () => {
  const userData = useContext(UserDataContext);
  return (
    <Container>
      <EmailTagMolecule user={userData.publicUserData} resolvedState="resolved" />
      <TopContainer>
        <RockHandAtom />
      </TopContainer>
      <WelcomeBackContainer>
        <WelcomeBackMolecule firstName="Ivan" />
        <LineContainer>
          <LineAtom />
        </LineContainer>
        <PopupContainer>
          <RefresherPopupMolecule />
        </PopupContainer>
      </WelcomeBackContainer>
    </Container>
  );
};

export default IvanTestingPage;
