import React from 'react';

interface NotionIconProps {
  width?: string;
}

const GoogleDocsIcon = ({
  width = '40',
}: NotionIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={width}
    viewBox="0 0 48 48"
    style={{ fill: '#000000' }}
  >
    <rect width="12" height="24" x="18" y="12" fill="#fff" />
    <path fill="#fbc02d" d="M29,3H19h-7c-1.657,0-3,1.343-3,3v29l5,1l5-1V13h10l1-5L29,3z" />
    <polygon fill="#4caf50" points="29,45 19,45 18,40 19,35 29,35 30,40" />
    <path fill="#2e7d32" d="M19,35v10h-7c-1.657,0-3-1.343-3-3v-7H19z" />
    <polygon fill="#1e88e5" points="39,35 34,36 29,35 29,13 34,12 39,13" />
    <path fill="#1565c0" d="M29,35h10v7c0,1.657-1.343,3-3,3h-7V35z" />
    <polygon fill="#e53935" points="39,13 29,13 29,3" />

  </svg>

);

GoogleDocsIcon.defaultProps = {
  width: '40',
};

export default GoogleDocsIcon;
