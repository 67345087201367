/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../../shared/components/Modal';
import BillingModalTitle from '../../BillingModalTitle';
import UpgradeNowButtonsCardMol from './UpgradeNowCardMol';
import UpgradeNowTextAtom from './UpgradeNowTextAtom';

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const MainContentContainer = styled.div`
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
  }

  #announcement {
    margin-bottom: 25px;
    padding: 0 10px;
  }

  #forOnlySevenDollars {
    background: linear-gradient(180deg, #6772C8 0%, #D691E5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    margin-bottom: 25px;

    font-weight: 600;
  }

  #LearnMore {
    margin-top: 12px;
  }
`;

interface UpgradeNowModalProps {
  isOpen: boolean,
  onClickSubscribe: () => void,
  onClickClose: () => void,
}

const UpgradeNowModal = ({
  isOpen,
  onClickSubscribe,
  onClickClose,
}: UpgradeNowModalProps) => {
  return (
    <Modal
      title=""
      isOpen={isOpen}
      setModalClosed={() => { }}
      isExitButtonEnabled={false}
      isHeadless
      blurriness={8}
    >
      <Container>
        <BillingModalTitle title="Upgrade Now" showCloseIcon onClose={onClickClose} />
        <MainContentContainer>
          <UpgradeNowTextAtom />
          <UpgradeNowButtonsCardMol
            onClickClose={onClickClose}
            onClickSubscribe={onClickSubscribe}
          />
        </MainContentContainer>
      </Container>
      {/* <ModalLineLocal /> */}
      {/* <ModalFooterWrapper /> */}
    </Modal>
  );
};

export default UpgradeNowModal;
