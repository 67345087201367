/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../shared/components/Modal';
import ForOnly7DollarsYouWillGetTextAtom from './ForOnly7DollarsYouWillGetText';
import AnnouncementTextAtom from './AnnouncementTextAtom';
import BillingModalTitle from '../BillingModalTitle';
import LearnMoreButtonAtom from '../LearnMoreButton';
import PremiumFeaturesListMol from './PremiumFeaturesListMol';
import StartTrialOrSubscribeCardMol from './StartTrialOrSubscribeCardMol';

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const MainContentContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
  }

  #announcement {
    margin-bottom: 25px;
    padding: 0 10px;
  }

  #forOnlySevenDollars {
    background: linear-gradient(180deg, #6772C8 0%, #D691E5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    margin-bottom: 25px;

    font-weight: 600;
  }

  #LearnMore {
    margin-top: 12px;
  }
`;

interface BillingModalFirstPromptExistingUsersProps {
  isOpen: boolean,
  onClickTrial: () => void,
  onClickSubscribe: () => void,
  onClickLearnMore: () => void,
}

const BillingModalFirstPromptExistingUsers = ({
  isOpen,
  onClickTrial,
  onClickSubscribe,
  onClickLearnMore,
}: BillingModalFirstPromptExistingUsersProps) => {
  return (
    <Modal
      title=""
      isOpen={isOpen}
      setModalClosed={() => { }}
      isExitButtonEnabled={false}
      isHeadless
      blurriness={2}
    >
      <Container>
        <BillingModalTitle title="New Shepherd Premium" />
        <MainContentContainer>
          <AnnouncementTextAtom />
          <ForOnly7DollarsYouWillGetTextAtom />
          <PremiumFeaturesListMol />
          <StartTrialOrSubscribeCardMol
            onClickTrial={onClickTrial}
            onClickSubscribe={onClickSubscribe}
          />
          <LearnMoreButtonAtom onClick={onClickLearnMore} />
        </MainContentContainer>
      </Container>
      {/* <ModalLineLocal /> */}
      {/* <ModalFooterWrapper /> */}
    </Modal>
  );
};

export default BillingModalFirstPromptExistingUsers;
