import { StripeSubscription } from '../../external/Stripe/StripeTypes';
import { SDateT, WorkspaceDb } from '../../shared/types/types';

class WorkspaceDbClass implements WorkspaceDb {
  data: {
    name: string;
    description: string;
    color: string;
    picture: string;
    createdBy: string;
  }

  permissions: {
    allowedDomains: string[];
    members: string[];
    editors: string[];
    managers: string[];
    admins: string[];
    invites: string[];
  }

  date: {
    created: SDateT;
    lastUpdated: SDateT;
  }

  privateId: string;

  billing: {
    subscription: StripeSubscription;
   }

  constructor(workspaceDb: WorkspaceDb) {
    this.data = workspaceDb.data;
    this.permissions = workspaceDb.permissions;
    this.date = workspaceDb.date;
    this.privateId = workspaceDb.privateId;
    this.billing = workspaceDb.billing;
  }
}

export default WorkspaceDbClass;
