import React from 'react';
import Font from '../../../../shared/typography/Font';

const ForOnly7DollarsYouWillGetTextAtom = () => (
  <Font font="header600" id="forOnlySevenDollars">
    For only $7pm you will get:
  </Font>
);

export default ForOnly7DollarsYouWillGetTextAtom;
