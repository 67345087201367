import React from 'react';
import styled from 'styled-components';
import ButtonOutline from '../../../../shared/components/buttons/ButtonOutline/ButtonOutline';
import MagnifyingGlassIcon from '../../../../shared/components/prosemirror/components/icons/magnifying-glass';
import EditSquareIcon from '../../../../shared/icons/editSquare';
import LightningIcon from '../../../../shared/icons/lightningIcon';
import Font from '../../../../shared/typography/Font';

const Container = styled.div`
  width: 250px;
  padding: 15px 0 15px 0;

  background: linear-gradient(180deg, #B6ACFF 0%, #E175FF 100%);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;

  #featuresList {
    text-align: left;
  }

  #small {
    font-size: 10px;
    margin-top: 2px;
  }

  &:last-child {
    align-self: center;
  }
  
  margin-bottom: 30px;
`;

const InnerContainer = styled.div``;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    width: 20px;
  }
  margin-bottom: 5px;
`;

const LearnMoreContainer = styled.div`
  cursor: pointer;
  color: white;
  margin-top: 10px;
  font-size: 10px;

  &:hover {
    color:#D691E5;
    border: 1p solid #6772C8;
  }
`;

interface Props {
  enableLearnMore?: boolean;
  onClickLearnMore?: () => void;
}

const PremiumFeaturesListMol = ({
  enableLearnMore = false,
  onClickLearnMore = () => { },
}: Props) => (
  <Container>
    <InnerContainer>
      <ItemContainer>
        <EditSquareIcon fill="#CF23FF" />
        <Font font="defaultSmall" color="white" id="featuresList">Unlimited # of notes</Font>
      </ItemContainer>
      <ItemContainer>
        <MagnifyingGlassIcon fill="#CF23FF" width={14} height={14} />
        <Font font="defaultSmall" color="white" id="featuresList">Unlimited search history</Font>
      </ItemContainer>
      <ItemContainer>
        <LightningIcon fill="#CF23FF" />
        <Font font="defaultSmall" color="white" id="featuresList">Unlimited app integrations</Font>
      </ItemContainer>
    </InnerContainer>
    <Font font="small" color="white" id="small">And so much more...</Font>
    {enableLearnMore && (
      <LearnMoreContainer>
        <ButtonOutline padding="2px 6px" onClick={onClickLearnMore}>Learn More</ButtonOutline>
      </LearnMoreContainer>
    )}
  </Container>
);

export default PremiumFeaturesListMol;
