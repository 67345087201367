/* eslint-disable no-unused-vars */
import {
  useContext, useState,
} from 'react';
import MeetingDataContext from '../../../../pages/meeting/context/MeetingDataContext';
import mentionsAdapter from '../logic/suggestions/mentions-adapter';

export default function useMentionSuggester() {
  const meetingContext = useContext(MeetingDataContext);

  const [show, setShow] = useState<boolean>(false);

  const [left, setLeft] = useState<number>(0);

  const [top, setTop] = useState<number>(0);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [results, setResults] = useState<any[]>([]);

  const [showPromotionMenu, setShowPromotionMenu] = useState<boolean>(false);

  function rotateSelectionUp() {
    const maxLength = showPromotionMenu ? 2 : results.length;

    if (selectedIndex < 1) {
      setSelectedIndex(maxLength - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  }

  function rotateSelectionDown() {
    const maxLength = showPromotionMenu ? 2 : results.length;

    setSelectedIndex((selectedIndex + 1) % maxLength);
  }

  function setViewable(value: boolean) {
    setShow(value);
  }

  function setPos(x: number, y: number) {
    setTop(y);
    setLeft(Math.min(Math.max(x, 0), 320));
  }

  function getSelectedMention() {
    if (selectedIndex >= results.length) return '';
    return results[selectedIndex].item;
  }

  function setResultsAndReset(data: any[]) {
    setResults(data);
    setSelectedIndex(0);
  }

  mentionsAdapter.reactMethods = {
    moveSelectionUp: rotateSelectionUp,
    moveSelectionDown: rotateSelectionDown,
    setResults: setResultsAndReset,
    setShow: setViewable,
    setPos,
    getSelectedMention,
    // eslint-disable-next-line arrow-body-style
    getAttendees: () => {
      // console.debug(meetingContext.attendees.attendees);
      return meetingContext.attendees.attendees;
    },
    setShowPromotionMenu: (value) => {
      setShowPromotionMenu(value);
    },
    getShowPromotionMenu: () => showPromotionMenu,
    setSelection: (value: number) => {
      setSelectedIndex(value);
    },
    getSelectionIndex: () => selectedIndex,
    getShow: () => show,
  };

  return {
    mentionsShow: [show, setShow] as const,
    mentionsLeft: [left, setLeft] as const,
    mentionsTop: [top, setTop] as const,
    mentionsContents: [results, setResults] as const,
    mentionsSelected: [selectedIndex, setSelectedIndex] as const,
    mentionPromotionShow: [showPromotionMenu, setShowPromotionMenu] as const,
  };
}
