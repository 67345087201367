import React from 'react';
import { StripeConstants } from '../../../../../external/Stripe/StripeConstants';
import Font from '../../../../../shared/typography/Font';

const UpgradeNowTextAtom = () => (
  <Font font="uiTextS" color="gray7" id="announcement">
    It looks like you’re trying to access a feature available only in the Premium plan.
    <br />
    <br />
    Upgrade now for
    {' '}
    <b>
      only $7pm
    </b>
    {' '}
    to search your notes history more than
    {' '}
    <b>
      {StripeConstants.FREE_PLAN.MAX_PAST_SEARCH_DAYS}
      {' '}
      days
    </b>
    {' '}
    in the past and
    {' '}
    <b>
      {StripeConstants.FREE_PLAN.MAX_FUTURE_SEARCH_DAYS}
      {' '}
      days
    </b>
    {' '}
    in the future.
  </Font>
);

export default UpgradeNowTextAtom;
