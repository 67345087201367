import React from 'react';
import { gray1 } from '../colors/COLORS';

interface Props {
  className?: string,
  fill?: string,
  size?: number
}
const SendIcon = ({ className = '', fill, size }: Props) => (
  <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.0873 3.82612C18.347 2.65474 17.2577 1.59774 16.0783 1.93907L3.03115 5.73552L2.90524 5.77737C1.68058 6.23827 1.49268 7.94389 2.63974 8.65003L8.03602 11.9716L11.2825 17.35L11.3554 17.461C12.1165 18.5273 13.8017 18.269 14.1859 16.9765L18.0568 3.94359L18.0873 3.82612ZM16.4874 3.12753C16.728 3.10169 16.9337 3.33577 16.8587 3.58711L12.9877 16.6205L12.9623 16.6844C12.8413 16.9223 12.499 16.9465 12.3527 16.7041L9.27935 11.6132L13.6564 7.20796L13.7167 7.13766C13.8974 6.89238 13.8761 6.54523 13.6535 6.32408L13.5832 6.2638C13.3379 6.08307 12.9908 6.1044 12.7696 6.32703L8.39852 10.7266L3.29499 7.58554L3.23989 7.54416C3.04169 7.36361 3.1084 7.01468 3.37998 6.93586L16.4266 3.13954L16.4874 3.12753Z" fill={fill} />
  </svg>
);

SendIcon.defaultProps = {
  fill: gray1,
  size: 20,
};

export default SendIcon;
