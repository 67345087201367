/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import HelpIconAtom from '../../../../pages/all-notes/HelpIconAtom';
import { RelevantMeetingsData } from '../../../types/types';
import { MeetingData } from '../../../types/MeetingData';
import Font from '../../../typography/Font';
import ReactTooltip from '../../tooltip/ReactTooltip';
import RelevantMeetingsList from './RelevantMeetingsList';

const Container = styled.div`
  position: absolute;
  right: -15px;
  top: -15px;

  border-radius: 8px;
  /* width: 250px; */
  padding: 10px;

  min-width: 200px;
  max-width: 250px;

  margin: 10px;

  box-shadow: 0px 2px 8px rgb(27 33 36 / 16%);
  border: 1px solid #E7E9EF;
  
  border-radius: 14px;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;


  z-index: 100000;
  
  &:hover {
    transition: all 1s;
  }
`;

const TitleContainer = styled.div`
  align-self: center;
  margin-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  & > *:first-child {
    margin-right: 10px;
    span {
      font-weight: 500;
    }
  }
`;

export interface RelevantMeetingsPopupProps {
  relevantMeetings: RelevantMeetingsData,
  isOpen: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  onClose: () => void,
  // eslint-disable-next-line no-unused-vars
  onClickMeeting: (meeting: MeetingData) => void,
}

const RelevantMeetingsPopup = ({
  relevantMeetings,
  isOpen, onClose, onClickMeeting,
}: RelevantMeetingsPopupProps) => {
  if (!isOpen) return null;

  return (
    <Container onMouseLeave={onClose}>
      <TitleContainer>
        <Font font="messageText">
          Related Meetings
        </Font>
        <ReactTooltip tip="A list of notes that could be related to this meeting" place="left" multiline enableOnMobile>
          <HelpIconAtom />
        </ReactTooltip>
      </TitleContainer>
      <RelevantMeetingsList relevantMeetings={relevantMeetings} onClickMeeting={onClickMeeting} />
    </Container>
  );
};

export default RelevantMeetingsPopup;
