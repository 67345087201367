import React from 'react';
import styled from 'styled-components';
import SlackNotificationSwitchsGroupMolecule from '../../../../../pages/meeting/modal/SlackModalComponents/SlackNotifications/SlackNotificationSwitchsGroupMolecule';
import { ResolvedStateExtended, SlackNotificationsSettings } from '../../../../types/types';
import { header400 } from '../../../../typography';
import { Container } from '../task-email/TaskEmailNotificationSettings';

const HeadText = styled.div`
  ${header400}
  margin-bottom: 20px;
`;

const SpacingModifier = styled.div`
  & > div > div:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

interface Props {
  notifications: SlackNotificationsSettings,
  // eslint-disable-next-line no-unused-vars
  updateSlackNotifications: (val: SlackNotificationsSettings) => void,
  isResolved: ResolvedStateExtended,
}

const SlackNotificationSettings = ({
  notifications, updateSlackNotifications, isResolved,
}: Props) => (
  <Container>
    <HeadText>
      Slack notifications
    </HeadText>
    <SpacingModifier>
      <SlackNotificationSwitchsGroupMolecule
        notifications={notifications}
        updateSlackNotifications={updateSlackNotifications}
        isResolved={isResolved}
      />
    </SpacingModifier>
  </Container>
);

export default SlackNotificationSettings;
