import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface AddColumnBeforeProps {
  fill?: string;
  width?: number;
  height?: number;
}

const AddColumnBeforeIcon = ({
  fill,
  width,
  height,
}: AddColumnBeforeProps) => (
  <svg width={width} height={height} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 6.75,0 C 8.26878,0 9.5,1.23122 9.5,2.75 v 0.5 C 9.5,3.66421 9.16421,4 8.75,4 8.33579,4 8,3.66421 8,3.25 V 2.75 C 8,2.05964 7.44036,1.5 6.75,1.5 h -4 C 2.0596,1.5 1.5000003,2.05964 1.5000003,2.75 v 10 C 1.5000003,13.4404 2.0596,14 2.75,14 h 4 C 7.44036,14 8,13.4404 8,12.75 v -0.4375 c 0,-0.4142 0.33579,-0.75 0.75,-0.75 0.41421,0 0.75,0.3358 0.75,0.75 V 12.75 c 0,1.5188 -1.23122,2.75 -2.75,2.75 h -4 C 1.2312,15.5 0,14.2688 0,12.75 v -10 C 0,1.23122 1.2312,0 2.75,0 Z m 2,5 C 9.16421,5 9.5,5.33579 9.5,5.75 V 7 h 1.25 c 0.41421,0 0.75,0.33579 0.75,0.75 0,0.41421 -0.33579,0.75 -0.75,0.75 H 9.5 V 9.75 C 9.5,10.1642 9.16421,10.5 8.75,10.5 8.33579,10.5 8,10.1642 8,9.75 V 8.5 H 6.75 C 6.33579,8.5 6,8.16421 6,7.75 6,7.33579 6.33579,7 6.75,7 H 8 V 5.75 C 8,5.33579 8.33579,5 8.75,5 Z"
      fill={fill}
    />
  </svg>
);

AddColumnBeforeIcon.defaultProps = {
  fill: darkBlue4,
  width: 12,
  height: 16,
};

export default AddColumnBeforeIcon;
