import React from 'react';
import { blue6 } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const PreviewIcon = ({ fill }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.2403 3.33702L10.0017 3.33301C6.55098 3.33301 3.44113 5.76891 1.71751 9.75454C1.64983 9.91104 1.64983 10.0883 1.71751 10.2448L1.83695 10.5137C3.53873 14.244 6.47962 16.5533 9.75991 16.6623L9.99841 16.6663C13.4492 16.6663 16.559 14.2304 18.2827 10.2448C18.3512 10.0863 18.3503 9.90667 18.2802 9.74887L18.1641 9.4877C16.4582 5.75043 13.5161 3.4459 10.2403 3.33702ZM10.0076 4.57419L10.2067 4.57848L10.4292 4.59005C13.0935 4.7787 15.5439 6.75428 17.0242 9.99893L17.0165 10.0189C15.499 13.3334 12.9642 15.3207 10.2158 15.4205L10.0034 15.4237L9.78919 15.4208L9.56725 15.4093C6.98568 15.2264 4.60539 13.3617 3.11603 10.2994L2.97508 9.99893L3.10556 9.7215C4.67606 6.4773 7.24298 4.5749 10.0076 4.57419ZM9.99966 6.76089C8.19933 6.76089 6.7405 8.21062 6.7405 9.99984C6.7405 11.7884 8.19949 13.238 9.99966 13.238C11.7999 13.238 13.2597 11.7883 13.2597 9.99984C13.2597 8.21072 11.8001 6.76089 9.99966 6.76089ZM9.99966 8.00282C11.1098 8.00282 12.0097 8.89667 12.0097 9.99984C12.0097 11.1023 11.1097 11.996 9.99966 11.996C8.88984 11.996 7.9905 11.1025 7.9905 9.99984C7.9905 8.89645 8.88974 8.00282 9.99966 8.00282Z" fill={fill} />
  </svg>
);

PreviewIcon.defaultProps = {
  fill: blue6,
};

export default PreviewIcon;
