import { Form } from 'formik';
import styled from 'styled-components';
import { gray1 } from '../../../../shared/colors/COLORS';

export const OnboardingContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding: 0px 32px;

  background-color: ${gray1};
`;

export const OnboardingTitle = styled.div`
  font-family: 'Inter', SF Pro Text;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
`;

export const OnboardingSubtitle = styled.div`
  font-family: 'Inter', SF Pro Text;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 25px 0 66px 0;
`;

export const OnboardingForm = styled(Form)`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 40px;

  width: 100%;
`;
