import React from 'react';
import styled from 'styled-components';
import { header200 } from '../../typography';
import { TaskStatus } from '../../types/types';
import TaskStatusCheckbox from '../task-card/TaskStatusCheckbox';

const Container = styled.div`
  ${header200};
  margin-bottom: 24px;
`;

interface Props {
  status: TaskStatus
  // eslint-disable-next-line no-unused-vars
  updateStatus: (newStatus: TaskStatus) => void,
}

const TaskStatusDropdown = ({ status, updateStatus }: Props) => (
  <Container>
    <TaskStatusCheckbox
      status={status}
      updateStatus={updateStatus}
      showText
      willCloseAfterClick
    />
  </Container>
);

export default TaskStatusDropdown;
