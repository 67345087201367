import { captureException } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../utils/firebase';
import { ResolvedState, SlackData } from '../shared/types/types';
import { toastDanger } from '../utils/notifications';
import { DISABLE_EVENT, SLACK_INTEGRATION } from '../utils/analytics/enums';
import { logIntegrationsEvent } from '../utils/analytics/eventLogger';
import { COLLECTIONS, USER_PATH } from './FirebaseConstants';

export const dbUpdateSlackData = async (
  userId: string,
  slackData: SlackData[],
): Promise<ResolvedState> => {
  const dbData = { [USER_PATH.integrations.slack]: slackData };
  return updateDoc(doc(firestore, COLLECTIONS.USERS, userId), dbData)
    .then(() => {
      console.log('Slack data updated successfuly');
      return 'resolved' as ResolvedState;
    })
    .catch((error) => {
      console.log('something went wrong', error);
      toastDanger('Error', 'Something went wrong while trying to update Slack notifications settings');
      Sentry.captureException(error);
      return 'rejected' as ResolvedState;
    });
};

export const slackAPIRemoveSlackIntegration = (userId: string) => {
  const defaultChannelData = { [USER_PATH.integrations.slack]: [] };
  updateDoc(doc(firestore, COLLECTIONS.USERS, userId), defaultChannelData)
    .then(() => {
      console.log('Successfully removed Slack data from user data');
      logIntegrationsEvent(userId, DISABLE_EVENT, SLACK_INTEGRATION);
    })
    .catch((error) => {
      console.log(error);
      captureException(error);
    });
};
