import { IndexableMention } from '.';
import { PublicUserDataV2 } from '../../../../types/types';

interface ReactMethods {
  moveSelectionUp: CallableFunction;
  moveSelectionDown: CallableFunction;
  setResults: CallableFunction;
  getAttendees: CallableFunction;
  // eslint-disable-next-line no-unused-vars
  setShow: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setPos: (x: number, y: number) => void;
  getSelectedMention: () => string;
  // eslint-disable-next-line no-unused-vars
  setShowPromotionMenu: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  getShowPromotionMenu: () => boolean;
  // eslint-disable-next-line no-unused-vars
  setSelection: (value: number) => void;
  getSelectionIndex: () => number;
  getShow: () => boolean;
}

class MentionsAdapter {
  public moveSelectionUp: CallableFunction = () => null;

  public moveSelectionDown: CallableFunction = () => null;

  public setSelection: CallableFunction = () => null;

  public setShow: CallableFunction = () => null;

  public setPos: CallableFunction = () => null;

  public getSelectedMention: CallableFunction = () => null;

  public getSelectionIndex: CallableFunction = () => null;

  public setResults: CallableFunction = () => null;

  public getAttendees: CallableFunction = () => [];

  /**
   * To interact with tasks, the following process will
   * be followed:
   *
   * 1. The user types the suggestion and resolves it.
   * 2. The mention then has a one-time-show "promote"
   *    pop-up. This allows the user to "promote" the
   *    mention to a task.
   * 3. If the user presses on any of the options, the
   *    node will either "promote" itself to a task or
   *    remain a mention.
   * 4. If the user doesn't choose anything, and the
   *    promotion occasion has been missed, then the
   *    mention will stay a mention.
   * 5. If, however, no choice is made (the promote
   *    pop-up has been shown 0 times), then the user
   *    can come and at any later point upgrade it.
   */

  // TODO: Probably try to set this with a uuid, so that it's
  //  kept for when the mention needs to get upgraded.
  //
  // Either that, or use findPositionOfNodeBefore, which should
  // work.

  public setShowPromotionMenu: CallableFunction = () => null;

  public getShowPromotionMenu: CallableFunction = () => null;

  public insertTask: CallableFunction = () => null;

  public getShow: CallableFunction = () => null;

  set reactMethods({
    moveSelectionUp,
    moveSelectionDown,
    setResults,
    setShow,
    setPos,
    getSelectedMention,
    getAttendees,
    setShowPromotionMenu,
    getShowPromotionMenu,
    setSelection,
    getSelectionIndex,
    getShow,
  }: ReactMethods) {
    this.setResults = setResults;
    this.moveSelectionUp = moveSelectionUp;
    this.moveSelectionDown = moveSelectionDown;
    this.setShow = setShow;
    this.setPos = setPos;
    this.getSelectedMention = getSelectedMention;
    this.getAttendees = getAttendees;
    this.setShowPromotionMenu = setShowPromotionMenu;
    this.getShowPromotionMenu = getShowPromotionMenu;
    this.setSelection = setSelection;
    this.getSelectionIndex = getSelectionIndex;
    this.getShow = getShow;
  }

  private mentions: Set<string> = new Set();

  set unknownMentions(mentions: string[]) {
    this.mentions = new Set(mentions ?? []);
  }

  get unknownMentions(): string[] {
    return Array.from(this.mentions);
  }

  public addUnknownMention(mention: string) {
    if (!this.hasUnknownMention(mention)) {
      this.mentions.add(mention);
    }
  }

  public hasUnknownMention(mention: string): boolean {
    return this.mentions.has(mention);
  }

  get indexableMentions(): IndexableMention[] {
    return (this.getAttendees() ?? [])
      .map((attendee: PublicUserDataV2) => ({
        ...attendee,
      }))
      .concat(
        this.unknownMentions.map((mention) => ({
          data: {
            name: mention,
            email: mention,
          },
        })),
      );
  }
}

export default new MentionsAdapter();
