/* eslint-disable no-unused-vars */
import moment, { Moment } from 'moment';
import {
  useContext,
  useState,
} from 'react';
import { AuthContext } from '../../../../App';
import TaskAPI from '../../../../external/TaskAPI/TaskAPI';
import MeetingDataContext from '../../../../pages/meeting/context/MeetingDataContext';
import { AssigneeV2, TaskItem, TaskStatus } from '../../../types/types';
import taskAdapter from '../logic/nodes/task-adapter';

export default function useTaskControls() {
  const user = useContext(AuthContext);

  const meetingData = useContext(MeetingDataContext);

  /**
   * Status menu section
   */
  const [statusMenuShow, setStatusMenuShow] = useState<boolean>(false);

  const [status, setStatus] = useState<any>('');

  const [statusFn, setStatusFn] = useState<(ts: TaskStatus) => void>(() => () => {});

  /**
   * Date menu section
   */
  const [dateMenuShow, setDateMenuShow] = useState<boolean>(false);

  const [dueDate, setDueDate] = useState<Date | Moment | null>(null);

  const [dateFn, setDateFn] = useState<(d: Date | Moment | null) => void>(() => () => {});

  /**
   * Assignee menu section
   */

  const [assigneeMenuShow, setAssigneeMenuShow] = useState<boolean>(false);

  const [assignee, setAssignee] = useState<AssigneeV2 | null>(null);

  const [assigneeFn, setAssigneeFn] = useState<(a: AssigneeV2 | null) => void>(() => () => {});

  /**
   * Task modal section
   */

  const [taskModalShow, setTaskShow] = useState<boolean>(false);

  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);

  const [left, setLeft] = useState<number>(0);

  const [top, setTop] = useState<number>(0);

  function setPos(x: number, y: number) {
    setTop(y);
    setLeft(Math.min(Math.max(x, 0), 320));
  }

  function setStatusFnTo(to: CallableFunction) {
    setStatusFn(() => to);
  }

  function setDateFnTo(to: CallableFunction) {
    setDateFn(() => to);
  }

  function setAssigneeFnTo(to: CallableFunction) {
    setAssigneeFn(() => to);
  }

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const contents: (hasRecurring?: boolean) => [Moment | null, string | null][] = (
    hasRecurring = false,
  ) => [
    [null, null],
    [moment(), 'Today'],
    [moment().add(1, 'day'), 'Tomorrow'],
    [moment().add(2, 'days')].flatMap((m: Moment) => [m, m.format('dddd')]) as [Moment, string],
    [moment().day(8)].flatMap((m: Moment) => [m, m.format('[Next] dddd')]) as [Moment, string],
    [moment().day(9)].flatMap((m: Moment) => [m, m.format('[Next] dddd')]) as [Moment, string],
    [moment().day(10)].flatMap((m: Moment) => [m, m.format('[Next] dddd')]) as [Moment, string],
    ...(hasRecurring ? [/* Add fetching here */] : []),
  ];

  function rotateSelectionUp() {
    if (selectedIndex < 1) {
      setSelectedIndex(contents().length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  }

  function rotateSelectionDown() {
    setSelectedIndex((selectedIndex + 1) % contents().length);
  }

  const [taskItem, setTaskItem] = useState<TaskItem | null>(null);

  taskAdapter.reactMethods = {
    setStatusMenuShow: (
      value: boolean,
      statusValue: string,
      statusFnValue: (ts: TaskStatus) => void,
      topValue: number,
      leftValue: number,
    ) => {
      setTop(topValue);
      setLeft(leftValue);
      setStatus(statusValue);
      setStatusFnTo(statusFnValue);
      if (value) {
        setDateMenuShow(false);
        setAssigneeMenuShow(false);
      }
      setStatusMenuShow(value);
    },
    calendarMenuShow: (
      value: boolean,
      updateDate: (d: Date | Moment | null) => void,
      due: Date | Moment | null,
      topValue: number,
      leftValue: number,
    ) => {
      setTop(topValue);
      setLeft(leftValue);
      setDueDate(due);
      setDateFnTo(updateDate);
      if (value) {
        setStatusMenuShow(false);
        setAssigneeMenuShow(false);
      }
      setDateMenuShow(value);
    },
    assigneeMenuShow: (
      value: boolean,
      assigneeFnValue: (a: AssigneeV2 | null) => void,
      assigneeValue: AssigneeV2 | null,
      topValue: number,
      leftValue: number,
    ) => {
      setTop(topValue);
      setLeft(leftValue);
      setAssignee(assigneeValue);
      setAssigneeFnTo(assigneeFnValue);
      if (value) {
        setDateMenuShow(false);
        setStatusMenuShow(false);
      }
      setAssigneeMenuShow(value);
    },
    getCurrentUser: () => user || null,
    getCurrentMeetingData: () => ({
      ...meetingData,
      name: meetingData.data.title,
    }) || null,
    getCalendarMenuShow: () => dateMenuShow,
    rotateCalendarMenuUp: () => rotateSelectionUp(),
    rotateCalendarMenuDown: () => rotateSelectionDown(),
    getSelectedDate: () => contents()[selectedIndex][0],
    setDate: () => {
      console.debug('selectedIndex:', selectedIndex);
      try {
        dateFn(contents()[selectedIndex][0]);
      } catch (e) {
        console.debug('Error setting date!');
      }
      setDateMenuShow(false);
    },
    setTaskModalShow: async (value, taskId) => {
      if (!value) {
        setTaskShow(false);
      } else {
        TaskAPI.getTask(taskId)
          .then((t) => {
            setTaskItem(t);
            setTaskShow(true);
          });
      }
    },
    setCalendarMenuIndex: (index) => setSelectedIndex(index),
  };

  return {
    statusMenuShow: [statusMenuShow, setStatusMenuShow] as const,
    tasksLeft: [left, setLeft] as const,
    tasksTop: [top, setTop] as const,
    status: [status, setStatus] as const,
    statusFn: [statusFn, setStatusFnTo] as const,
    taskCalendarShow: [dateMenuShow, setDateMenuShow] as const,
    dueDate: [dueDate, setDueDate] as const,
    dateFn: [dateFn, setDateFnTo] as const,
    assigneeMenuShow: [assigneeMenuShow, setAssigneeMenuShow] as const,
    assignee: [assignee, setAssignee] as const,
    assigneeFn: [assigneeFn, setAssigneeFnTo] as const,
    selectedDateIndex: [selectedIndex, setSelectedIndex] as const,
    taskModalShow: [taskModalShow, setTaskShow] as const,
    deleteModalShow: [deleteModalShow, setDeleteModalShow] as const,
    taskItem: [taskItem, setTaskItem] as const,
  };
}
