import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import { surface, gray3, gray7 } from '../../colors/COLORS';
import { header200, uiText } from '../../typography';

const InputStyle = styled(Field)`
  ${uiText};
  color: ${gray7};
  
  background: ${gray3};
  
  box-sizing: border-box;
  border: 2px solid ${gray3};
  border-radius: 10px;
  
  width: 100%; 
  padding: 8px 10px;

  :hover {
    cursor: pointer;
  }

  :focus {
    outline: none;
  }
`;

const Label = styled.p`
  ${header200};
  color: ${surface};
  margin: 20px 0 4px;
`;

const Error = styled.p`
  color: red;
  font-size: 10px;
  margin: 0;
  margin-top: 4px;
  height: 10px;
`;

export interface InputProps {
  id: string,
  label: string,
  name: string,
  error: string | undefined,
  touched: boolean | undefined,
}

const Input = ({
  id, label, name, error, touched,
}: InputProps) => (
  <div>
    <label htmlFor={id}>
      <Label>{label}</Label>
      <InputStyle id={id} name={name} autoComplete="off" />
      <Error>{error && touched && error}</Error>
    </label>
  </div>
);

export default Input;
