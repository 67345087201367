/* eslint-disable jsx-a11y/alt-text */
import prependHttp from 'prepend-http';
import { ProsemirrorNode } from 'prosemirror-suggest';
import { EditorView } from 'prosemirror-view';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import useDetectClickOutside from '../../hooks/fixed-click-outside';
import {
  darkBlue4, gray1, gray4, gray9,
} from '../../../../colors/COLORS';
import getOpenGraph from '../../../../logic/opengraph';
import { header500, uiText } from '../../../../typography';
import ButtonSmall from '../../../button-small';
import ReactTooltip from '../../../tooltip/ReactTooltip';
import linkPreviewAdapter from '../../logic/adapters/link-preview-adapter';
import { DropdownOffset, offsetBoundByproduct, offsetTo } from '../../logic/computation/offsets';
import CopyIcon from '../icons/copy';
import EditIcon from '../icons/edit';
import UnlinkIcon from '../icons/unlink';
import CssSpinner from '../../../css-spinner';

interface MenuDropdownProps {
  offset?: DropdownOffset | null;
}

const ButtonContainer = styled.div`
  margin-top: 8px;
`;

const InputContainer = styled.div`
  position: relative;
  overflow: hidden;
  background: ${gray4};
  border: 2px solid ${gray4};
  border-radius: 8px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 175ms;
  display: flex;
  justify-content: center;
  align-items: center;

  :first-of-type {
    margin-bottom: 8px;
  }

  :focus-within {
    border: 2px solid #058FEF;
  }
`;

const Input = styled.input`
  outline: 0px solid transparent;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  flex-grow: 1;
  ${uiText}
  border: 0;
`;

const MenuDropdown = styled.div<MenuDropdownProps>`
  z-index: 10;
  position: absolute;
  ${({ offset }) => (offset ? `top:${offset.top}px;left:${offset.left}px;` : '')}
  display: flex;
  flex-flow: column;
  width: 440px;
  padding: 16px;
  border-radius: 10px;
  background-color: ${gray1};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  font-family: "Inter, sans-serif";
`;

const Title = styled.a`
  ${header500};
  cursor: pointer;
`;

const NormalTitle = styled.span`
  ${header500};
  color: ${gray9} !important;
`;

const PreviewLinkTitle = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`;

const PreviewLinkControls = styled.div`
  display: flex;
  flex-flow: row;
`;

const PreviewLinkDescription = styled.p`
  ${uiText};
  color: ${darkBlue4};
  margin: 8px 0 0 0;
  width: 408px;
  height: 40px;

  text-overflow: ellipsis;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  border-radius: 10px;
  display: flex;
  items-align: center;
  justify-content: center;
`;

export interface LinkPreviewProps {
  show: boolean;
  anchor: HTMLElement | null;
  anchorNode: ProsemirrorNode<any> | null;
  href: string | null;
  view: EditorView;
}

export interface LinkPreviewInitState {
  show?: boolean; // because sometimes this is redundant (ie: open & close calls)
  anchor: HTMLElement;
  anchorNode: ProsemirrorNode<any>;
  href: string;
}

const LinkPreview = ({
  show,
  anchor,
  anchorNode,
  href,
  view,
}: LinkPreviewProps) => {
  const linkInput = useRef<HTMLInputElement | null>(null);
  const linkTitleInput = useRef<HTMLInputElement | null>(null);

  const anchorTitleAttrib = ((anchor?.getAttribute('title')) || href) ?? '';
  const dropdown = useRef<HTMLDivElement | null>(null);
  // TODO offset == null is equivalent to offset = { top: 0, left: 0 }

  const [offset, setOffset] = useState<DropdownOffset | null>(null);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [imageURL, setImageURL] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [newTitle, setNewTitle] = useState<string>(anchorTitleAttrib);
  const [newLink, setNewLink] = useState<string>(href ?? '');

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const clickOutside = useDetectClickOutside({
    onTriggered: () => { linkPreviewAdapter.closePreviewDropdown(); },
  });

  useEffect(() => {
    if (!anchor || !href) { return; }

    setIsEditing(false);
    setNewTitle(anchorTitleAttrib);
    setNewLink(href);
  }, [show, anchor]);

  const fetchOpenGraph = useCallback(async () => {
    if (!anchor || !href) { return; }

    setIsEditing(false);
    setIsLoading(true);
    try {
      const data = await getOpenGraph(href);
      setTitle(data.title || href);
      setDescription(data.description);
      setType(data.type);
      setImageURL(data.image);

      setIsLoading(false);
      setIsValid(true);
    } catch (e) {
      setTitle('');
      setDescription('');
      setType('');
      setImageURL('');

      setIsLoading(false);
      setIsValid(false);
    }
  }, [href]);

  // Fetch OpenGraph Data
  useEffect(() => {
    if (!anchor || !href) { return; }

    if (!isEditing) { fetchOpenGraph(); }
  }, [fetchOpenGraph]);

  // Set the offset such that the dropdown is bounded by the editor space
  useEffect(() => {
    setIsEditing(false);
    if (!anchor || !href) { return; }

    const editorRoot = view.dom.parentElement;
    if (editorRoot && dropdown.current) {
      const boundIn = editorRoot.getBoundingClientRect();
      const toBound = dropdown.current.getBoundingClientRect();
      const anchorBounds = anchor.getBoundingClientRect();

      const absoluteInput = offsetTo(toBound, anchorBounds);
      const offsetAdjustment = offsetBoundByproduct(
        absoluteInput,
        boundIn,
      );

      setOffset({
        left: absoluteInput.left + offsetAdjustment.left,
        top: absoluteInput.top + offsetAdjustment.top,
      });
    } else setOffset(null);
  }, [show, anchor, title, description, type, imageURL]);

  const copyLinkToClipboard = () => {
    if (!anchor || !href) { return; }

    navigator.clipboard.writeText(href);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 1000);
  };

  const openEditModal = () => {
    setIsEditing(true);
  };

  const updateAnchor = () => {
    if (!anchor || !anchorNode) { return; }

    const pos = view.posAtDOM(anchor, 0);
    const { state } = view;
    const { tr, schema, selection } = state;
    const { $head } = selection;
    const anchorWrapper = $head.nodeBefore;

    if (anchorWrapper && anchorWrapper.type.name === 'textInlineNode') {
      // valid anchor wrapped in a node
      const $pos = view.state.doc.resolve(pos);
      if ($pos && $pos.nodeAfter) {
        const end = pos + $pos.nodeAfter.nodeSize;

        try {
          const linkMark = schema.marks.link.create({
            title: newTitle,
            href: prependHttp(newLink),
          });
          const textNode = schema.nodes.textInlineNode.create(
            {
              text: newTitle,
            },
            [schema.text(newTitle, [linkMark])],
            [],
          );

          view.dispatch(
            tr.replaceWith(pos - 1, end, textNode),
          );
        } catch (e) {
          // console.log(e);
        } finally {
          linkPreviewAdapter.closePreviewDropdown();
        }
      }
    }
  };

  const unlinkAnchor = () => {
    if (!anchor || !anchorNode) { return; }

    const { tr } = view.state;
    const pos = view.posAtDOM(anchor, 0);
    const { $head } = view.state.selection;
    const anchorWrapper = $head.nodeBefore;

    if (anchorWrapper && anchorWrapper.type.name === 'textInlineNode') {
      // valid anchor wrapped in a node
      const { textContent } = anchorWrapper;
      const $pos = view.state.doc.resolve(pos);
      if ($pos && $pos.nodeAfter) {
        const end = pos + $pos.nodeAfter.nodeSize;

        try {
          view.dispatch(
            tr.replaceWith(pos - 1, end, view.state.schema.text(textContent)),
          );
        } catch (e) {
          // console.log(e);
        } finally {
          linkPreviewAdapter.closePreviewDropdown();
        }
      }
    }
  };

  const controls = (
    <PreviewLinkControls
      key="link-preview-controls"
    >
      <ReactTooltip
        tip={isLinkCopied ? 'Link copied!' : 'Copy link'}
        place="top"
      >
        <span
          className="link-preview-controls"
          onClick={copyLinkToClipboard}
          onKeyDown={() => { }}
          role="presentation"
          style={{
            marginRight: '8px',
            cursor: 'pointer',
          }}
        >
          <CopyIcon
            width={17}
            height={17}
            fill="#1B2124"
          />
        </span>
      </ReactTooltip>
      <ReactTooltip tip="Edit link" place="top">
        <span
          className="link-preview-controls"
          onClick={openEditModal}
          onKeyDown={() => { }}
          role="presentation"
          style={{
            marginRight: '8px',
            cursor: 'pointer',
          }}
        >
          <EditIcon
            width={17}
            height={17}
            fill="#1B2124"
          />
        </span>
      </ReactTooltip>
      <ReactTooltip tip="Remove link" place="top">
        <span
          className="link-preview-controls"
          onClick={unlinkAnchor}
          onKeyDown={() => { }}
          role="presentation"
          style={{
            marginRight: '8px',
            cursor: 'pointer',
          }}
        >
          <UnlinkIcon
            width={17}
            height={17}
            fill="#1B2124"
          />
        </span>
      </ReactTooltip>
    </PreviewLinkControls>
  );

  let previewContent;
  if (show) {
    // Invalid dropdown
    if (!isValid) {
      previewContent = (
        <MenuDropdown
          ref={dropdown}
          offset={offset}
        >
          <PreviewLinkTitle>
            <Title
              href={href || ''}
              target="_blank"
              style={{
                width: '237px',
                height: '20px',
                wordBreak: 'break-word',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {href}
            </Title>
            {controls}
          </PreviewLinkTitle>
          <PreviewLinkDescription>
            Preview information is not available for
            {' '}
            {href}
            {' '}
            .
          </PreviewLinkDescription>
        </MenuDropdown>
      );
    }

    // Loading dropdown
    if (isValid && isLoading) {
      previewContent = (
        <MenuDropdown
          ref={dropdown}
          offset={offset}
        >
          <PreviewLinkTitle>
            <Title
              href={href || ''}
              target="_blank"
              style={{
                width: '100%',
                height: '20px',
                wordBreak: 'break-word',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {href}
            </Title>
            {controls}
          </PreviewLinkTitle>
          <PreviewLinkDescription>
            The link preview is loading...
          </PreviewLinkDescription>
          <ImageContainer>
            <CssSpinner size={40} color="black" />
          </ImageContainer>
        </MenuDropdown>
      );
    }

    // Edit dropdown
    if (isValid && !isLoading && isEditing) {
      previewContent = (
        <MenuDropdown
          ref={dropdown}
          offset={offset}
          style={{
            width: '273px',
            height: '188px',
          }}
        >
          <NormalTitle
            style={{
              marginBottom: '16px',
            }}
          >
            Edit Link
          </NormalTitle>
          <InputContainer>
            <Input
              ref={linkTitleInput}
              placeholder={anchorTitleAttrib}
              value={newTitle}
              onChange={(e) => {
                setNewTitle(e.target.value);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </InputContainer>
          <InputContainer>
            <Input
              ref={linkInput}
              placeholder={href || ''}
              value={newLink}
              onChange={(e) => {
                setNewLink(e.target.value);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </InputContainer>
          <ButtonContainer
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <ButtonSmall
              text="Cancel"
              onClick={() => {
                setNewTitle(anchorTitleAttrib);
                setNewLink(href || '');
                setIsEditing(false);
                linkPreviewAdapter.closePreviewDropdown();
              }}
              isOutline
            />
            <ButtonSmall
              text="Update"
              onClick={updateAnchor}
            />
          </ButtonContainer>
        </MenuDropdown>
      );
    }

    // Normal dropdown view
    if (isValid && !isLoading && !isEditing) {
      previewContent = (
        <MenuDropdown
          ref={dropdown}
          offset={offset}
        >
          <PreviewLinkTitle>
            <Title
              href={href || ''}
              target="_blank"
              style={{
                width: '237px',
                height: '20px',
                wordBreak: 'break-word',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {title}
            </Title>
            {controls}
          </PreviewLinkTitle>
          {description && (
            <PreviewLinkDescription>
              {description}
            </PreviewLinkDescription>
          )}
          {
            imageURL && (
              <ImageContainer>
                <img
                  className="preview-logo"
                  src={imageURL}
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </ImageContainer>
            )
          }
        </MenuDropdown>
      );
    }

    if (previewContent) {
      return (
        <div ref={clickOutside}>
          {previewContent}
        </div>
      );
    }
  }

  // show === false
  return (<span />);
};

export default LinkPreview;
