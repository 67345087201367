import React, { useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { AuthContext, UserDataContext } from '../../../App';
import CompanyInfoForm from './forms/CompanyInfoForm';
import ProfileInfoForm from './forms/ProfileInfoForm';
import { ErrorModalData, ErrorModalFunctions, PersonalOnbDataDefault } from './utils/constants';
import { PersonalOnboardingData } from './utils/OnboardingTypes';
import { logOnboardingEvent } from '../../../utils/analytics/eventLogger';
import {
  ONBOARDING_FORM_PAGE, OPEN_EVENT, SUBMIT_EVENT,
} from '../../../utils/analytics/enums';
import Header from '../../../shared/components/header';
import { dbUpdateUserDataWithOnboardingDataAndSendWelcomeEmail } from './utils/functions';
import { OnboardingContainer } from './utils/styles';
import ErrorModal from '../../../shared/components/errors/error-modal';
import { HEADER_ONBOARDING_VIEW } from '../../../shared/components/header/utils/constants';
import ROUTES from '../../../routes/ROUTES_CONSTANTS';
import useFirstUserLoadEffect from '../../../utils/user/useFirstUserLoadEffect';
import ShepherdLogoLoadingPage from '../../../shared/components/loading/shepherd-logo-loading-page';
import KnownUsersUtils from '../../../utils/user/KnownUsers.ts/KnownUsersUtils';

const PersonalOnboarding = () => {
  const userData = useContext(UserDataContext);
  const authState = useContext(AuthContext);
  const navigate = useNavigate();
  const { trackEvent } = useIntercom();

  const initialFormValues2 = {
    ...PersonalOnbDataDefault,
    firstName: authState.firstName ?? '',
    lastName: authState.lastName ?? '',
  } as PersonalOnboardingData;

  const [formValues, setFormValues] = useState<PersonalOnboardingData>(initialFormValues2);
  const [activeStep, setActiveStep] = useState<0|1>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const initialFormValues = {
      ...PersonalOnbDataDefault,
      firstName: authState.firstName ?? '',
      lastName: authState.lastName ?? '',
    } as PersonalOnboardingData;
    console.log('Updating initialiFormValues', initialFormValues);
    setFormValues(initialFormValues);
  }, [authState.firstName, authState.lastName, userData.data]);

  useFirstUserLoadEffect(() => {
    // Known users can go through onboarding multiple times for testing
    if (KnownUsersUtils.isKnownUser(authState.userId)) return;
    if (userData.data.hasOnboarded) {
      navigate(ROUTES.dashboardPath);
    }
  }, [userData]);

  const handleGoToPreviousStep = (newData: PersonalOnboardingData) => {
    setFormValues((prev) => ({ ...prev, ...newData }));

    if (activeStep === 0) return;
    setActiveStep(0);
  };

  const handleGoToNextStep = (newData: PersonalOnboardingData) => {
    console.log('New values to be submitted: ', newData);
    handleSubmit(newData);
  };

  const handleSubmit = async (onboardingValues: PersonalOnboardingData) => {
    const logAnalytics = () => {
      // Known users can go through onboarding flow multiple times, but then we don't want to log it
      if (KnownUsersUtils.isKnownUser(authState.userId)) return;
      logOnboardingEvent(authState.userId, trackEvent, ONBOARDING_FORM_PAGE, SUBMIT_EVENT);
    };

    const status = await dbUpdateUserDataWithOnboardingDataAndSendWelcomeEmail(
      authState,
      onboardingValues,
      setIsLoading,
      logAnalytics,
    );

    if (status !== 'resolved') {
      setError(true);
      return;
    }

    navigate(ROUTES.onboardingWelcomePath);
  };

  useEffect(() => {
    // Known users can go through onboarding flow multiple times, but then we don't want to log it
    if (KnownUsersUtils.isKnownUser(authState.userId)) return;
    logOnboardingEvent(authState.userId, trackEvent, ONBOARDING_FORM_PAGE, OPEN_EVENT);
  }, []);

  if (userData.resolvedState !== 'resolved' && userData.data.email.length > 0) {
    return (
      <ShepherdLogoLoadingPage />
    );
  }

  return (
    <OnboardingContainer id="OnboardingContainer">
      <Header view={HEADER_ONBOARDING_VIEW} />
      <OnboardingStep
        activeStep={activeStep}
        onNextPress={handleGoToNextStep}
        onPreviousPress={handleGoToPreviousStep}
        onSubmitPress={handleSubmit}
        formValues={formValues}
        isLoading={isLoading}
      />
      <ErrorModal isOpen={error} data={ErrorModalData} functions={ErrorModalFunctions} />
    </OnboardingContainer>
  );
};

export default PersonalOnboarding;

interface OnboardingStepProps {
  activeStep: number;
  // eslint-disable-next-line no-unused-vars
  onNextPress: (newData: PersonalOnboardingData) => void;
  // eslint-disable-next-line no-unused-vars
  onPreviousPress: (newData: PersonalOnboardingData) => void;
  // eslint-disable-next-line no-unused-vars
  onSubmitPress: (newData: PersonalOnboardingData) => void;
  formValues: PersonalOnboardingData;
  isLoading: boolean;
}

const OnboardingStep = ({
  activeStep, onNextPress, onPreviousPress, onSubmitPress, formValues, isLoading,
}: OnboardingStepProps) => {
  if (activeStep === 0) {
    return (
      <ProfileInfoForm
        handleNextStep={onNextPress}
        formValues={formValues}
      />
    );
  }
  return (
    <CompanyInfoForm
      handlePreviousStep={onPreviousPress}
      handleOnSubmit={onSubmitPress}
      formValues={formValues}
      isLoading={isLoading}
    />

  );
};
