import React from 'react';
import styled from 'styled-components';
import Font from '../../../../shared/typography/Font';

const Container = styled.div`
  #forOnlySevenDollars {
    background: linear-gradient(180deg, #6772C8 0%, #D691E5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    margin-bottom: 25px;

    font-weight: 600;
  }
`;

const PremiumPlanText = () => (
  <Container>
    <Font font="header600" id="forOnlySevenDollars">
      Premium plan
    </Font>
  </Container>
);

export default PremiumPlanText;
