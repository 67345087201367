import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TaskCard from '.';
import CheckIconsAtom from '../../../pages/userCenter/meetingDashboard/meetingCard/CheckIconsAtom';
import { Page, TaskItem } from '../../types/types';

interface Props {
  selectAll: boolean,
  taskItems: TaskItem[],
  // eslint-disable-next-line no-unused-vars
  onSelectClick: (taskItem: TaskItem) => void,
}

interface SelectedTaskItems {
  isChecked: boolean,
  item: TaskItem,
}
const Container = styled.div`
  display: flex;
`;
const CheckBoxContainer = styled.div`
  cursor: pointer;
  margin-top: 14px;
`;
const TaskContainer = styled.div`
  width: 100%;
`;

export default function SelectTaskItem({ selectAll, taskItems, onSelectClick }: Props) {
  const [mappedTaskItems, setMappedTaskItems] = useState<SelectedTaskItems[]>([]);

  useEffect(() => {
    const selectableTaskItems = taskItems.map((taskItem) => ({
      isChecked: false,
      item: taskItem,
    }));
    setMappedTaskItems(selectableTaskItems);
  }, [taskItems]);

  useEffect(() => {
    const selectAllTaskItems = taskItems.map((taskItem) => ({
      isChecked: selectAll,
      item: taskItem,
    }));
    setMappedTaskItems(selectAllTaskItems);
  }, [selectAll]);

  const handleOnClick = (taskItemSelected: TaskItem) => {
    const mappedTask = mappedTaskItems.map((taskItem) => {
      if (taskItem.item.taskId === taskItemSelected.taskId) {
        return {
          isChecked: !taskItem.isChecked,
          item: taskItem.item,
        };
      }
      return taskItem;
    });
    setMappedTaskItems(mappedTask);
    onSelectClick(taskItemSelected);
  };

  const handleSetPage = (page: Page) => {
    console.log('Page in SelectTaskCard', page);
  };

  return (
    <>
      {
        mappedTaskItems.map((taskItem) => (
          <Container key={taskItem.item.taskId}>
            <CheckBoxContainer onClick={() => {
              handleOnClick(taskItem.item);
            }}
            >
              <CheckIconsAtom isChecked={taskItem.isChecked} />
            </CheckBoxContainer>
            <TaskContainer>
              <TaskCard
                setPage={handleSetPage}
                disableStatusDropdown={false}
                showSettingsThreeDots={false}
                taskItem={taskItem.item}
              />
            </TaskContainer>
          </Container>
        ))
      }
    </>
  );
}
