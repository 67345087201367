import React from 'react';

interface Props {
  fill?: string,
}

const TickCircle = ({ fill }: Props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2721 6.82247C11.4918 6.6028 11.8479 6.6028 12.0676 6.82247C12.2673 7.02217 12.2854 7.33466 12.122 7.55487L12.0676 7.61796L8.50808 11.1775C8.30841 11.3771 7.99597 11.3953 7.77576 11.232L7.71267 11.1775L5.93217 9.3978C5.71245 9.17817 5.71238 8.82202 5.932 8.6023C6.13166 8.40256 6.44415 8.38434 6.6644 8.54768L6.7275 8.60213L8.10966 9.98381L11.2721 6.82247Z" fill="#8792AF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 2.8125C5.58274 2.8125 2.8125 5.58274 2.8125 9C2.8125 12.4173 5.58274 15.1875 9 15.1875C12.4173 15.1875 15.1875 12.4173 15.1875 9C15.1875 5.58274 12.4173 2.8125 9 2.8125ZM1.6875 9C1.6875 4.96142 4.96142 1.6875 9 1.6875C13.0386 1.6875 16.3125 4.96142 16.3125 9C16.3125 13.0386 13.0386 16.3125 9 16.3125C4.96142 16.3125 1.6875 13.0386 1.6875 9Z" fill="#8792AF" />
  </svg>
);

TickCircle.defaultProps = {
  fill: 'none',
};

export default TickCircle;
