import React from 'react';
import Font from '../../../../../shared/typography/Font';

const YouAreNowOnTheFreePlanTextAtom = () => (
  <Font font="uiTextS" color="gray7" id="announcement">
    If you wish to upgrade to Shepherd Premium you can do so below or within the Shepherd app.
  </Font>
);

export default YouAreNowOnTheFreePlanTextAtom;
