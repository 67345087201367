import React, {
  Dispatch, SetStateAction, useRef, useState,
} from 'react';
import Downshift from 'downshift';
import { AssigneeV2 } from '../../types/types';
import SearchableAssignLoading from './SearchableAssignLoading';
import SearchableAssigneOptions from './SearchableAssignOptions';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import Scrollbar from '../scrollbar';
import SearchableMultiInput from '../multi-input/SearchableMultiInput';
import {
  AssignContainer, OptionMenuContainer, SelectContainer, Option, stateReducer,
} from '.';

type InputValue = {
  index: number,
  displayValue: string,
}

interface Props {
  values: InputValue[],
  setValues: Dispatch<SetStateAction<InputValue[]>>,
  selectedEmail: string,
  // eslint-disable-next-line no-unused-vars
  onUserSelect: (email: string) => void,
  candidates: AssigneeV2[],
  inputRefValue: string
  openByDefault?: boolean
}

const SearchableAssignMultiInput = ({
  values, setValues, selectedEmail, onUserSelect, candidates,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(document.createElement('input'));

  const [multiInputText, setMultiInputText] = useState('');
  const [openOptions, setOpenOptions] = useDetectOutsideClick(inputRef, true);

  const onSelectedItemChange = (selection: Option | null) => {
    onUserSelect(selection?.value || '');
    setOpenOptions(false);
  };

  const candidatesEmails = candidates.map((candidate) => ({ value: candidate.data.email }));

  return (
    <AssignContainer>

      <SelectContainer ref={inputRef}>
        <SearchableMultiInput
          values={values}
          setValues={setValues}
          setOpenOptions={setOpenOptions}
          setMultiInputText={setMultiInputText}
        />
        <Downshift
          onChange={onSelectedItemChange}
          itemToString={(item) => (item ? item.value : '')}
          initialSelectedItem={{ value: selectedEmail }}
          stateReducer={stateReducer}
        >
          {({
            getItemProps,
            getMenuProps,
            highlightedIndex,
          }) => (
            <div>
              <OptionMenuContainer data-is-open={openOptions}>
                {candidatesEmails.length === 0 ? (
                  <SearchableAssignLoading />
                ) : (
                  <Scrollbar maxHeight="220px">
                    <ul
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getMenuProps()}
                    >
                      {openOptions
                        ? (
                          <SearchableAssigneOptions
                            candidates={candidates}
                            inputValue={multiInputText}
                            getItemProps={getItemProps}
                            highlightedIndex={highlightedIndex}
                          />
                        )
                        : null}
                    </ul>
                  </Scrollbar>
                )}
              </OptionMenuContainer>
            </div>
          )}
        </Downshift>
      </SelectContainer>

    </AssignContainer>
  );
};

SearchableAssignMultiInput.defaultProps = {
  openByDefault: false,
};
export default SearchableAssignMultiInput;
