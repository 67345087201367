import React from 'react';
import styled from 'styled-components';
import CloseSquare from '../../../../icons/closeSquare';
import IconRockOnHand from '../../../../icons/icon _rock_on';
import Font from '../../../../typography/Font';
import image from './BillingPopupTitleBackground.svg';

const BillingTitle = styled.div<HeightAndFontSizeInterface>`
  border-radius: 10px 10px 0 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${image}) ;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  overflow: hidden;

  /* First child */

  > *:first-child {
    font-size: 14px;
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const RockHandContainer = styled.div`
  position: absolute;
  right: 50px;
  top: 17px;
  margin: 10px;
`;

interface HeightAndFontSizeInterface {
  height?: string,
  fontSize?: string,
}

interface FreePlanBillingModalTitleProps extends HeightAndFontSizeInterface {
  title: string,
  showCloseIcon?: boolean,
  onClose?: () => void,
  showRockOnIcon?: boolean,
}

const FreePlanBillingModalTitle = ({
  title,
  showCloseIcon = false,
  onClose = () => { },
  showRockOnIcon = false,
  height = '85px',
  fontSize = '24px',
}: FreePlanBillingModalTitleProps) => (
  <BillingTitle height={height} fontSize={fontSize}>
    <Font font="header700" color="gray1">{title}</Font>
    {showCloseIcon
      && (
        <CloseIconContainer onClick={onClose}>
          <CloseSquare width="20px" height="20px" />
        </CloseIconContainer>
      )}
    {showRockOnIcon
      && (
        <RockHandContainer onClick={onClose}>
          <IconRockOnHand />
        </RockHandContainer>
      )}
  </BillingTitle>
);

export default FreePlanBillingModalTitle;
