import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useIntercom } from 'react-use-intercom';

import { AuthContext } from '../../../App';
import { useUI } from '../../../pages/all-pages/ui-context/uiContext';
import ROUTES from '../../../routes/ROUTES_CONSTANTS';
import { CLOSE_EVENT, OPEN_EVENT } from '../../../utils/analytics/enums';
import {
  logEventButtonClickOpenIntercom,
  logQuickSettingsClick,
} from '../../../utils/analytics/eventLogger';

import FlowerImage from '../../img/full-flower.png';
import FeedbackModal from '../Modal/FeedbackModal';
import Font from '../../typography/Font';
import { COLORS } from '../../colors/COLORS';
import {
  FullScreenSidebarContainer, LogoContainer, LinksContainer, StyledLink, SidebarFlowerImage,
} from './FullScreenLeftSidebarStyles';

import ShepherdLogo from '../../icons/shepherd/ShepherdLogo';
import AddUser from '../../icons/AddUser';
import ThumbsUpIcon from '../../icons/ThumbsUp';
import HelpCenter from '../../icons/HelpCenter';

type ToggleIntercomSource = 'chat_with_us' | 'help_us';

const FullScreenLeftSidebar = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isChatBubbleOpen, setIsChatBubbleOpen] = useState(false);
  const { openInviteMemberModal } = useUI();
  const navigate = useNavigate();
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);
  const { show, hide, trackEvent } = useIntercom();
  const authData = useContext(AuthContext);
  const { userId } = authData;
  const handleFeedbackModalOpen = () => {
    setIsFeedbackModalOpen(true);
    logQuickSettingsClick(userId, OPEN_EVENT, 'feedback', trackEvent);
  };
  const toggleIntercom = (source: ToggleIntercomSource) => () => {
    if (isChatBubbleOpen) {
      hide();
      setIsChatBubbleOpen(false);
      logEventButtonClickOpenIntercom(authData.userId, trackEvent);
      logQuickSettingsClick(authData.userId, CLOSE_EVENT, source, trackEvent);
    } else {
      show();
      setIsChatBubbleOpen(true);
      logQuickSettingsClick(authData.userId, OPEN_EVENT, source, trackEvent);
    }
  };
  const openMembersModal = () => {
    openInviteMemberModal();
    logQuickSettingsClick(userId, OPEN_EVENT, 'invite_members', trackEvent);
  };

  const openAllNotes = () => {
    navigate(ROUTES.allNotes);
  };

  const links = [
    {
      onClick: openMembersModal,
      Icon: AddUser,
      text: 'Invite Friends',
    },
    {
      onClick: handleFeedbackModalOpen,
      Icon: ThumbsUpIcon,
      text: 'Share Feedback',
    },
    {
      onClick: toggleIntercom('help_us'),
      Icon: HelpCenter,
      params: { fill: COLORS.gray1 },
      text: 'Help Center',
    },
  ];

  return (
    <FullScreenSidebarContainer>
      <FeedbackModal
        isModalOpen={isFeedbackModalOpen}
        setModalClosed={closeFeedbackModal}
      />
      <LogoContainer>
        <ShepherdLogo onClick={openAllNotes} />
      </LogoContainer>
      <LinksContainer>
        {links.map(({
          onClick, Icon, text, params,
        }) => (
          <StyledLink key={text} onClick={onClick}>
            {params ? <Icon fill={params.fill} /> : <Icon /> }
            <Font color="gray1" font="uiTextS">
              {text}
            </Font>
          </StyledLink>
        ))}
      </LinksContainer>
      <SidebarFlowerImage src={FlowerImage} />
    </FullScreenSidebarContainer>
  );
};
export default FullScreenLeftSidebar;
