import React from 'react';
import styled from 'styled-components';
import { blue6, gray1 } from '../../../shared/colors/COLORS';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import { messageText } from '../../../shared/typography';

const NotificationBubble = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${messageText};
  font-size: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: ${gray1};
  background-color: ${blue6};
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 16px;
  position: relative;
  top: -5px;
  right: 18px;
  z-index: 2;
`;

interface Props {
  unviewedTasks?: number;
}

const UnviewedTasksBlueBubble = ({ unviewedTasks = 0 }: Props) => {
  if (!unviewedTasks) return null;
  const plural = unviewedTasks > 1 ? 's' : '';

  return (
    <ReactTooltip tip={`${unviewedTasks} new task${plural} assigned to you`} offset={{ top: 0, left: 20 }}>
      <NotificationBubble>
        {unviewedTasks}
      </NotificationBubble>
    </ReactTooltip>
  );
};
UnviewedTasksBlueBubble.defaultProps = {
  unviewedTasks: 0,
};

export default UnviewedTasksBlueBubble;
