import React, { useContext, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams, useLocation } from 'react-router-dom-v5-compat';

import { useIntercom } from 'react-use-intercom';
import { pendingMeetingData } from '../../database/utils/templateMeetingData';
import Header from '../../shared/components/header';
import {
  MeetingSection, PrivateNoteData, TaskItems, TaskTab,
  TemplateData, ProseMirrorView,
} from '../../shared/types/types';
import { MeetingData } from '../../shared/types/MeetingData';
import Meeting from '../meeting';
import AllTasksOrganism from '../userCenter/AllTasksOrganism';
import Navbar from '../userCenter/navbar';
import BottomBar from './bottom-bar';
import MeetingDataContext from '../meeting/context/MeetingDataContext';
import ProseMirrorEditorViewContext from '../meeting/context/ProseMirrorEditorViewContext';
import { mapDatabaseTemplateDataToTemplateData } from '../../shared/components/templates-modal/utils';
import ConnectionLostPopupAndModal from './connection-lost/ConnectionLostPopupAndModal';
import commandAdapter from '../../shared/components/prosemirror/logic/suggestions/command-adapter';
import { pendingTaskItems } from '../../utils/tasks/tasksUtils';
import { dbListenToPrivateNotesData } from '../../database/firebasePrivateNotesAPI';
import { AuthContext, UserDataContext } from '../../App';
import {
  DEFAULT_SECTION, MEETING_SECTION, TASK_TAB,
} from '../../utils/enums';
import useGetQueryParam from '../../utils/hook/useGetParams';
import PrivateNotesModals from '../../shared/components/private-notes/create-private-notes-modal-or-show-invite-notif';
import AllNotesPageCtrl from '../all-notes/AllNotesPageCtrl';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../shared/components/header/utils/constants';
import TaskItemsContext from '../meeting/context/TaskDataContext';
import { useUI } from './ui-context/uiContext';
import MainContentContainer from './MainContentContainer';
import { setMeetingSectionCarefully } from '../../utils/meetings/meetingsUtils';
import ScratchpadCtrl from '../scratchpad/ScratchpadCtrl';
import TrialEndedModalCtrl from './Billing/TrialEndedModal/TrialEndedModalCtrl';
import BillingModalCtrl from './Billing/BillingModalCtrl';
import useSetPageQueryParameter from '../../utils/hook/usePageURLQueryParameter';
import useTaskSectionQueryParameter from '../../utils/hook/useTaskSectionURLQueryParameter';
import QueryUtils, { QueryParameters } from '../../utils/meetings/QueryUtils';
import useFixDoubleLoadingInGoogleCalendar from '../../utils/hook/useFixDoubleLoadingInGoogleCalendar';
import UserAPI from '../../database/User/UserAPI';
import Mixpanel from '../../utils/analytics/Mixpanel';

const AllPages = () => {
  const authState = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const location = useLocation();
  const [taskItems, setTaskItems] = useState<TaskItems>(pendingTaskItems); // For a specific meeting
  const { page, setPage } = useUI();
  const [taskTab, setTaskTab] = useState<TaskTab>(TASK_TAB.TODO);
  const [isTemplatesOpen, setIsTemplatesOpen] = useState<boolean>(false);
  const { trackEvent } = useIntercom();
  useSetPageQueryParameter(setPage);
  useTaskSectionQueryParameter(setTaskTab);
  const params = useParams();
  useFixDoubleLoadingInGoogleCalendar();

  /**
   * This is so that the "/agenda" command can open the templates.
   */
  commandAdapter.openTemplatesMethod = () => {
    setIsTemplatesOpen(true);
  };

  const [meetingData, setMeetingData] = useState<MeetingData>(pendingMeetingData);
  const [isEditTemplateEnabled, setIsEditTemplateEnabled] = useState<boolean>(false);
  const [selectedTemplateToEdit, setSelectedTemplateToEdit] = useState<TemplateData>(
    mapDatabaseTemplateDataToTemplateData('', ''),
  );
  const [meetingSection, setMeetingSection] = useState<MeetingSection>(DEFAULT_SECTION);
  const [isRecurringMeeting, setIsRecurringMeeting] = useState<boolean>(false);
  const [proseMirrorEditorView, setProseMirrorEditorView] = useState<ProseMirrorView>({ page: '', editorView: '' });

  const [isPrivateNoteModalOpen, setIsPrivateNoteModalOpen] = useState<boolean>(false);
  const [isPrivateNoteInviteNotifOpen, setIsPrivateNoteInviteNotifOpen] = useState(false);
  const [activePrivateNoteId, setActivePrivateNoteId] = useState<string>('');
  const [privateNotesData, setPrivateNotesData] = useState<PrivateNoteData[]>([]);

  const [isCreateTaskModalOpen, setCreateTaskModalOpen] = useState<boolean>(false);
  useHotkeys('alt+t', () => setCreateTaskModalOpen(true), {}, [meetingSection]);

  const onClickSelectPrivateNote = (chatId: string) => {
    if (chatId.length === 0) return;
    setActivePrivateNoteId(chatId);
    setMeetingSection(MEETING_SECTION.PRIVATE_NOTES);
  };

  const onClickClosePrivateNoteModal = () => setIsPrivateNoteModalOpen(false);
  const onClickClosePrivateNoteInviteNotif = () => setIsPrivateNoteInviteNotifOpen(false);

  useEffect(() => {
    if (meetingData.meetingId.length === 0) return () => { };
    return dbListenToPrivateNotesData(
      meetingData.meetingId,
      authState.userId,
      authState.email,
      setPrivateNotesData,
    );
  }, [meetingData, activePrivateNoteId]);

  useEffect(() => {
    if (privateNotesData.length > 0) setIsPrivateNoteInviteNotifOpen(true);
  }, [privateNotesData]);

  useEffect(() => {
    // This event contributes way too much to our Mixpanel Events.
    // logNotesTabEvent(authState.userId, OPEN_EVENT, meetingSection);
    if (meetingSection !== MEETING_SECTION.PRIVATE_NOTES) setActivePrivateNoteId('');
  }, [meetingSection]);

  useEffect(() => {
    if (selectedTemplateToEdit.templateId.length === 0) {
      setIsEditTemplateEnabled(false);
    } else {
      setIsEditTemplateEnabled(true);
    }
  }, [selectedTemplateToEdit]);

  useEffect(() => {
    UserAPI.Workspaces.setupWorkspaceIfNotSetup(userData);
    if (userData.isResolved()) {
      const appVersion = process.env.REACT_APP_VERSION;
      console.log(`AppVersion${appVersion}`);
      trackEvent(`AppVersion${appVersion}`);
      Mixpanel.log(userData.userId, `AppVersion${appVersion}`, { version: appVersion });
    }
  }, [userData]);

  useEffect(() => {
    const meetingSectionQueryParam = useGetQueryParam(QueryParameters.MEETING_SECTION);
    if (QueryUtils.isValidMeetingSection(meetingSectionQueryParam)) {
      setMeetingSectionCarefully(meetingSectionQueryParam, meetingData.version, setMeetingSection);
    }
  }, [location]);

  return (
    <MeetingDataContext.Provider value={meetingData}>
      <TaskItemsContext.Provider value={taskItems}>
        <ProseMirrorEditorViewContext.Provider value={proseMirrorEditorView}>
          <Header setPage={setPage} view={DEFAULT_HEADER_MEETING_VIEW} />
          <Navbar page={page} setPage={setPage} meetingData={meetingData} />
          <MainContentContainer>
            <AllNotesPageCtrl setPage={setPage} page={page} />
            <AllTasksOrganism
              page={page}
              setPage={setPage}
              taskTab={taskTab}
              setTaskTab={setTaskTab}
            />
            <Meeting
              page={page}
              setPage={setPage}
              taskTab={taskTab}
              setTaskTab={setTaskTab}
              meetingData={meetingData}
              setMeetingData={setMeetingData}
              taskItems={taskItems}
              setTaskItems={setTaskItems}
              meetingId={params?.meetingId ?? ''}
              meetingTab={meetingSection}
              setMeetingTab={setMeetingSection}
              isTemplatesOpen={isTemplatesOpen}
              setIsTemplatesOpen={setIsTemplatesOpen}
              isEditTemplateEnabled={isEditTemplateEnabled}
              setSelectedTemplateToEdit={setSelectedTemplateToEdit}
              selectedTemplateToEdit={selectedTemplateToEdit}
              setCreateTaskModalOpen={setCreateTaskModalOpen}
              isRecurringMeeting={isRecurringMeeting}
              setProseMirrorEditorView={setProseMirrorEditorView}
              setPrivateNoteOpen={setIsPrivateNoteModalOpen}
              activePrivateNoteId={activePrivateNoteId}
              onClickSelectPrivateNote={onClickSelectPrivateNote}
              privateNotes={privateNotesData}
              setPrivateNotes={setPrivateNotesData}
            />
            <ScratchpadCtrl
              page={page}
            />
          </MainContentContainer>
          <BottomBar
            isEditTemplateBottomBarEnabled={isEditTemplateEnabled}
            meetingData={meetingData}
            setIsTemplatesOpen={setIsTemplatesOpen}
            meetingSection={meetingSection}
            taskItems={taskItems}
            page={page}
            setCreateTaskModalOpen={setCreateTaskModalOpen}
            isCreateTaskModalOpen={isCreateTaskModalOpen}
            setIsRecurringMeeting={setIsRecurringMeeting}
            privateNotes={privateNotesData}
            setSelectedTemplateToEdit={setSelectedTemplateToEdit}
            selectedTemplateToEdit={selectedTemplateToEdit}
          />
          <PrivateNotesModals
            isPrivateNotesModalOpen={isPrivateNoteModalOpen}
            onClickClosePrivateNotesModal={onClickClosePrivateNoteModal}
            isPrivateNoteInviteNotifOpen={isPrivateNoteInviteNotifOpen}
            onClickClosePrivateNoteInviteNotif={onClickClosePrivateNoteInviteNotif}
            privateNotes={privateNotesData}
            onClickSelectPrivateNote={onClickSelectPrivateNote}
          />
          <BillingModalCtrl />
          <TrialEndedModalCtrl />
          <ConnectionLostPopupAndModal />
        </ProseMirrorEditorViewContext.Provider>
      </TaskItemsContext.Provider>
    </MeetingDataContext.Provider>
  );
};

export default AllPages;
