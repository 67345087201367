import create from 'zustand';
import Notification from '../shared/classes/Notification/Notification';
import PlayPing from '../shared/classes/PlayPing';

interface NotificationStore {
  notifications: Notification[]
  readNotifications: Notification[]
  unreadNotifications: Notification[]
  unreadNotificationCount: number,
  // eslint-disable-next-line no-unused-vars
  updateStore: (newNotifications: Notification[]) => void;
}

const useNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  readNotifications: [],
  unreadNotifications: [],
  unreadNotificationCount: 0,
  updateStore: (dbNotifications: Notification[]) => set((state) => {
    if (state.notifications.length !== 0 && dbNotifications.length > state.notifications.length) {
      PlayPing.play();
    }
    const readItems = dbNotifications.filter((item) => item.read.isRead);
    const unreadItems = dbNotifications.filter((item) => !item.read.isRead);
    const unreadItemsCount = unreadItems.length;
    return {
      notifications: dbNotifications,
      readNotifications: readItems,
      unreadNotifications: unreadItems,
      unreadNotificationCount: unreadItemsCount,
    };
  }),
}));

export default useNotificationStore;
