import React from 'react';
import styled from 'styled-components';
import { gray7 } from '../../../shared/colors/COLORS';
import { uiTextMedium } from '../../../shared/typography';

const Author = styled.span`
  ${uiTextMedium}
  color: ${gray7};
`;

interface Props {
  author: string,
}

const AuthorAtom = ({ author }: Props) => (
  <Author>
    {author}
  </Author>
);

export default AuthorAtom;
