import React from 'react';
import CheckMarkIcon from '../../../../../shared/icons/CheckMarkIcon';
import { DropdownOption } from '../../utils/OnboardingTypes';
import isDropdownOptionSelected from './utils/functions';
import MultiSelectDropdownOptionContainer from './utils/styles';

interface MultiSelectDropdownOptionProps {
  option: DropdownOption,
  selectedOptions: Array<string>,
  /* eslint-disable-next-line */
  onDropdownOptionClick: (option: string) => void,
}

const MultiSelectDropdownOption = ({
  option,
  selectedOptions,
  onDropdownOptionClick,
}: MultiSelectDropdownOptionProps) => {
  const displayCheckMarkIfDropdownOptionSelected = (dropdownOption: string) => {
    if (isDropdownOptionSelected(selectedOptions, dropdownOption)) return <CheckMarkIcon width="18" />;

    return null;
  };

  return (
    <MultiSelectDropdownOptionContainer onClick={() => onDropdownOptionClick(option.value)}>
      {option.value}
      {displayCheckMarkIfDropdownOptionSelected(option.value)}
    </MultiSelectDropdownOptionContainer>
  );
};

export default MultiSelectDropdownOption;
