import React from 'react';
import Icon from '../../../icons/attendeeIcon';
import { BasicUser } from '../../../types/types';

interface Props {
  user: BasicUser,
}
function ReporterProfileIcon({ user }: Props) {
  return (
    <Icon
      size={36}
      email={user.email}
      photoUrl={user.photoUrl}
    />
  );
}

export default ReporterProfileIcon;
