/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import firebase from 'firebase/app';
import { DatabaseReference } from 'firebase/database';
import { Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import ProseMirrorComponent from '../../../shared/components/prosemirror/state-switchable';
import Modal from '.';
import { MEETING_SECTION } from '../../../utils/enums';
import { getFirepadPathV2 } from '../../../utils/firebase';
import { AuthContext } from '../../../App';
import MeetingDataContext from '../../../pages/meeting/context/MeetingDataContext';

interface GifSearchModalProps {
  isOpen: boolean;
  setModalClosed: () => void;
  schema: Schema;
  view: EditorView;
  getDbRef: (path: string) => DatabaseReference;
}

/**
 * NOTE: Is not in use. We talked about having a revision modal, and started the
 * development. But then we found a way to reduce number of missing notes, which made this feature
 * not needed anymore.
 */
const RevisionModal = ({
  isOpen, setModalClosed, view, schema, getDbRef,
}: GifSearchModalProps) => {
  const closeAndClean = () => {
    setModalClosed();
  };

  /* eslint-disable */
  useEffect(() => {
    console.log(isOpen);
    if (!isOpen) {
      return;
    }
  }, [isOpen]);
  /* eslint-enable */

  const { userId } = useContext(AuthContext);
  // eslint-disable-next-line no-unused-vars
  const { meetingId, version } = useContext(MeetingDataContext);

  return (
    <Modal
      title="Revision"
      isOpen={isOpen}
      setModalClosed={setModalClosed}
    >
      <ProseMirrorComponent
        path={getFirepadPathV2(MEETING_SECTION.SHARED_NOTES, meetingId, 'prosemirror', userId)}
        page={MEETING_SECTION.SHARED_NOTES}
      />
    </Modal>
  );
};

export default RevisionModal;
