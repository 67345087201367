/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom-v5-compat';
import { header700 } from '../../shared/typography';
import Font from '../../shared/typography/Font';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 50px;
  /* margin top for nth div child */
  & > span:nth-child(3) {
    margin-bottom: 40px;
  }
`;

const Header400 = styled.span`
  margin-bottom: 20px;
  ${header700}
`;

const ErrorMessage = styled.pre`
  color: red;
  margin: 5px;
`;

interface ErrorProps {
  error: Error,
  resetErrorBoundary: () => void,
}

const SErrorBoundaryPage = ({ error, resetErrorBoundary }: ErrorProps) => {
  const navigate = useNavigate();
  const hei = '2';

  const handleClickRefresh = () => {
    // Refresh the page
    navigate('/');
    window.location.href = '/';
    window.location.reload();
  };

  return (
    <Container>
      <Header400>Oh no!🥲 </Header400>
      <Header400>Something went wrong</Header400>

      <Font font="uiTextMedium" isButton onClick={handleClickRefresh}>Please refresh the browser to start using Shepherd again</Font>
      <ErrorMessage>Error:</ErrorMessage>
      <ErrorMessage>{error.message}</ErrorMessage>
      {/* <button onClick={resetErrorBoundary} type="button">Try again</button> */}
    </Container>
  );
};

export default SErrorBoundaryPage;
