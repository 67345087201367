import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface LinkProps {
  fill?: string;
  width?: number;
  height?: number;
}

const LinkIcon = ({
  fill,
  width,
  height,
}: LinkProps) => (
  <svg width={width} height={height} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 11.25,7.5 H 9.75 C 9.336,7.5 9,7.1642 9,6.75 9,6.3358 9.336,6 9.75,6 h 1.5 C 12.493,6 13.5,4.9926 13.5,3.75 13.5,2.5074 12.493,1.5 11.25,1.5 H 9.75 C 9.336,1.5 9,1.1642 9,0.75 9,0.3358 9.336,0 9.75,0 h 1.5 C 13.321,0 15,1.6789 15,3.75 15,5.8211 13.321,7.5 11.25,7.5 Z M 6,6.75 C 6,7.1642 5.664,7.5 5.25,7.5 H 3.75 C 1.679,7.5 0,5.8211 0,3.75 0,1.6789 1.679,0 3.75,0 h 1.5 C 5.664,0 6,0.3358 6,0.75 6,1.1642 5.664,1.5 5.25,1.5 H 3.75 C 2.508,1.5 1.5,2.5074 1.5,3.75 1.5,4.9926 2.508,6 3.75,6 h 1.5 C 5.664,6 6,6.3358 6,6.75 Z m 5.25,-3 c 0,0.4142 -0.336,0.75 -0.75,0.75 h -6 C 4.086,4.5 3.75,4.1642 3.75,3.75 3.75,3.3358 4.086,3 4.5,3 h 6 c 0.414,0 0.75,0.3358 0.75,0.75 z"
      fill={fill}
    />
  </svg>
);

LinkIcon.defaultProps = {
  fill: darkBlue4,
  width: 15,
  height: 8,
};

export default LinkIcon;
