import React from 'react';
import { uniqueId } from 'lodash';
import { PlatformData, SHARE_VIEW } from '../utils/ShareModalTypes';
import Platform from './Platform';

interface Props {
  platformData: PlatformData[],
}

const ShareView = ({ platformData }:Props) => {
  const sharablePlatforms = platformData
    .filter((platformObj) => platformObj.isActiveIntegration)
    .map((obj) => (
      <Platform
        key={uniqueId()}
        platform={obj.platform}
        onClick={obj.onClick}
        mode={SHARE_VIEW}
      />
    ));

  return (
    <>
      {sharablePlatforms}
    </>
  );
};

export default ShareView;
