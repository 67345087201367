/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useContext } from 'react';
import ReactJson from 'react-json-view';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { UserDataContext } from '../../App';
import UserAPI from '../../database/User/UserAPI';
import StripeAPI from '../../external/Stripe/StripeAPI';
import { COLORS } from '../../shared/colors/COLORS';
import ButtonSmall from '../../shared/components/button-small';
import Header from '../../shared/components/header';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../shared/components/header/utils/constants';
import BREAKPOINTS from '../../shared/CSS/CSS_Constants';
import Font from '../../shared/typography/Font';
import { toastInfo } from '../../utils/notifications';
import KnownUsersUtils from '../../utils/user/KnownUsers.ts/KnownUsersUtils';
import SubscribeToPremiumMol from '../billing/SubscribeToPremiumMol';
import CreateNewWorkspaceMol from './CreateNewWorkspaceMol';
import WorkspacesUtils from '../../database/Workspaces/WorkspacesUtils';
import WorkspaceAPI from '../../database/Workspaces/WorkspaceAPI';
import ListMyWorkspacesMol from './ListMyWorkspacesMol';
import MySelectedWorkspaceOrg from './MySelectedWorkspaceOrg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: ${BREAKPOINTS.md};
  /* background-color: ${COLORS.darkBlue7}; */
  border: 1px solid ${COLORS.darkBlue4};
  height: 100%;
  margin: 10px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  #title {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  button {
    margin-bottom: 5px;
  }

  /* Immediate div child */

  & > div {
    margin-bottom: 40px;
  };
`;

const BillingDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
`;

interface ManageBillingPageProps {
  subject?: string,
}

// /manage_workspaces
const ManageWorkspacesPage = ({ subject }: ManageBillingPageProps) => {
  const title = subject || 'Manage Workspaces Admin Panel';
  const userData = useContext(UserDataContext);

  const isKnownUser = KnownUsersUtils.isKnownUser(userData.userId);
  if (!isKnownUser) {
    return <Font font="defaultText">Not authorized. Contact harald@meetshepherd.com for access</Font>;
  }

  const handleClickCreateNewWorkspace = (workspaceName: string) => {
    console.log('Create new workspace: ', workspaceName);
    const newWorkspace = WorkspacesUtils.createNewWorkspace(workspaceName, userData.email);
    WorkspaceAPI.createWorkspace(newWorkspace, userData.userId);
  };

  // const handleSetUserToTrialing = () => {
  // //   console.log('Set user to `trialing`');
  // //   toastInfo('Set user to `trialing`', 'ManageBillingPage');
  // //   UserAPI.Billing.startTrial(userData, 'manage_billing_page', trackEvent);
  // // };
  return (
    <Container>
      <Header setPage={() => { }} view={DEFAULT_HEADER_MEETING_VIEW} />
      <MainContent>
        <Font font="header700" id="title">{title}</Font>
        <CreateNewWorkspaceMol onClick={handleClickCreateNewWorkspace} />
        <ListMyWorkspacesMol />
        <MySelectedWorkspaceOrg />
      </MainContent>
    </Container>
  );
};

export default ManageWorkspacesPage;
