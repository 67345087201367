import styled from 'styled-components';
import { TabPanel } from 'react-tabs';

interface styleProps {
  margin?: string
}
export const TabContentStyles = styled.div<styleProps>`
  .react-tabs__tab-panel {
    margin-top: ${({ margin }) => (margin)};
    &--selected {
    }
  }
`;

TabContentStyles.defaultProps = {
  margin: '16px',
};

const TabContent = TabPanel;

export default TabContent;
