import React from 'react';
import { PersonalOnboardingInputType } from '../../utils/OnboardingTypes';
import {
  Error, HiddenLabel, InputContainer, InputStyle, Label,
} from './utils/styles';

interface InputProps {
  inputData: PersonalOnboardingInputType,
  errors: any,
  touched: any,
  handleBlur: any,
  handleChange:any,
  value: string
}

const OnboardingInput = ({
  inputData, errors, touched, handleBlur, handleChange, value,
}: InputProps) => {
  const {
    id, name, label, placeholder,
  } = inputData;

  return (
    <InputContainer>
      <HiddenLabel htmlFor={id} />
      <Label>{label}</Label>
      <InputStyle
        id={id}
        name={name}
        placeholder={placeholder}
        autoComplete="off"
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
      />
      <Error>{touched && errors}</Error>
    </InputContainer>
  );
};

export default OnboardingInput;
