import React from 'react';
import { COLORS } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const DeleteIcon24x24 = ({ fill }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9636 7.35275C15.4287 6.88762 16.1828 6.88762 16.648 7.35275C17.0708 7.7756 17.1093 8.43729 16.7633 8.90356L16.648 9.03714L9.038 16.6471C8.57287 17.1122 7.81874 17.1122 7.35361 16.6471C6.93076 16.2243 6.89232 15.5626 7.23829 15.0963L7.35361 14.9627L14.9636 7.35275Z" fill={fill} />
    <path d="M7.34894 7.34876C7.77183 6.92596 8.43352 6.88759 8.89975 7.2336L9.03333 7.34894L16.6497 14.9668C17.1147 15.432 17.1147 16.1862 16.6495 16.6512C16.2266 17.074 15.5649 17.1124 15.0987 16.7664L14.9651 16.6511L7.34876 9.03315C6.88368 8.56797 6.88376 7.81384 7.34894 7.34876Z" fill={fill} />
  </svg>
);

DeleteIcon24x24.defaultProps = {
  fill: COLORS.gray1,
};

export default DeleteIcon24x24;
