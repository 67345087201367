import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Dropdown from '../../../../../../shared/components/dropdown';
import { DropdownItem } from '../../../../../../shared/components/dropdown/utils';
import { SettingSubText } from '../SettingTab';

export const DropdownContainer = styled.div`
  margin: 6px 0px 12px 0px;
`;

interface Props {
  label: ReactNode,
  placeholder: string,
  options: DropdownItem[],
  loading: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (tab: DropdownItem) => void,
  onClick: ()=>void
}

export default function ListSettingTab({
  label, placeholder, options, loading, onChange, onClick,
}: Props) {
  return (
    <>
      <SettingSubText>
        {label}
      </SettingSubText>
      <DropdownContainer>
        <Dropdown
          onDropdownClick={onClick}
          dropdownItems={options}
          isMultiSelect={false}
          placeholderText={placeholder}
          onOptionClick={onChange}
          isLoading={loading}
        />
      </DropdownContainer>
    </>
  );
}
