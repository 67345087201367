/* eslint-disable no-unused-vars */
import { TableControlsInitState } from '../../components/widgets/table-controls';

interface ReactMethods {
  openPreviewControls?: (props: TableControlsInitState) => void;
  closePreviewControls?: () => void;
  updatePreviewControls?: (props: TableControlsInitState) => void;

  openContextMenu?: () => void;
  closeContextMenu?: () => void;
}

class TableControlsAdapter {
  public openPreviewControls: CallableFunction = (state: TableControlsInitState) => null;

  public closePreviewControls: CallableFunction = () => null;

  public updatePreviewControls: CallableFunction = (state: TableControlsInitState) => null;

  public openContextMenu: CallableFunction = () => null;

  public closeContextMenu: CallableFunction = () => null;

  set reactMethods({
    openPreviewControls = (state: TableControlsInitState) => null,
    closePreviewControls = () => null,
    updatePreviewControls = (state: TableControlsInitState) => null,
    openContextMenu = () => null,
    closeContextMenu = () => null,
  }: ReactMethods) {
    this.openPreviewControls = openPreviewControls;
    this.closePreviewControls = closePreviewControls;
    this.updatePreviewControls = updatePreviewControls;
    this.openContextMenu = openContextMenu;
    this.closeContextMenu = closeContextMenu;
  }
}

export default new TableControlsAdapter();
