/* eslint-disable arrow-body-style */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../App';
import Font from '../../shared/typography/Font';
import WorkspaceItem from './WorkspaceItem';
import WorkspaceData from '../../database/Workspaces/WorkspaceData';
import WorkspaceAPI from '../../database/Workspaces/WorkspaceAPI';
import CssSpinner from '../../shared/components/css-spinner';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 550px;

  & > span {
    margin-bottom: 15px;
  }
`;

const MySelectedWorkspaceOrg = () => {
  const userData = useContext(UserDataContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceData | null>(null);

  const handleClickDelete = (workspaceId: string) => {
    WorkspaceAPI.deleteWorkspace(workspaceId, userData);
  };

  const handleClickSetWorkspace = () => {
    console.log('clicked set workspace');
  };

  useEffect(() => {
    if (!userData.selectedWorkspaceId) {
      return () => {};
    }
    return WorkspaceAPI.listenToWorkspace(
      userData.selectedWorkspaceId, setSelectedWorkspace, setIsLoading, userData,
    );
  }, [userData.selectedWorkspaceId]);

  return (
    <Container>
      <Font font="header400">My Selected Workspace</Font>
      {isLoading && (
        <CssSpinner color="black" />
      )}
      {(selectedWorkspace && userData.selectedWorkspaceId) && (
        <WorkspaceItem
          workspace={selectedWorkspace}
          onClickDelete={handleClickDelete}
          onSelectWorkspace={handleClickSetWorkspace}
        />
      )}
    </Container>
  );
};

export default MySelectedWorkspaceOrg;
