import React, {
  Dispatch, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react';
import { AuthContext } from '../../../../App';
import { dbListenToPrivateNotesData } from '../../../../database/firebasePrivateNotesAPI';
import { MeetingSection, PrivateNoteData } from '../../../types/types';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import MeetingDataContext from '../../../../pages/meeting/context/MeetingDataContext';
import { getPrivateNoteId } from '../utils/functions';
import SelectAndEditPrivateNotesDropdown from '../select-and-edit-private-notes-dropdown-organism';
import { MEETING_SECTION } from '../../../../utils/enums';
import { Container, DropdownButtonContainer } from './utils/styles';
import ProfileIcons from './components/private-notes-profile-icons-atom';
import DropdownArrow from './components/dropdown-arrow-atom';

interface Props {
  privateNotes: PrivateNoteData[],
  // eslint-disable-next-line no-unused-vars
  handleSecretNotesSelected: (chatId: string) => void,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
  meetingTab: MeetingSection,
  setPrivateNotes: Dispatch<SetStateAction<PrivateNoteData[]>>,
  handleOpenCreateSecretChat: () => void,
  selectedChat: number,
  setSelectedChat: Dispatch<SetStateAction<number>>,
  setDeletedModalOpen: Dispatch<SetStateAction<boolean>>,
  setChatToDelete: Dispatch<SetStateAction<PrivateNoteData>>,
}

const SelectPrivateNotesButton = ({
  privateNotes,
  setPrivateNotes,
  handleSecretNotesSelected,
  meetingTab,
  setMeetingTab,
  handleOpenCreateSecretChat,
  selectedChat,
  setSelectedChat,
  setDeletedModalOpen,
  setChatToDelete,
}: Props) => {
  if (privateNotes.length === 0) return null;

  const privateNotesDropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useDetectOutsideClick(privateNotesDropdownRef, false);
  const [activePrivateNote, setActivePrivateNote] = useState<PrivateNoteData>(privateNotes[0]);

  const meetingData = useContext(MeetingDataContext);
  const authState = useContext(AuthContext);

  useEffect(() => {
    if (selectedChat === -1) {
      setSelectedChat(0);
      setActivePrivateNote(privateNotes[0]);
      return;
    }

    setActivePrivateNote(privateNotes[selectedChat]);
  }, [privateNotes, selectedChat]);

  const onClickToggleDropdown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setIsDropdownOpen((prev: boolean) => !prev);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
  };

  const onClickSelectPrivateNote = (note: PrivateNoteData) => {
    setSelectedChat(privateNotes.indexOf(note));
    handleSecretNotesSelected(getPrivateNoteId(note));
    setMeetingTab(MEETING_SECTION.PRIVATE_NOTES);
    handleClose();
  };

  const handleDelete = (privateNote: PrivateNoteData) => {
    setDeletedModalOpen(true);
    setChatToDelete(privateNote);
  };

  const handleUpdate = () => {
    dbListenToPrivateNotesData(
      meetingData.meetingId,
      authState.userId,
      authState.email,
      setPrivateNotes,
    );
  };

  const handleOpenModalCloseDropdown = () => {
    handleOpenCreateSecretChat();
    setIsDropdownOpen(false);
  };

  return (
    <Container>
      <DropdownButtonContainer>
        <ProfileIcons
          meetingTab={meetingTab}
          onClickSelectPrivateNote={onClickSelectPrivateNote}
          activePrivateNote={activePrivateNote}
        />
        <DropdownArrow
          meetingTab={meetingTab}
          isDropdownOpen={isDropdownOpen}
          onClickToggleDropdown={onClickToggleDropdown}
        />
      </DropdownButtonContainer>
      <SelectAndEditPrivateNotesDropdown
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        privateNotes={privateNotes}
        dropdownOpen={isDropdownOpen}
        dropdownRef={privateNotesDropdownRef}
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
        handleNotesSelected={onClickSelectPrivateNote}
        handleOpenModalCloseDropdown={handleOpenModalCloseDropdown}
      />
    </Container>
  );
};

export default SelectPrivateNotesButton;
