/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import ButtonSmall from '../../shared/components/button-small';
import { toastInfo } from '../../utils/notifications';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;

  & > *:first-child {
    margin-right: 10px;
  }
`;

const Input = styled.input`
  width: 300px;
  height: 100%;
`;

interface CreateNewWorkspaceMolProps {
  // eslint-disable-next-line no-unused-vars
  onClick: (workspaceName: string) => void,
}

const CreateNewWorkspaceMol = ({ onClick }: CreateNewWorkspaceMolProps) => {
  const [workspaceName, setWorkspaceName] = React.useState<string>('');

  const handleClick = () => {
    if (workspaceName === '') {
      toastInfo('Fill in name', 'Please fill in a name for the workspace');
      return;
    }
    onClick(workspaceName);
    setWorkspaceName('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <Container>
      <Input type="text" placeholder="Enter workspace name" value={workspaceName} onKeyDown={handleKeyDown} onChange={(e) => setWorkspaceName(e.target.value)} />
      <ButtonSmall onClick={handleClick} text="Create new workspace" />
    </Container>
  );
};

export default CreateNewWorkspaceMol;
