import React from 'react';
import styled from 'styled-components';
import CrossIcon from '../../icons/cross';
import { gray7, gray9, darkBlue1 } from '../../colors/COLORS';

const Container = styled.div`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;

  :hover > svg > path {
    fill: ${gray9};
  }
  :hover > svg {
    border-radius: 4px;
    background: ${darkBlue1};
  }
`;

interface Props {
  onClick: any
}

/**
 * Important that parent `<div>` have styling `position: relative`
 */
const SearchableAssignClearButton = ({ onClick }: Props) => (
  <Container onClick={onClick}>
    <CrossIcon fill={gray7} />
  </Container>
);

export default SearchableAssignClearButton;
