/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import Modal from '../../Modal';
import Font from '../../../typography/Font';
import { COLORS } from '../../../colors/COLORS';
import ButtonSmall from '../../button-small';

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const MainContentContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
  }

  #announcement {
    margin-bottom: 25px;
    padding: 0 10px;
  }

  #forOnlySevenDollars {
    background: linear-gradient(180deg, #6772C8 0%, #D691E5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    margin-bottom: 25px;

    font-weight: 600;
  }

  #LearnMore {
    margin-top: 12px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;

  & > button {
    margin: 0;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`;

const Line = styled.div`
  background:  ${COLORS.darkBlue1};
  height: 2px;
  width: 100%;
`;

interface BillingModalFirstPromptExistingUsersProps {
  isOpen: boolean,
  onClickBack: () =>void,
  onClickInvite: () => void,
}

const InitialInvitePromptAddExtraSeat = ({
  isOpen,
  onClickBack,
  onClickInvite,
}: BillingModalFirstPromptExistingUsersProps) => {
  return (
    <Modal
      title="Invite Member"
      isOpen={isOpen}
      setModalClosed={() => { }}
      isExitButtonEnabled={false}
      blurriness={2}
    >
      <Container>
        <MainContentContainer>
          <Font font="uiTextS" color="gray7" id="announcement">
            Inviting others to join this Workspace will add them to the subscription.
          </Font>
          {/* <AnnouncementTextAtom /> */}
        </MainContentContainer>
        <Line />
        <ButtonRow>
          <ButtonSmall onClick={onClickBack} text="Back" isOutline />
          <ButtonSmall type="submit" onClick={onClickInvite} text="Invite" />
        </ButtonRow>
      </Container>
    </Modal>
  );
};

export default InitialInvitePromptAddExtraSeat;
