import styled from 'styled-components';
import { COLORS } from '../../shared/colors/COLORS';

const PageContainer = styled.div`
  background-color: ${COLORS.gray1};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default PageContainer;
