import { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { DraggableStateSnapshot, DraggableProvided } from 'react-beautiful-dnd';

const useDraggableInPortal = () => {
  const self = useRef<any>({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    self.fullviewContainer = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render: any) => (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
    const element = render(provided, snapshot);
    if (self.fullviewContainer !== undefined && snapshot.isDragging) {
      return createPortal(element, self.fullviewContainer);
    }
    return element;
  };
};

export default useDraggableInPortal;
