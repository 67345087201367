import { Dispatch, SetStateAction } from 'react';
import { MeetingSection, PrivateNoteData } from '../../../shared/types/types';
import { MEETING_SECTION } from '../../../utils/enums';

const setTabIndicatorLeftAndWidth = (
  setWidth: Dispatch<SetStateAction<number>>,
  setTabLeft: Dispatch<SetStateAction<number>>,
  privateNotes: PrivateNoteData[],
  meetingTab: MeetingSection,
  selectedChat: number,
) => {
  if (privateNotes.length === 0) {
    if (meetingTab === MEETING_SECTION.AGENDA) {
      setTabLeft(11);
      setWidth(76);
    }
    if (meetingTab === MEETING_SECTION.SHARED_NOTES) {
      setTabLeft(100);
      setWidth(113);
    }
    if (meetingTab === MEETING_SECTION.PERSONAL_NOTES) {
      setWidth(113);
      setTabLeft(226);
    }
    if (meetingTab === MEETING_SECTION.TASK) {
      setWidth(64);
      setTabLeft(353);
    }
    if (meetingTab === MEETING_SECTION.PRIVATE_NOTES) {
      setWidth(0);
    }
  }

  if (privateNotes.length > 0 && privateNotes[selectedChat].members.length > 1) {
    if (meetingTab === MEETING_SECTION.AGENDA) {
      setTabLeft(6);
      setWidth(76);
    }
    if (meetingTab === MEETING_SECTION.SHARED_NOTES) {
      setTabLeft(91);
      setWidth(114);
    }
    if (meetingTab === MEETING_SECTION.PERSONAL_NOTES) {
      setWidth(113);
      setTabLeft(213);
    }
    if (meetingTab === MEETING_SECTION.TASK) {
      setWidth(64);
      setTabLeft(335);
    }
    if (meetingTab === MEETING_SECTION.PRIVATE_NOTES) {
      setWidth(0);
    }
  }

  if (privateNotes.length > 0 && privateNotes[selectedChat].members.length === 1) {
    if (meetingTab === MEETING_SECTION.AGENDA) {
      setTabLeft(9);
      setWidth(76);
    }
    if (meetingTab === MEETING_SECTION.SHARED_NOTES) {
      setTabLeft(94);
      setWidth(114);
    }
    if (meetingTab === MEETING_SECTION.PERSONAL_NOTES) {
      setWidth(113);
      setTabLeft(220);
    }
    if (meetingTab === MEETING_SECTION.TASK) {
      setWidth(64);
      setTabLeft(345);
    }
    if (meetingTab === MEETING_SECTION.PRIVATE_NOTES) {
      setWidth(0);
    }
  }
};

export const handleSetTabAfterPrivateNoteDeleted = (
  privateNotes: PrivateNoteData[],
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
  setSelectedChat: Dispatch<SetStateAction<number>>,
  // eslint-disable-next-line no-unused-vars
  handlePrivateNoteSelected: (chatId: string) => void,
  chatToDelete: PrivateNoteData,
) => {
  if (privateNotes.length > 1) {
    const privateNoteToRedirectTo = privateNotes.filter(
      (privateNote) => privateNote.chatId !== chatToDelete.chatId,
    );

    if (privateNoteToRedirectTo.length === 0) {
      setSelectedChat(-1);
      setMeetingTab(MEETING_SECTION.SHARED_NOTES);
    }

    handlePrivateNoteSelected(privateNoteToRedirectTo[0].chatId);
    setSelectedChat(0);
    setMeetingTab(MEETING_SECTION.PRIVATE_NOTES);
  } else {
    setSelectedChat(-1);
    setMeetingTab(MEETING_SECTION.SHARED_NOTES);
  }
};

export default setTabIndicatorLeftAndWidth;
