/* eslint-disable no-unused-vars */
import date from 'date-and-time';
import { emptyInviteData } from '../../shared/components/quick-settings/invite-members/utils/constants';
import {
  GoogleMeetingIds, IntercomTrackEvent, InviteData, InviterData, ResolvedState,
  DatabaseGapiMeetingData,
  AuthState,
  GapiMeetingData,
} from '../../shared/types/types';
import { INVITED_SIGN_UP, LOGIN_EVENT, SIGNUP_EVENT } from '../analytics/enums';
import { logAuthenticationEvent } from '../analytics/eventLogger';
import { getStartOfToday } from '../dateUtils/date';
import { MeetingUtils } from '../meetings/meetingsUtils';
import CloudFunctions from '../../database/CloudFunctions';

export const gapiInsertFirstMeetingSummary = () => {
  const lightningEmojiCode = String.fromCodePoint(0x26A1);
  const summary = `${lightningEmojiCode} Boom - This is your first meeting note! ${lightningEmojiCode}`;

  return summary;
};

export const gapiFormatTimeForFirstMeeting = () => {
  const startTime = new Date();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const endTime = new Date(startTime.getTime() + (60 * 60 * 1000)); // add 1 hour

  return { startTime: startTime.toISOString(), endTime: endTime.toISOString(), timeZone };
};

export const gapiFormatTimeForInstantMeeting = () => {
  const startTime = new Date();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const endTime = new Date(startTime.getTime() + (15 * 60 * 1000)); // add 15 min

  return { startTime: startTime.toISOString(), endTime: endTime.toISOString(), timeZone };
};

export const mapGapiInsertMeetingResponseToGoogleMeetingIdsObj = (gapiResponse: any) => {
  const GoogleMeetingIdsObj: GoogleMeetingIds = {
    eventId: gapiResponse.result?.id ?? '',
    calendarId: gapiResponse.result?.creator?.email ?? '',
    resolvedState: 'resolved',
  };

  return GoogleMeetingIdsObj;
};

export const mapDatabaseGapiMeetingDataToGapiMeetingIdsObj = (data: DatabaseGapiMeetingData) => {
  const GoogleMeetingIdsObj: GoogleMeetingIds = {
    eventId: data.id ?? '',
    calendarId: data.creator?.email ?? '',
    resolvedState: 'resolved',
  };

  return GoogleMeetingIdsObj;
};

export const RejectedGoogleMeetingIdsObj: GoogleMeetingIds = {
  eventId: '',
  calendarId: '',
  resolvedState: 'rejected',
};

export const PendingGoogleMeetingIdsObj: GoogleMeetingIds = {
  eventId: '',
  calendarId: '',
  resolvedState: 'pending',
};

export const trackLoginAnalytics = async (
  userId: string, intercomTrackEvent: IntercomTrackEvent,
) => {
  logAuthenticationEvent(userId, intercomTrackEvent, LOGIN_EVENT);
};

export const trackSignUpAnalytics = async (
  userId: string,
  intercomTrackEvent: IntercomTrackEvent,
  signUpEmail: string,
) => {
  if (!signUpEmail) return;
  if (signUpEmail.length === 0) return;

  const {
    resolvedState, inviteId, invitedBy, inviteSource,
  } = await cfGetInviteDataOfSignUpEmail(signUpEmail);

  if (resolvedState === 'rejected' || inviteId === undefined || inviteId.length === 0) {
    logAuthenticationEvent(userId, intercomTrackEvent, SIGNUP_EVENT);
    return;
  }

  logAuthenticationEvent(
    userId, intercomTrackEvent, SIGNUP_EVENT, INVITED_SIGN_UP, inviteSource,
  );
  cfUpdateInviteDataSignedUpStatusToTrue(inviteId);
  cfUpdateInviteeSignedUpStatusInUserDataToTrue(invitedBy, inviteId);
};

const cfGetInviteDataOfSignUpEmail = (email: string) => (
  CloudFunctions().getInviteData({ email })
    .then((res: any) => {
      const { resolvedState, inviteData } = res.data;

      if (resolvedState === 'rejected') {
        console.error('error fetching invite data of signup email');
        return { resolvedState: 'rejected', ...emptyInviteData as InviteData };
      }

      console.log('successfully fetched invite data of signup email');
      return { resolvedState: 'resolved', ...inviteData as InviteData };
    })
    .catch((error) => {
      console.error('error fetching invite data of signup email', error);
      return { resolvedState: 'rejected', ...emptyInviteData as InviteData };
    })
);

const cfUpdateInviteDataSignedUpStatusToTrue = (inviteId: string) => (
  CloudFunctions().updateInviteeSignedUpStatusInUserDataToTrue({ inviteId })
    .then(() => {
      console.log('successfully updated signed up status of invite data');
      return 'resolved' as ResolvedState;
    })
    .catch((error) => {
      console.log('error updating signed up status of invite', error);
      return 'rejected' as ResolvedState;
    })
);

const cfUpdateInviteeSignedUpStatusInUserDataToTrue = (
  inviters: InviterData[],
  inviteId: string,
) => {
  if (inviters.length === 0) return;

  inviters.map((inviter: InviterData) => CloudFunctions()
    .updateInviteeSignedUpStatusInUserDataToTrue({ inviterUserId: inviter.userId, inviteId })
    .then(() => {
      console.log('successfully updated invitee signed up status in user data');
      return 'resolved' as ResolvedState;
    })
    .catch((error) => {
      console.log('error updating invitee signed up status in user data', error);
      return 'rejected' as ResolvedState;
    }));
};

export const appendTextToDescriptionString = (description: string, newText: string) => `${description}${newText}`;

export class GapiUtils {
  /**
   * Some events might have an empty title, and these events we don't want to
   * show in our app.
   *
   * If you have access to a calendar, but a specific event is set to private, then
   * you might be able to see that there is an event, but the event will show
   * up with an empty title. These events we don't want to show in all notes dashboard
   */
  static filterMustHaveTitle = (gapiData: GapiMeetingData) => {
    if (gapiData.summary.length > 0) {
      return true;
    }
    return false;
  }

  static filterOnlyUniqueMeetingsByEventId = (
    meeting: GapiMeetingData, index: number, self: GapiMeetingData[],
  ) => index === self.findIndex(
    (selfMeeting) => selfMeeting.id === meeting.id,
  );

  static sortMeetingsByDate = (
    a: GapiMeetingData, b: GapiMeetingData,
  ) => MeetingUtils.sortByStartDate(b.start.dateTime, a.start.dateTime);

  static filterOnlyTodayOrFutureMeetings = (gapiData: GapiMeetingData) => {
    const startOfToday = getStartOfToday();
    const meetingStart = new Date(gapiData.start.dateTime);
    return meetingStart >= startOfToday;
  }

  /**
   * We want to show firestore meetings in the same future period as we show
   * GAPI meetings. We show gapi meetings 7 days a head of the gapiMaxStartDay
   * value.
   */
  static filterOnlyMeetingsBeforeDate = (
    gapiMaxStartDay: number,
  ) => (gapiData: GapiMeetingData) => {
    const today = getStartOfToday();
    const startDate = date.addDays(today, gapiMaxStartDay);
    const endDate = date.addDays(startDate, 7);

    const meetingStart = new Date(gapiData.start.dateTime);
    return meetingStart <= endDate;
  }

  static filterOnlyPreviousMeetings = (gapiData: GapiMeetingData) => {
    const startOfToday = getStartOfToday();
    const meetingStart = new Date(gapiData.start.dateTime);
    return meetingStart < startOfToday;
  }
}
