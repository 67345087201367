import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface TableColorProps {
  fill?: string;
  width?: number;
  height?: number;
}

const TableColorIcon = ({
  fill,
  width,
  height,
}: TableColorProps) => (
  <svg width={width} height={height} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 5.9999633,0 1.7577758,4.0996094 c -0.83910993,0.81101 -1.41107808,1.8458131 -1.64257808,2.9707031 -0.23151,1.12489 -0.11233313,2.2900099 0.34179687,3.3496095 0.45413,1.0596 1.22228431,1.966315 2.20898431,2.603516 C 3.6526689,13.660637 4.8132833,14 5.9999633,14 c 1.1867,0 2.3472844,-0.339363 3.3339844,-0.976562 0.9866993,-0.6372 1.7547843,-1.543916 2.2089843,-2.603516 0.4541,-1.0596006 0.573297,-2.2247195 0.341797,-3.3496095 -0.2315,-1.12489 -0.803478,-2.1596931 -1.642578,-2.9707031 z m 0,2.0859375 3.2011719,3.09375 c 0.6257,0.60474 1.0452438,1.3693094 1.2148438,2.1933594 0.1695,0.82362 0.08195,1.6778309 -0.251954,2.4570312 C 9.8297258,10.609978 9.2604945,11.283219 8.5194945,11.761719 7.7778946,12.240619 6.9011233,12.5 5.9999633,12.5 5.0988033,12.5 4.2219621,12.240619 3.4804321,11.761719 2.7394121,11.283219 2.1701308,10.609978 1.8359008,9.8300781 1.5019308,9.0508778 1.4144377,8.1966669 1.5839477,7.3730469 c 0.1696,-0.82405 0.5891537,-1.5886194 1.2148437,-2.1933594 z"
      fill={fill}
    />
  </svg>
);

TableColorIcon.defaultProps = {
  fill: darkBlue4,
  width: 12,
  height: 14,
};

export default TableColorIcon;
