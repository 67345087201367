import React from 'react';
import { surface } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const MessageIcon = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.394 2.25004L5.59214 2.25C3.21499 2.25 1.5 4.17113 1.5 6.64423V11.3558C1.5 13.8289 3.21499 15.75 5.59214 15.75H12.3876C13.5294 15.7372 14.6101 15.2565 15.3844 14.4199C16.1587 13.5833 16.559 12.4637 16.492 11.322L16.493 6.64423C16.559 5.53628 16.1587 4.41671 15.3844 3.58008C14.6101 2.74345 13.5294 2.26279 12.394 2.25004ZM5.59214 3.39632L12.3813 3.39629C13.2055 3.40554 13.9899 3.75444 14.5519 4.36172C15.114 4.969 15.4045 5.78165 15.3559 6.61041L15.3549 11.3558C15.4045 12.2183 15.114 13.031 14.5519 13.6383C13.9899 14.2456 13.2055 14.5945 12.3813 14.6037L5.59214 14.6037C3.87209 14.6037 2.63808 13.2213 2.63808 11.3558V6.64423C2.63808 4.77866 3.87209 3.39632 5.59214 3.39632ZM13.518 6.47878C13.3216 6.23164 12.9635 6.19166 12.7182 6.38947L9.64372 8.86818L9.5556 8.93011C9.1931 9.15716 8.71709 9.13619 8.36986 8.86359L5.27293 6.38799L5.20322 6.3402C4.96302 6.20046 4.65106 6.25523 4.47346 6.48064C4.27809 6.72859 4.31928 7.08912 4.56545 7.2859L7.6663 9.76461L7.78728 9.85315C8.56732 10.3844 9.60101 10.355 10.3491 9.76772L13.4294 7.28441L13.4912 7.22663C13.68 7.02196 13.6966 6.70344 13.518 6.47878Z" fill={fill} />
  </svg>
);

MessageIcon.defaultProps = {
  fill: surface,
};

export default MessageIcon;
