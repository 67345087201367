import React from 'react';
import { layoutWhite } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const MoreIcon = ({ fill }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.16667 11.6666C5.08714 11.6666 5.83333 10.9204 5.83333 9.99992C5.83333 9.07944 5.08714 8.33325 4.16667 8.33325C3.24619 8.33325 2.5 9.07944 2.5 9.99992C2.5 10.9204 3.24619 11.6666 4.16667 11.6666Z" fill={fill} />
    <path d="M10 11.6666C10.9205 11.6666 11.6667 10.9204 11.6667 9.99992C11.6667 9.07944 10.9205 8.33325 10 8.33325C9.07952 8.33325 8.33333 9.07944 8.33333 9.99992C8.33333 10.9204 9.07952 11.6666 10 11.6666Z" fill={fill} />
    <path d="M15.8333 11.6666C16.7538 11.6666 17.5 10.9204 17.5 9.99992C17.5 9.07944 16.7538 8.33325 15.8333 8.33325C14.9129 8.33325 14.1667 9.07944 14.1667 9.99992C14.1667 10.9204 14.9129 11.6666 15.8333 11.6666Z" fill={fill} />
  </svg>

);

MoreIcon.defaultProps = {
  fill: layoutWhite,
};

export default MoreIcon;
