import {
  blockQuote, defaultSmall, defaultText, header100, header200, header300, header400, header500,
  header600, header700, header800, header900, link, linkHover, messageText, paragraph, pointer,
  small, smallPluss, titleSemibold, titleSemiboldForDashboardWelcomeScreen, uiText, uiTextM,
  uiTextMediumM, uiTextMedium, uiTextMediumS, uiTextS, uiTextL, uiTextMediumL,
} from '.';

/**
 * Contains all the fonts available
 */
const FONTS = {
  header100,
  header200,
  header300,
  header400,
  header500,
  header600,
  header700,
  header800,
  header900,
  uiText,
  uiTextMedium,
  messageText,
  small,
  smallPluss,
  pointer,
  defaultText,
  defaultSmall,
  link,
  linkHover,
  blockQuote,
  titleSemibold,
  paragraph,
  uiTextMediumL,
  uiTextL,
  uiTextS,
  uiTextMediumM,
  uiTextM,
  uiTextMediumS,
  titleSemiboldForDashboardWelcomeScreen,
};

export default FONTS;

export type FontType = keyof typeof FONTS;
