/* eslint-disable arrow-body-style */
import React from 'react';
import styled, { css } from 'styled-components';
import {
  getNiceDateAndTime2, getNiceDateAndTime3, returnYearIfNotCurrentYear,
} from '../../../../utils/dateUtils/date';
import SDate from '../../../classes/SDate';
import { COLORS } from '../../../colors/COLORS';
import { MeetingData } from '../../../types/MeetingData';
import Font from '../../../typography/Font';

interface IsTodayProps {
  isToday: boolean;
}

const Container = styled.div<IsTodayProps>`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;

  ${(props) => (props.isToday && css`
    background-color: ${COLORS.blue1};
  `)}

  &:hover {
    background-color: #F2F2F2;
    ${(props) => (props.isToday && css`
      background-color: ${COLORS.blue2};
    `)}
  } 

  & >:last-child {
    width: max-content;
  }
`;

const DateContainer = styled.div`
  display: flex;
  width: fit-content;
  margin-right: 10px;
  & > span {
    font-size: 12px !important;
  }

  & > span:nth-child(1) {
    margin-right: 5px;
    min-width: 15px;
    display: flex;
    justify-content: center;
  }

  & > span:nth-child(2) {
    margin-right: 5px;
    min-width: 22px;
  }
`;

const TodayContainer = styled.div`
  display: flex;
  min-width: 57px;
`;

interface RelevantMeetingItemProps {
  meeting: MeetingData,
  // eslint-disable-next-line no-unused-vars
  onClick: (meeting: MeetingData) => void,
}

const RelevantMeetingItem = ({ meeting, onClick }: RelevantMeetingItemProps) => {
  const { title } = meeting.data;
  const year = returnYearIfNotCurrentYear(new Date(meeting.date.start.date));

  const today = new SDate(meeting.date.start.date).isTodayReturnString();

  if (today) {
    return (
      <Container onClick={() => onClick(meeting)} isToday>
        <TodayContainer>
          <Font font="small" color="darkBlue4">{today}</Font>
        </TodayContainer>
        <Font font="smallPluss">{title}</Font>
      </Container>
    );
  }

  return (
    <Container onClick={() => onClick(meeting)} isToday={false}>
      <DateContainer>
        <Font font="small" color="darkBlue4">{getNiceDateAndTime2(new Date(meeting.date.start.date))}</Font>
        <Font font="small" color="darkBlue4">{getNiceDateAndTime3(new Date(meeting.date.start.date))}</Font>
        {year && <Font font="small" color="darkBlue4">{year}</Font>}
      </DateContainer>
      <Font font="smallPluss">{title}</Font>
    </Container>
  );
};

export default RelevantMeetingItem;
