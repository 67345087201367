/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../colors/COLORS';

const Container = styled.div<DaysLeftInTrialCircleAtomProps>`
  /* color: white;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: 10px;

  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; */

  cursor: pointer;
  user-select: none;
  border-radius: 50%;
  color: white;

  box-sizing: border-box;

  width: 23px;
  height: 23px;

  background: rgba(218, 64, 202, 0.3);
  border: 2px solid #F54DE5;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

`;

interface DaysLeftInTrialCircleAtomProps {
  daysLeft: number,
  onClick?: () => void,
}

const DaysLeftInTrialCircleAtom = ({
  daysLeft,
  onClick = () => { },
}: DaysLeftInTrialCircleAtomProps) => {
  const daysLeftMinimumOne = Math.max(0, daysLeft);

  return (
    <Container daysLeft={daysLeftMinimumOne} onClick={onClick}>
      {daysLeftMinimumOne}
    </Container>
  );
};

export default DaysLeftInTrialCircleAtom;
