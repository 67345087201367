import {
  AuthState, PublicUserDataV2, ReporterV2, ResolvedState, TaskItem,
} from '../../shared/types/types';
import { slackCoreAPISendNotificationForTaskCreate, slackCoreAPISendNotificationForTaskUpdate } from '../../utils/slack/SlackCoreAPI';
import SlackCoreAPI from './SlackCoreAPI';

class SlackAPI extends SlackCoreAPI {
  static async sendNotificationForTaskCreation(
    taskItem: TaskItem,
    authState: AuthState,
  ): Promise<any> {
    slackCoreAPISendNotificationForTaskCreate(
      taskItem,
      authState,
    );
  }

  /**
   * In some places it's hard to get the authState, so this function has a simpler
   * interface, where we create the authstate inside the function,
   * and use the existing `sendNotificationForTaskCreation` function
   */
  static async sendNotificationForTaskCreationWithCustomReporter(
    taskItem: TaskItem,
    reporter: ReporterV2,
  ): Promise<any> {
    const authState: AuthState = {
      userState: 'loggedIn',
      userId: reporter.userId,
      firstName: reporter.data.firstName,
      lastName: reporter.data.lastName,
      email: reporter.data.email,
      photoUrl: '', // We don't need this for slack
    };
    return SlackAPI.sendNotificationForTaskCreation(taskItem, authState);
  }

  static async sendNotification(
    title: string,
    message: string,
    recipientData: PublicUserDataV2,
  ): Promise<ResolvedState> {
    return SlackCoreAPI.sendNotificationCore(title, message, recipientData);
  }

  static sendNotificationForTaskUpdate = (
    task: TaskItem,
    context: AuthState,
    updatedSection: string,
    newDueDate?: string,
    newTitle?: string,
  ) => {
    slackCoreAPISendNotificationForTaskUpdate(task, context, updatedSection, newDueDate, newTitle);
  };
}

export default SlackAPI;
