import React from 'react';
import styled from 'styled-components';
import CloseSquare from '../../../shared/icons/closeSquare';
import IconRockOnHand from '../../../shared/icons/icon _rock_on';
import Font from '../../../shared/typography/Font';
import image from './BillingPopupTitleBackground.svg';

const BillingTitle = styled.div<HeightAndFontSizeInterface>`
  border-radius: 10px 10px 0 0;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${image}) ;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-left: -1.5px;
  margin-right: -1.5px;

  overflow: hidden;

  > *:first-child {
    font-size: ${({ fontSize }) => fontSize};
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const RockHandContainer = styled.div`
  margin-left: 6px;
`;

interface HeightAndFontSizeInterface {
  height?: string,
  fontSize?: string,
}

interface BillingModalTitleProps extends HeightAndFontSizeInterface {
  title: string,
  showCloseIcon?: boolean,
  onClose?: () => void,
  showRockOnIcon?: boolean,
}

const BillingModalTitle = ({
  title,
  showCloseIcon = false,
  onClose = () => { },
  showRockOnIcon = false,
  height = '85px',
  fontSize = '24px',
}: BillingModalTitleProps) => (
  <BillingTitle height={height} fontSize={fontSize}>
    <Font font="header700" color="gray1">{title}</Font>
    {showCloseIcon
      && (
        <CloseIconContainer onClick={onClose}>
          <CloseSquare width="20px" height="20px" />
        </CloseIconContainer>
      )}
    {showRockOnIcon
      && (
        <RockHandContainer onClick={onClose}>
          <IconRockOnHand />
        </RockHandContainer>
      )}
  </BillingTitle>
);

export default BillingModalTitle;
