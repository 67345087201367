import { captureException } from '@sentry/react';
import { NavigateFunction } from 'react-router-dom-v5-compat';
import { Dispatch, SetStateAction } from 'react';
import { MEETING_PATH } from '../../database/FirebaseConstants';
import { dbAddMeeting, dbUpdateMeetingData } from '../../database/Meetings/firebaseMeetingAPI';
import UserAPI from '../../database/User/UserAPI';
import { pendingGapiMeetingData } from '../../database/utils/gapiMeetingDataUtils';
import { mapGoogleMeetingToDatabaseMeetingData } from '../../database/utils/templateMeetingData';
import updateMeetingDataFromGapiMeetingData from '../../database/utils/updateMeetingDataFromCalendarData';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import {
  GapiCalendarData,
  GapiMeetingData, GoogleAttendee, ResolvedStateItem, ResolvedState, User,
} from '../../shared/types/types';
import { MeetingData } from '../../shared/types/MeetingData';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { gapiGetMeeting, getMeetingsInPeriod } from '../../utils/google/GoogleCalendarAPI';
import { gapiCoreGetMeeting, gapiInsertMeetingCore } from '../../utils/google/GoogleCalendarCore';
import { gapiFormatTimeForInstantMeeting } from '../../utils/google/GoogleCalendarUtils';
import log from '../../utils/logging';
import { makeMeetingUrl } from '../../utils/meetings/meetingsUtils';
import { StringUtils } from '../../utils/strings';
import GAPICore from './GAPICore';
import SentryAPI from '../../utils/analytics/SentryAPI';

declare let gapi: any;

/**
 * Class to interact with the Google API, including meetings and calendars.
 */
class GAPI extends GAPICore {
  /**
   * Gets Gapi data for a specific meeting using the meeting eventId and calendarId
   * and attendee information
   */
  static async getGapiDataForMeeting(
    meetingData: MeetingData,
    user: User,
    setGapiMeetingData: Dispatch<SetStateAction<GapiMeetingData>>,
  ): Promise<void> {
    if (meetingData.resolvedState !== RESOLVED) return;
    console.log(`Calling getGapiData for: '${meetingData.data.title.substring(0, 15)}'`);
    setGapiMeetingData(pendingGapiMeetingData);
    const gapiMeetingData = await gapiGetMeeting(
      meetingData.googleData.ids.eventId,
      meetingData.googleData.ids.calendarId,
      user.data.email,
      meetingData,
    );
    console.log(`Google Calendar API response: (eventId: ${meetingData.googleData.ids.eventId}), and (calendarId: ${meetingData.googleData.ids.calendarId})`);
    setGapiMeetingData(gapiMeetingData);
    SentryAPI.setContext('gapiMeetingData', gapiMeetingData);

    updateMeetingDataFromGapiMeetingData(gapiMeetingData, meetingData, user);
  }

  static async getMeetingsInPeriodApi(startDay: number, additionalDays: number)
    : Promise<ResolvedStateItem<GapiMeetingData[]>> {
    // const meetings = await getTodaysMeetings();
    const meetings = await getMeetingsInPeriod(startDay, additionalDays);
    // ConsoleImproved.log('New meetings in getMeetingsInPeriodApi', meetings);
    const filteredMeetingsByHavingAStartTime = meetings.filter((meeting) => meeting.start.dateTime);
    const nonDuplicatedMeetings = GAPI.removeDuplicateMeetings(
      filteredMeetingsByHavingAStartTime,
    );
    const sortedMeetingsByStartTime = nonDuplicatedMeetings.sort(
      (a, b) => (
        // eslint-disable-next-line no-nested-ternary
        (a.start.dateTime > b.start.dateTime) ? -1 : (
          (a.start.dateTime < b.start.dateTime) ? 1 : 0)),
    );
    return {
      item: sortedMeetingsByStartTime,
      resolvedState: RESOLVED,
    };
  }

  static mapMeetingsToAttendees(meetings: GapiMeetingData[]): GoogleAttendee[] {
    const allAttendees = meetings.reduce((acc, meeting) => {
      const { attendees } = meeting;
      attendees.forEach((attendeeCandidate) => {
        if (!acc.find((attendee) => attendee.email === attendeeCandidate?.email)) {
          acc.push(attendeeCandidate);
        }
      });
      return acc;
    }, [] as GoogleAttendee[]);
    return allAttendees;
  }

  static removeDuplicateMeetings(meetings: GapiMeetingData[]): GapiMeetingData[] {
    const nonDuplicatedMeetings = meetings.reduce((acc, meeting) => {
      if (!acc.find((m) => m.id === meeting.id)) {
        acc.push(meeting);
      }
      return acc;
    }, [] as GapiMeetingData[]);
    return nonDuplicatedMeetings;
  }

  static async createNewMeeting(
    title: string,
    description: string,
    userId: string,
    navigate: NavigateFunction,
  ): Promise<boolean> {
    const { startTime, endTime, timeZone } = gapiFormatTimeForInstantMeeting();

    const event = {
      summary: title,
      description,
      start: {
        dateTime: startTime,
        timeZone,
      },
      end: {
        dateTime: endTime,
        timeZone,
      },
    };

    try {
      const { eventId, calendarId } = await gapiInsertMeetingCore(event);
      const newlyCreatedMeeting = await gapiCoreGetMeeting(eventId, 'primary', 0, true);
      const user = await UserAPI.OtherUsers.dbGetSimpleUserDataByUserId(userId, 'admin');
      const addMeetingResponse = await dbAddMeeting(
        mapGoogleMeetingToDatabaseMeetingData(newlyCreatedMeeting, calendarId, user),
      );
      if (addMeetingResponse.resolvedState === 'resolved') {
        const meetingUrl = makeMeetingUrl(addMeetingResponse.meetingId);
        log(`Created new quick note meeting in database with meetingId: ${addMeetingResponse.meetingId}`);
        navigate(meetingUrl);
        return true;
      }
    } catch (error) {
      console.log('Error creating new meeting', event);
      console.log(error);
      captureException(new Error('Error creating new meeting'));
      return false;
    }

    return true;
  }

  /** Updates the title in both Google Calendar and the database */
  static async updateTitle(
    newTitle: string,
    gapiMeetingData: GapiMeetingData,
    meetingId: string,
  ): Promise<ResolvedState> {
    const eventId = gapiMeetingData.id;
    const calendarId = gapiMeetingData.organizer.email;

    const requestBody = {
      summary: newTitle,
    };

    try {
      const response = await gapi.client.calendar.events.patch({
        calendarId,
        eventId,
        resource: requestBody,
      });
      if (response.status === 200) {
        console.log(`Updated title of meeting to: '${newTitle}'`);
        console.log(response);
        dbUpdateMeetingData(newTitle, meetingId, MEETING_PATH.data.title);
        // Add logging
        return RESOLVED;
      }
    } catch (error: any) {
      ConsoleImproved.log('Error updating title', error);
      ConsoleImproved.log(error.result.error);
      captureException(new Error('Error updating title'));
      return REJECTED;
    }

    return RESOLVED;
  }

  /**
   * @param filterOnlySelected (default: true) if true, will only return those calendars
   * you have enabled (toggled on) in your google calendar
   */
  static async getMyCalendars(filterOnlySelected?: boolean): Promise<GapiCalendarData[]> {
    const calendars = await this.getMyCalendarsCore(filterOnlySelected);
    return calendars;
  }

  /**
   * Returns all Ids of all your calendars
   *
   * @param filterOnlySelected (default: true) if true, will only return those calendars
   * you have enabled (toggled on) in your google calendar
   */
  static async getMyCalendarIds(filterOnlySelected?: boolean): Promise<string[]> {
    const calendars = await this.getMyCalendars(filterOnlySelected);
    const ids = calendars
      .map((calendar) => calendar.id)
      .sort(StringUtils.sortStringsAlphabetically);
    return ids;
  }

  static async getAndUpdateMyCalendarIds(
    userData: User,
  ) {
    const calendarIds = await this.getMyCalendarIds();
    UserAPI.Permissions.updateUserCalendarIds(userData, calendarIds);
  }
}

export default GAPI;
