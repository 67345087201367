import { GapiCalendarData } from '../../shared/types/types';

/**
 * Maps the raw untyped data from Gapi to a typed GapiCalendarData object
 * They should match in structure, but this is a safety measure
 */
const mapRawGapiCalendarToGapiCalendarData = (gapiData: any): GapiCalendarData => {
  const newData: GapiCalendarData = {
    kind: 'calendar#calendarListEntry',
    etag: gapiData?.etag ?? '',
    id: gapiData?.id ?? '',
    summary: gapiData?.summary ?? '',
    hidden: gapiData?.hidden ?? false,
    description: gapiData?.description ?? '',
    location: gapiData?.location ?? '',
    timeZone: gapiData?.timeZone ?? '',
    summaryOverride: gapiData?.summaryOverride ?? '',
    colorId: gapiData?.colorId ?? '',
    backgroundColor: gapiData?.backgroundColor ?? '',
    foregroundColor: gapiData?.foregroundColor ?? '',
    selected: gapiData?.selected ?? false,
    accessRole: gapiData?.accessRole ?? '',
    defaultReminders: gapiData?.defaultReminders ?? [],
    notificationSettings: {
      notifications: gapiData?.notificationSettings?.notifications ?? [],
    },
    primary: gapiData?.primary ?? false,
    deleted: gapiData?.deleted ?? false,
    conferenceProperties: {
      allowedConferenceSolutionTypes: gapiData?.conferenceProperties
        ?.allowedConferenceSolutionTypes ?? [],
    },
  };
  return newData;
};

export default mapRawGapiCalendarToGapiCalendarData;
