import React, {
  useContext, useEffect, useState, Dispatch, SetStateAction,
} from 'react';
import { NavigateFunction, useParams, useNavigate } from 'react-router-dom-v5-compat';
import { useIntercom } from 'react-use-intercom';
import { captureException } from '@sentry/react';
import { trace } from 'firebase/performance';
import { AuthContext } from '../../App';
import { dbAddMeeting, dbGetMeetingByEventAndCalendarId } from '../../database/Meetings/firebaseMeetingAPI';
import { mapGoogleMeetingToDatabaseMeetingData } from '../../database/utils/templateMeetingData';
import SpinnerLoadingPage from '../../shared/components/loading/spinner-loading-page';
import { AuthState, ShepherdMeetingId, SimpleUserData } from '../../shared/types/types';
import { performance } from '../../utils/firebase';
import { logEventPageViewGoogleCalendar } from '../../utils/analytics/eventLogger';
import { gapiGetMeeting } from '../../utils/google/GoogleCalendarAPI';
import { makeMeetingUrl } from '../../utils/meetings/meetingsUtils';
import GoogleCalendarErrorPage from './GoogleCalendarErrorPage';
import UserAPI from '../../database/User/UserAPI';
import SentryAPI from '../../utils/analytics/SentryAPI';

const GoogleCalendar = () => {
  const [error, setError] = useState<boolean>(false);
  const authState = useContext(AuthContext);
  const navigate = useNavigate();
  const { update } = useIntercom();
  const params = useParams();

  const eventId: string = params?.eventId ?? '';
  const calendarId: string = params?.calendarId ?? '';

  useEffect(() => {
    getOrMakeMeetingByEventAndCalendarId(eventId, calendarId, authState, navigate, setError);
  }, [eventId, calendarId]);

  useEffect(() => {
    logEventPageViewGoogleCalendar(authState, update);
  }, []);

  const handleRetry = () => {
    setError(false);
    getOrMakeMeetingByEventAndCalendarId(eventId, calendarId, authState, navigate, setError);
  };

  if (error) {
    return <GoogleCalendarErrorPage email={authState.email} retry={handleRetry} />;
  }

  return (
    <SpinnerLoadingPage />
  );
};

export default GoogleCalendar;

export const getMeetingIdByEventIdAndCalendarId = async (
  eventId: string,
  calendarId: string,
) => {
  const { meetingId } = await dbGetMeetingByEventAndCalendarId(eventId, calendarId);
  return meetingId;
};

export const getOrMakeMeetingByEventAndCalendarId = async (
  eventId: string,
  calendarId: string,
  authState: AuthState,
  navigate: NavigateFunction,
  setError: Dispatch<SetStateAction<boolean>>,
) => {
  if (eventId.length === 0 || calendarId.length === 0) {
    console.log('EventId or calendarId is not long enough', eventId, calendarId);
    captureException('EventId or calendarId is not long enough', { extra: { eventId, calendarId, authState } });
    setError(true);
    return '';
  }

  let { meetingId } = await dbGetMeetingByEventAndCalendarId(eventId, calendarId);
  if (meetingId.length === 0) {
    const newMeeting = await makeNewMeetingByEventAndCalendarId(
      eventId, calendarId, authState,
    );

    if (newMeeting.resolvedState === 'rejected') {
      setError(true);
      return '';
    }
    SentryAPI.addBreadcrumb({
      category: 'getOrMakeMeetingByEventAndCalendarId', message: 'getOrMakeMeetingByEventAndCalendarId called and making new meeting', level: 'info', data: { meetingId, eventId, calendarId },
    });

    meetingId = newMeeting.meetingId;
  } else {
    SentryAPI.addBreadcrumb({
      category: 'getOrMakeMeetingByEventAndCalendarId', message: 'getOrMakeMeetingByEventAndCalendarId called and got meeting by event and calendar id', level: 'info', data: { meetingId, eventId, calendarId },
    });
  }

  navigate(makeMeetingUrl(meetingId));
  return meetingId;
};

const makeNewMeetingByEventAndCalendarId = async (
  eventId: string,
  calendarId: string,
  userData: AuthState,
) => {
  const tr = trace(performance, 'makeNewMeetingByEventAndCalendarId');
  tr.start();
  const googleMeeting = await gapiGetMeeting(
    eventId, calendarId, userData.email,
  );
  if (googleMeeting.resolvedState === 'rejected') {
    tr.stop();
    return { resolvedState: 'rejected', meetingId: '' } as ShepherdMeetingId;
  }

  const user: SimpleUserData = await UserAPI.OtherUsers.dbGetSimpleUserDataByUserId(userData.userId, 'admin');
  const newMeeting = mapGoogleMeetingToDatabaseMeetingData(
    googleMeeting,
    calendarId,
    user,
  );
  const newMeetingIdResponse: ShepherdMeetingId = await dbAddMeeting(newMeeting);
  tr.stop();

  return newMeetingIdResponse;
};
