/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ConsoleImproved from '../../../shared/classes/ConsoleImproved';
import SDate from '../../../shared/classes/SDate';
import {
  blue1, blue2, COLORS, layoutGrey, layoutWhite,
} from '../../../shared/colors/COLORS';
import ProfileIconsRow from '../../../shared/components/profile-icon/ProfileIconsRow';
import BREAKPOINTS from '../../../shared/CSS/CSS_Constants';
import { GapiMeetingData, GoogleAttendee, PublicUserDataV2 } from '../../../shared/types/types';
import useMeetingsAndUsersStore from '../../../zustand/useAllMeetingsStore';
import { getDay } from '../DayLableAtom';
import NoteItemDateAtom from './NoteItemDateAtom';
import NoteItemTitleAtom from './NoteItemTitleAtom';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-right: 10px;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
  margin-right: 20px;

  &:first-child{
    margin-left: 8px;
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm}){
    &:first-child{
      margin-left: 0px ;
    }
  }
`;

interface MeetingCardProp {
  isCurrentlyOngoing: boolean;
  date: string;
}

const RightContainer = styled.div<MeetingCardProp>`
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  /* width: 100%; */
  margin-right: 15px;
  min-height: 44px;
  

  background-color: ${layoutWhite};
 
  ${({ date }) => getDay(date) === 'Today' && css` 
    background-color: white;
    /* background-color: ${COLORS.blue1}; */
  `}

  /* & > *:not(:first-child) {
    margin-left: 8px;
  } */
/* last child */
  /* & > *:last-child {
    margin-left: 30px;
  } */

  ${(props) => props.isCurrentlyOngoing && css`
    background-color: ${blue1};
    ${getDay(props.date) === 'Today' && css`
      background-color: ${COLORS.blue2};
    `}
  `}

  &:hover {
    background-color: ${layoutGrey};
    box-shadow: 0px 2px 8px rgb(27 33 36 / 16%);
    ${(props) => props.isCurrentlyOngoing && css`
      background-color: ${blue2};
      ${getDay(props.date) === 'Today' && css`
        background-color: ${COLORS.blue3};
      `}
    `}
  }
  @media only screen and (max-width: ${BREAKPOINTS.sm}){
    /* padding: 0px; */
    /* justify-content: space-between; */
    /* padding: 0px 10px; */
    margin-right: 0px;
    & > *:not(:first-child) {
        margin-left: 0px;
    }
  }
`;

const AttendeesContainer = styled.div`
  width: auto;
`;

interface Props {
  note: GapiMeetingData,
  date: string,
  // eslint-disable-next-line no-unused-vars
  onAction: (eventId: string, calendarId: string, startDateTime: string) => void,
  // title: string;
  // // attendees: AttendeeV2[];
  // attendees: any[];
  // // replace date type
  // date: any;
}

// eslint-disable-next-line max-len

const NoteItemMolecule = ({ note, date, onAction }: Props) => {
  const numberOfDisplayAttendees = 2;
  const [meetingUsers, setMeetingUsers] = useState<PublicUserDataV2[]>([]);
  const [extraMeetingUsers, setExtraMeetingUsers] = useState<PublicUserDataV2[]>([]);
  // ToDo: Enhance Extra Attendees, to show all attendees
  // const [extraMeetingUsers, setExtraMeetingUsers] = useState<PublicUserDataV2[]>([]);
  const { fetchUsers } = useMeetingsAndUsersStore();

  const getDisplayUsers = async (attendees: GoogleAttendee[]) => {
    const users = await fetchUsers(attendees);
    const displayAttendees = users.slice(0, numberOfDisplayAttendees);
    const extraDisplayAttendees = users.slice(numberOfDisplayAttendees);
    setMeetingUsers(displayAttendees);
    setExtraMeetingUsers(extraDisplayAttendees);

    // cfGetAttendeesFromGoogleAttendees(attendees).then((result: PublicUserDataV2[]) => {
    //   const displayAttendees = result.slice(0, numberOfDisplayAttendees);
    //   setMeetingUsers(displayAttendees);
    // });
  };

  useEffect(() => {
    // getDisplayUsers(note.attendees);
  }, []);

  const handleGoToMeeting = () => {
    ConsoleImproved.log('Clicked go to meeting', note);
    // TODO: Should we be using note.organizer.email in stead?
    onAction(note.id, note.iCalUID, note.start.dateTime);
  };

  const isCurrentlyOngoing = SDate.isMeetingCurrentlyOngoing(note);
  const meetingTitle = note.status === 'cancelled' ? `${note.summary} - (Cancelled)` : note.summary;

  return (
    <Container>
      <LeftContainer>
        <NoteItemDateAtom date={note.start.dateTime} />
      </LeftContainer>
      <RightContainer
        onClick={handleGoToMeeting}
        isCurrentlyOngoing={isCurrentlyOngoing}
        date={date}
      >
        <NoteItemTitleAtom text={meetingTitle} />
        {/* <AttendeesContainer>
          <ProfileIconsRow
            profileIconAttendees={meetingUsers}
            extraAttendees={extraMeetingUsers}
            size={24}
          />
        </AttendeesContainer> */}
        {/* <NoteItemDropdownMolecule note={null} /> */}
        {/* <GoToMeetingAtom onClick={handleGoToMeeting} /> */}
      </RightContainer>
    </Container>
  );
};

export default NoteItemMolecule;
