import {
  Breadcrumb, addBreadcrumb, captureException, setContext,
} from '@sentry/react';

/**
 * Wrapper around Sentry functions, to easier track when functions are called.
 */
class SentryAPI {
  static captureExceptionAndConsoleError = (
    functionName: string, error: Error, ...params: any[]
  ) => {
    const errorMessage = `, with message: ${error?.message ?? ''}`;
    const text = `Error in ${functionName}${errorMessage}`;
    console.error(text, { error, ...params });
    captureException(error, { extra: { functionName, params } });
  };

  /**
   * Sets context data with the given name.
   * @param name — of the context
   * @param context — Any kind of data. This data will be normalized.
   */
  static setContext = (name: string, context: {
    [key: string]: any;
} | null): void => {
    setContext(name, context);
  }

  static addBreadcrumb = (breadcrumb: Breadcrumb): void => {
    console.log('addBreadcrumb', breadcrumb);
    addBreadcrumb(breadcrumb);
  }
}

export default SentryAPI;
