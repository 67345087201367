import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ScrollStatus, Scrollbar as ScrollbarType } from 'smooth-scrollbar/interfaces';
import { Tabs as ReactTabs } from 'react-tabs';
import TabButton, { TabButtonStyles } from './TabButton';
import TabButtonsContainer, { TabButtonsContainerStyles } from './TabButtonsContainer';
import TabContent, { TabContentStyles } from './TabContent';
import Scrollbar from '../scrollbar';

const TabsStyles = styled.div`
  .react-tabs {
    min-height: ${(props: any) => props['data-height']};
    max-height: ${(props: any) => props['data-height']};
    padding: ${(props: any) => props['data-container-padding']};
  }
`;

interface Props {
  tabIndex: number,
  // eslint-disable-next-line no-unused-vars
  setTabIndex: (index: number) => void,
  children: ReactNode,
  height: string,
  // eslint-disable-next-line no-unused-vars
  onScroll?: ((status: ScrollStatus, scrollbar: ScrollbarType | null) => void) | undefined,
  enableScrollBar?: boolean
  tabButtonPadding?: string
  containerPadding?: string
  contentMargin?: string
}

const TaskTabs = ({
  children, tabIndex, setTabIndex,
  height, onScroll, enableScrollBar,
  tabButtonPadding, containerPadding, contentMargin,
}: Props) => {
  const tabJSX = (
    <TabsStyles data-height={height} data-container-padding={containerPadding}>
      <TabButtonsContainerStyles padding={tabButtonPadding}>
        <TabButtonStyles>
          <TabContentStyles margin={contentMargin}>
            <ReactTabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
              {children}
            </ReactTabs>
          </TabContentStyles>
        </TabButtonStyles>
      </TabButtonsContainerStyles>
    </TabsStyles>
  );
  if (enableScrollBar) {
    return (
      <Scrollbar maxHeight={height} onScroll={onScroll}>
        {tabJSX}
      </Scrollbar>
    );
  } return (tabJSX);
};

TaskTabs.defaultProps = {
  onScroll: undefined,
  enableScrollBar: true,
  tabButtonPadding: '0',
  containerPadding: '0px 16px',
  contentMargin: '16px',
};

export default TaskTabs;

export {
  TabButton, TabButtonsContainer, TabContent,
  TabButtonStyles, TabButtonsContainerStyles, TabContentStyles, TabsStyles,
};
