import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { header200 } from '../../../shared/typography';
import Switch from '../../../shared/components/switch';
import MailIcon from '../../../shared/icons/MailIcon';
import { NotionIcon, SlackIcon, TrelloIcon } from '../../../shared/icons/integration-icons';

const CheckedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 13px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlatformText = styled.h2`
  margin-left: 16px;
  ${header200};
`;

export interface Props {
  platform: 'notion' | 'slack' | 'email' | 'trello'
  isChecked: boolean,
  setIsChecked: Dispatch<SetStateAction<boolean>>,
  disabled?: boolean
}

export default function PlatformShareCheckbox({
  platform, isChecked, setIsChecked, disabled = false,
}: Props) {
  return (
    <CheckedContainer>
      <LogoContainer>
        {platform === 'slack' && <SlackIcon width="40" />}
        {platform === 'notion' && <NotionIcon width="40" />}
        {platform === 'trello' && <TrelloIcon width="40" />}
        {platform === 'email' && <MailIcon />}
        <PlatformText>
          {platform === 'slack' && 'Slack'}
          {platform === 'notion' && 'Notion'}
          {platform === 'trello' && 'Trello'}
          {platform === 'email' && 'Email'}
        </PlatformText>
      </LogoContainer>
      <Switch
        checked={isChecked}
        setChecked={setIsChecked}
        disabled={disabled}
      />
    </CheckedContainer>
  );
}
