import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { TaskTab } from '../../shared/types/types';
import { QueryParameters } from '../meetings/QueryUtils';
import { isValidTaskTab } from '../tasks/tasksUtils';
import useGetQueryParam from './useGetParams';

// eslint-disable-next-line no-unused-vars
type SetPageType = (newPage: TaskTab) => void;

/**
 * Hook to set the taskTab query parameter if found in URL
 * @example
 * `http://localhost:3000/meeting/BTSeWMfCEMAfnCqd4cJz/?taskTab=completed`
 * calling `useTaskSectionQueryParameter(setTaskTab)` will call `setTaskTab('completed')
 *
 * @param setTaskTab function to be called if a valid taskTab query parameter is found in the URL
 */
const useTaskSectionQueryParameter = (setTaskTab: SetPageType) => {
  const location = useLocation();

  const navigateToTab = useCallback(() => {
    const taskSectionQueryParam = useGetQueryParam(QueryParameters.TASK_TAB);

    if (!isValidTaskTab(taskSectionQueryParam)) return;
    ConsoleImproved.log(`Found valid task tab url param '?${QueryParameters.TASK_TAB}=${taskSectionQueryParam}'. Opening task tab`);
    setTaskTab(taskSectionQueryParam);
  }, [location]);

  useEffect(() => {
    navigateToTab();
  }, [navigateToTab]);
};

export default useTaskSectionQueryParameter;
