import React from 'react';
import { surface } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const DustbinIcon = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 11.8125C7.18934 11.8125 6.9375 11.5607 6.9375 11.25L6.9375 9C6.9375 8.68934 7.18934 8.4375 7.5 8.4375C7.81066 8.4375 8.0625 8.68934 8.0625 9L8.0625 11.25C8.0625 11.5607 7.81066 11.8125 7.5 11.8125Z" fill="#1B2124" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5 11.8125C10.1893 11.8125 9.9375 11.5607 9.9375 11.25L9.9375 9C9.9375 8.68934 10.1893 8.4375 10.5 8.4375C10.8107 8.4375 11.0625 8.68934 11.0625 9L11.0625 11.25C11.0625 11.5607 10.8107 11.8125 10.5 11.8125Z" fill="#1B2124" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.03244 5.82547C2.84685 5.8128 2.60715 5.8125 2.25 5.8125V4.6875L2.26894 4.6875C2.28128 4.6875 2.29354 4.6875 2.30572 4.6875H15.6943C15.7065 4.6875 15.7187 4.6875 15.7311 4.6875L15.75 4.6875V5.8125C15.3928 5.8125 15.1532 5.8128 14.9676 5.82547C14.787 5.83779 14.6991 5.85989 14.6412 5.88386C14.4115 5.97901 14.229 6.16152 14.1339 6.39123C14.1099 6.4491 14.0878 6.53701 14.0755 6.71756C14.0628 6.90315 14.0625 7.14285 14.0625 7.5L14.0625 12.039C14.0625 12.7129 14.0625 13.2748 14.0026 13.7209C13.9393 14.1917 13.8 14.6168 13.4584 14.9584C13.1168 15.3 12.6917 15.4393 12.2209 15.5026C11.7748 15.5625 11.2129 15.5625 10.539 15.5625H7.461C6.78714 15.5625 6.22523 15.5625 5.77914 15.5026C5.30829 15.4393 4.88319 15.3 4.54159 14.9584C4.19999 14.6168 4.06074 14.1917 3.99743 13.7209C3.93746 13.2748 3.93748 12.7129 3.9375 12.039L3.9375 7.5C3.9375 7.14285 3.9372 6.90315 3.92453 6.71756C3.91221 6.53701 3.89011 6.4491 3.86614 6.39123C3.77099 6.16152 3.58848 5.97901 3.35877 5.88386C3.3009 5.85989 3.21299 5.83779 3.03244 5.82547ZM13.1629 5.8125H4.83712C4.86177 5.86081 4.8846 5.91025 4.9055 5.96072C4.99571 6.17851 5.0307 6.40315 5.04692 6.64098C5.06251 6.86944 5.06251 7.14771 5.0625 7.48106L5.0625 12C5.0625 12.723 5.06369 13.2087 5.1124 13.571C5.15893 13.9171 5.23935 14.0652 5.33709 14.1629C5.43482 14.2606 5.58295 14.3411 5.92904 14.3876C6.29132 14.4363 6.77699 14.4375 7.5 14.4375H10.5C11.223 14.4375 11.7087 14.4363 12.071 14.3876C12.4171 14.3411 12.5652 14.2606 12.6629 14.1629C12.7607 14.0652 12.8411 13.9171 12.8876 13.571C12.9363 13.2087 12.9375 12.723 12.9375 12V7.48106C12.9375 7.14771 12.9375 6.86944 12.9531 6.64098C12.9693 6.40315 13.0043 6.17851 13.0945 5.96072C13.1154 5.91025 13.1382 5.86081 13.1629 5.8125Z" fill="#1B2124" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.019 1.77507C9.71498 1.71675 9.35759 1.6875 8.99984 1.6875C8.64209 1.6875 8.2847 1.71675 7.98072 1.77507C7.82876 1.80422 7.68389 1.8418 7.55552 1.88983C7.43684 1.93423 7.28873 2.00328 7.16722 2.11666C6.94007 2.32859 6.92774 2.68453 7.13967 2.91168C7.34466 3.13139 7.68439 3.15012 7.91198 2.95932C7.91927 2.95578 7.93153 2.95031 7.94975 2.94349C8.00239 2.9238 8.08267 2.90102 8.19267 2.87992C8.41263 2.83772 8.69719 2.8125 8.99984 2.8125C9.30249 2.8125 9.58705 2.83772 9.80701 2.87992C9.91701 2.90102 9.99729 2.9238 10.0499 2.94349C10.0681 2.95031 10.0804 2.95578 10.0877 2.95932C10.3153 3.15012 10.655 3.13139 10.86 2.91168C11.0719 2.68453 11.0596 2.32859 10.8325 2.11666C10.7109 2.00328 10.5628 1.93423 10.4442 1.88983C10.3158 1.8418 10.1709 1.80422 10.019 1.77507ZM10.0949 2.96301C10.0954 2.96336 10.0957 2.96353 10.0957 2.96352C10.0958 2.96352 10.0955 2.96335 10.0949 2.96301Z" fill="#1B2124" />
  </svg>

);

DustbinIcon.defaultProps = {
  fill: surface,
};

export default DustbinIcon;
