import React, {
  Dispatch, SetStateAction, useRef,
} from 'react';
import styled from 'styled-components';
import { isEmailAlreadyAdded } from '../../../external/Email/emailUtils';
import SearchableAssignMultiInput from '../../../shared/components/searchable-assign/SearchableAssignMultiInput';
import { AssigneeV2 } from '../../../shared/types/types';
import { toastWarning } from '../../../utils/notifications';
import AddRemoveEmailsButton from './AddRemoveEmailsButton';
import { EmailRecipient } from './ShareNotesModal';

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  recipients: EmailRecipient[],
  handleAddEmails: () => void,
  handleRemoveEmails: () => void,
  setEmailRecipients: Dispatch<SetStateAction<EmailRecipient[]>>,
  isChecked: boolean,
  candidates: AssigneeV2[]
  showAddorRemoveButton?: boolean
}

const EmailMultiInput = ({
  recipients, handleAddEmails, handleRemoveEmails,
  setEmailRecipients, candidates, isChecked, showAddorRemoveButton,
}: Props) => {
  const inputRef = useRef<any>(null);

  const onUserSelect = (email: string) => {
    if (email.length === 0) return;

    if (isEmailAlreadyAdded(recipients, email)) {
      toastWarning('Email Added', 'Email-id is already added');
      return;
    }
    const newEmail = {
      index: recipients.length,
      displayValue: email,
    };
    setEmailRecipients((prevRecipients) => [...prevRecipients, newEmail]);
  };

  if (isChecked) {
    return (
      <>
        {showAddorRemoveButton && (
          <FlexContainer>
            <AddRemoveEmailsButton
              emailRecipients={recipients}
              handleAddAllEmails={handleAddEmails}
              handleRemoveEmails={handleRemoveEmails}
            />
          </FlexContainer>
        )}
        <SearchableAssignMultiInput
          values={recipients}
          setValues={setEmailRecipients}
          selectedEmail=""
          onUserSelect={onUserSelect}
          candidates={candidates}
          inputRefValue={inputRef.current?.input?.value ?? ''}
        />
      </>
    );
  }
  return null;
};

export default EmailMultiInput;

EmailMultiInput.defaultProps = {
  showAddorRemoveButton: true,
};
