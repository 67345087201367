import moment from 'moment';
import { DOMOutputSpecArray, NodeSpec } from 'prosemirror-model';

const TimestampDividerSpec: NodeSpec = {
  attrs: {
    time: {
      default: null,
    },
  },
  defining: true,
  inline: true,
  isolating: true,
  content: 'text?',
  atom: true,
  group: 'inline',
  /**
   * Here an empty string of marks is given, since
   * it is not desirable to apply diverse and possibly
   * confusing styles to what should be a static
   * timestamp element.
   */
  marks: '',
  toDOM: (node): DOMOutputSpecArray => [
    'div',
    {
      divtimestamp: node.attrs.time || '',
      class: 'timestamp-divider',
      contenteditable: 'false',
    },
    [
      'span',
      {
        class: 'timestamp-divider-text',
        contenteditable: 'false',
      },
      node.attrs.time
        ? moment(parseInt(node.attrs.time, 10)).format('Do MMM YYYY [|] h:mm a')
        : 0,
    ],
  ],
  parseDOM: [{
    tag: 'div[divtimestamp]',
    getAttrs: (dom) => ({
      timestamp: (dom as Element).getAttribute('divtimestamp') ?? '',
    }),
  }],
};

export default TimestampDividerSpec;
