import React, {
  Dispatch, SetStateAction, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import { PublicUserDataV2 } from '../../types/types';
import SearchableAssignMini from '../searchable-assign/SearchableAssignMini';

export const DropdownContainer = styled.div`
  position: fixed;
  height: auto;
  padding: 8px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  assigneeCandidates: PublicUserDataV2[],
  isOpen: boolean,
  setAssignee: Dispatch<SetStateAction<PublicUserDataV2>>,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
}

const AttendeeDropdown = ({
  assigneeCandidates, isOpen, setAssignee, setIsOpen,
}: Props) => {
  const node = useRef<HTMLDivElement>(null);

  // TODO reuse src\utils\detectOutsideClick.jsx instead
  const handleClick = (e: any) => {
    if (node.current !== null) {
      if (node.current.contains(e.target)) {
        // inside click
        console.log('does contain it');
        return;
      }
      setIsOpen(!isOpen);
      console.log('does not contain it');
      // outside click
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isOpen]);

  const handleSetAssignee = (assignee: PublicUserDataV2) => {
    setAssignee(assignee);
    setIsOpen(false);
    console.log(assignee);
  };

  if (!isOpen) return null;

  return (
    <DropdownContainer ref={node}>
      <SearchableAssignMini
        openByDefault
        selectedEmail=""
        onAssigneeChange={handleSetAssignee}
        candidates={assigneeCandidates}
      />
    </DropdownContainer>
  );
};

export default AttendeeDropdown;
