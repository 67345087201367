import React from 'react';
import { COLORS } from '../colors/COLORS';

interface Props {
  textColour?: string,
  width?: string,
}

const LogoText = ({ textColour, width }: Props) => (
  <svg width={width} viewBox="0 0 222 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M22.6866 3.92445L18.6202 18.0117H16.0518L11.9854 3.92445L13.3765 2.41895H21.2955L22.6866 3.92445Z" fill="#FE5968" />
      <path d="M34.03 16.7214L19.7973 20.2701L18.6201 18.0118L28.6793 7.36572L30.7126 7.79587L34.6721 14.6782L34.03 16.7214Z" fill="#2FDBA1" />
      <path d="M28.6793 33.1744L18.6201 22.5283L19.7973 20.27L34.03 23.8187L34.6721 25.8619L30.7126 32.7442L28.6793 33.1744Z" fill="#585DCD" />
      <path d="M13.3765 38.1211L11.9854 36.6156L16.0518 22.5283H18.6202L22.6866 36.6156L21.2955 38.1211H13.3765Z" fill="#65DEFE" />
      <path d="M0.642078 23.8187L14.8748 20.27L16.0519 22.5283L5.99273 33.1744L3.95948 32.7442L0 25.8619L0.642078 23.8187Z" fill="#FFCB00" />
      <path d="M5.99273 7.36572L16.0519 18.0118L14.8748 20.2701L0.642078 16.7214L0 14.6782L3.95948 7.79587L5.99273 7.36572Z" fill="#058FEF" />
    </g>
    <path d="M53.9216 24.8942C55.5627 25.2616 56.2921 25.8587 56.2465 26.9151C56.2465 28.1552 55.0613 29.0279 53.4657 29.0738C51.4598 29.1197 49.6819 27.8796 49.5451 26.3639H44.3481C44.3937 28.5686 45.2599 30.3139 46.9466 31.6459C48.679 32.9319 50.6848 33.575 52.9186 33.575C55.2892 33.575 57.3407 32.9779 59.0274 31.8296C60.7597 30.6814 61.6259 29.0279 61.6259 26.961C61.6259 25.2616 60.9421 21.8168 56.0642 20.5308L52.5084 19.6581C51.004 19.2447 50.2746 18.6477 50.2746 17.8668C50.2746 16.443 51.1863 15.7541 52.9642 15.7541C54.423 15.7541 55.7907 16.4889 55.8363 17.9587H61.1244C61.0333 15.6622 60.1671 13.9628 58.5715 12.8605C56.9759 11.7581 55.0613 11.207 52.8275 11.207C50.6848 11.207 48.8613 11.85 47.3113 13.136C45.7614 14.3761 44.9864 15.8918 44.9864 17.5913C44.9864 20.1634 45.5334 22.5977 51.004 24.1593L53.9216 24.8942Z" fill={textColour} />
    <path d="M69.8363 21.679C69.8363 18.2343 72.2069 16.6727 74.9422 16.6727C77.3583 16.6727 79.4553 18.5099 79.4553 21.4494V33.1157H85.017V21.1738C85.017 14.8814 81.9171 11.207 76.4465 11.207C75.2157 11.207 73.8936 11.5285 72.526 12.2174C71.204 12.8605 70.2922 13.7791 69.8363 14.9732V0H64.2746V33.1157H69.8363V21.679Z" fill={textColour} />
    <path d="M109.266 22.3221C109.266 19.0151 108.217 16.3511 106.166 14.2843C104.114 12.2174 101.561 11.207 98.4615 11.207C95.3615 11.207 92.763 12.2174 90.6204 14.2843C88.4778 16.3511 87.4293 19.0151 87.4293 22.3221C87.4293 25.629 88.4778 28.3389 90.6204 30.4517C92.763 32.5186 95.3615 33.575 98.4615 33.575C102.701 33.575 106.576 31.8756 108.4 28.3849L104.16 26.0883C103.203 27.8796 101.06 28.8442 98.8262 28.8442C95.6807 28.8442 93.3101 27.0529 92.9454 24.2511H109.175C109.22 23.5622 109.266 22.9192 109.266 22.3221ZM92.8998 20.4389C93.3101 17.6372 95.5439 15.8918 98.5071 15.8918C101.288 15.8918 103.431 17.5453 103.841 20.4389H92.8998Z" fill={textColour} />
    <path d="M111.67 11.6663V43.5418H117.232V30.268C118.326 32.3349 121.015 33.575 123.887 33.575C126.759 33.575 129.221 32.5645 131.227 30.4976C133.278 28.4308 134.281 25.7209 134.281 22.368C134.281 19.107 133.278 16.3971 131.227 14.3302C129.176 12.2634 126.759 11.207 123.887 11.207C121.015 11.207 118.326 12.493 117.232 14.5599V11.6663H111.67ZM117.323 22.4599C117.323 18.9692 119.83 16.5808 122.976 16.5808C124.526 16.5808 125.848 17.132 126.942 18.2343C128.036 19.3366 128.583 20.7604 128.583 22.4599C128.583 25.9046 126.121 28.2471 122.976 28.2471C119.876 28.2471 117.323 25.9965 117.323 22.4599Z" fill={textColour} />
    <path d="M142.554 21.679C142.554 18.2343 144.925 16.6727 147.66 16.6727C150.076 16.6727 152.173 18.5099 152.173 21.4494V33.1157H157.735V21.1738C157.735 14.8814 154.635 11.207 149.164 11.207C147.933 11.207 146.611 11.5285 145.244 12.2174C143.922 12.8605 143.01 13.7791 142.554 14.9732V0H136.992V33.1157H142.554V21.679Z" fill={textColour} />
    <path d="M181.984 22.3221C181.984 19.0151 180.935 16.3511 178.884 14.2843C176.832 12.2174 174.279 11.207 171.179 11.207C168.079 11.207 165.481 12.2174 163.338 14.2843C161.196 16.3511 160.147 19.0151 160.147 22.3221C160.147 25.629 161.196 28.3389 163.338 30.4517C165.481 32.5186 168.079 33.575 171.179 33.575C175.419 33.575 179.294 31.8756 181.117 28.3849L176.878 26.0883C175.92 27.8796 173.778 28.8442 171.544 28.8442C168.398 28.8442 166.028 27.0529 165.663 24.2511H181.892C181.938 23.5622 181.984 22.9192 181.984 22.3221ZM165.618 20.4389C166.028 17.6372 168.262 15.8918 171.225 15.8918C174.006 15.8918 176.148 17.5453 176.559 20.4389H165.618Z" fill={textColour} />
    <path d="M189.949 25.5372C189.949 19.4744 191.727 16.443 195.283 16.443C196.56 16.443 197.654 16.6267 198.52 17.0401L199.477 11.9878C198.52 11.4825 197.335 11.207 195.967 11.207C193.368 11.207 190.998 13.0442 189.949 16.7186V11.6663H184.388V33.1157H189.949V25.5372Z" fill={textColour} />
    <path d="M221.741 33.1157V0H216.225V14.5139C215.085 12.4471 212.441 11.207 209.523 11.207C206.651 11.207 204.189 12.2634 202.138 14.3302C200.087 16.3971 199.084 19.061 199.084 22.4139C199.084 25.7209 200.087 28.4308 202.138 30.4976C204.189 32.5645 206.651 33.575 209.523 33.575C212.395 33.575 215.085 32.2889 216.225 30.2221V33.1157H221.741ZM210.435 16.5349C211.939 16.5349 213.261 17.086 214.401 18.1424C215.541 19.1988 216.133 20.5767 216.133 22.3221C216.133 24.0674 215.586 25.4913 214.447 26.5936C213.353 27.65 212.031 28.2011 210.435 28.2011C208.839 28.2011 207.517 27.65 206.423 26.5936C205.375 25.4913 204.828 24.0674 204.828 22.3221C204.828 18.8773 207.289 16.5349 210.435 16.5349Z" fill={textColour} />
  </svg>
);

LogoText.defaultProps = {
  textColour: COLORS.gray1,
  width: '222',
};

export default LogoText;
