/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import {
  findCellClosestToPos, findTable, selectColumn, selectRow,
} from '@meetshepherd/prosemirror-utils';
import { EditorView } from 'prosemirror-view';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { selectionCell } from '@meetshepherd/prosemirror-tables';
import { gray1, gray2, gray4 } from '../../../../colors/COLORS';
import ThreeDotsIcon from '../../../../icons/text-editor/ThreeDotsIcon';
import CustomMenuItem, { MainIconContainer } from '../menu-item';
import {
  TableRowActions, TableColActions, TableSelectionActions, ColorPickerTableAction as pickerAction,
} from '../../logic/menu/items/table';
import ColorsDropdownOrg from '../../../text-editor/dropdowns/ColorsDropdownOrg';
import tableControlsAdapter from '../../logic/adapters/table-controls-adapter';
import { offsetBoundByproduct } from '../../logic/computation/offsets';

export interface TableControlsProps {
  show: boolean;
  contextMenu: boolean;
  tableRect: DOMRect | null;
  cellRect: DOMRect | null;
  view: EditorView;
}

export interface TableControlsInitState {
  show?: boolean; // because sometimes this is redundant (ie: open & close calls)
  contextMenu?: boolean;
  tableRect: DOMRect | null;
  cellRect: DOMRect | null;
}

interface ControlContainerProps {
  tableRect: DOMRect | null;
}

const ControlsContainer = styled.div<ControlContainerProps>`
  position: absolute;
  ${({ tableRect: r }) => (r ? `left:${r.x}px;top:${r.y}px;` : 'display:none;pointer-events: none;')}
`;

interface ControlIconProps {
  rect: DOMRect | null;
  rotate: boolean;
  active: boolean;
}

const ControlIcon = styled.div<ControlIconProps>`
  position: absolute;
  ${({ rect: r }) => (r ? `left:${r.x}px;top:${r.y}px;` : '')}
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 13px;
  height: 18px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ active }) => (active ? '#058FEF' : '#B7BECF')};
  ${({ rotate }) => (rotate ? 'transform: rotate(90deg);' : '')}
  transition: left 300ms ease-in-out, top 300ms ease-in-out;
  z-index: 21;
`;

const ContextMenu = styled.div<ControlIconProps>`
  position: absolute;
  ${({ rect: r }) => (r ? `left:${r.x}px;top:${r.y}px;` : '')}
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  z-index: 21;
`;

interface MenuDropdownProps {
  visible: boolean;
}

const MenuDropdown = styled.div<MenuDropdownProps>`
  ${({ visible }) => (visible ? 'display: flex;' : 'display: none;')}
  position: absolute;
  flex-flow: column;
  min-width: 202px;
  background-color: ${gray1};
  padding: 8px;
  border-radius: 10px;
  font-family: Inter, sans-serif;
`;

const DropdownItem = styled.div`
  padding: 6px 8px;
  border-radius: 6px;
  scroll-margin: 8px;
  display: flex;
  flex-flow: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: ${gray2};
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

const LinkPreview = ({
  show,
  contextMenu,
  tableRect,
  cellRect,
  view,
}: TableControlsProps) => {
  // the padding that affects the table
  const tablePadding = 16;
  // table bounding regions without padding (used to position controls)
  const unpaddedTableRect = tableRect ? new DOMRect(
    tableRect.x - tablePadding,
    tableRect.y - tablePadding,
    tableRect.width,
    tableRect.height,
  ) : null;

  // offset from table to cell (used to calculate top & left css props)
  const relCellRect = (tableRect && cellRect) ? new DOMRect(
    cellRect.x - tableRect.x,
    tableRect.y - cellRect.y,
    cellRect.width,
    cellRect.height,
  ) : null;

  // Calculate bounding boxes relative to unpaddedTableRect
  const rowControlRect = (unpaddedTableRect && relCellRect) ? new DOMRect(
    0,
    -relCellRect.y + relCellRect.height / 2 + (18 / 2),
    13,
    18,
  ) : null;

  const colControlRect = (unpaddedTableRect && relCellRect) ? new DOMRect(
    relCellRect.x + relCellRect.width / 2 + (13 / 2),
    -4,
    18, // rotated 90deg
    13, // width & height swap
  ) : null;

  const contextMenuElement = useRef<HTMLDivElement>(null);

  const [rowActive, setRowActive] = useState(false);

  const [colActive, setColActive] = useState(false);

  const unactivateControls = () => {
    setRowActive(false);
    setColActive(false);
  };

  const [boundedContextMenuRect, setBoundedContextMenuRect] = useState<DOMRect | null>(null);
  useEffect(() => {
    // this is the rect relative to the whole page
    const contextMenuCurrentRect = contextMenuElement.current?.getBoundingClientRect();
    if (contextMenuCurrentRect && rowControlRect && colControlRect && view && view.dom) {
      // editor bounds relative to the whole page
      const editorBounds = view.dom.parentElement?.getBoundingClientRect()
        ?? view.dom.getBoundingClientRect();
      const offset = offsetBoundByproduct(contextMenuCurrentRect, editorBounds);
      // this is the rect relative to the table, with correct offset applied.
      //* the offset does not care what the coordinate space is
      //  the origin point has to be the same for this to work
      //  in this case, the origin point is viewport (0,0) when calculating offsets
      //  but the offset is applied with respect to a relative table
      // Thus we convert from viewport to table coordinate system
      //  in which (0,0) is the upper left table corner.
      setBoundedContextMenuRect(new DOMRect(
        colControlRect.x - (contextMenuCurrentRect.width / 8) * 3,
        rowControlRect.y + offset.top,
        contextMenuCurrentRect.width, // irrelevant
        contextMenuCurrentRect.height,
      ));
    } else {
      setBoundedContextMenuRect(null);
    }
  }, [contextMenu, contextMenuElement, show, tableRect, cellRect]);

  useEffect(() => {
    unactivateControls();
  }, [tableRect, cellRect, show]);

  const selectRows = () => {
    const table = findTable(view.state.selection);
    if (table) {
      // const rowCount = table.node.childCount;
      // const colCount = table.node.firstChild!.childCount;

      const currentCell = findCellClosestToPos(view.state.selection.$anchor);
      if (currentCell) {
        const { rowIndex } = view.domAtPos(currentCell.pos).node as HTMLTableRowElement;
        // const { cellIndex } = view.domAtPos(currentCell.start).node as HTMLTableCellElement;

        view.dispatch(selectRow(rowIndex)(view.state.tr));
      }
    }
  };

  const selectCols = () => {
    const table = findTable(view.state.selection);
    if (table) {
      // const rowCount = table.node.childCount;
      // const colCount = table.node.firstChild!.childCount;

      const currentCell = findCellClosestToPos(view.state.selection.$anchor);
      if (currentCell) {
        // const { rowIndex } = view.domAtPos(currentCell.pos).node as HTMLTableRowElement;
        const { cellIndex } = view.domAtPos(currentCell.start).node as HTMLTableCellElement;

        view.dispatch(selectColumn(cellIndex)(view.state.tr));
      }
    }
  };

  const disableCTXMenu = (e: any) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    } catch (err) {
      console.debug('Event doesn\'t need prevention!');
    }
  };

  if (!show || !colControlRect || !rowControlRect) {
    return (
      <ControlsContainer
        tableRect={null}
        onContextMenu={disableCTXMenu}
        onContextMenuCapture={disableCTXMenu}
      />
    );
  }

  const simpleDropdownUnwrap = (actions: Record<string, any>[]) => {
    return actions.map((value, i) => {
      return (
        <DropdownItem
          key={`table-control-${i.toString()}`}
          onMouseDown={(e) => {
            if (!view) return;
            value.callback(view.state, view.dispatch);
            unactivateControls();
            tableControlsAdapter.closeContextMenu();
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {(value.icon
            && (
              <MainIconContainer
                style={{
                  marginRight: '8px',
                }}
              >
                {value.icon}
              </MainIconContainer>
            )
          )}
          {value.labelColor ? (
            <span style={{ color: value.labelColor }}>{value.label}</span>
          ) : value.label}
        </DropdownItem>
      );
    });
  };

  return (
    <ControlsContainer
      tableRect={unpaddedTableRect}
      onContextMenu={disableCTXMenu}
      onContextMenuCapture={disableCTXMenu}
    >
      <ControlIcon
        rect={rowControlRect}
        rotate={false}
        active={rowActive}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.button === 0) {
            setRowActive(true);
            setColActive(false);
            selectRows();
          }
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setRowActive(true);
          setColActive(false);
        }}
      >
        <ThreeDotsIcon fill="#FFF" />
        <MenuDropdown
          visible={rowActive && !colActive}
          style={{
            transform: 'translate(16px, 4px)',
          }}
        >
          {simpleDropdownUnwrap(TableRowActions)}
        </MenuDropdown>
      </ControlIcon>
      <ControlIcon
        rect={colControlRect}
        rotate
        active={colActive}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.button === 0) {
            setColActive(true);
            setRowActive(false);
            selectCols();
          }
        }}
      >
        <ThreeDotsIcon fill="#FFF" />
        <MenuDropdown
          visible={colActive && !rowActive}
          style={{
            transform: 'translate(0, -50%) rotate(-90deg) translate(0, 16px)',
          }}
        >
          {simpleDropdownUnwrap(TableColActions)}
        </MenuDropdown>
      </ControlIcon>
      <ContextMenu
        rect={boundedContextMenuRect}
        rotate={false}
        active={false}
        style={{
          display: contextMenu ? 'inherit' : 'none',
          pointerEvents: contextMenu ? 'all' : 'none',
        }}
        onContextMenu={disableCTXMenu}
        onContextMenuCapture={disableCTXMenu}
      >
        <MenuDropdown
          ref={contextMenuElement}
          visible
        >
          <CustomMenuItem
            closeOnClickAway
            hideChevron
            list
            listAlign="left"
            adapter="tableControl"
            listContents={(secondaryScope) => (
              <ColorsDropdownOrg
                selectedColor={(() => {
                  const color = '#FFFFFF';
                  const state = view?.state;
                  if (!state) return color;
                  const tableCellNodePosition = selectionCell(state)?.pos;
                  if (!tableCellNodePosition) return color;
                  const tableNode = view?.state.doc.nodeAt(tableCellNodePosition);
                  return tableNode?.attrs?.background ?? '#FFFFFF';
                })()}
                setSelectedColor={(color) => {
                  secondaryScope.closeContents();
                  tableControlsAdapter.closeContextMenu();
                  if (!view) return false;
                  return pickerAction.callback(color, view.state, view.dispatch);
                }}
                handleResetClick={() => {
                  secondaryScope.closeContents();
                  tableControlsAdapter.closeContextMenu();
                  if (!view) return false;
                  return pickerAction.callback(null, view.state, view.dispatch);
                }}
              />
            )}
            Container={DropdownItem}
            contents={() => (
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                }}
              >
                {(pickerAction.icon
              && (
                <MainIconContainer
                  style={{
                    marginRight: '8px',
                  }}
                >
                  {pickerAction.icon}
                </MainIconContainer>
              )
            )}
                {pickerAction.label}
              </div>
            )}
          />
          {simpleDropdownUnwrap(TableSelectionActions)}
        </MenuDropdown>
      </ContextMenu>
    </ControlsContainer>
  );
};

export default LinkPreview;
