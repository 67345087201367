/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { blue6, darkBlue1, gray1 } from '../../colors/COLORS';

const Input = styled.input<InputProps>`
  /* -webkit-appearance: none; */
  width: 100%;
  height: ${({ isLinkInput }) => (isLinkInput ? '44px' : '40px')};
  padding: 10px 0px 10px 15px;
  background-color: ${gray1};
  border: 2px solid ${darkBlue1};
  box-sizing: border-box;
  border-radius: 8px;

  :focus-visible {
    outline: inherit;
  }

  :focus {
    border: 2px solid ${blue6};
  }
`;

interface InputProps {
  isLinkInput: boolean,
}

interface Props {
  value: string,
  placeholder: string;
  onEnterKeyPress: () => void;
  // eslint-disable-next-line no-unused-vars
  onChange: (text: string) => void;
  isLinkInput?: boolean;
}

const TextInputAtom = ({
  value, placeholder, onEnterKeyPress, onChange, isLinkInput = false,
}: Props) => {
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      onEnterKeyPress();
      console.log("You've pressed the enter key!");
    }
  };

  const handleChange = (event: any) => {
    onChange(event.target.value);
  };

  return (
    <Input
      value={value}
      isLinkInput={isLinkInput}
      placeholder={placeholder}
      onKeyPress={handleKeyPress}
      onChange={handleChange}
      autoFocus
    />
  );
};

TextInputAtom.defaultProps = {
  isLinkInput: false,
};

export default TextInputAtom;
