import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom-v5-compat';
import { useIntercom } from 'react-use-intercom';
import { AuthContext } from '../../App';
import { dbFindAndNavigateToMeetingByMeetId } from '../../database/Meetings/firebaseMeetingAPI';
import SpinnerLoadingPage from '../../shared/components/loading/spinner-loading-page';
import { logEventPageViewGoogleMeet } from '../../utils/analytics/eventLogger';
import GoogleMeetErrorPage from './GoogleMeetErrorPage';

const GoogleMeet = () => {
  const [error, setError] = useState<boolean>(false);
  const authState = useContext(AuthContext);
  const { update } = useIntercom();
  const navigate = useNavigate();
  const params = useParams();

  const googleMeetId: string = params?.googleMeetId ?? '';

  useEffect(() => {
    logEventPageViewGoogleMeet(authState, update);
  }, []);

  useEffect(() => {
    if (googleMeetId.length > 9) {
      dbFindAndNavigateToMeetingByMeetId(
        googleMeetId, authState.userId, navigate, setError, authState,
      );
    } else {
      setError(true);
    }
  }, [googleMeetId]);

  const handleRetry = () => {
    setError(false);
    dbFindAndNavigateToMeetingByMeetId(
      googleMeetId, authState.userId, navigate, setError, authState,
    );
  };

  if (error) {
    return <GoogleMeetErrorPage googleMeetId={googleMeetId} retry={handleRetry} />;
  }

  return (
    <SpinnerLoadingPage />
  );
};

export default GoogleMeet;
