/**
 * @class UserId
 * @description
 * This class is used to validate the user id.
 *
 */
class UserId {
  value: string;

  /**
   * @class UserId
   * @description
   * This class is used to validate the user id.
   *
   */
  constructor(userId: string) {
    this.value = userId;
    if (this.isNotValid()) {
      this.consoleLog('Invalid ');
    }
  }

  /** Need to more than 10 characters to be valid */
  isValid(): boolean {
    return this.value.length >= 10;
  }

  /** Need to be less than 11 characters to be not valid */
  isNotValid(): boolean {
    return !this.isValid();
  }

  /** Print out the user id to console */
  consoleLog(optionalText?: string): void {
    const extraText = optionalText ? `${optionalText}, ` : '';
    console.log(`${extraText}UserId: '${this.value}'. Length: ${this.value.length}`);
  }
}

export default UserId;

UserId.prototype.toString = function userIdToString() {
  return this.value;
};

UserId.prototype.valueOf = function userIdValueOf() {
  return this.value;
};
