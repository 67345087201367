import React from 'react';
import CssSpinner from '../../../../../shared/components/css-spinner';
import ButtonStyle from './utils/styles';

export interface OnboardingSubmitButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  isDisabled?: boolean,
  text: string,
  type?: 'submit' | 'reset' | 'button',
  hasIcon?: boolean,
  Icon?: any,
  loading?: boolean,
}

const OnboardingSubmitButton = ({
  onClick, isDisabled, text, type, Icon, hasIcon, loading,
}: OnboardingSubmitButtonProps) => (
  <ButtonStyle
    onClick={onClick}
    disabled={isDisabled || loading}
    type={type}
    id="onboarding"
  >
    {loading ? (
      <CssSpinner />
    ) : (
      <>
        {hasIcon && <Icon />}
        {text}
      </>
    )}
  </ButtonStyle>
);

OnboardingSubmitButton.defaultProps = {
  onClick: undefined,
  type: 'button',
  isDisabled: false,
  Icon: undefined,
  hasIcon: false,
  loading: false,
};

export default OnboardingSubmitButton;
