import React from 'react';
import TextBox from '../../../../../shared/components/textbox';

interface TextBoxProps {
  // eslint-disable-next-line no-unused-vars
  setCustomMessage: (message: string) => void,
  customMessage: string,
}

export default function CustomMessage({ setCustomMessage, customMessage }: TextBoxProps) {
  return (
    <TextBox
      placeholder="Write here to add additional notes"
      onChange={setCustomMessage}
      defaultValue={customMessage}
    />
  );
}
