import { REJECTED, RESOLVED } from '../../utils/enums';
import { ResolvedState } from '../types/types';

class SmartReturnData<Type> {
  value: Type;

  returnStatus: ResolvedState;

  error: Error | null;

  constructor(value: Type, returnStatus: ResolvedState, error: Error | null | string = null) {
    this.value = value;
    this.returnStatus = returnStatus;
    if (typeof error === 'string') {
      this.error = new Error(error);
      return;
    }

    this.error = error;
  }

  get isResolved(): boolean {
    return this.returnStatus === RESOLVED;
  }

  get isRejected(): boolean {
    return this.returnStatus === REJECTED;
  }

  get isPending(): boolean {
    return this.returnStatus === 'pending';
  }

  get isError(): boolean {
    return this.error !== null;
  }

  /**
   * Be careful using this function, as the value is set to null, so if you try to do
   * anything with it you will get run-time errors.
   */
  static rejected(error: Error | null | string = null): SmartReturnData<any> {
    return new SmartReturnData({}, REJECTED, error);
  }

  static resolved(): SmartReturnData<any> {
    return new SmartReturnData({}, RESOLVED);
  }
}

export default SmartReturnData;
