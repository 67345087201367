import React from 'react';
import styled from 'styled-components';
import { uiTextMedium } from '../../../../../shared/typography';

const Email = styled.p<TextProps>`
  ${uiTextMedium}
  color: ${(props: TextProps) => props.color};
`;

interface TextProps {
  color: string,
}

interface Props {
  email: string,
  color: string,
}

const EmailAtom = ({ email, color }: Props) => (
  <Email color={color}>
    {email}
  </Email>
);

export default EmailAtom;
