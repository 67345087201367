/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import WorkspaceData from '../../database/Workspaces/WorkspaceData';
import Font from '../../shared/typography/Font';
import DeleteIcon24x24 from '../../shared/icons/DeleteIcon24x24';
import { COLORS } from '../../shared/colors/COLORS';
import CheckboxIcon from '../../shared/icons/text-editor/CheckboxIcon';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  width: 100%;

  & > *:first-child {
    margin-right: 8px;
  }
`;

const RightContainer = styled.div`
  display: flex;
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    background-color: ${COLORS.surfaceLight};
    border-radius: 4px;
  }
`;

const SetToSelectedWorkspaceButtonContainer = styled(DeleteButtonContainer)`
`;

interface WorkspaceItemProps {
  workspace: WorkspaceData,
  // eslint-disable-next-line no-unused-vars
  onClickDelete: (workspaceId: string) => void, onSelectWorkspace: (workspaceId: string) => void,

}

const WorkspaceItem = ({ workspace, onClickDelete, onSelectWorkspace }: WorkspaceItemProps) => {
  return (
    <Container>
      {/* Title font */}
      <Font font="defaultText">{workspace.workspaceId}</Font>
      <Font font="defaultText">{workspace.name}</Font>
      <RightContainer>
        <SetToSelectedWorkspaceButtonContainer
          onClick={() => onSelectWorkspace(workspace.workspaceId)}
        >
          <CheckboxIcon fill="#1B2124" />
        </SetToSelectedWorkspaceButtonContainer>
        <DeleteButtonContainer onClick={() => onClickDelete(workspace.workspaceId)}>
          <DeleteIcon24x24 fill="black" />
        </DeleteButtonContainer>
      </RightContainer>
    </Container>
  );
};

export default WorkspaceItem;
