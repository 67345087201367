import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface MinusProps {
  fill?: string;
  width?: number;
  height?: number;
}

const MinusIcon = ({
  fill,
  width,
  height,
}: MinusProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 5.41663,12.1876 c 0,-0.5983 0.48502,-1.0834 1.08333,-1.0834 H 17.875 c 0.5983,0 1.0833,0.4851 1.0833,1.0834 0,0.5983 -0.485,1.0833 -1.0833,1.0833 H 6.49996 c -0.59831,0 -1.08333,-0.485 -1.08333,-1.0833 z"
      fill={fill}
    />
  </svg>
);

MinusIcon.defaultProps = {
  fill: darkBlue4,
  width: 24,
  height: 24,
};

export default MinusIcon;
