import React from 'react';
import styled from 'styled-components';
import { surface } from '../../colors/COLORS';
import { header400 } from '../../typography';

const Text = styled.span`
  ${header400};
  color: ${surface};
`;

interface Props {
  text: string,
}

const SubHeaderAtom = ({ text }: Props) => (
  <Text>
    {text}
  </Text>
);

export default SubHeaderAtom;
