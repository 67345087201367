import React from 'react';
import styled from 'styled-components';
import {
  header600, uiTextS,
} from '../../typography';
import ImageSource from '../../img/relax.png';
import ButtonSmall from '../button-small';
import { useUI } from '../../../pages/all-pages/ui-context/uiContext';

const Container = styled.div`
  width: 100%;
  height: 278px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h1`
  ${header600};
  margin-bottom: 12px;
`;

const SubHeader = styled.h3`
  ${uiTextS};
  margin-bottom: 32px;
`;

const Image = styled.img`
  margin-top:100px;
  height: 150px;
`;

const EmptyNotificationContent = () => {
  const {
    openInviteMemberModal, openUpdatesHubModal,
    closeInviteMemberModal, closeUpdatesHubModal,
    enableBackButtonInviteMemberModal, onBackButtonClickInviteMemberModal,
  } = useUI();

  const onBackButtonClick = () => {
    closeInviteMemberModal();
    openUpdatesHubModal();
  };

  const onClick = () => {
    enableBackButtonInviteMemberModal(true);
    onBackButtonClickInviteMemberModal(onBackButtonClick);
    closeUpdatesHubModal();
    openInviteMemberModal();
  };

  return (
    <Container>
      <Image src={ImageSource} />
      <Header>No updates available</Header>
      <SubHeader>You’re on top of things. Sit back and relax!</SubHeader>
      <ButtonSmall onClick={onClick} text="Invite a colleague to join Shepherd" />
    </Container>
  );
};

export default EmptyNotificationContent;
