/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ReactNotifications } from 'react-notifications-component';
import { COLORS } from '../../shared/colors/COLORS';
import LineAtom from '../../shared/components/new-feaures-modal/LineAtom';
import RockHandAtom from '../dashboard-welcome-page/components/RockHandAtom';
import { UserDataContext } from '../../App';
import BREAKPOINTS from '../../shared/CSS/CSS_Constants';
import { header900, header800, header600 } from '../../shared/typography';
import SendIcon from '../../shared/icons/SendIcon';
import Font from '../../shared/typography/Font';
import UserAPI from '../../database/User/UserAPI';
import { toastSuccess } from '../../utils/notifications';
import SlackBoltAPI from '../../external/SlackBolt/SlackBoltAPI';
import Logger from '../../utils/analytics/Logger/Logger';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background: ${COLORS.layoutWhite};
  padding: 16px;
  padding-bottom: 75px;
`;

const HandContainer = styled.div`
  height: 40%;
  padding-bottom: 40px;
  
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  transform-origin: bottom center;
  transform: scale(0.8);

  @media (max-height: 880px) {
    height: 100%;
  }
`;

const WelcomeBackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: -webkit-fill-available;

  height: 100%;
  @media(max-height: 880px) {
    height: 100%;
  }
`;

const LineContainer = styled.div`
width: 440px;

@media(max-height: 880px) {
  display: none;
}
`;

const Title = styled.span`
  ${header900}
  @media only screen and (max-width: ${BREAKPOINTS.sm}){
    ${header800}
  }
`;

const Text = styled.span`
  ${header600}
  text-align: center;
  white-space: pre-wrap;
`;

const ExportNotesPage = () => {
  const userData = useContext(UserDataContext);
  const navigate = useNavigate();

  const handleClickExportNow = async () => {
    // eslint-disable-next-line no-alert
    const result = window.confirm('Are you sure you want to export your notes?');
    console.log(result);
    if (result) {
      // eslint-disable-next-line no-alert
      const response = await UserAPI.General
        .dbUpdateUserWithRequestedToExportNotes(userData.userId);
      console.log(response);
      toastSuccess('Success', 'Your notes are being processed and will be sent to your email.');
      SlackBoltAPI.logMessage(`User ${userData.name} (${userData.email}) requested to export notes. UserId: ${userData.userId}`);
      Logger.logEvent(userData.userId, 'ExportNotesRequested', { email: userData.email });
    }
  };

  const handleClickGotIt = async () => {
    navigate('/');
  };

  const Button = ({ children, onClick }: any) => (
    <div
      className="flex w-fit bg-black items-center justify-center px-4 py-2 rounded-lg transition-all hover:rounded-sm cursor-pointer hover:bg-gray-500 select-none"
      onClick={onClick}
    >
      {children}

    </div>
  );

  if (userData.resolvedState === 'pending') return null;

  return (
    <div className="bg-gray-400 h-screen flex flex-col items-center">
      <ReactNotifications />
      <Container>
        <div className="flex flex-col w-full h-full max-w-[900px] items-center justify-center">
          <HandContainer>
            <RockHandAtom />
          </HandContainer>
          <WelcomeBackContainer>
            <Title>
              Export your notes
            </Title>
            {userData.data.exportNotes ? (
              <>
                <div className="flex items-center justify-center gap-1 flex-col text-center">
                  <Font font="uiTextL">
                    Your notes will now be sent to the email address linked to your account.
                  </Font>
                  <br />
                  <Font font="uiTextL">
                    Please note that this might take up to 14 business days.
                  </Font>
                </div>
                <LineContainer><LineAtom /></LineContainer>
                <Button onClick={handleClickGotIt}>
                  <div className="mr-2">
                    <SendIcon />
                  </div>
                  <Font font="messageText" color="gray1">Got it</Font>
                </Button>
              </>
            ) : (
              <>
                <div className="flex items-center justify-center gap-1 flex-col">
                  <Font font="uiTextL">
                    You can request to have your notes emailed to you via a CSV file.
                  </Font>
                  <br />
                  <Font font="uiTextL">
                    If you wish to do so, please click the button below 👇
                  </Font>
                </div>
                <LineContainer><LineAtom /></LineContainer>
                <Button onClick={handleClickExportNow}>
                  <div className="mr-2">
                    <SendIcon />
                  </div>
                  <Font font="messageText" color="gray1">Export now</Font>
                </Button>
              </>
            )}
          </WelcomeBackContainer>
        </div>
      </Container>
    </div>
  );
};

export default ExportNotesPage;
