import moment from 'moment';
import SDate from '../../shared/classes/SDate';
import { SDateT } from '../../shared/types/types';
import { StripeConstants } from './StripeConstants';
import StripeSubscriptionUtils from './StripeSubscriptionUtils';
import {
  BillingMeetingUsed, BillingStatus, IBillingUserData, StripeSubscription,
} from './StripeTypes';

class BillingUserData implements IBillingUserData {
  stripeCustomerIdSet: boolean;

  stripeCustomerId: string;

  status: BillingStatus;

  activePlans: string[];

  created: SDateT;

  trialStart: SDateT;

  trialEnd: SDateT;

  meetingsUsed: BillingMeetingUsed[];

  subscription: StripeSubscription;

  isPaidTrialStarted: boolean;

  hasSeenInvitePromptToPremiumWorkspace: number;

  /**
   * Some users should be on premium, even though their subscription data doesn't
   * say so, since their company bought premium for them.
   * */
  userId: string;

  isBillingInitialized(): boolean {
    return this.status !== 'not_initialized' || this.isOnPremiumPlan();
  }

  isOnPremiumPlan(): boolean {
    if (StripeConstants.PREMIUM_USER_IDS.some((id) => id === this.userId)) return true;

    return this.subscription.status === 'active'
      || this.subscription.status === 'incomplete'
      || this.subscription.status === 'trialing';
  }

  isOnTrial(): boolean {
    return this.status === 'trialing' && !this.isOnPremiumPlan();
  }

  isOnFreePlan(): boolean {
    return !this.isOnTrial() && !this.isOnPremiumPlan() && this.isBillingInitialized();
  }

  /**
   * User has to be in a trialing state and the trial has to be over
   */
  hasTrialEnded = (): boolean => {
    if (!this.isOnTrial()) return false;
    return SDate.isDateInPast(this.trialEnd.date);
  }

  value = (): IBillingUserData => ({
    stripeCustomerIdSet: this.stripeCustomerIdSet,
    stripeCustomerId: this.stripeCustomerId,
    status: this.status,
    activePlans: this.activePlans,
    created: this.created,
    trialStart: this.trialStart,
    trialEnd: this.trialEnd,
    meetingsUsed: this.meetingsUsed,
    subscription: this.subscription,
    isPaidTrialStarted: this.isPaidTrialStarted,
    hasSeenInvitePromptToPremiumWorkspace: this.hasSeenInvitePromptToPremiumWorkspace,
  });

  isMeetingInMeetingsUsed = (meetingId: string): boolean => this.meetingsUsed
    .some((meetingUsed) => meetingUsed.meetingId === meetingId);

  private hasUsedXOrMoreMeetingsLastXDays = (x: number, days: number): boolean => {
    const now = moment();
    const xDaysAgo = moment().subtract(days, 'days');
    const meetingsUsedLastXDays = this.meetingsUsed.filter((meeting) => {
      const meetingDate = moment(meeting.start);
      return meetingDate.isBetween(xDaysAgo, now);
    });
    return meetingsUsedLastXDays.length >= x;
  }

  hasUsed10OrMoreMeetingsLast30Days = (): boolean => this
    .hasUsedXOrMoreMeetingsLastXDays(StripeConstants.FREE_PLAN.MAX_MEETINGS, 30);

  constructor(databaseBillingData: any, userId: string) {
    this.stripeCustomerIdSet = (databaseBillingData?.stripeCustomerId?.length > 0 ? true : (
      databaseBillingData?.stripeCustomerIdSet ?? false));
    this.stripeCustomerId = databaseBillingData?.stripeCustomerId ?? '';
    this.status = databaseBillingData?.status ?? 'not_initialized';
    this.activePlans = databaseBillingData?.activePlans ?? [];
    this.created = databaseBillingData?.created ?? SDate.currentISOObject();
    this.trialStart = databaseBillingData?.trialStart ?? SDate.currentISOObject();
    this.trialEnd = databaseBillingData?.trialEnd
      ?? new SDate(SDate.getDateXWeeksInFuture(2)).value;
    this.meetingsUsed = databaseBillingData?.meetingsUsed ?? [];
    this.isPaidTrialStarted = databaseBillingData?.isPaidTrialStarted ?? false;
    this.subscription = StripeSubscriptionUtils
      .mapDatabaseToValue(databaseBillingData?.subscription);
    this.userId = userId;
    this.hasSeenInvitePromptToPremiumWorkspace = databaseBillingData
      ?.hasSeenInvitePromptToPremiumWorkspace ?? 0;
  }

  static createDefaultBillingData(): BillingUserData {
    return new BillingUserData({}, '');
  }
}

export default BillingUserData;
