import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../App';
import { dbSavePrivateNote, dbUpdatePrivateNotePath } from '../../../../database/firebasePrivateNotesAPI';
import ButtonSmall from '../../../../shared/components/button-small';
import Modal from '../../../../shared/components/Modal';
import { SingleButtonModalFooterdWrapper } from '../../../../shared/components/Modal/ModalFooterWrapper';
import SearchableAssign from '../../../../shared/components/searchable-assign';
import { AssigneeV2, SDateT, PrivateNoteAttendeeV2 } from '../../../../shared/types/types';
import { dateISOObject } from '../../../../utils/dateUtils/date';
import { mapAssigneeV2ToSecretChatAttendeeV2, mapAttendeeV2ToPrivateNoteAttendeeV2, mapPrivateNoteAttendeeV2ToAttendeeV2 } from '../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import MeetingDataContext from '../../context/MeetingDataContext';
import { ModalLineLocal } from '../../modal/ShareNotesModal';
import NameInputAtom from '../add-members-modal/NameInputAtom';
import AccessAtom from './components/AccessAtom';
import ExplanationTextAtom from './components/ExplanationAtom';
import IconEmailMolecules from './components/IconEmailMolecules';
import { createSecretChatObject } from './components/secretModalUtils';
import {
  validateAttendeesV2HaveValidUserId,
  filterCurrentUserFromAttendees,
  checkIfUserIsAlreadyInvitedToPrivateNote,
  removeMemberFromPrivateMemberByEmail,
  mapPublicUserDataV2ToAttendeeV2,
} from './utils/functions';

const Container = styled.div`
  padding: 24px 24px 8px 24px;
  span:first-child {
    margin-bottom: 18px;
  }
  span:nth-child(1) {
    margin-bottom: 16px;
  }

  span:nth-child(3) {
    margin-bottom: 8px;
  }
`;

export const NameInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  :first-child {
    margin-bottom: 8px;
  }

  input {
    margin-bottom: 20px;
  }
`;

interface Props {
  isPrivateNotesModalOpen: boolean
  onClickClosePrivateNotesModal: () => void
  /* eslint-disable-next-line */
  onClickSelectPrivateNote: (chatId: string) => void
}

const CreatePrivateNotesModal = ({
  isPrivateNotesModalOpen,
  onClickClosePrivateNotesModal,
  onClickSelectPrivateNote,
}: Props) => {
  const meetingData = useContext(MeetingDataContext);
  const authData = useContext(AuthContext);
  const userData = useContext(UserDataContext);

  const [privateNoteTitle, setPrivateNoteTitle] = useState<string>('');
  const [privateNoteMembers, setPrivateNoteMembers] = useState<PrivateNoteAttendeeV2[]>([]);

  const userAttendeeV2Data = mapPublicUserDataV2ToAttendeeV2(userData.publicUserData);

  const meetingAttendees = validateAttendeesV2HaveValidUserId(meetingData.attendees.attendees);
  // TODO Matt: Improve naming. This checks if the user is in the attendees list?
  // but why?
  const filteredMeetingAttendees = filterCurrentUserFromAttendees(meetingAttendees, authData);

  useEffect(() => {
    if (checkIfUserIsAlreadyInvitedToPrivateNote(privateNoteMembers, authData.email)) return;
    if (userAttendeeV2Data.resolvedState === 'resolved' && userAttendeeV2Data.userId.length !== 0) {
      const convertedCurrentUser = mapAttendeeV2ToPrivateNoteAttendeeV2(userAttendeeV2Data);
      setPrivateNoteMembers((prev) => [...prev, convertedCurrentUser]);
    }
  }, [isPrivateNotesModalOpen]);

  const onClickAddAssigneeToPrivateNote = (assignee: AssigneeV2) => {
    if (assignee.userId.length === 0) return;

    const newPrivateNoteMember = mapAssigneeV2ToSecretChatAttendeeV2(assignee);
    if (checkIfUserIsAlreadyInvitedToPrivateNote(
      privateNoteMembers,
      newPrivateNoteMember.data.email,
    )) return;

    setPrivateNoteMembers((prev) => [...prev, newPrivateNoteMember]);
  };

  const onClickRemoveEmailFromPrivateNoteMembers = (email: string) => {
    setPrivateNoteMembers((prev) => removeMemberFromPrivateMemberByEmail(prev, email));
  };

  const onClickCreatePrivateNote = () => {
    const created: SDateT = dateISOObject();
    const userIds = privateNoteMembers.map((member) => member.userId);

    const privateNote = createSecretChatObject(
      privateNoteTitle,
      '',
      privateNoteMembers,
      meetingData.meetingId,
      userIds,
      created,
      created,
      userData.publicUserData,
    );
    dbSavePrivateNote(privateNote, meetingData.meetingId, onCreatePrivateNoteSaveNotePath);

    setPrivateNoteMembers([]);
    onClickClosePrivateNotesModal();
  };

  const onClickCloseModal = () => {
    setPrivateNoteMembers([]);
    onClickClosePrivateNotesModal();
  };

  const onCreatePrivateNoteSaveNotePath = (noteId: string) => {
    if (noteId.length === 0) return;
    onClickSelectPrivateNote(noteId);
    // TODO Matt: Make a generatePath function. This is very risky, as if we wan't the path in
    //  another place, it's super easy to get it wrong.
    const suffix = meetingData.version >= 3 ? '_Prosemirror' : '_Firepad';
    const path = `secretNotes/${noteId}${suffix}`;
    dbUpdatePrivateNotePath(
      authData.userId,
      meetingData.meetingId,
      noteId,
      path,
    );
  };

  if (filteredMeetingAttendees.length === 0) return null;

  return (
    <Modal
      title="Create new Private note"
      isOpen={isPrivateNotesModalOpen}
      setModalClosed={onClickCloseModal}
      isScrollbarEnabled={false}
    >
      <Container>
        <ExplanationTextAtom />
        <NameInputContainer>
          <AccessAtom text="Name your Private note" />
          <NameInputAtom setTitle={setPrivateNoteTitle} placeholder="Type title here" />
        </NameInputContainer>
        <AccessAtom text="Add others" />
        <SearchableAssign
          candidates={filteredMeetingAttendees}
          selectedEmail=""
          onAssigneeChange={onClickAddAssigneeToPrivateNote}
          clearInputAfterSelect
        />
        <IconEmailMolecules
          handleRemove={onClickRemoveEmailFromPrivateNoteMembers}
          assignees={privateNoteMembers.map(mapPrivateNoteAttendeeV2ToAttendeeV2)}
        />
      </Container>
      <ModalLineLocal />
      <SingleButtonModalFooterdWrapper>
        <ButtonSmall text="Create" onClick={onClickCreatePrivateNote} />
      </SingleButtonModalFooterdWrapper>
    </Modal>
  );
};

export default CreatePrivateNotesModal;
