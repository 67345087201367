import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import Notification from '../../shared/classes/Notification/Notification';
import {
  AuthState,
  PublicUserDataV2, ResolvedState, TaskMeetingData,
} from '../../shared/types/types';
import { InviteSource, SHARE_NOTES_EMAIL, SLACK } from '../../utils/analytics/enums';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { mapPublicUserDataV2ToBasicUser } from '../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import EmailAPI from '../Email/EmailAPI';
import cfSearchPublicUserDataV2ByEmail from '../publicUserData/PublicUserDataAPI';
import SlackAPI from '../Slack/SlackAPI';
import MentionedStatus from '../ToastAPI/MentionedStatus';
import ToastAPI from '../ToastAPI/ToastAPI';
import MentionLogger from './MentionLogger';

class MentionAPI {
  /**
   * @param receiverEmail email of the person that was mentioned,
   * and also the person to receive the notification
   * @param reporterEmail email of the person who mentioned the person
   * @param authState email of the logged in user. If the reporter is the same as auth,
   * then we don't send any notifications
   * @param simpleMeetingData meeting data of the meeting that the person was mentioned in
   * @param content OBS: Not in use right now.
   * The content to be delivered with the mention. This should be the
   * sentence that the person was mentioned in.
   * i.e. `@haraldlons what are your thoughts on the above?`
   * Then the content should be `what are your thoughts on the above?`
   */
  static async createMention(
    receiverEmail: string,
    reporterEmail: string,
    authState: AuthState,
    simpleMeetingData: TaskMeetingData,
    content: string,
  ): Promise<ResolvedState> {
    ConsoleImproved.log('MentionAPI, ', {
      receiverEmail, reporterEmail, authUserEmail: authState, content, simpleMeetingData,
    });
    if (receiverEmail === authState.email) {
      ConsoleImproved.log('MentionAPI, receiverEmail is the same as authUserEmail');
      return RESOLVED;
    }
    const reporter = await cfSearchPublicUserDataV2ByEmail(reporterEmail);
    const receiver = await cfSearchPublicUserDataV2ByEmail(receiverEmail);
    const slackNotification = MentionAPI.sendSlackNotificaiton(receiver,
      reporter, simpleMeetingData);
    const emailNotificaiton = MentionAPI.sendEmailNotification(
      receiverEmail, reporter, simpleMeetingData,
    );
    const notification = await MentionAPI
      .sendAppNotification(receiver, reporter, simpleMeetingData);
    return Promise.all([slackNotification, emailNotificaiton, notification])
      .then((resolvedData) => {
        ToastAPI.showSuccessForMention(receiver.data.firstName,
          new MentionedStatus(resolvedData));
        MentionLogger.logNewMention(authState.userId);
        return RESOLVED as ResolvedState;
      })
      .catch(() => REJECTED as ResolvedState);
  }

  private static async sendSlackNotificaiton(
    receiver: PublicUserDataV2,
    reporter: PublicUserDataV2,
    simpleMeetingData: TaskMeetingData,
  ): Promise<ResolvedState> {
    if (receiver.external.slack.hasEnabledSlack === false
      || receiver.external.slack.notifications.mentionedInNotes === false) {
      ConsoleImproved.log('MentionAPI, slack notificaiton is disabled', { reporter: receiver });
      return RESOLVED;
    }

    const reporterName = reporter.isShepherdUser ? reporter.data.firstName : reporter.data.email;
    const title = `${reporterName} mentioned you in "${simpleMeetingData.name}"\n\n`;
    const message = `${title} <${MentionAPI.createSlackMeetingLink(simpleMeetingData.meetingId)}|Click here> to view and edit the notes`;
    return SlackAPI.sendNotification(title, message, receiver);
  }

  private static async sendEmailNotification(
    receiverEmail: string,
    reporter: PublicUserDataV2,
    simpleMeetingData: TaskMeetingData,
  ): Promise<ResolvedState> {
    // TODO: We should have own setting for receiving email notifications for mentions
    if (reporter.external.email.receiveTaskEmail === false) {
      ConsoleImproved.log('MentionAPI, email notificaiton is disabled', { reporter });
      return RESOLVED;
    }

    const reporterName = reporter.isShepherdUser ? reporter.data.firstName : reporter.data.email;
    const subject = `${reporterName} mentioned you in "${simpleMeetingData.name}"`;
    const body = MentionAPI.createMentionEmailBody(reporterName, simpleMeetingData);
    return EmailAPI.sendBasicEmail([receiverEmail], subject, body);
  }

  private static async sendAppNotification(
    receiver: PublicUserDataV2,
    reporter: PublicUserDataV2,
    simpleMeetingData: TaskMeetingData,
  ) {
    const recipientUser = mapPublicUserDataV2ToBasicUser(receiver);
    const reporterUser = mapPublicUserDataV2ToBasicUser(reporter);
    const notificationSource = Notification.generateNotificationSource(
      'meeting', simpleMeetingData.meetingId, simpleMeetingData.name,
    );
    const notification = Notification.generateNotification(
      recipientUser, reporterUser, notificationSource,
    );
    ConsoleImproved.log('MentionAPI, sendNotification called', { notification, recipientUser, reporterUser });
    return notification.addNotificationToDb();
  }

  static async sendWorkspaceInvitation(
    receiverEmail: string,
    reporterEmail: string,
    workspaceId: string,
    workspaceName: string,
  ) {
    const reporter = await cfSearchPublicUserDataV2ByEmail(reporterEmail);
    const receiver = await cfSearchPublicUserDataV2ByEmail(receiverEmail);
    const recipientUser = mapPublicUserDataV2ToBasicUser(receiver);
    const reporterUser = mapPublicUserDataV2ToBasicUser(reporter);
    const notificationSource = Notification.generateNotificationSource('workspace', workspaceId, workspaceName);
    const notification = Notification.generateNotificationForWorkspaceInvite(
      recipientUser, reporterUser, notificationSource, workspaceName,
    );
    console.log('Created notification (but not sent yet)', {
      notification, recipientUser, reporterUser, reporter, receiver,
    });
    ConsoleImproved.log('MentionAPI, sendWorkspaceInvitation called', { notification, recipientUser, reporterUser });
    return notification.addNotificationToDb();
  }

  static createMentionEmailBody(reporterName: string, simpleMeetingData: TaskMeetingData) {
    let body = `${reporterName} mentioned you in "${simpleMeetingData.name}". `;
    const clickHereLink = MentionAPI.createHtmlLink('Click here', MentionAPI.createMeetingLink(SHARE_NOTES_EMAIL, simpleMeetingData.meetingId));
    body += `${clickHereLink} to view and edit the notes`;
    return body;
  }

  static createHtmlLink(text: string, url: string) {
    return `<a href="${url}">${text}</a>`;
  }

  static createMeetingLink(channel: InviteSource, meetingId: string) {
    return `https://app.meetshepherd.com/meeting/${meetingId}?ref=${channel}`;
  }

  static createSlackMeetingLink(meetingId: string) {
    const url = `${MentionAPI.createMeetingLink(SLACK, meetingId)}`;
    return url;
  }
}

export default MentionAPI;
