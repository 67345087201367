import React, { ReactNode } from 'react';
import TabContainer from '../../task-tabs';

interface Props {
  children: ReactNode,
  tabIndex: number,
  // eslint-disable-next-line no-unused-vars
  setTabIndex: (index: number) => void,

}

function NotificationTabContainer({ children, tabIndex, setTabIndex }:Props) {
  return (
    <TabContainer
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      height="540px"
      enableScrollBar={false}
      tabButtonPadding="12px"
      containerPadding="0"
      contentMargin="6px"
    >
      {children}
    </TabContainer>
  );
}

export default NotificationTabContainer;
