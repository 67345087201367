import React from 'react';
import styled from 'styled-components';
import DevTagAtom from './DevTagAtom';

const Container = styled.div``;

const DevTagCtrl = () => {
  const isLocalhost = window.location.hostname === 'localhost';

  const isStaging = window.location.hostname.includes('staging');

  if (isLocalhost) {
    return (
      <Container>
        <DevTagAtom text="Dev" />
      </Container>
    );
  }

  if (isStaging) {
    return (
      <Container>
        <DevTagAtom text="Staging" />
      </Container>
    );
  }

  return null;
};

export default DevTagCtrl;
