import React from 'react';
import CloseSquareIcon from '../../icons/closeSquare';
import OnlyShowInChromeExtension from '../only-show-in-chrome-extension/OnlyShowInChromeExtension';
import { IconContainer } from './utils/styles';

interface Props {
  onClick: () => void
}

const CloseSidebarAtom = ({ onClick }: Props) => (
  <OnlyShowInChromeExtension>
    <IconContainer onClick={onClick}>
      <CloseSquareIcon width="24" height="24" />
    </IconContainer>
  </OnlyShowInChromeExtension>
);

export default CloseSidebarAtom;
