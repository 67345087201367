/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { createContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { EditorView } from 'prosemirror-view';
import { MeetingSection } from '../../types/types';
import useStateSwitchableProseMirrorFirebase from './hooks/use-state-browsable-prose-mirror-firebase';
import 'prosemirror-menu/style/menu.css';
import 'prosemirror-view/style/prosemirror.css';
import './styles/menu.css';
import './styles/editor.css';
import './styles/images.css';
import UndoIcon from './components/icons/undo';
import RedoIcon from './components/icons/redo';
import { darkBlue9 } from '../../colors/COLORS';
import ButtonSmall from '../button-small';

export interface EditorContextType {
  update: Object;
  setUpdate: CallableFunction;
  view: EditorView | null;
}

export const EditorContext = createContext<EditorContextType | null>(null);

interface ProseMirrorProps {
  path: string;
  page: MeetingSection;
}

const EditorContainer = styled.div`
`;

const Editor = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  // Be careful about this!
  // Will affect the horizontal scrolling
  overflow-y: scroll;
  height: calc(100vh - 320px - 300px);
  margin-bottom: 0;
  cursor: text;
`;

interface StateControllerWrapperProps {

}

const StateControllerWrapper = styled.div<StateControllerWrapperProps>`
  display: flex;
  flex-direction: row;
  gap: 0.85rem;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const ControlWrapper = styled.div`
  position: relative;
  flex-grow: 0;
  flex-shrink: 1;
  flex: 0 1 auto;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`;

const LeftDisplacement = styled.span`
  color: ${darkBlue9};
  font-weight: bold;
  position: absolute;
  font-size: 8px;
  bottom: 0;
  right: 0;
  transform: translate(100%, 0);
`;

const RightDisplacement = styled.span`
  color: ${darkBlue9};
  font-weight: bold;
  position: absolute;
  font-size: 8px;
  bottom: 0;
  left: 0;
  transform: translate(-100%, 0);
`;

const ProseMirrorComponent = ({
  path,
  page,
}: ProseMirrorProps) => {
  const [update, setUpdate] = useState({});

  const [state, setState] = useState<number | undefined>(undefined);
  const [
    proseMirrorEditor,
    editorView,
    editorSchema,
    getDbRef,
    stateFetcher,
  ] = useStateSwitchableProseMirrorFirebase({
    path,
    page,
    state,
  });

  const apply = () => {
  };

  return (
    <div>
      <StateControllerWrapper>
        {/* -50 */}
        <ControlWrapper
          onClick={() => {
            setState(stateFetcher.decrementState(50));
          }}
        >
          <UndoIcon />
          <LeftDisplacement>50</LeftDisplacement>
        </ControlWrapper>
        {/* -10 */}
        <ControlWrapper
          onClick={() => {
            setState(stateFetcher.decrementState(10));
          }}
        >
          <UndoIcon />
          <LeftDisplacement>10</LeftDisplacement>
        </ControlWrapper>
        {/* -1 */}
        <ControlWrapper
          onClick={() => {
            setState(stateFetcher.previousState());
          }}
        >
          <UndoIcon />
        </ControlWrapper>
        {/* CurrentState */}
        <ControlWrapper
          style={{
            userSelect: 'none',
            pointerEvents: 'none',
          }}
        >
          <span>{stateFetcher.currentlyUsedState()}</span>
        </ControlWrapper>
        {/* +1 */}
        <ControlWrapper
          onClick={() => {
            setState(stateFetcher.nextState());
          }}
        >
          <RedoIcon />
        </ControlWrapper>
        {/* +10 */}
        <ControlWrapper
          onClick={() => {
            setState(stateFetcher.incrementState(10));
          }}
        >
          <RightDisplacement>10</RightDisplacement>
          <RedoIcon />
        </ControlWrapper>
        {/* +50 */}
        <ControlWrapper
          onClick={() => {
            setState(stateFetcher.incrementState(50));
          }}
        >
          <RightDisplacement>50</RightDisplacement>
          <RedoIcon />
        </ControlWrapper>
      </StateControllerWrapper>
      <ButtonSmall
        text="Apply"
        isOutline
        onClick={apply}
      />
      <EditorContext.Provider
        value={{
          update,
          setUpdate,
          view: editorView,
        }}
      >
        <EditorContainer>
          <Editor
            id="state-changeable-editor"
            ref={proseMirrorEditor}
          />
        </EditorContainer>
      </EditorContext.Provider>
    </div>
  );
};

ProseMirrorComponent.defaultProps = {
};

export default ProseMirrorComponent;
