import { StripeConstants } from './StripeConstants';
import { StripePortalData } from './StripeTypes';

/**
 * Contains the data needed by Stripe to create a portal session
 * https://stripe.com/docs/api/checkout/sessions/create
 *
 * @param customerId - Stripe Customer Id
 * @param returnUrl - Url to redirect to when returns from Stripe
 */
class PortalSessionData implements StripePortalData {
  customerId: string;

  returnUrl: string;

  constructor(customerId: string) {
    const domain = window.location.origin; // i.e. https://app.meetshepherd.com
    this.customerId = customerId;
    this.returnUrl = `${domain}${StripeConstants.URLS.RETURN_URL}`;
  }

  getData(): StripePortalData {
    return {
      customerId: this.customerId,
      returnUrl: this.returnUrl,
    };
  }

  validate() {
    if (!this.customerId) {
      console.error(`invalid-argument, customerId is empty: "${this.getData()}"`);
      throw new Error(`invalid-argument, customerId is empty: "${this.getData()}"`);
    }
    if (!this.returnUrl) {
      console.error(`invalid-argument, returnUrl is empty: "${this.returnUrl}"`);
      throw new Error(`invalid-argument, returnUrl is empty: "${this.returnUrl}"`);
    }
  }
}

export default PortalSessionData;
