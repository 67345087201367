import React from 'react';
import styled from 'styled-components';
import {
  APPS_VIEW, EMAIL_SHARE_VIEW, NOTION_SHARE_VIEW,
  ShareModalMode, SHARE_VIEW, SLACK_SHARE_VIEW, TRELLO_SHARE_VIEW,
  CALENDAR_SHARE_VIEW,
} from '../utils/ShareModalTypes';
import ButtonSmall from '../../../../../shared/components/button-small';
import { blue6 } from '../../../../../shared/colors/COLORS';
import { uiTextMedium } from '../../../../../shared/typography';
import { ModalLineLocal } from '../utils/commonStyles';
import { DatabasePublicUserV2 } from '../../../../../shared/types/types';
import PreviewButton from '../../PreviewButton';

const ButtonRow = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;

  & > button {
    margin: 0;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`;

const TextContainer = styled.div`
  margin: 24px 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.button`
  color: ${blue6};
  ${uiTextMedium};
  cursor: pointer;
  background: none;
  border: none;
`;

const ModalFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
`;

const ModalFooterRightButtonsWrapper = styled.div`
  display: flex;

  button:first-of-type {
    margin-right: 8px;
  }
`;

interface Props {
  mode: ShareModalMode,
  setMode: React.Dispatch<React.SetStateAction<ShareModalMode>>,
  // eslint-disable-next-line no-unused-vars
  handleExport: (emails?: DatabasePublicUserV2[]) => void,
  isExportLoading: boolean,
  isExportButtonDisabled: boolean,
  onPreviewClick: () => void,
}
export default function ModalFooterButtons({
  mode, setMode, handleExport, isExportLoading, isExportButtonDisabled, onPreviewClick,
}: Props) {
  switch (mode) {
    case SHARE_VIEW:
      return (
        <TextContainer>
          <Text onClick={() => { setMode(APPS_VIEW); }}>View and manage apps</Text>
        </TextContainer>
      );
    case APPS_VIEW:
      return (
        <>
          <ModalLineLocal />
          <ButtonRow>
            <ButtonSmall onClick={() => { setMode(SHARE_VIEW); }} text="Done" isOutline />
          </ButtonRow>
        </>
      );
    case SLACK_SHARE_VIEW:
      return (
        <>
          <ModalLineLocal />
          <ButtonRow>
            <ButtonSmall onClick={() => { setMode(SHARE_VIEW); }} text="Back" isOutline />
            <ButtonSmall onClick={() => { handleExport(); }} text="Send" loading={isExportLoading} isDisabled={isExportButtonDisabled} />
          </ButtonRow>
        </>
      );
    case NOTION_SHARE_VIEW:
    case TRELLO_SHARE_VIEW:
      return (
        <>
          <ModalLineLocal />
          <ButtonRow>
            <ButtonSmall onClick={() => { setMode(SHARE_VIEW); }} text="Back" isOutline />
            <ButtonSmall onClick={() => { handleExport(); }} text="Export" loading={isExportLoading} isDisabled={isExportButtonDisabled} />
          </ButtonRow>
        </>
      );
    case CALENDAR_SHARE_VIEW:
      return (
        <>
          <ModalLineLocal />
          <ButtonRow>
            <ButtonSmall onClick={() => { setMode(SHARE_VIEW); }} text="Cancel" isOutline />
            <ButtonSmall onClick={() => { handleExport(); }} text="Add" loading={isExportLoading} isDisabled={isExportButtonDisabled} />
          </ButtonRow>
        </>
      );
    case EMAIL_SHARE_VIEW:
      return (
        <>
          <ModalLineLocal />
          <ModalFooterWrapper>
            <PreviewButton onClick={onPreviewClick} />
            <ModalFooterRightButtonsWrapper>
              <ButtonSmall onClick={() => { setMode(SHARE_VIEW); }} text="Back" isOutline />
              <ButtonSmall onClick={() => { handleExport(); }} text="Send" loading={isExportLoading} isDisabled={isExportButtonDisabled} />
            </ModalFooterRightButtonsWrapper>
          </ModalFooterWrapper>
        </>
      );

    default:
      return <></>;
  }
}
