/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import ButtonSmall from '../../shared/components/button-small';
import ErrorImage from '../../shared/icons/ErrorImage';
import { header800, uiTextMedium } from '../../shared/typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OopsText = styled.h1`
  margin-top: 40px;
  ${header800};
  margin-bottom: 0;
`;

const BoldText = styled.span`
  font-weight: bold !important;
`;

const ExplanationText = styled.p`
  ${uiTextMedium};
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 85px;
`;

const ListContainer = styled.ol`
  margin-top: 0;
  margin-bottom: 0;
`;

const ListItem = styled.li`
  ${uiTextMedium};
  `;

interface GoogleMeetErrorTemplateProps {
  retry: () => void;
}

const GoogleMeetErrorTemplate = ({ retry }: GoogleMeetErrorTemplateProps) => {
  return (
    <Container>
      <ErrorImage />
      <OopsText>
        Oops!
      </OopsText>
      <ExplanationText>
        This meeting is not connected to Shepherd yet.
        In order to use Shepherd during this meeting, please follow these easy steps:
      </ExplanationText>
      <ListContainer>
        <ListItem>Return to your Google Calendar</ListItem>
        <ListItem>Click on the event in your calendar</ListItem>
        <ListItem>
          Click
          {' '}
          <b>&#39;Open Shepherd&#39;</b>
        </ListItem>
        <ListItem>Return to your Google Meet</ListItem>
        <ListItem>
          Click
          {' '}
          <BoldText>Retry</BoldText>
        </ListItem>
      </ListContainer>
      <ButtonContainer>
        <ButtonSmall text="Retry" onClick={retry} />
      </ButtonContainer>
    </Container>
  );
};

export default GoogleMeetErrorTemplate;
