import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import MeetingDetailsHeaderOrg from '../../shared/components/MeetingDetailsHeader/MeetingDetailsHeaderOrg';
import {
  AuthState, MeetingAnalyticsData, MeetingSection,
  Shortcut, MeetingVersion, TaskTab, Page, PrivateNoteData,
  TemplateData,
  TaskItems,
  RelevantMeetingsData,
} from '../../shared/types/types';
import { MeetingData } from '../../shared/types/MeetingData';
import MeetingAnalyticsDataContext from './context/MeetingAnalyticsDataContext';
import { COLORS } from '../../shared/colors/COLORS';
import TabsMenu from './tab/TabsMenu';
import TabBody from './tab/TabBody';
import FireworkConfetti from '../../shared/confetti/FireworkConfetti';
import TemplateTitleEditBar from './templates/components/TemplateTitleEditBar';
// import DeskTopNotificationReminder from
// './desktop-notification-remind/DesktopNotificationReminder';
import BREAKPOINTS from '../../shared/CSS/CSS_Constants';
import PageContainer from '../all-pages/PageContainer';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${COLORS.gray1};
  overflow-y: hidden;
  @media only screen and (max-width: ${BREAKPOINTS.sm}){
    overflow-x: hidden;
  }
  // all childeren to be width 100%
  & > * {
    width: 100%;
  }
`;

type Props = {
  meetingData: MeetingData,
  meetingCreator: string,
  // eslint-disable-next-line no-unused-vars
  setPage: (page: Page) => void,
  taskTab: TaskTab,
  setTaskTab: Dispatch<SetStateAction<TaskTab>>,
  meetingAnalyticsData: MeetingAnalyticsData,
  attendeesLoading: boolean,
  calendarError: boolean,
  setMembersInvited: Dispatch<SetStateAction<boolean>>,
  // eslint-disable-next-line react/no-unused-prop-types
  closeTemplatesView: () => void
  unviewedTasksAssignedToMe: number,
  meetingDataVersion: MeetingVersion,
  authState: AuthState,
  meetingId: string,
  // eslint-disable-next-line no-unused-vars
  handleShortcutTrigger: (shortcut: Shortcut) => void
  membersInvited: boolean,
  taskItems: TaskItems,
  meetingTab: MeetingSection,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
  isTemplatesOpen: boolean,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  isRecurringMeeting: boolean,
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>,
  setPrivateNoteOpen?: Dispatch<SetStateAction<boolean>>,
  privateNotes: PrivateNoteData[],
  // eslint-disable-next-line no-unused-vars
  onClickSelectPrivateNote: (chatId: string) => void,
  setPrivateNotes: Dispatch<SetStateAction<PrivateNoteData[]>>,
  activePrivateNoteId: string,
  isEditTemplateEnabled: boolean,
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
  selectedTemplateToEdit: TemplateData,
  // eslint-disable-next-line no-unused-vars
  updateTitle: (title: string) => void,
  relevantMeetings: RelevantMeetingsData,
  // eslint-disable-next-line no-unused-vars
  clickRelevantMeeting: (meeting: MeetingData) => void,
}

export default function MeetingPageTemplate({
  meetingData,
  meetingCreator,
  setPage,
  taskTab,
  setTaskTab,
  meetingAnalyticsData,
  attendeesLoading,
  calendarError,
  setMembersInvited,
  closeTemplatesView,
  unviewedTasksAssignedToMe,
  meetingDataVersion,
  authState,
  meetingId,
  handleShortcutTrigger,
  membersInvited,
  taskItems,
  meetingTab,
  setMeetingTab,
  isTemplatesOpen,
  setIsTemplatesOpen,
  setCreateTaskModalOpen,
  isRecurringMeeting,
  setProseMirrorEditorView,
  privateNotes,
  setPrivateNotes,
  setPrivateNoteOpen,
  onClickSelectPrivateNote,
  activePrivateNoteId,
  isEditTemplateEnabled,
  setSelectedTemplateToEdit,
  selectedTemplateToEdit,
  updateTitle,
  relevantMeetings,
  clickRelevantMeeting,
}: Props) {
  return (
    <MeetingAnalyticsDataContext.Provider value={meetingAnalyticsData}>
      <PageContainer>
        <Container>
          <MeetingDetailsHeaderOrg
            meetingData={meetingData}
            meetingCreator={meetingCreator}
            isRecurringMeeting={isRecurringMeeting}
            attendeesLoading={attendeesLoading}
            calendarError={calendarError}
            setMembersInvited={setMembersInvited}
            updateTitle={updateTitle}
          />
          {isEditTemplateEnabled
            ? (
              <TemplateTitleEditBar
                setSelectedTemplateToEdit={setSelectedTemplateToEdit}
                selectedTemplateToEdit={selectedTemplateToEdit}
              />
            )
            : (
              <TabsMenu
                meetingData={meetingData}
                meetingTab={meetingTab}
                setMeetingTab={setMeetingTab}
                closeTemplatesView={closeTemplatesView}
                unviewedTasks={unviewedTasksAssignedToMe}
                meetingDataVersion={meetingDataVersion}
                setPrivateNotes={setPrivateNotes}
                onClickSelectPrivateNote={onClickSelectPrivateNote}
                setPrivateNoteOpen={setPrivateNoteOpen}
                privateNotes={privateNotes}
                activePrivateNoteId={activePrivateNoteId}
                relevantMeetings={relevantMeetings}
                clickRelevantMeeting={clickRelevantMeeting}
              />
            )}
          <TabBody
            tab={meetingTab}
            setPage={setPage}
            taskTab={taskTab}
            setTaskTab={setTaskTab}
            userId={authState.userId}
            meetingId={meetingId}
            taskItems={taskItems}
            setCreateTaskModalOpen={setCreateTaskModalOpen}
            isTemplatesOpen={isTemplatesOpen}
            setIsTemplatesOpen={setIsTemplatesOpen}
            setSelectedTemplateToEdit={setSelectedTemplateToEdit}
            onShortcutTrigger={handleShortcutTrigger}
            setProseMirrorEditorView={setProseMirrorEditorView}
            secretChatId={activePrivateNoteId}
          />
          {/* <ManageNotesBar /> */}
          <FireworkConfetti shouldTrigger={membersInvited} />
          {/* <DeskTopNotificationReminder /> */}
        </Container>
      </PageContainer>
    </MeetingAnalyticsDataContext.Provider>
  );
}

MeetingPageTemplate.defaultProps = {
  setPrivateNoteOpen: null,
};
