/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import styled from 'styled-components';
import InviteClass from '../../../shared/classes/Invite/InviteClass';
import ButtonSmall from '../../../shared/components/button-small';
import ButtonOutline from '../../../shared/components/buttons/ButtonOutline/ButtonOutline';
import HorizontalLine from '../../../shared/icons/HorizontalLine';
import WorkspaceLogo from '../../../shared/icons/WorkspaceLogo';
import Font from '../../../shared/typography/Font';
import CssSpinner from '../../../shared/components/css-spinner';

const InviteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px 12px;
  border-radius: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 34px;
  margin-bottom: 52px;

  & > * {
    width: 128px;
  }

  & > *:not(:last-child) {
    margin-right: 21px;
  }
`;

const SentenceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

type LoadingState = 'not' | 'decline_loading' | 'success_loading';

interface InviteCardProps {
  invite: InviteClass,
  // eslint-disable-next-line no-unused-vars
  onAction: (workspaceId: string, workspaceName: string, acceptOrDecline: 'accept' | 'decline') => void,
}

const InviteCard = ({ invite, onAction }: InviteCardProps) => {
  const [isLoading, setIsLoading] = useState<LoadingState>('not');

  const handleDecline = () => {
    setIsLoading('decline_loading');
    onAction(invite.identifiers.workspaceId, invite.identifiers.workspaceName, 'decline');
  };

  const handleAccept = () => {
    setIsLoading('success_loading');
    onAction(invite.identifiers.workspaceId, invite.identifiers.workspaceName, 'accept');
  };

  return (
    <InviteContainer>
      <WorkspaceLogo name={invite.identifiers.workspaceName} />
      <SentenceWrapper>
        <Font font="uiTextM" color="gray9">You have been invited to join&nbsp;</Font>
        <Font font="header500" color="gray9">{`${invite.identifiers.workspaceName}`}</Font>
      </SentenceWrapper>
      <Font font="header500" color="gray9">Workspace</Font>
      <ButtonGroup>
        <ButtonOutline padding="12px 20px" onClick={handleDecline}>
          {isLoading === 'decline_loading' ? (
            <CssSpinner />
          ) : (
            <Font font="uiTextM" color="gray7">Decline</Font>
          )}
        </ButtonOutline>
        <ButtonSmall
          text="Accept"
          size="xl"
          onClick={handleAccept}
          buttonVariant="submit"
          loading={isLoading === 'success_loading'}
        />
      </ButtonGroup>
      <HorizontalLine />
    </InviteContainer>
  );
};

export default InviteCard;
