import cfSearchPublicUserDataV2ByEmailCore, { cfSearchPublicUserDataV2ByEmailsCore } from './PublicUserDataCore';

// TODO: Should this function actually  just use the cfSearchPublicUserDataV2ByEmailsV2 function?
const cfSearchPublicUserDataV2ByEmail = (
  email: string,
) => cfSearchPublicUserDataV2ByEmailCore(email);

export const cfSearchPublicUserDataV2ByEmailsV2 = (
  emails: string[],
) => cfSearchPublicUserDataV2ByEmailsCore(emails);

export default cfSearchPublicUserDataV2ByEmail;
