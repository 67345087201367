import create from 'zustand';
import CursorPositions from '../shared/classes/CursorPosition';

interface CursorPositionStore {
  positions: CursorPositions;
  // eslint-disable-next-line no-unused-vars
  setCursorPosition: (meetingId: string, tab: string, position: number) => void;
  // eslint-disable-next-line no-unused-vars
  getCursorPosition: (meetingId: string, tab: string) => number;
}

const useCursorPositionStore = create<CursorPositionStore>((set, get) => ({
  positions: new CursorPositions(),
  setCursorPosition: (meetingId: string, tab: string, position: number) => {
    const { positions } = get();
    positions.add(meetingId, tab, position);
    set(() => ({
      positions,
    }));
  },
  getCursorPosition: (meetingId: string, tab: string) => {
    const { positions } = get();
    return positions.getPositionByPath(meetingId, tab);
  },
}));

export default useCursorPositionStore;
