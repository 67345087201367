import { captureMessage } from '@sentry/react';
import CloudFunctions from '../../database/CloudFunctions';

/**
 * Responsible for calling the API, getting the results and parsing it into correct format
 * Responsible for logging if there is an error
 */
class IntercomCFCore {
  protected static coreUpdateSubscriptionData = async (
    userId: string, subscriptionStatus: string,
  ): Promise<any> => {
    try {
      const result = await CloudFunctions()
        .intercomUpdateSubscription({ userId, subscriptionStatus });
      console.log(`Result in ${IntercomCFCore.coreUpdateSubscriptionData.name}`, {
        result,
        userId,
        subscriptionStatus,
      });
      return result;
    } catch (error) {
      console.error(`Error in ${IntercomCFCore.coreUpdateSubscriptionData.name}`, { error, userId, subscriptionStatus });
      captureMessage(`Error in ${IntercomCFCore.coreUpdateSubscriptionData.name}`, { extra: { error, userId, subscriptionStatus } });
      return {};
    }
  }
}

export default IntercomCFCore;
