import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { NotionData } from '../../../../shared/types/types';
import { messageText } from '../../../../shared/typography';
import PlatformShareCheckbox from '../PlatformShareCheckbox';

const SwitchGroup = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 16px;
  }
  margin-bottom: 0px;
`;

const SubText = styled.p`
  ${messageText};
`;

export interface Props {
  isNotionEnabled: boolean,
  notionWorkspace: NotionData[],
  isChecked: boolean,
  setIsChecked: Dispatch<SetStateAction<boolean>>
}

const ShareNotionCheckboxAndDropdown = ({
  isNotionEnabled, isChecked, setIsChecked, notionWorkspace,
}: Props) => {
  if (!isNotionEnabled) return null;

  return (
    <>
      <SwitchGroup>
        <PlatformShareCheckbox platform="notion" isChecked={isChecked} setIsChecked={setIsChecked} />
        {isChecked
        && (
        <SubText>
          You can find exported meeting notes inside a page called
          <br />
          {`“Shepherd Notes” in your ${notionWorkspace[0].topLevelPageName} page.`}
        </SubText>
        )}
      </SwitchGroup>
    </>
  );
};

export default ShareNotionCheckboxAndDropdown;
