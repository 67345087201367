import React from 'react';
import { surface } from '../../colors/COLORS';

interface Props {
  fill?: string,
}

const ClearColorIcon = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.8849 9.06971C15.0711 9.9741 15.0307 10.9051 14.7718 11.7859L13.4853 10.5509C13.5188 10.1586 13.4959 9.76189 13.4157 9.37208C13.2461 8.54803 12.8261 7.78384 12.2004 7.1791L12.2004 7.1791L9.00021 4.08609L7.87945 5.16931L6.79661 4.12978L9.00021 2L13.2429 6.10053C14.082 6.91154 14.6534 7.94482 14.8849 9.06971ZM13.0022 12.1664L14.1142 13.234C14.1137 13.2347 14.1132 13.2354 14.1128 13.2362L15.5339 14.6005C15.8328 14.8874 15.8424 15.3622 15.5556 15.661C15.2687 15.9598 14.794 15.9695 14.4952 15.6826L13.149 14.3903C12.8979 14.6226 12.6253 14.8344 12.3336 15.0227C11.3469 15.6599 10.1869 16 9.00021 16C7.81353 16 6.65349 15.6599 5.6668 15.0227C4.68011 14.3855 3.91107 13.4798 3.45695 12.4202C3.00282 11.3606 2.88399 10.1946 3.1155 9.06971C3.33582 7.99915 3.86403 7.01157 4.63784 6.21959L2.59193 4.25552C2.29312 3.96866 2.28343 3.49389 2.57029 3.19508C2.85714 2.89627 3.33192 2.88658 3.63073 3.17344L5.71542 5.17475L5.71728 5.17295L6.80012 6.21248L6.79826 6.21427L13.0009 12.1688C13.0013 12.168 13.0017 12.1672 13.0022 12.1664ZM12.0644 13.349L5.71998 7.25844C5.13844 7.85005 4.74697 8.58366 4.58471 9.37208C4.4152 10.1957 4.5017 11.0501 4.83566 11.8293C5.1699 12.6092 5.73953 13.2841 6.48055 13.7626C7.22209 14.2415 8.09905 14.5 9.00021 14.5C9.90138 14.5 10.7783 14.2415 11.5199 13.7626C11.7134 13.6376 11.8953 13.4992 12.0644 13.349Z" fill={fill} />
  </svg>
);

ClearColorIcon.defaultProps = {
  fill: surface,
};

export default ClearColorIcon;
