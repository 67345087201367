import mapRawGapiCalendarToGapiCalendarData from '../../database/utils/mapGapiCalendar';
import { GapiCalendarData } from '../../shared/types/types';

declare let gapi: any;

/**
 * Core functions to interact with the Google API. Should only be used by GoogleAPI class.
 */
class GAPICore {
  /**
   * @param filterOnlySelected (default: true) if true, will only return those calendars
   * you have enabled (toggled on) in your google calendar
   */
  protected static async getMyCalendarsCore(filterOnlySelected = true)
    : Promise<GapiCalendarData[]> {
    return gapi
      .client.calendar.calendarList.list({})
      .then((response: any) => {
        const data: GapiCalendarData[] = response?.result?.items
          .map(mapRawGapiCalendarToGapiCalendarData);

        return data.filter((calendar) => (filterOnlySelected ? calendar.selected : true));
      })
      .catch((error: any) => {
        console.error('Execute error in GoogleAPICore.getMyCalendarsCore', error);
        return [];
      });
  }
}

export default GAPICore;
