import {
  AttendeeV2, AuthState, PrivateNoteAttendeeV2, PublicUserDataV2,
} from '../../../../../shared/types/types';

export const validateAttendeesV2HaveValidUserId = (attendees: AttendeeV2[]) => (
  attendees.filter((attendee) => attendee.userId.length !== 0)
);

export const emptyAttendeeV2Data = () => {
  const data : AttendeeV2 = {
    responseStatus: 'pending',
    resolvedState: 'rejected',
    userId: '',
    isShepherdUser: true,
    data: {
      name: '',
      email: '',
      firstName: '',
      lastName: '',
      photoUrl: '',
    },
    external: {
      email: {
        receiveTaskEmail: false,
      },
      slack: {
        hasEnabledSlack: false,
        notifications: {
          meetingStartsSoon: false,
          mentionedInNotes: false,
          taskOverdue: false,
          taskCreated: false,
          taskUpdated: false,
          taskDeleted: false,
        },
      },
      trello: {
        version: 1,
        isTrelloEnabled: false,
        isAutoSyncEnabled: false,
        isAllowOtherToSyncEnabled: false,
      },
    },
  };
  return data;
};

// TODO Matt: Is this function not used anymore?
export const getUserAttendeeDataFromAttendees = (attendees: AttendeeV2[], authData: AuthState) => {
  const userAttendeeData = attendees.filter((attendee) => attendee.userId === authData.userId);
  if (userAttendeeData.length === 0) return emptyAttendeeV2Data();
  return userAttendeeData[0];
};

export const mapPublicUserDataV2ToAttendeeV2 = (publicUserData: PublicUserDataV2): AttendeeV2 => ({
  ...publicUserData,
  responseStatus: '',
});

export const filterCurrentUserFromAttendees = (attendees: AttendeeV2[], authData: AuthState) => (
  attendees.filter((attendee) => (attendee.data.email !== authData.email))
);

export const checkIfUserIsAlreadyInvitedToPrivateNote = (
  privateNoteMembers: PrivateNoteAttendeeV2[],
  email: string,
) => (
  privateNoteMembers.filter((member) => (
    member.data.email === email
  )).length > 0
);

export const removeMemberFromPrivateMemberByEmail = (
  privateNoteMembers: PrivateNoteAttendeeV2[],
  email: string,
) => (
  privateNoteMembers.filter((member) => (
    member.data.email !== email
  ))
);
