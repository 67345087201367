import { useEffect, useState } from 'react';

interface Size {
  width: number | undefined;
  height: number | undefined;
  isScreenSizeSM: boolean,
}

const useWindowSize = () => {
  /**
   * Initialize state with undefined width/height so server and client renders match
   * this is mostly intended for server side rendering but good to have it anyway
   * Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
   */
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
    isScreenSizeSM: false,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isScreenSizeSM: window.innerWidth <= 576,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

export default useWindowSize;
