import React from 'react';
import styled from 'styled-components';
import { PENDING, REJECTED } from '../../../../utils/enums';
import { RelevantMeetingsData } from '../../../types/types';
import { MeetingData } from '../../../types/MeetingData';
import Font from '../../../typography/Font';
import CssSpinner from '../../css-spinner';
import FutureOrPreviousRelatedMeetingList from './FutureOrPreviousRelatedMeetingList';

const Container = styled.div`
  align-self: flex-start;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

interface RelevantMeetingsListProps {
  relevantMeetings: RelevantMeetingsData,
  // eslint-disable-next-line no-unused-vars
  onClickMeeting: (meeting: MeetingData) => void,
}

const RelevantMeetingsList = ({ relevantMeetings, onClickMeeting }: RelevantMeetingsListProps) => {
  if (relevantMeetings.resolveState === PENDING) {
    return <CssSpinner color="black" />;
  }

  if (relevantMeetings.resolveState === REJECTED) {
    return <Font font="messageText"><i>Failed to load relevant meetings</i></Font>;
  }

  if (relevantMeetings.previousMeetings.length === 0
    && relevantMeetings.futureMeetings.length === 0) {
    return <Font font="messageText"><i>No relevant meetings</i></Font>;
  }

  return (
    <Container>
      <FutureOrPreviousRelatedMeetingList
        isFuture
        meetings={relevantMeetings.futureMeetings}
        onClickMeeting={onClickMeeting}
      />
      <FutureOrPreviousRelatedMeetingList
        isFuture={false}
        meetings={relevantMeetings.previousMeetings}
        onClickMeeting={onClickMeeting}
      />
    </Container>
  );
};

export default RelevantMeetingsList;
