/* eslint-disable no-bitwise */
import { NodeSpec } from 'prosemirror-model';
import { userToColour } from '../../hooks/use-prosemirror-yjs';

const hashCode = (s: string): number => s.split('')
  .reduce(
    (a, b) => {
      const n = ((a << 5) - a) + b.charCodeAt(0);
      return n & n;
    },
    0,
  );

const idFromJson = (rawJSON: any): number => {
  const json: string = rawJSON.toString();
  try {
    return JSON.parse(json)?.userId ?? hashCode(json);
  } catch (e) {
    return hashCode(json);
  }
};

const usefulJsonContent = (attrs: any) => {
  if (!attrs.userjson || attrs.userjson === '{}') {
    // not valid json, but as unique as it can get for unknown users.
    // this content will later be hashed to a number.
    return attrs.email + attrs.name;
  }
  return attrs.userjson;
};

const MentionSpec: NodeSpec = {
  attrs: {
    email: {
      default: 'unknown',
    },
    name: {
      default: 'unknown',
    },
    'promote-chance': {
      default: false,
    },
    uid: {
      default: '',
    },
    userjson: {
      default: '{}',
    },
  },
  defining: true,
  inline: true,
  isolating: true,
  content: 'text*',
  atom: true,
  group: 'inline',
  /**
   * Here an empty string of marks is given, since
   * it is not desirable to apply diverse and possibly
   * confusing styles to what should be a static
   * mention element.
   */
  marks: '',
  toDOM: (node) => [
    'span',
    {
      mention: undefined,
      email: node.attrs.email,
      name: node.attrs.name,
      'promote-chance': node.attrs['promote-chance'],
      uid: node.attrs.uid,
      class: `resolved-mention pyc-${userToColour(idFromJson(usefulJsonContent(node.attrs)))}-body`,
      contenteditable: 'false',
      userjson: node.attrs.userjson,
    },
    0,
  ],
  parseDOM: [{
    tag: 'span[mention]',
    getAttrs: (dom) => ({
      email: (dom as Element).getAttribute('email') ?? '',
      name: (dom as Element).getAttribute('name') ?? '',
      'promote-chance': (dom as Element).getAttribute('promote-chance') === 'true',
      uid: (dom as Element).getAttribute('uid') ?? '0',
      userjson: (dom as Element).getAttribute('userjson') ?? '{}',
    }),
  }],
};

export default MentionSpec;
