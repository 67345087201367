import React, { HTMLProps } from 'react';
import styled from 'styled-components';
import ButtonSmall from '../button-small';
import ResolvedStateSmartExpanded from '../../../utils/ResolvedState/ResolvedStateSmartExpanded';

const Container = styled.div`
  > button {
    height: 40px;
    padding: 8px 14px;
  }
`;

interface Props {
  isDisabled: boolean;
  onClick: HTMLProps<HTMLElement>['onClick'];
  status?: ResolvedStateSmartExpanded;
}

const InviteButtonAtom = ({
  isDisabled, onClick,
  status = ResolvedStateSmartExpanded.NULL,
}: Props) => {
  let text = 'Invite';
  if (status.isPending) {
    text = 'Sending...';
  } else if (status.isResolved) {
    text = 'Sent!';
  } else if (status.isRejected) {
    text = 'Error';
  }

  return (
    <Container>
      <ButtonSmall
        text={text}
        isOutline
        isDisabled={isDisabled}
        onClick={onClick}
      />
    </Container>
  );
};

export default InviteButtonAtom;
