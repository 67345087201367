import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface EditProps {
  fill?: string;
  width?: number;
  height?: number;
}

const EditIcon = ({
  fill,
  width,
  height,
}: EditProps) => (
  <svg width={width} height={height} viewBox="0 0 16.34 16.34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 12.37137,0.03933835 c -0.895725,0 -1.79116,0.3419706 -2.4746101,1.02539055 L 1.0647289,9.8987133 C 0.40834895,10.555013 0.03933835,11.445122 0.03933835,12.373322 v 2.503907 c 0,0.8284 0.67158,1.5 1.49999995,1.5 h 2.5039062 c 0.92826,0 1.8182394,-0.367038 2.4746094,-1.023438 L 15.351838,6.5198071 c 1.3668,-1.3668301 1.3668,-3.5823888 0,-4.9492188 L 14.845979,1.0647289 C 14.162529,0.38130895 13.267095,0.03933835 12.37137,0.03933835 Z m 0,1.49999995 c 0.51185,0 1.023562,0.1954125 1.414062,0.5859375 l 0.50586,0.5058594 c 0.7811,0.78104 0.781099,2.047075 0,2.828125 L 13.736604,6.0139477 10.40262,2.6819164 10.957307,2.1252758 c 0.3905,-0.390525 0.902213,-0.5859375 1.414063,-0.5859375 z M 9.3420729,3.7424633 12.674104,7.0744945 5.457307,14.293244 c -0.37507,0.375 -0.8836325,0.583985 -1.4140625,0.583985 H 1.5393383 v -2.503907 c 0,-0.5304 0.2108675,-1.038962 0.5859375,-1.414062 z"
      fill={fill}
    />
  </svg>
);

EditIcon.defaultProps = {
  fill: darkBlue4,
  width: 16.34,
  height: 16.34,
};

export default EditIcon;
