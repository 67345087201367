import { ProsemirrorNode } from 'prosemirror-suggest';
import {
  useState,
} from 'react';
import { LinkPreviewInitState } from '../components/widgets/link-preview';
import linkPreviewAdapter from '../logic/adapters/link-preview-adapter';

export default function useLinkPreviewer() {
  const [show, setShow] = useState<boolean>(false);

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const [anchorNode, setAnchorNode] = useState<ProsemirrorNode<any> | null>(null);

  const [href, setHref] = useState<string | null>(null);

  function openLinkPreview(state: LinkPreviewInitState) {
    setAnchor(state.anchor);
    setAnchorNode(state.anchorNode);
    setHref(state.href);
    setShow(true);
  }

  function closeLinkPreview() {
    setAnchor(null);
    setAnchorNode(null);
    setHref(null);
    setShow(false);
  }

  linkPreviewAdapter.reactMethods = {
    openPreviewDropdown: openLinkPreview,
    closePreviewDropdown: closeLinkPreview,
  };

  return {
    lpShow: [show, setShow] as const,
    lpAnchor: [anchor, setAnchor] as const,
    lpAnchorNode: [anchorNode, setAnchorNode] as const,
    lpHref: [href, setHref] as const,
  };
}
