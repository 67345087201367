import React from 'react';
import styled from 'styled-components';
import Font from '../../../../shared/typography/Font';

const Container = styled.div`
  #forOnlySevenDollars2 {
    /* background: linear-gradient(180deg, #6772C8 0%, #D691E5 100%); */
    -webkit-background-clip: text;
    background-clip: text;

    margin-bottom: 25px;

    font-weight: 600;
  }
`;

const FreePlanTextAtom = () => (
  <Container>
    <Font font="header600" color="learnMoreGrey" id="forOnlySevenDollars2">
      Free plan
    </Font>
  </Container>
);

export default FreePlanTextAtom;
