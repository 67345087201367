import * as EmailValidatorNPM from 'email-validator';

class EmailValidator {
  /**
   * @param email (string) Email to validate
   * @param onNotValidText (string) (optional) Text to console.log if email is not valid
   */
  static validate = (email: string, onNotValidText: string = ''): boolean => {
    const result = EmailValidatorNPM.validate(email);
    if (!result && onNotValidText.length > 0) {
      console.error(`Email not valid: '${onNotValidText}'`, email);
    }

    return result;
  }

  /**
   * @param onNotValidText (string) (optional) Text to console.log if email is not valid
   */
  static filterByValidEmails = (onNotValidText: string = '') => ((email: string) => EmailValidator.validate(email, onNotValidText))

  /**
   * @param onNotValidText (string) (optional) Text to console.log if email is not valid
   */
  static filterByNotValidEmails = (onNotValidText: string = '') => ((email: string) => !EmailValidator.validate(email, onNotValidText))
}

export default EmailValidator;
