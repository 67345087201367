import React from 'react';
import styled from 'styled-components';
import { StripeConstants } from '../../../../external/Stripe/StripeConstants';
import Font from '../../../../shared/typography/Font';

const Container = styled.div`
  width: 310px;
  display: flex;
  align-items: center;
  border: 3px solid #E7E9EF;
  border-radius: 8px;

  span {
    margin-bottom: 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    text-align: center;
  }

  li {
    padding-left: 25px;

    /* Marker */

    ::marker {
      color: #E7E9EF;
      font-size: 20px;
      font-weight: 600;
    }
  }
`;

const Ul = styled.ul`
  list-style-type: ">";
  padding-left: 10px;
`;

const LearnMoreFreePlanBoxRestrictionsText = () => (
  <Container>
    <Ul>
      <li>
        <Font font="uiTextM" color="learnMoreGrey">
          Only
          {'  '}
          <b>
            {StripeConstants.FREE_PLAN.MAX_MEETINGS}
            {' '}
            <u>unique</u>
            {' '}
            meetings notes
          </b>
          {' '}
          per month
        </Font>
      </li>
      <li>
        <Font font="uiTextM" color="learnMoreGrey">
          Searchable notes history up to
          {' '}
          <br />
          <b>
            {StripeConstants.FREE_PLAN.MAX_PAST_SEARCH_DAYS}
            {' '}
            days
          </b>
          {' '}
          in the past and
          {' '}
          <b>
            {StripeConstants.FREE_PLAN.MAX_FUTURE_SEARCH_DAYS}
            {' '}
            days
          </b>
          {' '}
          <br />
          in the future
        </Font>
      </li>
    </Ul>
  </Container>
);

export default LearnMoreFreePlanBoxRestrictionsText;
