import React from 'react';
import styled from 'styled-components';
import { mapDatabaseUserDataToPublicUserDataV2 } from '../../../utils/user/UserDataUtils';
import { EmailSendingStatus } from '../../types/types';
import EmailTagMolecule from './email-component/EmailTagMolecule';

const Container = styled.div`
  & > div {
    margin-top: 8px;
  }
`;

interface Props {
  emailSendingStatusList: EmailSendingStatus[];
}

const EmailTagGroupOrganism = ({ emailSendingStatusList }: Props) => (
  <Container>
    {emailSendingStatusList.map((sendingStatus) => (
      <EmailTagMolecule
        key={sendingStatus.email}
        user={mapDatabaseUserDataToPublicUserDataV2({ data: { email: sendingStatus.email } }, '')}
        resolvedState={sendingStatus.resolvedState}
      />
    ))}
  </Container>
);

export default EmailTagGroupOrganism;
