/* eslint-disable indent */
import React from 'react';
import styled, { css } from 'styled-components';
import { messageText } from '../../../shared/typography';
import {
  gray1, darkBlue4, darkBlue6, darkBlue1, gray10,
} from '../../../shared/colors/COLORS';
import { MeetingSection } from '../../../shared/types/types';
import UnviewedTasksBlueBubble from './UnviewedTasksBlueBubble';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import ButtonDivStyle from './utils/index';
import { MEETING_SECTION } from '../../../utils/enums';
import useWindowSize from '../../../utils/hook/useWindowSize';

interface ButtonProps {
  active: boolean,
  page: MeetingSection,
  disabled: boolean,
  shouldHavePadding: boolean,
  privateNotesExist: boolean
  isScreenSizeSM: boolean
}

const Button = styled(ButtonDivStyle) <ButtonProps>`
  ${messageText};
  overflow: hidden;
  white-space: nowrap;
  height: fit-content;
  margin-right: ${({ isScreenSizeSM }) => (isScreenSizeSM ? '4px' : '8px')};;
  width: fit-content;
  border-radius: 8px;
  z-index: 1;
  background: none;
  user-select: none;
  ${({
  shouldHavePadding, privateNotesExist, page,
}) => {
    if (page === MEETING_SECTION.PRIVATE_NOTES && !privateNotesExist) {
      return css`
        padding: 6px 12px;
      `;
    }

    if (page === MEETING_SECTION.PRIVATE_NOTES && privateNotesExist) {
      return css`
        padding-right: 0px;
      `;
    }

    if (!shouldHavePadding) {
      return css`
        padding: 6px 12px;
      `;
    }

    return css`
        padding: 6px 12px;
      `;
  }};

  svg {
    margin-right: 0px !important;
    padding: 4px;
  }

  ${({ active }) => {
    if (active) {
      return css`
        color: ${gray1};
        background:${gray10};
        border-radius: 8px;
      `;
    }

    return css`
      color: ${darkBlue4};
      &:hover {
        color: ${darkBlue6};
        background: ${darkBlue1};
      }
    `;
  }};
`;

export interface Props {
  active: boolean,
  disabled: boolean,
  onClick: () => void,
  meetingTab?: MeetingSection,
  unviewedTasks?: number,
  tooltipText: string,
  children: React.ReactNode;
  padding?: boolean,
  privateNotesExist?: boolean
}

const TabDivButton = ({
  active,
  disabled, onClick,
  meetingTab = 'agenda',
  unviewedTasks = 0,
  tooltipText,
  children,
  padding = false,
  privateNotesExist = false,
}: Props) => {
  const { isScreenSizeSM } = useWindowSize();
  return (
    <>
      <ReactTooltip tip={tooltipText} delay place="top">
        <Button
          isScreenSizeSM={isScreenSizeSM}
          active={active}
          disabled={disabled}
          onClick={onClick}
          page={meetingTab}
          shouldHavePadding={padding}
          privateNotesExist={privateNotesExist}
          data-meeting-tab={meetingTab}
        >
          {children}
        </Button>
      </ReactTooltip>
      <UnviewedTasksBlueBubble unviewedTasks={unviewedTasks} />
    </>
  );
};

export default TabDivButton;
