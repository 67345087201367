import React from 'react';

interface NotionIconProps {
  width?: string;
}

const EmailIcon = ({
  width = '40',
}: NotionIconProps) => (
  <svg width={width} height={width} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={width} height={width} rx="10" fill="#058FEF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M24.5253 11L15.4562 11C12.2866 11 10 13.5615 10 16.859V23.141C10 26.4385 12.2866 29 15.4562 29H24.5168C26.0392 28.9829 27.4802 28.3421 28.5126 27.2266C29.5449 26.111 30.0786 24.6183 29.9893 23.0959L29.9906 16.859C30.0786 15.3817 29.5449 13.889 28.5126 12.7734C27.4802 11.6579 26.0392 11.0171 24.5253 11ZM15.4562 12.5284L24.5084 12.5284C25.6073 12.5407 26.6532 13.0059 27.4026 13.8156C28.152 14.6253 28.5393 15.7089 28.4745 16.8139L28.4732 23.141C28.5393 24.2911 28.152 25.3747 27.4026 26.1844C26.6532 26.9941 25.6073 27.4593 24.5084 27.4716L15.4562 27.4716C13.1628 27.4716 11.5174 25.6284 11.5174 23.141V16.859C11.5174 14.3716 13.1628 12.5284 15.4562 12.5284ZM26.024 16.6384C25.7622 16.3089 25.2847 16.2556 24.9576 16.5193L20.8583 19.8242L20.7408 19.9068C20.2575 20.2095 19.6228 20.1816 19.1598 19.8181L15.0306 16.5173L14.9376 16.4536C14.6174 16.2673 14.2014 16.3403 13.9646 16.6409C13.7041 16.9715 13.759 17.4522 14.0873 17.7145L18.2217 21.0195L18.383 21.1375C19.4231 21.8459 20.8013 21.8067 21.7988 21.0236L25.9058 17.7125L25.9882 17.6355C26.24 17.3626 26.2621 16.9379 26.024 16.6384Z" fill="white" />
  </svg>

);

EmailIcon.defaultProps = {
  width: '40',
};

export default EmailIcon;
