/* eslint-disable arrow-body-style */
import React from 'react';
import styled, { css } from 'styled-components';
import Font from '../../../typography/Font';
import WorkspaceMember from '../../../../database/Workspaces/WorkspaceMember';

type ContainerProps = {
  backgroundColor: string
  isEditor: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;

  padding: 2px 8px;
  ${(props) => css`background-color: ${props.backgroundColor};`}
  border-radius: 8px;
  user-select: none;
  ${(props) => (props.isEditor && css`
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  `)};
`;

interface WorkspacePermissionTagProps {
  isEditor: boolean,
  member: WorkspaceMember,
  onClick: () => void
}

const WorkspacePermissionTag = ({ isEditor, member, onClick }: WorkspacePermissionTagProps) => {
  const handleClick = () => {
    if (isEditor) {
      onClick();
    }
  };
  return (
    <Container
      backgroundColor={member.permissionColor}
      onClick={handleClick}
      isEditor={isEditor}
      id="manage-member-permissions-button"
    >
      <Font font="small" color="purple6">{member.permissionName}</Font>
    </Container>
  );
};

export default WorkspacePermissionTag;
