import React from 'react';
import styled from 'styled-components';
import ShowOrNotHelpers from './ShowOrNotContainerHelpers';

const ShowOverContainer = styled.div<ShowOverProps>`
  /* Show this container only over a width limit */
  display: none;
  @media (min-width: ${({ showOverThisWidth }) => showOverThisWidth}px) {
    display: contents;
  }
`;

const ShowUnderContainer = styled.div<ShowUnderProps>`
  /* Show this container only under a width limit */
  display: none;
  @media (max-width: ${({ showUnderThisWidth = 0 }) => (showUnderThisWidth - 1)}px) {
    display: contents;
  }
`;

interface ShowOverProps {
  /** Show this component's children if the screen width is more than this value in pixels. */
  showOverThisWidth?: number,
}

interface ShowUnderProps {
  /** Show this component's children if the screen width is less than this value in pixels. */
  showUnderThisWidth?: number,
}

interface Props extends ShowOverProps, ShowUnderProps {
  children: React.ReactNode,
}

/**
 * Based on screen width, we show or not show this component's children.
 */
const ShowOrNotContainer = ({
  showOverThisWidth = 0,
  showUnderThisWidth = 0,
  children,
}: Props) => {
  const showOverWidth = ShowOrNotHelpers
    .makeSureIsAValidWidth(showOverThisWidth);
  if (showOverWidth !== 0) {
    return (
      <ShowOverContainer showOverThisWidth={showOverWidth}>
        {children}
      </ShowOverContainer>
    );
  }

  const showUnderWidth = ShowOrNotHelpers
    .makeSureIsAValidWidth(showUnderThisWidth);
  if (showUnderThisWidth !== 0) {
    return (
      <ShowUnderContainer showUnderThisWidth={showUnderWidth}>
        {children}
      </ShowUnderContainer>
    );
  }

  return null;
};

export default ShowOrNotContainer;
