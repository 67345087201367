import React, { useContext } from 'react';
import styled from 'styled-components';
import ProfileIcon from '../../../shared/components/profile-icon';
import { green6 } from '../../../shared/colors/COLORS';
import WelcomeMessage from './WelcomeMessage';
import HelpButtonGroup from './HelpButtonGroup';
import { Page } from '../../../shared/types/types';
import WelcomeBannerCloseIcon from './WelcomeBannerCloseIcon';
import { UserDataContext } from '../../../App';
import { ALL_PAGES } from '../../../utils/enums';
import { CSS_CONSTANTS } from '../../../shared/CSS/CSS_Constants';

const Container = styled.div`
  height: 110px;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MaxWidthContainer = styled.div`
  max-width: ${CSS_CONSTANTS.MAX_WIDTH_CONTENT};
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const LeftPartContainer = styled.div`
  padding: 18px;
  display: flex;
  align-items: center;
  height: 100%;

  // profile icon
  & > div:first-child {
    cursor: default;
  }
`;

const RightPartContainer = styled.div`
  position: relative;
  width: 170px;
  padding: 20px 38px 20px 21px;
  background: #000;
`;

interface Props {
  page: Page,
  handleWelcomeBannerClose: () => void,
  isClosed: boolean,
}

const WelcomeBanner = ({
  page, handleWelcomeBannerClose, isClosed,
}: Props) => {
  const userData = useContext(UserDataContext);

  if (page !== ALL_PAGES.ALL_TASKS || isClosed) return null;
  return (
    <Container>
      <MaxWidthContainer>
        <LeftPartContainer>
          <ProfileIcon size={75} user={userData.publicUserData} color={green6} borderWidth={6} />
          <WelcomeMessage userName={userData.data.firstName} />
        </LeftPartContainer>
        <RightPartContainer>
          <HelpButtonGroup />
          <WelcomeBannerCloseIcon onClick={handleWelcomeBannerClose} />
        </RightPartContainer>
      </MaxWidthContainer>
    </Container>
  );
};

export default WelcomeBanner;
