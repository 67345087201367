import React, { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/browser';
import { APP_ERROR_EVENT, APP_RELOAD_EVENT, ERROR_EVENT } from '../../utils/analytics/enums';
import { logAppErrorEvents } from '../../utils/analytics/eventLogger';
import SErrorBoundaryPage from './SErrorBoundaryPage';
import { AuthContext } from '../../App';

interface Props {
  children: React.ReactNode;
}

const SErrorBoundaryContainer = ({ children } : Props) => {
  const authState = useContext(AuthContext);
  const handleError = (error: Error, info: {componentStack: string }) => {
    logAppErrorEvents(
      authState.userId, ERROR_EVENT, info.componentStack,
      error.message, +new Date(),
    );
    const exceptionObject = {
      event: APP_ERROR_EVENT,
      componentStack: info.componentStack,
      error,
    };
    Sentry.captureException(exceptionObject);
  };

  const handleReset = () => {
    logAppErrorEvents(authState.userId, APP_RELOAD_EVENT, '', '', +new Date());
    window.location.reload();
  };

  return (
    <ErrorBoundary
      FallbackComponent={SErrorBoundaryPage}
      onError={handleError}
      onReset={handleReset}
    >
      {children}
    </ErrorBoundary>
  );
};

export default SErrorBoundaryContainer;
