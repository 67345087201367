/* eslint-disable import/prefer-default-export */

export const COLLECTIONS = {
  USERS: 'users',
  MEETINGS: 'meetings',
  TAGS: 'tags',
  TASKS: 'tasks',
  WORKSPACES: 'workspaces',
  NOTIFICATIONS: 'notifications',
};

export const MEETING_PATH = {
  data: {
    title: 'data.title',
    description: 'data.description',
    attendees: 'data.attendees',
  },
  date: {
    start: {
      date: 'date.start.date',
      timestamp: 'date.start.timestamp',
    },
    /** Not so nice hack to also be able to have `date.start` path */
    startOriginal: 'date.start',
    end: {
      date: 'date.end.date',
      timestamp: 'date.end.timestamp',
    },
    /** Not so nice hack to also be able to have `date.end` path */
    endOriginal: 'date.end',
  },
  googleData: {
    ids: {
      eventId: 'googleData.ids.eventId',
      recurringEventId: 'googleData.ids.recurringEventId',
      dataEventId: 'googleData.ids.dataEventId',
      calendarId: 'googleData.ids.calendarId',
      meetId: 'googleData.ids.meetId',
      htmlLink: 'googleData.ids.htmlLink',
    },
    attendees: 'googleData.attendees',
    content: {
      summary: 'googleData.content.summary',
      status: 'googleData.content.status',
      organizer: {
        /**
         * `calendarId` of the event.
         *
         * The creator can be different from the calendarId, if the creator selects
         * a different calendar than his primary calendar.
         */
        email: 'googleData.content.organizer.email',
      },
      creator: {
        email: 'googleData.content.creator.email',
      },
    },
    conferenceData: 'googleData.conferenceData',
  },
};

export const USER_PATH = {
  data: {
    photoUrl: 'data.photoUrl',
    firstName: 'data.firstName',
    lastName: 'data.lastName',
    email: 'data.email',
    name: 'data.name',
    receivedWelcomeEmail: 'data.receivedWelcomeEmail',
    newFeaturesViewed: 'data.newFeaturesViewed',
  },
  permissions: {
    google: {
      calendars: 'permissions.google.calendars',
    },
    tags: 'permissions.tags',
  },
  settings: {
    clockFormat: 'settings.clockFormat',
    disabledDomains: 'settings.disabledDomains',
  },
  billing: {
    stripeCustomerIdSet: 'billing.stripeCustomerIdSet',
    stripeCustomerId: 'billing.stripeCustomerId',
    status: 'billing.status',
    activePlans: 'billing.activePlans',
    created: 'billing.created',
    /** 'billing.trialStart' */
    trialStartPath: 'billing.trialStart',
    trialStart: {
      timestamp: 'billing.trialStart.timestamp',
      date: 'billing.trialStart.date',
    },
    /** 'billing.trialEnd' */
    trialEndPath: 'billing.trialEnd',
    trialEnd: {
      timestamp: 'billing.trialEnd.timestamp',
      date: 'billing.trialEnd.date',
    },
    meetingsUsed: 'billing.meetingsUsed',
    subscription_PATH: 'billing.subscription',
    isPaidTrialStarted: 'billing.isPaidTrialStarted',
    hasSeenInvitePromptToPremiumWorkspace: 'billing.hasSeenInvitePromptToPremiumWorkspace',
    subscription: {
      status: 'billing.subscription.status',
    },
  },
  meta: {
    privateId: 'meta.privateId',
  },
  integrations: {
    slack: 'integrations.slack',
  },
  workspaces: {
    selectedWorkspaceId: 'workspaces.selectedWorkspaceId',
  },
};

export const WORKSPACE_PATH = {
  billing: {
    subscription: {
      status: 'billing.subscription.status',
      currentPeriodStart: 'billing.subscription.currentPeriodStart',
      currentPeriodEnd: 'billing.subscription.currentPeriodEnd',
      cancelAtPeriodEnd: 'billing.subscription.cancelAtPeriodEnd',
      canceledAt: 'billing.subscription.canceledAt',
      created: 'billing.subscription.created',
      daysUntilDue: 'billing.subscription.daysUntilDue',
      defaultPaymentMethod: 'billing.subscription.defaultPaymentMethod',
      discount: 'billing.subscription.discount',
      latestInvoice: 'billing.subscription.latestInvoice',
      onBehalfOf: 'billing.subscription.onBehalfOf',
      startDate: 'billing.subscription.startDate',
      testClock: 'billing.subscription.testClock',
      quantity: 'billing.subscription.quantity',
    },
  },
  permissions: {
    allowedDomains: 'permissions.allowedDomains',
    members: 'permissions.members',
    editors: 'permissions.editors',
    managers: 'permissions.managers',
    admins: 'permissions.admins',
    invites: 'permissions.invites',
  },
};

export const getNestedKeyObject = (object: any, prefix: string) => {
  const nestedKeyObject: any = {};
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === 'object') {
      nestedKeyObject[key] = getNestedKeyObject(object[key], `${prefix}${key}.`);
    } else {
      nestedKeyObject[key] = `${prefix}${key}`;
    }
  });
  return nestedKeyObject;
};
