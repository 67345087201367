import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import CssSpinner from '../../../../shared/components/css-spinner';
import { ResolvedState, TrelloData } from '../../../../shared/types/types';
import TrelloSettings from './TrelloSettings';
import IntegrationSuccessModal from '../integrations/IntegrationSuccessModal';

const WaitingOnIntegrationSetupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  setTrelloData: Dispatch<SetStateAction<TrelloData>>,
  trelloData: TrelloData
}

const TrelloIntegrationProcessStatus = ({ setTrelloData, trelloData }: Props) => {
  const [status, setStatus] = useState<ResolvedState>('pending');
  const userData = useContext(UserDataContext);
  const trelloUserData = userData.integrations.trello;

  useEffect(() => {
    if (trelloUserData.accessToken.length) setStatus('resolved');
  }, [trelloUserData]);

  useEffect(() => {
    const milliSecondsBeforeTimeout = 60 * 1000;
    setTimeout(() => {
      setStatus((prev) => (prev === 'resolved' ? 'resolved' : 'rejected'));
    }, milliSecondsBeforeTimeout);
  }, [trelloUserData]);

  if (status === 'pending') {
    return (
      <WaitingOnIntegrationSetupContainer>
        <CssSpinner color="black" />
      </WaitingOnIntegrationSetupContainer>
    );
  }

  if (status === 'resolved') {
    return (
      <>
        <IntegrationSuccessModal
          headText="Select your default settings"
          bottomText="You may export your Shepherd tasks to only one Trello board at this time. These settings can be updated easily!"
        />
        <TrelloSettings
          setTrelloData={setTrelloData}
          trelloData={trelloData}
        />
      </>
    );
  }
  if (status === 'rejected') {
    return (
      <div>
        <p>
          There was an error connecting to Notion. Please try again.
        </p>
      </div>
    );
  }
  return null;
};

export default TrelloIntegrationProcessStatus;
