/* eslint-disable no-unused-vars */
import { useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import useDetectOutsideClick from '../../../../../utils/hook/detectOutsideClick';
import { DropdownData } from '../../utils/OnboardingTypes';
import DropdownErrorMessage from '../dropdown-error-message-atom';
import MultiSelectDropdown from '../multi-select-dropdown-molecule';
import MultiSelectInput from '../multi-select-input-molecule';
import removeValueFromArray from './utils/functions';
import {
  MultiSelectInputAndDropdownContainer, OnboardingMultiSelectInputContainer,
  HiddenLabel, VisibleLabel,
} from './utils/styles';

interface OnboardingMultiSelectDropdownProps {
  data: DropdownData,
}

const OnboardingMultiSelectDropdown = ({ data }: OnboardingMultiSelectDropdownProps) => {
  const {
    id, name, label, placeholder,
  } = data.data;
  const { options } = data;

  const inputAndDropdownRef = useRef(null);

  const [, meta, helpers] = useField(name);
  const { value, error, touched } = meta;
  const { setValue, setTouched } = helpers;

  const [selectedOptions, setSelectedOptions] = useState<Array<string>>(value);
  const [isDropdownOpen, setIsDropdownOpen] = useDetectOutsideClick(inputAndDropdownRef, false);

  useEffect(() => {
    setValue(selectedOptions);
  }, [selectedOptions]);

  const onDropdownOptionClick = (newValue: string) => {
    if (selectedOptions.includes(newValue)) {
      setSelectedOptions((prev:any) => removeValueFromArray(prev, newValue));
      return;
    }

    setSelectedOptions((prev:any) => [...prev, newValue]);
  };

  const onRemoveSelectedOption = (event:any, valueToRemove: string) => {
    event?.stopPropagation(); // to stop dropdown from closing or opening
    setSelectedOptions((prev:any) => removeValueFromArray(prev, valueToRemove));
  };

  const onInputFieldClick = (event: any) => {
    event?.stopPropagation();
    setIsDropdownOpen((prev: boolean) => !prev);
    setTouched(true);
  };

  return (
    <OnboardingMultiSelectInputContainer>
      <HiddenLabel htmlFor={id} />
      <VisibleLabel>{label}</VisibleLabel>
      <MultiSelectInputAndDropdownContainer id={id} ref={inputAndDropdownRef}>
        <MultiSelectInput
          selectedOptions={selectedOptions}
          placeholder={placeholder}
          isDropdownOpen={isDropdownOpen}
          onRemoveSelectedOption={onRemoveSelectedOption}
          onInputFieldClick={onInputFieldClick}
        />
        <MultiSelectDropdown
          dropdownOptions={options}
          selectedOptions={selectedOptions}
          isDropdownOpen={isDropdownOpen}
          onDropdownOptionClick={onDropdownOptionClick}
        />
      </MultiSelectInputAndDropdownContainer>
      <DropdownErrorMessage
        isDropdownOpen={isDropdownOpen}
        error={error}
        touched={touched}
      />
    </OnboardingMultiSelectInputContainer>
  );
};

export default OnboardingMultiSelectDropdown;
