import React from 'react';

interface Props {
  fill?: string,
}

const AddFileIcon = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9547 1.50878C10.9224 1.50301 10.8892 1.5 10.8552 1.5C10.8213 1.5 10.788 1.50301 10.7557 1.50878H6.06324C4.19777 1.50878 2.62524 3.02215 2.62524 4.86803V13.0048C2.62524 14.9414 4.11433 16.3985 6.06324 16.3985H12.0542C13.9057 16.3985 15.4142 14.8648 15.4142 13.0048V6.02828C15.4142 5.88304 15.3581 5.74344 15.2575 5.63868L11.458 1.68168C11.3519 1.57122 11.2054 1.50878 11.0522 1.50878H10.9547ZM10.2927 2.63317L6.06324 2.63377C4.80965 2.63377 3.75024 3.65332 3.75024 4.86802V13.0048C3.75024 14.3146 4.73024 15.2735 6.06324 15.2735H12.0542C13.2802 15.2735 14.2892 14.2476 14.2892 13.0048L14.289 6.73731L13.6316 6.73955C13.3815 6.73925 13.097 6.73872 12.7808 6.738C11.453 6.73519 10.3698 5.69511 10.2967 4.38556L10.2927 4.24425V2.63317ZM13.6733 5.61288L12.7833 5.613C12.0285 5.61141 11.4177 4.99917 11.4177 4.24425V3.26389L13.6733 5.61288ZM10.7208 9.123C11.0315 9.123 11.2833 9.37484 11.2833 9.6855C11.2833 9.97027 11.0717 10.2056 10.7971 10.2429L10.7208 10.248H9.44529V11.5239C9.44529 11.8346 9.19345 12.0864 8.88279 12.0864C8.59802 12.0864 8.36268 11.8748 8.32543 11.6002L8.32029 11.5239V10.248H7.04507C6.73441 10.248 6.48257 9.99616 6.48257 9.6855C6.48257 9.40073 6.69418 9.16538 6.96874 9.12814L7.04507 9.123H8.32029V7.84815C8.32029 7.53749 8.57213 7.28565 8.88279 7.28565C9.16757 7.28565 9.40291 7.49727 9.44016 7.77182L9.44529 7.84815V9.123H10.7208Z" fill={fill} />
  </svg>

);

AddFileIcon.defaultProps = {
  fill: '#200E32',
};

export default AddFileIcon;
