import React from 'react';
import styled from 'styled-components';
import Font from '../../../../shared/typography/Font';

const Container = styled.div`
  width: 320px;

  #forOnlySevenDollars2 {
    /* background: linear-gradient(180deg, #6772C8 0%, #D691E5 100%); */
    -webkit-background-clip: text;
    background-clip: text;

    margin-bottom: 25px;

    font-weight: 600;
  }
  display: flex;
  align-items: center;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    /* align-items: center; */
    text-align: center;
  }
`;

const LearnMoreText = () => (
  <Container>
    <Font font="uiTextS" color="learnMorePurple" id="announcement">
      When subscribing to our Premium plan
      for only
      {' '}
      <b>$7 per month</b>
      {' '}
      you will get
      full access to all features
      {' '}
      <u>
        without
        any
      </u>
      {' '}
      limitations:
    </Font>
  </Container>
);

export default LearnMoreText;
