import React from 'react';
import Icon from '../../icons/attendeeIcon';
import { PublicUserDataV2 } from '../../types/types';
import ReactTooltip from '../tooltip/ReactTooltip';

export interface Props {
  user: PublicUserDataV2,
  color?: string,
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right',
  size?: number,
  // only affects profile picture
  borderWidth?: number
}

const ProfileIcon = ({
  user, color, tooltipPosition, size = 24, borderWidth = 2,
}: Props) => {
  if (typeof user === 'undefined') return null; // TODO Ivan: Add comment about why we need this check
  const tooltipText = user.isShepherdUser ? `${user.data.name} - ${user.data.email}` : `${user.data.email} - Not signed up to Shepherd`;

  return (
    <ReactTooltip place={tooltipPosition} tip={tooltipText} disabled>
      <Icon
        color={color}
        size={size}
        email={user.data.email}
        nonUser={user.isShepherdUser}
        photoUrl={user.data.photoUrl}
        borderWidth={borderWidth}
      />
    </ReactTooltip>
  );
};

export default ProfileIcon;
