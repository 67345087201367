import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../colors/COLORS';
import { MeetingData } from '../../../types/MeetingData';
import Font from '../../../typography/Font';
import RelevantMeetingItem from './RelevantMeetingItem';

const FontContainer = styled.div`
  align-self: center;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  margin-right: 15px;

  color: ${COLORS.darkBlue4};
  font-size: 13px;
`;

const HorizontalSpacer = styled.div`
  height: 10px;
  width: 100%;
`;

interface FutureOrPreviousRelatedMeetingListProps {
  /** If it is not future, it is previous meetings */
  isFuture: boolean,
  meetings: MeetingData[],
  // eslint-disable-next-line no-unused-vars
  onClickMeeting: (meeting: MeetingData) => void,
}

const FutureOrPreviousRelatedMeetingList = ({ isFuture, meetings, onClickMeeting }
  : FutureOrPreviousRelatedMeetingListProps) => {
  if (meetings.length === 0) return null;
  const title = isFuture ? 'Future' : 'Previous';

  return (
    <>
      <FontContainer>
        <Font font="small">{title}</Font>
      </FontContainer>
      {meetings.map((meeting) => (
        <RelevantMeetingItem key={meeting.meetingId} meeting={meeting} onClick={onClickMeeting} />
      ))}
      {isFuture && <HorizontalSpacer />}
    </>
  );
};

export default FutureOrPreviousRelatedMeetingList;
