import React, { useState } from 'react';
import WelcomeScreensSwitcher from './components/welcome-screens-switcher';
import WelcomeScreensNavigation from './components/welcome-screens-navigation-molecule';
import { WelcomeContainer } from './utils/styles';
import OnboardingConstants from './utils/OnboardingConstants';
import { WelcomeScreens } from './utils/OnboardingTypes';
import { switchToNextWelcomeScreen, switchToPreviousWelcomeScreen } from './utils/functions';

const OnboardingWelcomeScreens = () => {
  const [activeScreen, setActiveScreen] = useState<WelcomeScreens>(OnboardingConstants.firstScreen);

  const navigateToPreviousScreen = () => {
    if (activeScreen === OnboardingConstants.firstScreen) return;

    setActiveScreen((prev) => switchToPreviousWelcomeScreen(prev));
  };

  const navigateToNextScreen = () => {
    if (activeScreen === OnboardingConstants.lastScreen) return;

    setActiveScreen((prev) => switchToNextWelcomeScreen(prev));
  };

  const navigateNextOrBack = (direction: 'next' | 'back') => {
    if (direction === 'next') {
      navigateToNextScreen();
    } else {
      navigateToPreviousScreen();
    }
  };

  const navigateToLastScreen = () => {
    setActiveScreen(OnboardingConstants.lastScreen);
  };

  return (
    <WelcomeContainer>
      <WelcomeScreensSwitcher activeScreen={activeScreen} navigateNextOrBack={navigateNextOrBack} />
      <WelcomeScreensNavigation
        data={{ activeScreen, totalScreens: OnboardingConstants.numberOfScreens }}
        functions={{ navigateToPreviousScreen, navigateToNextScreen, navigateToLastScreen }}
      />
    </WelcomeContainer>
  );
};

export default OnboardingWelcomeScreens;
