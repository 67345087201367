class GapiHtmlLink {
  value: string;

  constructor(htmlLink: string) {
    // "https://www.google.com/calendar/event?eid=OXZuY2JzN2RybjA3bnBkMDc5aHVucXRhN2pfMjAyMzAyMDhUMDcwMDAwWiB0aG9tYXNAbWVldHNoZXBoZXJkLmNvbQ"
    // Get only the eid part
    if (!htmlLink) {
      this.value = '';
      return;
    }
    const maxLen = 52;
    const eid = htmlLink.split('eid=')[1];
    // console.log('EID: ', eid);
    // console.log('EID2: ', eid.substring(0, maxLen));
    this.value = eid.substring(0, maxLen);
  }
}

export default GapiHtmlLink;
