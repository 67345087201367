import React, { useContext } from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../App';
import { dbSaveUserWorkspace } from '../../../../database/firebaseNotionAPI';
import { darkBlue1 } from '../../../../shared/colors/COLORS';
import ButtonSmall from '../../../../shared/components/button-small';
import Modal from '../../../../shared/components/Modal';
import ModalLine from '../../../../shared/components/Modal/ModalLine';
import { header200 } from '../../../../shared/typography';
import { notionAuthUrl } from '../../../../utils/notion/notionUtils';

const Container = styled.div`
 padding: 0 24px 0 24px ;
`;

const SectionText = styled.p`
  ${header200};
  margin: 24px 0 10px 0;
`;

const ModalLineLocal = styled.div`
  background: ${darkBlue1};
  height: 2px;
  width: 100%;
  margin-top: 24px;
`;

const WorkspaceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  div:not(:last-child){
  margin-bottom: 8px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WorkspaceLogo = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 8px;
`;

const WorkspaceName = styled.h2`
  ${header200};
  margin-left: 16px;
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;

  & > button {
    margin: 0;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`;

interface Props {
  isOpen: boolean,
  setModalClosed: () => void,
}

export default function NotionWorkspaceModal({ isOpen, setModalClosed }: Props) {
  const notionWorkspace = useContext(UserDataContext).integrations.notion;
  const { userId } = useContext(AuthContext);

  const removeWorkspace = (removeWorkspaceID: string) => {
    const updatedWorkspace = notionWorkspace.filter(
      (workspace) => workspace.workspaceId !== removeWorkspaceID,
    );
    dbSaveUserWorkspace(userId, updatedWorkspace);
  };

  const signedInWorkspace = notionWorkspace.map((workspace) => (
    <WorkspaceContainer key={workspace.workspaceId}>
      <InnerContainer>
        <WorkspaceLogo src={workspace.workspaceIcon} />
        <WorkspaceName>{workspace.workspaceName}</WorkspaceName>
      </InnerContainer>
      <ButtonSmall onClick={() => removeWorkspace(workspace.workspaceId)} text="Sign out" isOutline />
    </WorkspaceContainer>
  ));

  return (
    <>
      <Modal isOpen={isOpen} setModalClosed={setModalClosed} title="Notion Workspace">
        <Container>
          <SectionText>You are signed into </SectionText>
          {signedInWorkspace}
          <ModalLine />
          <SectionText>Add a Notion Workspace</SectionText>
          <ButtonSmall onClick={() => window.open(notionAuthUrl)} text="Sign in to another workspace" />
        </Container>
        <ModalLineLocal />
        <ButtonRow>
          <ButtonSmall onClick={() => setModalClosed()} text="Back" isOutline />
          <ButtonSmall onClick={() => setModalClosed()} text="Done" />
        </ButtonRow>
      </Modal>
    </>
  );
}
