import React from 'react';
import styled from 'styled-components';
import Font from '../../../../typography/Font';
import ButtonSmall from '../../../button-small';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  cursor: default;
  user-select: none;

  border-radius: 8px;
  width: 100%;
  height: 32px;

  box-shadow: 0px 2px 8px rgba(27, 33, 36, 0.16);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > *:first-child {
    font-size: 10px
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;

  > *:last-child {
    font-size: 10px;
    height: fit-content;
  }
`;

interface DaysLeftInTrialDropdownCardProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onClickSubscribe: () => void,
}

const DaysLeftInTrialDropdownCardMolecule = ({
  onClickSubscribe,
}: DaysLeftInTrialDropdownCardProps) => (
  <Container>
    <TextContainer>
      <Font font="defaultSmall" id="freeTrial">
        Upgrade Workspace
      </Font>
    </TextContainer>
    <ButtonContainer>
      <ButtonSmall padding="1px 8px" text="Upgrade" onClick={onClickSubscribe} />
    </ButtonContainer>
  </Container>
);

export default DaysLeftInTrialDropdownCardMolecule;
