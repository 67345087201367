import { v4 as uuidv4 } from 'uuid';

export const BLACKLISTED_DOMAINS = 'blacklisted_domains';
export const REMOVE_BLACKLISTED_DOMAINS = 'remove_blacklisted_domains';

class CEComs {
  static sendBlacklistedDomains = (blacklistedDomains: string[]) => {
    const message = {
      type: BLACKLISTED_DOMAINS,
      id: uuidv4(),
      sequence: 1,
      data: {
        blacklistedDomains,
      },
    };

    window.parent.postMessage(message, '*');
  }

  static removeBlacklistedDomains = (domains: string[]) => {
    const message = {
      type: REMOVE_BLACKLISTED_DOMAINS,
      id: uuidv4(),
      sequence: 1,
      data: {
        domains,
      },
    };

    window.parent.postMessage(message, '*');
  };
}

export default CEComs;
