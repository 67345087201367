import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { UserDataContext } from '../../../App';
import { getUsersTrelloSetting } from '../../../utils/trello/trelloAPIs';
import { defaultTrelloData, isTrelloSyncAvailable } from '../../../utils/trello/trelloUtils';
import {
  AssigneeV2, ResolvedStateItem, TaskItem, TrelloData,
} from '../../types/types';
import Modal from '../Modal';
import Scrollbar from '../scrollbar';
import TaskModalContent from './TaskModalContent';
import {
  newTitleChange, setAssignee, setTaskDueDate, newDescriptionChange, setIsPrivate,
} from './TaskModalUtils';

interface Props {
  taskItem: TaskItem,
  /* eslint-disable no-unused-vars */
  updateTaskItem: (newTaskItem: TaskItem, trelloData: TrelloData,
    isTrelloSyncChecked: boolean, callback: any, updatedSections: string) => void,
  /* eslint-disable no-unused-vars */
  isOpen: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  openDeleteModal: () => void,
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
}

const TaskDetailsModal = ({
  taskItem, updateTaskItem, isOpen, setOpen,
  disableAssigneeSelect, disableCalendarSelect, openDeleteModal,
}: Props) => {
  const [displayTaskItem, setDisplayTaskItem] = useState<TaskItem>(taskItem);
  const [isSyncingToDB, setSyncingToDB] = useState<boolean>(false);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [trelloChecked, setTrelloChecked] = useState<boolean>(false);
  const [trelloDisabled, setTrelloDisabled] = useState<boolean>(false);
  const [userTrelloData, setUserTrelloData] = useState({
    trelloData: defaultTrelloData,
    self: true,
  });
  const [updatedSection, setUpdatedSection] = useState<string>('');

  const userData = useContext(UserDataContext);

  useEffect(() => {
    setDisplayTaskItem(taskItem);
    if (isTrelloSyncAvailable(taskItem)) {
      setTrelloChecked(true);
    } else {
      setTrelloChecked(false);
    }
    setAssigneeTrelloData(taskItem.data.assignee.email);
  }, [taskItem]);

  useEffect(() => {
    checkTrelloTaskIntegration(userTrelloData.trelloData);
  }, [userTrelloData]);

  const checkTrelloTaskIntegration = (trelloData: TrelloData) => {
    if (trelloData.settings.isTrelloEnabled) {
      setTrelloDisabled(false);
    } else {
      setTrelloChecked(false);
      setTrelloDisabled(true);
    }
  };

  const setAssigneeTrelloData = (emailId: string) => {
    if (emailId) {
      getUsersTrelloSetting(emailId).then((response: ResolvedStateItem<TrelloData>) => {
        setUserTrelloData({
          trelloData: response.item,
          self: userData.data.email === emailId,
        });
      }).catch((error) => {
        console.log('something went wrong', error);
      });
    } else {
      setTrelloDisabled(true);
    }
  };

  const onTitleChange = (title: string) => {
    handleSetUpdatedSection(updatedSection, 'title');
    setCanUpdate(true);
    newTitleChange(setDisplayTaskItem, title);
  };

  const onDescriptionChange = (description: string) => {
    handleSetUpdatedSection(updatedSection, 'description');
    setCanUpdate(true);
    newDescriptionChange(setDisplayTaskItem, description);
  };

  const onAssigneeChange = (assignee: AssigneeV2) => {
    handleSetUpdatedSection(updatedSection, 'assignee');
    setCanUpdate(true);
    setAssignee(setDisplayTaskItem, assignee);
    if (assignee.data.email === userData.data.email) {
      setUserTrelloData({
        trelloData: userData.integrations.trello,
        self: true,
      });
    } else {
      setAssigneeTrelloData(assignee.data.email);
    }
  };

  const onTrelloSyncChange = (value: boolean) => {
    setCanUpdate(true);
    setTrelloChecked(value);
  };

  const onPrivateTaskChange = (value: boolean) => {
    handleSetUpdatedSection(updatedSection, 'private status');
    setCanUpdate(true);
    setIsPrivate(setDisplayTaskItem, value);
  };

  const onDueDateChange = (dueDate: Date) => {
    handleSetUpdatedSection(updatedSection, 'due date');
    setCanUpdate(true);
    setTaskDueDate(setDisplayTaskItem, dueDate);
  };

  const onConfirmClick = () => {
    console.log(userTrelloData);
    setSyncingToDB(true);
    updateTaskItem(
      displayTaskItem,
      userTrelloData.trelloData,
      trelloChecked,
      onTaskItemUpdated,
      updatedSection,
    );
    setCanUpdate(false);
    setOpen(false);
    setUpdatedSection('');
  };

  const onTaskItemUpdated = () => {
    setSyncingToDB(false);
  };

  const resetState = () => {
    setCanUpdate(false);
    setOpen(false);
    setDisplayTaskItem(taskItem);
  };

  const handleSetUpdatedSection = (
    prevState: string,
    updatedPart: string,
  ) => {
    if (prevState?.includes(updatedPart)) return;
    if (prevState?.length === 0) {
      setUpdatedSection(updatedPart);
      return;
    }
    setUpdatedSection(`${prevState}, ${updatedPart}`);
  };

  if (!isOpen) return null;

  return (
    <Modal title="Task Details" isOpen={isOpen} setModalClosed={() => { setOpen(false); }} isScrollbarEnabled={false}>
      <Scrollbar maxHeight="75vh">
        <TaskModalContent
          confirmButtonName="Update"
          taskModalVersion="edit"
          trelloChecked={trelloChecked}
          setTrelloToggleChange={onTrelloSyncChange as Dispatch<SetStateAction<boolean>>}
          trelloDisabled={trelloDisabled}
          taskItem={displayTaskItem}
          setTaskItem={setDisplayTaskItem}
          setModalClosed={resetState}
          onTitleChange={onTitleChange}
          onDescriptionChange={onDescriptionChange}
          onDueDateChange={onDueDateChange}
          onAssigneeChange={onAssigneeChange}
          onPrivateTaskChange={onPrivateTaskChange}
          onConfirmClick={onConfirmClick}
          openDeleteModal={openDeleteModal}
          loading={isSyncingToDB}
          canUpdate={canUpdate}
          disableCalendarSelect={disableCalendarSelect}
          disableAssigneeSelect={disableAssigneeSelect}
        />
      </Scrollbar>
    </Modal>
  );
};

TaskDetailsModal.defaultProps = {
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
};

export default TaskDetailsModal;
