import { NodeSpec } from 'prosemirror-model';

const SpanSpec: NodeSpec = {
  attrs: {
    text: {
      default: null,
    },
  },
  defining: true,
  inline: true,
  isolating: true,
  content: 'text?',
  atom: true,
  group: 'inline',
  // TODO do not accept marks on this span, instead apply the mark to the whole content of the span
  toDOM: (node) => [
    'span',
    {
      text: node.attrs.text || '',
      contenteditable: 'false',
      onclick: 'event.preventDefault();event.stopPropagation();',
    },
    0,
  ],
  parseDOM: [{
    tag: 'span[text]',
    getAttrs: (dom) => ({
      text: (dom as Element).getAttribute('text') ?? '',
    }),
  }],
};

export default SpanSpec;
