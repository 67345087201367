/* eslint-disable no-unused-vars */
import { EditorView } from 'prosemirror-view';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  gray1, gray2, gray4, gray6,
} from '../../../colors/COLORS';
import { uiTextMedium } from '../../../typography';
import useDetectClickOutside from '../hooks/fixed-click-outside';
import emojiAdapter from '../logic/suggestions/emoji-adapter';

interface MenuProps {
  show: boolean;
  top: number;
  left: number;
}

const Menu = styled.div<MenuProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  max-height: 256px;
  position: absolute;
  width: 192px;
  background-color: ${gray1};
  border-radius: 8px;
  top: min(${({ top }) => `${top}px`}, calc(100vh - 368px));
  left: min(${({ left }) => `${left}px`}, 258px);
  z-index: 1000;
  box-shadow: 0px 10px 18px rgba(0,0,0,0.14),0px 0px 1px rgba(0,0,0,0.25);
  padding: 8px;
  flex-flow: column;
  user-select: none;
  gap: 4px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface MenuItemProps {
  selected?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  padding: 6px 8px;
  border-radius: 6px;
  scroll-margin: 8px;
  display: flex;
  flex-flow: row;
  align-items: center;

  ${uiTextMedium}
  ${({ selected }) => (selected ? `background-color: ${gray4};` : '')}

  &:hover {
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? gray6 : gray2)};
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

MenuItem.defaultProps = {
  selected: false,
};

const MenuItemText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

interface EmojiFloatingPanelProps {
  show: boolean,
  left: number,
  top: number,
  contents: unknown[],
  selectedIndex: number,
  view?: EditorView | null,
}

const EmojiFloatingPanel = ({
  show,
  left,
  top,
  contents,
  selectedIndex,
  view,
}: EmojiFloatingPanelProps) => {
  const selectedRef = useRef(null);

  useEffect(() => {
    if (selectedRef.current) {
      (selectedRef.current as unknown as HTMLDivElement).scrollIntoView({
        block: 'nearest',
      });
    }
  }, [selectedIndex, contents, show]);

  const handleClick = ({ emoji }: any) => {
    if (!view) return;
    const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('emoji-suggesting')[0], 0);
    const e = emojiAdapter.getSelectedEmoji();
    if (!e || !currentPos) return;
    const { tr } = view.state;
    tr.insertText(emoji, currentPos, view.state.selection.$anchor.pos);
      view.dispatch!(tr);
      emojiAdapter.setShow(false);
  };

  const ref = useDetectClickOutside({
    onTriggered: () => {
      emojiAdapter.setShow(false);
    },
  });

  return (
    <Menu
      show={show}
      left={left}
      top={top}
      ref={ref}
    >
      {
        contents.map((value, index) => (
          <MenuItem
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClick(value as any);
            }}
            selected={index === selectedIndex}
            key={(value as any).key}
            ref={index === selectedIndex ? selectedRef : null}
          >
            { `${(value as any).emoji}` }
            &nbsp;&nbsp;
            <MenuItemText>
              { `${(value as any).key}`}
            </MenuItemText>
          </MenuItem>
        ))
      }
    </Menu>
  );
};

EmojiFloatingPanel.defaultProps = {
  view: null,
};

export default EmojiFloatingPanel;
