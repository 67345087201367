/* eslint-disable no-unused-vars */
import { IntercomProps } from 'react-use-intercom';
import { AuthState } from '../shared/types/types';
import { PENDING } from './enums';

declare global {
    interface Window {
        Intercom:any;
    }
}

type BootType = (props?: IntercomProps | undefined) => void;

export const setupIntercom = (
  authState: AuthState,
  boot: BootType,
  shutdown: BootType,
) => {
  if (authState.userState === 'unresolved') return;
  if (authState.userState === 'loggedOut') {
    shutdown();
    boot();
    return;
  }

  shutdown();
  if (authState.email.length === 0) {
    boot({
      userId: authState.userId,
      hideDefaultLauncher: true,
    });
    return;
  }
  boot({
    userId: authState.userId,
    email: authState.email,
    name: `${authState.firstName} ${authState.lastName}`,
    hideDefaultLauncher: true,
  });
};

export const updateIntercomRoute = (authState: AuthState, update: any) => {
  if (authState.email.length === 0) {
    update({
      userId: authState.userId,
    });
    return;
  }
  update({
    userId: authState.userId,
    email: authState.email,
    name: `${authState.firstName} ${authState.lastName}`,
  });
};
