/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import { Keymap, wrapIn } from 'prosemirror-commands';
import { NodeType, Schema } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import { findPositionOfNodeBefore } from '@meetshepherd/prosemirror-utils';
import { uuid4 } from '@sentry/utils';
import commandAdapter from '../suggestions/command-adapter';
import emojiAdapter from '../suggestions/emoji-adapter';
import mentionsAdapter from '../suggestions/mentions-adapter';
import MentionAPI from '../../../../../external/Mentions/MentionAPI';
import taskAdapter from '../nodes/task-adapter';
import { createNonShepherdUserIfEmailIsValidAndNotInAttendees } from '../../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';

export default {
  ArrowUp: (state, dispatch, view) => {
    if (emojiAdapter.getShow()) {
      emojiAdapter.moveSelectionUp();
      return true;
    }

    if (mentionsAdapter.getShow()) {
      mentionsAdapter.moveSelectionUp();
      return true;
    }

    if (commandAdapter.getShow()) {
      commandAdapter.moveSelectionUp();
      return true;
    }

    if (mentionsAdapter.getShowPromotionMenu()) {
      mentionsAdapter.moveSelectionUp();
      return true;
    }

    if (taskAdapter.getCalendarMenuShow()) {
      taskAdapter.rotateCalendarMenuUp();
      return true;
    }

    return false;
  },
  ArrowDown: (state, dispatch, view) => {
    if (emojiAdapter.getShow()) {
      emojiAdapter.moveSelectionDown();
      return true;
    }

    if (mentionsAdapter.getShow()) {
      mentionsAdapter.moveSelectionDown();
      return true;
    }

    if (commandAdapter.getShow()) {
      commandAdapter.moveSelectionDown();
      return true;
    }

    if (mentionsAdapter.getShowPromotionMenu()) {
      mentionsAdapter.moveSelectionDown();
      return true;
    }

    if (taskAdapter.getCalendarMenuShow()) {
      taskAdapter.rotateCalendarMenuDown();
      return true;
    }

    return false;
  },
  'Shift-Enter': (state, dispatch, view) => {
    if (mentionsAdapter.getShow() && view) {
      // here we always treat the input as unknown mention.
      const suggestionSpan = view.dom.getElementsByClassName('mentions-suggesting')[0];
      const currentPos = view.posAtDOM(suggestionSpan, 0);
      const content = suggestionSpan.textContent?.trim();

      const { tr } = state;
      const mention: NodeType = (state.schema as Schema).nodes.resolvedMention;
      if (content) {
        const trimmed = content.charAt(0) === '@' ? content.slice(1) : content;
        mentionsAdapter.addUnknownMention(trimmed);
        tr.replaceRangeWith(
          currentPos,
          state.selection.$anchor.pos,
          mention.create({
            name: trimmed,
            email: trimmed,
            'promote-chance': true,
            userjson: '{}',
            uid: uuid4(),
          }, [(state.schema as Schema).text(trimmed)]),
        );

        createNonShepherdUserIfEmailIsValidAndNotInAttendees(
          trimmed,
          mentionsAdapter.getAttendees(),
        );

        // send email to foreign user
        // IMPROVEMENT: check `trimmed` for a valid email address via regex
        //    and use it to email the user.
      }
      dispatch!(tr);

      mentionsAdapter.setSelection(0);
      mentionsAdapter.setShow(false);
      return true;
    }
    return false;
  },
  Enter: (state, dispatch, view) => {
    if (emojiAdapter.getShow()) {
      const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('emoji-suggesting')[0], 0);
      const e = emojiAdapter.getSelectedEmoji();
      if (!e || !currentPos) return true;
      const { tr } = state;
      tr.insertText(e, currentPos, state.selection.$anchor.pos);
      dispatch!(tr);
      emojiAdapter.setShow(false);
      return true;
    }

    if (mentionsAdapter.getShow() && view) {
      const suggestionSpan = view.dom.getElementsByClassName('mentions-suggesting')[0];
      const currentPos = view.posAtDOM(suggestionSpan, 0);
      const e = mentionsAdapter.getSelectedMention();
      let content = suggestionSpan.textContent?.trim();
      const hasUnknownContent = !!content && !e;
      const isSelectedFromMenu = !(!e || !currentPos);
      const isUserValid = !!(e?.userId);
      if (!isSelectedFromMenu && !hasUnknownContent) return true;
      if (!hasUnknownContent && !isUserValid) {
        // valid mention, but not a known user
        content = e.data.name;
      }
      const { tr } = state;
      const mention: NodeType = (state.schema as Schema).nodes.resolvedMention;
      if (content && (hasUnknownContent || !isUserValid)) {
        const trimmed = content.charAt(0) === '@' ? content.slice(1) : content;
        mentionsAdapter.addUnknownMention(trimmed);
        tr.replaceRangeWith(
          currentPos,
          state.selection.$anchor.pos,
          mention.create({
            name: trimmed,
            email: trimmed,
            'promote-chance': true,
            userjson: '{}',
            uid: uuid4(),
          }, [(state.schema as Schema).text(trimmed)]),
        );

        createNonShepherdUserIfEmailIsValidAndNotInAttendees(
          trimmed,
          mentionsAdapter.getAttendees(),
        );

        // send email to foreign user
        // IMPROVEMENT: check `trimmed` for a valid email address via regex
        //    and use it to email the user.
      } else {
        tr.replaceRangeWith(
          currentPos,
          state.selection.$anchor.pos,
          mention.create({
            name: e.data.name,
            email: e.data.email,
            'promote-chance': false,
            userjson: JSON.stringify(e),
            uid: uuid4(),
          }, [(state.schema as Schema).text(e.data.name || e.data.email)]),
        );
      }
      dispatch!(tr);

      mentionsAdapter.setSelection(0);
      mentionsAdapter.setShow(false);
      return true;
    }

    if (commandAdapter.getShow()) {
      const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
      const c = commandAdapter.getSelectedCommand();
      if (!c || !currentPos) return true;
      const { tr } = state;
      c.callback();
      if (!c.useTransactionLogic) {
        tr.deleteRange(currentPos, state.selection.$anchor.pos);
        dispatch!(tr);
      }
      commandAdapter.setShow(false);
      return true;
    }

    if (mentionsAdapter.getShowPromotionMenu()) {
      const { tr } = state;
      const taskType: NodeType = state.schema.nodes.task;

      if (mentionsAdapter.getSelectionIndex() === 0) {
        tr.insertText(' ');
        view?.dispatch(tr);

        try {
          const startPos = view!.posAtDOM(
            view?.domAtPos(
              findPositionOfNodeBefore(
                state.selection,
              )! - 1,
            ).node!,
            0,
          ) - 1;

          const prevNode = state.doc.nodeAt(startPos);

          const authState = taskAdapter.getCurrentUser();
          const m = taskAdapter.getCurrentMeetingData();
          // For backwards compatibility, the task adapter isn't set in the older versions.
          if (authState && m && typeof prevNode?.attrs?.userjson === 'string') {
            MentionAPI.createMention(
              JSON.parse(prevNode.attrs.userjson).data.email,
              authState.email,
              authState,
              {
                meetingId: m.meetingId,
                startDate: m.date.start,
                tags: m.tags.tags,
                name: m.name,
              },
              '',
            );
          }
        } catch (err) {
          console.debug('Could not notify!');
        }

        return true;
      }

      try {
        const startPos = view!.posAtDOM(
          view?.domAtPos(
            findPositionOfNodeBefore(
              state.selection,
            )! - 1,
          ).node!,
          0,
        ) - 1;

        const prevNode = state.doc.nodeAt(startPos);

        const endPos = state.selection.$anchor.pos;
        const userJson = prevNode?.content.firstChild?.attrs.userjson;

        tr.replaceRangeWith(
          startPos,
          endPos,
          taskType.create({
            status: 'todo',
            userjson: userJson || '{}',
            id: uuid4(),
          }),
        );
        tr.setSelection(TextSelection.create(tr.doc, findPositionOfNodeBefore(
          tr.selection,
        )! - 1));
        view?.dispatch(tr);
        return true;
      } catch (e) {
        console.error('Failed to promote mention!', e);
        return true;
      }
    }

    return false;
  },
  Escape: (state, dispatch, view) => {
    if (!dispatch) return false;
    const { tr } = state;
    if (emojiAdapter.getShow()) {
      tr.insertText(' ');
      dispatch(tr);
      return true;
    }

    if (mentionsAdapter.getShow()) {
      tr.insertText(' ');
      dispatch(tr);
      return true;
    }

    if (commandAdapter.getShow()) {
      tr.insertText(' ');
      dispatch(tr);
      return true;
    }

    if (mentionsAdapter.getShowPromotionMenu()) {
      tr.insertText(' ');
      dispatch(tr);
      return true;
    }

    // if (taskAdapter.getCalendarMenuShow()) {
    //   taskAdapter.setCalendarMenuShow(false);
    //   return true;
    // }

    return false;
  },
} as Keymap;
