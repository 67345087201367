import styled from 'styled-components';
import { COLORS } from '../../colors/COLORS';

export const FullScreenSidebarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px 0 20px;
`;

export const LogoContainer = styled.div`
  margin-top: 24px;
  padding: 0 0 24px 12px;
`;

export const LinksContainer = styled.div`
  padding: 24px 0;
`;

export const StyledLink = styled.div`
  margin: 20px 0;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  flex-grow: 0;
  cursor: pointer;

  & > *:first-child {
    margin-right: 12px;
  }

  &:hover {
    background-color: ${COLORS.gray8};
    border-radius: 4px;
    transition: background-color 0.1s ease-in-out;
  }

  &:active {
    background-color: ${COLORS.gray9};
  }
`;

export const SidebarFlowerImage = styled.img`
  margin-left: -10px;
  position: absolute;
  top: 40vh;
  max-height: 50%;
`;
