import React, { useRef } from 'react';
// doc for the pkg: https://github.com/downshift-js/downshift
import Downshift from 'downshift';
import styled from 'styled-components';
import { AssigneeV2 } from '../../types/types';
import SearchableAssignLoading from './SearchableAssignLoading';
import { emptyPublicUserDataV2 } from '../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import {
  SearchableAssignProps, stateReducer, Option,
} from '.';
import InputMolecule from './InputMolecule';
import MiniOptionsMolecule from './MiniOptionsMolecule';

export const Container = styled.div`
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 8px;
`;

const SearchableAssignMini = ({
  openByDefault, selectedEmail, onAssigneeChange, candidates,
}: SearchableAssignProps) => {
  const inputRef = useRef<HTMLInputElement>(document.createElement('input'));
  const focusInput = () => inputRef.current.focus();

  const [openOptions, setOpenOptions] = useDetectOutsideClick(inputRef, openByDefault);

  const onSelectedItemChange = (selection: Option | null) => {
    if (selection === null) {
      onAssigneeChange(emptyPublicUserDataV2);
      return;
    }
    onAssigneeChange(candidates.find(
      (candidate: AssigneeV2) => candidate.data.email === selection.value,
    ) ?? emptyPublicUserDataV2);
  };

  const candidatesEmails = candidates.map((candidate) => ({ value: candidate.data.email }));

  if (candidatesEmails.length === 0) {
    return (
      <Container>
        <SearchableAssignLoading />
      </Container>
    );
  }

  return (
    <Container>
      <Downshift
        onChange={onSelectedItemChange}
        itemToString={(item) => (item ? item.value : '')}
        initialSelectedItem={{ value: selectedEmail }}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          inputValue,
          highlightedIndex,
          getRootProps,
          clearSelection,
          isOpen,
          // selectHighlightedItem,
        }) => (
          <div>
            <InputMolecule
              getInputProps={getInputProps}
              getRootProps={getRootProps}
              clearSelection={clearSelection}
              focusInput={focusInput}
              inputRef={inputRef}
              setOpenOptions={setOpenOptions}
            />
            <MiniOptionsMolecule
              openOptions={openOptions}
              isOpen={isOpen}
              getMenuProps={getMenuProps}
              candidates={candidates}
              inputValue={inputValue}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              profileIconSize={30}
            />
          </div>
        )}
      </Downshift>
    </Container>
  );
};

SearchableAssignMini.defaultProps = {
  openByDefault: false,
};
export default SearchableAssignMini;
