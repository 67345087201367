import React from 'react';
import Font from '../../../../shared/typography/Font';

const AnnouncementTextTrialEndedOfferExtensionAtom = () => (
  // <Font font="uiTextS" color="gray7" id="announcement">
  //   We hope you have enjoyed your free trial of Shepherd Premium!
  //   As a thank you for being an early adopter, we are offering you a 7 day extension for only 1$.
  // </Font>
  <Font font="uiTextS" color="grayText" id="announcement">
    As a thank you, we&apos;re offering
    {' '}
    <br />
    a
    {' '}
    <b>7-day extension</b>
    {' '}
    to your
    {' '}
    <br />
    Premium
    {' '}
    Trial for only
    {' '}
    <b>$1</b>
  </Font>
);

export default AnnouncementTextTrialEndedOfferExtensionAtom;
