import React from 'react';
import Font from '../../../../shared/typography/Font';

const AnnouncementTextAtom = () => (
  <Font font="uiTextS" color="gray7" id="announcement">
    We’re excited to announce that we’re now releasing our
    Premium plan with full access to Shepherd’s features without
    {' '}
    <u>
      <b>any</b>
    </u>
    {' '}
    limitations.
  </Font>
);

export default AnnouncementTextAtom;
