import { TeamOrPrivate, WorkspacePermissionExpanded } from '../../../shared/types/types';
import { logEvent } from '../eventLogger';
import { EVENTS, ACTIONS } from './LoggerConstants';

abstract class LoggerWorkspaces {
  static logClickTeamOrPersonalWorkspaceInOnboarding = (
    userId: string, selected: TeamOrPrivate,
  ) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.clickTeamOrPersonalDuringOnboarding, selected };
    return logEvent(userId, eventType, eventData);
  }

  static logCreateWorkspace = (userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.createWorkspace };
    return logEvent(userId, eventType, eventData);
  }

  static logDeletedWorkspace = (userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.deleteWorkspace };
    return logEvent(userId, eventType, eventData);
  }

  static logGetWorkspace = (workspaceId: string, userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.getWorkspace, workspaceId };
    return logEvent(userId, eventType, eventData);
  }

  static logInviteMember = (workspaceId: string, userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.inviteMember, workspaceId };
    return logEvent(userId, eventType, eventData);
  }

  static logChangePermission = (
    workspaceId: string, memberEmail: string,
    newPermission: WorkspacePermissionExpanded, userId: string,
  ) => {
    const eventType = EVENTS.workspaces;
    const eventData = {
      action: ACTIONS.changePermission, workspaceId, memberEmail, newPermission,
    };
    return logEvent(userId, eventType, eventData);
  }

  static logGetInvitesByEmail = (userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.getInvitesByEmail };
    return logEvent(userId, eventType, eventData);
  }

  static logAcceptInvite = (workspaceId: string, userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.acceptInvite, workspaceId };
    return logEvent(userId, eventType, eventData);
  }

  static logDeclineInvite = (workspaceId: string, userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.declineInvite, workspaceId };
    return logEvent(userId, eventType, eventData);
  }

  static logPageInvite = (userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.pageViewInvite };
    return logEvent(userId, eventType, eventData);
  }

  static logClickInvites = (userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.clickInvitesQuickSettings };
    return logEvent(userId, eventType, eventData);
  }

  static logSetMyWorkspaceId = (userId: string, workspaceId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.setMyWorkspaceId, workspaceId };
    return logEvent(userId, eventType, eventData);
  }

  static logCreateInitialPrivateWorkspace = (userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.createInitialPrivateWorkspace };
    return logEvent(userId, eventType, eventData);
  }

  static logResetSelectedWorkspaceId = (userId: string) => {
    const eventType = EVENTS.workspaces;
    const eventData = { action: ACTIONS.resetSelectedWorkspaceId };
    return logEvent(userId, eventType, eventData);
  }
}

export default LoggerWorkspaces;
