import React, {
  Dispatch, SetStateAction,
} from 'react';
import {
  MeetingSection, Shortcut, TaskItems, TaskTab, TemplateData, Page,
} from '../../../shared/types/types';
import { MEETING_PAGE, MEETING_SECTION } from '../../../utils/enums';
import Tasks from '../../../shared/components/tasks';
import Notes from '../notes';
import TemplatesView from '../templates';

interface Props {
  tab: MeetingSection,
  // eslint-disable-next-line no-unused-vars
  setPage: (page: Page) => void,
  taskTab: TaskTab,
  setTaskTab: Dispatch<SetStateAction<TaskTab>>,
  userId: string,
  meetingId: string,
  taskItems: TaskItems,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  isTemplatesOpen: boolean,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
  // eslint-disable-next-line no-unused-vars
  onShortcutTrigger: (shortcut: Shortcut) => void,
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>
  secretChatId: string,
}

const TabBody = ({
  tab,
  setPage,
  taskTab,
  setTaskTab,
  userId,
  meetingId,
  taskItems,
  setCreateTaskModalOpen,
  isTemplatesOpen,
  setIsTemplatesOpen,
  setSelectedTemplateToEdit,
  onShortcutTrigger,
  setProseMirrorEditorView,
  secretChatId,
}: Props) => {
  if (tab === MEETING_SECTION.TASK) {
    return (
      <Tasks
        setPage={setPage}
        taskTab={taskTab}
        setTaskTab={setTaskTab}
        taskItems={taskItems}
        taskPage={MEETING_PAGE}
        setCreateTaskModalOpen={setCreateTaskModalOpen}
        disableGoToMeeting
        height="calc(100vh - 260px)"
      />
    );
  }

  return (
    <>
      {isTemplatesOpen && (
        <TemplatesView
          tab={tab}
          setIsTemplatesOpen={setIsTemplatesOpen}
          setSelectedTemplateToEdit={setSelectedTemplateToEdit}
        />
      )}
      <Notes
        noteType={tab}
        userId={userId}
        meetingId={meetingId}
        setProseMirrorEditorView={setProseMirrorEditorView}
        onShortcutTrigger={onShortcutTrigger}
        displayNotes={!isTemplatesOpen}
        secretChatId={secretChatId}
      />
    </>
  );
};

export default TabBody;
