import {
  EditorState, Transaction,
} from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { ResolvedPos, Schema } from 'prosemirror-model';
import { Command } from 'prosemirror-commands';
import { CellSelection } from '@meetshepherd/prosemirror-tables';

const isResolvedPosInsideTable = ($head: ResolvedPos): boolean => {
  for (let d = $head.depth; d > 0; d -= 1) {
    // eslint-disable-next-line prefer-destructuring
    const tableRole = $head.node(d).type.spec.tableRole;
    if (tableRole === 'cell' || tableRole === 'header_cell') return true;
  }
  return false;
};

export const ignoreWhenSelectionTargetsTable = (
  state: EditorState,
  // eslint-disable-next-line no-unused-vars
  dispatch?: (tr: Transaction) => void,
  // eslint-disable-next-line no-unused-vars
  view?: EditorView,
) => {
  const { selection } = state;
  if (selection.empty) return false;

  if (selection instanceof CellSelection) {
    return true;
  }

  const fromInsideCell = isResolvedPosInsideTable(selection.$from);
  const toInsideCell = isResolvedPosInsideTable(selection.$to);

  if (fromInsideCell !== toInsideCell) { // XOR
    // if it is not a cell selection, but one of them targets a cell,
    // it will result in a table mutation which we do not support
    return true;
  }
  // otherwise, it is a normal text selection inside a cell

  return false; // if no other case is caught, use the default backspace behavior
};

export const buildKeymap = (schema: Schema, mapKeys: Record<string, any>) => {
  const keys: Record<string, any> = {};
  function bind(key: string, cmd: Command) {
    if (mapKeys) {
      const mapped = mapKeys[key];
      if (mapped === false) return;
      // eslint-disable-next-line no-param-reassign
      if (mapped) key = mapped;
    }
    keys[key] = cmd;
  }

  bind('Backspace', ignoreWhenSelectionTargetsTable);
  bind('Delete', ignoreWhenSelectionTargetsTable);

  return keys;
};
