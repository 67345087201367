import { Mark, MarkType } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';

/**
 * @param state The current EditorState
 * @param type The candidate MarkType
 * @returns Whether the current selection has mark of MarkType active or not.
 */
export default function markActive(state: EditorState, type: Mark | MarkType): boolean {
  if (!state || !type) return false;
  const {
    from, $from, to, empty,
  } = state.selection;
  if (empty) return Boolean(type.isInSet(state.storedMarks || $from.marks()));
  return state.doc.rangeHasMark(from, to, type);
}
