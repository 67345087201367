import React from 'react';
import styled from 'styled-components';
import OnboardingWelcomeScreensModel from '../../../onboarding/welcome/utils/onboarding-welcome-screens-model';

const Container = styled.div``;

const WatchDemoActualVideoMol = () => {
  const { videoSrc } = OnboardingWelcomeScreensModel.tutorialVideo;

  return (
    <Container>
      <iframe
        src={videoSrc}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Container>
  );
};

export default WatchDemoActualVideoMol;
