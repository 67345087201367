import React from 'react';
import useNotificationStore from '../../../../zustand/useNotificationStore';
import { TabButton, TabButtonsContainer } from '../../task-tabs';

interface Props {
tabIndex: number,
// eslint-disable-next-line no-unused-vars
setTabIndex: (index: number) => void,
}

function NotificationTabButtons({ tabIndex, setTabIndex }:Props) {
  const { unreadNotificationCount } = useNotificationStore();

  return (
    <TabButtonsContainer>
      <TabButton selected={tabIndex === 0} onClick={() => { setTabIndex(0); }}>All</TabButton>
      <TabButton selected={tabIndex === 1} onClick={() => { setTabIndex(1); }}>{`Unread (${unreadNotificationCount})`}</TabButton>
      <TabButton selected={tabIndex === 2} onClick={() => { setTabIndex(2); }}>Read</TabButton>
    </TabButtonsContainer>
  );
}

export default NotificationTabButtons;
