import styled, { css } from 'styled-components';
import { header200 } from '../../../typography';
import { surface, blue6 } from '../../../colors/COLORS';
import { SIDEBAR_WIDTH } from '../../../../utils/globalStyle';
import { DEFAULT_HEADER_MEETING_VIEW, HEADER_DASHBOARD_VIEW } from './constants';

interface ContainerProps {
  view: string;
}

export const Container = styled.div<ContainerProps>`
  flex-shrink: 0;

  display: flex;
  box-sizing: border-box;
  height: 48px;
  padding: 12px 16px;

  ${({ view }) => {
    if (view === DEFAULT_HEADER_MEETING_VIEW || view === HEADER_DASHBOARD_VIEW) {
      return css`
        justify-content: space-between;
        background-color: ${surface};
        width: 100%;
        border-bottom: 1px solid #32373A;
      `;
    }

    return css`
      position: absolute;
      top: 0;
      left: 0;
      width: ${SIDEBAR_WIDTH};
      justify-content: flex-end;
    `;
  }}
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  & > div:nth-child(3) {
    margin-left: 10px;
  }
`;

export const BetaSign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 16px;
  height: 20px;
  width: 45px;

  ${header200}
  color: white;
  background: ${blue6};
`;

export const IconContainer = styled.button`
  all: unset;
  display: flex;
  cursor: pointer;

  &:hover {
    opacity: 0.4;
  }
`;

export const IconGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
