import React from 'react';
import styled from 'styled-components';
import { header400 } from '../../../typography';
import Font from '../../../typography/Font';

export const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const HeadText = styled.div`
  ${header400}
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

interface Props {
  onClick: () => void;
}

const ChromeExtensionSettings = ({ onClick }: Props) => (
  <Container>
    <HeadText>
      Chrome Extension
    </HeadText>
    <TextContainer>
      <Font font="messageText">Shepherd is currently disabled for these sites</Font>
      <Font font="messageText" color="blue4" onClick={onClick} isButton>Open</Font>
    </TextContainer>
  </Container>
);

export default ChromeExtensionSettings;
