import { useStripe } from '@stripe/react-stripe-js';
import React, { useContext } from 'react';
import { useIntercom } from 'react-use-intercom';
import { UserDataContext } from '../../../../App';
import ConsoleImproved from '../../../../shared/classes/ConsoleImproved';
import { toastDanger } from '../../../../utils/notifications';
import LimitMeetingsModal from '../TrialEndedModal/LimitMeetings/LimitMeetingsModalOrg';
import { StripeConstants } from '../../../../external/Stripe/StripeConstants';
import StripeAPI from '../../../../external/Stripe/StripeAPI';
import Mixpanel from '../../../../utils/analytics/Mixpanel';

interface Props {
  isOpen: boolean;
  onClickFree: () => void;
}

const UsageLimitModalCtrl = ({ isOpen, onClickFree }: Props) => {
  const userData = useContext(UserDataContext);
  const { trackEvent } = useIntercom();
  const stripe = useStripe();

  const handleClickSubscribe = async () => {
    ConsoleImproved.log('Clicked Subscribe', userData);

    try {
      // TODO: Need to find out if this is limited by meetings or by searches
      await StripeAPI.createAndRedirectToCheckoutSession(
        userData, StripeConstants.CHECKOUT_ORIGINS.DuringFreePlanHitMeetingLimitation,
        userData.workspace.numberOfMembers, trackEvent, stripe,
      );
    } catch (error: any) {
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const handleClickFree = async () => {
    await Mixpanel.Billing.logClosedPaywall(userData, trackEvent, 'exceededNumberOfMeetings');
    onClickFree();
  };

  return (
    <>
      <LimitMeetingsModal
        isOpen={isOpen}
        onClickSubscribe={handleClickSubscribe}
        onClickClose={handleClickFree}
      />
    </>
  );
};

export default UsageLimitModalCtrl;
