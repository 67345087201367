import React, { Dispatch, SetStateAction } from 'react';
import SlackToggleSwitch from '../../../../../shared/components/switch/SlackToggleSwitch';
import { ResolvedStateExtended } from '../../../../../shared/types/types';

interface ToggleProps {
  label: string,
  isToggled: boolean,
  onToggle: Dispatch<SetStateAction<boolean>>,
  isResolved: ResolvedStateExtended
}

const ToggleAtom = ({
  label, isToggled, onToggle, isResolved,
}: ToggleProps) => (
  <SlackToggleSwitch
    label={label}
    checked={isToggled}
    setChecked={onToggle}
    disabled={isResolved === 'rejected' || isResolved === 'pending'}
  />
);

export default ToggleAtom;
