/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import moment, { Moment } from 'moment';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TaskStatus } from '../../../../types/types';
import CustomizedReactCalendar from '../../../calendar';
import TaskDropdown from '../../../task-card/TaskDropdown';
import TaskStatusDropdownOptions from '../../../task-card/TaskStatusDropdownOptions';
import useDetectClickOutside from '../../hooks/fixed-click-outside';

interface DropdownWrapperProps {
  show: boolean;
  top: number;
  left: number;
}

const CalendarContainer = styled.div<DropdownWrapperProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  top: clamp(10px, ${({ top }) => top}px, calc(100vh - 376px));
  left: clamp(10px, ${({ left }) => left}px, 192px);
  width: 328px;
  height: 316px;
`;

interface TaskCalendarFloatingPanelProps {
  show: boolean,
  left: number,
  top: number,
  updateDate: (newDate: Date | Moment) => void,
  closeContents: () => void,
}

const TaskCalendarFloatingPanel = ({
  show,
  left,
  top,
  updateDate,
  closeContents,
}: TaskCalendarFloatingPanelProps) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (show) {
        closeContents();
      }
    },
  });
  const calendarRef = useRef(null);

  return (
    <CalendarContainer
      show={show && left !== 0 && top !== 0}
      left={left}
      top={top}
      ref={ref}
    >
      <CustomizedReactCalendar
        isOpen
        calendarRef={calendarRef}
        setIsOpen={() => { closeContents(); }}
        setDate={(date) => { updateDate(moment(date)); }}
      />
    </CalendarContainer>
  );
};

export default TaskCalendarFloatingPanel;
