import React, {
  Dispatch, SetStateAction, useState,
} from 'react';
import EditPrivateNoteModal from '../edit-private-note-modal-organism';
import PrivateNoteDropdownOption from '../private-note-dropdown-option-molecule';
import { emptyPrivateNoteData, getPrivateNoteId } from '../utils/functions';
import { PrivateNoteData } from '../../../types/types';

interface Props {
  handleUpdate: () => void,
  // eslint-disable-next-line no-unused-vars
  handleDelete: (chat: PrivateNoteData) => void,
  privateNotes: PrivateNoteData[],
  dropdownOpen: boolean,
  dropdownRef: React.MutableRefObject<null>,
  selectedChat: number,
  setSelectedChat: Dispatch<SetStateAction<number>>,
  // eslint-disable-next-line no-unused-vars
  handleNotesSelected: (chat: PrivateNoteData) => void,
  handleOpenModalCloseDropdown: () => void,
}

const SelectAndEditPrivateNotesDropdown = ({
  handleUpdate,
  handleDelete,
  privateNotes,
  dropdownOpen,
  dropdownRef,
  selectedChat,
  handleNotesSelected,
  setSelectedChat,
  handleOpenModalCloseDropdown,
}: Props) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [privateNoteToEdit, setPrivateNoteToEdit] = useState<PrivateNoteData>(emptyPrivateNoteData);

  const handleDeleteChat = (chat: PrivateNoteData) => {
    handleDelete(chat);
    setEditModalOpen(false);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    handleUpdate();
  };

  return (
    <>
      <PrivateNoteDropdownOption
        isOpen={dropdownOpen}
        privateNotes={privateNotes}
        refOutsideClick={dropdownRef}
        handleSecretNotesSelected={handleNotesSelected}
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
        handleOpenCreateSecretChat={handleOpenModalCloseDropdown}
        setChatToEdit={setPrivateNoteToEdit}
        setEditModalOpen={setEditModalOpen}
      />
      <EditPrivateNoteModal
        isOpen={editModalOpen}
        setClosed={handleEditModalClose}
        currentAttendees={privateNoteToEdit.members}
        secretChatId={getPrivateNoteId(privateNoteToEdit)}
        chat={privateNoteToEdit}
        handleDelete={handleDeleteChat}
        onClickSelectPrivateNote={handleNotesSelected}
      />
    </>
  );
};

export default SelectAndEditPrivateNotesDropdown;
