import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { AuthContext } from './App';
import FirestoreMeetings from './database/Meetings/FirestoreMeetings';
import ConsoleImproved from './shared/classes/ConsoleImproved';
import { listenToWindowRouteMessages } from './utils/chromeExtension/chromeExtension';
import { makeMeetingUrl } from './utils/meetings/meetingsUtils';
import { cleanUrl } from './utils/urlUtils/getCleanUrls';

interface Props {
  children: React.ReactNode;
}

// TODO use new hook
const ListenToRouteMessageContainer = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newUrl, setNewUrl] = useState('');
  const authState = useContext(AuthContext);

  useEffect(() => {
    listenToWindowRouteMessages(setNewUrl);
  }, []);

  const navigateToNewUrl = async () => {
    if (authState.userState !== 'loggedIn') return;
    const cleanedUrl = cleanUrl(newUrl);
    if (cleanedUrl === '') return;
    if (!location.pathname.includes(cleanedUrl)) {
      ConsoleImproved.log('Navigating to new URL', { newUrl, location_pathname: location.pathname, cleanedUrl });
      const isItTheSameMeeting = await FirestoreMeetings
        .isCurrentMeetingTheSameAsTheNewWeTryToNavigateTo(
          cleanedUrl, authState, location.pathname,
        );
      if (isItTheSameMeeting.isCurrentMeeting) {
        console.log("We are already in the meeting, so we don't need to navigate to it again");
        return;
      }
      if (isItTheSameMeeting.meetingId) {
        navigate(makeMeetingUrl(isItTheSameMeeting.meetingId));
        return;
      }
      navigate(cleanedUrl);
    } else {
      ConsoleImproved.log('NOT Navigating to new URL', {
        newUrl,
        location_pathname: location.pathname,
        cleanedUrl,
        includes: location.pathname.includes(cleanedUrl),
      });
    }
  };

  useEffect(() => {
    navigateToNewUrl();
  }, [newUrl]);

  return (
    <>
      {children}
    </>
  );
};

export default ListenToRouteMessageContainer;
