import _ from 'lodash';

const urlRules: Rules = {
  'google-meet': {
    replace: '_meet/',
    with: '',
  },
};

export const cleanUrl = (url: string) => {
  if (url) {
    let actualUrl = (decodeURI(url) || '').trim();
    const urlRule = urlRules[_.split(actualUrl, '/')[1]];
    if (urlRule) {
      actualUrl = actualUrl.toLowerCase().replace(urlRule.replace, urlRule.with);
    }
    return actualUrl.toString();
  }
  return '';
};

export const isShepherdAllowedUrl = (url: string) => {
  const { hostname } = new URL(url);
  return !!urlRules[hostname];
};

interface Rules {
  [key: string]: {
    replace: string,
    with: string,
  };
}
