/* eslint-disable no-unused-vars */
import {
  useRef, useEffect, useState, useMemo,
} from 'react';
import { StatePreviewEditor } from '@meetshepherd/prosemirror-firebase';
import {
  DOMParser, MarkSpec, NodeSpec, Schema,
} from 'prosemirror-model';
import { AllSelection, EditorState } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { addListNodes } from 'prosemirror-schema-list';
import { schema, marks } from 'prosemirror-schema-basic';
import { menuBar } from 'prosemirror-menu';
import OrderedMap from 'orderedmap';
import { Mutex } from 'async-mutex';
import { keymap } from 'prosemirror-keymap';
import { selectAll } from 'prosemirror-commands';
import { MeetingSection } from '../../../types/types';
import setup from '../logic';
import { buildColorMarks, defaultColors } from '../logic/marks/color-marks';
import { buildHighlightMarks, defaultHighlights } from '../logic/marks/highlight-marks';
import { tableNodes } from '../logic/menu/helpers/table-utils';
import suggestions from '../logic/suggestions';
import MentionSpec from '../logic/nodes/mention';
import underline from '../logic/marks/underline';
import { todoItemSpec, todoListSpec } from '../logic/nodes/checkbox';
import fontSize from '../logic/marks/font-size';
import strikethrough from '../logic/marks/strikethrough';
import fonts from '../logic/marks/fonts';
import TimestampSpec from '../logic/nodes/timestamp';
// TODO: Uncomment this on release
import { getDbRef } from '../../../../utils/firebase';
// TODO: Comment this on release (or delete it)
// import { getDbRef } from '../../../../utils/inventiff-firebase';
import immediateDecorations from '../logic/decorations/immediate-decorations';

export interface HookProps {
  path: string;
  page: MeetingSection;
  state: number | undefined;
}

export interface StateFetcher {
  currentlyUsedState: () => number | undefined;
  nextState: () => number | undefined;
  previousState: () => number | undefined;
  incrementState: (n: number | undefined) => number | undefined;
  decrementState: (n: number | undefined) => number | undefined;
}

export default function useProseMirrorFirebase(props: HookProps) {
  const proseMirrorRef = useRef<HTMLDivElement>(null);

  const [editorView, setEditorView] = useState<EditorView | null>(null);

  const [editorSchema, setEditorSchema] = useState<Schema | null>(null);

  const [stateFetcher, setStateFetcher] = useState<StateFetcher>({
    currentlyUsedState: () => undefined,
    nextState: () => undefined,
    previousState: () => undefined,
    incrementState: () => undefined,
    decrementState: () => undefined,
  });

  function stringToColor(s: string, alpha = 1) {
    const hue = s.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) % 360;
    return `hsla(${hue}, 100%, 50%, ${alpha})`;
  }

  const mutex = useMemo(() => new Mutex(), []);

  useEffect(() => {
    let firebaseEditor: StatePreviewEditor;

    const baseMarks = {} as Record<string, MarkSpec>;

    const firebaseEditorSchema: Schema = new Schema({
      nodes: addListNodes(
        (schema.spec.nodes as OrderedMap<NodeSpec>)
          .append(
            tableNodes({
              tableGroup: 'block',
              cellContent: 'block+',
              cellAttributes: {
                background: {
                  default: null,
                  getFromDOM(dom: any) {
                    return dom.style.backgroundColor || null;
                  },
                  setDOMAttr(value: any, attrs: any) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      attrs.style = `${(attrs.style || '')};background-color: ${value};`;
                    }
                  },
                },
              },
            }) as any,
          ).append({
            resolvedTimestamp: TimestampSpec,
            resolvedMention: MentionSpec,
            todo_list: todoListSpec,
            todo_item: todoItemSpec,
          }),
        'paragraph block*',
        'block',
      ),
      marks: Object.assign(
        baseMarks,
        marks,
        {
          underline,
          fontSize,
          strikethrough,
          ...fonts,
        },
        buildColorMarks(...defaultColors),
        buildHighlightMarks(...defaultHighlights),
      ),
    });

    setEditorSchema(firebaseEditorSchema);

    /**
     * This mutex is required here as cleanup can
     * only happens once the text editor has been
     * instantiated. Since there is no mechanism
     * to synchronise the cleanup with regards to
     * the text editor constructor, they had to be
     * placed within the same mutex.
     */
    mutex.runExclusive(async () => {
      firebaseEditor = await new StatePreviewEditor({
        firebaseRef: getDbRef(props.path),
        stateConfig: {
          schema: firebaseEditorSchema,
          plugins: [
            ...setup({ schema: firebaseEditorSchema }),
            suggestions,
          ],
        },
        view({ stateConfig, updateCollab, selections }) {
          const view = new EditorView(proseMirrorRef.current!, {
            state: EditorState.create(
              {
                ...stateConfig,
              },
            ),
            dispatchTransaction(transaction) {
              const newState = view.state.apply(transaction);
              view.updateState(newState);
              updateCollab(transaction, newState);
            },
            decorations({ doc }) {
              return DecorationSet.create(doc, Object.entries(selections!).map(
                ([clientID, { from, to }]) => {
                  if (from === to) {
                    const elem = document.createElement('span');
                    elem.style.borderLeft = `1px solid ${stringToColor(clientID)}`;
                    return Decoration.widget(from, elem);
                  }
                  return Decoration.inline(from, to, {
                    style: `background-color: ${stringToColor(clientID, 0.2)};`,
                  });
                },
              ));
            },
          });
          setEditorView(view);
          return view;
        },
        clientID: undefined,
        progress: undefined,
        stateLimit: props.state,
      });
      const { view } = firebaseEditor;

      const { state } = view;
      const { doc, tr, selection } = state;

      const allSelection = new AllSelection(doc);
      const { from, to } = allSelection;
      // TODO make it uneditable too
      firebaseEditor.view.dispatch(
        tr.setMeta(immediateDecorations, {
          from,
          to,
          options: {
            style: 'pointer-events: none;user-select: none;',
          },
        }),
      );

      setStateFetcher({
        currentlyUsedState: () => firebaseEditor.currentlyUsedState(),
        nextState: () => firebaseEditor.nextState(),
        previousState: () => firebaseEditor.previousState(),
        incrementState: (n: number | undefined) => firebaseEditor.incrementState(n),
        decrementState: (n: number | undefined) => firebaseEditor.decrementState(n),
      });
    });
    return () => {
      /**
       * See explanation at the first use of
       * mutex.runExclusive in this useEffect().
       */
      mutex.runExclusive(async () => {
        if (firebaseEditor) {
          await firebaseEditor.destroy();
        }
      });
    };
  }, [props.path, props.page, props.state, mutex]);

  return [proseMirrorRef, editorView, editorSchema, getDbRef, stateFetcher] as const;
}
