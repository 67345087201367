import { ShortcutInfo } from '../../../types/types';

export const macBasicShortcuts: ShortcutInfo[] = [
  { name: 'Open/Close Sidebar', commands: ['Option', 'x'] },
  { name: 'Mention User', commands: ['@', 'Username'] },
  { name: 'Create New Task', commands: ['⌘', 't'] },
  { name: 'Insert GIF', commands: ['/', 'Gif'] },
  { name: 'Insert Emoji', commands: [':', 'Keyword'] },
  { name: 'Insert Timestamp', commands: ['/', 'Time'] },
];

/* removed for now since doesn't work when cursor is within text editor
{ name: 'Move Between Tabs', commands: ['⌘', 'left-arrow', 'right-arrow'] }, */

export const macCommonActionShortcuts: ShortcutInfo[] = [
  { name: 'Copy', commands: ['⌘', 'c'] },
  { name: 'Cut', commands: ['⌘', 'x'] },
  { name: 'Paste', commands: ['⌘', 'v'] },
  { name: 'Paste Without Formatting', commands: ['⌘', 'Shift', 'v'] },
  { name: 'Undo', commands: ['⌘', 'z'] },
  { name: 'Redo', commands: ['⌘', 'Shift', 'z'] },
  { name: 'Insert Link', commands: ['⌘', 'k'] },
  { name: 'Print', commands: ['⌘', 'p'] },
  { name: 'Find', commands: ['⌘', 'f'] },
  { name: 'Bulleted List', commands: ['-', 'Spacebar'] },
  { name: 'Indent Bullet List', commands: ['Tab'] },
];

export const macTextFormattingShortcuts: ShortcutInfo[] = [
  { name: 'Bold', commands: ['⌘', 'b'] },
  { name: 'Italicize', commands: ['⌘', 'i'] },
  { name: 'Underline', commands: ['⌘', 'u'] },
  { name: 'Strikethrough', commands: ['⌘', 'Shift', 'x'] },
  { name: 'Copy Text Formatting', commands: ['⌘', 'Option', 'c'] },
  { name: 'Paste Text Formatting', commands: ['⌘', 'Option', 'v'] },
];

export const macTextSelectionWithKeyboardShortcuts: ShortcutInfo[] = [
  { name: 'Select All', commands: ['⌘', 'a'] },
  { name: 'Extend Selection One Character', commands: ['⌘', 'left-arrow', 'right-arrow'] },
  { name: 'Extend Selection One Line', commands: ['⌘', 'left-arrow', 'right-arrow'] },
  { name: 'Extend Selection to the End of the Line', commands: ['Shift', 'Fn', 'right-arrow'] },
  { name: 'Extend Selection to the Beginning of the Document', commands: ['⌘', 'Shift', 'up-arrow'] },
  { name: 'Extend Selection to the End of the Document', commands: ['⌘', 'Shift', 'down-arrow'] },
];

export const macTextSelectionWithCursorShortcuts: ShortcutInfo[] = [
  { name: 'Select Word', commands: ['Double-click'] },
  { name: 'Select Paragraph', commands: ['Triple-click'] },
];
