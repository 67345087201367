import React from 'react';
import ShepherdIcon from './components/shepherd-icon';
import ShepherdText from './components/shepherd-text';
import { DEFAULT_LOGO_SIZE, ICON_TO_LOGO_RATIO, TEXT_TO_LOGO_RATIO } from './utils/constants';
import { ShepherdLogoContainer } from './utils/styles';

export interface ShepherdLogoProps {
  width?: number;
  spin?: boolean;
  fadeIn?: boolean;
}

const ShepherdLogo = ({
  width = DEFAULT_LOGO_SIZE,
  spin = false,
  fadeIn = true,
}: ShepherdLogoProps) => {
  const iconWidth = (width * ICON_TO_LOGO_RATIO);
  const textWidth = (width * TEXT_TO_LOGO_RATIO);

  return (
    <ShepherdLogoContainer width={width} fadeIn={fadeIn}>
      <ShepherdIcon width={iconWidth} spin={spin} />
      <ShepherdText width={textWidth} />
    </ShepherdLogoContainer>
  );
};

ShepherdLogo.defaultProps = {
  width: DEFAULT_LOGO_SIZE,
  spin: false,
  fadeIn: true,
};

export default ShepherdLogo;
