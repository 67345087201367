import React, {
  Dispatch, SetStateAction, useContext, useState,
} from 'react';
import EnableCrossIcon from '../../../../shared/icons/EnableCrossIcon';
import {
  CrossIconContainer, EnableButton, InnerContainer, IntegrationText, IntegrationContainer,
} from '../integrations/utils/commonStyles';
import { notionAuthUrl } from '../../../../utils/notion/notionUtils';
import { IntegrationsTabView } from '../../../../shared/types/types';
import { NOTION_PROCESSING_VIEW } from '../../../../utils/constants';
import { NotionIcon } from '../../../../shared/icons/integration-icons';
import NotionNotesExportModal from './NotionNotesExportModal';
import {
  NOTION_INTEGRATION, OPEN_EVENT, SETTINGS_MODAL_LOCATION, SHARE_MODAL_LOCATION,
} from '../../../../utils/analytics/enums';
import { logIntegrationsEvent } from '../../../../utils/analytics/eventLogger';
import { AuthContext } from '../../../../App';

interface Props {
  enable: boolean,
  setView: Dispatch<SetStateAction<IntegrationsTabView>>
  analyticsFrom: typeof SHARE_MODAL_LOCATION | typeof SETTINGS_MODAL_LOCATION;
}

export default function NotionNotIntegrated({ enable, setView, analyticsFrom }: Props) {
  const authState = useContext(AuthContext);
  const [isNotesExportModalOpen, setIsNotesExportModalOpen] = useState(false);
  const handleNotionButtonClick = () => {
    setIsNotesExportModalOpen(true);
  };

  const handleNotionRedirect = () => {
    try {
      handleNotesExportModalCloseClick();
      window.open(notionAuthUrl);
      setView(NOTION_PROCESSING_VIEW);
      logIntegrationsEvent(authState.userId, OPEN_EVENT, NOTION_INTEGRATION, analyticsFrom);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotesExportModalCloseClick = () => {
    setIsNotesExportModalOpen(false);
  };

  if (enable) return null;
  return (
    <div>
      <IntegrationContainer>
        <InnerContainer>
          <NotionIcon width="28" />
          <IntegrationText>Notion (Beta)</IntegrationText>
        </InnerContainer>
        <InnerContainer>
          <CrossIconContainer onClick={handleNotionButtonClick}>
            <EnableCrossIcon />
            <EnableButton>Enable</EnableButton>
          </CrossIconContainer>
        </InnerContainer>
      </IntegrationContainer>
      <NotionNotesExportModal
        isOpen={isNotesExportModalOpen}
        handleCloseClick={handleNotesExportModalCloseClick}
        handleNotionRedirect={handleNotionRedirect}
      />
    </div>
  );
}
