import React from 'react';
import { gray1 } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const PaperPlusIcon = ({ fill }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.6062 2.01194C14.5632 2.00426 14.5188 2.00024 14.4735 2.00024C14.4283 2.00024 14.3839 2.00426 14.3409 2.01194H8.08424C5.59695 2.01194 3.50024 4.02977 3.50024 6.49094V17.3399C3.50024 19.9222 5.48569 21.8649 8.08424 21.8649H16.0722C18.5408 21.8649 20.5522 19.82 20.5522 17.3399V8.03794C20.5522 7.8443 20.4773 7.65817 20.3432 7.51849L15.2772 2.24249C15.1358 2.0952 14.9404 2.01194 14.7362 2.01194H14.6062ZM13.7235 3.51114L8.08424 3.51194C6.41278 3.51194 5.00024 4.87134 5.00024 6.49094V17.3399C5.00024 19.0864 6.30691 20.3649 8.08424 20.3649H16.0722C17.7068 20.3649 19.0522 18.9971 19.0522 17.3399L19.052 8.98333L18.1754 8.98631C17.8419 8.98591 17.4626 8.9852 17.041 8.98424C15.2706 8.9805 13.8263 7.59372 13.7288 5.84765L13.7235 5.65924V3.51114ZM18.231 7.48408L17.0443 7.48425C16.0379 7.48212 15.2235 6.66581 15.2235 5.65924V4.35209L18.231 7.48408ZM14.2943 12.1642C14.7086 12.1642 15.0443 12.5 15.0443 12.9142C15.0443 13.2939 14.7622 13.6077 14.3961 13.6574L14.2943 13.6642H12.5936V15.3654C12.5936 15.7797 12.2579 16.1154 11.8436 16.1154C11.4639 16.1154 11.1502 15.8333 11.1005 15.4672L11.0936 15.3654V13.6642H9.39334C8.97913 13.6642 8.64334 13.3285 8.64334 12.9142C8.64334 12.5345 8.9255 12.2208 9.29157 12.1711L9.39334 12.1642H11.0936V10.4644C11.0936 10.0502 11.4294 9.71444 11.8436 9.71444C12.2233 9.71444 12.5371 9.9966 12.5868 10.3627L12.5936 10.4644V12.1642H14.2943Z" fill={fill} />
  </svg>
);

PaperPlusIcon.defaultProps = {
  fill: gray1,
};

export default PaperPlusIcon;
