import React, { useContext, useState } from 'react';
import { dbUpdatePrivateNoteTitleAndMembers } from '../../../../database/firebasePrivateNotesAPI';
import Modal from '../../Modal';
import PaperLockIcon from '../../../icons/PaperLockIcon';
import { AssigneeV2, PrivateNoteData, PrivateNoteAttendeeV2 } from '../../../types/types';
import { mapAssigneeV2ToSecretChatAttendeeV2 } from '../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import MeetingDataContext from '../../../../pages/meeting/context/MeetingDataContext';
import { ModalLineLocal } from '../../../../pages/meeting/modal/ShareNotesModal';
import EditPrivateNoteModalBody from './components/edit-private-note-modal-body-molecule';
import EditPrivateNoteModalFooter from './components/edit-private-note-modal-footer-molecule';
import { logSecretChatEvents } from '../../../../utils/analytics/eventLogger';
import { INVITE_EVENT } from '../../../../utils/analytics/enums';
import { AuthContext } from '../../../../App';

interface Props {
  isOpen: boolean,
  setClosed: () => void,
  secretChatId: string,
  currentAttendees: PrivateNoteAttendeeV2[],
  chat: PrivateNoteData
  // eslint-disable-next-line no-unused-vars
  handleDelete: (chat: PrivateNoteData) => void,
  // eslint-disable-next-line no-unused-vars
  onClickSelectPrivateNote: (chat: PrivateNoteData) => void,
}

const EditPrivateNoteModal = ({
  isOpen,
  setClosed,
  secretChatId,
  currentAttendees,
  chat,
  handleDelete,
  onClickSelectPrivateNote,
}: Props) => {
  if (!isOpen) return null;
  const meetingData = useContext(MeetingDataContext);
  const authState = useContext(AuthContext);

  const [participants, setParticipants] = useState<PrivateNoteAttendeeV2[]>(currentAttendees);
  const [privateNoteTitle, setPrivateNoteTitle] = useState<string>(chat.title);

  const attendees = meetingData.attendees.attendees.filter(
    (attendee) => attendee.userId.length !== 0,
  ) ?? [];

  const filterPrivateNoteMembersFromMeetingAttendees = attendees.filter(
    (attendee) => currentAttendees.every(
      (currentAttendee) => currentAttendee.userId !== attendee.userId,
    ),
  );

  const onClickAddAttendeeToPrivateNote = (assignee: AssigneeV2) => {
    if (assignee.data.email.length === 0) return;
    if (participants.filter((tag) => tag.data.email === assignee.data.email).length > 0) return;
    const attendee = mapAssigneeV2ToSecretChatAttendeeV2(assignee);
    logSecretChatEvents(authState.userId, INVITE_EVENT);
    setParticipants((prevState) => [...prevState, attendee]);
  };

  const onClickRemoveAttendeeFromPrivateNote = (email: string) => {
    const tempArray = participants.filter((participant) => participant.data.email !== email);
    setParticipants(tempArray);
  };

  const onClickUpdatePrivateNoteTitleAndMembers = () => {
    dbUpdatePrivateNoteTitleAndMembers(
      privateNoteTitle, meetingData.meetingId, secretChatId, participants,
    );
    onClickSelectPrivateNote(chat);
    setClosed();
  };

  // TODO: make it so that you can still open edit modal if you have no remaining attendees
  // if (remainingAttendees.length === 0) return null;
  return (
    <Modal title="Edit Private note" isOpen={isOpen} setModalClosed={setClosed} icon={<PaperLockIcon />} isScrollbarEnabled={false}>
      <EditPrivateNoteModalBody
        privateNoteTitle={privateNoteTitle}
        setPrivateNoteTitle={setPrivateNoteTitle}
        privateNoteMembers={participants}
        remainingMeetingAttendees={filterPrivateNoteMembersFromMeetingAttendees}
        onClickAddAttendeeToPrivateNote={onClickAddAttendeeToPrivateNote}
        onClickRemoveAttendeeFromPrivateNote={onClickRemoveAttendeeFromPrivateNote}
      />
      <ModalLineLocal />
      <EditPrivateNoteModalFooter
        privateNote={chat}
        onClickCloseModal={setClosed}
        onClickDeletePrivateNote={handleDelete}
        onClickUpdatePrivateNoteTitleAndMembers={onClickUpdatePrivateNoteTitleAndMembers}
      />
    </Modal>
  );
};

export default EditPrivateNoteModal;
