import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import UserAPI from '../../../../database/User/UserAPI';
import {
  darkBlue4, gray3, gray7, green6, surface,
} from '../../../../shared/colors/COLORS';
import ProfileIcon from '../../../../shared/components/profile-icon';
import { mapDatabaseTemplateDataToTemplateData } from '../../../../shared/components/templates-modal/utils';
import { TemplateData, User } from '../../../../shared/types/types';
import { header400, small } from '../../../../shared/typography';
import { DateToTemplateDateFormat } from '../../../../utils/dateUtils/date';
import { mapDatabaseDataToUser } from '../../../../utils/user/UserDataUtils';
import { CSS_CONSTANTS } from '../../../../shared/CSS/CSS_Constants';

interface Props {
  selectedTemplateToEdit: TemplateData
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background-color: ${gray3};
  max-width: ${CSS_CONSTANTS.MAX_WIDTH_CONTENT};
`;

const EditText = styled.div`
  ${small};
  color: ${darkBlue4};
`;

const TitleTextInput = styled.input`
  all: unset;
  ${header400};
  color: ${surface};
  width: 298px;
`;

const DateText = styled.div`
 ${small};
 color: ${gray7};
`;

export default function TemplateTitleEditBar(
  { selectedTemplateToEdit, setSelectedTemplateToEdit }: Props,
) {
  const [title, setTitle] = useState('');
  const [templateData, setTemplateData] = useState<TemplateData>(mapDatabaseTemplateDataToTemplateData('', ''));
  const [creatorData, setCreatorData] = useState<User>(mapDatabaseDataToUser('', ''));

  useEffect(() => {
    setTemplateData(selectedTemplateToEdit);
    setTitle(selectedTemplateToEdit.data.title);
    if (selectedTemplateToEdit.creator.userId) {
      UserAPI.OtherUsers.dbGetUserByUserId(selectedTemplateToEdit.creator.userId)
        .then((databaseUser: User) => {
          setCreatorData(databaseUser);
        });
    }
  }, [selectedTemplateToEdit]);

  const handleChange = (newTitle: string) => {
    setSelectedTemplateToEdit({
      ...selectedTemplateToEdit,
      data: {
        ...selectedTemplateToEdit.data,
        title: newTitle,
      },
    });
  };

  return (
    <Container>
      <EditText>Editing</EditText>
      |
      <TitleTextInput value={title} onChange={(e) => { handleChange(e.target.value); }} />
      <ProfileIcon
        user={creatorData.publicUserData}
        color={green6}
        key={templateData.creator.email}
      />
      <DateText>{DateToTemplateDateFormat(templateData.date.created.date)}</DateText>
    </Container>
  );
}
