/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import WorkspaceMember from '../../../../database/Workspaces/WorkspaceMember';
import ProfileCard from '../../ProfileCard/ProfileCard';
import WorkspaceChangePermissionsDropdown from './WorkspaceChangePermissionsDropdown';
import { WorkspacePermissionExpanded } from '../../../types/types';
import WorkspaceData from '../../../../database/Workspaces/WorkspaceData';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface WorkspaceMemberRowMolProps {
  member: WorkspaceMember,
  workspace: WorkspaceData,
  // eslint-disable-next-line no-unused-vars
  setMemberPermission: (email: string, newPermission: WorkspacePermissionExpanded) => void,
  // eslint-disable-next-line no-unused-vars
  setIsPermissionsOpen: (isOpen: boolean) => void
}

const WorkspaceMemberRowMol = ({
  member,
  workspace,
  setMemberPermission,
  setIsPermissionsOpen,
}: WorkspaceMemberRowMolProps) => {
  return (
    <Container>
      <ProfileCard user={member} />
      <WorkspaceChangePermissionsDropdown
        member={member}
        workspace={workspace}
        setMemberPermission={setMemberPermission}
        setIsPermissionsOpen={setIsPermissionsOpen}
      />
    </Container>
  );
};

export default WorkspaceMemberRowMol;
