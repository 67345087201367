import React from 'react';
import { surface, gray2 } from '../colors/COLORS';

interface Props {
  fill?: string,
  innerFill?: string,
}

const PaperLockIcon = ({ fill, innerFill }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.6063 2.0119C14.5633 2.00422 14.5189 2.00021 14.4737 2.00021C14.4284 2.00021 14.3841 2.00422 14.3411 2.0119H8.08438C5.59709 2.0119 3.50038 4.02973 3.50038 6.4909L3.50031 6.74977C3.50018 7.16407 3.836 7.5 4.25031 7.5C4.66443 7.5 5.00018 7.16435 5.00031 6.75023L5.00038 6.4909C5.00038 4.8713 6.41292 3.5119 8.08438 3.5119L13.7237 3.5111V5.65921L13.7289 5.84762C13.8264 7.59369 15.2708 8.98047 17.0411 8.98421C17.4628 8.98517 17.8421 8.98588 18.1756 8.98628L19.0521 8.9833L19.0524 17.3399C19.0524 18.997 17.7069 20.3649 16.0724 20.3649H15.7501C15.3359 20.3649 15.0001 20.7007 15.0001 21.1149C15.0001 21.5291 15.3359 21.8649 15.7501 21.8649H16.0724C18.541 21.8649 20.5524 19.8199 20.5524 17.3399V8.0379C20.5524 7.84426 20.4775 7.65812 20.3434 7.51845L15.2774 2.24245C15.1359 2.09516 14.9406 2.0119 14.7364 2.0119H14.6063ZM15.2237 4.35205V5.65921C15.2237 6.66578 16.038 7.48209 17.0444 7.48422L18.2311 7.48405L15.2237 4.35205Z" fill={fill} />
    <rect y="8" width="14" height="14" fill={innerFill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.49717 12.143L5.49716 13H10.9412C12.7409 13 14.2 14.3688 14.2 16.0572V18.9428C14.2 20.6312 12.7409 22 10.9412 22H5.45885C3.6591 22 2.20001 20.6312 2.20001 18.9428V16.0572C2.20001 14.8618 2.93136 13.8267 3.99716 13.324L3.99717 12.1379C4.00681 9.9709 5.79608 8.24233 7.96712 8.25003L7.96859 8.25003L7.96566 9.00003V8.25003L7.96712 8.25003C9.65865 8.25064 11.1542 9.31031 11.7045 10.8738C11.842 11.2646 11.6368 11.6928 11.246 11.8303C10.8553 11.9678 10.4271 11.7625 10.2896 11.3718C9.95284 10.415 9.02855 9.75003 7.96566 9.75003H7.96274C6.59673 9.74469 5.50388 10.8271 5.49717 12.143ZM10.9412 14.5H5.45885C4.39582 14.5 3.70001 15.286 3.70001 16.0572V18.9428C3.70001 19.714 4.39582 20.5 5.45885 20.5H10.9412C12.0042 20.5 12.7 19.714 12.7 18.9428V16.0572C12.7 15.286 12.0042 14.5 10.9412 14.5ZM8.95001 16.5625C8.95001 16.1483 8.61423 15.8125 8.20001 15.8125C7.7858 15.8125 7.45001 16.1483 7.45001 16.5625V18.2812C7.45001 18.6955 7.7858 19.0312 8.20001 19.0312C8.61423 19.0312 8.95001 18.6955 8.95001 18.2812V16.5625Z" fill={fill} />
  </svg>
);

PaperLockIcon.defaultProps = {
  fill: surface,
  innerFill: gray2,
};

export default PaperLockIcon;
