import React from 'react';
import CrossIcon from '../../../icons/cross';
import { InputCrossContainer, InputItem, CrossContainer } from '../../integration-multi-input';
import { DropdownItem } from '../utils';

interface Props{
  isMultiSelect: boolean
  selectedItems: DropdownItem[]
  setSelectedItems: React.Dispatch<DropdownItem[]>,
}

function MultiSelectItemsMolecule({ isMultiSelect, selectedItems, setSelectedItems }:Props) {
  const handleRemoveSelectedItem = (itemToRemove:any, event:any) => {
    event.stopPropagation();
    const filteredItems = selectedItems.filter(
      (item: any) => item.id !== itemToRemove.id,
    );
    setSelectedItems(filteredItems);
  };

  if (!isMultiSelect) return null;

  return (
    <>
      {selectedItems.map((item: any) => (
        <InputCrossContainer key={item.id}>
          <InputItem>{item.name}</InputItem>
          <CrossContainer onClick={(event) => handleRemoveSelectedItem(item, event)}>
            <CrossIcon />
          </CrossContainer>
        </InputCrossContainer>
      ))}
    </>
  );
}

export default MultiSelectItemsMolecule;
