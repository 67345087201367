import React from 'react';
import { Menu } from '../../../../shared/components/integration-multi-input';
import { TabDropdownItem } from '../../../../shared/components/simple-dropdown';
import { trelloDropdownOptions } from '../../../../utils/constants';

interface Props {
  isActive: boolean,
  ref: React.MutableRefObject<null>
  // eslint-disable-next-line no-unused-vars
  onClick: (option: TabDropdownItem) => void
}

export default function TrelloMultiInputMenuItems(
  { isActive, ref, onClick }:Props,
) {
  return (
    <Menu
      data-active={isActive}
      ref={ref}
    >
      <ul>
        {
            trelloDropdownOptions.map((option) => (
              <li key={option.id}>
                <button type="button" onClick={() => onClick(option)}>{option.value}</button>
              </li>
            ))
          }
      </ul>
    </Menu>
  );
}
