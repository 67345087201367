import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import ReactTooltip from '../tooltip/ReactTooltip';

const Container = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.4;
  }
`;

interface Props {
  onClick: () => void;
}

const GiveFeedbackAtom = ({ onClick }: Props) => (
  <ReactTooltip tip="Give feedback" place="bottom">
    <Container>
      <FontAwesomeIcon onClick={onClick} icon={regular('message')} color="white" style={{ height: '20px', marginTop: '3px' }} />
    </Container>
  </ReactTooltip>
);

export default GiveFeedbackAtom;
