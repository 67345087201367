import React, {
  Dispatch, SetStateAction, useContext, useRef,
} from 'react';
import styled from 'styled-components';
import { darkBlue4 } from '../../../../shared/colors/COLORS';
import { uiText, header200 } from '../../../../shared/typography';
import { AuthContext, UserDataContext } from '../../../../App';
import NotionIntegrated from '../NotionModalComponents/NotionIntegrated';
import { IntegrationsTabView } from '../../../../shared/types/types';
import TrelloIntegrated from '../TrelloModalComponents/TrelloIntegrated';
import SlackIntegrated from '../SlackModalComponents/SlackIntegrated';

const NotIntegratedText = styled.p`
  ${uiText};
  color: ${darkBlue4};
`;

const SectionText = styled.h2`
  ${header200};
  margin-top: 0px;
`;
const Container = styled.div`
  margin-bottom: 24px;
`;

interface Props {
  isSlackIntegrated: boolean,
  isNotionIntegrated: boolean,
  setTabView: Dispatch<SetStateAction<IntegrationsTabView>>,
  isTrelloIntegrated: boolean,
}

function IntegratedApps({
  isSlackIntegrated, isNotionIntegrated, setTabView, isTrelloIntegrated,
}: Props) {
  const integratedOptionsDropdownRef = useRef(null);

  const { userId } = useContext(AuthContext);
  const { integrations } = useContext(UserDataContext);

  if (!isSlackIntegrated && !isNotionIntegrated && !isTrelloIntegrated) {
    return (
      <Container>
        <NotIntegratedText>
          No apps integrated yet! Check out what’s available below.
        </NotIntegratedText>
      </Container>
    );
  }
  return (
    <Container>
      <SectionText>Enabled</SectionText>
      <SlackIntegrated
        enable={isSlackIntegrated}
        dropDownRef={integratedOptionsDropdownRef}
        userId={userId}
        setTabView={setTabView}
      />
      <NotionIntegrated
        enable={isNotionIntegrated}
        dropDownRef={integratedOptionsDropdownRef}
        userId={userId}
      />
      <TrelloIntegrated
        enable={isTrelloIntegrated}
        dropDownRef={integratedOptionsDropdownRef}
        userId={userId}
        trelloBoard={integrations.trello}
      />
    </Container>
  );
}

export default IntegratedApps;
