import React from 'react';
import ConnectIntegrations from '../../screens/connect-integrations';
import { OnboardingScreens } from '../../utils/OnboardingConstants';
import { WelcomeScreens } from '../../utils/OnboardingTypes';
import BillingOnboardingStepCtrl from '../../screens/BillingOnboardingStep/BillingOnboardingStepCtrl';
import WorkspaceOnboardingPages from '../../../../workspace/Onboarding/WorkspaceOnboardingPage';

interface WelcomeScreensSwitcherProps {
  activeScreen: WelcomeScreens;
  // eslint-disable-next-line no-unused-vars
  navigateNextOrBack: (direction: 'next' | 'back') => void;
}

const WelcomeScreensSwitcher = ({
  activeScreen,
  navigateNextOrBack,
}: WelcomeScreensSwitcherProps) => {
  // Old onboarding screens. Commented out 24. May 2023.
  // If not needed anymore after a while, delete the comments and the code.

  // if (activeScreen === OnboardingScreens.CALENDAR_SCREEN) {
  //   return (
  //     <CalendarScreen />
  //   );
  // }

  // if (activeScreen === OnboardingScreens.MEET_SCREEN) {
  //   return (
  //     <MeetScreen />
  //   );
  // }

  // if (activeScreen === OnboardingScreens.TASKS_SCREEN) {
  //   return (
  //     <TasksScreen />
  //   );
  // }

  if (activeScreen === OnboardingScreens.INTEGRATIONS_SCREEN) {
    return (
      <ConnectIntegrations />
    );
  }

  if (activeScreen === OnboardingScreens.WORKSPACE_HOW_ARE_YOU_PLANNING_TO_USE_SHEPHERD) {
    return (
      <WorkspaceOnboardingPages navigateNextOrBack={navigateNextOrBack} />
    );
  }

  // if (activeScreen === 'video') {
  //   return (
  //     <VideoIntroduction />
  //   );
  // }

  if (activeScreen === OnboardingScreens.BILLING_SCREEN) {
    return (
      <BillingOnboardingStepCtrl />
    );
  }

  return null;
};

export default WelcomeScreensSwitcher;
