import React, { useContext } from 'react';
import { UserDataContext } from '../../App';
import { Page } from '../../shared/types/types';
import { ALL_PAGES } from '../../utils/enums';
import ScratchpadTemplate from './ScratchpadTemplate';

interface Props {
  page: Page,
}

const ScratchpadCtrl = ({ page }: Props) => {
  const userData = useContext(UserDataContext);

  if (page !== ALL_PAGES.SCRATCHPAD) return null;
  if (userData.userId.length < 10) return null;
  // We first need a privateId.
  // Should be made in App.tsx when we listen to user data
  if (userData.meta.privateId.length < 5) return null;

  const notePath = `users/${userData.userId}/scratchpad/${userData.meta.privateId}`;

  return (
    <ScratchpadTemplate notePath={notePath} />
  );
};

export default ScratchpadCtrl;
