import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface EnclosingBlockProps {
  fill?: string;
  width?: number;
  height?: number;
}

const EnclosingBlockIcon = ({
  fill,
  width,
  height,
}: EnclosingBlockProps) => (
  <svg width={width} height={height} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 0.512,3.5121 C 0.323,3.3231 0,3.457 0,3.7243 V 6.7757 C 0,7.043 0.323,7.1769 0.512,6.9879 L 2.25,5.25 Z M 0,9.75 c 0,0.4142 0.336,0.75 0.75,0.75 h 12 c 0.414,0 0.75,-0.3358 0.75,-0.75 C 13.5,9.3358 13.164,9 12.75,9 h -12 C 0.336,9 0,9.3358 0,9.75 Z m 0,-9 C 0,1.1642 0.336,1.5 0.75,1.5 h 12 C 13.164,1.5 13.5,1.1642 13.5,0.75 13.5,0.3358 13.164,0 12.75,0 h -12 C 0.336,0 0,0.3358 0,0.75 Z m 4.5,3 c 0,0.4142 0.336,0.75 0.75,0.75 h 7.5 C 13.164,4.5 13.5,4.1642 13.5,3.75 13.5,3.3358 13.164,3 12.75,3 H 5.25 C 4.836,3 4.5,3.3358 4.5,3.75 Z m 0,3 c 0,0.4142 0.336,0.75 0.75,0.75 h 7.5 C 13.164,7.5 13.5,7.1642 13.5,6.75 13.5,6.3358 13.164,6 12.75,6 H 5.25 C 4.836,6 4.5,6.3358 4.5,6.75 Z"
      fill={fill}
    />
  </svg>
);

EnclosingBlockIcon.defaultProps = {
  fill: darkBlue4,
  width: 14,
  height: 12,
};

export default EnclosingBlockIcon;
