import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import {
  addDoc, collection, deleteDoc, doc, query, updateDoc,
  onSnapshot,
} from 'firebase/firestore';
import {
  PrivateNoteAttendeeV2, PrivateNote, PrivateNoteData, ResolvedState,
} from '../shared/types/types';
import { firestore } from '../utils/firebase';
import { mapDatabaseDataToPrivateNotesData } from '../shared/components/private-notes/utils/functions';
import { CREATE_EVENT, DELETE_EVENT } from '../utils/analytics/enums';
import { logSecretChatEvents } from '../utils/analytics/eventLogger';
import { COLLECTIONS } from './FirebaseConstants';

export const dbSavePrivateNote = (
  chat: PrivateNote,
  meetingId: string,
  // eslint-disable-next-line no-unused-vars
  saveChatPath: (chatId: string) => void,
) => {
  // firestore()
  //   .collection('meetings')
  //   .doc(meetingId)
  //   .collection('secretNotes')
  //   .add(chat)
  addDoc(collection(firestore, COLLECTIONS.MEETINGS), chat)
    .then((docRef) => {
      console.log('secret chat added successfully');
      saveChatPath(docRef.id);
      return docRef.id;
    })
    .catch((error) => {
      console.log('something went wrong');
      console.log(error);
      return '';
    });
};

const orderPrivateNotesFromNewestToLatest = (
  privateNotes: PrivateNoteData[],
) => (
  privateNotes.sort((a, b) => (moment(a.created.date).isBefore(b.created.date) ? 1 : -1))
);

export const dbListenToPrivateNotesData = (
  meetingId: string,
  userId: string,
  userEmail: string,
  setSecretNotesData: Dispatch<SetStateAction<PrivateNoteData[]>>,
// ) => firestore()
//   .collection('meetings')
//   .doc(meetingId)
//   .collection('secretNotes')
//   .onSnapshot((snapshot) => {
) => {
  const q = query(collection(firestore, COLLECTIONS.MEETINGS, meetingId, 'secretNotes'));
  return onSnapshot(q, (snapshot) => {
    if (snapshot.empty) {
      setSecretNotesData([]);
      return;
    }
    const chats = mapDatabaseDataToPrivateNotesData(snapshot.docs, userId, userEmail);
    const orderedChats = orderPrivateNotesFromNewestToLatest(chats);
    setSecretNotesData(orderedChats);
  });
};

export const dbUpdatePrivateNotePath = (
  userId: string,
  meetingId: string,
  chatId: string,
  path: string,
) => {
  const updateData = { chatPathInRealtimeDb: path };
  // firestore()
  //   .collection('meetings')
  //   .doc(meetingId)
  //   .collection('secretNotes')
  //   .doc(chatId)
  //   .update(updateData)
  updateDoc(doc(firestore, COLLECTIONS.MEETINGS, meetingId, 'secretNotes', chatId), updateData)
    .then(() => {
      console.log('successfully saved secret chat path');
      logSecretChatEvents(userId, CREATE_EVENT);
    })
    .catch((error) => {
      console.log(error, 'error');
      Sentry.captureException(error);
    });
};

export const dbDeleteSecretChat = (
  userId: string,
  meetingId: string,
  secretChatId: string,
// ) => firestore()
//   .collection('meetings')
//   .doc(meetingId)
//   .collection('secretNotes')
//   .doc(secretChatId)
//   .delete()
) => deleteDoc(doc(firestore, COLLECTIONS.MEETINGS, meetingId, 'secretNotes', secretChatId))
  .then(() => {
    console.log('chat deleted successfully');
    logSecretChatEvents(userId, DELETE_EVENT);
    return 'resolved' as ResolvedState;
  })
  .catch((error) => {
    console.log(error);
    Sentry.captureException(error);
    return 'rejected' as ResolvedState;
  });

export const dbUpdatePrivateNoteTitleAndMembers = (
  updatedTitle: string,
  meetingId: string,
  chatId: string,
  updatedMembers: PrivateNoteAttendeeV2[],
) => {
  const dbData = {
    title: updatedTitle,
    members: updatedMembers,
  };
  // firestore()
  //   .collection('meetings')
  //   .doc(meetingId)
  //   .collection('secretNotes')
  //   .doc(chatId)
  //   .update(dbData)
  updateDoc(doc(firestore, COLLECTIONS.MEETINGS, meetingId, 'secretNotes', chatId), dbData)
    .then(() => {
      console.log('successfully updated secret notes members');
    })
    .catch((error) => {
      console.log(error, ' an error occured');
      Sentry.captureException(error);
    });
};
