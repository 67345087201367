import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface ChevronProps {
  fill?: string;
  width?: number;
  height?: number;
}

const ChevronIcon = ({
  fill,
  width,
  height,
}: ChevronProps) => (
  <svg width={width} height={height} viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 0,0 c -0.1246,0.126 -0.1944,0.2959 -0.1944,0.473 0,0.1772 0.0698,0.3471 0.1944,0.473 l 1.9593,1.9767 c 0.1453,0.1433 0.3333,0.2147 0.5193,0.2147 0.186,0 0.3707,-0.0714 0.5127,-0.2147 l 1.9533,-1.97 C 5.069,0.8267 5.1387,0.6568 5.1387,0.4797 5.1387,0.3027 5.069,0.1327 4.9446,0.0067 4.8834,-0.0554 4.8104,-0.1047 4.73,-0.1384 4.6495,-0.1721 4.5632,-0.1894 4.476,-0.1894 c -0.0873,0 -0.1736,0.0173 -0.2541,0.051 -0.0804,0.0337 -0.1534,0.083 -0.2146,0.1451 L 2.4753,1.5514 0.9373,0 C 0.8759,-0.0618 0.8029,-0.111 0.7225,-0.1445 0.642,-0.178 0.5558,-0.1953 0.4686,-0.1953 0.3815,-0.1953 0.2952,-0.178 0.2148,-0.1445 0.1343,-0.111 0.0613,-0.0618 0,0 Z"
      fill={fill}
    />
  </svg>
);

ChevronIcon.defaultProps = {
  fill: darkBlue4,
  width: 5,
  height: 3,
};

export default ChevronIcon;
