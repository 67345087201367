import { ResolvedPos } from 'prosemirror-model';
import { EditorState, Selection, Transaction } from 'prosemirror-state';
import { ProsemirrorNode } from 'prosemirror-suggest';
import { EditorView } from 'prosemirror-view';
import { liftAny } from '../sink-lift';

/**
 * Get the depth at which a list is located from the current ResolvedPosition
 * @param pos ResolvedPosition
 * @param depthStart start search at depth
 * @param depthEnd end search at depth
 * @returns the possible depth or -1
 */
export const getListDepth = (pos: ResolvedPos, depthStart?: number, depthEnd?: number): number => {
  const start = depthStart ?? pos.depth;
  const end = depthEnd ?? 0;
  for (let d = start; d > end; d -= 1) {
    const currentNode = pos.node(d);
    if (currentNode.type.name === 'list_item') {
      return d - 1; // this relies on the fact that there is no list_item without a list
    }
  }
  return -1;
};

// is it correct? maybe > 0 is the real answer
export const isInsideList = (selection: Selection): boolean => getListDepth(selection.$head) > -1;

export const isInsideNestedList = (selection: Selection): boolean => {
  const { $head } = selection;
  const listDepth = getListDepth(selection.$head);
  return getListDepth($head, listDepth - 1) > -1;
  // -1 again to avoid counting itself as a nested list
  // is > -1 the real answer, is it not > 0?
};

export const closestListAroundSelection = (selection: Selection): ProsemirrorNode | null => {
  const { $head } = selection;
  const depth = getListDepth($head);

  if (depth < 0) return null;
  return $head.node(depth);
  // no overhead, no calculation done twice. Really good :)
};

export const closestListItemAroundSelection = (pos: ResolvedPos): ProsemirrorNode | null => {
  for (let d = pos.depth; d > 0; d -= 1) {
    const currentNode = pos.node(d);
    if (currentNode.type.name === 'list_item') {
      return currentNode; // this relies on the fact that there is no list_item without a list
    }
  }
  return null;
};

export const nthNestedListAroundSelection = (
  selection: Selection,
  n: number,
): ProsemirrorNode | null => {
  if (n < 0) return null;

  const { $head } = selection;
  let skip: number = 1; // so that the first -1 will not ruin the search
  for (let i = 0; i < n; i += 1) { skip = getListDepth($head, skip - 1); }

  if (skip < 0) return null;
  return $head.node(skip);
};

export interface AroundListType {
  depth: number;
  node: ProsemirrorNode | null;
  nested: number;
}

export const whatListIsTheSelectionAround = (selection: Selection): AroundListType => {
  const { $head } = selection;
  const listDepth = getListDepth(selection.$head);
  if (listDepth === -1) {
    return {
      depth: -1,
      node: null,
      nested: -1,
    };
  }

  const nestedListDepth = getListDepth($head, listDepth - 1);
  return {
    depth: listDepth,
    node: $head.node(listDepth),
    nested: nestedListDepth,
    // isNested <==> nested > -1
  };
};

export const handleItemListBackspace = (
  state: EditorState,
  // eslint-disable-next-line no-unused-vars
  dispatch?: (tr: Transaction) => void,
  view?: EditorView,
) => {
  if (!view) return false;
  const { selection } = state;
  const { $head } = selection;
  const { textContent } = $head.parent;
  if (textContent.length === 0) {
    const aroundList = whatListIsTheSelectionAround(selection);
    if (aroundList.nested > -1) {
      // this is in a nested list, so we can shift tab it
      if (liftAny(view)(state, dispatch)) { return true; }
    } else if (aroundList.depth > -1) {
      // this is in the root of a big or small list, there is no other list to shift to
      if (dispatch) {
        const { tr } = state;
        dispatch(tr.deleteRange($head.pos - 1, $head.pos));
        return true;
      }
    } else {
      // this is no longer in a list
      // if preced by list this acts up
      return false;
    }
  }
  return false; // if no other case is caught, use the default backspace behavior
};
