/* eslint-disable no-undef */
/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import WorkspaceData from '../../../../database/Workspaces/WorkspaceData';
import Font from '../../../typography/Font';
import PulsingPurpleButton from '../../../../pages/all-pages/Billing/TrialEndedOfferExtensionModal/YesExtendButtonAtom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

interface WorkspaceBillingOrgProps {
  workspace: WorkspaceData,
  onClickSubscribe: () => void,
  onClickManage: () => void,
}

const WorkspaceBillingOrg = ({
  workspace,
  onClickSubscribe,
  onClickManage,
}: WorkspaceBillingOrgProps) => {
  if (!workspace.name) {
    return null;
  }

  // TODO: Actually manage the subscription if you click it

  if (workspace.isOnPremium()) {
    return (
      <Container>
        <Font font="messageText" color="darkBlue4">
          This Workspace is on the
          {' '}
          <b>Premium</b>
          {' '}
          Plan
        </Font>
        <Font
          font="messageText"
          color="blue4"
          onClick={onClickManage}
          isButton
        >
          Manage Subscription

        </Font>
      </Container>
    );
  }

  return (
    <Container>
      <Font font="messageText" color="darkBlue4">
        This Workspace is on the
        {' '}
        <b>Free</b>
        {' '}
        Plan
      </Font>
      <PulsingPurpleButton onClick={onClickSubscribe} text="Upgrade Workspace" />
    </Container>
  );
};

export default WorkspaceBillingOrg;
