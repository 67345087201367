import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import UserAPI from '../../../../database/User/UserAPI';
import CEComs from '../../../../external/ChromeExtensionCommunication/CEComs';
import ConsoleImproved from '../../../classes/ConsoleImproved';
import Font from '../../../typography/Font';
import DisabledPagesList from './DisabledPagesList';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > *:first-child {
    margin-bottom: 24px;
  }
  > *:nth-child(2) {
    margin-bottom: 24px;
  }
`;

const BackContainer = styled.div`
  margin-top: 30px;
  width: 50px;

  align-self: center;
`;

interface Props {
  onGoBack: () => void;
}

const BlackListDomainsSettings = ({ onGoBack }: Props) => {
  const userData = useContext(UserDataContext);

  const handleRemoveDisableSite = (site: string) => {
    ConsoleImproved.log('Remove site', site);
    CEComs.removeBlacklistedDomains([site]);
    UserAPI.Settings.removeDisabledDomain(userData.userId, site);
  };
  const handleGoBack = () => onGoBack();

  return (
    <Container>
      <Font font="header400" color="gray9">Chrome Extension</Font>
      <Font font="messageText">Shepherd is currently disabled for these sites</Font>
      <DisabledPagesList
        disabledSites={userData.settings.disabledDomains}
        onRemoveDisabledSite={handleRemoveDisableSite}
      />
      <BackContainer>
        <Font font="messageText" color="blue4" onClick={handleGoBack} isButton>Back</Font>
      </BackContainer>
    </Container>
  );
};

export default BlackListDomainsSettings;
