import { Invite } from '../../types/types';

class InviteClass implements Invite {
  type: 'workspace' | 'meeting';

  identifiers: {
    workspaceId: string;
    workspaceName: string;
    workspaceAdmin: string;
    meetingId: string;
  };

  invitedEmail: string;

  constructor(data: any) {
    this.type = data?.type || 'workspace';
    this.identifiers = data?.identifiers || {
      workspaceId: '',
      workspaceName: '',
      workspaceAdmin: '',
      meetingId: '',
    };
    this.invitedEmail = data?.invitedEmail || '';
  }

  static createDummyWorkspaceInvite = (workspaceName: string) => {
    const invite = new InviteClass({
      type: 'workspace',
      identifiers: {
        workspaceId: '',
        workspaceName,
        workspaceAdmin: '',
        meetingId: '',
      },
      invitedEmail: '',
    });
    return invite;
  }
}

export default InviteClass;
