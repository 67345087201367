import React from 'react';

interface Props {
  fill?: string,
}

const EditSquareIcon = ({ fill }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.19289 10.2375L15.5424 2.9002C16.746 1.69993 18.6966 1.69993 19.9001 2.9002L21.0972 4.09534C22.3009 5.29702 22.3009 7.246 21.0972 8.44638L13.7126 15.8188C13.1739 16.3566 12.4434 16.6589 11.6815 16.6589H7.99751C7.58297 16.6589 7.24985 16.3179 7.26015 15.9042L7.3526 12.1927C7.37114 11.4577 7.67186 10.7577 8.19289 10.2375ZM20.0542 5.13671L18.8576 3.94213C18.23 3.31624 17.2125 3.31624 16.5852 3.94185L15.9806 4.54543L19.4496 8.0086L20.0544 7.40474C20.6816 6.77924 20.6816 5.76316 20.0542 5.13671ZM18.4065 9.04996L14.9375 5.5868L9.23598 11.2789C8.98257 11.5319 8.83631 11.8724 8.8273 12.2296L8.75298 15.1853L11.6815 15.1862C12.0057 15.1862 12.3183 15.0736 12.567 14.8701L12.6695 14.7774L18.4065 9.04996ZM11.3348 2.0379C11.7422 2.0379 12.0724 2.36758 12.0724 2.77425C12.0724 3.14704 11.7949 3.45513 11.4349 3.50389L11.3348 3.51061H7.65773C5.17793 3.51061 3.5717 5.15289 3.47937 7.70324L3.47516 7.93758V16.1003C3.47516 18.7086 5.00328 20.4241 7.43426 20.5228L7.65773 20.5273H16.3356C18.8216 20.5273 20.4229 18.8885 20.515 16.335L20.5192 16.1003V12.1456C20.5192 11.7389 20.8494 11.4092 21.2567 11.4092C21.6301 11.4092 21.9387 11.6863 21.9876 12.0457L21.9943 12.1456V16.1003C21.9943 19.4994 19.8376 21.8894 16.5657 21.9963L16.3356 22H7.65773C4.33531 22 2.10323 19.6871 2.00349 16.3358L2 16.1003V7.93758C2 4.54179 4.16225 2.14866 7.4281 2.04164L7.65773 2.0379H11.3348Z" fill={fill} />
  </svg>
);

EditSquareIcon.defaultProps = {
  fill: 'white',
};

export default EditSquareIcon;
