import React from 'react';
import { gray1 } from '../colors/COLORS';

interface Props {
  fill?: string
  width?: string
  height?: string
}
const OpenLinkIcon = ({ fill, width, height }: Props) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 11V3M20 3H12M20 3L10 13" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M19 15V15C19 16.8692 19 17.8038 18.5981 18.5C18.3348 18.9561 17.9561 19.3348 17.5 19.5981C16.8038 20 15.8692 20 14 20H9C6.17157 20 4.75736 20 3.87868 19.1213C3 18.2426 3 16.8284 3 14V9C3 7.13077 3 6.19615 3.40192 5.5C3.66523 5.04394 4.04394 4.66523 4.5 4.40192C5.19615 4 6.13077 4 8 4V4" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
  </svg>

);

OpenLinkIcon.defaultProps = {
  fill: gray1,
  width: '16',
  height: '16',
};

export default OpenLinkIcon;
