import styled from 'styled-components';
import { darkBlue1 } from '../../../../../shared/colors/COLORS';
import { header200 } from '../../../../../shared/typography';

export const ModalLineLocal = styled.div<{ bottomMargin?: boolean}>`
  background: ${darkBlue1};
  height: 2px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: ${(p) => (p.bottomMargin ? 24 : 0)}px;
`;
export const SectionText = styled.h2`
  ${header200};
  margin-bottom: 16px;
`;

export const EnterEmailRecipientsAtom = styled.span`
  ${header200};
`;
