import React from 'react';
import styled from 'styled-components';
import { StripeConstants } from '../../../../../external/Stripe/StripeConstants';
import ButtonOutline from '../../../../../shared/components/buttons/ButtonOutline/ButtonOutline';
import Font from '../../../../../shared/typography/Font';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 17px;

  border-radius: 8px;
  width: 100%;
  height: 50px;

  box-shadow: 0px 2px 8px rgba(27, 33, 36, 0.16);

  #freeTrial {
    font-weight: bold;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > *:first-child {
    font-size: 11px;
  }
  > *:last-child {
    font-size: 10px;
  }

`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;

  > *:first-child {
    margin-right: 8px;
  }
  > *:last-child {
    font-size: 12px;
  }
`;

interface StartTrialCardMolProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onClickTrial: () => void,
}

const StartTrialCardMol = ({
  onClickTrial,
}: StartTrialCardMolProps) => (
  <Container>
    <TextContainer>
      <Font font="defaultSmall" id="freeTrial">
        Start
        {' '}
        {StripeConstants.TRIAL_LENGTH}
        -day free trial
      </Font>
      <Font font="defaultSmall" color="gray7">
        No credit card details required
      </Font>
    </TextContainer>
    <ButtonContainer>
      <ButtonOutline padding="8px 8px" onClick={onClickTrial}>
        Start Trial
      </ButtonOutline>
    </ButtonContainer>
  </Container>
);

export default StartTrialCardMol;
