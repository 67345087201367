import styled from 'styled-components';
import { gray3, gray7, surface } from '../../../../../../shared/colors/COLORS';
import { uiTextS } from '../../../../../../shared/typography';

interface SelectedOptionsContainerProps {
  hasInput: boolean
}

export const SelectedOptionsContainer = styled.div<SelectedOptionsContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;

  padding: 0px;
  width: 405px;
  overflow-x: scroll;
  white-space: nowrap;

  ${uiTextS};
  color: ${({ hasInput }) => (hasInput ? surface : gray7)};

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

export const SelectedOption = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  column-gap: 4px;

  padding: 2px 8px;
  border-radius: 6px;

  background: ${gray3};
  ${uiTextS};
  white-space: nowrap;
`;

export const SelectedOptionCloseButton = styled.div`
  all: unset;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;
