import React from 'react';
import * as Sentry from '@sentry/browser';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../utils/firebase';
import { ResolvedState, TrelloData } from '../shared/types/types';
import { defaultTrelloData, mapTrelloWebhookURLToVersionNo } from '../utils/trello/trelloUtils';
import { toastSuccess } from '../utils/notifications';
import { DISABLE_EVENT, TRELLO_INTEGRATION } from '../utils/analytics/enums';
import { logIntegrationsEvent } from '../utils/analytics/eventLogger';
import CloudFunctions from './CloudFunctions';

export const dbSaveTrelloData = async (
  userId: string,
  trelloData: TrelloData,
  setPromise: React.Dispatch<React.SetStateAction<ResolvedState>>,
) => {
  const trelloDataToSave = trelloData;
  // TODO Matt: Notice that the function is not actually returning anything.
  // await functions()
  //   .httpsCallable('subscribeTrelloWebhook')({ accessToken:
  //  trelloDataToSave.accessToken, idModel: trelloDataToSave.board.boardId })
  await CloudFunctions().subscribeTrelloWebhook(
    { accessToken: trelloDataToSave.accessToken, idModel: trelloDataToSave.board.boardId },
  )
    .then((data: any) => {
      trelloDataToSave.webhookId = data.data?.id ?? '';
      trelloDataToSave.version = mapTrelloWebhookURLToVersionNo(data.data.callbackURL);
    }).catch((error) => {
      console.log('trello board not set yet', JSON.parse(error));
    })
    .finally(() => {
      const dbData = {
        'integrations.trello': trelloDataToSave,
      };
      updateDoc(doc(firestore, 'users', userId), dbData)
        .then(() => {
          console.log('trello data saved successfully');
          toastSuccess('Success', 'Trello settings saved');
          setPromise('resolved');
        })
        .catch((error) => {
          console.log('trello data not saved');
          console.log(error);
          setPromise('rejected');
          Sentry.captureException(error);
        });
    });
};

export const removeTrelloIntegration = (userId: string) => {
  const defaultChannelData = {
    'integrations.trello': defaultTrelloData,
  };
  updateDoc(doc(firestore, 'users', userId), defaultChannelData)
    .then(() => {
      console.log('Successfully removed trello data from user data');
      logIntegrationsEvent(userId, DISABLE_EVENT, TRELLO_INTEGRATION);
    })
    .catch((error) => {
      console.log(error);
    });
};
