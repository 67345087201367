import styled from 'styled-components';
import {
  surface, gray1, layoutGrey, gray9,
} from '../../../../../../shared/colors/COLORS';
import { uiText as textFont } from '../../../../../../shared/typography';

const ButtonStyle = styled.button`
  ${textFont};
  color: ${gray1};

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${surface};

  border: none;
  border-radius: 10px;

  height: 48px;
  width: 100%;

  &:hover {
    cursor: pointer;
    background: ${gray9};
  }

  &:disabled {
    background: ${layoutGrey};
    cursor: unset;
  }

  svg {
    margin-right: 32px;
    height: 24px;
  }
`;

export default ButtonStyle;
