import React from 'react';
import styled from 'styled-components';
import {
  gray9, gray5, surface,
} from '../../../colors/COLORS';
import Arrow from '../../../icons/ArrowIcon';
import { uiText } from '../../../typography';

const ShortcutKeyboardSquareButtonContainer = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${uiText};
  color: ${gray9};

  background-color: ${gray5};
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.3), inset 0px -4px 4px rgba(0, 0, 0, 0.1);

  border-radius: 8px;

  &:hover{
    cursor: default;
  }
`;

const ShortcutKeyboardFlexibleButtonContainer = styled.div`
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${uiText};
  color: ${gray9};

  border-radius: 8px;

  background-color: ${gray5};
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.3), inset 0px -4px 4px rgba(0, 0, 0, 0.1);

  padding: 6px 8px;

  &:hover{
    cursor: default;
  }
`;

const SimpleTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${uiText}
  color: ${gray9};

  white-space: nowrap;

  &:hover{
    cursor: default;
  }
`;

interface ShortcutCommandButtonProps {
  command: string
}

const ShortcutCommandButton = ({
  command,
}:ShortcutCommandButtonProps) => {
  if (command === 'right-arrow') {
    return (
      <ShortcutKeyboardSquareButtonContainer>
        <Arrow fill={surface} direction="right" width="6px" height="auto" />
      </ShortcutKeyboardSquareButtonContainer>
    );
  }

  if (command === 'left-arrow') {
    return (
      <ShortcutKeyboardSquareButtonContainer>
        <Arrow fill={surface} direction="left" width="6px" height="auto" />
      </ShortcutKeyboardSquareButtonContainer>
    );
  }

  if (command === 'up-arrow') {
    return (
      <ShortcutKeyboardSquareButtonContainer>
        <Arrow fill={surface} direction="up" width="6px" height="auto" />
      </ShortcutKeyboardSquareButtonContainer>
    );
  }

  if (command === 'down-arrow') {
    return (
      <ShortcutKeyboardSquareButtonContainer>
        <Arrow fill={surface} direction="down" width="6px" height="auto" />
      </ShortcutKeyboardSquareButtonContainer>
    );
  }

  if (command === 'Spacebar'
  || command === 'Tab'
  || command === 'Alt'
  || command === 'Ctrl'
  || command === 'Shift'
  || command === 'Home'
  || command === 'End'
  || command === 'Option') {
    return (
      <ShortcutKeyboardFlexibleButtonContainer>
        {command}
      </ShortcutKeyboardFlexibleButtonContainer>
    );
  }

  if (command === 'Double-click'
  || command === 'Triple-click'
  || command === 'Username'
  || command === 'Gif'
  || command === 'Keyword'
  || command === 'Time') {
    return (
      <SimpleTextContainer>
        {command}
      </SimpleTextContainer>
    );
  }

  return (
    <ShortcutKeyboardSquareButtonContainer>
      {command}
    </ShortcutKeyboardSquareButtonContainer>
  );
};

export default ShortcutCommandButton;
