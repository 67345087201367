import { useState, useEffect } from 'react';
import { User } from '../../shared/types/types';

const useFirstUserLoadEffect = (callback: any, deps: [userData: User]) => {
  const [isTriggered, setIsTriggered] = useState(false);

  useEffect(() => {
    const userData = deps[0];
    if (isTriggered) return;
    if (userData.resolvedState === 'resolved') {
      setIsTriggered(true);
      callback();
    }
  }, deps);
};

export default useFirstUserLoadEffect;
