/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import InviteButtonAtom from '../../invite-members-modal/InviteButtonAtom';
import TextInputAtom from '../../invite-members-modal/TextInputAtom';
import ResolvedStateSmartExpanded from '../../../../utils/ResolvedState/ResolvedStateSmartExpanded';
import EmailValidator from '../../../classes/EmailValidator';
import { COLORS } from '../../../colors/COLORS';

const InputFormContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

const ResetButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;

  :hover {
    background-color: ${COLORS.gray4};
    cursor: pointer;
  }
`;

interface InviteByEmailInputProps {
  onClickInvite: (email: string) => void,
  status: ResolvedStateSmartExpanded,
  onReset: () => void,
  showReset?: boolean,
}

const InviteByEmailInput = ({
  onClickInvite, status, onReset,
  showReset = true,
}: InviteByEmailInputProps) => {
  const [email, setEmail] = useState('');

  const handleClickInvite = () => {
    console.log('InviteByEmailInput: handleClickInvite: email:', email);
    onClickInvite(email);
  };

  const handleReset = () => {
    setEmail('');
    onReset();
  };

  useEffect(() => {
    if (status.isNull) { setEmail(''); }
  }, [status]);

  const isDisabledFromStatus = status.isResolved || status.isPending;
  const isEmailValid = EmailValidator.validate(email);

  return (
    <InputFormContainer>
      <TextInputAtom
        value={email}
        placeholder="Type email address"
        onEnterKeyPress={handleClickInvite}
        onChange={setEmail}
      />
      <InviteButtonAtom
        isDisabled={isDisabledFromStatus || !isEmailValid}
        onClick={handleClickInvite}
        status={status}
      />
      {showReset && (
        <ResetButtonContainer onClick={handleReset}>
          <FontAwesomeIcon icon={regular('circle-xmark')} />
        </ResetButtonContainer>
      )}
    </InputFormContainer>
  );
};

export default InviteByEmailInput;
