import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface EmojiProps {
  fill?: string;
  width?: number;
  height?: number;
}

const EmojiIcon = ({
  fill,
  width,
  height,
}: EmojiProps) => (
  <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 8.25,0 C 3.694,0 0,3.6937 0,8.25 0,12.8063 3.694,16.5 8.25,16.5 12.807,16.5 16.5,12.8063 16.5,8.25 16.5,3.6937 12.807,0 8.25,0 Z m 0,1.5 C 11.978,1.5 15,4.5221 15,8.25 15,11.9779 11.978,15 8.25,15 4.522,15 1.5,11.9779 1.5,8.25 1.5,4.5221 4.522,1.5 8.25,1.5 Z M 6,5.75 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z m 4.5,0 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z m -5.32031,4.566406 c -0.21612,-0.0051 -0.4335,0.08365 -0.58594,0.259766 -0.271,0.3131 -0.23683,0.787394 0.07617,1.058594 0.476,0.4119 1.07106,0.684268 1.66406,0.855468 C 6.93898,12.664934 7.598,12.75 8.25,12.75 c 0.652,0 1.31102,-0.08507 1.91602,-0.259766 0.593,-0.1712 1.18806,-0.443568 1.66406,-0.855468 0.313,-0.2712 0.34717,-0.745494 0.07617,-1.058594 C 11.63525,10.263072 11.16066,10.2288 10.84766,10.5 10.58466,10.728 10.206,10.917128 9.75,11.048828 9.294,11.180428 8.777,11.25 8.25,11.25 7.724,11.25 7.206,11.180428 6.75,11.048828 6.294,10.917128 5.91534,10.728 5.65234,10.5 5.51541,10.38135 5.34778,10.320407 5.17969,10.316406 Z"
      fill={fill}
    />
  </svg>
);

EmojiIcon.defaultProps = {
  fill: darkBlue4,
  width: 17,
  height: 17,
};

export default EmojiIcon;
