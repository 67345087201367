import React from 'react';
import DownArrow from '../../../../../shared/icons/downArrow';
import UpArrow from '../../../../../shared/icons/UpArrow';

interface DropdownInputFieldArrowProps {
  isDropdownOpen: boolean,
}

const DropdownInputFieldArrow = ({ isDropdownOpen }: DropdownInputFieldArrowProps) => {
  if (isDropdownOpen) return <UpArrow />;

  return <DownArrow />;
};

export default DropdownInputFieldArrow;
