import { some } from 'lodash';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
// import { getStringSimilarity } from '../../../utils/strings';
import DropdownInputFieldMolecule from './molecules/DropdownInputFieldMolecule';
import DropdownOptionMolecule from './molecules/DropdownOptionMolecule';
import { DropdownItem, removeItem } from './utils';

interface Props{
  selectedItems?: DropdownItem[],
  setSelectedItems?: React.Dispatch<DropdownItem[]>,
  onDropdownClick?: () => void,
  dropdownItems: DropdownItem[],
  isMultiSelect: boolean,
  isLoading?: boolean,
  isError?: boolean,
  placeholderText: string,
  inputText:string,
  // eslint-disable-next-line no-unused-vars
  setInputText: React.Dispatch<React.SetStateAction<string>>,
  // eslint-disable-next-line no-unused-vars
  onOptionClick: (item: DropdownItem)=> void
}

const Container = styled.div``;

function DropDownWithInputControl({
  selectedItems = [], setSelectedItems = () => {}, onDropdownClick = () => {}, dropdownItems,
  inputText, setInputText,
  isMultiSelect, placeholderText, onOptionClick, isLoading = false, isError = false,
}:Props) {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  // const [filteredDropdownItems,
  //   setFilteredDropdownItems] = useState<DropdownItem[]>(dropdownItems);

  // useEffect(() => {
  //   if (inputText.length === 0) {
  //     setFilteredDropdownItems(dropdownItems);
  //     return;
  //   }
  //   const filteredItems = dropdownItems.filter(
  //     (item) => item.name.toLowerCase()
  //       .indexOf(inputText.toLowerCase()) !== -1,
  //   );
  //   setFilteredDropdownItems(filteredItems);
  // }, [inputText, dropdownItems]);

  useEffect(() => {
    if (!isActive) return;
    onDropdownClick();
  }, [isActive]);

  const OnClick = (item:any) => {
    setInputText('');
    if (isMultiSelect) {
      if (some(selectedItems, { name: item.name, id: item.id })) {
        const filteredItems = removeItem(selectedItems, item);
        setSelectedItems(filteredItems);
        return;
      }
      setSelectedItems([...selectedItems, item]);
      return;
    }
    setIsActive(false);
    onOptionClick(item);
  };

  return (
    <Container ref={dropdownRef}>
      <DropdownInputFieldMolecule
        inputText={inputText}
        setInputText={setInputText}
        isActive={isActive}
        setIsActive={setIsActive}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        isMultiSelect={isMultiSelect}
        placeholderText={placeholderText}
      />
      <DropdownOptionMolecule
        isError={isError}
        isLoading={isLoading}
        inputText={inputText}
        isActive={isActive}
        selectedItems={selectedItems}
        dropdownItems={dropdownItems}
        onItemClick={OnClick}
      />
    </Container>
  );
}

DropDownWithInputControl.defaultProps = {
  selectedItems: [],
  setSelectedItems: () => {},
  onDropdownClick: () => {},
  isLoading: false,
  isError: false,
};

export default DropDownWithInputControl;
