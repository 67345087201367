import styled from 'styled-components';
import { blue6, darkBlue4, green6 } from '../../../../../shared/colors/COLORS';
import { uiTextMedium, header200 } from '../../../../../shared/typography';

export const IntegratedContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AppContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`;
export const MenuActiveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActiveText = styled.h2`
  ${uiTextMedium};
  color: ${green6};
  margin-left: 4px;
  margin-right: 28px;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  cursor: pointer;
`;

export const NameText = styled.h2`
 ${header200};
 margin-left: 16px;
`;

export const IntegrationsContainer = styled.div``;

export const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div:not(:last-child){
  margin-bottom: 8px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IntegrationText = styled.h2`
  ${header200};
  margin-left: 16px;
`;

export const Logo = styled.img`
  height: 28px;
  width: 28px;
`;

export const EnableButton = styled.button`
  background: none;
  border: none;
  color: ${blue6};
  ${uiTextMedium};
  cursor: pointer;
`;

export const CrossIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const ComingSoon = styled.h2`
  ${uiTextMedium};
  color: ${darkBlue4};
`;

export const IntegrationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
