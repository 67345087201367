import React from 'react';

interface Props {
  // eslint-disable-next-line no-unused-vars
  onClick?: (event: any) => void,
}

const DeleteCrossIcon = ({ onClick }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <path d="M12.4698 6.12762C12.8574 5.74001 13.4858 5.74001 13.8734 6.12762C14.2258 6.47999 14.2578 7.0314 13.9695 7.41996L13.8734 7.53128L7.53177 13.8729C7.14416 14.2605 6.51572 14.2605 6.12811 13.8729C5.77574 13.5206 5.7437 12.9691 6.03201 12.5806L6.12811 12.4693L12.4698 6.12762Z" fill="#595959" />
    <path d="M6.12415 6.12413C6.47656 5.77179 7.02797 5.73982 7.4165 6.02816L7.52781 6.12428L13.8748 12.4725C14.2623 12.8602 14.2623 13.4886 13.8746 13.8762C13.5222 14.2285 12.9708 14.2605 12.5823 13.9722L12.4709 13.8761L6.12401 7.52779C5.73644 7.14014 5.7365 6.5117 6.12415 6.12413Z" fill="#595959" />
  </svg>

);

DeleteCrossIcon.defaultProps = {
  onClick: (event: any) => { console.log(event); },
};

export default DeleteCrossIcon;
