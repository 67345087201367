import React from 'react';
import Font from '../../../../shared/typography/Font';
import OnboardingWelcomeScreensModel from '../../../onboarding/welcome/utils/onboarding-welcome-screens-model';

const WatchDemoText = () => {
  const { videoSrc } = OnboardingWelcomeScreensModel.tutorialVideo;
  return (
    <Font font="uiTextS" color="gray7" id="announcement">
      Watch
      {' '}
      <a href={videoSrc} target="_blank" rel="noreferrer">this</a>
      {' '}
      demo to learn how to get the most value
      out of Shepherd
    </Font>
  );
};

export default WatchDemoText;
