import Firepad from 'firepad';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage, ref as storageRef } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import * as Sentry from '@sentry/browser';
import { MEETING_SECTION } from './enums';
import { MeetingSection, TextEditor } from '../shared/types/types';
import CloudFunctions from '../database/CloudFunctions';

const firebaseConfig = {
  apiKey: 'AIzaSyCdYthlXdgBfDokonqdb0okI8xG3oLZtZc',
  authDomain: 'shepherd-mvp.firebaseapp.com',
  databaseURL: 'https://shepherd-mvp.firebaseio.com',
  projectId: 'shepherd-mvp',
  storageBucket: 'shepherd-mvp.appspot.com',
  messagingSenderId: '232311426352',
  appId: '1:232311426352:web:5fadb4ea0ddf3b72ac0cf5',
  measurementId: 'G-B4GK90WM5M',
};
export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const euFunctions = getFunctions(app, 'europe-west1'); // Cloud Functions
// connectFunctionsEmulator(euFunctions, '127.0.0.1', 5001);
export const database = getDatabase(app);
export const storage = getStorage(app);
export const performance = getPerformance(app);
export const analytics = getAnalytics();
export const auth = getAuth(app);

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }
// Apparently, just be requiring cors will fix some cors issues
// eslint-disable-next-line no-unused-vars
const cors = require('cors')({ origin: true });

// export const {
//   auth, functions, firestore, database, analytics, performance, storage,
// } = firebase;

// TODO some functions should be placed else where

export const getFirepadPath = (noteTab: MeetingSection, meetingId: string, userId?: string) => {
  switch (noteTab) {
    case MEETING_SECTION.AGENDA:
      return `/meetings/${meetingId}/agenda_Firepad`;
    case MEETING_SECTION.PERSONAL_NOTES:
      return `users/${userId}/meetings/${meetingId}/privateNotes_Firepad`;
    case MEETING_SECTION.SHARED_NOTES:
      return `/meetings/${meetingId}/sharedNotes_Firepad`;
    default:
      console.log('Unexpected behavior when getting firepad path');
      return '';
  }
};

export const getFirepadPathV2 = (
  noteTab: MeetingSection, meetingId: string, textEditor: TextEditor, userId?: string,
) => {
  const suffix = textEditor === 'firepad' ? '_Firepad' : '_Prosemirror';
  switch (noteTab) {
    case MEETING_SECTION.AGENDA:
      return `/meetings/${meetingId}/agenda${suffix}`;
    case MEETING_SECTION.PERSONAL_NOTES:
      return `users/${userId}/meetings/${meetingId}/privateNotes${suffix}`;
    case MEETING_SECTION.SHARED_NOTES:
      return `/meetings/${meetingId}/sharedNotes${suffix}`;
    default:
      console.log('Unexpected behavior when getting firepad path');
      return '';
  }
};

export const getFirepadPathV3 = (
  noteTab: MeetingSection, meetingId: string, textEditor: TextEditor, userId?: string,
) => {
  // TODO change these paths to be relevant room names
  const suffix = textEditor === 'firepad' ? '_Firepad' : '_Prosemirror';
  switch (noteTab) {
    case MEETING_SECTION.AGENDA:
      return `${meetingId}_agenda_${suffix}`;
    case MEETING_SECTION.PERSONAL_NOTES:
      return `${meetingId}_${userId}_private_${suffix}`;
    case MEETING_SECTION.PRIVATE_NOTES: // ? is this related to getFirepadSecretChatPath?
      return `${meetingId}_${userId}_private_${suffix}`;
    case MEETING_SECTION.SHARED_NOTES:
      return `${meetingId}_shared_${suffix}`;
    default:
      console.log('Unexpected behavior when getting firepad path');
      return '';
  }
};

export const getFirepadSecretChatPath = (
  secretChatId: string, textEditor: TextEditor,
) => {
  const suffix = textEditor === 'firepad' ? '_Firepad' : '_Prosemirror';
  return `secretNotes/${secretChatId}${suffix}`;
};

// Old Version
// export const cfSendMeetingNotes = (
//   emails: { email: string }[], meetingTitle: string, html: string, templateId: string,
// ) => functions()
//   .httpsCallable('sendMeetingNotes')({
//     emails, meetingTitle, html, templateId,
//   });
// === New Version with Module
export const cfSendMeetingNotes = (
  emails: { email: string }[], meetingTitle: string, html: string, templateId: string,
) => CloudFunctions().sendMeetingNotes({
  emails, meetingTitle, html, templateId,
});

export const cfSendQuickFeedback = (
  email: string, feedback: string,
) => CloudFunctions().sendQuickFeedback({
  email, feedback,
})
  .catch((error) => {
    Sentry.captureException(error);
    throw new Error(error);
  });

/** Realtime Database */
export const getDbRef = (path: string) => ref(database, path);

export const getStorageRef = (path: string) => storageRef(storage, path);

export const createHeadlessFirepad = (
  noteTab: MeetingSection, meetingId: string, userId?: string,
) => new Firepad.Headless(getDbRef(getFirepadPath(noteTab, meetingId, userId)));
