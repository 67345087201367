import { Dispatch, SetStateAction } from 'react';
import { getMeetingIdByEventIdAndCalendarId } from '../../pages/googleCalendar';
import EmailValidator from '../../shared/classes/EmailValidator';
import { AuthState, MeetingsObject } from '../../shared/types/types';
import { MeetingData } from '../../shared/types/MeetingData';
import UrlUtils from '../../utils/urlUtils/UrlUtils';
import { MEETING_PATH } from '../FirebaseConstants';
import { rejectedMeetingData } from '../utils/templateMeetingData';
import { dbFindAndNavigateToMeetingByMeetId } from './firebaseMeetingAPI';
import FirestoreMeetingsCore from './FirestoreMeetingsCore';
import { MeetingUtils } from '../../utils/meetings/meetingsUtils';
import { HTML_LINK_LENGTH } from '../../utils/constants';

class FirestoreMeetings extends FirestoreMeetingsCore {
  static getMeetingByMeetingId = async (
    meetingId: string, userId: string,
  ): Promise<MeetingData> => {
    if (meetingId.length === 0) return rejectedMeetingData;
    return FirestoreMeetingsCore.getMeetingDataByMeetingIdCore(meetingId, userId);
  }

  static listenAndSetMyMeetingsByCalendarIds(
    calendarIds: string[],
    userId: string,
    userEmail: string,
    setMeetings: Dispatch<SetStateAction<MeetingsObject>>,
  ): () => void {
    const promises = calendarIds
      .filter(MeetingUtils.filterOutAddressBook)
      .map((calendarId) => FirestoreMeetings
        .listenAndSetMeetingsByCalendarId(calendarId, userId, setMeetings));

    const anotherPromise = FirestoreMeetings.listenToMeetingsWhereImInvitedTo(
      userId, userEmail, setMeetings,
    );
    const unsubscribeFunction = () => {
      promises.forEach((unsubscribe) => unsubscribe()); anotherPromise();
    };
    return unsubscribeFunction;
  }

  static listenAndSetMeetingsByCalendarId(
    calendarId: string,
    userId: string,
    setMeetings: Dispatch<SetStateAction<MeetingsObject>>,
  ): () => void {
    if (calendarId.length === 0) return () => { };
    return this.listenAndSetMeetingsByCalendarIdCore(calendarId, userId, setMeetings);
  }

  static listenToMeetingsWhereImInvitedTo(
    userId: string, userEmail: string, setMeetings: Dispatch<SetStateAction<MeetingsObject>>,
  ): () => void {
    if (!EmailValidator.validate(userEmail,
      FirestoreMeetings.listenToMeetingsWhereImInvitedTo.name)) return () => { };
    return this.listenToMeetingsWhereImInvitedToCore(userId, userEmail, setMeetings);
  }

  static paginateMeetingsByCalendarId(calendarId: string) {
    return this.paginateMeetingsByCalendarIdCore(calendarId);
  }

  /**
   * @param meetingId Meeting id
   * @param googleAttendees Attendees from google, with email property
   */
  static updateAttendees(meetingId: string, googleAttendees: any[]) {
    const attendeeEmails = googleAttendees
      .map((attendee) => attendee.email)
      .filter((email) => EmailValidator.validate(email, FirestoreMeetings.updateAttendees.name));

    return FirestoreMeetingsCore.updateAttendeesCore(meetingId, attendeeEmails);
  }

  /**
   * @param meetingId Meeting id
   * @param newHtmlLink New html link, should only be id part and of length 52
   */
  static updateHtmlLink(meetingId: string, newHtmlLink: string) {
    if (newHtmlLink.length === 0) return Promise.resolve();
    const update = {
      [MEETING_PATH.googleData.ids.htmlLink]: newHtmlLink.substring(0, HTML_LINK_LENGTH),
    };

    return FirestoreMeetingsCore.updateMeeting(meetingId, update);
  }

  static listenToMyTag = () => FirestoreMeetingsCore.listenToMyTagsCore('haraldTag');

  static listenToMeetingsByTagId = (tagId: string) => FirestoreMeetingsCore
    .listenToMeetingsByTagIdCore(tagId);

  /**
   * Not in use atm.
   */
  static getMeetingByDataEventId = async (dataEventId: string) => FirestoreMeetingsCore
    .getMeetingByDataEventIdCore(dataEventId);

  static isCurrentMeetingTheSameAsTheNewWeTryToNavigateTo = async (
    newUrl: string, authState: AuthState, currentPath: string,
  ): Promise<{ isCurrentMeeting: boolean, meetingId: string }> => {
    if (newUrl.length === 0) return { isCurrentMeeting: false, meetingId: '' };
    if (currentPath.length === 0) return { isCurrentMeeting: false, meetingId: '' };
    if (newUrl.includes('google-calendar')) {
      const { eventId, calendarId } = UrlUtils.getEventIdAndCalendarIdFromUrl(newUrl);
      if (eventId.isNotValid()) {
        return { isCurrentMeeting: false, meetingId: '' };
      }

      const meetingId = await getMeetingIdByEventIdAndCalendarId(eventId.value, calendarId.value);

      if (
        meetingId?.length > 0
        && currentPath.includes(meetingId)) {
        return { isCurrentMeeting: true, meetingId };
      }

      return { isCurrentMeeting: false, meetingId };
    }

    if (newUrl.includes('google-meet')) {
      const meetId = UrlUtils.getMeetIdFromUrl(newUrl);
      const meetingId = await dbFindAndNavigateToMeetingByMeetId(
        meetId, authState.userId, () => { }, () => { }, authState,
      );
      if (
        meetingId?.length > 0
        && currentPath.includes(meetingId)) {
        return { isCurrentMeeting: true, meetingId };
      }

      return { isCurrentMeeting: false, meetingId };
    }
    return { isCurrentMeeting: false, meetingId: '' };
  }

  static getMeetingsByDataEventIds = async (dataEventIds: string[]) => {
    const result = FirestoreMeetingsCore.coreGetMeetingsByDataEventIds(dataEventIds);
    return result;
  }
}

export default FirestoreMeetings;
