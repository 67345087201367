import React from 'react';

interface Props{
  width?: string
}

const GoogleCalendarLogo = ({ width = '40' }:Props) => (
  <svg width={width} height={width} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.5945 10.2573H10.2573V29.5946H29.5945V10.2573Z" fill="white" />
    <path d="M29.5947 38.2965L38.2965 29.5947H29.5947V38.2965Z" fill="#EA4335" />
    <path d="M38.2965 10.2573H29.5947V29.5946H38.2965V10.2573Z" fill="#FBBC04" />
    <path d="M29.5945 29.5947H10.2573V38.2965H29.5945V29.5947Z" fill="#34A853" />
    <path d="M1.55566 29.5947V35.3959C1.55566 36.9985 2.85368 38.2965 4.45625 38.2965H10.2574V29.5947H1.55566Z" fill="#188038" />
    <path d="M38.2965 10.2574V4.45625C38.2965 2.85368 36.9985 1.55566 35.3959 1.55566H29.5947V10.2574H38.2965Z" fill="#1967D2" />
    <path d="M29.5947 1.55566H4.45625C2.85368 1.55566 1.55566 2.85368 1.55566 4.45625V29.5947H10.2574V10.2574H29.5947V1.55566Z" fill="#4285F4" />
    <path d="M14.2238 25.2584C13.501 24.7701 13.0007 24.0571 12.7275 23.1144L14.405 22.4231C14.5573 23.0032 14.8232 23.4528 15.2027 23.7719C15.5798 24.0909 16.039 24.248 16.5756 24.248C17.1243 24.248 17.5957 24.0812 17.9897 23.7477C18.3837 23.4141 18.5819 22.9887 18.5819 22.4738C18.5819 21.9469 18.374 21.5166 17.9583 21.1831C17.5425 20.8495 17.0204 20.6827 16.3968 20.6827H15.4275V19.0221H16.2977C16.8343 19.0221 17.2863 18.8771 17.6537 18.5871C18.0211 18.297 18.2048 17.9006 18.2048 17.3954C18.2048 16.9458 18.0404 16.5881 17.7117 16.3198C17.383 16.0515 16.9672 15.9161 16.462 15.9161C15.9689 15.9161 15.5774 16.0466 15.2873 16.3101C14.9974 16.5743 14.7795 16.9078 14.654 17.2794L12.9934 16.5881C13.2134 15.9644 13.6171 15.4133 14.2093 14.9372C14.8015 14.461 15.558 14.2217 16.4765 14.2217C17.1558 14.2217 17.7673 14.3522 18.3087 14.6157C18.8502 14.8791 19.2756 15.2441 19.5826 15.7082C19.8896 16.1747 20.0418 16.6968 20.0418 17.277C20.0418 17.8692 19.8992 18.3695 19.614 18.7804C19.3288 19.1913 18.9783 19.5056 18.5625 19.7255V19.8246C19.0994 20.046 19.5657 20.4095 19.9113 20.8761C20.2618 21.3474 20.4383 21.9106 20.4383 22.5681C20.4383 23.2256 20.2715 23.8129 19.9379 24.3278C19.6043 24.8427 19.1427 25.2487 18.5577 25.5436C17.9703 25.8385 17.3105 25.9884 16.5781 25.9884C15.7296 25.9908 14.9465 25.7467 14.2238 25.2584ZM24.5281 16.9337L22.6862 18.2656L21.7653 16.8685L25.0695 14.4851H26.3361V25.7273H24.5281V16.9337Z" fill="#4285F4" />
  </svg>
);

export default GoogleCalendarLogo;

GoogleCalendarLogo.defaultProps = {
  width: '40',
};
