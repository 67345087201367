/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { AuthContext, UserDataContext } from '../../../App';
import { defaultEmailHeadTemplate, populateEmailHeadTemplate } from '../../../external/Email/emailUtils';
import RealisticConfetti from '../../../shared/confetti/RealisticConfetti';
import SendIcon from '../../../shared/icons/SendIcon';
import { PrivateNoteData, TaskItem } from '../../../shared/types/types';
import { CLOSE_EVENT, OPEN_EVENT } from '../../../utils/analytics/enums';
import { logEventButtonClickSendEmail, logShareNotesEvents } from '../../../utils/analytics/eventLogger';
import { getUTCString } from '../../../utils/dateUtils/date';
import { MEETING_SECTION } from '../../../utils/enums';
import { mapTimestampForLog, setNoteContent } from '../../../utils/meetings/meetingsUtils';
import SquareButtonMolecule from '../../all-pages/bottom-bar/SquareButtonMolecule';
import MeetingDataContext from '../context/MeetingDataContext';
import FetchProseMirrorTextEditors from '../modal/FetchProseMirrorTextEditors';
import ShareNotesModal from '../modal/ShareModal/index';
import { NotesDataUtils, NotesData, NotesDataKeys } from '../modal/ShareModal/utils/ShareModalTypes';

type Props = {
  privateNotes: PrivateNoteData[],
  taskItems: TaskItem[];
  closeDropdown: () => void;
}

const ShareMeetingNotes = ({ taskItems, privateNotes, closeDropdown }: Props) => {
  const [notes, setNotes] = useState<NotesData>(NotesDataUtils.dummyNotesData);
  const [isShareNotesModalOpen, setShareNotesModalOpen] = useState(false);
  const [triggerConfetti, setTriggerConfetti] = useState(false);

  const { trackEvent } = useIntercom();
  const {
    data: { title }, date: { start, end }, version, meetingId,
  } = useContext(MeetingDataContext);

  const { userId } = useContext(UserDataContext);
  const authData = useContext(AuthContext);

  useEffect(() => {
    const initialEmailHeadTemplate = populateEmailHeadTemplate(
      defaultEmailHeadTemplate, title, getUTCString(start.date),
    );

    updateNotes('head', initialEmailHeadTemplate);
  }, [title, start]);

  const updateNotes = (noteType: NotesDataKeys, newText: string) => setNotes((prevNotes) => {
    if (!NotesDataUtils.isValidNewText(newText, prevNotes[noteType])) return prevNotes;
    return ({
      ...prevNotes,
      [noteType]: newText,
    });
  });

  const handleShareButtonClick = () => {
    closeDropdown();
    handleShareNotesModalOpen(true);
    logEventButtonClickSendEmail(authData.userId, trackEvent);
  };

  const handleShareNotesModalOpen = (value: boolean) => {
    logShareNotesEvents(authData.userId, value ? OPEN_EVENT : CLOSE_EVENT,
      [], [], mapTimestampForLog(start, end), 'resolved');
    setShareNotesModalOpen(value);
  };

  useEffect(() => {
    // After the an email is sent this const is used to triger the confetti fireworks,
    // after 5 seconds it resets to false
    if (triggerConfetti) {
      setTimeout(() => {
        setTriggerConfetti(false);
      }, 5000);
    }
  }, [triggerConfetti]);

  const setNote = (noteType: NotesDataKeys) => (newText: string) => {
    updateNotes(noteType, newText);
  };

  useEffect(() => {
    if (!meetingId) return;

    if (version <= 2) {
      setNoteContent(setNote('shared'), MEETING_SECTION.SHARED_NOTES, meetingId, userId);
      setNoteContent(setNote('my'), MEETING_SECTION.PERSONAL_NOTES, meetingId, userId);
    }

    if (version >= 3) {
      // Filler comment
    }
  }, [isShareNotesModalOpen]);

  return (
    <>
      <SquareButtonMolecule icon={<SendIcon />} onClick={handleShareButtonClick} tooltipText="Share a summary of this meeting" />
      <ShareNotesModal
        isOpen={isShareNotesModalOpen}
        handleCloseClick={() => { handleShareNotesModalOpen(false); }}
        taskItems={taskItems}
        notes={notes}
        setTriggerConfetti={setTriggerConfetti}
        setCustomMessage={setNote('head')}
      />
      <RealisticConfetti triggerConfetti={triggerConfetti} />
      {isShareNotesModalOpen && (
        <FetchProseMirrorTextEditors
          updateNotes={updateNotes}
        />
      )}
    </>
  );
};

export default ShareMeetingNotes;
