import mapDatabaseMeetingDataToMeetingData from '../../database/utils/mapMeetingData';
import { MeetingDataI } from './MeetingData';
import {
  AttendeeV2, ConferenceData, MeetingVersion, ResolvedState, SDateT, UserPermissions, Permissions,
} from './types';

class MeetingDataCore implements MeetingDataI {
  resolvedState: ResolvedState;

  meetingId: string;

  permissions: Permissions;

  userRole: UserPermissions;

  attendees: {
    attendees: AttendeeV2[];
    resolvedState: ResolvedState;
  };

  version: MeetingVersion;

  data: {
    agenda: any[];
    attachments: any[];
    attendees: any[];
    description: string;
    title: string;
    postMeetingTasks: any[];
    preMeetingTasks: any[];
    status?: string
  };

  tags: {
    tags: string[];
    meetingSeries: {
      name: string;
      id: string;
    };
  };

  date: {
    created: SDateT;
    start: SDateT;
    /** Check `date.start` comment */
    end: SDateT;
    lastUpdated: SDateT;
  };

  googleData: {
    ids: {
      /** In Google Calendar Event Resource it's simply called `id` */
      eventId: string;
      recurringEventId: string;
      dataEventId: string; // Not in use atm.
      calendarId: string;
      meetId: string;
      /** The first part of `htmlLink`is the same as the first 52 characters of
       *  `data-event-id` in meeting cards in Google Calendar (GC).
       * In GC, before you open a meeting, you can just see it in your schedule,
       * we have not access to the event id, so we need another way to identify
       * the meeting. We have access to the `data-event-id` and it seems like
       * the first 52 characters is the same as the `htmlLink` in the event resource.
       */
      htmlLink: string;
    },
    attendees: string[];
    content: {
      summary: string;
      status: string;
      organizer: {
        /**
         * `calendarId` of the event.
         *
         * The creator can be different from the calendarId, if the creator selects
         * a different calendar than his primary calendar.
         */
        email: string;
      },
      creator: {
        email: string,
      }
    }
    conferenceData: ConferenceData,
  };

  constructor(meetingId: string, databaseData: any, userId: string) {
    const meetingI = mapDatabaseMeetingDataToMeetingData(meetingId, databaseData, userId);
    this.resolvedState = meetingI.resolvedState;
    this.meetingId = meetingI.meetingId;
    this.permissions = meetingI.permissions;
    this.userRole = meetingI.userRole;
    this.attendees = meetingI.attendees;
    this.resolvedState = meetingI.resolvedState;
    this.version = meetingI.version;
    this.data = meetingI.data;
    this.tags = meetingI.tags;
    this.date = meetingI.date;
    this.googleData = meetingI.googleData;
  }
}

export default MeetingDataCore;
