import React, { useState, createContext, useEffect } from 'react';
import { DetectedOperatingSystemState, DetectedOperatingSystem } from '../../shared/types/types';
import { initialOperatingSystemContextState, pendingOperatingSystemState } from './enums';

interface Props {
  children: React.ReactNode;
}

export const DetectedOperatingSystemContext = createContext<DetectedOperatingSystemState>(
  initialOperatingSystemContextState,
);

const DetectingOperatingSystemContainer = ({ children }:Props) => {
  const [detectedOperatingSystem, setDetectedOperatingSystem] = useState<DetectedOperatingSystem>(
    pendingOperatingSystemState,
  );

  // TODO: Do we need to pass the setter as well?
  const operatingSystem = { detectedOperatingSystem, setDetectedOperatingSystem };

  useEffect(() => {
    if (window.navigator.userAgent.includes('Mac')) return setDetectedOperatingSystem('Mac');
    if (window.navigator.userAgent.includes('Linux')) return setDetectedOperatingSystem('Linux');
    return setDetectedOperatingSystem('Windows');
  }, [window.navigator.userAgent]);

  return (
    <DetectedOperatingSystemContext.Provider value={operatingSystem}>
      {children}
    </DetectedOperatingSystemContext.Provider>
  );
};

export default DetectingOperatingSystemContainer;
