import React from 'react';
import { warning7 } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const DangerIcon = ({ fill }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3799 4.29791C13.5873 3.05583 11.9449 2.62625 10.6301 3.35492C10.1663 3.60989 9.7819 3.99393 9.52426 4.46015L2.25249 17.1868L2.18777 17.358C1.67375 18.8448 2.37084 20.3951 3.76376 20.9625L3.98676 21.0419C4.21223 21.1118 4.4447 21.1535 4.68073 21.1667L4.76399 21.165L4.79569 21.1679H19.2777L19.4537 21.1536C20.9645 20.9865 22.074 19.6979 21.9967 18.1954C21.9787 17.8746 21.9096 17.5637 21.7903 17.2737L14.4709 4.45083L14.3799 4.29791ZM11.355 4.66815C11.9995 4.31095 12.8079 4.54334 13.1634 5.18598L20.4455 17.931L20.4671 18.0285C20.48 18.0953 20.4937 18.1845 20.4989 18.2759C20.5364 19.0056 19.9725 19.6305 19.2385 19.6689L4.79569 19.6679L4.76499 19.669L4.61436 19.6526C4.51541 19.6363 4.41916 19.6094 4.32689 19.5722C3.64958 19.2963 3.32243 18.5212 3.59859 17.8411L10.8319 5.19499L10.9138 5.06424C11.0312 4.89785 11.1811 4.76373 11.355 4.66815ZM11.2503 16.2696C11.2503 15.8556 11.5863 15.5136 12.0003 15.5136C12.4143 15.5136 12.7503 15.8446 12.7503 16.2586V16.2696C12.7503 16.6836 12.4143 17.0196 12.0003 17.0196C11.5863 17.0196 11.2503 16.6836 11.2503 16.2696ZM12.733 10.1943C12.6834 9.82826 12.3696 9.54611 11.9899 9.54611C11.5757 9.54611 11.2399 9.8819 11.2399 10.2961V13.3961L11.2467 13.4979C11.2964 13.864 11.6102 14.1461 11.9899 14.1461C12.4041 14.1461 12.7399 13.8103 12.7399 13.3961V10.2961L12.733 10.1943Z" fill={fill} />
  </svg>
);

DangerIcon.defaultProps = {
  fill: warning7,
};

export default DangerIcon;
