/* eslint-disable arrow-body-style */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom-v5-compat';
import CreateNewWorkspaceTemplate from './CreateNewWorkspaceTemplate';
import WorkspaceAPI from '../../../database/Workspaces/WorkspaceAPI';
import { UserDataContext } from '../../../App';
import { toastDanger, toastSuccess } from '../../../utils/notifications';
import UserWorkspacesAPI from '../../../database/User/UserWorkspacesAPI';
import Header from '../../../shared/components/header';
import { useUI } from '../../all-pages/ui-context/uiContext';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../../shared/components/header/utils/constants';
import ROUTES from '../../../routes/ROUTES_CONSTANTS';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background-color: white;
`;

const CreateNewWorkspacePageCtrl = () => {
  const userData = useContext(UserDataContext);
  const { setPage } = useUI();
  const navigate = useNavigate();

  const handleClickContinue = async (newWorkspaceName: string) => {
    console.log('CreateNewWorkspacePage: handleClickContinue');
    const workspaceId = await WorkspaceAPI
      .createWorkspaceSimple(newWorkspaceName, userData.email, userData.userId);
    if (!workspaceId) {
      toastDanger('Failed to create workspace');
      return;
    }
    toastSuccess('Workspace created successfully!');
    await UserWorkspacesAPI.setMyWorkspaceId(userData.userId, workspaceId);
    navigate(ROUTES.inviteMembersToWorkspace);
  };

  return (
    <Container>
      <Header setPage={setPage} view={DEFAULT_HEADER_MEETING_VIEW} />
      <CreateNewWorkspaceTemplate
        asTeamOrPersonal="team"
        onClickBack={() => navigate(ROUTES.dashboardPath)}
        onClickCreate={handleClickContinue}
      />
    </Container>
  );
};

export default CreateNewWorkspacePageCtrl;
