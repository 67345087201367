import { MeetingAnalyticsData } from '../../shared/types/types';

const INIT_MEETING_ANALYTICS_DATA : MeetingAnalyticsData = {
  hasUsedShepherd: false,
  hasAgenda: false,
  hasSharedNotes: false,
  hasPrivateNotes: false,
  users: [],
};

export default INIT_MEETING_ANALYTICS_DATA;
