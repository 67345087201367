import React from 'react';
import { Markup } from 'interweave';
import styled from 'styled-components';

interface Props {
    html: string,
}

const OverflowScroll = styled.div`
    overflow-y: auto;
    padding-top: 12px;
    min-height: 50px;
    max-height: 500px;
    /* height: 300px; */
`;

const TextBoxReadOnly = ({ html }: Props) => (
  <OverflowScroll>
    <Markup content={html} />
  </OverflowScroll>
);

export default TextBoxReadOnly;
