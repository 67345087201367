import React from 'react';
import { darkBlue4 } from '../../colors/COLORS';

interface Props {
  fill?: string,
}

const ThreeDotsIcon = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50049 3.75C7.50049 4.57843 8.17206 5.25 9.00049 5.25C9.82892 5.25 10.5005 4.57843 10.5005 3.75C10.5005 2.92157 9.82892 2.25 9.00049 2.25C8.17206 2.25 7.50049 2.92157 7.50049 3.75Z" fill={fill} />
    <path d="M7.50049 9C7.50049 9.82843 8.17206 10.5 9.00049 10.5C9.82892 10.5 10.5005 9.82843 10.5005 9C10.5005 8.17157 9.82892 7.5 9.00049 7.5C8.17206 7.5 7.50049 8.17157 7.50049 9Z" fill={fill} />
    <path d="M7.50049 14.25C7.50049 15.0784 8.17206 15.75 9.00049 15.75C9.82891 15.75 10.5005 15.0784 10.5005 14.25C10.5005 13.4216 9.82892 12.75 9.00049 12.75C8.17206 12.75 7.50049 13.4216 7.50049 14.25Z" fill={fill} />
  </svg>
);

ThreeDotsIcon.defaultProps = {
  fill: darkBlue4,
};

export default ThreeDotsIcon;
