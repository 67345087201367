import React, { ReactNode } from 'react';
import {
  DragDropContext, Droppable, DropResult, ResponderProvided,
} from 'react-beautiful-dnd';
import { REACT_DND_ID_PREFIX } from '../../../utils/constants';

interface Props {
  children: ReactNode,
  // eslint-disable-next-line no-unused-vars
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void,
}

const DroppableTaskCardsHolder = ({ children, onDragEnd }: Props) => {
  // eslint-disable-next-line no-unused-vars
  const getTaskCardListStyle = (isDraggingOver: boolean) => ({});

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={REACT_DND_ID_PREFIX}>
        {(provided, snapshot) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getTaskCardListStyle(snapshot.isDraggingOver)}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DroppableTaskCardsHolder;
