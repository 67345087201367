/* eslint-disable no-unused-vars */
import { findPositionOfNodeBefore } from '@meetshepherd/prosemirror-utils';
import { uuid4 } from '@sentry/utils';
import {
  chainCommands, createParagraphNear, liftEmptyBlock, newlineInCode, splitBlockKeepMarks, wrapIn,
} from 'prosemirror-commands';
import { NodeType, Schema } from 'prosemirror-model';
import { wrapInList } from 'prosemirror-schema-list';
import { TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import {
  useContext,
  useMemo,
  useState,
} from 'react';
import { AuthContext } from '../../../../App';
import keymapAdapter from '../logic/keymap/keymap-adapter';
import { insertNonNestableTable } from '../logic/menu/helpers/table-utils';
import commandAdapter from '../logic/suggestions/command-adapter';

interface CommandsSuggesterProps {
  view: EditorView,
  openGifModal: CallableFunction;
  openImageModal: CallableFunction;
  saveTemplate: CallableFunction;
  openShortcutsModal: CallableFunction;
}

export default function useCommandsSuggester({
  view,
  openGifModal,
  openImageModal,
  saveTemplate,
  openShortcutsModal,
}: CommandsSuggesterProps) {
  const user = useContext(AuthContext);

  const availableCommands = useMemo(() => ([
    {
      name: 'Mention',
      description: 'Mention someone',
      alias: 'mention, assign, task, tag, user',
      useTransactionLogic: true,
      className: 'ic-mentions',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.insertText(
          '@',
          currentPos,
          state.selection.$anchor.pos,
        );
        view.dispatch!(tr);
      },
    },
    {
      name: 'Task',
      description: 'Create a new task',
      alias: 'task, to do, assign, action, check, new',
      useTransactionLogic: true,
      className: 'ic-task',
      callback: () => {
        if (!view) return;

        const taskType: NodeType = view.state.schema.nodes.task;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        const userObject = {
          userId: user.userId,
          userState: user.userState,
          data: {
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            photoUrl: user.photoUrl,
          },
        };
        tr.replaceRangeWith(
          currentPos,
          state.selection.$anchor.pos,
          taskType.create({
            status: 'todo',
            userjson: JSON.stringify(userObject) || '{}',
            id: uuid4(),
          }),
        );
        tr.setSelection(TextSelection.create(tr.doc, findPositionOfNodeBefore(
          tr.selection,
        )! - 1));
        view.dispatch(tr);
      },
    },
    {
      name: 'Table',
      description: 'Insert a table',
      alias: 'link, insert',
      useTransactionLogic: true,
      className: 'ic-table',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.delete(
          currentPos,
          state.selection.$anchor.pos,
        );
        view.dispatch!(tr);
        insertNonNestableTable(view.state, view.dispatch);
      },
    },
    {
      name: 'Bullet_list',
      description: 'Insert an unordered list',
      alias: 'bullet, unordered, list',
      useTransactionLogic: true,
      className: 'ic-bulleted',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.delete(
          currentPos,
          state.selection.$anchor.pos,
        );
        view.dispatch!(tr);
        /**
         * Don't use the destructured state here,
         * because that is outdated. `view.state`, however,
         * is always up to date.
         */
        wrapInList(state.schema.nodes.bullet_list)(view.state, view.dispatch);
      },
    },
    {
      name: 'Numbered_list',
      description: 'Insert an ordered list',
      alias: 'number, list, order',
      useTransactionLogic: true,
      className: 'ic-numbered',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.delete(
          currentPos,
          state.selection.$anchor.pos,
        );
        view.dispatch!(tr);
        /**
         * Don't use the destructured state here,
         * because that is outdated. `view.state`, however,
         * is always up to date.
         */
        wrapInList(state.schema.nodes.ordered_list)(view.state, view.dispatch);
      },
    },
    {
      name: 'Checkbox_list',
      description: 'Insert an checkbox list',
      alias: 'check, tick, task, action, list',
      useTransactionLogic: true,
      className: 'ic-checkboxed',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.delete(
          currentPos,
          state.selection.$anchor.pos,
        );
        view.dispatch!(tr);
        /**
         * Don't use the destructured state here,
         * because that is outdated. `view.state`, however,
         * is always up to date.
         */
        wrapInList(state.schema.nodes.todo_list)(view.state, view.dispatch);
      },
    },
    {
      name: 'Link',
      description: 'Insert a link',
      alias: 'link, insert',
      className: 'ic-link',
      callback: () => {
        keymapAdapter.openLinkMenu();
      },
    },
    {
      name: 'Quote',
      description: 'Insert a quote',
      alias: 'quote',
      useTransactionLogic: true,
      className: 'ic-quote',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.delete(
          currentPos,
          state.selection.$anchor.pos,
        );
        view.dispatch!(tr);
        wrapIn(state.schema.nodes.blockquote)(view.state, view.dispatch);
      },
    },
    {
      name: 'Code',
      description: 'Insert a code block',
      alias: 'code, stamp, block',
      useTransactionLogic: true,
      className: 'ic-code',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.replaceRangeWith(
          currentPos,
          state.selection.$anchor.pos,
          (state.schema as Schema).nodes.code_block.create(
            {},
            [(state.schema as Schema).text('// Shift + Enter to create\n// newlines inside the code block.\n')],
          ),
        );
        tr.setSelection(TextSelection.create(tr.doc, currentPos));
        view.dispatch!(tr);
      },
    },
    {
      name: 'Time_stamp',
      description: 'Insert a timestamp',
      alias: 'time, stamp, clock',
      useTransactionLogic: true,
      className: 'ic-timestamp',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const timestamp: NodeType = (view.state.schema as Schema).nodes.resolvedTimestamp;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);

        tr.replaceRangeWith(
          currentPos,
          state.selection.$anchor.pos,
          timestamp.create(
            { time: `${+(new Date())}` },
            [(state.schema as Schema).text((new Date()).toUTCString())],
          ),
        );
        view.dispatch!(tr);
      },
    },
    {
      name: 'Divider',
      description: 'Insert a divider',
      alias: 'divider, line, horizontal, break, section, paragraph',
      useTransactionLogic: true,
      className: 'ic-divider',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.replaceRangeWith(
          currentPos,
          state.selection.$anchor.pos,
          (state.schema as Schema).nodes.horizontal_rule.create(),
        );
        view.dispatch!(tr);
        chainCommands(
          newlineInCode,
          createParagraphNear,
          liftEmptyBlock,
          splitBlockKeepMarks,
        )(view.state, view.dispatch);
      },
    },
    {
      name: 'Time_stamp_divider',
      description: 'Insert a timestamp',
      alias: 'time, stamp, divider',
      useTransactionLogic: true,
      className: 'ic-timestamp-divider',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const timestamp: NodeType = (view.state.schema as Schema).nodes.timestampDivider;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.replaceRangeWith(
          currentPos,
          state.selection.$anchor.pos,
          timestamp.create(
            { time: `${+(new Date())}` },
            [(state.schema as Schema).text((new Date()).toUTCString())],
          ),
        );
        view.dispatch!(tr);
      },
    },
    {
      name: 'Insert_template',
      description: 'Browse your templates',
      alias: 'agenda, template, insert, add',
      useTransactionLogic: true,
      className: 'ic-insert-template',
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.delete(
          currentPos,
          state.selection.$anchor.pos,
        );
        view.dispatch(tr);
        commandAdapter.openTemplates();
      },
    },
    {
      name: 'Save_as_template',
      description: 'Save this note as a template',
      alias: 'agenda, add, create, template, save',
      className: 'ic-save-as-template',
      callback: () => {
        saveTemplate();
      },
    },
    {
      name: 'Image',
      description: 'Insert an image',
      alias: 'image, picture, media, insert',
      className: 'ic-image',
      callback: () => {
        openImageModal();
      },
    },
    {
      name: 'GIF',
      description: 'Insert a GIF',
      alias: 'GIF, image, media, insert',
      className: 'ic-gif',
      callback: () => {
        openGifModal();
      },
    },
    {
      name: 'Emoji',
      description: 'Pick an emoji',
      alias: 'emoji, smiley',
      className: 'ic-emoji',
      callback: () => {
        commandAdapter.openEmojiMenu();
      },
    },
    {
      name: 'Colour',
      description: 'Color your text',
      alias: 'colour, text, font, highlight',
      className: 'ic-colour',
      callback: () => {
        commandAdapter.openColourMenu();
      },
    },
    {
      name: 'Highlight',
      description: 'Highlight your text',
      alias: 'highlight, text, font, colour, background',
      className: 'ic-highlight',
      callback: () => {
        commandAdapter.openHighlightMenu();
      },
    },
    {
      name: 'Shortcuts',
      description: 'Be fancy with shortcuts',
      alias: 'shortcut, quick',
      className: 'ic-shortcuts',
      callback: () => {
        openShortcutsModal();
      },
    },
  ]), [openGifModal]);

  const [show, setShow] = useState<boolean>(false);

  const [left, setLeft] = useState<number>(0);

  const [top, setTop] = useState<number>(0);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [results, setResults] = useState<any[]>([]);

  function rotateSelectionUp() {
    if (selectedIndex < 1) {
      setSelectedIndex(results.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  }

  function rotateSelectionDown() {
    setSelectedIndex((selectedIndex + 1) % results.length);
  }

  function setViewable(value: boolean) {
    setShow(value);
  }

  function setPos(x: number, y: number) {
    setTop(y);
    setLeft(Math.min(Math.max(x, 0), 320));
  }

  function getSelectedCommand() {
    if (selectedIndex >= results.length) return '';
    return results[selectedIndex].item;
  }

  function setResultsAndReset(data: any[]) {
    setResults(data);
    setSelectedIndex(0);
  }

  commandAdapter.reactMethods = {
    moveSelectionUp: rotateSelectionUp,
    moveSelectionDown: rotateSelectionDown,
    setResults: setResultsAndReset,
    getResults: () => results,
    setShow: setViewable,
    setPos,
    getSelectedCommand,
    getCommands: () => availableCommands,
    getShow: () => show,
  };

  return {
    commandsShow: [show, setShow] as const,
    commandsLeft: [left, setLeft] as const,
    commandsTop: [top, setTop] as const,
    commandsContents: [results, setResults] as const,
    commandsSelected: [selectedIndex, setSelectedIndex] as const,
  };
}
