/* eslint-disable arrow-body-style */
import React, { useContext, useEffect, useState } from 'react';
import InviteTeammatesToWorkspaceTemplate from './InviteTeammatesToWorkspaceTemplate';
import { UserDataContext } from '../../../App';
import WorkspaceData from '../../../database/Workspaces/WorkspaceData';
import WorkspaceMember from '../../../database/Workspaces/WorkspaceMember';
import useMeetingsAndUsersStore from '../../../zustand/useAllMeetingsStore';
import { WorkspacePermissionExpanded } from '../../../shared/types/types';
import WorkspaceAPI from '../../../database/Workspaces/WorkspaceAPI';

interface Props {
  onClickContinue: () => void
}

const InviteTeammatesToWorkspacePage = ({ onClickContinue }: Props) => {
  const userData = useContext(UserDataContext);
  const [workspaceInvitees, setWorkspaceInvitees] = useState<WorkspaceMember[]>([]);
  const { fetchUsersByEmails } = useMeetingsAndUsersStore();

  const handleClickContinue = () => {
    console.log('InviteTeammatesToWorkspacePage: handleClickContinue');
    onClickContinue();
  };

  useEffect(() => {
    if (!userData.isResolved()) return;
    getWorkspaceMembers(userData.workspace);
  }, [userData.workspace, userData.resolvedState]);

  const getWorkspaceMembers = async (workspace: WorkspaceData) => {
    const workspaceMembers = await workspace.getMembers(fetchUsersByEmails);
    setWorkspaceInvitees(workspaceMembers.filter((member) => member.permission === 'invited'));
  };

  const handleSetMemberPermission = (email: string, newPermission: WorkspacePermissionExpanded) => {
    const { workspaceId } = userData.workspace;
    WorkspaceAPI.setPermissionsOfMember(workspaceId, email, newPermission, userData.userId);
  };

  return (
    <InviteTeammatesToWorkspaceTemplate
      selectedWorkspace={userData.workspace}
      invitedMembers={workspaceInvitees}
      onClickContinue={handleClickContinue}
      onSetMemberPermission={handleSetMemberPermission}
    />
  );
};

export default InviteTeammatesToWorkspacePage;
