import React from 'react';
import styled from 'styled-components';
import { TaskStatus } from '../../types/types';
import {
  EllipseIcon,
  CheckIcon,
  ClockIcon,
  EmptyIcon,
} from '../../icons/tasks';
import DownArrow from '../../icons/downArrow';
import { getStatusName } from '../../../utils/tasks/tasksUtils';

const Container = styled.span`
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-items: center;
  /* margin-right: 8px; */
`;

const TextContainer = styled.span`
  margin: 0 8px;
`;

const StatusIconMap = {
  todo: EmptyIcon,
  inProgress: EllipseIcon,
  overdue: ClockIcon,
  completed: CheckIcon,
};

interface Props {
  status: TaskStatus,
  showText?: Boolean
}

const TaskStatusIcon = ({ status, showText }: Props) => {
  const Icon = StatusIconMap[status];
  return (
    <Container>
      <Icon />
      {showText && (
        <>
          <TextContainer>{getStatusName(status)}</TextContainer>
          <DownArrow />
        </>
      )}
    </Container>
  );
};

TaskStatusIcon.defaultProps = {
  showText: false,
};

export default TaskStatusIcon;
