import React, {
  Dispatch, SetStateAction, useContext,
} from 'react';
import ReactTooltip from '../tooltip/ReactTooltip';
import Switch from '../switch';
import {
  Container, SubHeader, SwitchContainer, ToggleContainer,
} from './PrivateTaskToggle';
import { UserDataContext } from '../../../App';
import { User } from '../../types/types';
import { TrelloIcon } from '../../icons/integration-icons';

interface Props {
  selectedEmail: string,
  checked: boolean,
  setChecked: Dispatch<SetStateAction<boolean>>,
  disabled: boolean
}

export default function TrelloToggle({
  checked, setChecked, disabled, selectedEmail,
}: Props) {
  const userData = useContext(UserDataContext);
  const disableText = generateDisableTooltipText(selectedEmail, userData);

  return (
    <Container>
      <TrelloIcon width="20" />
      <ToggleContainer>
        <ReactTooltip
          place="top"
          tip="Send this task to your Trello board"
          multiline
        >
          <SubHeader onClick={() => { setChecked(!checked); }}>
            Sync to Trello
          </SubHeader>
        </ReactTooltip>
      </ToggleContainer>
      <SwitchContainer>
        <Switch
          setChecked={setChecked}
          checked={checked}
          disabled={disabled}
          disabledTooltipText={disableText}
        />
      </SwitchContainer>
    </Container>
  );
}

const generateDisableTooltipText = (selectedEmail: string, userData: User) => {
  if (selectedEmail === userData.data.email) {
    return 'To enable Trello go to Integrations';
  }
  return 'Assignee has not integrated trello';
};
