import React, {
  Dispatch, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { isSameDay } from 'date-fns';
import { gray7 } from '../../colors/COLORS';
import { messageText, small } from '../../typography';
import Calendar from '../calendar';
import {
  dateToTaskDueDateFormat, ISOStringToDate,
} from '../../../utils/dateUtils/date';
import { TaskItem } from '../../types/types';
import { isTaskOverdue } from '../../../utils/tasks/tasksUtils';
import TaskDueDateOverdueModifier from './TaskDueDateOverdueModifier';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import { slackCoreAPISendNotificationForTaskOverdue } from '../../../utils/slack/SlackCoreAPI';
import { AuthContext } from '../../../App';

const Container = styled.span`
  ${small}
  color: ${gray7};
  cursor: pointer;
  width: 80px;
  text-align: center;
`;

const CalendarContainer = styled.div`
 ${messageText}
  position: relative;
  left: -347px;
  top: 16px;
`;

interface Props {
  id?: string,
  taskItem: TaskItem,
  // eslint-disable-next-line no-unused-vars
  updateDueDate: (dueDate: Date) => void,
  disableCalendarSelect?: boolean,
}

const TaskDueDate = ({
  id, taskItem, updateDueDate, disableCalendarSelect,
}: Props) => {
  const CalendarRef = useRef(null);
  const [isCalendarOpen, setCalendarOpen] = useDetectOutsideClick(CalendarRef, false);
  const [displayDate, setDisplayDate] = useState<Date | null>(null);
  const isOverdue = isTaskOverdue(taskItem);
  const authState = useContext(AuthContext);

  useEffect(() => {
    const dueDateDate: (Date | null) = (
      (taskItem.date.dueDate.type === 'noDueDate' || taskItem.date.dueDate.date.date.length === 0)
        ? null : ISOStringToDate(taskItem.date.dueDate.date.date));
    setDisplayDate(dueDateDate);
  }, [taskItem.date.dueDate]);

  useEffect(() => {
    if (!isOverdue) return;
    // TODO: Will this send an overdue notification every time you open
    // an overdue task, or will it only send once? - Only once
    slackCoreAPISendNotificationForTaskOverdue(
      taskItem,
      authState,
    );
  }, [isOverdue]);

  const setNewDate = (newDate: Date) => {
    if (isSameDay(newDate, displayDate as Date)) return;
    updateDueDate(newDate);
  };

  const handleDueDateClick = () => {
    if (disableCalendarSelect) return;
    setCalendarOpen(true);
  };

  return (
    <>
      <Container onClick={handleDueDateClick}>
        <TaskDueDateOverdueModifier
          id={id}
          isOverdue={displayDate !== null && isTaskOverdue(taskItem)}
        >
          {displayDate ? dateToTaskDueDateFormat(displayDate) : 'Add due date'}
        </TaskDueDateOverdueModifier>
      </Container>
      <CalendarContainer>
        <Calendar
          isOpen={isCalendarOpen}
          setIsOpen={setCalendarOpen}
          setDate={setNewDate as Dispatch<SetStateAction<Date>>}
          calendarRef={CalendarRef}
        />
      </CalendarContainer>
    </>
  );
};

TaskDueDate.defaultProps = {
  id: '',
  disableCalendarSelect: false,
};

export default TaskDueDate;
