import styled from 'styled-components';

const DropdownErrorMessage = styled.p`
  color: red;
  font-size: 10px;
  margin: 0;
  height: 10px;
`;

export default DropdownErrorMessage;
