import { some } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import {
  gray8, gray9, surface,
} from '../../../colors/COLORS';
import { header500, messageText, uiTextS } from '../../../typography';
import CssSpinner from '../../css-spinner';
import MenuItemAtom from '../atoms/MenuItemAtom';
import { DropdownItem } from '../utils';
import SearchIconWBG from '../../../icons/SearchIconWBG';

const MenuItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NoResultContainer = styled.div`
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
`;

const HeadText = styled.div`
  ${header500}
  margin: 16px 0px 16px 0px;
  color: ${gray9};
`;

const SubText = styled.div`
  ${uiTextS}
  color: ${gray8};
`;

const LoaderContainer = styled.div`
  ${messageText}
  text-align: center;
`;

interface Props {
  isLoading?: boolean
  isError?:boolean
  inputText: string,
  selectedItems: DropdownItem[],
  dropdownItems: DropdownItem[],
  // eslint-disable-next-line no-unused-vars
  onItemClick: (item: DropdownItem)=>void
}

function MenuItemsMolecule({
  inputText, selectedItems, dropdownItems, onItemClick, isLoading, isError,
}:Props) {
  const checkIsSelected = (item: DropdownItem) => some(
    selectedItems, { name: item.name, id: item.id },
  );
  if (dropdownItems.length === 0 && !isLoading) {
    return (
      <NoResultContainer>
        <SearchIconWBG />
        <HeadText>No results</HeadText>
        <SubText>
          {`Your search "${inputText}" is not available.`}
        </SubText>
        <SubText>
          Please try again.
        </SubText>
      </NoResultContainer>
    );
  }
  if (isLoading) {
    return (
      <LoaderContainer>
        <CssSpinner color={surface} size={30} thickness={4} padding={4} />
      </LoaderContainer>
    );
  }
  if (isError) {
    return (
      <LoaderContainer>
        <span>Something went wrong</span>
      </LoaderContainer>
    );
  }

  return (
    <MenuItems>
      {dropdownItems.map((item: DropdownItem) => (
        <MenuItemAtom
          item={item}
          onItemClick={onItemClick}
          key={item.id}
          isSelected={checkIsSelected(item)}
        />
      ))}
    </MenuItems>
  );
}
export default MenuItemsMolecule;

MenuItemsMolecule.defaultProps = {
  isLoading: false,
  isError: false,
};
