import { green6, yellow6 } from '../colors/COLORS';
import SDate from './SDate';

class ConsoleImproved {
  /**
   * Prints the text, prefixed with the current time in 'mm:ss:SS' format,
   * with black background and white text
   *
   * @Motivation This way the text stands out from the other outputs in the console
   *
   * @Examples
   *
   * `('Hello World')` => `59:59:99    Hello World`
   *
   * `('Log something')` => `12:23:99    Log something`
   */
  static log(obj: any, obj2?: any) {
    const dateText = `%c${SDate.getMinutesSecondsAndHundredths()}\t`;
    const dateStyling = `color: ${green6}; background: #000; padding: 0px;`;
    const textStyling = 'color: #fff; background: #000; padding: 0px;';

    if (typeof obj === 'object') {
      const yellowStyling = `color: ${yellow6}; background: #000; padding: 0px;`;
      console.log(`${dateText}%cPrinting Object`, dateStyling, yellowStyling);
      console.log(obj);
      if (obj2) {
        ConsoleImproved.log(obj2);
      }
      return;
    }

    console.log(`${dateText}%c${obj}`, dateStyling, textStyling);
    if (typeof obj2 !== 'undefined') {
      console.log(obj2);
    }
  }
}

export default ConsoleImproved;
