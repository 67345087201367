// TODO: We might want to have other workspace onboarding screens
/**
 * At 24. May 2023, we redid our onboarding, and removed a bunch of screens to make the
 * onboarding simpler. I will keep the old screens here for a while,
 * and if we don't use it for a while, we can delete it.
 */
export const OnboardingScreens = {
  // CALENDAR_SCREEN: 'calendar',
  // MEET_SCREEN: 'meet',
  // TASKS_SCREEN: 'tasks',
  INTEGRATIONS_SCREEN: 'integrations',
  // VIDEO_SCREEN: 'video',
  BILLING_SCREEN: 'billing',
  /**
   * Create a new workspace
   */
  WORKSPACE_CREATE: 'workspace-create',
  /**
   * How are you planning to use Shepherd? Personal or with team.
   * If you choose team you can create a new workspace name and invite members.
   */
  WORKSPACE_HOW_ARE_YOU_PLANNING_TO_USE_SHEPHERD: 'workspace-how-to-use-it',
  /**
   * Here you can invite members to your workspace.
   */
  WORKSPACE_INVITE: 'workspace-invite',
} as const;

const OnboardingConstants = {
  screens: OnboardingScreens,
  numberOfScreens: Object.keys(OnboardingScreens).length,
  firstScreen: OnboardingScreens.INTEGRATIONS_SCREEN,
  lastScreen: OnboardingScreens.BILLING_SCREEN,
};

export default OnboardingConstants;
