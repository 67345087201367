const pingSound = require('./Notification/utils/ping.wav');

class PlayPing {
  static play = () => {
    const audioObj = new Audio(pingSound);
    audioObj.play();
  }
}

export default PlayPing;
