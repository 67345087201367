import { COLORS } from '../../shared/colors/COLORS';
import { PublicUserDataV2, WorkspacePermission } from '../../shared/types/types';
import PublicUserDataV2Class from './PublicUserDataV2Class';

class WorkspaceMember extends PublicUserDataV2Class {
  permission: WorkspacePermission

  constructor(user: PublicUserDataV2, permission: WorkspacePermission) {
    super(user);
    this.permission = permission;
  }

  get email(): string {
    return this.data.email;
  }

  /**
   * Capitalized permission name
   * @example
   * 'admin' -> 'Admin'
   * 'editor' -> 'Editor'
   * 'manager' -> 'Manager'
   * 'member' -> 'Member'
   *
   */
  get permissionName(): string {
    return this.permission.charAt(0).toUpperCase() + this.permission.slice(1);
  }

  get permissionColor() {
    if (this.permission === 'admin') return COLORS.purple1;
    if (this.permission === 'editor') return COLORS.editorWorkspaceMember;
    if (this.permission === 'manager') return COLORS.workspaceViewer;
    if (this.permission === 'member') return COLORS.blue2;
    if (this.permission === 'invited') return COLORS.warning2;
    return COLORS.blue2;
  }

  /**
   * Note: Not to be used in production
   */
  static createDummy(
    name: string, email: string, photoUrl: string, userId: string, permission: WorkspacePermission,
  ) {
    const user = PublicUserDataV2Class.createDummyUser(name, email, photoUrl, userId);
    return new WorkspaceMember(user, permission);
  }

  static async getByUserId(userId: string, permission: WorkspacePermission) {
    const user = await PublicUserDataV2Class.getUserByUserId(userId);
    return new WorkspaceMember(user, permission);
  }
}

export default WorkspaceMember;
