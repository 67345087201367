/* eslint-disable import/prefer-default-export */
import { Schema } from 'prosemirror-model';
import { liftListItem, sinkListItem } from 'prosemirror-schema-list';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

interface ListKind {
  listName: string;
  listItemName: string;
}

const defaultListKinds = [
  {
    listName: 'bullet_list',
    listItemName: 'list_item',
  },
  {
    listName: 'ordered_list',
    listItemName: 'list_item',
  },
  {
    listName: 'todo_list',
    listItemName: 'todo_item',
  },
];

export function liftAny(view: EditorView, listTypes: ListKind[] = defaultListKinds) {
  const anchor = (view.state as EditorState).selection.$anchor;

  let ancestorListKind: ListKind | undefined;
  for (let i = anchor.depth; i > 0; i -= 1) {
    const ancestor = anchor.node(i);
    ancestorListKind = listTypes.find((listKind) => listKind.listName === ancestor.type.name);
    if (ancestorListKind) break;
  }

  if (ancestorListKind) {
    return liftListItem((view.state.schema as Schema).nodes[ancestorListKind.listItemName]);
  }
  return () => {};
}

export function sinkAny(view: EditorView, listTypes: ListKind[] = defaultListKinds) {
  const anchor = (view.state as EditorState).selection.$anchor;

  let ancestorListKind: ListKind | undefined;
  for (let i = anchor.depth; i > 0; i -= 1) {
    const ancestor = anchor.node(i);
    ancestorListKind = listTypes.find((listKind) => listKind.listName === ancestor.type.name);
    if (ancestorListKind) break;
  }

  if (ancestorListKind) {
    return sinkListItem((view.state.schema as Schema).nodes[ancestorListKind.listItemName]);
  }
  return () => {};
}
