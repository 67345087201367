import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStripe } from '@stripe/react-stripe-js';
import { useIntercom } from 'react-use-intercom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom-v5-compat';
import WorkspaceData from '../../../../database/Workspaces/WorkspaceData';
import Font from '../../../typography/Font';
import QuickSettingsWorkspaceItemOrg from './QuickSettingsWorkspaceItemOrg';
import { UserDataContext } from '../../../../App';
import WorkspaceAPI from '../../../../database/Workspaces/WorkspaceAPI';
import { COLORS } from '../../../colors/COLORS';
import UserWorkspacesAPI from '../../../../database/User/UserWorkspacesAPI';
import WorkspaceSettingsModal from './WorkspaceSettingsModal';
import WorkspaceMember from '../../../../database/Workspaces/WorkspaceMember';
import useMeetingsAndUsersStore from '../../../../zustand/useAllMeetingsStore';
import { WorkspacePermissionExpanded } from '../../../types/types';
import StripeAPI from '../../../../external/Stripe/StripeAPI';
import ConsoleImproved from '../../../classes/ConsoleImproved';
import { toastDanger, toastInfo } from '../../../../utils/notifications';
import MessageIcon from '../../../icons/MessageIcon';
import ROUTES from '../../../../routes/ROUTES_CONSTANTS';
import Logger from '../../../../utils/analytics/Logger/Logger';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitleContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 6px;
`;

const CreateNewWorkspaceButton = styled.div`
  width: 100%;
  margin-top: 6px;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${COLORS.gray1};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  &:hover {
    background-color: ${COLORS.gray4};
    cursor: pointer;
  }
`;

const InviteIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

interface Props {
  onCloseQuickSettings: () => void;
  onClickManageBilling: () => void,
}

const QuickSettingsWorkspacesListCtrl = ({
  onCloseQuickSettings,
  onClickManageBilling,
}: Props) => {
  const userData = useContext(UserDataContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [workspaces, setWorkspaces] = useState<WorkspaceData[]>([]);
  const { fetchUsersByEmails } = useMeetingsAndUsersStore();
  const navigate = useNavigate();

  const [isWorkspaceSettingsOpen, setIsWorkspaceSettingsOpen] = useState<boolean>(false);
  const [selectedWorkspaceMembers, setSelectedWorkspaceMembers] = useState<WorkspaceMember[]>([]);

  const stripe = useStripe();
  const { trackEvent } = useIntercom();

  const handleCloseQuickSettings = () => {
    onCloseQuickSettings();
  };

  const handleClickCreateWorkspace = () => {
    console.log('Create new workspace');
    // const newName = RandomNamesGenerator.generate();
    // WorkspaceAPI.createWorkspaceSimple(newName, userData.email, userData.userId);
    navigate(ROUTES.createNewWorkspace);
  };

  const handleClickWorkspace = (newWorkspaceId: string, openSettings: boolean) => {
    if (openSettings) {
      handleClickWorkspaceSettings();
    }
    if (userData.selectedWorkspaceId === newWorkspaceId) {
      console.log('Already in this workspace');
      return;
    }
    console.log(`Switch to workspace ${newWorkspaceId}`);
    UserWorkspacesAPI.setMyWorkspaceId(userData.userId, newWorkspaceId);
    setTimeout(handleCloseQuickSettings, 500);
  };

  const handleClickWorkspaceSettings = () => {
    console.log('Workspace settings');
    setIsWorkspaceSettingsOpen(true);
    handleCloseQuickSettings();
  };

  useEffect(() => {
    if (userData.resolvedState !== 'resolved') return () => {};
    return WorkspaceAPI.listenToMyWorkspaces(userData.email, setWorkspaces, setIsLoading);
  }, [userData.resolvedState]);

  const getWorkspaceMembers = async (workspace: WorkspaceData) => {
    // 1. Whenver the userData.workspace changes,
    //  which can happen whenever the workspace data changes, since
    // we are continuously listening to that data,
    //  we need to update the selectedWorkspaceMembers state.
    // We loop through the members of the workspace

    // 1. Fetch all PublicUserDataV2 users from workspace emails;
    // 2. Map the PublicUserDataV2 to WorkspaceMember;
    // 2.1. First going over admins, and then managers. If a manager is already counted
    // as an admin, then we skip it.
    // 2.2. Go over admin, then manager, then editor and then member.

    // 1. Fetch all Public UserDataV2 users from workspace emails;
    const workspaceMembers = await workspace.getMembers(fetchUsersByEmails);
    setSelectedWorkspaceMembers(workspaceMembers);
  };

  const handleSetMemberPermission = (email: string, newPermission: WorkspacePermissionExpanded) => {
    const { workspaceId } = userData.workspace;
    if (email === userData.email && newPermission === null) {
      WorkspaceAPI.removeYourselfFromWorkspace(workspaceId);
      toastInfo('Removing yourself from workspace');
      return;
    }
    WorkspaceAPI.setPermissionsOfMember(workspaceId, email, newPermission, userData.userId);
  };

  useEffect(() => {
    if (!userData.isResolved()) return;
    getWorkspaceMembers(userData.workspace);
  }, [userData.workspace, userData.resolvedState]);

  const handleClickSubscribe = async () => {
    console.log('Clicked subscribe');
    const { numberOfMembers } = userData.workspace;
    try {
      await StripeAPI.createAndRedirectToCheckoutSession(userData, 'workspaceSettings', numberOfMembers, trackEvent, stripe);
    } catch (error: any) {
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const handleClickInvites = () => {
    Logger.Workspaces.logClickInvites(userData.userId);
    setTimeout(() => {
      navigate(ROUTES.invites);
    }, 200);
  };

  if (isLoading) {
    return (
      <Container>
        <Font font="messageText" color="darkBlue4">Workspaces</Font>
        <Font font="messageText" color="darkBlue4">Loading...</Font>
      </Container>
    );
  }

  return (
    <Container>
      <SectionTitleContainer>
        <Font font="messageText" color="darkBlue4">Workspaces</Font>
      </SectionTitleContainer>
      {workspaces.map((workspace) => (
        <QuickSettingsWorkspaceItemOrg
          key={workspace.workspaceId}
          workspaceData={workspace}
          selectedWorkspaceId={userData.selectedWorkspaceId}
          onClick={handleClickWorkspace}
        />
      ))}
      <CreateNewWorkspaceButton onClick={handleClickInvites} id="check-your-invites">
        <InviteIconContainer>
          <MessageIcon fill={COLORS.gray7} />
        </InviteIconContainer>
        <Font font="uiTextS" color="gray7">
          Invites
        </Font>
      </CreateNewWorkspaceButton>
      <CreateNewWorkspaceButton onClick={handleClickCreateWorkspace} id="create-new-workspace-button">
        <FontAwesomeIcon icon={solid('people-group')} color={COLORS.gray7} style={{ marginRight: '8px' }} />
        <Font font="uiTextS" color="gray7">
          Create workspace
        </Font>
      </CreateNewWorkspaceButton>
      <WorkspaceSettingsModal
        isOpen={isWorkspaceSettingsOpen}
        members={selectedWorkspaceMembers}
        activateConfetti={false}
        setClosed={() => setIsWorkspaceSettingsOpen(false)}
        setMemberPermission={handleSetMemberPermission}
        onClickSubscribe={handleClickSubscribe}
        onClickManageBilling={onClickManageBilling}
      />
    </Container>
  );
};

export default QuickSettingsWorkspacesListCtrl;
