export const currentDateNicelyFormatted = (): string => {
  const today = new Date();
  const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const dateTime = `${date} ${time}`;
  return dateTime;
};

export function currentTimestamp() {
  return Date.now();
}

/**
 * Example:
 * `() => '2011-10-05T14:48:00.000Z'`
 *
 * ```
 * const event = new Date('05 October 2011 14:48 UTC');
 * console.log(event.toString());
 * // expected output: Wed Oct 05 2011 16:48:00 GMT+0200 (CEST)
 * // (note: your timezone may vary)

 * console.log(event.toISOString());
 * // expected output: 2011-10-05T14:48:00.000Z
 * ```
 */
export const currentISOString = () => new Date().toISOString();
