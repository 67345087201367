import React from 'react';
import styled from 'styled-components';
import ProfilePicture from '../profile-picture';
import Font from '../../typography/Font';
import { PublicUserDataV2 } from '../../types/types';

const Container = styled.div`
  display: flex;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  & > :first-child {
    margin-bottom: 3px;
  }
`;

interface ProfileCardProps {
  user: PublicUserDataV2,
}

const ProfileCard = ({ user }: ProfileCardProps) => {
  const { name } = user.data;
  const { email } = user.data;
  const { photoUrl } = user.data;
  const initial = name.charAt(0).toUpperCase();

  return (
    <Container>
      <ProfilePicture photoUrl={photoUrl} initial={initial} />
      <ProfileDetails>
        <Font font="uiTextMedium">{name}</Font>
        <Font font="small" color="gray7">{email}</Font>
      </ProfileDetails>
    </Container>
  );
};

export default ProfileCard;
