import styled from 'styled-components';
import { darkBlue1 } from '../../colors/COLORS';

const ModalLine = styled.div`
  background:  ${darkBlue1};
  height: 2px;
  width: 100%;
`;

export default ModalLine;
