import React from 'react';
import styled from 'styled-components';
import { getTimeFromNow } from '../../../utils/dateUtils/date';
import {
  surface, gray7, green6,
} from '../../colors/COLORS';
import { ReporterV2 } from '../../types/types';
import { uiText } from '../../typography';
import ProfileIcon from '../profile-icon';

const CreatorTagComponent = styled.div`
  ${uiText};
  width: fit-content;
  height: 20px;
  padding: 0px 4px 0px 4px;
  color: ${surface};
  border-radius: 4px;
`;

const CreatorSubHeader = styled.h2`
  ${uiText};
  margin: 0 0 8px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;
`;

const SubContainer = styled.div`
  width: 375px;
  display: flex;
  align-items: center;
  justify-content: left;
  span{
    ${uiText};
    color: ${gray7};
    margin-left: 8px;
  }
`;

interface Props {
  reporter: ReporterV2,
  createdAt: string
}

const AssigneeTag = ({
  reporter, createdAt,
}: Props) => (
  <Container>
    <CreatorSubHeader>Created by</CreatorSubHeader>
    <SubContainer>
      <ProfileIcon
        user={reporter}
        size={20}
        color={green6}
      />
      <CreatorTagComponent>{reporter.data.name}</CreatorTagComponent>
      <span>{getTimeFromNow(createdAt)}</span>
    </SubContainer>
  </Container>
);

export default AssigneeTag;
