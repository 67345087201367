/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom-v5-compat';
import { COLORS } from '../../../shared/colors/COLORS';
import InviteClass from '../../../shared/classes/Invite/InviteClass';
import Font from '../../../shared/typography/Font';
import InviteCard from './InviteCard';
import SendIcon from '../../../shared/icons/SendIcon';
import ArrowRight2 from '../../../shared/icons/ArrowRight2';
import ROUTES from '../../../routes/ROUTES_CONSTANTS';

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 24px 24px 0 0;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }

  padding: 12px;
  flex-grow: 1;
`;

const BorderRadiusContainer = styled.div`
  background-color: ${COLORS.surface};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  padding: 0 12px;

  // make the second child take up the rest of the space minus the arrow
  & > *:nth-child(2) {
    margin-left: -24px;
    flex-grow: 1;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
  cursor: pointer;

  & > * {
    transform: rotate(180deg);
  }

  &:hover {
    background: ${COLORS.gray4};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const InviteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  flex: 1;
`;

const SendIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // margin between icon and text is 4px
  & > *:not(:last-child) {
    margin-right: 4px;
  }
`;

interface Props {
  invites: InviteClass[];
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onAction: (workspaceId: string, workspaceName: string, acceptOrDecline: 'accept' | 'decline') => void;
}

const InvitePageMainContent = ({ invites, isLoading, onAction }: Props) => {
  const navigate = useNavigate();

  const handleBackArrowClick = () => {
    navigate(ROUTES.allNotes);
  };

  return (
    <BorderRadiusContainer>
      <MainContentContainer>
        <HeaderContainer>
          <ArrowContainer onClick={handleBackArrowClick}>
            <ArrowRight2 fill={COLORS.gray7} />
          </ArrowContainer>
          <TitleContainer>
            <Font font="header600">Your Workspace Invitations</Font>
          </TitleContainer>
        </HeaderContainer>
        {isLoading && (
          <div>
            <Font font="header500">Loading...</Font>
          </div>
        )}
        {!isLoading && invites.length === 0 && (
          <div>
            <Font font="header500">You have no invites</Font>
          </div>
        )}
        <InviteContainer>
          {invites.map((invite) => (
            <InviteCard
              key={invite.identifiers.workspaceId}
              invite={invite}
              onAction={onAction}
            />
          ))}
        </InviteContainer>
        <SendIconContainer>
          <Font font="uiTextS" color="gray7">
            You have also received the above invite/s in your email inbox
          </Font>
          <SendIcon fill={COLORS.gray7} />
        </SendIconContainer>
      </MainContentContainer>
    </BorderRadiusContainer>
  );
};

export default InvitePageMainContent;
