/* eslint-disable arrow-body-style */
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import WorkspaceAPI from '../../database/Workspaces/WorkspaceAPI';
import WorkspaceData from '../../database/Workspaces/WorkspaceData';
import WorkspaceItem from './WorkspaceItem';
import Font from '../../shared/typography/Font';
import { UserDataContext } from '../../App';
import UserWorkspacesAPI from '../../database/User/UserWorkspacesAPI';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 550px;
  & > span {
    margin-bottom: 15px;
  }
`;

const ListMyWorkspacesMol = () => {
  const userData = useContext(UserDataContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [workspaces, setWorkspaces] = React.useState<WorkspaceData[]>([]);

  useEffect(() => {
    if (userData.userId === '') return () => {};
    return WorkspaceAPI.listenToMyWorkspaces(userData.email, setWorkspaces, setIsLoading);
  }, [userData.userId]);

  const handleClickDelete = async (workspaceId: string) => {
    WorkspaceAPI.deleteWorkspace(workspaceId, userData);
  };

  const handleSelectWorkspace = async (workspaceId: string) => {
    UserWorkspacesAPI.setMyWorkspaceId(userData.userId, workspaceId);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Font font="header400">My workspaces:</Font>
      {workspaces.map((workspace) => (
        <WorkspaceItem
          workspace={workspace}
          onClickDelete={handleClickDelete}
          onSelectWorkspace={handleSelectWorkspace}
          key={workspace.workspaceId}
        />
      ))}
    </Container>
  );
};

export default ListMyWorkspacesMol;
