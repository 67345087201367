import moment from 'moment';
import { SDateT, AMPMor24h } from '../../shared/types/types';
import {
  WEEKDAY_FORMAT,
  DAY_AND_MONTH_FORMAT,
  HOUR_AND_MINUTES_FORMAT,
  DAY_FORMAT,
  MONTH_FORMAT,
  YEAR_FORMAT,
  DAY_AND_MONTH_FORMAT_SMALL,
} from '../constants';
import { currentDateNicelyFormatted, currentISOString, currentTimestamp } from '../currentDateNicelyFormatted';

export const getStartOfToday = () => {
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
};

export const getStartOfWeek = () => moment().startOf('week').toDate().toISOString();
export const getEndOfWeek = () => moment().endOf('week').toDate().toISOString();

export const getStartOfMonth = () => moment().startOf('month').toDate().toISOString();
export const getEndOfMonth = () => moment().endOf('month').toDate().toISOString();

// in 9 hours
export const getTimeFromNow = (startTime: string) => moment(startTime).fromNow();

export const timeFromNowToDueDate = (dueDate: Date) => {
  const today = new Date();

  const millisecondsPerDay = 1000 * 60 * 60 * 24;

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate());
  const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

  return ((Math.floor((utc2 - utc1) / millisecondsPerDay)) * -1);
};

// TODO Harald: Check if we should deprecate this function.
// Might not be the format we want to store in.
export const dateObject = () => ({
  date: currentDateNicelyFormatted(),
  timestamp: currentTimestamp(),
});

export const dateISOObject = () => ({
  date: currentISOString(),
  timestamp: currentTimestamp(),
});

export const dateToSDateObject = (passedDate: Date): SDateT => ({
  date: passedDate.toISOString(),
  timestamp: Number(moment(passedDate).format('x')),
});

export const dateAndTimeString = (startTime: Date, endTime: Date) => {
  const start = moment(startTime).format(DAY_AND_MONTH_FORMAT).toString();
  const startDay = moment(startTime).format(WEEKDAY_FORMAT).toString();
  const end = moment(endTime).format(HOUR_AND_MINUTES_FORMAT).toString();
  const startHour = moment(startTime).format(HOUR_AND_MINUTES_FORMAT);
  const startEndHours = `${startHour} - ${end}`; // I.e. Friday 21 May 16:30 - 17:00
  return [start, startDay, startEndHours];
};

/** @example getNiceDate(new Date()) => "21 May 16:30 Friday" */
export const getNiceDate = (date: Date) => {
  const dayAndMonth = moment(date).format(DAY_AND_MONTH_FORMAT).toString();
  const hourAndMinute = moment(date).format(HOUR_AND_MINUTES_FORMAT);
  const weekday = moment(date).format(WEEKDAY_FORMAT).toString();
  const nicelyFormattedDate = `${dayAndMonth} ${hourAndMinute} ${weekday}`;
  return nicelyFormattedDate;
};

export const dateToTaskDueDateFormat = (dueDate: Date) => {
  const date = moment(dueDate).format(DAY_FORMAT).toString();
  const month = moment(dueDate).format(MONTH_FORMAT).toString();
  const year = moment(dueDate).format(YEAR_FORMAT).toString();
  const fullDate = `${date} ${month} ${year}`;
  return fullDate;
};

/**
 * @example
 * fx("2021-08-21T08:29:15.982Z") => "21 Aug 2021"
*/
export const DateToTemplateDateFormat = (createdDate: string) => {
  const fullDateObj = ISOStringToDate(createdDate);
  const date = moment(fullDateObj).format(DAY_FORMAT).toString();
  const month = moment(fullDateObj).format(MONTH_FORMAT).toString();
  const year = moment(fullDateObj).format(YEAR_FORMAT).toString();
  const fullDate = `${date} ${month} ${year}`;
  return fullDate;
};

export const ISOStringToDate = (ISOString: string) => new Date(Date.parse(ISOString));

export const ISOStringToTaskDueDateFormat = (
  ISOString: string,
) => dateToTaskDueDateFormat(ISOStringToDate(ISOString));

export const getWeekday = (startTime: string) => {
  // Friday
  const weekday = moment(startTime).format(WEEKDAY_FORMAT).toString();
  return weekday;
};

export const getDayAndMonth = (time: string) => {
  // 4 June
  const dayAndMonth = moment(time).format(DAY_AND_MONTH_FORMAT).toString();
  return dayAndMonth;
};

/**
 * @examples
 * `getHourAndMinutes('2021-08-21T18:29:15.982Z')` => `"18:29"`
 * `getHourAndMinutes('2021-08-21T18:29:15.982Z', 'AMPM')` => `"6:29 PM"`
 *
 * @param amPmOr24h (default: 24h) 24 hour time or AM / PM time
 */
export const getHourAndMinutes = (time: string, amPmOr24h: AMPMor24h = '24h') => {
  let hourAndMinutes = moment(time).format(HOUR_AND_MINUTES_FORMAT);
  if (amPmOr24h === 'AMPM') {
    hourAndMinutes = moment(time).format('LT');
  }

  return hourAndMinutes;
};

export const getUTCString = (datetime: string) => {
  const datetimeObj = new Date(datetime);
  return datetimeObj.toUTCString();
};

export const isDateStringsTheSame = (date1: string, date2: string) => {
  const date1Obj = new Date(date1);
  const date2Obj = new Date(date2);
  return date1Obj.toUTCString() === date2Obj.toUTCString();
};

export const isISODateFormatCorrect = (date: string): boolean => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)) return false;
  const d = new Date(date);
  return d.toISOString() === date;
};

/** Get the date in format "21. Nov - 10:30" */
export const getNiceDateAndTime = (date: Date) => {
  const dayAndMonth = moment(date).format(DAY_AND_MONTH_FORMAT_SMALL).toString();
  const hourAndMinute = moment(date).format(HOUR_AND_MINUTES_FORMAT);
  const nicelyFormattedDate = `${dayAndMonth} - ${hourAndMinute}`;
  return nicelyFormattedDate;
};

/** Get the date in format "21" or "1" */
export const getNiceDateAndTime2 = (date: Date) => {
  const dayAndMonth = moment(date).format(DAY_FORMAT).toString();
  const nicelyFormattedDate = `${dayAndMonth}`;
  return nicelyFormattedDate;
};

/** Get the date in format "Nov" */
export const getNiceDateAndTime3 = (date: Date) => {
  const dayAndMonth = moment(date).format(MONTH_FORMAT).toString();
  const nicelyFormattedDate = `${dayAndMonth}`;
  return nicelyFormattedDate;
};

/** Get the date in format "- 10:30" */
export const getNiceDateAndTime4 = (date: Date) => {
  const hourAndMinute = moment(date).format(HOUR_AND_MINUTES_FORMAT);
  const nicelyFormattedDate = `- ${hourAndMinute}`;
  return nicelyFormattedDate;
};

export const returnYearIfNotCurrentYear = (date: Date) => {
  const year = moment(date).format(YEAR_FORMAT).toString();
  const currentYear = moment().format(YEAR_FORMAT).toString();
  if (year !== currentYear) {
    return year;
  }
  return '';
};
