import React, {
  Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import { enGB } from 'date-fns/locale';
import { DatePickerCalendar } from '@axel-dev/react-nice-dates';
import './calendar.css';
import { gray1, gray2 } from '../../colors/COLORS';

const CalendarContainer = styled.div`
  position: fixed;
  background: ${gray1};
  border-radius: 10px;
  border: 1px solid ${gray2};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  width: 328px;
  height: 316px;
  z-index: 30;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));

  & > div > div {
    margin: auto;
  }
`;

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  // eslint-disable-next-line no-unused-vars
  setDate: (dueDate: Date) => void,
  calendarRef?: React.MutableRefObject<null>,
}

const CustomizedReactCalendar = ({
  isOpen, setIsOpen, setDate, calendarRef,
}: Props) => {
  const handleDateChange = (date: Date | null) => {
    if (!date) return;
    const newDate: Date = new Date(date);
    setDate(newDate);
    setIsOpen(false);
  };

  if (isOpen) {
    return (
      <CalendarContainer ref={calendarRef}>
        <DatePickerCalendar
          locale={enGB}
          onDateChange={handleDateChange}
          minimumDate={new Date()}
        />
      </CalendarContainer>
    );
  }
  return null;
};

CustomizedReactCalendar.defaultProps = {
  calendarRef: null,
};

export default CustomizedReactCalendar;
