import React from 'react';
import { DropdownItem } from '../../shared/components/dropdown/utils';
import HashtagIcon from '../../shared/icons/Hashtag';
import UserIcon from '../../shared/icons/UserIcon';
import {
  SlackChannel, SlackMessageContent, SlackUser,
} from '../../shared/types/types';
import { SLACK_CHANNEL } from '../analytics/enums';
import { Block, SlackChannelType } from './slackUtils';

export const mapSlackAPIChannelToSlackChannel = (
  channel: any, type: SlackChannelType,
): DropdownItem => {
  const data: DropdownItem = {
    id: channel?.id ?? '',
    name: type === SLACK_CHANNEL ? channel?.name ?? '' : channel?.real_name ?? '',
    icon: type === SLACK_CHANNEL ? <HashtagIcon size={20} /> : <UserIcon />,
  };
  return data;
};

export const isValidAccessToken = (accessToken: string): boolean => accessToken.length > 10;

export const createSlackMessageContent = (
  channel: SlackChannel, slackBlocks: any,
): SlackMessageContent => ({
  /*
    there is no type for slackBlocks as the parsing of markdown
    to slack blocks returns a array of objects and each object is
    of diffrent type based on the text type hence 'any' for slackBlocks
  */

  channel: channel.id,
  blocks: slackBlocks,
  mrkdwn: true,
});

export const isValidBlock = (block: Block[]) => {
  if (!block[0].type.length
    || !block[0].text.text.length
    || !block[0].text.type.length) return false;
  return true;
};

export const mapSlackAPIUserToSlackUser = (response: any): SlackUser => {
  const slackUser = {
    name: response?.user?.profile?.real_name,
    iconUrl: response?.user?.profile?.image_original,
  } as SlackUser;
  return slackUser;
};
