/* eslint-disable constructor-super */
import { ResolvedState } from '../../shared/types/types';
import { PENDING, REJECTED, RESOLVED } from '../enums';
import ResolvedStateSmart from './ResolvedStateSmart';

/**
 * A class that wraps the ResolvedState enum, and adds some helper methods
 * making it easier to work with the enum
 *
 * @example
 * ```
 * const resolvedStateSmart = ResolvedStateSmart.RESOLVED;
 * if (resolvedStateSmart.isNotResolved) return;
 * // positive path
 * ```
 */
class ResolvedStateSmartExpanded extends ResolvedStateSmart {
  isNull: boolean = false;

  constructor(value: ResolvedState | null) {
    super(value || 'pending');
    this.isNull = value === null;
  }

  get isResolved(): boolean {
    if (this.isNull) return false;
    return this.value === RESOLVED;
  }

  get isNotResolved(): boolean {
    if (this.isNull) return true;
    return this.value !== RESOLVED;
  }

  get isRejected(): boolean {
    if (this.isNull) return false;
    return this.value === REJECTED;
  }

  get isPending(): boolean {
    if (this.isNull) return false;
    return this.value === PENDING;
  }

  static get REJECTED(): ResolvedStateSmartExpanded {
    return new ResolvedStateSmartExpanded(REJECTED);
  }

  static get RESOLVED(): ResolvedStateSmartExpanded {
    return new ResolvedStateSmartExpanded(RESOLVED);
  }

  static get PENDING(): ResolvedStateSmartExpanded {
    return new ResolvedStateSmartExpanded(PENDING);
  }

  static get NULL(): ResolvedStateSmartExpanded {
    return new ResolvedStateSmartExpanded(null);
  }
}

export default ResolvedStateSmartExpanded;
