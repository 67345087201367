import SlackBoltAPI from '../../../external/SlackBolt/SlackBoltAPI';
import { StripeConstants } from '../../../external/Stripe/StripeConstants';
import {
  BillingStartTrialOrigin, BillingClickTryForFreeOrigin,
  StripeCheckoutSessionOrigin, StripeCheckoutSessionOriginExtended, StripeClosePaywallOrigin,
} from '../../../external/Stripe/StripeTypes';
import SDate from '../../../shared/classes/SDate';
import { IntercomTrackEvent, User } from '../../../shared/types/types';
import { RESOLVED } from '../../enums';
import { logEvent } from '../eventLogger';
import BillingAnalyticsConstants from './MixpanelBillingConstants';

abstract class MixpanelBilling {
  static async log(userId: string, eventData: Object, intercomTrackEvent: IntercomTrackEvent) {
    SlackBoltAPI.logMessage(`User ${userId} did something - eventData: ${JSON.stringify(eventData, null, 2)}`);
    return logEvent(
      userId, BillingAnalyticsConstants.eventTypes.billing, eventData, intercomTrackEvent,
    );
  }

  static async logClickedSubscribe(
    user: User,
    intercomTrackEvent: IntercomTrackEvent,
    origin: StripeCheckoutSessionOriginExtended,
  ) {
    if (user.data.email.includes('meetshepherd.com')) {
      return RESOLVED;
    }
    SlackBoltAPI.logMessage(`User ${user.data.name} (${user.data.email}) clicked subscribe - origin: ${origin} - userId: ${user.userId}`);
    return logEvent(
      user.userId,
      BillingAnalyticsConstants.eventTypes.click_subscribe, { origin }, intercomTrackEvent,
    );
  }

  static async logClickedCloseInCancelPage(
    user: User,
    intercomTrackEvent: IntercomTrackEvent,
    origin: StripeCheckoutSessionOrigin | null,
  ) {
    const newOrigin = origin ?? StripeConstants.CHECKOUT_ORIGINS.DuringFreePlan;
    SlackBoltAPI.logMessage(`User ${user.data.name} (${user.data.email}) clicked close in cancel page - origin: ${origin} - userId: ${user.userId}`);
    return logEvent(
      user.userId,
      BillingAnalyticsConstants.eventTypes.click_close_in_cancel_page,
      { origin: newOrigin }, intercomTrackEvent,
    );
  }

  static async logClosedPaywall(
    user: User,
    intercomTrackEvent: IntercomTrackEvent,
    origin: StripeClosePaywallOrigin,
    meetingStartDateTime?: string,
  ) {
    let text = '';
    if (origin === 'openMeetingOutsidePeriod' && meetingStartDateTime) {
      const meetingStartDateTimeFormatted = SDate
        .calculateNumberOfDaysSinceDate(meetingStartDateTime);
      if (meetingStartDateTimeFormatted > 0) {
        text = `. Meeting was ${meetingStartDateTimeFormatted} days ago`;
      } else {
        text = `. Meeting is in ${-1 * meetingStartDateTimeFormatted} days`;
      }
    }
    SlackBoltAPI.logMessage(`User ${user.data.name} (${user.data.email}) closed paywall${text} - origin: ${origin} - userId: ${user.userId}`);
    return logEvent(
      user.userId,
      BillingAnalyticsConstants.eventTypes.close_paywall,
      { origin }, intercomTrackEvent,
    );
  }

  static async logStartTrial(
    user: User,
    origin: BillingStartTrialOrigin,
    intercomTrackEvent?: IntercomTrackEvent,
  ) {
    SlackBoltAPI.logMessage(`User ${user.data.name} (${user.data.email}) started trial - origin: ${origin} - userId: ${user.userId}`);
    return logEvent(
      user.userId,
      BillingAnalyticsConstants.eventTypes.click_start_trial,
      { origin },
      intercomTrackEvent,
    );
  }

  static async logClickTryForFree(
    user: User,
    intercomTrackEvent: IntercomTrackEvent,
    origin: BillingClickTryForFreeOrigin,
  ) {
    SlackBoltAPI.logMessage(`User ${user.data.name} (${user.data.email}) clicked try for free - origin: ${origin} - userId: ${user.userId}`);
    return logEvent(
      user.userId,
      BillingAnalyticsConstants.eventTypes.click_try_for_free,
      { origin },
      intercomTrackEvent,
    );
  }
}

export default MixpanelBilling;
