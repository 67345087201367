import React from 'react';
import Font from '../../../../../shared/typography/Font';

const LimitMeetingsTextAtom = () => (
  <Font font="uiTextS" color="gray7" id="announcement">
    It looks like you’ve reach your limit of
    {' '}
    <u>
      10 unique meeting notes
    </u>
    {' '}
    for the month.
    <br />
    <br />
    Upgrade now to access
    {' '}
    <b>unlimited notes</b>
    {' '}
    for only
    {' '}
    <b>$7 per month</b>
    .
  </Font>
);

export default LimitMeetingsTextAtom;
