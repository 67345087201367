import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../../shared/colors/COLORS';
import ButtonSmall from '../../../../shared/components/button-small';
import Font from '../../../../shared/typography/Font';
import ButtonWhite from '../../../../shared/components/buttons/ButtonWhite/ButtonWhite';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  border-radius: 8px;
  width: 100%;

  box-shadow: 0px 2px 8px rgba(27, 33, 36, 0.16);

  #freeTrial {
    font-weight: bold;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > *:first-child {
    font-size: 11px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;

  > *:first-child {
    margin-right: 20px;
    color: ${COLORS.gray7}
  }
  > *:last-child {
    font-size: 12px;
  }
`;

interface StartTrialOrSubscribeMoleculeProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onClickWatchDemo: () => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onClickClose: () => void,
}

const SuccessfulPaymentCardMol = ({
  onClickWatchDemo,
  onClickClose,
}: StartTrialOrSubscribeMoleculeProps) => (
  <Container>
    <TextContainer>
      <Font font="defaultSmall" id="freeTrial">
        Watch a quick product demo
      </Font>
    </TextContainer>
    <ButtonContainer>
      <ButtonWhite padding="7px 4px" onClick={onClickWatchDemo}>
        Watch Demo
      </ButtonWhite>
      <ButtonSmall padding="0px 7px" text="Let's go" onClick={onClickClose} />
    </ButtonContainer>
  </Container>
);

export default SuccessfulPaymentCardMol;
