import { arrayRemove, arrayUnion } from 'firebase/firestore';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { AMPMor24h } from '../../shared/types/types';
import { USER_PATH } from '../FirebaseConstants';
import UserAPICore from './UserAPICore';

class UserSettingsAPI extends UserAPICore {
  static updateClockFormat = async (userId: string, clockFormat: AMPMor24h) => {
    const updates = { [USER_PATH.settings.clockFormat]: clockFormat };
    // ConsoleImproved.log('Updating clock format', updates);
    return UserAPICore.updateUser(userId, updates, 'clockFormat');
  }

  static removeDisabledDomain = async (userId: string, domain: string) => {
    const updates = {
      [USER_PATH.settings.disabledDomains]:
        arrayRemove(domain),
    };
    ConsoleImproved.log('Removing disabled domain in Firestore', updates);
    return UserAPICore.updateUser(userId, updates, 'remove disabled domains').then(() => {
      ConsoleImproved.log('Removed disabled domain in Firestore');
    });
  }

  static addDisabledDomain = async (userId: string, domain: string) => {
    const updates = {
      [USER_PATH.settings.disabledDomains]:
        arrayUnion(domain),
    };
    ConsoleImproved.log('Adding disabled domain in Firestore', domain);
    return UserAPICore.updateUser(userId, updates, 'add disabled domains').then(() => {
      ConsoleImproved.log('Added disabled domain in Firestore', domain);
    });
  }
}

export default UserSettingsAPI;
