import { Dispatch, SetStateAction } from 'react';
import { captureException } from '@sentry/react';
import {
  addDoc, collection, deleteDoc, doc, query, updateDoc, where,
  onSnapshot, writeBatch,
} from 'firebase/firestore';
import {
  AuthState,
  DatabaseAssignee,
  DatabaseTaskItem, TaskItem, TaskItems, TaskOrderField, TaskStatus,
} from '../shared/types/types';
import { firestore } from '../utils/firebase';
import { handleOnSnapshotError } from './firebaseHandleError';
import { mapDocumentsToTaskItems } from './utils/mapTaskData';
import { toastDanger, toastSuccess } from '../utils/notifications';
import {
  filterTasksExcludeOtherUsersPrivateTasks, generateResolvedTaskItems,
  mapTaskItemToDatabaseTaskItem, rejectedTaskItems, sortTasksByCreated,
} from '../utils/tasks/tasksUtils';
import { dateToSDateObject } from '../utils/dateUtils/date';
import { logTasksUserAction } from '../utils/analytics/eventLogger';
import { CREATE_EVENT, DUPLICATE_TASK, TASKS_CREATE_TASK_BUTTON_FIELD } from '../utils/analytics/enums';
import { COLLECTIONS } from './FirebaseConstants';
import SentryAPI from '../utils/analytics/SentryAPI';

type SetTaskType = Dispatch<SetStateAction<TaskItem>>

export const dbCreateTask = (
  authState: AuthState, taskData: DatabaseTaskItem, intercomTrackEvent?: any,
  isDuplicateTask: boolean = false,
// ) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   .add(taskData)
) => addDoc(collection(firestore, COLLECTIONS.TASKS), taskData)
  .then((docRef) => {
    logTasksUserAction(
      authState.userId, isDuplicateTask ? DUPLICATE_TASK : CREATE_EVENT,
      TASKS_CREATE_TASK_BUTTON_FIELD, intercomTrackEvent,
    );
    console.log('task added successfully', taskData);
    toastSuccess('Task Created', `Task '${taskData.data.title}' created`);
    return docRef.id;
  })
  .catch((error) => {
    SentryAPI.captureExceptionAndConsoleError('dbCreateTask', error);
    toastDanger('Task Could Not Be Created', error.message);
    return '';
  });

export const dbUpdateTask = (
  taskId: string, taskItem: TaskItem, callback: any = () => { },
) => {
  // TODO: Should test this function since I needed to add`: any` to next line
  const databaseTaskItem: any = mapTaskItemToDatabaseTaskItem(taskItem);
  // return firestore()
  //   .collection(COLLECTIONS.TASKS)
  //   .doc(taskId)
  //   .update(databaseTaskItem)
  return updateDoc(doc(firestore, COLLECTIONS.TASKS, taskId), databaseTaskItem)
    .then(() => {
      callback();
      console.log(`successfully updated task ${taskId}`);
      console.log(databaseTaskItem);
    })
    .catch((error) => {
      console.error(`Error in dbUpdateTask ${error.message}`, { error, taskId, taskItem });
      captureException(error, { extra: { taskId, taskItem, functionName: 'dbUpdateTask' } });
      throw error;
    });
};

// export const dbDeleteTask = (taskId: string) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   .doc(taskId)
//   .delete()
export const dbDeleteTask = (taskId: string) => deleteDoc(doc(firestore, COLLECTIONS.TASKS, taskId))
  .then(() => {
    console.log(`Task successfully deleted with id: ${taskId}`);
  })
  .catch((error) => {
    console.error(`Error in dbDeleteTask ${error.message}`, { error, taskId });
    captureException(error, { extra: { taskId, functionName: 'dbDeleteTask' } });
    toastDanger('Task Could Not Be Deleted', error.message);
  });

// export const dbListenToTask = (taskId: string, setTaskData: SetTaskType) => firestore()
// .collection(COLLECTIONS.TASKS)
// .doc(taskId)
// .onSnapshot(async (snapshot) => {
export const dbListenToTask = (taskId: string, setTaskData: SetTaskType) => {
  onSnapshot(doc(firestore, COLLECTIONS.TASKS, taskId), async (snapshot) => {
    if (!snapshot.exists()) return;

    const tasks = await mapDocumentsToTaskItems([snapshot]);
    setTaskData(tasks[0]);
  }, (error) => {
    console.error(`Error in dbListenToTask ${error.message}`, { error, taskId });
    captureException(error, { extra: { taskId, functionName: 'dbListenToTask' } });
  });
};

export const dbMaybeListenToTask = (
  taskId: string, setTaskData: SetTaskType, setTaskDeleted: CallableFunction,
// ) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   .doc(taskId)
//   .onSnapshot(async (snapshot) => {
) => onSnapshot(doc(firestore, COLLECTIONS.TASKS, taskId), async (snapshot) => {
  if (!snapshot.exists()) {
    setTaskDeleted();
    return;
  }

  const tasks = await mapDocumentsToTaskItems([snapshot]);
  setTaskData(tasks[0]);
}, (error) => {
  console.error(`Error in dbMaybeListenToTask ${error.message}`, { error, taskId });
  captureException(error, { extra: { taskId, functionName: 'dbMaybeListenToTask' } });
});

// TODO: I think we need to listen two ways to all the tasks properly
// 1. Listen to my private tasks
// 2. Listen to all not private tasks
// This way, we never listen to other peoples private tasks
export const dbListenToTasksForMeeting = (
  meetingId: string,
  userEmail: string,
  setTasksData: Dispatch<SetStateAction<TaskItems>>,
// ) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   .where('meeting.meetingId', '==', meetingId)
//   .onSnapshot(async (snapshot) => {
) => onSnapshot(query(collection(firestore, COLLECTIONS.TASKS),
  where('meeting.meetingId', '==', meetingId)), async (snapshot) => {
  if (snapshot.empty) return setTasksData(generateResolvedTaskItems([]));

  const tasks = await mapDocumentsToTaskItems(snapshot.docs);
  const tasksSorted = tasks.sort(sortTasksByCreated);
  const tasksFiltered = tasksSorted.filter(
    (task) => filterTasksExcludeOtherUsersPrivateTasks(task, userEmail),
  );
  return setTasksData(generateResolvedTaskItems(tasksFiltered));
}, () => {
  setTasksData(rejectedTaskItems);
  return handleOnSnapshotError(`Something went wrong while listening tasks for a meetingId: ${meetingId}`);
});

// better name dbListenForTasksForUser, same for the funcs above
export const dbListenToTasksForUser = (
  // userId: string,
  email: string,
  setTasksData: Dispatch<SetStateAction<TaskItems>>,
// ) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   // .where('data.assignee.userId', '==', userId)
//   .where('data.assignee.email', '==', email)
//   .onSnapshot(async (snapshot) => {
) => onSnapshot(query(collection(firestore, COLLECTIONS.TASKS),
  where('data.assignee.email', '==', email)), async (snapshot) => {
  if (snapshot.empty) return setTasksData(generateResolvedTaskItems([]));

  const tasks = await mapDocumentsToTaskItems(snapshot.docs);
  const tasksSorted = tasks.sort(sortTasksByCreated);
  return setTasksData(generateResolvedTaskItems(tasksSorted));
}, () => {
  setTasksData(rejectedTaskItems);
  return handleOnSnapshotError(`Something went wrong while listening tasks for the email: ${email}`);
});

export const dbBatchUpdateTaskOrder = (tasks: TaskItem[], fieldName: TaskOrderField) => {
  const batch = writeBatch(firestore);
  // const batch = firestore().batch();
  tasks.forEach((task: TaskItem, index) => {
    const taskDocRef = doc(firestore, COLLECTIONS.TASKS, task.taskId);
    batch.update(taskDocRef, { [`order.${fieldName}`]: index });
  });
  batch.commit()
    .then(() => {
      console.log(`batch updated tasks orders. field: ${fieldName}`);
    }).catch((error) => {
      console.error(`Error in dbBatchUpdateTaskOrder ${error.message}`, { error, tasks, fieldName });
      captureException(error, { extra: { tasks, fieldName, functionName: 'dbBatchUpdateTaskOrder' } });
    });
};

export const dbUpdateUnseenTask = (taskId: string) => {
  setTimeout(() => {
    // firestore()
    //   .collection(COLLECTIONS.TASKS)
    //   .doc(taskId)
    //   .update({
    //     'data.isViewed': 'true',
    //   })
    updateDoc(doc(firestore, COLLECTIONS.TASKS, taskId), {
      'data.isViewed': 'true',
    })
      .then(() => {
        console.log('updated successfully');
      })
      .catch((error) => {
        console.error(`Error in dbUpdateUnseenTask ${error.message}`, { error, taskId });
        captureException(error, { extra: { taskId, functionName: 'dbUpdateUnseenTask' } });
      });
  }, 10000);
};

export const dbUpdateSlackIntegrationUserNotified = (taskId: string) => {
  // firestore()
  //   .collection('tasks')
  //   .doc(taskId)
  //   .update({
  //     'integrations.slack.userNotifiedOverdue': true,
  //   })
  updateDoc(doc(firestore, COLLECTIONS.TASKS, taskId), {
    'integrations.slack.userNotifiedOverdue': true,
  })
    .then(() => {
      console.log('updated successfully');
    })
    .catch((error) => {
      console.error(`Error in dbUpdateSlackIntegrationUserNotified ${error.message}`, { error, taskId });
      captureException(error, { extra: { taskId, functionName: 'dbUpdateSlackIntegrationUserNotified' } });
    });
};

export const dbTaskUpdateAssignee = (
  taskId: string, databaseAssignee: DatabaseAssignee,
// ) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   .doc(taskId)
//   .update({
//     'data.assignee': databaseAssignee,
//     assignee: databaseAssignee,
//   })
) => updateDoc(doc(firestore, COLLECTIONS.TASKS, taskId), {
  'data.assignee': databaseAssignee,
  assignee: databaseAssignee,
})
  .then(() => {
    console.log('updated assignee successfully');
  })
  .catch((error) => {
    console.error(`Error in dbTaskUpdateAssignee ${error.message}`, { error, taskId, databaseAssignee });
    captureException(error, { extra: { taskId, databaseAssignee, functionName: 'dbTaskUpdateAssignee' } });
  });

export const dbTaskUpdateDueDate = (
  taskId: string, newDueDate: Date,
// ) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   .doc(taskId)
//   .update({
//     'date.dueDate.date': dateToSDateObject(newDueDate),
//     'date.dueDate.type': 'date',
//   })
) => updateDoc(doc(firestore, COLLECTIONS.TASKS, taskId), {
  'date.dueDate.date': dateToSDateObject(newDueDate),
  'date.dueDate.type': 'date',
})
  .then(() => {
    console.log('updated due date successfully');
  })
  .catch((error) => {
    console.error(`Error in dbTaskUpdateDueDate ${error.message}`, { error, taskId, newDueDate });
    captureException(error, { extra: { taskId, newDueDate, functionName: 'dbTaskUpdateDueDate' } });
  });

export const dbTaskUpdateTitle = (
  taskId: string, title: string,
// ) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   .doc(taskId)
//   .update({
//     'data.title': title,
//   })
) => updateDoc(doc(firestore, COLLECTIONS.TASKS, taskId), {
  'data.title': title,
})
  .then(() => {
    console.log('updated title successfully');
  })
  .catch((error) => {
    console.error(`Error in dbTaskUpdateTitle ${error.message}`, { error, taskId, title });
    captureException(error, { extra: { taskId, title, functionName: 'dbTaskUpdateTitle' } });
  });

export const dbTaskUpdateStatus = (
  taskId: string, status: TaskStatus,
// ) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   .doc(taskId)
//   .update({
//     'data.status': status,
//   })
) => updateDoc(doc(firestore, COLLECTIONS.TASKS, taskId), {
  'data.status': status,
})
  .catch((error) => {
    console.error(`Error in dbTaskUpdateStatus ${error.message}`, { error, taskId, status });
    captureException(error, { extra: { taskId, status, functionName: 'dbTaskUpdateStatus' } });
  });

export const dbTaskUpdateTrelloData = (
  taskId: string, trelloId: string, trelloChecked: boolean,
// ) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   .doc(taskId)
//   .update({
//     'integrations.trello.trelloTaskId': trelloId,
//     'integrations.trello.isTrelloSyncEnabled': trelloChecked,
//   })
) => updateDoc(doc(firestore, COLLECTIONS.TASKS, taskId), {
  'integrations.trello.trelloTaskId': trelloId,
  'integrations.trello.isTrelloSyncEnabled': trelloChecked,
})
  .then(() => {
    console.log('updated trello info in task successfully');
  })
  .catch((error) => {
    console.error(`Error in dbTaskUpdateTrelloData ${error.message}`, {
      error, taskId, trelloId, trelloChecked,
    });
    captureException(error, {
      extra: {
        taskId, trelloId, trelloChecked, functionName: 'dbTaskUpdateTrelloData',
      },
    });
  });

export const dbUpdateSlackNotificationSent = (
  taskItem: TaskItem,
// ) => firestore()
//   .collection(COLLECTIONS.TASKS)
//   .doc(taskItem.taskId)
//   .update({
//     'integrations.slack.isOverdueNotificationSent': true,
//   })
) => updateDoc(doc(firestore, COLLECTIONS.TASKS, taskItem.taskId), {
  'integrations.slack.isOverdueNotificationSent': true,
})
  .then(() => {
    console.log('Slack overdue notification updated');
  })
  .catch((error) => {
    console.error(`Error in dbUpdateSlackNotificationSent ${error.message}`, { error, taskItem });
    captureException(error, { extra: { taskItem, functionName: 'dbUpdateSlackNotificationSent' } });
  });
