import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { header200 } from '../../../../shared/typography';
import NotionNotIntegrated from '../NotionModalComponents/NotionNotIntegrated';
import { IntegrationsTabView } from '../../../../shared/types/types';
import TrelloNotIntegrated from '../TrelloModalComponents/TrelloNotIntegrated';
import {
  SETTINGS_MODAL_LOCATION, SHARE_MODAL_LOCATION,
} from '../../../../utils/analytics/enums';
import SlackNotIntegrated from '../SlackModalComponents/SlackNotIntegrated';
import ModalLine from '../../../../shared/components/Modal/ModalLine';
import { OuterContainer } from './utils/commonStyles';

const EnabledHeader = styled.h2`
  ${header200};
  margin-top: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

interface Props {
  isSlackEnabled: boolean,
  isNotionIntegrated: boolean,
  setView: Dispatch<SetStateAction<IntegrationsTabView>>,
  isTrelloIntegrated: boolean,
  setIsIntegratingSlack: Dispatch<SetStateAction<boolean>>,
  analyticsFrom: typeof SHARE_MODAL_LOCATION | typeof SETTINGS_MODAL_LOCATION;
}

const NotIntegratedApps = ({
  isSlackEnabled, isNotionIntegrated, isTrelloIntegrated,
  setView, setIsIntegratingSlack, analyticsFrom,
}: Props) => {
  if (isSlackEnabled && isNotionIntegrated && isTrelloIntegrated) {
    return <></>;
  }
  return (
    <Container>
      <ModalLine />
      <EnabledHeader>Not Enabled</EnabledHeader>
      <OuterContainer>
        <SlackNotIntegrated
          enable={isSlackEnabled}
          setView={setView}
          setIsIntegratingSlack={setIsIntegratingSlack}
          analyticsFrom={analyticsFrom}
        />
        <NotionNotIntegrated
          enable={isNotionIntegrated}
          setView={setView}
          analyticsFrom={analyticsFrom}
        />
        <TrelloNotIntegrated
          enable={isTrelloIntegrated}
          setView={setView}
          analyticsFrom={analyticsFrom}
        />
      </OuterContainer>
    </Container>
  );
};

export default NotIntegratedApps;
