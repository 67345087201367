import React from 'react';
import { getTrelloBoardsAPIResponse, TrelloAPIBoard } from '../../../utils/trello/trelloUtils';
import { SlackChannel } from '../../types/types';

export const removeItem = (collection: any[], itemToRemove: any) => {
  const filteredItems = collection.filter(
    (item: any) => item.id !== itemToRemove.id,
  );
  return filteredItems;
};

export interface DropdownItem {
  id: string,
  name: string,
  icon: React.ReactNode
}

export const mapSlackChannelToDropdownItem = (data: SlackChannel[]) => {
  const dropdownItems = data.map((channel) => {
    const item: DropdownItem = {
      id: channel?.id ?? '',
      name: channel?.name ?? '',
      icon: null,
    };
    return item;
  });
  return dropdownItems;
};

export const mapDropdownItemToSlackChannel = (items: DropdownItem[]) => {
  const channels = items.map((item) => {
    const data: SlackChannel = {
      id: item.id,
      name: item.name,
    };
    return data;
  });
  return channels;
};

export const mapTrelloBoardsToDropdownItem = (data: getTrelloBoardsAPIResponse[]) => {
  const dropdownItems = data.map((channel) => {
    const item: DropdownItem = {
      id: channel?.id ?? '',
      name: channel?.name ?? '',
      icon: null,
    };
    return item;
  });
  return dropdownItems;
};

export const mapTrelloColumnsToDropdownItems = (data: TrelloAPIBoard[]) => {
  const dropdownItems = data.map((channel) => {
    const item: DropdownItem = {
      id: channel?.id ?? '',
      name: channel?.name ?? '',
      icon: null,
    };
    return item;
  });
  return dropdownItems;
};
