import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';

import { useNavigate } from 'react-router-dom-v5-compat';
import QuickSettings from '../quick-settings/QuickSettings';
import ShepherdLogo from '../../icons/shepherd/ShepherdLogo';
import SendCloseSidebarWindowMessage from '../../../utils/sendWindowMessages/closeSidebar';
import {
  Container, LeftContainer, IconGroupContainer,
} from './utils/styles';
import { DEFAULT_HEADER_MEETING_VIEW, HEADER_DASHBOARD_VIEW } from './utils/constants';
import { TopHeaderState, Page } from '../../types/types';
import NotificationInbox from '../notificationInbox';
import { AuthContext } from '../../../App';
import useNotificationStore from '../../../zustand/useNotificationStore';
import NotificationAPI from '../../classes/Notification/NotificationAPI';
import { logFullScreenButtonClick } from '../../../utils/analytics/eventLogger';
import FeedbackModal from '../Modal/FeedbackModal';
import GiveFeedbackAtom from './GiveFeedbackAtom';
import OpenInFullScreenAtom from './OpenInFullScreenAtom';
import CloseSidebarAtom from './CloseSidebarAtom';
import FreePlanTagCtrl from './FreePlanTag/FreePlanTagCtrl';
import ROUTES from '../../../routes/ROUTES_CONSTANTS';
import DaysLeftInTrialIconCtrl from './TrialLengthCircle/DaysLeftInTrialIconCtrl';
import OnlyShowInChromeExtension from '../only-show-in-chrome-extension/OnlyShowInChromeExtension';
import PremiumTagCtrl from './PremiumTag/PremiumTagCtrl';
import DevTagCtrl from './OnlyInDevEnv/DevTagCtrl';

const ShepherdLogoStyled = styled.div`
  align-items: center;
  display: flex;
  svg {
    display: block;
  }

  > *:first-child {
    cursor: pointer;
  };

  > *:nth-child(2) {
    margin-left: 10px;
  }
`;

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

export interface Props {
  // eslint-disable-next-line no-unused-vars
  setPage?: (page: Page) => void;
  view?: TopHeaderState
}

const Header = ({ setPage = () => { }, view = DEFAULT_HEADER_MEETING_VIEW }: Props) => {
  const { userId } = useContext(AuthContext);
  const { updateStore } = useNotificationStore();
  const { trackEvent } = useIntercom();
  const navigate = useNavigate();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (userId.length === 0) return () => { };
    return NotificationAPI.listenToUserNotifications(userId, updateStore);
  }, [userId]);

  const handleCloseSidebarClick = () => {
    SendCloseSidebarWindowMessage();
  };
  const handleOpenInFullScreenClick = () => {
    logFullScreenButtonClick(userId, trackEvent);
    window.open(window.location.href);
  };

  const handleClickShepherdIcon = () => {
    navigate(ROUTES.allNotes);
  };

  const renderSettingsAndLogo = () => {
    if (view === DEFAULT_HEADER_MEETING_VIEW || view === HEADER_DASHBOARD_VIEW) {
      return (
        <>
          <LeftContainer>
            <QuickSettings setPage={setPage} />
            <NotificationInbox />
            <DevTagCtrl />
          </LeftContainer>
          <ShepherdLogoStyled>
            <ShepherdLogo onClick={handleClickShepherdIcon} />
            <FreePlanTagCtrl />
            <PremiumTagCtrl />
          </ShepherdLogoStyled>
        </>
      );
    }

    return null;
  };

  const handleFeedbackClick = () => {
    console.log('feedback clicked');
    setIsFeedbackModalOpen(true);
  };

  return (
    <Container view={view}>
      {renderSettingsAndLogo()}
      <FeedbackModal
        isModalOpen={isFeedbackModalOpen}
        setModalClosed={() => setIsFeedbackModalOpen(false)}
      />
      <IconGroupContainer>
        <IconWrapper>
          <DaysLeftInTrialIconCtrl />
          <OnlyShowInChromeExtension showInChromeExtension={false}>
            <GiveFeedbackAtom onClick={handleFeedbackClick} />
          </OnlyShowInChromeExtension>
          <OpenInFullScreenAtom onClick={handleOpenInFullScreenClick} />
          <CloseSidebarAtom onClick={handleCloseSidebarClick} />
        </IconWrapper>
      </IconGroupContainer>
    </Container>
  );
};

Header.defaultProps = {
  view: DEFAULT_HEADER_MEETING_VIEW,
};

export default Header;
