import styled from 'styled-components';
import { COLORS } from '../../shared/colors/COLORS';

// eslint-disable-next-line import/prefer-default-export
export const HorizontalLine = styled.hr`
  box-sizing: border-box;
  margin: 0;
  border: 0.5px solid ${COLORS.darkBlue1};
  background-color: ${COLORS.darkBlue1};
`;
