import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import PremiumPlanTagAtom from '../FreePlanTag/PremiumPlanTagAtom';

const Container = styled.div``;

const PremiumTagCtrl = () => {
  const userData = useContext(UserDataContext);

  if (!userData.isOnPremium()) {
    return null;
  }

  return (
    <Container>
      <PremiumPlanTagAtom onClick={() => { }} />
    </Container>
  );
};

export default PremiumTagCtrl;
