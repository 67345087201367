import React from 'react';
import styled from 'styled-components';
import {
  surface, gray2, gray1,
} from '../../../colors/COLORS';
import TickIcon from '../../../icons/TickIcon';
import { link } from '../../../typography';
import { DropdownItem } from '../utils';

interface Props{
  isSelected: boolean,
  item: DropdownItem
  // eslint-disable-next-line no-unused-vars
  onItemClick: (item: DropdownItem)=> void
}

interface TextProps {
  hasIcon: boolean
}

const MenuItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    margin: 0 8px;
    background-color: ${gray1};
`;

const Text = styled.div<TextProps>`
    ${link};
    border: none;
    background: none;
    padding: ${({ hasIcon }) => (hasIcon ? '6px 6px' : '6px 16px')};
    color: ${surface};
    cursor: pointer;
    width: 100%;
    text-align: left;
    
    &:hover {
      background: ${gray2};
    }
`;

const TickIconWrapper = styled.div`
  margin-right: 12px;
`;

function MenuItemAtom({ item, onItemClick, isSelected }:Props) {
  return (
    <MenuItem key={item.id}>
      {item.icon}
      <Text
        hasIcon={item.icon !== null}
        onClick={(e) => {
          e.preventDefault();
          onItemClick(item);
        }}
      >
        {item.name}
      </Text>
      <TickIconWrapper>
        {isSelected && <TickIcon size="20" />}
      </TickIconWrapper>
    </MenuItem>
  );
}

export default MenuItemAtom;
