import { InviteData } from '../../../../types/types';

export const emptyInviteData: InviteData = {
  inviteId: '',
  email: '',
  invitedBy: [],
  isSignedUp: false,
  inviteSource: 'inviteEmail',
};

export const SEND_INVITES_MODAL_TITLE = 'Invite members to Shepherd';
export const EMPTY_INVITE_ID = '';
