/* eslint-disable no-unused-vars */
import { Node, NodeSpec } from 'prosemirror-model';
import { liftListItem, sinkListItem, splitListItem } from 'prosemirror-schema-list';
import { EditorSchema } from 'prosemirror-suggest';
import { EditorView, NodeView } from 'prosemirror-view';

const todoItemSpec: NodeSpec = {
  attrs: {
    done: { default: false },
  },
  content: 'paragraph block*',
  toDOM(node) {
    const { done } = node.attrs;

    return [
      'li', {
        'data-type': 'todo_item',
        'data-done': done.toString(),
      },
      ['span', { class: 'todo-checkbox todo-checkbox-unchecked', contenteditable: 'false' }],
      ['span', { class: 'todo-checkbox todo-checkbox-checked', contenteditable: 'false' }],
      ['div', { class: 'todo-content' }, 0],
    ];
  },
  parseDOM: [{
    priority: 51, // Needs higher priority than other nodes that use a "li" tag
    tag: '[data-type="todo_item"]',
    getAttrs(dom) {
      return {
        // Naming clash with ProseMirrorNode, this should be DOMNode
        done: (dom as any).getAttribute('data-done') === 'true',
      };
    },
  }],
};

const todoListSpec: NodeSpec = {
  group: 'block',
  content: 'todo_item+ | list_item+',
  toDOM(node: Node) {
    return ['ul', {
      'data-type': 'todo_list',
    }, 0];
  },
  parseDOM: [{
    priority: 51, // Needs higher priority than other nodes that use a "ul" tag
    tag: '[data-type="todo_list"]',
  }],
};

const CheckboxView: (node: Node, view: EditorView, getPos: () => number) => NodeView = (
  node, view, getPos,
) => {
  const dom = document.createElement('span');
  const checkbox = document.createElement('input');

  checkbox.type = 'checkbox';
  checkbox.contentEditable = 'false';
  checkbox.addEventListener('change', (event) => {
    const { checked } = event.target as any;

    if (typeof getPos === 'function') {
      view.dispatch(
        view.state.tr.setNodeMarkup(getPos(), undefined, {
          checked,
        }),
      );
    }
  });

  if (node.attrs.checked) {
    checkbox.setAttribute('checked', 'checked');
  }

  dom.append(checkbox);

  return {
    dom,
    update: (updatedNode: Node) => {
      if (updatedNode.attrs.checked) {
        checkbox.setAttribute('checked', 'checked');
      } else {
        checkbox.removeAttribute('checked');
      }

      return true;
    },
  };
};

const todoItemKeymap = (schema: EditorSchema) => ({
  Enter: splitListItem(schema.nodes.todo_item),
  Tab: sinkListItem(schema.nodes.todo_item),
  'Shift-Tab': liftListItem(schema.nodes.todo_item),
});

export {
  CheckboxView, todoListSpec, todoItemSpec, todoItemKeymap,
};
