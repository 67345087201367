import React from 'react';
import { INTERCOM_CONSTANTS } from '../../../utils/constants';
import OpenLinkIcon from '../../icons/OpenLink';
import OnlyShowInChromeExtension from '../only-show-in-chrome-extension/OnlyShowInChromeExtension';
import ReactTooltip from '../tooltip/ReactTooltip';
import { IconContainer } from './utils/styles';

interface Props {
  onClick: () => void
}

const OpenInFullScreenAtom = ({ onClick }: Props) => (
  <ReactTooltip tip="Open Shepherd in Full Screen" place="bottom">
    <OnlyShowInChromeExtension>
      <IconContainer
        id={INTERCOM_CONSTANTS.open_in_full_screen_id}
        onClick={onClick}
      >
        <OpenLinkIcon width="24" height="24" />
      </IconContainer>
    </OnlyShowInChromeExtension>
  </ReactTooltip>
);

export default OpenInFullScreenAtom;
