import React from 'react';
import { gray1 } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const SinglePlusIcon = ({ fill }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} d="M10.8095 6.61796C10.8095 5.96016 11.3427 5.42692 12.0005 5.42692C12.5985 5.42692 13.0936 5.86762 13.1786 6.44196L13.1916 6.61796V17.3801C13.1916 18.0379 12.6583 18.5711 12.0005 18.5711C11.4025 18.5711 10.9075 18.1304 10.8224 17.5561L10.8095 17.3801V6.61796Z" />
    <path fill={fill} d="M5.42206 11.9994C5.42212 11.4014 5.86287 10.9064 6.43722 10.8214L6.61322 10.8085L17.3855 10.8096C18.0433 10.8097 18.5764 11.343 18.5764 12.0008C18.5763 12.5988 18.1356 13.0938 17.5612 13.1788L17.3852 13.1917L6.61298 13.1906C5.95518 13.1905 5.42199 12.6572 5.42206 11.9994Z" />
  </svg>
);

SinglePlusIcon.defaultProps = {
  fill: gray1,
};

export default SinglePlusIcon;
