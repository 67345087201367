import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { COLORS } from '../../colors/COLORS';
import {
  small, uiText, uiTextS, uiTextM, uiTextL,
} from '../../typography';

interface StyleProps {
  'data-icon-only': boolean | undefined
  'data-left-icon': boolean | undefined
  'data-right-icon': boolean | undefined
  'data-outline': boolean | undefined
  'data-text': boolean | undefined
  'data-custom-width': string | undefined
  padding: string | undefined
  size: '2xl' | 'xl' | 'l' | 'm' | 's' | 'xs'
}

type ButtonSize = '2xl' | 'xl' | 'l' | 'm' | 's' | 'xs';
type TypographyStyle = FlattenSimpleInterpolation;

const sizeToTypography: Record<ButtonSize, TypographyStyle> = {
  xs: small,
  s: uiTextS,
  m: uiTextM,
  l: uiTextL,
  xl: uiTextL,
  '2xl': uiTextL,
};

const sizeToPadding: Record<ButtonSize, string> = {
  xs: '8px 12px',
  s: '8px 14px',
  m: '10px 16px',
  l: '10px 18px',
  xl: '12px 20px',
  '2xl': '16px 28px',
};

const sizeToHeight: Record<ButtonSize, string> = {
  xs: '30px',
  s: '36px',
  m: '40px',
  l: '42px',
  xl: '46px',
  '2xl': '56px',
};

const sizeToBorderRadius: Record<ButtonSize, string> = {
  xs: '8px',
  s: '8px',
  m: '8px',
  l: '8px',
  xl: '10px',
  '2xl': '12px',
};

export const ButtonStyle = styled.button<StyleProps>`

  // Font
  ${(props: StyleProps) => sizeToTypography[props.size] || uiText} 

  // Button
  align-items: center;
  display: flex;
  background: ${(props: StyleProps) => (props['data-outline'] ? COLORS.gray1 : COLORS.gray10)};
  ${(props: StyleProps) => props['data-text'] && 'background: transparent'};
  border: ${(props: StyleProps) => (props['data-outline'] ? `2px solid ${COLORS.darkBlue1}` : 'none')};
  ${(props: StyleProps) => props['data-text'] && 'border: none'}
  border-radius: ${(props: StyleProps) => sizeToBorderRadius[props.size] || '8px'};
  color: ${(props: StyleProps) => (props['data-outline'] ? COLORS.surface : COLORS.gray1)};
  ${(props: StyleProps) => props['data-text'] && `color: ${COLORS.darkBlue4};`}
  cursor: pointer;
  height: ${(props: StyleProps) => sizeToHeight[props.size] || '32px'};
  width: ${(props: StyleProps) => props['data-custom-width']};
  justify-content: center;

  // Padding
  padding: ${(props: StyleProps) => (props['data-icon-only'] ? '4px' : (props.padding || sizeToPadding[props.size] || '4px 16px'))};
  ${(props: StyleProps) => props['data-text'] && 'padding: 4px;'}
  
  // Custom padding
  ${(props: StyleProps) => props.padding && css` padding: ${props.padding};`}

  // Hover
  &:hover {
    background: ${COLORS.gray8};
    ${(props: StyleProps) => props['data-text'] && 'background: transparent;'}
    color: ${COLORS.gray1};
    ${(props: StyleProps) => props['data-text'] && `color: ${COLORS.surface}`};
    border-color: ${(props: StyleProps) => props['data-outline'] && 'transparent'};

    path {
      fill: ${COLORS.gray1};
    }
  }

  // Disabled
  &:disabled {
    background: rgba(9, 30, 66, 0.04);
    color: ${COLORS.darkBlue3};
    cursor: unset;

    path {
      fill: ${COLORS.darkBlue3};
    }
  }

  // Focus
  &:not(:disabled) {
    &:focus {
      background: ${COLORS.darkBlue6};
      color: ${COLORS.gray1};

      path {
        fill: ${COLORS.gray1};
      }
    }

    &:active {
      background: ${COLORS.blue1};
      color: ${COLORS.blue7};

      path {
        fill: ${COLORS.blue7};
      }
    }
  }

  svg {
    margin-right: ${(props: StyleProps) => (props['data-left-icon'] ? '4px' : 0)};
    margin-left: ${(props: StyleProps) => (props['data-right-icon'] ? '4px' : 0)};
  }
`;

export const SubmitButtonStyle = styled(ButtonStyle)<StyleProps>`
  background: ${(props: StyleProps) => (props['data-outline'] ? COLORS.gray1 : COLORS.blue6)};

  &:hover {
    background: ${COLORS.blue6};
    color: ${COLORS.gray1};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    path {
      fill: ${COLORS.gray1};
    }
  }

  &:disabled {
    background: ${COLORS.blue3};
    color: ${COLORS.blue1};
    cursor: unset;

    path {
      fill: ${COLORS.blue1};
    }
  }

  &:not(:disabled) {
    &:focus {
      background: ${COLORS.blue7};
    }

    &:active {
      background: ${COLORS.blue6};
      color: ${COLORS.gray1};

      path {
        fill: ${COLORS.gray1};
      }
    }
  }
`;

export const ErrorButtonStyle = styled(ButtonStyle)<StyleProps>`
  background: ${(props: StyleProps) => (props['data-outline'] ? COLORS.gray1 : COLORS.error6)};

  &:hover {
    background: ${COLORS.error7};
    color: ${COLORS.gray1};
    ${(props: StyleProps) => props['data-text'] && `color: ${COLORS.surface}`};

    path {
      fill: ${COLORS.gray1};
    }
  }

  &:disabled {
    background: ${COLORS.error3};
    color: ${COLORS.error1};

    path {
      fill: ${COLORS.error1};
    }
  }

  &:not(:disabled) {
    &:focus {
      background: ${COLORS.error7};
    }

    &:active {
      background: ${COLORS.error6};
      color: ${COLORS.gray1};

      path {
        fill: ${COLORS.gray1};
      }
    }
  }
`;
