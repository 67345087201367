/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../colors/COLORS';
import RelatedMeetingIcon from '../../../icons/RelatedMeetingIcon';
import { RelevantMeetingsData } from '../../../types/types';
import { MeetingData } from '../../../types/MeetingData';
import { small } from '../../../typography';
import RelevantMeetingsPopup from './RelevantMeetingsPopup';

const PositionContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  /* padding: 4px 8px; */
  /* background-color: ${COLORS.surface}; */

  /* width: 80px; */
  color: ${COLORS.darkBlue4};
  /* height: 40px; */

  & > span:nth-child(2) {
    opacity: 0;
    margin-left: 4px;

    height: 0;
    width: 0;
  }



  &:hover {
    transition: all 1s;
    /* height: 80px; */

    & > span:nth-child(2) {
      transition: all 1s;
      opacity: 1;
      height: 14px;
      width: 49px;
    }

  }
`;

// Delete if not used after 11. Feb 2023
const RelevantText = styled.span`
  ${small}
`;
const MeetingText = styled.span`
  ${small}
`;

interface RelevantMeetingTagProps {
  relevantMeetings: RelevantMeetingsData,
  clickRelevantMeeting: (meeting: MeetingData) => void,
}

const RelevantMeetingTag = ({
  relevantMeetings,
  clickRelevantMeeting,
}: RelevantMeetingTagProps) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <PositionContainer>
      <Container onMouseEnter={() => setIsHovered(true)}>
        {/* <RelevantText>Related</RelevantText> */}
        <RelatedMeetingIcon />
      </Container>
      <RelevantMeetingsPopup
        relevantMeetings={relevantMeetings}
        isOpen={isHovered}
        onClose={() => setIsHovered(false)}
        onClickMeeting={clickRelevantMeeting}
      />
    </PositionContainer>
  );
};

export default RelevantMeetingTag;
