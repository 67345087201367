import React from 'react';
import { surface } from '../colors/COLORS';

interface CheckMarkIconProps {
  width?: string,
  fill?: string,
}

const CheckMarkIcon = ({
  width = '24',
  fill = surface,
}: CheckMarkIconProps) => (
  <svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.6065 5.20491C20.0456 5.53987 20.13 6.16738 19.7951 6.6065L11.3239 17.7116C10.5974 18.664 9.20225 18.7685 8.34197 17.9349L4.30413 14.0225C3.9075 13.6382 3.89751 13.0051 4.28183 12.6085C4.66615 12.2118 5.29923 12.2019 5.69587 12.5862L9.73371 16.4986L18.2049 5.39349C18.5399 4.95438 19.1674 4.86995 19.6065 5.20491Z" fill={fill} />
  </svg>
);

CheckMarkIcon.defaultProps = {
  width: '24',
  fill: surface,
};

export default CheckMarkIcon;
