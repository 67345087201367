/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

interface Props {
  onClick: () => void;
}

const ShepherdLogo = ({ onClick }: Props) => (
  <div onClick={onClick}>
    <svg width="124" height="24" viewBox="0 0 125 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.7 13.5444C33.5929 13.7443 33.9898 14.0691 33.965 14.6439C33.965 15.3186 33.3201 15.7934 32.452 15.8184C31.3606 15.8434 30.3933 15.1687 30.3189 14.344H27.4913C27.5161 15.5435 27.9874 16.4931 28.9051 17.2178C29.8476 17.9175 30.939 18.2674 32.1543 18.2674C33.4441 18.2674 34.5602 17.9425 35.478 17.3178C36.4205 16.693 36.8918 15.7934 36.8918 14.6689C36.8918 13.7443 36.5197 11.8701 33.8658 11.1703L31.9311 10.6955C31.1126 10.4706 30.7157 10.1458 30.7157 9.72095C30.7157 8.94627 31.2118 8.57143 32.1791 8.57143C32.9728 8.57143 33.7169 8.97126 33.7417 9.77093H36.6189C36.5693 8.52145 36.0981 7.59683 35.2299 6.99708C34.3618 6.39733 33.3201 6.09746 32.1047 6.09746C30.939 6.09746 29.9468 6.44731 29.1035 7.14702C28.2602 7.82174 27.8386 8.64639 27.8386 9.57101C27.8386 10.9704 28.1362 12.2949 31.1126 13.1445L32.7 13.5444Z" fill="white" />
      <path d="M41.3589 11.7951C41.3589 9.92086 42.6486 9.07122 44.1368 9.07122C45.4514 9.07122 46.5923 10.0708 46.5923 11.6701V18.0175H49.6183V11.5202C49.6183 8.09663 47.9317 6.09746 44.9553 6.09746C44.2856 6.09746 43.5664 6.27238 42.8223 6.64723C42.103 6.99708 41.6069 7.49687 41.3589 8.1466V0H38.3329V18.0175H41.3589V11.7951Z" fill="white" />
      <path d="M62.8116 12.1449C62.8116 10.3457 62.2411 8.89629 61.1249 7.77176C60.0088 6.64723 58.6198 6.09746 56.9332 6.09746C55.2466 6.09746 53.8328 6.64723 52.667 7.77176C51.5013 8.89629 50.9308 10.3457 50.9308 12.1449C50.9308 13.9442 51.5013 15.4186 52.667 16.5681C53.8328 17.6926 55.2466 18.2674 56.9332 18.2674C59.2399 18.2674 61.3482 17.3428 62.3403 15.4436L60.0336 14.1941C59.5127 15.1687 58.347 15.6935 57.1316 15.6935C55.4202 15.6935 54.1304 14.7189 53.932 13.1945H62.7619C62.7867 12.8197 62.8116 12.4698 62.8116 12.1449ZM53.9072 11.1204C54.1304 9.596 55.3458 8.64639 56.958 8.64639C58.471 8.64639 59.6367 9.54602 59.86 11.1204H53.9072Z" fill="white" />
      <path d="M64.1195 6.34735V23.6901H67.1455V16.4681C67.7408 17.5927 69.2042 18.2674 70.7668 18.2674C72.3294 18.2674 73.6688 17.7176 74.7601 16.5931C75.8763 15.4686 76.422 13.9942 76.422 12.1699C76.422 10.3957 75.8763 8.92128 74.7601 7.79675C73.644 6.67222 72.3294 6.09746 70.7668 6.09746C69.2042 6.09746 67.7408 6.79717 67.1455 7.9217V6.34735H64.1195ZM67.1951 12.2199C67.1951 10.3207 68.5593 9.02124 70.2707 9.02124C71.1141 9.02124 71.8334 9.32111 72.4286 9.92086C73.0239 10.5206 73.3215 11.2953 73.3215 12.2199C73.3215 14.0941 71.9822 15.3686 70.2707 15.3686C68.5841 15.3686 67.1951 14.1441 67.1951 12.2199Z" fill="white" />
      <path d="M80.923 11.7951C80.923 9.92086 82.2127 9.07122 83.7009 9.07122C85.0155 9.07122 86.1565 10.0708 86.1565 11.6701V18.0175H89.1825V11.5202C89.1825 8.09663 87.4958 6.09746 84.5194 6.09746C83.8498 6.09746 83.1305 6.27238 82.3864 6.64723C81.6671 6.99708 81.171 7.49687 80.923 8.1466V0H77.897V18.0175H80.923V11.7951Z" fill="white" />
      <path d="M102.376 12.1449C102.376 10.3457 101.805 8.89629 100.689 7.77176C99.5729 6.64723 98.1839 6.09746 96.4973 6.09746C94.8107 6.09746 93.3969 6.64723 92.2311 7.77176C91.0654 8.89629 90.4949 10.3457 90.4949 12.1449C90.4949 13.9442 91.0654 15.4186 92.2311 16.5681C93.3969 17.6926 94.8107 18.2674 96.4973 18.2674C98.804 18.2674 100.912 17.3428 101.904 15.4436L99.5977 14.1941C99.0768 15.1687 97.9111 15.6935 96.6957 15.6935C94.9843 15.6935 93.6945 14.7189 93.4961 13.1945H102.326C102.351 12.8197 102.376 12.4698 102.376 12.1449ZM93.4713 11.1204C93.6945 9.596 94.9099 8.64639 96.5221 8.64639C98.0351 8.64639 99.2008 9.54602 99.4241 11.1204H93.4713Z" fill="white" />
      <path d="M106.71 13.8942C106.71 10.5956 107.677 8.94627 109.612 8.94627C110.306 8.94627 110.901 9.04623 111.373 9.27113L111.894 6.52228C111.373 6.2474 110.728 6.09746 109.984 6.09746C108.57 6.09746 107.28 7.09704 106.71 9.09621V6.34735H103.684V18.0175H106.71V13.8942Z" fill="white" />
      <path d="M124.007 18.0175V0H121.005V7.89671C120.385 6.77218 118.947 6.09746 117.359 6.09746C115.797 6.09746 114.457 6.67222 113.341 7.79675C112.225 8.92128 111.679 10.3707 111.679 12.1949C111.679 13.9942 112.225 15.4686 113.341 16.5931C114.457 17.7176 115.797 18.2674 117.359 18.2674C118.922 18.2674 120.385 17.5677 121.005 16.4431V18.0175H124.007ZM117.855 8.99625C118.674 8.99625 119.393 9.29612 120.013 9.87088C120.633 10.4456 120.956 11.1953 120.956 12.1449C120.956 13.0945 120.658 13.8692 120.038 14.469C119.443 15.0437 118.724 15.3436 117.855 15.3436C116.987 15.3436 116.268 15.0437 115.673 14.469C115.102 13.8692 114.805 13.0945 114.805 12.1449C114.805 10.2707 116.144 8.99625 117.855 8.99625Z" fill="white" />
      <path d="M13.94 1.92507L11.4413 10.5811H9.86322L7.36454 1.92507L8.21935 1H13.0852L13.94 1.92507Z" fill="#FE5968" />
      <path d="M20.9091 9.78843L12.1637 11.969L11.4404 10.5814L17.6214 4.03979L18.8707 4.3041L21.3036 8.53298L20.9091 9.78843Z" fill="#2FDBA1" />
      <path d="M17.6214 19.8977L11.4404 13.3561L12.1637 11.9685L20.9091 14.149L21.3036 15.4045L18.8707 19.6334L17.6214 19.8977Z" fill="#585DCD" />
      <path d="M8.21935 22.9373L7.36454 22.0122L9.86322 13.3562H11.4413L13.94 22.0122L13.0852 22.9373H8.21935Z" fill="#65DEFE" />
      <path d="M0.394528 14.149L9.1399 11.9685L9.8632 13.3561L3.68226 19.8977L2.43292 19.6334L0 15.4045L0.394528 14.149Z" fill="#FFCB00" />
      <path d="M3.68226 4.03979L9.8632 10.5814L9.1399 11.969L0.394528 9.78843L0 8.53298L2.43292 4.3041L3.68226 4.03979Z" fill="#058FEF" />
    </svg>
  </div>
);

export default ShepherdLogo;
