import React from 'react';

// eslint-disable-next-line import/prefer-default-export

/**
 * When react-smooth-scrollbar  is used as a parent component,
 * all the default scrollbar of the children component's do not
 * work as expected hence we need to stop event propagation for
 * those components where default scroll behavior is not working
 */
const stopScrollbarPropagation = (
  ref: React.MutableRefObject<HTMLTextAreaElement>,
) => {
  [
    'touchmove',
    'mousewheel',
    'wheel',
  ].forEach((eventType) => {
    ref.current.addEventListener(eventType, (e) => e.stopPropagation());
  });
};

export default stopScrollbarPropagation;
