import * as Yup from 'yup';
import { PersonalOnboardingData } from './OnboardingTypes';
import { sortArrayAlphabetically } from './functions';

export const PersonalOnbDataDefault: PersonalOnboardingData = {
  firstName: '',
  lastName: '',
  useShepherd: '',
  jobType: '',
  companySize: '',
  productivityTool: [],
  remotePolicy: '',
};

// formik validation schema

export const ProfileFormValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^(?!\s+$).*/, 'Must contain letter and numbers')
    .matches(/^(\S)/, 'Can\'t start with spaces')
    .matches(/(\S)$/, 'Can\'t end with spaces')
    .required('Required'),
  lastName: Yup.string()
    .matches(/^(?!\s+$).*/, 'Must contain letter and numbers')
    .matches(/^(\S)/, 'Can\'t start with spaces')
    .matches(/(\S)$/, 'Can\'t end with spaces')
    .required('Required'),
  jobType: Yup.string()
    .required('Required'),
});

export const CompanyFormValidationSchema = Yup.object().shape({
  jobType: Yup.string()
    .required('Required'),
  companySize: Yup.string()
    .required('Required'),
  productivityTool: Yup.array()
    .min(1, 'Required'),
  remotePolicy: Yup.string()
    .required('Required'),
});

// dropdown values

export const UseShepherdValues = [
  { id: 0, value: 'Bring structure to meetings' },
  { id: 1, value: 'Take collaborative notes' },
  { id: 2, value: 'Keep track of tasks' },
  { id: 3, value: 'Quickly share meeting summary' },
  { id: 4, value: 'All of the above' }];

export const Roles = [
  { id: 0, value: 'Admin' },
  { id: 1, value: 'Customer Service' },
  { id: 2, value: 'Design' },
  { id: 10, value: 'Product' },
  { id: 11, value: 'Management' },
  { id: 3, value: 'Engineering' },
  { id: 4, value: 'Finance' },
  { id: 5, value: 'HR' },
  { id: 6, value: 'Operations' },
  { id: 7, value: 'Sales' },
  { id: 8, value: 'Student' },
  { id: 9, value: 'Other' },
];

/** Sorted roles alphabetically */
export const sortedRoles = sortArrayAlphabetically(Roles);

export const CompanySizes = [
  { id: 0, value: '1-10' },
  { id: 1, value: '11-50' },
  { id: 2, value: '51-100' },
  { id: 3, value: '101-250' },
  { id: 4, value: '251+' },
];

export const ProductivityTools = [
  { id: 0, value: 'Asana' },
  { id: 1, value: 'Slack' },
  { id: 2, value: 'Notion' },
  { id: 3, value: 'Jira' },
  { id: 4, value: 'Trello' },
  { id: 5, value: 'Google Docs' },
  { id: 6, value: 'ClickUp' },
  { id: 7, value: 'HubSpot' },
  { id: 8, value: 'Salesforce' },
  { id: 9, value: 'Todoist' },
  { id: 10, value: 'Google Meet' },
  { id: 11, value: 'Zoom' },
  { id: 12, value: 'MS Teams' },
];

export const RemotePolicies = [
  { id: 0, value: '100% in Office' },
  { id: 1, value: 'Hybrid' },
  { id: 2, value: 'Fully Remote' },
];

// error message for failed submission

export const ErrorModalData = {
  error: 'Trouble connecting to the server',
  errorDescription: `Please refresh and try again. If the problem 
persists, please reach out.`,
  action: 'Refresh the page',
};

export const ErrorModalFunctions = {
  handleModalClose: () => {},
  handleActionClick: () => { window.location.reload(); },
};
