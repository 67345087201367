/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import BillingModalTitle from '../../../../../pages/all-pages/Billing/BillingModalTitle';
import LearnMoreButtonAtom from '../../../../../pages/all-pages/Billing/LearnMoreButton';
import Font from '../../../../typography/Font';
import FreePlanBillingModalTitle from './FreePlanBillingModalTitle';
import FreePlanSubscribeCardMol from './FreePlanSubscribeCardMol';

const Container = styled.div`
  background: white;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(27, 33, 36, 0.16);
  span {
    user-select: none;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;

  & > *:first-child {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    /* or 250% */

    text-align: center;

    color: #9A9A9A;
    margin-bottom: 15px;
  }

  & > *:last-child {
    margin-top: 15px;
    font-size: 14px;
  }
`;

interface FreePlanDropdownOrgSecondProps {
  isOpen: boolean;
  onClose: () => void;
  onClickSubscribe: () => void;
  onClickLearnMore: () => void;
}

const FreePlanDropdownOrgSecond = ({
  isOpen,
  onClose,
  onClickSubscribe,
  onClickLearnMore,
}: FreePlanDropdownOrgSecondProps) => {
  if (!isOpen) return null;

  return (
    <Container onMouseLeave={onClose}>
      <FreePlanBillingModalTitle title="You are on the Free Plan" />
      <MainContent>
        <Font font="uiTextS" color="gray7" id="announcement">
          Why not try our Premium plan for only
          {' '}
          <b>$7 pm</b>
          {' '}
          to get the most out of Shepherd?
        </Font>
        <FreePlanSubscribeCardMol onClickSubscribe={onClickSubscribe} />
        <LearnMoreButtonAtom onClick={onClickLearnMore} variant="trial" />
      </MainContent>
    </Container>
  );
};

export default FreePlanDropdownOrgSecond;
