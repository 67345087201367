import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { DropdownItem, mapTrelloColumnsToDropdownItems } from '../../../../../../shared/components/dropdown/utils';
import { EllipseIcon, EmptyIcon, CheckIcon } from '../../../../../../shared/icons/tasks';
import { ResolvedStateItem, TrelloData } from '../../../../../../shared/types/types';
import { getTrelloBoardsList } from '../../../../../../utils/trello/trelloAPIs';
import { updateList } from '../../../../../../utils/trello/trelloSettingsUpdateUtil';
import { TrelloAPIBoard } from '../../../../../../utils/trello/trelloUtils';
import { SettingContainer, SettingLabel } from '../SettingTab';
import ListSettingTab from './ListSettingTab';

const TaskExportLabel = styled.p`
  padding: 0px;
  margin: 0px 0px 0px 0px;
  display: flex;
`;

interface Props {
  setTrelloData: Dispatch<SetStateAction<TrelloData>>,
  trelloData: TrelloData
}

// TODO: Better naming of component
export default function ListSettings({ setTrelloData, trelloData }:Props) {
  const [accessToken, setAccessToken] = useState('');
  const [listsLoading, setListsLoading] = useState(false);

  const [todoPlaceholder, setToDoPlaceholder] = useState('');
  const [inProgressPlaceholder, setInProgressPlaceholder] = useState('');
  const [completedPlaceholder, setCompletedPlaceholder] = useState('');

  const [listsResponse, setListsResponse] = useState<TrelloAPIBoard[]>([]);
  const [listsOption, setListsOption] = useState<DropdownItem[]>([]);

  useEffect(() => {
    setAccessToken(trelloData.accessToken);
    setToDoPlaceholder(trelloData.board.todoList.listName || 'Select destination');
    setInProgressPlaceholder(trelloData.board.inProgressList.listName || 'Select destination');
    setCompletedPlaceholder(trelloData.board.completedList.listName || 'Select destination');
    setListsResponse([]); // TODO: Better naming
    setListsOption([]); // TODO: Better naming
  }, [trelloData]);

  useEffect(() => {
    setListsResponse([]);
    setListsOption([]);
  }, [trelloData.board.boardId]);

  const toDoLabel = (
    <TaskExportLabel>
      Tasks in
      &nbsp;
      <EmptyIcon />
      &nbsp;
      <b>To do</b>
      &nbsp;
      should always export to...
    </TaskExportLabel>
  );

  const inProgressLabel = (
    <TaskExportLabel>
      Tasks in
      &nbsp;
      <EllipseIcon />
      &nbsp;
      <b>In progress</b>
      &nbsp;
      should always export to...
    </TaskExportLabel>
  );

  const completedLabel = (
    <TaskExportLabel>
      Tasks in
      &nbsp;
      <CheckIcon />
      &nbsp;
      <b>Complete</b>
      &nbsp;
      should always export to...
    </TaskExportLabel>
  );

  const onToDoListChange = (selectedOption: DropdownItem) => {
    setToDoPlaceholder(selectedOption.name);
    updateList('todo', selectedOption, listsResponse, setTrelloData);
  };

  const onInProgressListChange = (selectedOption: DropdownItem) => {
    setInProgressPlaceholder(selectedOption.name);
    updateList('inProgress', selectedOption, listsResponse, setTrelloData);
  };

  const onCompletedListChange = (selectedOption: DropdownItem) => {
    setCompletedPlaceholder(selectedOption.name);
    updateList('completed', selectedOption, listsResponse, setTrelloData);
  };

  const onListDropdownClick = () => {
    if (accessToken.length > 10 && listsResponse.length === 0) {
      setListsLoading(true);
      getTrelloBoardsList(accessToken, trelloData.board.boardId)
        .then((response: ResolvedStateItem<TrelloAPIBoard[]>) => {
          setListsResponse(response.item);
          const options: DropdownItem[] = mapTrelloColumnsToDropdownItems(response.item);
          setListsOption(options);
        })
        .catch((error:any) => {
          console.log('Trello CF Failed', error);
        })
        .finally(() => {
          setListsLoading(false);
        });
    }
  };

  return (
    <SettingContainer>
      <SettingLabel>Trello List</SettingLabel>
      <ListSettingTab
        label={toDoLabel}
        onClick={onListDropdownClick}
        onChange={onToDoListChange}
        loading={listsLoading}
        options={listsOption}
        placeholder={todoPlaceholder}
      />
      <ListSettingTab
        label={inProgressLabel}
        onClick={onListDropdownClick}
        onChange={onInProgressListChange}
        loading={listsLoading}
        options={listsOption}
        placeholder={inProgressPlaceholder}
      />
      <ListSettingTab
        label={completedLabel}
        onClick={onListDropdownClick}
        onChange={onCompletedListChange}
        loading={listsLoading}
        options={listsOption}
        placeholder={completedPlaceholder}
      />
    </SettingContainer>
  );
}
