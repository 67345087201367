import React from 'react';
import CssSpinner from '../css-spinner';
import { COLORS } from '../../colors/COLORS';
import { ButtonStyle, SubmitButtonStyle, ErrorButtonStyle } from './ButtonStyles';

const styleMapping = {
  primary: ButtonStyle,
  submit: SubmitButtonStyle,
  error: ErrorButtonStyle,
};

const spinnerColorMapping = {
  primary: COLORS.gray8,
  submit: COLORS.blue8,
  error: COLORS.error8,
};

export interface ButtonSmallProps {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isDisabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
  Icon?: any;
  loading?: boolean;
  isOutline?: boolean;
  isText?: boolean;
  customWidth?: string;
  padding?: string;
  buttonVariant?: keyof typeof styleMapping;
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl';
}

const renderChildren = (props: ButtonSmallProps) => {
  const {
    loading, Icon, hasLeftIcon, hasRightIcon, text, buttonVariant = 'primary',
  } = props;

  const spinnerColor = spinnerColorMapping[buttonVariant];

  if (loading) {
    return <CssSpinner color={spinnerColor} />;
  }

  return (
    <>
      {hasLeftIcon && <Icon />}
      {text}
      {!hasRightIcon && !hasLeftIcon && Icon !== undefined && <Icon />}
      {hasRightIcon && <Icon />}
    </>
  );
};

const ButtonSmall = ({
  text, onClick, isDisabled, type, Icon, hasLeftIcon,
  hasRightIcon, loading, isOutline, isText, customWidth,
  padding, size = 's', buttonVariant = 'primary',
}: ButtonSmallProps) => {
  const ButtonComponent = styleMapping[buttonVariant];

  return (
    <ButtonComponent
      data-icon-only={(!hasRightIcon && !hasLeftIcon && Icon !== undefined) || loading}
      data-left-icon={hasLeftIcon}
      data-right-icon={hasRightIcon}
      data-outline={isOutline}
      data-text={isText}
      onClick={onClick}
      disabled={isDisabled || loading}
      type={type}
      data-custom-width={customWidth}
      padding={padding}
      size={size}
    >
      {renderChildren({
        Icon, text, hasLeftIcon, hasRightIcon, loading, buttonVariant,
      })}
    </ButtonComponent>
  );
};

ButtonSmall.defaultProps = {
  onClick: undefined,
  type: 'button',
  isDisabled: false,
  Icon: undefined,
  hasLeftIcon: false,
  hasRightIcon: false,
  loading: false,
  isOutline: false,
  isText: false,
  customWidth: '',
};

export default ButtonSmall;
