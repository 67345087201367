import React from 'react';

interface Props {
  size?: number,
}

const GoogleMeetLogo = ({ size }: Props) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.3376 20.0029L25.9189 24.0963L30.7344 27.1737L31.5741 20.0281L30.7344 13.042L25.8265 15.7457L22.3376 20.0029Z" fill="#00832D" />
    <path d="M1.55566 26.5105V32.5981C1.55566 33.9899 2.68293 35.1172 4.07469 35.1172H10.1623L11.4218 30.5157L10.1623 26.5105L5.98495 25.251L1.55566 26.5105Z" fill="#0066DA" />
    <path d="M10.1623 4.88867L1.55566 13.4953L5.98495 14.7549L10.1623 13.4953L11.4009 9.54467L10.1623 4.88867Z" fill="#E94235" />
    <path d="M10.1623 13.4951H1.55566V26.5101H10.1623V13.4951Z" fill="#2684FC" />
    <path d="M36.2343 8.53264L30.7344 13.0417V27.1734L36.2595 31.7035C37.0865 32.35 38.2957 31.7602 38.2957 30.7085V9.50666C38.2957 8.44237 37.0593 7.8588 36.2343 8.53264ZM22.3376 20.0026V26.5101H10.1624V35.1168H28.2154C29.6071 35.1168 30.7344 33.9895 30.7344 32.5977V27.1734L22.3376 20.0026Z" fill="#00AC47" />
    <path d="M28.2154 4.88867H10.1624V13.4953H22.3376V20.0028L30.7344 13.0461V7.4077C30.7344 6.01594 29.6071 4.88867 28.2154 4.88867Z" fill="#FFBA00" />
  </svg>
);

GoogleMeetLogo.defaultProps = {
  size: 24,
};

export default GoogleMeetLogo;
