import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { gray1, red3 } from '../colors/COLORS';
import ProfileIconFirstLetter from './ProfileIconFirstLetter';

interface ProfilePictureIconProp {
  size: number,
  borderWidth: number,
}

const ProfilePictureIcon = styled.img<ProfilePictureIconProp>`
  display: inline-flex;
  position: relative;

  background: ${gray1};

  border-radius: 50%;
  z-index: 18;
  width: ${(props) => props.size - props.borderWidth * 2}px;
  height: ${(props) => props.size - props.borderWidth * 2}px;
  border: ${(props) => props.borderWidth}px solid ${gray1};
`;

export interface Props {
  color?: string,
  email?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  name?: string,
  photoUrl?: string,
  /** Total width and height of icon, which includes borders */
  size?: number,
  nonUser?: boolean,
  // only affect profile picture
  borderWidth?: number
}

const Icon = ({
  color, email = '', photoUrl = '', size = 24, nonUser = false, borderWidth = 2,
}: Props) => {
  const [isProfileImage, setIsProfileImage] = useState<boolean>(false);
  const handleProfilePictureError = () => setIsProfileImage(false);

  useEffect(() => {
    const newVal = photoUrl?.length > 0 ?? false;
    setIsProfileImage(newVal);
  }, [photoUrl]);

  return (
    <>
      {isProfileImage
        ? (
          <ProfilePictureIcon
            src={photoUrl}
            onError={handleProfilePictureError}
            alt="user profile image"
            size={size}
            borderWidth={borderWidth}
          />
        )
        : (
          // the 14 here is the standard font size, 24 and 2 are the standrad icon size.
          // See the default font size definition in src/shared/typography/index.tsx
          <ProfileIconFirstLetter
            email={email}
            color={color}
            size={size}
            nonUser={nonUser}
          />
        )}
    </>
  );
};

Icon.defaultProps = {
  color: red3,
  email: '',
  name: '',
  photoUrl: '',
  size: 24,
};

export default Icon;
