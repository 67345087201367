import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../../../pages/onboarding/welcome/utils/animations';
import ShepherdIcon from '../../../icons/shepherd-logo/components/shepherd-icon';
import { ResolvedState } from '../../../types/types';

const Container = styled.div`
  position: absolute;
  top: 320px;
  @media only screen and (min-width: 1280px){
    top: 420px;
    left: 415px;
    margin-left: calc((100vw - 415px)/2);
  }
  @media only screen and (max-width: 1280px){
    left: 50%;
  }

  /* Fade in animation */
  opacity: 0;
  animation: ${fadeIn} ease-in-out forwards 3s;
  animation-delay: 1s;
`;

interface Props {
  resolvedState: ResolvedState,
}

const EditorLoadingSpinner = ({ resolvedState }: Props) => {
  if (resolvedState !== 'pending') return null;
  return (
    <Container>
      <ShepherdIcon spin />
    </Container>
  );
};

export default EditorLoadingSpinner;
