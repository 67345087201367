import React from 'react';
import styled from 'styled-components';
import { surface } from '../../../../../shared/colors/COLORS';
import { uiTextMedium } from '../../../../../shared/typography';

const Title = styled.span`
  ${uiTextMedium};
  color: ${surface};
`;

interface Props {
  title: string,
}

const TitleAtom = ({ title }: Props) => (
  <Title>
    {title}
  </Title>
);

export default TitleAtom;
