import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { AuthContext } from '../../../../App';
import { dbDeleteUserTemplate } from '../../../../database/firebaseTemplatesAPI';
import ConsoleImproved from '../../../../shared/classes/ConsoleImproved';
import { error6, red4 } from '../../../../shared/colors/COLORS';
import ButtonSmall from '../../../../shared/components/button-small';
import { ModalFooterRightButtonsWrapper } from '../../../../shared/components/Modal/ModalFooterWrapper';
import { editTemplate, mapDatabaseTemplateDataToTemplateData, prepareDatabaseTemplateData } from '../../../../shared/components/templates-modal/utils';
import { CSS_CONSTANTS } from '../../../../shared/CSS/CSS_Constants';
import {
  DatabaseTemplateData, MeetingSection, TemplateData, TemplateVersion,
} from '../../../../shared/types/types';
import { uiTextMedium } from '../../../../shared/typography';
import { DELETE_EVENT, TEMPLATES_NOTE_TEMPLATE_FIELD } from '../../../../utils/analytics/enums';
import { logTemplateUserAction } from '../../../../utils/analytics/eventLogger';
import { createHeadlessFirepad } from '../../../../utils/firebase';
import MeetingDataContext from '../../context/MeetingDataContext';
import ProseMirrorEditorViewContext from '../../context/ProseMirrorEditorViewContext';

interface Props {
  isEnabled: boolean
  meetingSection: MeetingSection
  selectedTemplateToEdit: TemplateData
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
}
const LeftMarginDeleteText = '18px';

const DeleteText = styled.div`
  position: absolute;
  bottom: 10px;
  left: ${LeftMarginDeleteText};

  @media only screen and (min-width: ${CSS_CONSTANTS.LEFT_MENU_SIDEBAR_MAX_VISIBLE_WIDTH}){
    left: calc(${CSS_CONSTANTS.LEFT_MENU_SIDEBAR_WIDTH} + ${LeftMarginDeleteText});
  }

  @media only screen and (min-width: ${CSS_CONSTANTS.MAX_WIDTH_FULL_PAGE}){
    left: calc((100vw - ${CSS_CONSTANTS.MAX_WIDTH_FULL_PAGE})*0.5 + ${CSS_CONSTANTS.LEFT_MENU_SIDEBAR_WIDTH} + ${LeftMarginDeleteText});
  }

  ${uiTextMedium};
  color: ${error6};
  cursor: pointer;
  padding: 4px 10px;
  background: white;
  border: 2px solid;
  border-radius: 10px;

  /* hover */
  &:hover {
    background: ${red4};
    color: white;
  }
`;

export default function TemplateEditBottomBar({
  isEnabled, selectedTemplateToEdit, setSelectedTemplateToEdit, meetingSection,
}: Props) {
  const [templateData, setTemplateData] = useState<TemplateData>(mapDatabaseTemplateDataToTemplateData('', ''));
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const authData = useContext(AuthContext);
  const meetingData = useContext(MeetingDataContext);
  const { editorView } = useContext(ProseMirrorEditorViewContext);

  const { trackEvent } = useIntercom();

  useEffect(() => {
    setTemplateData(selectedTemplateToEdit);
  }, [selectedTemplateToEdit]);

  const handleReset = () => {
    setSelectedTemplateToEdit(mapDatabaseTemplateDataToTemplateData('', ''));
  };

  const handleSaveClick = () => {
    if (meetingData.version === 2) {
      const headlessFirepad = createHeadlessFirepad(
        meetingSection,
        meetingData.meetingId,
        authData.userId,
      );
      headlessFirepad.getHtml((html: string) => {
        const newTemplateData = prepareDatabaseTemplateData(
          templateData.data.title, html, authData, meetingData,
        );
        handleUpdate(newTemplateData);
      });
    }

    if (meetingData.version >= 3) {
      const version: TemplateVersion = 2;
      const editorHtml = editorView?.dom?.innerHTML ?? '';
      ConsoleImproved.log('Saving new template with text', editorHtml);

      const newTemplateData = prepareDatabaseTemplateData(
        templateData.data.title,
        editorHtml,
        authData,
        meetingData,
        version,
      );
      handleUpdate(newTemplateData);
    }
  };

  const handleUpdate = (newTemplateData: DatabaseTemplateData) => {
    editTemplate(authData.userId, selectedTemplateToEdit.templateId,
      newTemplateData, handleReset, setIsSubmitLoading);
  };

  const handleDeleteClick = () => {
    dbDeleteUserTemplate(authData.userId, templateData.templateId);
    logTemplateUserAction(authData.userId, trackEvent, DELETE_EVENT,
      TEMPLATES_NOTE_TEMPLATE_FIELD, meetingData.version, meetingData.meetingId);
    handleReset();
  };

  if (!isEnabled) return null;

  return (
    <>
      <DeleteText onClick={handleDeleteClick}>Delete</DeleteText>
      <ModalFooterRightButtonsWrapper>
        <ButtonSmall onClick={handleReset} text="Cancel" isOutline />
        <ButtonSmall onClick={handleSaveClick} text="Save Changes" loading={isSubmitLoading} />
      </ModalFooterRightButtonsWrapper>
    </>
  );
}
