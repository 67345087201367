import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { darkBlue1, gray1 } from '../../../../shared/colors/COLORS';

const Input = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  padding: 10px 0px 10px 15px;
  background-color: ${gray1};
  border: 2px solid ${darkBlue1};
  box-sizing: border-box;
  border-radius: 8px;

  :focus-visible {
    outline: inherit;
  }
`;

interface Props {
  setTitle: Dispatch<SetStateAction<string>>,
  prevTitle?: string,
  placeholder?: string,
}

const NameInputAtom = ({ setTitle, prevTitle, placeholder }: Props) => {
  if (prevTitle?.length !== 0) {
    return (
      <Input onChange={(e) => setTitle(e.target.value)} autoFocus value={prevTitle} />
    );
  }
  return (
    <Input onChange={(e) => setTitle(e.target.value)} autoFocus placeholder={placeholder} />
  );
};

NameInputAtom.defaultProps = {
  prevTitle: '',
  placeholder: '',
};

export default NameInputAtom;
