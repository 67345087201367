import React, {
  Dispatch, SetStateAction, useContext, useEffect,
} from 'react';
import FirepadComponent from '../../../shared/components/firepad/component';
import { getFirepadPathV3, getFirepadPathV2, getFirepadSecretChatPath } from '../../../utils/firebase';
import ProseMirrorComponentV2 from '../../../shared/components/prosemirror/index';
import ProseMirrorComponentV1 from '../../../shared/components/prosemirror/index_old';
import MeetingDataContext from '../context/MeetingDataContext';
import PreviousMeetingDataContext from '../../../shared/components/previous-meeting-notes/context/PreviousMeetingDataContext';
import NotesLoading from './NotesLoading';
import { NoteType, Shortcut } from '../../../shared/types/types';
import { startTrackingEvent, stopTrackingEvent } from '../../../utils/analytics/eventLogger';
import { ALL_PAGES, MEETING_SECTION } from '../../../utils/enums';
import { NotesFor } from '../../../utils/analytics/enums';

interface NotesProps {
  noteType: NoteType,
  userId: string,
  meetingId: string,
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>
  isPreviousMeeting?: boolean
  // eslint-disable-next-line no-unused-vars
  onShortcutTrigger: (shortcut: Shortcut) => void,
  displayNotes?: boolean,
  secretChatId?: string,
  notesFor?: NotesFor,
}

const Notes = ({
  noteType,
  userId,
  meetingId,
  setProseMirrorEditorView,
  isPreviousMeeting = false,
  onShortcutTrigger,
  displayNotes = true,
  secretChatId,
  notesFor = ALL_PAGES.CURRENT_MEETING,
}: NotesProps) => {
  // We had an issue with the notes, that when running the CE to point to localhost,
  // and creating a new meeting in calendar then we would get an Permissions Denied error.
  // This was most likely because of realtime database security rules
  // Specificially the ".validate": "newData.hasChild('history')"
  // With the new database architecture, hopefully this issue will be resolved

  const meetingData = isPreviousMeeting
    ? useContext(PreviousMeetingDataContext)
    : useContext(MeetingDataContext);

  useEffect(() => {
    if (meetingData.resolvedState === 'pending') {
      startTrackingEvent('notesLoad');
    } else {
      stopTrackingEvent('notesLoad');
    }
  }, [meetingData.resolvedState]);

  if (meetingData.resolvedState === 'pending') return <NotesLoading />;

  if (secretChatId?.length !== 0 && noteType === MEETING_SECTION.PRIVATE_NOTES) {
    if (meetingData.version >= 5) {
      return (
        <ProseMirrorComponentV2
          path={getFirepadSecretChatPath(secretChatId!, 'prosemirror')}
          meetingSection={noteType}
          setProseMirrorEditorView={setProseMirrorEditorView}
          displayNotes={displayNotes}
          isPreviousMeeting={isPreviousMeeting}
          notesFor={notesFor}
        />
      );
    }

    if (meetingData.version >= 3) {
      return (
        <ProseMirrorComponentV1
          path={getFirepadSecretChatPath(secretChatId!, 'prosemirror')}
          page={noteType}
          setProseMirrorEditorView={setProseMirrorEditorView}
          displayNotes={displayNotes}
          isPreviousMeeting={isPreviousMeeting}
          notesFor={notesFor}
        />
      );
    }
    return (
      <>
        <FirepadComponent
          path={getFirepadSecretChatPath(secretChatId!, 'firepad')}
          setIsSynchronized={(dummy) => dummy}
          // eslint-disable-next-line no-unused-vars
          setHtml={(html) => { }}
          page={noteType}
          isPreviousMeeting={isPreviousMeeting}
          onShortcutTrigger={onShortcutTrigger}
          notesFor={notesFor}
        />
      </>
    );
  }
  // For meetingVersion 4 or above, we don't want to show the agenda notes
  // because we only want the user to use the shared notes
  if (meetingData.version >= 4 && noteType === MEETING_SECTION.AGENDA) return null;

  // TODO: This is a temporary forced change.
  // return (
  //   <ProseMirrorComponent
  //     path={getFirepadPathV3(page, meetingId, 'prosemirror', userId)}
  //     page={page}
  //     setHtml={setNotesContent}
  //     setIsSynchronized={setIsSynchronized}
  //     setProseMirrorEditorView={setProseMirrorEditorView}
  //     displayNotes={displayNotes}
  //     isPreviousMeeting={isPreviousMeeting}
  //   />
  // );
  if (meetingData.version >= 5) {
    const prosemirrorPath = getFirepadPathV3(noteType, meetingId, 'prosemirror', userId);
    return (
      <ProseMirrorComponentV2
        path={prosemirrorPath}
        meetingSection={noteType}
        setProseMirrorEditorView={setProseMirrorEditorView}
        displayNotes={displayNotes}
        isPreviousMeeting={isPreviousMeeting}
        notesFor={notesFor}
      />
    );
  }

  if (meetingData.version >= 3 && noteType !== MEETING_SECTION.PRIVATE_NOTES) {
    return (
      <ProseMirrorComponentV1
        path={getFirepadPathV2(noteType, meetingId, 'prosemirror', userId)}
        page={noteType}
        setProseMirrorEditorView={setProseMirrorEditorView}
        displayNotes={displayNotes}
        isPreviousMeeting={isPreviousMeeting}
        notesFor={notesFor}
      />
    );

    // return (
    //   <ProseMirrorComponent
    //     path={getFirepadPathV2(page, meetingId, 'prosemirror', userId)}
    //     page={page}
    //     setHtml={setNotesContent}
    //     setIsSynchronized={setIsSynchronized}
    //     setProseMirrorEditorView={setProseMirrorEditorView}
    //     displayNotes={displayNotes}
    //     isPreviousMeeting={isPreviousMeeting}
    //   />
    // );
  }
  return (
    <>
      <FirepadComponent
        path={getFirepadPathV2(noteType, meetingId, 'firepad', userId)}
        setIsSynchronized={(dummy) => dummy}
        // eslint-disable-next-line no-unused-vars
        setHtml={(html) => { }}
        page={noteType}
        isPreviousMeeting={isPreviousMeeting}
        onShortcutTrigger={onShortcutTrigger}
        notesFor={notesFor}
      />
    </>
  );
};

Notes.defaultProps = {
  isPreviousMeeting: false,
  displayNotes: true,
  secretChatId: '',
  notesFor: ALL_PAGES.CURRENT_MEETING,
};

export default Notes;
