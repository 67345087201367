import React from 'react';
import styled from 'styled-components';
import Font from '../../shared/typography/Font';

const Container = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;

  > span {
    margin-bottom: 20px;
  }

  > span:last-child {
    margin-bottom: 0;
  }
`;

const PrivacyModelContent = () => (
  <Container>
    <Font font="messageText">
      Our mission is to empower you with data to improve your work life.
      To do this, we need access to your calendar.
    </Font>

    <Font font="messageText">
      The calendar permissions are only used to be able to view your events
      inside Shepherd. We do not edit or delete any of your events.
    </Font>

    <Font font="messageText">
      We are committed to protecting your privacy and security and will never
      share your data with any third party. This is why we utelize the latest
      in encryption technology provided by Google, including Google Authentication.
      All traffic to our servers are secure and encrypted.
      {' '}
    </Font>

  </Container>
);

export default PrivacyModelContent;
