/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom-v5-compat';
import Header from '../../../shared/components/header';
import { useUI } from '../../all-pages/ui-context/uiContext';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../../shared/components/header/utils/constants';
import InviteTeammatesToWorkspacePage from '../InviteTeammates/InviteTeammatesToWorkspacePage';
import ROUTES from '../../../routes/ROUTES_CONSTANTS';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background-color: white;
`;

const InviteMembersToWorkspacePageCtrl = () => {
  const { setPage } = useUI();
  const navigate = useNavigate();

  const handleClickContinue = async () => {
    navigate(ROUTES.allNotes);
  };

  return (
    <Container>
      <Header setPage={setPage} view={DEFAULT_HEADER_MEETING_VIEW} />
      <InviteTeammatesToWorkspacePage onClickContinue={handleClickContinue} />
    </Container>
  );
};

export default InviteMembersToWorkspacePageCtrl;
