/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import Font from '../../shared/typography/Font';

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

interface ScratchpadTitleProps {
  title: string,
}

const ScratchpadTitle = ({ title }: ScratchpadTitleProps) => {
  return (
    <Container>
      <Font font="titleSemibold">{title}</Font>
    </Container>
  );
};

export default ScratchpadTitle;
