import React from 'react';
import styled from 'styled-components';
import { gray4 } from '../../colors/COLORS';
import { uiTextMedium } from '../../typography';
import { TaskStatus } from '../../types/types';
import TaskStatusIcon from './TaskStatusIcon';

const Container = styled.div`
  padding: 6px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-radius: 6px;
    background-color: ${gray4};
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const Text = styled.span`
  ${uiTextMedium}
  width: 244px;
`;

interface Props {
  status: TaskStatus
  text: string,
  onSelect: any,
}

const TaskStatusDropdownItem = ({ status, text, onSelect }: Props) => (
  <Container onClick={onSelect}>
    <IconWrapper>
      <TaskStatusIcon status={status} />
    </IconWrapper>
    <Text>
      {text}
    </Text>
  </Container>
);

export default TaskStatusDropdownItem;
