import React from 'react';
import styled, { css } from 'styled-components';
import { layoutGrey, surface, gray1 } from '../../../../shared/colors/COLORS';

type ContainerProps = {
  isWorkspace: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  background-color: ${gray1};
  color: ${surface};
  flex-direction: row;
  align-items: center;
  padding: 10px 16px 10px 14px;
  border-radius: 6px;
  width: 100%;
  :hover {
    background-color: ${layoutGrey};
    cursor: pointer;
  }

  ${(props) => props.isWorkspace && css`
    padding: 4px 8px;
  `}
`;

const Text = styled.span`
  margin-left: 8px;
`;

interface Props {
  icon: any,
  text: string,
  onClick?: () => void,
  isWorkspace?: boolean
}

const DropdownOption = ({
  icon, text, onClick, isWorkspace = false,
}: Props) => (
  <Container onClick={onClick} isWorkspace={isWorkspace}>
    {icon}
    <Text>
      {text}
    </Text>
  </Container>
);

DropdownOption.defaultProps = {
  onClick: () => { },
};

export default DropdownOption;
