import styled from 'styled-components';

const ModalBodyWrapper = styled.div`
  padding: 24px 24px 24px 24px;
  > div > div > div > p > img {
    max-width: 388px;
  }
`;

export default ModalBodyWrapper;
