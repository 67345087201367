import { REJECTED } from '../../../utils/enums';
import { toastDanger } from '../../../utils/notifications';
import { ResolvedState } from '../../types/types';
import Notification from './Notification';
import NotificationAPICore from './NotificationAPICore';

/**
 * Responsible for
 * - Calling methods of NotificationAPICore
 * - Handles response and conveys error to FE
 * - Handles pre-processing if required before calling methods of NotificationAPICore
 * @extends: NotificationCore
 * @method: static async listenToUserNotification(userId: string, updateStore: zustandStore)
 * @method: async addNotification() => void
 * @method: async updateNotification(updateObj: object) => void
 */
class NotificationAPI extends NotificationAPICore {
  static listenToUserNotifications = (
    // eslint-disable-next-line no-unused-vars
    userId: string, updateStore: (newNotifications: Notification[]) => void,
  ) => NotificationAPICore.dbListenNotification(userId, updateStore)

  /** Saves new notification object to DB, only when state==='not-synced' */
  static addNotificationToDb = async (notification: Notification): Promise<ResolvedState> => {
    if (notification.db.state === 'synced') return REJECTED;
    const notificationObjToSave = notification.getNotificationDatabase;
    const response = await NotificationAPICore.dbAddNotification(notificationObjToSave);
    if (response === 'rejected') {
      toastDanger('Failed sending notification', 'Please try again');
    }
    return response;
  };

  /** Updates notification object to DB */
  static updateNotificationInDb = async (notificationId: string, updatedObj: object) => {
    const response = await NotificationAPICore.dbUpdateNotification(notificationId, updatedObj);
    if (response === 'rejected') {
      toastDanger('Failed updating notification', 'please try after sometime');
    }
    return response;
  }
}

export default NotificationAPI;
