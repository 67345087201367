import {
  triggerEventMeetingHasAgenda,
  triggerEventMeetingHasPrivateNotes,
  triggerEventMeetingHasSharedNotes,
  triggerEventMeetingWithShepherdUsed,
  triggerLogUsedScratchpad,
} from '../../../../pages/meeting/events';
import { NotesFor } from '../../../../utils/analytics/enums';
import { shouldWeLog, shouldWeLogMeeting } from '../../../../utils/analytics/functions';
import { mapTimestampForLog } from '../../../../utils/meetings/meetingsUtils';
import {
  MeetingAnalyticsData, MeetingSection, SDateT, TimestampLog, User,
} from '../../../types/types';

const triggerLogEvent = (
  userData: User,
  isSidebarOpen: boolean,
  page: MeetingSection,
  meetingAnalyticsData: MeetingAnalyticsData,
  html: string,
  meetingId: string,
  intercomTrackEvent: any,
  start: SDateT,
  end: SDateT,
  noteFor: NotesFor,
) => {
  if (!shouldWeLog(userData, isSidebarOpen)) return;
  if (page === 'scratchpad') {
    triggerLogUsedScratchpad(userData, html);
    return;
  }
  if (!shouldWeLogMeeting(userData, isSidebarOpen, meetingId)) return;

  const logTimeStamp: TimestampLog = mapTimestampForLog(start, end);
  triggerEventMeetingWithShepherdUsed(meetingAnalyticsData,
    html, meetingId, userData.userId, logTimeStamp, noteFor, intercomTrackEvent);
  triggerEventMeetingHasSharedNotes(userData.userId, meetingAnalyticsData,
    html, page, meetingId, logTimeStamp, noteFor, intercomTrackEvent);
  triggerEventMeetingHasAgenda(userData.userId, meetingAnalyticsData,
    html, page, meetingId, logTimeStamp, noteFor, intercomTrackEvent);
  triggerEventMeetingHasPrivateNotes(userData.userId, meetingAnalyticsData,
    html, page, meetingId, logTimeStamp, noteFor, intercomTrackEvent);
};

export const mapOptionMenuNamesToTextEditorOptions = (name: string) => {
  switch (name) {
    case 'Mention':
      return 'mention';
    case 'Task':
      return 'insert_task';
    case 'Table':
      return 'insert_table';
    case 'Bullet_list':
      return 'insert_bullet_list';
    case 'Numbered_list':
      return 'insert_number_list';
    case 'Checkbox_list':
      return 'insert_checkbox_list';
    case 'Link':
      return 'insert_link';
    case 'Quote':
      return 'insert_quote';
    case 'Code':
      return 'insert_code';
    case 'Time_stamp':
      return 'insert_time_stamp';
    case 'Divider':
      return 'insert_divider';
    case 'Time_stamp_divider':
      return 'insert_time_stamp_divider';
    case 'Insert_template':
      return 'insert_template';
    case 'Save_as_template':
      return 'save_as_template';
    case 'Image':
      return 'insert_media';
    case 'GIF':
      return 'insert_gif';
    case 'Emoji':
      return 'insert_emoji';
    case 'Colour':
      return 'font_color';
    case 'Highlight':
      return 'font_highlight';
    case 'Shortcuts':
      return 'show_shortcut';
    default:
      return '';
  }
};

export default triggerLogEvent;
