import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

interface Props {
  icon: any,
}

const IconAtom = ({ icon }: Props) => (
  <Container>
    {icon}
  </Container>
);

export default IconAtom;
