import React from 'react';
import styled from 'styled-components';
import { StripeConstants } from '../../../../../external/Stripe/StripeConstants';
import Font from '../../../../../shared/typography/Font';

const UL = styled.ul`
  text-align: left;

  li:last-of-type {
    margin-bottom: 0;
  }
  margin-bottom: 0;
`;

const AreYouSureTextAtom = () => (
  <Font font="uiTextS" color="gray7" id="announcement">
    If you choose to downgrade to the free plan,
    <br />
    you will be limited to:
    <UL>
      <li>
        Only
        {' '}
        <b>
          {StripeConstants.FREE_PLAN.MAX_MEETINGS}
          {' '}
          meeting notes
        </b>
        {' '}
        per month
      </li>
      <li>
        Searchable notes history
        {' '}
        <b>
          {StripeConstants.FREE_PLAN.MAX_PAST_SEARCH_DAYS}
          {' '}
          days
        </b>
        {' '}
        in the past and
        {' '}
        <b>
          {StripeConstants.FREE_PLAN.MAX_FUTURE_SEARCH_DAYS}
          {' '}
          days
        </b>
        {' '}
        in the future
      </li>
    </UL>
  </Font>
);

export default AreYouSureTextAtom;
