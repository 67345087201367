import React from 'react';

interface TrelloIconProps {
  width?: string
}

const TrelloIcon = ({
  width = '40',
}: TrelloIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={width}
    viewBox="0 0 172 172"
    style={{ fill: '#000000' }}
  >
    <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
      <path d="M0,172v-172h172v172z" fill="none" />
      <path d="M43,172c-23.74824,0 -43,-19.25176 -43,-43v-86c0,-23.74824 19.25176,-43 43,-43h86c23.74824,0 43,19.25176 43,43v86c0,23.74824 -19.25176,43 -43,43z" fill="#1e88e5" />
      <g fill="#ffffff"><path d="M130.032,28.208h-88.064c-7.59552,0 -13.76,6.16448 -13.76,13.76v88.064c0,7.59552 6.16448,13.76 13.76,13.76h88.064c7.59552,0 13.76,-6.16448 13.76,-13.76v-88.064c0,-7.59552 -6.16448,-13.76 -13.76,-13.76zM74.992,116.272c0,3.0272 -2.4768,5.504 -5.504,5.504h-19.264c-3.0272,0 -5.504,-2.4768 -5.504,-5.504v-66.048c0,-3.0272 2.4768,-5.504 5.504,-5.504h19.264c3.0272,0 5.504,2.4768 5.504,5.504zM127.28,83.248c0,3.0272 -2.4768,5.504 -5.504,5.504h-19.264c-3.0272,0 -5.504,-2.4768 -5.504,-5.504v-33.024c0,-3.0272 2.4768,-5.504 5.504,-5.504h19.264c3.0272,0 5.504,2.4768 5.504,5.504z" /></g>
    </g>

  </svg>

);

TrelloIcon.defaultProps = {
  width: '40',
};

export default TrelloIcon;
