import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { Page } from '../../shared/types/types';
import { QueryParameters } from '../meetings/QueryUtils';
import { isValidPage } from '../tasks/tasksUtils';
import useGetQueryParam from './useGetParams';

// eslint-disable-next-line no-unused-vars
type SetPageType = (newPage: Page) => void;

/**
 * Hook to set the page query parameter if found in URL
 * @example
 * `http://localhost:3000/meeting/L2f2zaA7gfCasIEfVTr8?page=currentMeeting`
 * calling `useSetPageQueryParameter(setPage)` will call `setPage('currentMeeting')
 *
 * @param setPage function to be called if a valid page query parameter is found in the URL
 */
const useSetPageQueryParameter = (setPage: SetPageType) => {
  const location = useLocation();

  const navigateToPage = useCallback(() => {
    const topPage = useGetQueryParam(QueryParameters.PAGE);
    if (!isValidPage(topPage)) return;
    setPage(topPage);
  }, [location]);

  useEffect(() => {
    navigateToPage();
  }, [navigateToPage]);
};

export default useSetPageQueryParameter;
