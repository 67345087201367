interface ICursorPosition {
  positions: Map<string, number>;
  // eslint-disable-next-line no-unused-vars
  add: (meetingId: string, tab: string, position: number) => void;
  // eslint-disable-next-line no-unused-vars
  getPositionByPath: (meetingId: string, tab: string) => number;
}

/**
 * Stores Cursor Positions for tabs and meetings.
 */
class CursorPositions implements ICursorPosition {
  positions: Map<string, number>;

  constructor() {
    this.positions = new Map();
  }

  add(meetingId: string, tab: string, position: number) {
    const path = CursorPositions.makePath(meetingId, tab);
    this.positions.set(path, position);
  }

  /**
   * @example
   * add('12321321fs', 'sharedNotes') => 432
   * add('12321321fs', 'private') => 42
   */
  getPositionByPath(meetingId: string, tab: string) {
    const path = CursorPositions.makePath(meetingId, tab);
    return this.positions.get(path) || 0;
  }

  static makePath(meetingId: string, tab: string) {
    return `${meetingId}/${tab}`;
  }
}

export default CursorPositions;
