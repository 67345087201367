/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/browser';
import {
  addDoc,
  collection, deleteDoc, doc, limit, orderBy, query, updateDoc, where,
  onSnapshot,
} from 'firebase/firestore';
import { REJECTED, RESOLVED } from '../../../utils/enums';
import { firestore } from '../../../utils/firebase';
import { NotificationDatabase, ResolvedState } from '../../types/types';
import Notification from './Notification';
import { COLLECTIONS } from '../../../database/FirebaseConstants';

/**
 * Responsible for
 * - Interaction with firebase
 * - Handle error logging to sentry
 * - Converting response to proper datatype
 * @access: protected
 * @method: async dbListen(userId: string, updateStore: zustandStore)
 * @method: async dbAdd(notificationData: NotificationDatabase) => Promise<ResolvedState>
 * @method: async dbUpdate(notificationId: string, updateField: Object) => Promise<ResolvedState>
 * @method: async dbDelete(notificationId: string) => Promise<ResolvedState>
 */
class NotificationAPICore {
  /** Adds a listener to users notification and updates the notificationStore */
  protected static dbListenNotification = (
    // eslint-disable-next-line no-unused-vars
    userId: string, updateStore: (newNotifications: Notification[]) => void,
  // ) => firestore()
  //   .collection(COLLECTIONS.NOTIFICATIONS)
  //   .where('recipient.userId', '==', userId)
  //   .orderBy('created.timestamp', 'desc')
  //   .limit(50)
  //   .onSnapshot((snapshot) => {
  ) => {
    try {
      const c = collection(firestore, COLLECTIONS.NOTIFICATIONS);
      const q = query(c,
        where('recipient.userId', '==', userId),
        orderBy('created.timestamp', 'desc'),
        limit(50));
      return onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs
          .map((document) => new Notification(document.id, document.data()));
        updateStore(docs);
      });
    } catch (error) {
      console.error(error);
      return () => {};
    }
  }

  /** Inserts a new notification to notification collection */
  protected static dbAddNotification = async (
    notificationData: NotificationDatabase,
  // ) => firestore()
  //   .collection(COLLECTIONS.NOTIFICATIONS)
  //   .add(notificationData)
  ) => addDoc(collection(firestore, COLLECTIONS.NOTIFICATIONS), notificationData)
    .then((response) => {
      console.log(response.id);
      return RESOLVED as ResolvedState;
    })
    .catch((error) => {
      console.error(error);
      Sentry.captureException(error);
      return REJECTED as ResolvedState;
    })

  /** Updates a notification with the field provided */
  protected static dbUpdateNotification = async (
    notificationId: string, updates: Object,
  // ) => firestore()
  //   .collection(COLLECTIONS.NOTIFICATIONS)
  //   .doc(notificationId)
  //   .update(updateField)
  ) => updateDoc(doc(firestore, COLLECTIONS.NOTIFICATIONS, notificationId), updates as any)
    .then((response) => {
      console.log(response);
      return RESOLVED as ResolvedState;
    })
    .catch((error) => {
      console.error(error);
      Sentry.captureException(error);
      return REJECTED as ResolvedState;
    })

  /** Deletes a notification with the id provided */
  protected static dbDeleteNotification = async (
    notificationId: string,
  // ) => firestore()
  //   .collection(COLLECTIONS.NOTIFICATIONS)
  //   .doc(notificationId)
  //   .delete()
  ) => deleteDoc(doc(firestore, COLLECTIONS.NOTIFICATIONS, notificationId))
    .then((response) => {
      console.log(response);
      return RESOLVED as ResolvedState;
    })
    .catch((error) => {
      console.error(error);
      Sentry.captureException(error);
      return REJECTED as ResolvedState;
    })
}

export default NotificationAPICore;
