import { captureException } from '@sentry/react';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { ResolvedState, SendGridEmailRecipient } from '../../shared/types/types';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { toastDanger } from '../../utils/notifications';
import ResolvedStateSmart from '../../utils/ResolvedState/ResolvedStateSmart';
import SentryAPI from '../../utils/analytics/SentryAPI';
import CloudFunctions from '../../database/CloudFunctions';

abstract class EmailCore {
  protected static sendBasicEmailCore = async (
    emailRecipients: string[],
    subject: string,
    mainContentHTML: string,
  ): Promise<ResolvedState> => CloudFunctions().sendBasicEmail2V2({
    emailRecipients, subject, mainContentHTML,
  })
    .then(() => RESOLVED as ResolvedState)
    .catch((error) => {
      console.error(`Error in EmailCore.sendBasicEmailCore ${error.message}`, {
        error, emailRecipients, subject, mainContentHTML,
      });
      captureException(error, {
        extra: {
          emailRecipients, subject, mainContentHTML, functionName: 'EmailCore.sendBasicEmailCore',
        },
      });
      return REJECTED as ResolvedState;
    });

  protected static cfSendMeetingNotesEmailCore = async (
    emails: SendGridEmailRecipient[], meetingTitle: string, html: string, templateId: string,
  ) => CloudFunctions().sendMeetingNotes({
    emails, meetingTitle, html, templateId,
  })
    .catch((error) => {
      console.error(`Error in EmailCoreAPI.cfSendMeetingNotesEmailCore ${error.message}`, {
        error, emails, meetingTitle, html, templateId,
      });
      captureException(error, {
        extra: {
          emails, meetingTitle, html, templateId, functionName: 'EmailCoreAPI.cfSendMeetingNotesEmailCore',
        },
      });
      throw new Error(error);
    });

  protected static cfSendQuickFeedbackEmailCore = async (
    email: string, feedback: string,
  ) => CloudFunctions().sendQuickFeedback({
    email, feedback,
  })
    .catch((error) => {
      console.error(`Error in EmailCoreAPI.cfSendQuickFeedbackEmailCore ${error.message}`, { error, email, feedback });
      captureException(error, { extra: { email, feedback, functionName: 'EmailCoreAPI.cfSendQuickFeedbackEmailCore' } });
      throw new Error(error);
    });

  // protected static cfGetAssigneeDataByEmailsCore = async (emails: string[]) => functions()
  //   .httpsCallable('searchAssigneeDataByEmails')({ emails })
  protected static cfGetAssigneeDataByEmailsCore = async (emails: string[]) => CloudFunctions()
    .searchAssigneeDataByEmails({ emails })
    .catch((error) => {
      console.error(`Error in EmailCoreAPI.cfGetAssigneeDataByEmailsCore ${error.message}`, { error, emails });
      captureException(error, { extra: { emails, functionName: 'EmailCoreAPI.cfGetAssigneeDataByEmailsCore' } });
      toastDanger('Error fetching user data', error?.message ?? 'Error');
      return {
        data: [],
      };
    });

  protected static cfSendTaskChangeNotificationEmailCore = (
    email: string,
    operatorName: string,
    actionDescription: string,
    taskAssignee: string,
    taskTitle: string,
    dueDateDisplay: 'none' | 'unset',
    taskDueDate: string,
    overdueColor: string,
    iconUrl: string,
    description: string,
  ) => CloudFunctions().sendTaskChangeNotification({
    email,
    operatorName,
    actionDescription,
    taskAssignee,
    taskTitle,
    dueDateDisplay,
    taskDueDate,
    overdueColor,
    iconUrl,
    description,
  })
    .then(() => {
      ConsoleImproved.log('cfSendTaskChangeNotificationEmailCore', {
        email,
        operatorName,
        actionDescription,
        taskAssignee,
        taskTitle,
        dueDateDisplay,
        taskDueDate,
        overdueColor,
        iconUrl,
        description,
      });
    })
    .catch((error) => {
      console.error(`Error in EmailCoreAPI.cfSendTaskChangeNotificationEmailCore ${error.message}`, {
        error,
        email,
        operatorName,
        actionDescription,
        taskAssignee,
        taskTitle,
        dueDateDisplay,
        taskDueDate,
        overdueColor,
        iconUrl,
        description,
      });
      captureException(error, {
        extra: {
          email, operatorName, actionDescription, taskAssignee, taskTitle, dueDateDisplay, taskDueDate, overdueColor, iconUrl, description, functionName: 'EmailCoreAPI.cfSendTaskChangeNotificationEmailCore',
        },
      });
      throw new Error(error);
    });

  static cfSendWelcomeEmailCore = async (
    firstName: string,
  ): Promise<ResolvedState> => CloudFunctions().sendWelcomeEmailV2({ name: firstName })
    .then((response) => {
      console.log('response from sendWelcomeEmailV2', response);
      return RESOLVED as ResolvedState;
    })
    .catch((error) => {
      console.error(`Error in EmailCoreAPI.cfSendWelcomeEmailCore ${error.message}`, { error, firstName });
      captureException(error, { extra: { firstName, functionName: 'EmailCoreAPI.cfSendWelcomeEmailCore' } });
      return 'rejected';
    });

  protected static cfSendInviteMembersEmailsCore = async (
    emails: string[],
  ): Promise<ResolvedState> => CloudFunctions().sendInviteMembersEmailsV2({ emails })
    .then(() => 'resolved' as ResolvedState)
    .catch((error) => {
      console.error(`Error in EmailCoreAPI.cfSendInviteMembersEmailsCore ${error.message}`, { error, emails });
      captureException(error, { extra: { emails, functionName: 'EmailCoreAPI.cfSendInviteMembersEmailsCore' } });
      return 'rejected';
    });

  protected static sendWorkspaceInviteCore = async (
    recipientEmail: string, recipientName: string, workspaceName: string,
    senderName: string, senderEmail: string,
  ): Promise<ResolvedStateSmart> => CloudFunctions().sendWorkspaceInviteEmailFunctionV3({
    recipientEmail, recipientName, workspaceName, senderName, senderEmail,
  })
    .then(() => ResolvedStateSmart.RESOLVED)
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('EmailCore.sendWorkspaceInviteCore', error, recipientEmail, recipientName, workspaceName, senderName, senderEmail);
      return ResolvedStateSmart.REJECTED;
    });

  protected static sendNewUserInviteAndWorkspaceInviteCore = async (
    recipientEmail: string, workspaceName: string,
    senderName: string, senderEmail: string,
  ): Promise<ResolvedStateSmart> => CloudFunctions().sendNewUserInviteAndWorkspaceInviteV3({
    recipientEmail, workspaceName, senderName, senderEmail,
  })
    .then(() => ResolvedStateSmart.RESOLVED)
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('EmailCore.sendNewUserInviteAndWorkspaceInvite', error, recipientEmail, workspaceName, senderName, senderEmail);
      return ResolvedStateSmart.REJECTED;
    });
}

export default EmailCore;
