import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../../shared/colors/COLORS';

const Container = styled.div`
  /* width: 195px;
  height: 50px; */
  padding: 12px 42px;
  cursor: pointer;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  /* background: linear-gradient(180deg, #E175FF 0%, #B6ACFF 100%);
  border-radius: 4px; */

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* color: white; */
  color: ${COLORS.upgradeNowPurple};
  text-align: center;

  /* background: linear-gradient(180deg, #5A3FFF -28.15%, #D792E5 96.66%); */
  background: rgba(197, 187, 255, 0.7);
  box-shadow: 0px 4px 4px rgba(139, 139, 139, 0.25);
  border-radius: 10px;

  :hover {
    animation: pulse 1s infinite;
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.06);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;

interface Props {
  onClick: () => void;
}

const UpgradeNowButton = ({ onClick }: Props) => (
  <Container onClick={onClick}>Upgrade now</Container>
);

export default UpgradeNowButton;
