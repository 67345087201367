/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, {
  createContext,
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled, { css } from 'styled-components';
import { EditorView } from 'prosemirror-view';
import { useIntercom } from 'react-use-intercom';
import { MeetingSection } from '../../types/types';
import useProseMirrorFirebase from './hooks/use-prose-mirror-firebase';
import useProseMirrorYjs from './hooks/use-prosemirror-yjs';
import 'prosemirror-menu/style/menu.css';
import 'prosemirror-view/style/prosemirror.css';
import './styles/menu.css';
import './styles/editor.css';
import './styles/images.css';
import FileUploadModal from '../Modal/FileUploadModal';
import EmojiFloatingPanel from './components/emoji-floating-panel';
import MeetingDataContext from '../../../pages/meeting/context/MeetingDataContext';
import useEmojiSuggester from './hooks/use-emoji-suggester';
import useMentionsSuggester from './hooks/use-mention-suggester';
import MentionsFloatingPanel from './components/mentions-floating-panel';
import useCommandsSuggester from './hooks/use-command-suggester';
import CommandsFloatingPanel from './components/commands-floating-panel';
import CustomEditorMenu from './components/menu';
import GifSearchModal from '../Modal/GifSearchModal';
import keymapAdapter from './logic/keymap/keymap-adapter';
import triggerLogEvent from './analytics';
import { SidebarStateContext } from '../../../ListenToSidebarMessageContainer';
import MeetingAnalyticsDataContext from '../../../pages/meeting/context/MeetingAnalyticsDataContext';
import { UserDataContext } from '../../../App';
import { IsOnlineContext } from '../../../utils/context/DetectInternetConnectionContainer';
import RevisionModal from '../Modal/RevisionModal';
import { darkBlue1 } from '../../colors/COLORS';
import { uiText, uiTextMedium } from '../../typography';
import SaveTemplateModal from '../templates-modal';
import commandAdapter from './logic/suggestions/command-adapter';
import ShortcutsModal from '../shortcuts';
import useTaskControls from './hooks/use-task-controls';
import TaskStatusFloatingPanel from './components/tasks/task-status-floating-panel';
import TaskCalendarFloatingPanel from './components/tasks/task-calendar-floating-panel';
import LinkPreviewModal from './components/widgets/link-preview';
import TableControls from './components/widgets/table-controls';
import useLinkPreviewer from './hooks/use-link-previewer';
import useTableControls from './hooks/use-table-controls';
import DateSelect from './components/tasks/task-date-select';
import Scrollbar from '../scrollbar';
import AssigneePicker from './components/tasks/task-choose-asignee';
import tableControlsAdapter from './logic/adapters/table-controls-adapter';
import TaskModal from './components/tasks/task-modal';
import EditorLoadingSpinner from './components/EditorLoadingSpinner';
import { ALL_PAGES } from '../../../utils/enums';
import { NotesFor } from '../../../utils/analytics/enums';
import { ifOnFreePlanAddMeetingToMeetingsUsed } from '../../../pages/meeting/events';
import { HorizontalLine } from '../../../pages/meeting/HorizontalLine';

export interface EditorContextType {
  update: Object;
  setUpdate: CallableFunction;
  view: EditorView | null;
  openImageModal: CallableFunction;
  openGifModal: CallableFunction;
  openRevisionModal: CallableFunction;
  isInsideTable: boolean;
}

export const EditorContext = createContext<EditorContextType | null>(null);

interface ProseMirrorProps {
  path: string;
  canEdit?: boolean;
  meetingSection: MeetingSection;
  displayNotes: boolean,
  setProseMirrorEditorView?: Dispatch<SetStateAction<any>>,
  isPreviousMeeting?: boolean
  notesFor?: NotesFor,
  // eslint-disable-next-line no-unused-vars
  setNotes?: (newText: string) => void,
}

interface EditorContainerStyleProp {
  displayNotes: boolean
  isPreviousMeeting: boolean
  isOnline: boolean,
}

const EditorContainer = styled.div<EditorContainerStyleProp>`
  ${({ displayNotes }) => (!displayNotes) && css`
    display: none;
  `}
  ${({ isPreviousMeeting }) => (isPreviousMeeting) && css`
    height: 100%;
  `}
  // TODO Alex: We might not need this after you completely remove the text editor
  ${({ isOnline }) => (!isOnline) && css` 
    opacity: 0.5;
  `}
`;

const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LostConnectionTab = styled.div`
  height: calc(100vh - 320px);
  width: 100%;
  background-color: ${darkBlue1};
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
  ${uiTextMedium}
`;

interface EditorStyleProp {
  isPreviousMeeting: boolean
}

const Editor = styled.div<EditorStyleProp>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // Be careful about this!
  // Will affect the horizontal scrolling
  /* min-height: ${({ isPreviousMeeting }) => (isPreviousMeeting ? '100%' : 'calc(100vh - 320px - 30px)')}; */
  height: ${({ isPreviousMeeting }) => (isPreviousMeeting ? '100%' : 'calc(100vh - 320px - 30px)')};
  overflow-y: auto;
  margin-bottom: 0;
  cursor: text;
`;

const ProseMirrorComponentV2 = ({
  path,
  canEdit,
  meetingSection,
  displayNotes,
  setProseMirrorEditorView,
  isPreviousMeeting = false,
  notesFor = ALL_PAGES.CURRENT_MEETING,
  setNotes = (dummy: string) => { },
}: ProseMirrorProps) => {
  const isSidebarOpen = useContext(SidebarStateContext);
  const meetingAnalyticsData = useContext(MeetingAnalyticsDataContext);
  const {
    meetingId, date: { start, end },
    resolvedState: meetingResolvedState,
  } = useContext(MeetingDataContext);
  const userData = useContext(UserDataContext);
  const { userId } = userData;
  const { trackEvent } = useIntercom();
  const [isTimerOn, setIsTimerOn] = useState(false);

  const [modal, setModal] = useState(false);

  const [gifModal, setGifModal] = useState(false);

  const [revisionModal, setRevisionModal] = useState(false);

  const [update, setUpdate] = useState({});

  const isOnline = useContext(IsOnlineContext);

  const [flag, setFlag] = useState(0);

  const {
    emojiShow,
    emojiLeft,
    emojiTop,
    emojiContents,
    emojiSelected,
  } = useEmojiSuggester();

  const {
    mentionsShow,
    mentionsLeft,
    mentionsTop,
    mentionsContents,
    mentionsSelected,
    mentionPromotionShow,
  } = useMentionsSuggester();

  const [proseMirrorEditor,
    editorView,
    editorSchema,
    getDbRef,
    editorReadonly,
    loading,
  ] = useProseMirrorYjs({
    path,
    canEdit,
    meetingSection,
    toggleImageUpload: () => { setModal(true); },
    username: `${userData.data.firstName} ${userData.data.lastName || ''}`,
    userId,
    meetingId,
  });

  useEffect(() => {
    if (flag === 0) return;
    if (!editorView) return;
    const newText = editorView?.dom?.innerHTML ?? '';
    setNotes(newText);
  }, [flag]);

  const [saveTemplateModal, setSaveTemplateModal] = useState<boolean>(false);

  const [shortcutsModal, setShortcutsModal] = useState<boolean>(false);

  const {
    commandsShow,
    commandsLeft,
    commandsTop,
    commandsContents,
    commandsSelected,
  } = useCommandsSuggester({
    view: editorView!,
    openGifModal: () => {
      setGifModal(true);
    },
    openImageModal: () => {
      setModal(true);
    },
    saveTemplate: () => {
      setSaveTemplateModal(true);
    },
    openShortcutsModal: () => {
      setShortcutsModal(true);
    },
  });

  const {
    lpShow,
    lpAnchor,
    lpAnchorNode,
    lpHref,
  } = useLinkPreviewer();

  const {
    tControlShow,
    tControlCtxMenu,
    tControlTableRect,
    tControlCellRect,
  } = useTableControls();

  const [showDisconnected, setShowDisconnected] = useState(false);

  const [
    disconnectTimeout,
    setDisconnectTimeout,
  ] = useState<ReturnType<typeof setTimeout> | undefined>(undefined);

  // useEffect(() => {
  //   if (editorReadonly) {
  //     setDisconnectTimeout(setTimeout(() => setShowDisconnected(true), 3000));
  //   } else {
  //     if (typeof disconnectTimeout !== 'undefined') {
  //       clearTimeout(disconnectTimeout);
  //     }
  //     setShowDisconnected(false);
  //   }
  //   return () => {
  //     if (typeof disconnectTimeout !== 'undefined') {
  //       clearTimeout(disconnectTimeout);
  //     }
  //   };
  // }, [editorReadonly]);

  useEffect(() => {
    if (!editorView) return;

    // It takes a bit of time for the editor to be ready
    [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000].forEach((i) => {
      setTimeout(() => {
        setFlag((prev) => prev + 1);
      }, i);
    });

    if (setProseMirrorEditorView) setProseMirrorEditorView({ page: meetingSection, editorView });
  }, [editorView]);

  useEffect(() => {
    if (!editorView) return;
    ifOnFreePlanAddMeetingToMeetingsUsed(userData, meetingId, editorView.dom?.innerHTML ?? '');

    if (isTimerOn) return;
    setIsTimerOn(true);
    setTimeout(() => { setIsTimerOn(false); }, 1000);

    // Since we want to make sure we have loaded the editor before we start tracking
    const localMeetingId = meetingResolvedState === 'resolved' ? meetingId : '';
    triggerLogEvent(
      userData,
      isSidebarOpen,
      meetingSection,
      meetingAnalyticsData,
      editorView.dom?.innerHTML ?? '',
      localMeetingId,
      trackEvent,
      start, end, notesFor,
    );
  }, [
    editorView?.dom?.innerHTML, meetingAnalyticsData, meetingId,
    meetingResolvedState, userData.userId, isSidebarOpen,
  ]);

  const {
    statusMenuShow,
    tasksLeft,
    tasksTop,
    status,
    statusFn,
    taskCalendarShow,
    dueDate,
    dateFn,
    assigneeMenuShow,
    assignee,
    assigneeFn,
    selectedDateIndex,
    taskModalShow,
    deleteModalShow,
    taskItem,
  } = useTaskControls();

  return (
    <EditorContext.Provider
      value={{
        update,
        setUpdate,
        view: editorView,
        openImageModal: () => {
          setModal(true);
        },
        openGifModal: () => {
          setGifModal(true);
        },
        openRevisionModal: () => {
          setRevisionModal(true);
        },
        isInsideTable: tControlShow[0],
      }}
    >
      <EditorContainer
        displayNotes={displayNotes}
        isPreviousMeeting={isPreviousMeeting}
        isOnline={isOnline}
      >
        <MenuContainer>
          <CustomEditorMenu
            hide={editorReadonly}
            schema={editorSchema!}
            view={editorView!}
            setUpdate={setUpdate}
            legacy={false}
          />
        </MenuContainer>
        {/* <Scrollbar maxHeight="calc(100vh - 320px - 30px)"> */}
        <Editor
          id="editor"
          className={`${editorReadonly ? 'readonly-grey' : ''}`}
          style={editorReadonly ? { display: 'none' } : {}}
          ref={proseMirrorEditor}
          isPreviousMeeting={isPreviousMeeting}
          onKeyDown={(e) => {
            // On Ctrl + K, activate the link menu.
            if (e.key === 'k' && e.ctrlKey) {
              e.preventDefault();
              keymapAdapter.openLinkMenu();
              // keymapAdapter.focusLinkMenu();

              // On Ctrl + /, clear formatting.
            } else if (e.key === '/' && e.ctrlKey) {
              e.preventDefault();
              keymapAdapter.clearFormatting(editorView);

              // On Escape, close some menus.
            } else if (e.key === 'Escape') {
              keymapAdapter.closeLinkMenu();
              tableControlsAdapter.closePreviewControls();
            }
            setUpdate({});
          }}
          onClick={() => {
            if (!editorView?.hasFocus()) {
              editorView?.focus();
            }
            setUpdate({});
          }}
          onKeyUp={() => { setUpdate({}); }}
          onKeyPress={() => { setUpdate({}); }}
        />
        <EditorLoadingSpinner resolvedState={loading}></EditorLoadingSpinner>
        {/* </Scrollbar> */}
        <FileUploadModal
          path={path}
          view={editorView!}
          schema={editorSchema!}
          isOpen={modal}
          setModalClosed={() => setModal(false)}
        />
        <GifSearchModal
          view={editorView!}
          schema={editorSchema!}
          isOpen={gifModal}
          setModalClosed={() => setGifModal(false)}
        />
        <RevisionModal
          view={editorView!}
          schema={editorSchema!}
          isOpen={revisionModal}
          getDbRef={getDbRef}
          setModalClosed={() => setRevisionModal(false)}
        />
        <EmojiFloatingPanel
          show={emojiShow[0]
            && !modal && !gifModal && !saveTemplateModal && !shortcutsModal}
          left={emojiLeft[0]}
          top={emojiTop[0]}
          contents={emojiContents[0]}
          selectedIndex={emojiSelected[0]}
          view={editorView}
        />
        <MentionsFloatingPanel
          show={mentionsShow[0]
            && !modal && !gifModal && !saveTemplateModal && !shortcutsModal}
          left={mentionsLeft[0]}
          top={mentionsTop[0]}
          contents={mentionsContents[0]}
          selectedIndex={mentionsSelected[0]}
          showPromotion={mentionPromotionShow[0]}
          view={editorView}
        />
        <CommandsFloatingPanel
          show={commandsShow[0]
            && !modal && !gifModal && !saveTemplateModal && !shortcutsModal}
          left={commandsLeft[0]}
          top={commandsTop[0]}
          contents={commandsContents[0]}
          selectedIndex={commandsSelected[0]}
          view={editorView}
        />
        <SaveTemplateModal
          isTemplateModalOpen={saveTemplateModal}
          setModalClose={() => setSaveTemplateModal(false)}
          openTemplates={() => commandAdapter.openTemplates()}
          meetingSection={meetingSection}
        />
        <ShortcutsModal
          isOpen={shortcutsModal}
          setIsOpen={setShortcutsModal}
        />
        <TaskStatusFloatingPanel
          show={statusMenuShow[0]}
          left={tasksLeft[0]}
          top={tasksTop[0]}
          status={status[0]}
          updateStatus={statusFn[0]}
          closeContents={() => {
            statusMenuShow[1](false);
          }}
        />
        <DateSelect
          show={taskCalendarShow[0]}
          left={tasksLeft[0]}
          top={tasksTop[0]}
          closeContents={() => {
            taskCalendarShow[1](false);
          }}
          dueDate={dueDate[0]}
          updateDate={dateFn[0]}
          selectedIndex={selectedDateIndex[0]}
        />
        <AssigneePicker
          show={assigneeMenuShow[0]}
          left={tasksLeft[0]}
          top={tasksTop[0]}
          closeContents={() => {
            assigneeMenuShow[1](false);
          }}
          currentAssignee={assignee[0]}
          updateAssignee={assigneeFn[0]}
          view={editorView}
        />
        <TaskModal
          taskItem={taskItem[0]}
          isTaskDetailsOpen={taskModalShow[0]}
          setTaskDetailsOpen={taskModalShow[1]}
          isDeleteModalOpen={deleteModalShow[0]}
          setDeleteModalOpen={deleteModalShow[1]}
        />
      </EditorContainer>
      <LinkPreviewModal
        show={lpShow[0]}
        anchor={lpAnchor[0]}
        anchorNode={lpAnchorNode[0]}
        href={lpHref[0]}
        view={editorView!}
      />
      <TableControls
        show={tControlShow[0]}
        contextMenu={tControlCtxMenu[0]}
        view={editorView!}
        tableRect={tControlTableRect[0]}
        cellRect={tControlCellRect[0]}
      />
      {showDisconnected
        && (
          <LostConnectionTab>
            Lost connection to the notes.
          </LostConnectionTab>
        )}
    </EditorContext.Provider>
  );
};

ProseMirrorComponentV2.defaultProps = {
  canEdit: true,
  setProseMirrorEditorView: null,
  isPreviousMeeting: false,
  notesFor: ALL_PAGES.CURRENT_MEETING,
};

const checkIfStateAreTheSame = (prevState: ProseMirrorProps, presentState: ProseMirrorProps) => {
  if (prevState.canEdit !== presentState.canEdit
    || prevState.path !== presentState.path
    || prevState.meetingSection !== presentState.meetingSection
    || prevState.displayNotes !== presentState.displayNotes
    || prevState.isPreviousMeeting
    || presentState.isPreviousMeeting) {
    return false;
  }
  return true;
};

export default React.memo(ProseMirrorComponentV2, checkIfStateAreTheSame);

export const useEditorContext = () => useContext(EditorContext);
