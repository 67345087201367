import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../App';
import { useUI } from '../../../pages/all-pages/ui-context/uiContext';
import { CLOSE_EVENT, OPEN_EVENT } from '../../../utils/analytics/enums';
import { logUpdateHubAction } from '../../../utils/analytics/eventLogger';
import UnreadBubble from './atoms/UnreadBubble';
import UpdateHubIcon from './atoms/UpdateHubIcon';
import UpdateHubModal from './molecules/UpdateHubModal';

function NotificationInbox() {
  const { userId } = useContext(AuthContext);
  const [isHover, setIsHover] = useState(false);
  const { isUpdatesHubModalOpen, openUpdatesHubModal, closeUpdatesHubModal } = useUI();
  const toggleUpdatesHubModal = () => {
    if (isUpdatesHubModalOpen) {
      logUpdateHubAction(userId, CLOSE_EVENT);
      closeUpdatesHubModal();
      return;
    }
    logUpdateHubAction(userId, OPEN_EVENT);
    openUpdatesHubModal();
  };

  return (
    <>
      <UpdateHubIcon
        isHover={isHover}
        setIsHover={setIsHover}
        setModalClosed={toggleUpdatesHubModal}
      />
      <UpdateHubModal isOpen={isUpdatesHubModalOpen} setModalClosed={toggleUpdatesHubModal} />
      <UnreadBubble
        onClick={toggleUpdatesHubModal}
        isHover={isHover}
        setIsHover={setIsHover}
      />
    </>
  );
}

export default NotificationInbox;
