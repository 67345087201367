import React from 'react';

interface Props {
  size?: number,
  color?: string,
}

const RefreshArrow = ({ size, color }: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M21 2.25C20.5858 2.25 20.25 2.58579 20.25 3V6.1197C19.9558 5.81983 19.6143 5.5012 19.2253 5.18253C17.6311 3.87653 15.2186 2.55508 12 2.55508C6.59631 2.55508 2.25 6.84156 2.25 12.1525C2.25 17.4651 6.62736 21.75 12 21.75C17.3726 21.75 21.75 17.4651 21.75 12.1525C21.75 11.7383 21.4142 11.4025 21 11.4025C20.5858 11.4025 20.25 11.7383 20.25 12.1525C20.25 16.6126 16.5685 20.25 12 20.25C7.43151 20.25 3.75 16.6126 3.75 12.1525C3.75 7.69089 7.40369 4.05508 12 4.05508C14.7814 4.05508 16.8689 5.19126 18.2747 6.34288C18.752 6.73388 19.1483 7.12495 19.4631 7.47034H16.3449C15.9307 7.47034 15.5949 7.80612 15.5949 8.22034C15.5949 8.63455 15.9307 8.97034 16.3449 8.97034H21C21.4142 8.97034 21.75 8.63455 21.75 8.22034V3C21.75 2.58579 21.4142 2.25 21 2.25Z" fill={color} />
  </svg>
);

RefreshArrow.defaultProps = {
  size: 24,
  color: '#1B2124',
};

export default RefreshArrow;
