import React from 'react';
import styled from 'styled-components';
import ModalLine from './ModalLine';

const Container = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;
  align-items: center;

  & > button {
    margin: 0;
  }

  button:not(:last-child) {
    margin-right: 8px;
  }
`;

interface Props {
  children: React.ReactNode,
}

const ModalFooterButtonsRightAlignedWrapper = ({ children }: Props) => (
  <>
    <ModalLine />
    <Container>
      {children}
    </Container>
  </>
);

export default ModalFooterButtonsRightAlignedWrapper;
