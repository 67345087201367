import React from 'react';
import styled from 'styled-components';
import ProfileIcon from '.';
import {
  red6, purple6, green6, cyan6, yellow6, blue6,
} from '../../colors/COLORS';
import PlusIcon from '../../icons/plusIcon';
import { PublicUserDataV2 } from '../../types/types';

export const ProfilePicContainer = styled.div`
  display: flex;
  cursor: pointer;

  & button {
    margin: 0;
  }

  & > div:not(:nth-child(1)) {
    margin-left: -5px;
  }
  & > div:nth-child(1) {
    z-index: 10;
  }
  & > div:nth-child(2) {
    z-index: 9;
  }
  & > div:nth-child(3) {
    z-index: 8;
  }
  & > div:nth-child(4) {
    z-index: 7;
  }
  & > div:nth-child(5) {
    z-index: 6;
  }
  & > div:nth-child(6) {
    z-index: 5;
  }
  & > div:nth-child(7) {
    z-index: 4;
  }
  & > div:nth-child(8) {
    z-index: 3;
  }

  & > div {
    &:hover {
      z-index: 40;
    }
  }
`;

export const profileColors = [red6, purple6, green6, cyan6, yellow6, blue6];

export interface Props {
  profileIconAttendees: PublicUserDataV2[],
  extraAttendees: PublicUserDataV2[],
  size?: number,
}

const ProfileIconsRow = ({
  profileIconAttendees, extraAttendees, size = 24,
}: Props) => (
  <>
    <ProfilePicContainer>
      {profileIconAttendees.map((attendee, index) => (
        <ProfileIcon
          user={attendee}
          color={profileColors[index]}
          key={attendee.data.email}
          size={size}
        />
      ))}
      <PlusIcon attendees={extraAttendees} size={size} />
    </ProfilePicContainer>

  </>
);

ProfileIconsRow.defaultProps = {
  size: 24,
};

export default ProfileIconsRow;
