import React from 'react';
import styled from 'styled-components';
import videoPlayerSrc from '../../../shared/img/VideoPlayer.png';

const Image = styled.img``;

const VideoPlayerAtom = () => (
  <Image src={videoPlayerSrc} />
);

export default VideoPlayerAtom;
