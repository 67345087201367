import React from 'react';
import Notification from '../../../classes/Notification/Notification';
import TimeFromNow from './TimeFromNow';

interface Props{
  notification: Notification
}
function NotificationHeadingText({ notification }: Props) {
  return (
    <div>
      {notification.reporter.name}
      <TimeFromNow created={notification.created} />
    </div>
  );
}

export default NotificationHeadingText;
