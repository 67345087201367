import React from 'react';
import { COLORS } from '../colors/COLORS';

interface Props {
  fill?: string
  width?: string
  height?: string
}
const CloseSquareIcon = ({ fill, width, height }: Props) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" fill={fill} clipRule="evenodd" d="M10.8896 1.3335H5.11028C2.84524 1.3335 1.33362 2.95523 1.33362 5.2775V10.7228C1.33362 13.0473 2.8412 14.6668 5.11028 14.6668H10.889C13.1587 14.6668 14.667 13.0473 14.667 10.7228V5.2775C14.667 2.95317 13.1588 1.3335 10.8896 1.3335ZM5.11033 2.3335H10.8897C12.59 2.3335 13.667 3.49014 13.667 5.2775V10.7228C13.667 12.5103 12.5899 13.6668 10.889 13.6668H5.11033C3.41017 13.6668 2.33366 12.5104 2.33366 10.7228V5.2775C2.33366 3.49241 3.41388 2.3335 5.11033 2.3335ZM6.04712 6.04159C6.22465 5.8641 6.50243 5.84799 6.69815 5.99325L6.75423 6.04167L7.99943 7.28713L9.24346 6.04309C9.43872 5.84783 9.7553 5.84783 9.95056 6.04309C10.1281 6.2206 10.1442 6.49838 9.99898 6.69412L9.95056 6.7502L8.70646 7.99431L9.95156 9.23967C10.1468 9.43495 10.1468 9.75153 9.95148 9.94677C9.77396 10.1243 9.49618 10.1404 9.30045 9.99512L9.24438 9.9467L7.99935 8.70141L6.7559 9.94487C6.56064 10.1401 6.24405 10.1401 6.04879 9.94487C5.87128 9.76736 5.85514 9.48958 6.00038 9.29384L6.04879 9.23776L7.29232 7.99423L6.04704 6.7487C5.8518 6.55342 5.85184 6.23683 6.04712 6.04159Z" />
  </svg>
);

CloseSquareIcon.defaultProps = {
  fill: COLORS.gray1,
  width: '16',
  height: '16',
};

export default CloseSquareIcon;
