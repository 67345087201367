import { captureException } from '@sentry/browser';
import {
  ResolvedState, ResolvedStateItem, TaskItem, TaskStatus, TrelloData,
} from '../../shared/types/types';
import { getStringSimilarity } from '../strings';
import { getTrelloBoardMembers, sendTaskToTrelloBoard, updateTrelloTask } from './trelloAPIs';

export type TrelloUpdateType = 'single' | 'many'

export type TrelloUpdateField = 'idList' | 'idMembers' | 'name' | 'desc' | 'due' | 'all'

export const trelloAuthUrl = 'https://trello.com/1/authorize?expiration=never&name=meetshepherd&scope=read,write&response_type=token&key=cd6fea696f1a635621808b198ce72fda&return_url=https://app.meetshepherd.com/trello/';

export const trelloWebhookURLV1 = 'https://us-central1-shepherd-mvp.cloudfunctions.net/trelloWebhookListener';

export const trelloWebhookURLV2 = 'https://us-central1-shepherd-mvp.cloudfunctions.net/trelloWebhookListenerV2';

export const defaultTrelloData: TrelloData = {
  version: 1,
  settings: {
    isTrelloEnabled: false,
    isAutoSyncEnabled: false,
    isAllowOtherToSyncEnabled: false,
  },
  accessToken: '',
  webhookId: '',
  workspaceId: '',
  workspaceName: '',
  board: {
    boardId: '',
    boardName: '',
    boardURL: '',
    todoList: {
      listId: '',
      listName: '',
    },
    inProgressList: {
      listId: '',
      listName: '',
    },
    completedList: {
      listId: '',
      listName: '',
    },
  },
};

export interface getTrelloBoardsAPIResponse {
  name: string,
  id: string,
  url: string
}

export interface TrelloAPIBoard {
  id: string,
  name: string,
  closed: boolean,
  pos: number,
  softLimit: any,
  idBoard: string,
  subscribed: boolean
}

export interface TrelloTaskCreate {
  name: string,
  idList: string
}

export interface TrelloUpdateObj {
  accessToken: string
  cardId: string,
  idList: string
  idMembers: string[],
  name: string,
  desc: string,
  due: string | null
}

export interface TrelloBoardMember {
  id: string,
  fullName: string,
  username: string
}

export const mapTasksToTrelloTask = async (
  selectedTasks: TaskItem[], trelloUserData: TrelloData,
) => {
  const boardMembers: ResolvedStateItem<TrelloBoardMember[]> = await getTrelloBoardMembers(
    trelloUserData.accessToken, trelloUserData.board.boardId,
  );
  const trelloTaskArr: TrelloTaskCreate[] = selectedTasks.map((task: TaskItem) => (
    {
      taskId: task.taskId,
      name: task.data.title,
      due: task.date.dueDate.date.date || null,
      desc: task.data.description,
      idList: mapTaskStatusToListId(task.data.status, trelloUserData),
      idMembers: mapTaskAssigneeToTrelloAssignee(boardMembers.item, task.assignee.data.name),
    }));
  return trelloTaskArr;
};

export const mapTaskStatusToListId = (taskStatus: TaskStatus, trelloData: TrelloData) => {
  if (taskStatus === 'todo') {
    return trelloData.board.todoList.listId;
  }
  if (taskStatus === 'inProgress') {
    return trelloData.board.inProgressList.listId;
  }
  if (taskStatus === 'completed') {
    return trelloData.board.completedList.listId;
  }
  return trelloData.board.todoList.listId;
};

export const mapTaskAssigneeToTrelloAssignee = (
  boardMembers: TrelloBoardMember[],
  assigneeName: string,
) => boardMembers
  .filter(
    (member: TrelloBoardMember) => getStringSimilarity(member.fullName, assigneeName) >= 0.5,
  )
  .map((member: TrelloBoardMember) => member.id);

export const updateTrelloCard = async (
  trelloData: TrelloData,
  taskItem: TaskItem,
  updateType: TrelloUpdateType,
  updateField: TrelloUpdateField,
  updateValue: string = '',
): Promise<ResolvedState> => {
  if (updateType === 'single' && isTrelloSyncAvailable(taskItem)) {
    const updateObj: TrelloUpdateObj = {
      accessToken: trelloData.accessToken,
      cardId: taskItem.integrations.trello.trelloTaskId,
      idMembers: [],
      idList: '',
      name: taskItem.data.title,
      desc: taskItem.data.description,
      due: taskItem.date.dueDate.date.date || null,
    };
    if (updateField === 'idMembers') {
      const boardMembers: ResolvedStateItem<TrelloBoardMember[]> = await getTrelloBoardMembers(
        trelloData.accessToken, trelloData.board.boardId,
      );
      updateObj.idMembers = mapTaskAssigneeToTrelloAssignee(boardMembers.item, updateValue);
    }
    if (updateField === 'idList') {
      updateObj.idList = mapTaskStatusToListId(
        updateValue as TaskStatus, trelloData,
      );
    } else {
      updateObj.idList = mapTaskStatusToListId(
        taskItem.data.status,
        trelloData,
      );
    }
    if (updateField === 'name') {
      updateObj.name = updateValue;
    }
    if (updateField === 'desc') {
      updateObj.desc = updateValue;
    }
    if (updateField === 'due') {
      updateObj.due = updateValue;
    }
    return updateTrelloTask(updateObj);
  }
  if (updateType === 'many') {
    if (isTrelloSyncAvailable(taskItem)) {
      const boardMembers: ResolvedStateItem<TrelloBoardMember[]> = await getTrelloBoardMembers(
        trelloData.accessToken, trelloData.board.boardId,
      );
      const updateObj: TrelloUpdateObj = {
        accessToken: trelloData.accessToken,
        cardId: taskItem.integrations.trello.trelloTaskId,
        idList: mapTaskStatusToListId(taskItem.data.status, trelloData),
        idMembers: mapTaskAssigneeToTrelloAssignee(boardMembers.item, taskItem.assignee.data.name),
        name: taskItem.data.title,
        desc: taskItem.data.description,
        due: taskItem.date.dueDate.date.date || null,
      };
      return updateTrelloTask(updateObj);
    }
    // TODO: Can this if be replaced by isTrelloSyncAvailable?
    //  -> TODO Reply: here we want trelloTaskId to have length 0
    //  -> where as in isTrelloSyncAvailable we dont want trelloTaskId to be 0
    if (taskItem.integrations.trello.trelloTaskId.length === 0
      && taskItem.integrations.trello.isTrelloSyncEnabled) {
      try {
        return sendTaskToTrelloBoard(true, [taskItem], trelloData);
      } catch (error) {
        captureException(error);
        console.log('Error sending task to trello board');
        console.log(error);
      }
    }
    return 'rejected' as ResolvedState;
  }
  return 'rejected' as ResolvedState;
};

export const isTrelloSyncAvailable = (
  taskItem: TaskItem,
) => taskItem.integrations.trello.trelloTaskId.length
  && taskItem.integrations.trello.isTrelloSyncEnabled;

export const isTrelloBoardsEnabled = (
  trelloUserData: TrelloData,
) => trelloUserData.board.boardId.length !== 0;

export const mapTrelloWebhookURLToVersionNo = (webhookURL: string) => {
  switch (webhookURL) {
    case trelloWebhookURLV1:
      return 1;
    case trelloWebhookURLV2:
      return 2;
    default:
      return 1;
  }
};
