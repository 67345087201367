import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { AuthContext } from '../../../App';
import DropdownOption from '../../../pages/all-pages/bottom-bar/share-dropdown/DropdownOption';
import SquareButtonMolecule from '../../../pages/all-pages/bottom-bar/SquareButtonMolecule';
import { logEventButtonClickNewTask } from '../../../utils/analytics/eventLogger';
import AddPlusIcon from '../../icons/AddPlusIcon';
import CheckboxIcon from '../../icons/text-editor/CheckboxIcon';
import { MeetingData } from '../../types/MeetingData';
import CreateNewTaskModal from '../task-modal/CreateNewTaskModal';

const Container = styled.div`
  width: 100%;
`;

interface Props {
  meetingData: MeetingData,
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
  btnType?: string,
}

const CreateTask = ({
  meetingData, isOpen, setIsOpen, disableAssigneeSelect, disableCalendarSelect, btnType = 'square',
}: Props) => {
  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    logEventButtonClickNewTask(authState.userId, trackEvent);
    setIsOpen(true);
  };

  const CreateTaskBtn = () => {
    if (btnType === 'square') {
      return <SquareButtonMolecule onClick={openModal} icon={<AddPlusIcon />} tooltipText="Create new task" />;
    }
    return <DropdownOption text="Add Task" icon={<CheckboxIcon fill="#1B2124" />} onClick={openModal} />;
  };

  return (
    <Container>
      <CreateTaskBtn />
      <CreateNewTaskModal
        meetingData={meetingData}
        isModalOpen={isOpen}
        setModalClosed={closeModal}
        disableAssigneeSelect={disableAssigneeSelect}
        disableCalendarSelect={disableCalendarSelect}
      />
    </Container>
  );
};

CreateTask.defaultProps = {
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
  btnType: 'square',
};

export default CreateTask;
