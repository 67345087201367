import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import SelectPrivateNotesButton from '../select-private-notes-button-atom';
import TabDivButton from '../../../../pages/meeting/tab/TabDivButton';
import { MEETING_SECTION } from '../../../../utils/enums';
import { MeetingSection, PrivateNoteData } from '../../../types/types';

interface Props {
  doesPrivateNotesExist: boolean,
  privateNotes: PrivateNoteData[],
  setPrivateNotes: Dispatch<SetStateAction<PrivateNoteData[]>>,
  meetingTab: MeetingSection,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
  onClickOpenPrivateNotesModal: () => void,
  // eslint-disable-next-line no-unused-vars
  handleSecretNotesSelected: (chatId: string) => void,
  setSelectedChat: Dispatch<SetStateAction<number>>,
  selectedChat: number,
  setIsDeleted: Dispatch<SetStateAction<boolean>>,
  setChatToDelete: Dispatch<SetStateAction<PrivateNoteData>>,
}

const MeetingTabSelectPrivateNote = ({
  doesPrivateNotesExist,
  privateNotes,
  setPrivateNotes,
  meetingTab,
  setMeetingTab,
  selectedChat,
  setSelectedChat,
  onClickOpenPrivateNotesModal,
  handleSecretNotesSelected,
  setChatToDelete,
  setIsDeleted,
}: Props) => {
  const [isPrivateNoteOpen, setIsPrivateNoteOpen] = useState(false);

  useEffect(() => {
    setIsPrivateNoteOpen(meetingTab === MEETING_SECTION.PRIVATE_NOTES);
  }, [meetingTab]);

  return (
    <TabDivButton
      active={isPrivateNoteOpen}
      disabled={isPrivateNoteOpen}
      onClick={() => null}
      tooltipText=""
      meetingTab={MEETING_SECTION.PRIVATE_NOTES}
      privateNotesExist={doesPrivateNotesExist}
      padding
    >
      <SelectPrivateNotesButton
        privateNotes={privateNotes}
        handleSecretNotesSelected={handleSecretNotesSelected}
        setMeetingTab={setMeetingTab}
        meetingTab={meetingTab}
        setPrivateNotes={setPrivateNotes}
        handleOpenCreateSecretChat={onClickOpenPrivateNotesModal}
        setSelectedChat={setSelectedChat}
        selectedChat={selectedChat}
        setDeletedModalOpen={setIsDeleted}
        setChatToDelete={setChatToDelete}
      />
    </TabDivButton>
  );
};

export default MeetingTabSelectPrivateNote;
