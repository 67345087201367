import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import BREAKPOINTS from '../../../shared/CSS/CSS_Constants';
import { uiText } from '../../../shared/typography';

const Container = styled.span`
  ${uiText}
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  width: 100%;
  /* @media only screen and (max-width: ${BREAKPOINTS.sm}){
    width: 44%
  } */
`;

interface Props {
  text: string;
}

const NoteItemTitleAtom = ({ text }: Props) => {
  const textRef = useRef<HTMLSpanElement>(document.createElement('span'));
  const [isTextOverflow, setTextOverflow] = useState<boolean>(false);
  useEffect(() => {
    setTextOverflow(textRef.current.scrollWidth > textRef.current.offsetWidth);
  }, [textRef, text]);

  return isTextOverflow ? (
    <ReactTooltip tip={text}>
      <Container ref={textRef}>
        {text}
      </Container>
    </ReactTooltip>
  ) : (
    <Container ref={textRef}>
      {text}
    </Container>
  );
};

export default NoteItemTitleAtom;
