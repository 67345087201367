interface ReactMethods {
  moveSelectionUp: CallableFunction;
  moveSelectionDown: CallableFunction;
  setResults: CallableFunction;
  getResults: CallableFunction;
  getCommands: CallableFunction;
  // eslint-disable-next-line no-unused-vars
  setShow: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setPos: (x: number, y: number) => void;
  getSelectedCommand: () => { name: string, callback: CallableFunction };
  getShow: () => boolean;
}

class CommandAdapter {
  public moveSelectionUp: CallableFunction = () => null;

  public moveSelectionDown: CallableFunction = () => null;

  public setResults: CallableFunction = () => null;

  public getResults: CallableFunction = () => null;

  public setShow: CallableFunction = () => null;

  public setPos: CallableFunction = () => null;

  public openTemplates: CallableFunction = () => null;

  public openColourMenu: CallableFunction = () => null;

  public openHighlightMenu: CallableFunction = () => null;

  public openEmojiMenu: CallableFunction = () => null;

  public getSelectedCommand: () => {
      name: string,
      callback: CallableFunction,
      useTransactionLogic?: boolean,
    } =
    () => ({ name: 'noop', callback: () => null });

  public getCommands: CallableFunction = () => null;

  set openTemplatesMethod(method: CallableFunction) {
    this.openTemplates = method;
  }

  public getShow: CallableFunction = () => null;

  set reactMethods({
    moveSelectionUp,
    moveSelectionDown,
    setResults,
    getResults,
    setShow,
    setPos,
    getSelectedCommand,
    getCommands,
    getShow,
  }: ReactMethods) {
    this.moveSelectionUp = moveSelectionUp;
    this.moveSelectionDown = moveSelectionDown;
    this.setResults = setResults;
    this.getResults = getResults;
    this.setShow = setShow;
    this.setPos = setPos;
    this.getSelectedCommand = getSelectedCommand;
    this.getCommands = getCommands;
    this.getShow = getShow;
  }
}

export default new CommandAdapter();
