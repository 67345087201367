/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../colors/COLORS';

const WorkspaceLogoContainer = styled.div< { color?: string } >`
  background-color: ${COLORS.purple6};
  ${(props) => props.color && `background-color: ${props.color}`};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  overflow: hidden;


  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  border: 4px solid #FFFFFF;

  box-shadow: 0px 4px 6px -2px rgba(27, 33, 36, 0.06);
  filter: drop-shadow(0px 8px 16px rgba(27, 33, 36, 0.16));
  border-radius: 24px;
  margin-bottom: 20px;
`;

const InitialLetterLogoContainer = styled.div`
  width: 62px;
  height: 54px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #FEFEFE;
  text-shadow: 0px 2px 8px rgba(27, 33, 36, 0.16);
`;

interface WorkspaceLogoProps {
  name: string | 'Workspace',
  color?: string,
  image?: string,
}

const WorkspaceLogo = ({ name: workspaceName, color, image }: WorkspaceLogoProps) => {
  const initialLetter = workspaceName ? workspaceName[0].toUpperCase() : 'W';

  return (
    <WorkspaceLogoContainer color={color}>
      {image ? <img src={image} alt="workspace logo" /> : <InitialLetterLogoContainer>{initialLetter}</InitialLetterLogoContainer>}
    </WorkspaceLogoContainer>
  );
};

export default WorkspaceLogo;
