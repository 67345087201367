import React, { useEffect, useState } from 'react';
import { mapPrivateNoteAttendeeV2ToAttendeeV2 } from '../../../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import PlusIcon from '../../../../../icons/plusIcon';
import { PrivateNoteAttendeeV2, PrivateNoteData } from '../../../../../types/types';
import ProfileIcon from '../../../../profile-icon';
import { PlusIconBubble, ProfileBubbleContainer, SecondProfileIconBubble } from './utils/styles';

interface Props {
  privateNote: PrivateNoteData,
}

const ProfileBubbles = ({ privateNote }: Props) => {
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [privateNoteMembers, setPrivateNoteMembers] = useState<PrivateNoteAttendeeV2[]>([]);

  useEffect(() => {
    const userOfPrivateNote = privateNote?.members ?? [];
    setNumberOfMembers(userOfPrivateNote.length);
    setPrivateNoteMembers(userOfPrivateNote);
  }, [privateNote]);

  if (numberOfMembers === 0) return null;

  if (numberOfMembers === 1) {
    return (
      <ProfileBubbleContainer>
        <ProfileIcon user={privateNoteMembers[0]} size={16} />
      </ProfileBubbleContainer>
    );
  }
  if (numberOfMembers === 2) {
    return (
      <ProfileBubbleContainer>
        <ProfileIcon user={privateNoteMembers[0]} size={16} />
        <SecondProfileIconBubble>
          <ProfileIcon user={privateNoteMembers[1]} size={16} />
        </SecondProfileIconBubble>
      </ProfileBubbleContainer>
    );
  }
  return (
    <ProfileBubbleContainer>
      <ProfileIcon user={privateNoteMembers[0]} size={16} />
      <PlusIconBubble>
        <PlusIcon
          attendees={privateNoteMembers.slice(1).map(mapPrivateNoteAttendeeV2ToAttendeeV2)}
          size={19}
          zIndex={20}
        />
      </PlusIconBubble>
    </ProfileBubbleContainer>
  );
};

export default ProfileBubbles;
