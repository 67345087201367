import { captureException } from '@sentry/react';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { PublicUserDataV2, ResolvedState, SlackNotificationData } from '../../shared/types/types';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { isValidBlock } from '../../utils/slack/SlackAPIUtils';
import CloudFunctions from '../../database/CloudFunctions';

class SlackCoreAPI {
  protected static async sendNotificationCore(
    title: string,
    message: string,
    recipientData: PublicUserDataV2,
  ): Promise<ResolvedState> {
    const blocks = [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `${message}`,
        },
      },
    ];

    const data: SlackNotificationData = {
      title,
      recipientEmail: recipientData.data.email,
      blocks,
    };

    if (!isValidBlock(blocks)) {
      ConsoleImproved.log('Invalid block', blocks);
      return REJECTED;
    }
    return CloudFunctions().sendSlackNotificationV2(data)
      .then(() => {
        ConsoleImproved.log('Sent slack notification', data);
        return RESOLVED as ResolvedState;
      })
      .catch((error) => {
        console.log(error);
        captureException(error);
        return REJECTED as ResolvedState;
      });
  }
}

export default SlackCoreAPI;
