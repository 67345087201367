import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { layoutWhite } from '../../../shared/colors/COLORS';
import { MeetingSection, TemplateData } from '../../../shared/types/types';
import TemplatesContent from './components/TemplatesContent';

// const height = 'calc(100vh - 48px - 48px - 167px - 60px)';

const TemplatesContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background: ${layoutWhite};

  padding: 24px 16px;
  overflow-y: scroll;
`;

interface Props {
  tab: MeetingSection,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
}

const TemplatesView = ({ tab, setIsTemplatesOpen, setSelectedTemplateToEdit }: Props) => (
  <TemplatesContainer>
    <TemplatesContent
      key="user"
      section="user"
      tab={tab}
      setIsTemplatesOpen={setIsTemplatesOpen}
      setSelectedTemplateToEdit={setSelectedTemplateToEdit}
    />
    <TemplatesContent
      key="shepherd"
      section="shepherd"
      tab={tab}
      setIsTemplatesOpen={setIsTemplatesOpen}
      setSelectedTemplateToEdit={setSelectedTemplateToEdit}
    />
  </TemplatesContainer>
);

export default TemplatesView;
