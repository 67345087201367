import React, { Dispatch, SetStateAction, useContext } from 'react';
import { AuthContext } from '../../../../App';
import EnableCrossIcon from '../../../../shared/icons/EnableCrossIcon';
import { SlackIcon } from '../../../../shared/icons/integration-icons';
import { IntegrationsTabView } from '../../../../shared/types/types';
import {
  SHARE_MODAL_LOCATION, SETTINGS_MODAL_LOCATION, OPEN_EVENT, SLACK_INTEGRATION,
} from '../../../../utils/analytics/enums';
import { logIntegrationsEvent } from '../../../../utils/analytics/eventLogger';
import { SLACK_PROCESSING_VIEW } from '../../../../utils/constants';
import { slackAuthUrl } from '../../../../utils/slack/slackUtils';
import {
  IntegrationContainer, InnerContainer, IntegrationText, CrossIconContainer, EnableButton,
} from '../integrations/utils/commonStyles';

interface Props {
  enable: boolean,
  setView: Dispatch<SetStateAction<IntegrationsTabView>>
  setIsIntegratingSlack: Dispatch<SetStateAction<boolean>>,
  analyticsFrom: typeof SHARE_MODAL_LOCATION | typeof SETTINGS_MODAL_LOCATION;
}

export default function SlackNotIntegrated({
  enable, setView, setIsIntegratingSlack, analyticsFrom,
}:Props) {
  const authState = useContext(AuthContext);

  const handleSlackButtonClick = () => {
    try {
      window.open(slackAuthUrl);
      logIntegrationsEvent(authState.userId, OPEN_EVENT, SLACK_INTEGRATION, analyticsFrom);
      setView(SLACK_PROCESSING_VIEW);
      setIsIntegratingSlack(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (enable) return <></>;
  return (
    <IntegrationContainer>
      <InnerContainer>
        <SlackIcon width="28" />
        <IntegrationText>Slack (Beta)</IntegrationText>
      </InnerContainer>
      <InnerContainer>
        <CrossIconContainer onClick={handleSlackButtonClick}>
          <EnableCrossIcon />
          <EnableButton>Enable</EnableButton>
        </CrossIconContainer>
      </InnerContainer>
    </IntegrationContainer>
  );
}
