import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../../shared/components/Modal';
import PrivacyModelContent from './PrivacyModelContent';

interface PrivacyModalProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const PrivacyModal = ({ isOpen, setIsOpen }: PrivacyModalProps) => {
  const handleModalClose = () => setIsOpen(false);

  return (
    <Modal
      isOpen={isOpen}
      setModalClosed={handleModalClose}
      title="Privacy"
    >
      <PrivacyModelContent />
    </Modal>
  );
};

export default PrivacyModal;
