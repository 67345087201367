import React from 'react';
import styled from 'styled-components';

const Container = styled.div<StyleProps>`
  border-radius: 10px;
  /* background: linear-gradient(180deg, #B7ABFF 0%, #E077FF 100%); */
  background: linear-gradient(-45deg, #B7ABFF 0%, #E077FF 100%);
  border: 3px solid #F7F8F8;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;

  width: fit-content;
  padding: ${({ padding }) => ((padding && padding.length > 0) ? padding : '2px 10px')};
  color: white;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  &:hover{
    /* animation:colorchange 1s;

    @keyframes colorchange{
      from{background:red}
      to{background:blue} 
    } */


    /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
    background: linear-gradient(-45deg, #B7ABFF 0%, #E077FF 100%);
    background-size: 400% 400%;
    animation: gradient 4s ease infinite;
    /* height: 100vh; */

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

  }
`;

interface StyleProps {
  padding?: string,
}

interface ButtonPremiumProps extends StyleProps {
  onClick?: () => void,
  children: React.ReactNode,
}

const ButtonPremium = ({ onClick = () => { }, padding = '', children }: ButtonPremiumProps) => (
  <Container onClick={onClick} padding={padding}>
    {children}
  </Container>
);

export default ButtonPremium;
