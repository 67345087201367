import React from 'react';
import styled from 'styled-components';
import { gray9 } from '../../shared/colors/COLORS';
import { titleSemibold } from '../../shared/typography';

const Container = styled.span`
  ${titleSemibold};
  color: ${gray9};
  user-select: none;
`;

interface Props {
  text: string,
}

const HeaderTextAtom = ({ text }: Props) => (
  <Container>
    {text}
  </Container>
);

export default HeaderTextAtom;
