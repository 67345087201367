import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { darkBlue4 } from '../../../colors/COLORS';
import { SDateT } from '../../../types/types';
import { defaultSmall } from '../../../typography';
import ReactTooltip from '../../tooltip/ReactTooltip';

const TimeText = styled.span`
  padding-left: 8px;
  ${defaultSmall}
  color: ${darkBlue4};
  display: inline-flex;
`;

interface Props {
  created: SDateT;
}

function TimeFromNow({ created }: Props) {
  return (
    <TimeText>
      <ReactTooltip
        place="right"
        tip={moment(created.date).local().format('LLL')}
      >
        {moment(created.date).fromNow()}
      </ReactTooltip>
    </TimeText>
  );
}
export default TimeFromNow;
