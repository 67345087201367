import styled from 'styled-components';
import { darkBlue4 } from '../../../../../shared/colors/COLORS';
import { small } from '../../../../../shared/typography';

const MeetingRecurrenceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    margin-right: 4px;
  }
  span {
    color: ${darkBlue4};
    ${small};
  }
`;

export default MeetingRecurrenceContainer;
