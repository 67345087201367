import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface ItalicProps {
  fill?: string;
  width?: number;
  height?: number;
}

const ItalicIcon = ({
  fill,
  width,
  height,
}: ItalicProps) => (
  <svg width={width} height={height} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 4.5,11.25 C 4.5,11.6642 4.164,12 3.75,12 h -3 C 0.336,12 0,11.6642 0,11.25 0,10.8358 0.336,10.5 0.75,10.5 h 0.695 l 3.087,-9 H 3.75 C 3.336,1.5 3,1.1642 3,0.75 3,0.3358 3.336,0 3.75,0 h 3 C 7.164,0 7.5,0.3358 7.5,0.75 7.5,1.1642 7.164,1.5 6.75,1.5 H 6.055 l -3.087,9 H 3.75 c 0.414,0 0.75,0.3358 0.75,0.75 z"
      fill={fill}
    />
  </svg>
);

ItalicIcon.defaultProps = {
  fill: darkBlue4,
  width: 8,
  height: 12,
};

export default ItalicIcon;
