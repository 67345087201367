// Note: This is a temporary file, used for development by inventiff
// import { endpoints } from '../../../utils/inventiff-yjs-backend';
// Note: This is the file we should be using in prod
import { endpoints } from '../../../utils/yjs-backend';

export interface OGResponse {
  title: string,
  description: string,
  type: string,
  image: string,
}

export interface OGFailResponse {
  type: string, // only error for now
  message: string, // only 'Could not provide a response!' for now
}

// ? const encodedURL = encodeURIComponent(url);
export default async (url: string): Promise<OGResponse> => (
  fetch(
    `${endpoints.opengraphData}?url=${url}`,
    {
      method: 'GET',
      headers: {
      },
    },
  )
    .then((res) => res.json())
);
