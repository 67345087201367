import React from 'react';
import styled from 'styled-components';
import { gray9 } from '../../colors/COLORS';
import { header600 } from '../../typography';

const Title = styled.span`
  ${header600};
  margin: 0px;
  color: ${gray9};
`;

interface Props { }

const TitleAtom: React.FC<Props> = ({ children }) => (
  <Title>
    {children}
  </Title>
);

export default TitleAtom;
