import React from 'react';

interface Props {
  onClick: () => void,
}

const ThreeDotsIcon = ({ onClick }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <path d="M19 10C17.8954 10 17 10.8955 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12C21 10.8955 20.1046 10 19 10Z" fill="#6F7C9F" />
    <path d="M12 10C10.8954 10 10 10.8955 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8955 13.1046 10 12 10Z" fill="#6F7C9F" />
    <path d="M5 10C3.89543 10 3 10.8955 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12C7 10.8955 6.10457 10 5 10Z" fill="#6F7C9F" />
  </svg>
);

export default ThreeDotsIcon;
