import React from 'react';
import useNotificationStore from '../../../../zustand/useNotificationStore';
import Notification from '../../../classes/Notification/Notification';
import { TabContent } from '../../task-tabs';
import Notifications from './Notifications';

interface Props {
 tabIndex: number,
 // eslint-disable-next-line no-unused-vars
 onClick: (notification: Notification) => void
}

function NotificationTabContent({ tabIndex, onClick }: Props) {
  const {
    notifications, unreadNotifications, readNotifications,
  } = useNotificationStore();
  return (
    <>
      <TabContent selected={tabIndex === 0}>
        <Notifications notifications={notifications} onNotificationClick={onClick} />
      </TabContent>
      <TabContent selected={tabIndex === 1}>
        <Notifications notifications={unreadNotifications} onNotificationClick={onClick} />
      </TabContent>
      <TabContent selected={tabIndex === 2}>
        <Notifications notifications={readNotifications} onNotificationClick={onClick} />
      </TabContent>
    </>
  );
}

export default NotificationTabContent;
