namespace ShowOrNotHelpers {
  export const makeSureIsAValidWidth = (width: number) => {
    if (width < 0) {
      return 0;
    }
    if (width > 3000) {
      return 0;
    }

    return width;
  };
}

export default ShowOrNotHelpers;
