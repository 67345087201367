import React from 'react';
import { gray9 } from '../../colors/COLORS';

interface Props {
  fill?: string,
}

const ClearFormatIcon = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.48849 10.5488L8.81324 14.3802C8.75006 14.7387 8.43858 15 8.07455 15C7.60823 15 7.25493 14.579 7.33586 14.1198L8.19324 9.25429L3.16674 4.22779C2.87354 3.93459 2.87371 3.45916 3.16712 3.16617C3.46023 2.87346 3.93508 2.87363 4.22799 3.16654L14.8337 13.7723C15.1266 14.0651 15.1266 14.54 14.8337 14.8328C14.5409 15.1257 14.0661 15.1257 13.7732 14.8329L9.48849 10.5488ZM8.82999 5.64754L9.03249 4.50004H7.68249L6.18249 3.00004H14.2502C14.6645 3.00004 15.0002 3.33583 15.0002 3.75004C15.0002 4.16426 14.6645 4.50004 14.2502 4.50004H10.555L10.1252 6.94279L8.82999 5.64754Z" fill={fill} />
  </svg>
);

ClearFormatIcon.defaultProps = {
  fill: gray9,
};

export default ClearFormatIcon;
