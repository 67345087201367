import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface FontColorProps {
  fill?: string;
  width?: number;
  height?: number;
}

const FontColorIcon = ({
  fill,
  width,
  height,
}: FontColorProps) => (
  <svg width={width} height={height} viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 7.4946272,6.6057 c -0.116,-0.1294 -0.313,-0.1281 -0.426,0.003 -0.637,0.733 -2.489,2.9958 -2.489,4.4868 -0.005,0.3595 0.061,0.7164 0.194,1.0505 0.133,0.334 0.331,0.6385 0.581,0.8962 0.507,0.5204 1.199,0.8184 1.925,0.8283 0.726,0.0099 1.426,-0.2689 1.947,-0.7753 0.257,-0.2507 0.463,-0.5497 0.605,-0.88 0.142,-0.3302 0.2179998,-0.6852 0.2229998,-1.0447 v -0.075 c 0,-1.493 -1.9089998,-3.7598 -2.5599998,-4.4898 z m -1.317,-1.253 0.501,-0.5429 -1.471,-4.1042 C 5.0556272,0.2823 4.6546272,0 4.2046272,0 c -0.45,0 -0.851,0.2829 -1.002,0.7068 L 0.04162719,9.556 c -0.164,0.4601 0.177,0.944 0.665,0.944 0.29900001,0 0.56500001,-0.1876 0.66600001,-0.4686 l 0.905,-2.5314 h 2.207 c 0.509,-0.7568 1.074,-1.4746 1.69,-2.1473 z m -1.973,-3.2527 1.395,3.9 h -2.787 z"
      fill={fill}
    />
  </svg>
);

FontColorIcon.defaultProps = {
  fill: darkBlue4,
  width: 10,
  height: 14,
};

export default FontColorIcon;
