import { Dispatch, SetStateAction } from 'react';
import { MeetingSection, MeetingVersion } from '../../types/types';

export const rightKeyNavigation = (
  tab: MeetingSection,
  setTab: Dispatch<SetStateAction<MeetingSection>>,
  version: MeetingVersion,
) => {
  switch (tab) {
    case 'agenda':
      setTab('shared');
      break;
    case 'shared':
      setTab('private');
      break;
    case 'private':
      setTab('task');
      break;
    case 'task':
      if (version <= 3) { setTab('agenda'); } else { setTab('shared'); }
      break;
    default:
      console.log('There isnt a default');
      break;
  }
};

export const leftKeyNavigation = (
  tab: MeetingSection,
  setTab: Dispatch<SetStateAction<MeetingSection>>,
  version: MeetingVersion,
) => {
  switch (tab) {
    case 'task':
      setTab('private');
      break;
    case 'private':
      setTab('shared');
      break;
    case 'shared':
      if (version <= 3) { setTab('agenda'); } else { setTab('task'); }
      break;
    case 'agenda':
      setTab('task');
      break;
    default:
      console.log('no default');
  }
};
