/* eslint-disable no-alert */
import React from 'react';
import * as Sentry from '@sentry/react';
import {
  GoogleAuthProvider, onAuthStateChanged, signInWithCredential, signOut,
} from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import {
  AuthState, IntercomTrackEvent, LoginOrSignup,
} from '../../shared/types/types';
import {
  API_KEY, CLIENT_ID, DISCOVERY_DOCS, SCOPES,
} from '../constants';
import { LOGGED_IN_STATE, LOGGED_OUT_STATE } from '../enums';
import { analytics, auth } from '../firebase';
import {
  EVENT_NAMES, LOGIN_EVENT, NO_SCOPE_GRANTED, SIGNUP_EVENT,
} from '../analytics/enums';
import { setMixpanelUserAlias } from '../mixpanel';
import { trackLoginAnalytics, trackSignUpAnalytics } from './GoogleCalendarUtils';
import { logEventGoogleAuthNotAllChecked } from '../analytics/eventLogger';
import Mixpanel from '../analytics/Mixpanel';

declare let gapi: any;

type SetAuthState = React.Dispatch<React.SetStateAction<AuthState>>;

const initializeGoogleAPI = (setAuthState: SetAuthState) => {
  gapi.load('client:auth2', () => {
    initClient(setAuthState);
  });
};

function initClient(setLoggedIn: SetAuthState) {
  gapi.client.init({
    apiKey: API_KEY,
    clientId: CLIENT_ID,
    discoveryDocs: DISCOVERY_DOCS,
    scope: SCOPES,
  }).then(
    () => listenForFirebaseState(setLoggedIn),
    (error: any) => {
      console.log('Something went wrong while initializing the Google API');
      console.log(error);
      Sentry.captureException(error);
      if (error?.details?.includes('Cookies are not enabled in current environment.')) {
        alert('You need to enable cookies in your browser to use this app. Please enable cookies and try again.');
        return;
      }
      alert('Something went wrong while initializing the Google API. If this ');
    },
  );
}

const listenForFirebaseState = (setLoggedIn: SetAuthState) => (
  onAuthStateChanged(auth, (user) => {
    try {
      if (user) {
        const email = user.email ?? user.providerData[0]?.email ?? '';
        const uid = user.uid ?? '';
        const displayName = user.displayName ?? user.providerData[0]?.displayName ?? '';
        const photoURL = user.photoURL ?? user.providerData[0]?.photoURL ?? '';
        // const {
        //   uid, displayName, email, photoURL,
        // } = user;
        const index = displayName?.indexOf(' ') || 0;

        const firstName = displayName?.substr(0, index) || '';
        const lastName = displayName?.substr(index + 1) || '';

        const newAuthData: AuthState = {
          userId: uid,
          userState: LOGGED_IN_STATE,
          firstName,
          lastName,
          email: email || '',
          photoUrl: photoURL || '',
        };

        setLoggedIn(newAuthData);
        if (email.length === 0) {
          Sentry.captureException(new Error('AUTH_EMAIL_MISSING'), {
            extra: {
              uid,
              firstName,
              lastName,
              photoURL,
              providerData: user.providerData[0],
            },
          });
          logUserOutIfNotAlreadyDoneItInTheLast24Hours(uid);
        }
      } else {
        setLoggedIn({
          userId: '',
          userState: LOGGED_OUT_STATE,
          firstName: '',
          lastName: '',
          email: '',
          photoUrl: '',
        });
        console.log('user is not present');
      }
    } catch (error) {
      console.error('Something went wrong while getting the google auth instance. Logging the user out', error);
      Sentry.captureException(error);
      logUserOutIfNotAlreadyDoneItInTheLast24Hours('');
    }
  })
);

const logUserOutIfNotAlreadyDoneItInTheLast24Hours = async (userId: string) => {
  if (userId.length === 0) {
    signOutOfGoogleAndFirebase();
    return;
  }

  const lastTimeWeLoggedTheUserOut = localStorage.getItem('lastTimeWeLoggedTheUserOut') ?? '0';
  const lastTimeDate = new Date(Number(lastTimeWeLoggedTheUserOut));
  console.log('lastTime we logged the user out: ');
  console.log(lastTimeDate.toISOString());

  if (lastTimeDate.getTime() < new Date().getTime() - (24 * 60 * 60 * 1000)) {
    console.log('Logging out since email is empty and more than 24 hours since we logged the user out');
    localStorage.setItem('lastTimeWeLoggedTheUserOut', new Date().getTime().toString());
    await Mixpanel
      .log(userId, EVENT_NAMES.LOGGED_USER_OUT, { lastTime: lastTimeDate.toISOString() });
    setTimeout(() => {
      // To be sure we set a delay here, as you need to be logged in to be able to log to mixpanel
      signOutOfGoogleAndFirebase();
    }, 100);
  }
};

const logAnalytics = (
  userId: string,
  intercomTrackEvent: IntercomTrackEvent,
  authenticationType: LoginOrSignup,
  signUpEmail: string = '',
) => {
  if (authenticationType === LOGIN_EVENT) trackLoginAnalytics(userId, intercomTrackEvent);

  trackSignUpAnalytics(userId, intercomTrackEvent, signUpEmail);
};

export const signInToGoogleAndFirebase = (
  onScopesNotGranted: any,
  intercomTrackEvent: any,
) => {
  const options = new gapi.auth2.SigninOptionsBuilder();
  options.setPrompt('select_account');

  gapi.auth2.getAuthInstance().signIn(options)
    .then((googleUser: any) => {
      // https://developers.google.com/identity/sign-in/web/reference#googleusergetgrantedscopes
      // console.log(googleUser.getGrantedScopes());
      if (checkGrantedScopes(SCOPES, googleUser.getGrantedScopes())) {
        const token = googleUser.getAuthResponse().id_token;
        const credentials = GoogleAuthProvider.credential(token);
        // analytics().logEvent('login', { method: 'google' });
        logEvent(analytics, 'login', { method: 'google' });
        signInWithCredential(auth, credentials)
          .then(async (user: any) => {
            console.log('Signed in with Firebase. Yeah!');

            const isNewUser = user.additionalUserInfo?.isNewUser ?? false;
            const userId = user.user?.uid ?? '';
            const email = user.user?.email ?? '';
            if (isNewUser) {
              setMixpanelUserAlias(userId);
              logAnalytics(userId, intercomTrackEvent, SIGNUP_EVENT, email);
              logEvent(analytics, 'signup', { method: 'firebase' });
              return;
            }

            logAnalytics(userId, intercomTrackEvent, LOGIN_EVENT, email);
            logEvent(analytics, 'login', { method: 'firebase' });
          })
          .catch((error: Error) => {
            console.log('Something went wrong when signing in to Shepherd (firebase)', error);
            Sentry.captureException(error);
            signOutOfGoogleAndFirebase();
          });
      } else {
        logEventGoogleAuthNotAllChecked(googleUser, googleUser.getGrantedScopes());
        Sentry.captureException(new Error(NO_SCOPE_GRANTED), {
          extra: {
            googleUser,
            grantedScopes: googleUser.getGrantedScopes(),
          },
        });
        onScopesNotGranted();
      }
    });
};

const checkGrantedScopes = (
  demandedScopes: string,
  grantedScopes: string,
): boolean => demandedScopes.split(' ')
  .every((demandedScope) => grantedScopes.split(' ').includes(demandedScope));

export const signOutOfGoogleAndFirebase = () => {
  signOut(auth)
    .then(() => {
      logEvent(analytics, 'logout', { method: 'firebase' });
    });
  gapi.auth2.getAuthInstance().signOut().then(() => {
    logEvent(analytics, 'logout', { method: 'google' });
  });
};

export default initializeGoogleAPI;
