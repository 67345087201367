import { logAppEvent, logEvent } from './eventLogger';
import {
  APP_FIELD, LOADED_EVENT, PLATFORM_USAGE_EVENT, UNKNOWN,
} from './enums';
import {
  AuthState, Browser, Device, User,
} from '../../shared/types/types';
import {
  CHROME,
  CHROME_EXTENSION, DESKTOP, EDGE, FIREFOX, IE, MOBILE, OPERA, SAFARI, TABLET,
} from '../enums';
import { isPageInIframe } from '../chromeExtension/chromeExtension';

/**
 * Declaring a global document interface, as documentMode is technically
 * deprecated and if referred in typescript gives error.
 *
 * This is used to detect if the browser is an Internet Explorer
 */
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Document {
    documentMode?: any;
  }
}

const logAppAnalytics = (
  authState: AuthState,
  hasSidebarOpened: boolean,
) => {
  if (authState.userId.length <= 0 || !hasSidebarOpened) return;

  const urlPath = window.location.pathname;
  const emptyPreviousMeetingPath = '/meeting//previousMeeting/';
  if (urlPath !== emptyPreviousMeetingPath) logAppEvent(authState.userId, APP_FIELD, LOADED_EVENT);
};

/**
 * Logs which
 *
 * Device: `DESKTOP` | `MOBILE` | `TABLET` | `CHROME_EXTENSION`
 *
 * and
 *
 * Browser: `CHROME` | `FIREFOX` | `SAFARI` | `OPERA` | `EDGE` | `IE` | `UNKNOWN`
 *
 * the user is using
 */
export const logPlatformAnalytics = (userId: string) => {
  const { userAgent } = navigator;
  const device: Device = detectDevice(userAgent);
  const browser: Browser = detectBrowser(userAgent);
  logEvent(userId, PLATFORM_USAGE_EVENT, { device, browser });
};

export const detectDevice = (userAgent: string) => {
  if (isPageInIframe()) {
    return CHROME_EXTENSION;
  }
  if (checkTabletRegex(userAgent)) {
    return TABLET;
  }
  if (checkMobileRegex(userAgent)) {
    return MOBILE;
  }
  return DESKTOP;
};

export const isMobileOrTablet = (userAgent: string) => {
  const device = detectDevice(userAgent);
  return device === MOBILE || device === TABLET;
};

const detectBrowser = (userAgent: string) => {
  if ((userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) !== -1) {
    return OPERA;
  } if (userAgent.indexOf('Edg') !== -1) {
    return EDGE;
  } if (userAgent.indexOf('Chrome') !== -1) {
    return CHROME;
  } if (userAgent.indexOf('Safari') !== -1) {
    return SAFARI;
  } if (userAgent.indexOf('Firefox') !== -1) {
    return FIREFOX;
  } if ((userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode === true)) {
    return IE;
  }
  return UNKNOWN;
};

const checkTabletRegex = (userAgent: string) => /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent);
const checkMobileRegex = (userAgent: string) => /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent);

export default logAppAnalytics;

/**
 * Checks properties if they are in the state that makes us want to
 * log events.
 *
 * We don't want to log if we are not logged in.
 * We also don't want to log if the sidebar is not open,
 * except if we are in the full page, then we want to log
 * events even though the isSidebarOpen property is set to false
 */
export const shouldWeLog = (userData: User, isSidebarOpen: boolean) => {
  if (userData.resolvedState !== 'resolved') return false;
  if (userData.userId === '') return false;
  const isInSidebar = isPageInIframe();
  if (isInSidebar && !isSidebarOpen) return false;
  return true;
};

export const shouldWeLogMeeting = (
  userData: User, isSidebarOpen: boolean, meetingId: string,
) => {
  if (!shouldWeLog(userData, isSidebarOpen)) return false;
  if (meetingId.length < 5) return false;
  return true;
};
