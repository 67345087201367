import React from 'react';
import styled from 'styled-components';

import { header400, defaultSmall } from '../../typography';
import CrossIcon from '../../icons/cross';
import { ToastNotificationType } from '../../types/types';
import { getToastNotificationBackgroundColor, getToastNotificationColor } from './ToastNotificationUtils';
import ToastNotificationIcon from './ToastNotificationIcon';

interface ColorProp {
  color: string
}

const Container = styled.div<ColorProp>`
  display: flex;
  padding: 16px;
  width: 344px;
  height: auto;
  background-color: ${(props) => (props.color)};
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  cursor: default;
`;

const IconContainer = styled.div`
  margin-right: 12px;
`;

const TextContainer = styled.div<ColorProp>`
  display: flex;
  flex-direction: column;
  color: ${(props) => (props.color)};
  width: 244px;
  margin-right: 16px;
`;

const TitleContainer = styled.span`
  ${header400};
  margin-bottom: 10px;
`;

const MessageContainer = styled.span`
  ${defaultSmall};
`;

const CrossIconContainer = styled.div`
  cursor: pointer;
`;

export interface Props {
  type: ToastNotificationType,
  title: string,
  message: string,
}

const ToastNotification = ({ type, title, message }: Props) => {
  const textColor = getToastNotificationColor(type);
  const backgroundColor = getToastNotificationBackgroundColor(type);

  return (
    <Container color={backgroundColor}>
      <IconContainer>
        <ToastNotificationIcon type={type} />
      </IconContainer>
      <TextContainer color={textColor}>
        <TitleContainer>{title}</TitleContainer>
        <MessageContainer>{message}</MessageContainer>
      </TextContainer>
      <CrossIconContainer>
        <CrossIcon />
      </CrossIconContainer>
    </Container>
  );
};

export default ToastNotification;
