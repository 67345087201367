/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { header800, uiTextMedium } from '../../shared/typography';
import ButtonSmall from '../../shared/components/button-small';
import ErrorImage from '../../shared/icons/ErrorImage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 135px;
`;

const Text = styled.h2`
  margin-top: 0;
  ${header800};
`;

const TextExplanationBottom = styled.h2`
  ${uiTextMedium};
  text-align: center;
  margin: 0;
`;

const TextExplanationTop = styled.div`
  ${uiTextMedium};
  margin-bottom: 24px;
`;

const OopsContainer = styled.div`
  margin-bottom: 16px;
`;

interface GoogleCalendarErrorPageTemplateProps {
  email: string;
  retry: () => void;
}

const GoogleCalendarErrorPageTemplate = ({ email, retry }:
  GoogleCalendarErrorPageTemplateProps) => {
  return (
    <Container>
      <ImageContainer>
        <ErrorImage />
      </ImageContainer>
      <OopsContainer>
        <Text>Oops!</Text>
      </OopsContainer>
      <TextExplanationTop>
        We could not get the meeting data from Google Calendar.
      </TextExplanationTop>
      {email && (
      <TextExplanationBottom>
        You are currently logged into Shepherd with
        {' '}
        <br />
        <strong>{email}</strong>
        <br />
        so make sure this account has access to the event in Google Calendar.
      </TextExplanationBottom>
      )}
      <ButtonContainer>
        <ButtonSmall text="Retry" onClick={retry} />
      </ButtonContainer>

    </Container>
  );
};

export default GoogleCalendarErrorPageTemplate;
