/* eslint-disable no-unused-vars */
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

export type LazyTransaction = (view: EditorView) => boolean;

export interface LazyTransactionAttr {
  id: string,
}

export interface LazyTransactionObject {
  tr: LazyTransaction;
  attr: LazyTransactionAttr;
}

export interface IndexedLTO {
  index: number;
  attr: LazyTransactionAttr;
  ret: boolean;
}

class LazyTransactionsAdapter {
  private list: LazyTransactionObject[] = [];

  private currentView: EditorView | null = null;

  // This is only a fallback for when currentView is not yet available.
  private currentSchema: EditorState['schema'] | null = null;
  // The currentSchema always resides in currentView, unless it's prior to plugin initialisation
  //                    (or prior to the actual construction of the EditorView)
  // Some plugins depend on the schema, which can be, and is constructed before and EditorView
  //                    thus it is sometimes useful to have a dedicated field for it

  append(trObj: LazyTransactionObject) {
    this.list.push(trObj);
  }

  clear(predicate?: (attr: LazyTransactionAttr) => boolean): IndexedLTO[] {
    if (!this.currentView) return [];
    const mapper = (trObj: LazyTransactionObject, index: number) => ({
      index,
      attr: trObj.attr,
      ret: trObj.tr(this.currentView!),
    });

    if (!predicate) {
      // fetch the processed list
      const process = this.list.map(mapper);
      // clear the processed list from the array
      this.list = []; // no predicate === clear all
      return process;
    }

    // fetch the processed list
    const process = this.list
      .filter((trObj) => predicate(trObj.attr))
      .map(mapper);
    // remove the processed list from the array
    this.list = this.list.filter((trObj) => !predicate(trObj.attr));
    return process;
  }

  set view(currentView: EditorView) {
    this.currentView = currentView;
    this.currentSchema = this.currentView.state.schema;
  }

  // TODO convert to getter and setter with identical name
  setSchema(currentSchema: EditorState['schema']) {
    this.currentSchema = currentSchema;
  }

  getSchema(): EditorState['schema'] | null {
    return this.currentView?.state.schema ?? this.currentSchema;
  }
}

export default new LazyTransactionsAdapter();
