import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface HighlightProps {
  fill?: string;
  width?: number;
  height?: number;
}

const HighlightIcon = ({
  fill,
  width,
  height,
}: HighlightProps) => (
  <svg width={width} height={height} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 5.3427152,5.8067909 5.8109698,-4.04262 0.8845,0.97193 -4.5704698,5.4056 z m -1.74758,0.30839 3.4015,3.73744 c 0.07041,0.0698 0.15483,0.1237995 0.24767,0.1584991 0.09285,0.0347 0.192,0.0493 0.29091,0.0429 0.0989,-0.0065 0.19532,-0.0338 0.28287,-0.0803 0.08756,-0.0464 0.16425,-0.111 0.22503,-0.1892995 l 5.5885698,-6.60969 c 0.1113,-0.12234 0.1743,-0.281 0.1773,-0.44639 0.0029,-0.16539 -0.0544,-0.32619 -0.1613,-0.45241 l -1.8848,-2.07087099 c -0.1156,-0.118293 -0.2703,-0.190476 -0.4353,-0.203079 -0.1649,-0.012602 -0.3288,0.035236 -0.461,0.134589 L 3.7613252,5.0796909 c -0.08367,0.05315 -0.15511,0.12345 -0.2096,0.20625 -0.05449,0.08281 -0.09079,0.17623 -0.10649,0.2741 -0.01571,0.09787 -0.01047,0.19796 0.01537,0.29366 0.02584,0.09569 0.07169,0.18482 0.13453,0.26148 z M 0,10.52202 l 3.7310952,0.6457 c 0.30407,0.0527 0.61806,-0.0404 0.84629,-0.2481 0.40845,-0.3718 0.44363,-1.0091996 0.07189,-1.4176291 l -0.9255,-1.01689 c -0.37173,-0.40844 -1.00418,-0.43821 -1.41263,-0.06648 z"
      fill={fill}
    />
  </svg>
);

HighlightIcon.defaultProps = {
  fill: darkBlue4,
  width: 14,
  height: 11,
};

export default HighlightIcon;
