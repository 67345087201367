import React from 'react';
import Font from '../../../../../shared/typography/Font';

const PaymentFailedTextAtom = () => (
  <Font font="uiTextS" color="gray7" id="announcement">
    Your payment attempt seems to have failed. Don’t worry, just try again!
  </Font>
);

export default PaymentFailedTextAtom;
