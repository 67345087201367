import React from 'react';
import { EmailRecipient } from '../../../../../../pages/meeting/modal/ShareNotesModal';
import SendIcon from '../../../../../icons/SendIcon';
import ButtonSmall from '../../../../button-small';
import ModalFooterButtonsRightAlignedWrapper from '../../../../Modal/ModalFooterButtonsRightAlignedWrapper';

interface InviteMembersModalFooterProps {
  loading: boolean,
  emailRecipients: EmailRecipient[],
  onCancelClick: () => void,
  onSubmitClick: () => void,
}

const InviteMembersModalFooter = ({
  loading, emailRecipients, onCancelClick, onSubmitClick,
}: InviteMembersModalFooterProps) => {
  const isDisabled = emailRecipients.length === 0;

  return (
    <ModalFooterButtonsRightAlignedWrapper>
      <ButtonSmall
        onClick={onCancelClick}
        text="Cancel"
        isOutline
      />
      <ButtonSmall
        type="submit"
        onClick={onSubmitClick}
        text="Send"
        hasLeftIcon
        Icon={SendIcon}
        loading={loading}
        isDisabled={isDisabled}
      />
    </ModalFooterButtonsRightAlignedWrapper>
  );
};

export default InviteMembersModalFooter;
