import * as Sentry from '@sentry/browser';
import { AuthState } from '../../shared/types/types';

const setSentryUser = (authState: AuthState) => {
  if (authState.userState !== 'loggedIn') return;
  if (authState.email.length === 0) {
    Sentry.setUser({
      id: authState.userId,
    });
    return;
  }
  Sentry.setUser({
    id: authState.userId,
    email: authState.email,
    username: `${authState.firstName} ${authState.lastName}`,
  });
};

type Environment = 'local' | 'staging' | 'production' | 'unknown';

export const getEnvironment = (): Environment => {
  const isLocalhost = window.location.hostname === 'localhost';
  if (isLocalhost) return 'local';

  const isStaging = window.location.hostname.includes('staging');
  if (isStaging) return 'staging';

  const isProduction = window.location.hostname.includes('app');
  if (isProduction) return 'production';

  return 'unknown';
};

export default setSentryUser;
