import React, {
  Dispatch, SetStateAction, useContext, useEffect,
} from 'react';
import MeetingDataContext from '../../../../../../pages/meeting/context/MeetingDataContext';
import EmailMultiInput from '../../../../../../pages/meeting/modal/EmailMultiInput';
import { EmailRecipient } from '../../../../../../pages/meeting/modal/ShareNotesModal';
import { mapAttendeesToEmailRecipientsV2 } from '../../../../../../utils/meetings/meetingsUtils';
import ModalLabel from '../../../../Modal/ModalLabel';
import { ModalContent, ModalContentTitle } from './utils/styles';

interface InviteMembersModalContentProps {
  emailRecipients: EmailRecipient[],
  setEmailRecipients: Dispatch<SetStateAction<EmailRecipient[]>>,
}

const InviteMembersModalContent = ({
  emailRecipients,
  setEmailRecipients,
}: InviteMembersModalContentProps) => {
  const { attendees: { attendees } } = useContext(MeetingDataContext);
  const nonShepherdAttendees = attendees.filter((attendee) => !attendee.userId.length);

  useEffect(() => {
    const newEmailRecipients = mapAttendeesToEmailRecipientsV2(nonShepherdAttendees);
    setEmailRecipients(newEmailRecipients);
  }, [attendees]);

  const handleAddEmails = () => {
    const newEmailRecipients = mapAttendeesToEmailRecipientsV2(nonShepherdAttendees);
    setEmailRecipients(newEmailRecipients);
  };

  const handleRemoveEmails = () => {
    setEmailRecipients([]);
  };

  return (
    <ModalContent>
      <ModalContentTitle>Enter the emails of people you’d like to use Shepherd</ModalContentTitle>
      <ModalLabel text="Email recipients" />
      <EmailMultiInput
        recipients={emailRecipients}
        handleAddEmails={handleAddEmails}
        handleRemoveEmails={handleRemoveEmails}
        setEmailRecipients={setEmailRecipients}
        candidates={nonShepherdAttendees}
        isChecked
      />
    </ModalContent>
  );
};
export default InviteMembersModalContent;
