import React from 'react';
import styled from 'styled-components';
import Font from '../../../typography/Font';
import WorkspaceProfileIconAtom from './WorkspaceProfileIconAtom';
import { COLORS } from '../../../colors/COLORS';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > *:first-child {
    margin-bottom: 16px;
  }
  margin-bottom: 16px;
`;

const WorkspaceNameContainer = styled.div`
`;

interface WorkspaceModalWorkspaceNameAndIconMolProps {
  workspaceName: string,
}

const WorkspaceModalWorkspaceNameAndIconMol = (
  { workspaceName }: WorkspaceModalWorkspaceNameAndIconMolProps,
) => (
  <Container>
    <WorkspaceProfileIconAtom
      name={workspaceName}
      color={COLORS.error7}
      size={80}
      isSelected={false}
      isBig
    />
    <WorkspaceNameContainer>
      <Font font="header700">
        {workspaceName}
        {' '}
        Workspace
      </Font>
    </WorkspaceNameContainer>
  </Container>
);

export default WorkspaceModalWorkspaceNameAndIconMol;
