import React from 'react';
import styled from 'styled-components';
import logo from './RelatedMeetingPNG.png'; // Tell webpack this JS file uses this image

const Container = styled.div`
  display: flex;
  img {
    width: 26px;
    height: 24px;
  }
`;

function RelatedMeetingIcon() {
  // Import result is the URL of your image
  return (
    <Container>
      <img src={logo} alt="Logo" />
    </Container>
  );
}

export default RelatedMeetingIcon;
