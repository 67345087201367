import styled from 'styled-components';
import { CSS_CONSTANTS } from '../../CSS/CSS_Constants';

// Will space out the components to each side of the modal
// Often used with a LeftContainer and a RightContainer inside
// this component
// I.e. use ModalFooterRightButtonsWrapper for the right
// container

interface StyleProps {
  addPadding?: boolean
}

const ModalFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ addPadding }:StyleProps) => (addPadding ? '10px 24px' : '')};
`;

export default ModalFooterWrapper;

const RightButtonMargin = '18px';

export const ModalFooterRightButtonsWrapper = styled.div`
  position: absolute;
  bottom: 10px;

  right: ${RightButtonMargin};
  @media only screen and (min-width: ${CSS_CONSTANTS.MAX_WIDTH_FULL_PAGE}){
    right: calc((100vw - ${CSS_CONSTANTS.MAX_WIDTH_FULL_PAGE})*0.5 + ${RightButtonMargin} );
  }

  display: flex;
  margin-right: 10px;

  button:first-of-type {
    margin-right: 8px;
  }
`;

export const SingleButtonModalFooterdWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 24px;
`;

ModalFooterWrapper.defaultProps = {
  addPadding: true,
};
