import { PENDING, RESOLVED } from '../../../../utils/enums';
import { RelevantMeetingsData } from '../../../types/types';

/**
 * RelevantMeetingsUtils
 */
namespace RelMeetUtils {
  export const pendingData = {
    futureMeetings: [],
    previousMeetings: [],
    resolveState: PENDING,
  } as RelevantMeetingsData;

  export const resolvedEmptyData = {
    futureMeetings: [],
    previousMeetings: [],
    resolveState: RESOLVED,
  } as RelevantMeetingsData;
}

export default RelMeetUtils;
