import React from 'react';
import styled from 'styled-components';
import ButtonOutline from '../../../../shared/components/buttons/ButtonOutline/ButtonOutline';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;

  > *:first-child {
    margin-right: 8px;
  }
  > *:last-child {
    font-size: 12px;
  }
`;

interface StartTrialOrSubscribeMoleculeProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onClickBack: () => void,
}

const LearnMoreBackButtonAtom = ({
  onClickBack,
}: StartTrialOrSubscribeMoleculeProps) => (
  <ButtonContainer>
    <ButtonOutline padding="4px 12px" onClick={onClickBack}>
      Back
    </ButtonOutline>
  </ButtonContainer>
);

export default LearnMoreBackButtonAtom;
