import React from 'react';

const WindowsLogo = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="9.5625" y="9.5625" width="5.625" height="5.625" fill="#FEBA08" />
    <rect x="2.8125" y="9.5625" width="5.625" height="5.625" fill="#05A6F0" />
    <rect x="9.5625" y="2.8125" width="5.625" height="5.625" fill="#80BC06" />
    <rect x="2.8125" y="2.8125" width="5.625" height="5.625" fill="#F25325" />
  </svg>
);

export default WindowsLogo;
