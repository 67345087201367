import { RRule } from 'rrule';
import { Dispatch, SetStateAction } from 'react';
import { captureException } from '@sentry/react';
import { gapiGetMeeting } from '../../../../../utils/google/GoogleCalendarAPI';
import { capitalize } from '../../../../../utils/strings';
import { MeetingData } from '../../../../../shared/types/MeetingData';

const getMeetingRecurrence = async (
  recurringEventId: string,
  calendarId: string,
  email: string,
  meetingData: MeetingData,
  setMeetingRecurrence: Dispatch<SetStateAction<string>>,
) => {
  if (recurringEventId.length === 0 || calendarId.length === 0 || email.length === 0) return;

  const gapiData = await gapiGetMeeting(recurringEventId, calendarId, email, meetingData);
  if (gapiData.resolvedState !== 'resolved') return;

  const unformattedRecurrence = parseReccurenceRule(gapiData.recurrence[0]);
  const formattedRecurrence = capitalize(unformattedRecurrence);
  setMeetingRecurrence(formattedRecurrence);
};

const parseReccurenceRule = (rrule: string) => {
  try {
    const rule = RRule.fromString(rrule);
    return rule.toText();
  } catch (error: any) {
    captureException({ ...error, rrule });
    return 'N/A';
  }
};

export default getMeetingRecurrence;
