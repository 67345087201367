import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../../App';
import ConsoleImproved from '../../../shared/classes/ConsoleImproved';
import ProseMirrorComponentV2 from '../../../shared/components/prosemirror';
import { MEETING_SECTION } from '../../../utils/enums';
// eslint-disable-next-line no-unused-vars
import { getFirepadPathV3 } from '../../../utils/firebase';
import MeetingDataContext from '../context/MeetingDataContext';
import { NotesDataKeys } from './ShareModal/utils/ShareModalTypes';

const EditorsContainer = styled.div`
  display: none;
`;

interface Props {
  // eslint-disable-next-line no-unused-vars
  updateNotes: (noteType: NotesDataKeys, newText: string) => void,
}

const FetchProseMirrorTextEditors = ({
  updateNotes,
}: Props) => {
  const { userId } = useContext(AuthContext);
  const { meetingId, version } = useContext(MeetingDataContext);

  const displayNotes = false;

  useEffect(() => {
    ConsoleImproved.log('Rendering FetchProsemirrorTextEditors');
  }, []);

  const setNotes = (noteType: NotesDataKeys) => (newText: string) => {
    updateNotes(noteType, newText);
  };

  if (version >= 2) {
    return (
      <EditorsContainer>
        <ProseMirrorComponentV2
          path={getFirepadPathV3(MEETING_SECTION.SHARED_NOTES, meetingId, 'prosemirror', userId)}
          meetingSection={MEETING_SECTION.SHARED_NOTES}
          displayNotes={displayNotes}
          setNotes={setNotes('shared')}
        />
        <ProseMirrorComponentV2
          path={getFirepadPathV3(MEETING_SECTION.PERSONAL_NOTES, meetingId, 'prosemirror', userId)}
          meetingSection={MEETING_SECTION.PERSONAL_NOTES}
          displayNotes={displayNotes}
          setNotes={setNotes('my')}
        />
      </EditorsContainer>
    );
  }

  return null;
};

export default FetchProseMirrorTextEditors;
