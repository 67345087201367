import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { User } from '../../shared/types/types';
import { REJECTED } from '../../utils/enums';
import { USER_PATH } from '../FirebaseConstants';
import UserAPICore from './UserAPICore';

class UserPermissionsAPI extends UserAPICore {
  static updateUserCalendarIds = async (userData: User, calendarIds: string[]) => {
    if (calendarIds.length === 0) return REJECTED;
    if (!UserAPICore.isDifferentCalendarIds(userData, calendarIds)) {
      return REJECTED;
    }
    ConsoleImproved.log('Fetched calendarIds is different from what is in Firestore', { userData, calendarIds });

    const updates = { [USER_PATH.permissions.google.calendars]: calendarIds };
    ConsoleImproved.log('Updating calendarIds', updates);
    return UserAPICore.updateUser(userData.userId, updates, 'calendarIds');
  }

  static updateUserWithHaraldTag = async (userId: string, haraldTag: string) => {
    const updates = { [USER_PATH.permissions.tags]: [haraldTag] };
    ConsoleImproved.log('Updating haraldTag', updates);
    return UserAPICore.updateUser(userId, updates, 'haraldTag');
  }
}

export default UserPermissionsAPI;
