/* eslint-disable no-unused-vars */
import {
  codeMirrorBlockPlugin,
  defaultSettings,
  languageLoaders,
  codeBlockArrowHandlers,
  legacyLanguageLoaders,
  CodeBlockSettings,
} from 'prosemirror-codemirror-block';
import { undo as legacyUndo, redo as legacyRedo } from 'prosemirror-history';
import { undo, redo } from 'y-prosemirror';
import { keymap } from 'prosemirror-keymap';
import { EditorView } from 'prosemirror-view';
import { ProsemirrorNode } from 'prosemirror-suggest';

const langLoaders = { ...languageLoaders, ...legacyLanguageLoaders };

export default (legacy = false) => [
  codeMirrorBlockPlugin({
    ...defaultSettings,
    languageLoaders: langLoaders,
    undo: legacy ? legacyUndo : undo,
    redo: legacy ? legacyRedo : redo,
    // eslint-disable-next-line max-len
    createSelect: (settings: CodeBlockSettings, dom: HTMLElement, node: ProsemirrorNode, view: EditorView, getPos: boolean | (() => number)) => {
      const select = document.createElement('select');
      select.className = 'codeblock-select';
      const noneOption = document.createElement('option');
      noneOption.value = 'none';
      noneOption.textContent = settings.languageNameMap?.none || 'none';
      select.append(noneOption);
      Object.keys(langLoaders)
        .sort()
        .forEach((lang) => {
          if (
            settings.languageWhitelist
            && !settings.languageWhitelist.includes(lang)
          ) { return; }
          const option = document.createElement('option');
          option.value = lang;
          option.textContent = settings.languageNameMap?.[lang] || lang;
          select.append(option);
        });
      select.value = node.attrs.lang || 'none';
      dom.prepend(select);
      select.onchange = async (e) => {
        if (!(e.target instanceof HTMLSelectElement)) return;
        const lang = e.target.value;
        if (typeof getPos === 'function') {
          view.dispatch(
            view.state.tr.setNodeMarkup(getPos(), undefined, {
              ...node.attrs,
              lang,
            }),
          );
        }
      };
      select.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      };
      // Delete code.
      return () => {};
    },
  }),
  keymap(codeBlockArrowHandlers),
];
