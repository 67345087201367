import React from 'react';
import styled from 'styled-components';
import Notification from '../../../classes/Notification/Notification';
import MarkReadUnreadStatusIcon from '../atoms/MarkReadUnreadStatusIcon';
import NotificationHeadingText from '../atoms/NotificationHeadingText';

interface Props{
  notification: Notification
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function NotificationHeading({ notification }:Props) {
  return (
    <Container>
      <NotificationHeadingText notification={notification} />
      <MarkReadUnreadStatusIcon
        notification={notification}
        key={notification.db.documentId}
      />
    </Container>
  );
}
