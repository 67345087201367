import React from 'react';
import { surface } from '../colors/COLORS';

interface Props {
  fill?: string,
  rotateBy?: number
}

// difference with ArrowRight2: https://github.com/meetshepherd/dashboard/pull/337#discussion_r769511017
const ArrowRight = ({ fill, rotateBy }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(${rotateBy}deg)` }}>
    <g id="Iconly/Light-outline/Arrow - Right">
      <g id="Arrow - Right">
        <path id="Arrow - Right_2" fillRule="evenodd" clipRule="evenodd" d="M3.70716 11.1771C3.3078 11.2321 3 11.5795 3 11.9999C3 12.4585 3.36631 12.8302 3.81818 12.8302H18.1999L13.0047 18.0813L12.9253 18.1743C12.687 18.4989 12.7124 18.9602 13.0023 19.2556C13.3212 19.5805 13.8392 19.5816 14.1594 19.258L20.7477 12.5996C20.787 12.5614 20.8224 12.5194 20.8536 12.474C21.0766 12.1498 21.0452 11.6999 20.7593 11.4111L14.1593 4.74193L14.0674 4.66174C13.7466 4.42125 13.2921 4.44905 13.0023 4.74446C12.6834 5.06942 12.6845 5.59515 13.0047 5.91871L18.2012 11.1696L3.81818 11.1696L3.70716 11.1771Z" fill={fill} />
      </g>
    </g>
  </svg>
);

ArrowRight.defaultProps = {
  fill: surface,
  rotateBy: 0,
};

export default ArrowRight;
