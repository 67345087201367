import React from 'react';
import styled from 'styled-components';
import { header600 } from '../../../shared/typography';
import useWindowSize from '../../../utils/hook/useWindowSize';

const Text = styled.span`
  ${header600}
  text-align: center;
  white-space: pre-wrap;
`;

interface Props {
  text: string,
}

const SubTitleAtom = ({ text }: Props) => {
  const { isScreenSizeSM } = useWindowSize();
  if (isScreenSizeSM) return null;

  return (
    <Text>{text}</Text>
  );
};

export default SubTitleAtom;
