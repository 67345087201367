import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface RedoProps {
  fill?: string;
  width?: number;
  height?: number;
}

const RedoIcon = ({
  fill,
  width,
  height,
}: RedoProps) => (
  <svg width={width} height={height} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 9.75,12.219668 c 0,0.4142 -0.336,0.75 -0.75,0.75 H 4.875 C 2.183,12.969668 0,10.787067 0,8.0946675 c 0,-2.6924 2.183,-4.875 4.875,-4.875 H 10.94 L 9,1.2803675 c -0.293,-0.2929 -0.293,-0.7678 0,-1.0607 0.293,-0.29289 0.768,-0.29289 1.061,0 l 3.75,3.75 -3.75,3.75 c -0.293,0.2929 -0.768,0.2929 -1.061,0 -0.293,-0.2929 -0.293,-0.7678 0,-1.0607 l 1.94,-1.9393 H 4.875 c -1.864,0 -3.375,1.511 -3.375,3.375 0,1.864 1.511,3.3750005 3.375,3.3750005 H 9 c 0.414,0 0.75,0.335799 0.75,0.75 z"
      fill={fill}
    />
  </svg>
);

RedoIcon.defaultProps = {
  fill: darkBlue4,
  width: 14,
  height: 13,
};

export default RedoIcon;
