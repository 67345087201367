/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../../shared/components/Modal';
import BillingModalTitle from '../../BillingModalTitle';
import YouAreNowOnTheFreePlanButtonsCardMol from './YouAreNowOnTheFreePlanButtonsCardMol';
import YouAreNowOnTheFreePlanTextAtom from './YouAreNowOnTheFreePlanTextAtom';

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const MainContentContainer = styled.div`
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
  }

  #announcement {
    margin-bottom: 25px;
    padding: 0 10px;
  }

  #forOnlySevenDollars {
    background: linear-gradient(180deg, #6772C8 0%, #D691E5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    margin-bottom: 25px;

    font-weight: 600;
  }

  #LearnMore {
    margin-top: 12px;
  }
`;

interface YouAreNowOnTheFreePlanModalProps {
  isOpen: boolean,
  onClickBack: () => void,
  onClickClose: () => void,
}

const YouAreNowOnTheFreePlanModal = ({
  isOpen,
  onClickBack,
  onClickClose,
}: YouAreNowOnTheFreePlanModalProps) => {
  return (
    <Modal
      title=""
      isOpen={isOpen}
      setModalClosed={() => { }}
      isExitButtonEnabled={false}
      isHeadless
      blurriness={2}
    >
      <Container>
        <BillingModalTitle title="You are now on the free plan" showCloseIcon onClose={onClickClose} />
        <MainContentContainer>
          <YouAreNowOnTheFreePlanTextAtom />
          <YouAreNowOnTheFreePlanButtonsCardMol
            onClickClose={onClickClose}
            onClickSubscribe={onClickBack}
          />
        </MainContentContainer>
      </Container>
      {/* <ModalLineLocal /> */}
      {/* <ModalFooterWrapper /> */}
    </Modal>
  );
};

export default YouAreNowOnTheFreePlanModal;
