import React from 'react';
import { surface } from '../colors/COLORS';

interface Props {
  fill?: string,
  width?: number,
}

const UpArrow = ({
  fill,
  width,
}: Props) => (
  <svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.29201 13.707C8.1052 13.5181 8.00043 13.2632 8.00043 12.9975C8.00043 12.7318 8.1052 12.4769 8.29201 12.288L11.231 9.323C11.449 9.108 11.731 9.001 12.01 9.001C12.289 9.001 12.566 9.108 12.779 9.323L15.709 12.278C15.8956 12.467 16.0002 12.7219 16.0002 12.9875C16.0002 13.2531 15.8956 13.508 15.709 13.697C15.6172 13.7902 15.5077 13.8642 15.387 13.9147C15.2664 13.9652 15.1368 13.9912 15.006 13.9912C14.8752 13.9912 14.7457 13.9652 14.625 13.9147C14.5043 13.8642 14.3948 13.7902 14.303 13.697L12.005 11.38L9.69801 13.707C9.60597 13.7998 9.49646 13.8735 9.3758 13.9238C9.25514 13.9741 9.12572 13.9999 8.99501 13.9999C8.86429 13.9999 8.73487 13.9741 8.61421 13.9238C8.49355 13.8735 8.38404 13.7998 8.29201 13.707Z" fill={fill} />
  </svg>
);

UpArrow.defaultProps = {
  fill: surface,
  width: 24,
};

export default UpArrow;
