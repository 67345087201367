import React from 'react';
import styled from 'styled-components';
import ButtonSmall from '../button-small';
import { red6, gray7 } from '../../colors/COLORS';
import { uiTextMedium, uiText } from '../../typography';
import { getTimeFromNow } from '../../../utils/dateUtils/date';
import ModalFooterButtonsRightAlignedWrapper from '../Modal/ModalFooterButtonsRightAlignedWrapper';

const DeleteTaskText = styled.div`
  ${uiTextMedium}
  display: flex;
  align-items:center;
  margin-right: auto;
  color: ${red6};
  cursor: pointer;
  height: 32px;
`;

const TimeText = styled.div`
  ${uiText}
  color: ${gray7};
`;

interface Props {
  setModalClosed: () => void,
  isButtonDisabled: boolean,
  onCreateClick: () => void,
  confirmButtonName: string,
  loading: boolean,
  inTaskDetails?: boolean,
  canUpdate?: boolean
  updatedAt?: any
  openDeleteModal?: any
}

const TaskModalFooterButtons = ({
  setModalClosed, isButtonDisabled, onCreateClick, loading,
  confirmButtonName, inTaskDetails, canUpdate, updatedAt, openDeleteModal,
}: Props) => (
  // TODO refactor
  <ModalFooterButtonsRightAlignedWrapper>
    {inTaskDetails && (
    <DeleteTaskText onClick={() => {
      setModalClosed();
      openDeleteModal();
    }}
    >
      Delete Task
    </DeleteTaskText>
    )}
    {canUpdate || !inTaskDetails
      ? (
        <>
          <ButtonSmall onClick={setModalClosed} text="Cancel" isOutline />
          <ButtonSmall
            type="submit"
            onClick={onCreateClick}
            text={confirmButtonName}
            loading={loading}
            isDisabled={isButtonDisabled}
          />
        </>
      ) : (
        <TimeText>
          Updated
          {' '}
          {getTimeFromNow(updatedAt)}
        </TimeText>
      )}
  </ModalFooterButtonsRightAlignedWrapper>
);

TaskModalFooterButtons.defaultProps = {
  inTaskDetails: false,
  canUpdate: true,
  updatedAt: '',
  openDeleteModal: '',
};

export default TaskModalFooterButtons;
