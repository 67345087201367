import React from 'react';
import { darkBlue4 } from '../../../../colors/COLORS';

interface CopyProps {
  fill?: string;
  width?: number;
  height?: number;
}

const CopyIcon = ({
  fill,
  width,
  height,
}: CopyProps) => (
  <svg width={width} height={height} viewBox="0 0 16.5 16.5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 3.7861015,0.05563282 c -2.05403,1e-7 -3.73046868,1.67643878 -3.73046868,3.73046868 V 8.625945 c 0,2.054031 1.67643898,3.730469 3.73046868,3.730469 h 0.4335937 v 0.427734 c 0,2.054067 1.6764024,3.730469 3.7304688,3.730469 h 4.839844 c 2.054066,0 3.730469,-1.676402 3.730469,-3.730469 V 7.9443046 c 0,-2.0540664 -1.676403,-3.7304687 -3.730469,-3.7304687 H 12.356414 V 3.7861015 c 0,-2.0540299 -1.676438,-3.73046868 -3.7304691,-3.73046868 z m 0,1.48828118 h 4.8398434 c 1.2549548,0 2.2421881,0.9872341 2.2421881,2.2421875 V 4.2138359 H 7.950164 c -0.6418844,0 -1.2468922,0.1633801 -1.7753906,0.4511719 -0.1056998,0.057558 -0.2093323,0.1203333 -0.3085938,0.1875 C 5.5689149,5.0538388 5.3013062,5.297044 5.0732109,5.5732109 4.9970693,5.6653999 4.9255337,5.7610588 4.8583671,5.8603202 4.4548274,6.4561455 4.2196953,7.1734847 4.2196953,7.9443046 V 10.868133 H 3.7861015 c -1.2549536,0 -2.2421875,-0.9872327 -2.2421875,-2.242188 V 3.7861015 c 0,-1.2549534 0.9872341,-2.2421875 2.2421875,-2.2421875 z M 7.950164,5.7021171 h 3.66211 1.177734 c 1.254917,0 2.242187,0.9872707 2.242187,2.2421875 v 4.8398434 c 0,1.254917 -0.98727,2.242188 -2.242187,2.242188 H 7.950164 c -1.2549168,0 -2.2421875,-0.987271 -2.2421875,-2.242188 V 11.612273 7.9443046 c 0,-0.078432 0.00418,-0.1562562 0.011719,-0.2324218 C 5.8327517,6.5693722 6.7736452,5.7021171 7.950164,5.7021171 Z"
      fill={fill}
    />
  </svg>
);

CopyIcon.defaultProps = {
  fill: darkBlue4,
  width: 16.5,
  height: 16.5,
};

export default CopyIcon;
