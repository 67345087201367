import React, { Dispatch, SetStateAction, useContext } from 'react';
import EnableCrossIcon from '../../../../shared/icons/EnableCrossIcon';
import {
  CrossIconContainer, EnableButton, InnerContainer,
  IntegrationContainer, IntegrationText,
} from '../integrations/utils/commonStyles';
import { trelloAuthUrl } from '../../../../utils/trello/trelloUtils';
import { IntegrationsTabView } from '../../../../shared/types/types';
import { TRELLO_PROCESSING_VIEW } from '../../../../utils/constants';
import { TrelloIcon } from '../../../../shared/icons/integration-icons';
import {
  OPEN_EVENT, SETTINGS_MODAL_LOCATION, SHARE_MODAL_LOCATION, TRELLO_INTEGRATION,
} from '../../../../utils/analytics/enums';
import { logIntegrationsEvent } from '../../../../utils/analytics/eventLogger';
import { AuthContext } from '../../../../App';

interface Props {
  enable: boolean,
  setView: Dispatch<SetStateAction<IntegrationsTabView>>,
  analyticsFrom: typeof SHARE_MODAL_LOCATION | typeof SETTINGS_MODAL_LOCATION;
}

export default function TrelloNotIntegrated({ enable, setView, analyticsFrom }: Props) {
  const authState = useContext(AuthContext);

  const handleButtonClick = () => {
    try {
      window.open(trelloAuthUrl);
      setView(TRELLO_PROCESSING_VIEW);
      logIntegrationsEvent(authState.userId, OPEN_EVENT, TRELLO_INTEGRATION, analyticsFrom);
    } catch (error) {
      console.log(error);
    }
  };
  if (enable) return null;

  return (
    <>
      <IntegrationContainer>
        <InnerContainer>
          <TrelloIcon width="28" />
          <IntegrationText>Trello</IntegrationText>
        </InnerContainer>
        <InnerContainer>
          <CrossIconContainer onClick={handleButtonClick}>
            <EnableCrossIcon />
            <EnableButton>Enable</EnableButton>
          </CrossIconContainer>
        </InnerContainer>
      </IntegrationContainer>
    </>
  );
}
