import React from 'react';
import { DEFAULT_TEXT_SIZE } from '../../utils/constants';

interface Props {
  width?: number;
}

const ShepherdText = ({ width }:Props) => (
  <svg width={`${width}px`} viewBox="0 0 128 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="wordmark">
      <path d="M7.72097 18.22C8.88753 18.4812 9.40599 18.9056 9.37359 19.6565C9.37359 20.538 8.53108 21.1583 7.39693 21.191C5.97114 21.2236 4.70738 20.3421 4.61016 19.2648H0.916077C0.948481 20.8319 1.56416 22.0725 2.76312 23.0192C3.99448 23.9334 5.42027 24.3904 7.00808 24.3904C8.6931 24.3904 10.1513 23.966 11.3502 23.1498C12.5816 22.3336 13.1973 21.1583 13.1973 19.6892C13.1973 18.4812 12.7112 16.0326 9.24397 15.1185L6.71644 14.4982C5.6471 14.2044 5.12863 13.78 5.12863 13.2249C5.12863 12.2129 5.77672 11.7231 7.04048 11.7231C8.07742 11.7231 9.04955 12.2455 9.08195 13.2902H12.8408C12.776 11.6579 12.1604 10.4499 11.0262 9.66634C9.89206 8.8828 8.53108 8.49103 6.94327 8.49103C5.42027 8.49103 4.1241 8.94809 3.02235 9.86223C1.92061 10.7437 1.36974 11.8211 1.36974 13.0291C1.36974 14.8573 1.75859 16.5877 5.6471 17.6977L7.72097 18.22Z" fill="#1B2124" />
      <path d="M19.0334 15.9347C19.0334 13.4861 20.7184 12.3761 22.6626 12.3761C24.3801 12.3761 25.8706 13.682 25.8706 15.7715V24.064H29.824V15.5756C29.824 11.1028 27.6205 8.49103 23.732 8.49103C22.8571 8.49103 21.9173 8.71956 20.9452 9.20928C20.0055 9.66634 19.3574 10.3193 19.0334 11.1681V0.524994H15.08V24.064H19.0334V15.9347Z" fill="#1B2124" />
      <path d="M47.0603 16.3918C47.0603 14.0411 46.315 12.1476 44.8568 10.6784C43.3986 9.20928 41.5839 8.49103 39.3804 8.49103C37.1769 8.49103 35.3299 9.20928 33.8069 10.6784C32.2839 12.1476 31.5386 14.0411 31.5386 16.3918C31.5386 18.7424 32.2839 20.6686 33.8069 22.1704C35.3299 23.6395 37.1769 24.3904 39.3804 24.3904C42.394 24.3904 45.1484 23.1825 46.4446 20.7013L43.431 19.0689C42.7505 20.3421 41.2275 21.0277 39.6397 21.0277C37.4038 21.0277 35.7188 19.7545 35.4595 17.763H46.9954C47.0279 17.2733 47.0603 16.8162 47.0603 16.3918ZM35.4271 15.0532C35.7188 13.0617 37.3066 11.8211 39.4128 11.8211C41.3895 11.8211 42.9125 12.9964 43.2041 15.0532H35.4271Z" fill="#1B2124" />
      <path d="M48.7691 8.8175V31.475H52.7224V22.0398C53.5001 23.509 55.4119 24.3904 57.4534 24.3904C59.4949 24.3904 61.2447 23.6722 62.6705 22.2031C64.1287 20.7339 64.8416 18.8077 64.8416 16.4244C64.8416 14.1064 64.1287 12.1802 62.6705 10.7111C61.2123 9.24192 59.4949 8.49103 57.4534 8.49103C55.4119 8.49103 53.5001 9.40516 52.7224 10.8743V8.8175H48.7691ZM52.7872 16.4897C52.7872 14.0085 54.5694 12.3108 56.8053 12.3108C57.9071 12.3108 58.8468 12.7026 59.6245 13.4861C60.4022 14.2697 60.7911 15.2817 60.7911 16.4897C60.7911 18.9383 59.0412 20.6033 56.8053 20.6033C54.6018 20.6033 52.7872 19.0036 52.7872 16.4897Z" fill="#1B2124" />
      <path d="M70.7219 15.9347C70.7219 13.4861 72.407 12.3761 74.3512 12.3761C76.0686 12.3761 77.5592 13.682 77.5592 15.7715V24.064H81.5126V15.5756C81.5126 11.1028 79.3091 8.49103 75.4206 8.49103C74.5456 8.49103 73.6059 8.71956 72.6338 9.20928C71.6941 9.66634 71.046 10.3193 70.7219 11.1681V0.524994H66.7686V24.064H70.7219V15.9347Z" fill="#1B2124" />
      <path d="M98.7488 16.3918C98.7488 14.0411 98.0035 12.1476 96.5454 10.6784C95.0872 9.20928 93.2725 8.49103 91.069 8.49103C88.8655 8.49103 87.0185 9.20928 85.4955 10.6784C83.9725 12.1476 83.2272 14.0411 83.2272 16.3918C83.2272 18.7424 83.9725 20.6686 85.4955 22.1704C87.0185 23.6395 88.8655 24.3904 91.069 24.3904C94.0826 24.3904 96.837 23.1825 98.1332 20.7013L95.1196 19.0689C94.4391 20.3421 92.9161 21.0277 91.3283 21.0277C89.0924 21.0277 87.4074 19.7545 87.1481 17.763H98.684C98.7164 17.2733 98.7488 16.8162 98.7488 16.3918ZM87.1157 15.0532C87.4074 13.0617 88.9952 11.8211 91.1014 11.8211C93.0781 11.8211 94.6011 12.9964 94.8927 15.0532H87.1157Z" fill="#1B2124" />
      <path d="M104.411 18.6771C104.411 14.3676 105.675 12.2129 108.202 12.2129C109.11 12.2129 109.887 12.3435 110.503 12.6373L111.183 9.04604C110.503 8.68691 109.66 8.49103 108.688 8.49103C106.841 8.49103 105.156 9.79694 104.411 12.4087V8.8175H100.458V24.064H104.411V18.6771Z" fill="#1B2124" />
      <path d="M127.009 24.064V0.524994H123.088V10.8417C122.278 9.37251 120.398 8.49103 118.324 8.49103C116.283 8.49103 114.533 9.24192 113.075 10.7111C111.617 12.1802 110.904 14.0738 110.904 16.4571C110.904 18.8077 111.617 20.7339 113.075 22.2031C114.533 23.6722 116.283 24.3904 118.324 24.3904C120.366 24.3904 122.278 23.4763 123.088 22.0072V24.064H127.009ZM118.972 12.2782C120.042 12.2782 120.981 12.6699 121.792 13.4208C122.602 14.1717 123.023 15.1512 123.023 16.3918C123.023 17.6324 122.634 18.6445 121.824 19.428C121.046 20.1789 120.107 20.5707 118.972 20.5707C117.838 20.5707 116.899 20.1789 116.121 19.428C115.376 18.6445 114.987 17.6324 114.987 16.3918C114.987 13.9432 116.737 12.2782 118.972 12.2782Z" fill="#1B2124" />
    </g>
  </svg>
);

ShepherdText.defaultProps = {
  width: DEFAULT_TEXT_SIZE,
};

export default ShepherdText;
