import React, { useState } from 'react';
import { darkBlue4, darkBlue6 } from '../../../colors/COLORS';
import PaperPlusIcon from '../../../icons/PaperPlusIcon';
import {
  AddPrivateNotesButtonContainer,
  AddPrivateNotesTextContainer,
} from './utils/styles';

const AddPrivateNotesButton = () => {
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  return (
    <AddPrivateNotesButtonContainer
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <PaperPlusIcon fill={isMouseOver ? darkBlue6 : darkBlue4} />
      <AddPrivateNotesTextContainer>Private notes</AddPrivateNotesTextContainer>
    </AddPrivateNotesButtonContainer>
  );
};

export default AddPrivateNotesButton;
