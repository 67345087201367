/* eslint-disable import/prefer-default-export */

// ======= CheckoutOrigins  ========
const STRIPE_CHECKOUT_ORIGINS = {
  TrialExtension: 'trialExtension',
  ExistingUserBeforeTrial: 'existingUserBeforeTrial',
  DuringOnboarding: 'duringOnboarding',
  DuringTrial: 'duringTrial',
  AtEndedTrial: 'atEndedTrial',
  DuringFreePlan: 'duringFreePlan',
  DuringFreePlanHitMeetingLimitation: 'duringFreePlanHitMeetingLimitation',
  DuringFreePlanHitSearchLimitation: 'duringFreePlanHitSearchLimitation',
  WorkspaceSettings: 'workspaceSettings',
} as const;

const PAYWALL_ORIGINS = {
  OpenMeetingOutsidePeriod: 'openMeetingOutsidePeriod',
  ExceededNumberOfMeetings: 'exceededNumberOfMeetings',
} as const;

const SubscriptionStatuses = {
  /**
   * @property {string} trialing - The subscription is currently in a trial period and
   * it’s safe to provision your product for your customer. The subscription transitions
   * automatically to active when the first payment is made.
   */
  trialing: 'trialing',
  /**
   * @property {string} active - The subscription is in good standing and the most recent
   * payment is successful. It’s safe to provision your product for your customer.
   */
  active: 'active',
  /**
   * @property {string} incomplete - A successful payment needs to be made within 23 hours
   *  to activate the subscription. Or the payment requires action, like customer authentication.
   *  Read more about payments that require action. Subscriptions can also be incomplete
   * if there’s a pending payment. In that case, the invoice status would be
   * open_payment_pending and the PaymentIntent status would be processing.
   */
  incomplete: 'incomplete',
  /**
   * @property {string} incomplete_expired - The initial payment on the subscription failed
   * and no successful payment was made within 23 hours of creating the subscription.
   * These subscriptions don’t bill customers. This status exists so you can track customers
   *  that failed to activate their subscriptions.
   */
  incomplete_expired: 'incomplete_expired',
  /**
   * @property {string} past_due - Payment on the latest finalized invoice either failed or
   *  wasn’t attempted. The subscription continues to create invoices. Your subscription
   * settings determine the subscription’s next state. If the invoice is still unpaid
   * after all Smart Retries have been attempted, you can configure the subscription
   * to move to canceled, unpaid, or leave it as past_due. To move the subscription
   * to active, pay the most recent invoice before its due date.
   */
  past_due: 'past_due',
  /**
   * @property {string} canceled - The subscription has been canceled. During cancellation,
   * automatic collection for all unpaid invoices is disabled (auto_advance=false).
   * This is a terminal state that can’t be updated.
   */
  canceled: 'canceled',
  /**
   * @property {string} unpaid - The latest invoice hasn’t been paid but the subscription
   * remains in place. The latest invoice remains open and invoices continue to be
   * generated but payments aren’t attempted. You should revoke access to your product
   *  when the subscription is unpaid since payments were already attempted and retried
   * when it was past_due. To move the subscription to active, pay the most recent
   * invoice before its due date.
   */
  unpaid: 'unpaid',
} as const;

export const StripeConstants = {
  isTest: false,
  PRICES: {
    ONE_DOLLAR_PER_DAY: 'price_1Mj3ONAwnRUlcRSmAOuPOtWk',
    ONE_DOLLAR_FOR_TRIAL_EXTENSION: 'price_1N97ndAwnRUlcRSm0WQvpHXR',
    SEVEN_DOLLAR_PER_MONTH: 'price_1Mj3NoAwnRUlcRSmcXDQBnDZ',
    SEVEN_DOLLAR_PER_MONTH_INCLUSIVE_TAX: 'price_1Mm9yOAwnRUlcRSm9VePdqoM',
  },
  TRIAL_LENGTH: 14,
  URLS: {
    SUCCESS_URL: '/successfull_payment?isSuccessfull=true',
    CANCEL_URL: '/cancel_payment?isSuccessfull=false',
    RETURN_URL: '/',
  },
  TEST: {
    // HARALD_CUSTOMER_ID: 'cus_NrUzCuI4uDub9z',
    HARALD_CUSTOMER_ID: 'cus_Nsfz2sVFxHAFiq',
    PRICES: {
      ONE_DOLLAR_PER_DAY: 'price_1Mg6SzAwnRUlcRSm0fxzc0F1',
      SEVEN_DOLLAR_PER_DAY: 'price_1MM4FVAwnRUlcRSmhAEqjpY7',
      SEVEN_DOLLAR_PER_MONTH: 'price_1MC0uRAwnRUlcRSmh3om1fIT',
      SEVENTY_DOLLAR_PER_YEAR: 'price_1MC0uRAwnRUlcRSm3OofbT7U',
      ONE_DOLLAR_FOR_TRIAL_EXTENSION: 'price_1N5kajAwnRUlcRSmQampmyzy',
      ONE_CENT_FOR_TRIAL_EXTENSION: 'price_1N10uSAwnRUlcRSmgj6HY4xn',
    },
    TEST_PUBLIC_KEY: 'pk_test_51M6pHzAwnRUlcRSmIo9LAejqZcv5tOIxltWnMKnu7qMpzH50fz7TqFKWzopDjd3TkCcatQBPWWt2prGjZmOr3xoW00GmDVS76v',
  },
  PUCLIC_KEY: 'pk_live_51M6pHzAwnRUlcRSmYZQF3FQB7a9EloLC2mj9APfTw4orjRFUO0BN3l1kKyGHChaDQxMVFBdOp6uRhiv11CebCYxO00wiivwsTp',
  CHECKOUT_ORIGINS: STRIPE_CHECKOUT_ORIGINS,
  PAYWALL_ORIGINS,
  ORIGIN_URL_PARAM_PATH: 'origin',
  FREE_PLAN: {
    MAX_MEETINGS: 10,
    /** On the free plan, the user should only be able to search for meetings up
     * to 5 days in the future
     */
    MAX_FUTURE_SEARCH_DAYS: 5,
    /**
     * On the free plan, the user should not be able to search for meetings
     * more than 5 days in the past
     */
    MAX_PAST_SEARCH_DAYS: 5,
  },
  SubscriptionStatuses,
  LOGGING: {
    TRIAL_START_ID: 'trial_start',
    TRIAL_END_ID: 'trial_end',
  },
  /** Some users should be on premium, even if they have not payed,
   * since their company have payed for them
   *
   * Some of these users are documented in Notion:
   * https://www.notion.so/meetshepherd/Premium-Members-on-Shepherd-04ebb2e512bb43198dddf8541309e818?pvs=4
   *
   * OBS: Also update this list in BE
   * */
  PREMIUM_USER_IDS: [
    'moWGzwSb8lUq3ISkjdqEET13h342', // Rhys Lindsay-White
    'cltlTQBUsFNleGJPvaJngEE6JMu2', // Tafadzwa Muzavazi
    'TpJvNMxV8VbTdFIrEps841DmqZK2', // Travor Shava
    'q7pWTjtruAgLI2psNZeGNzyAwpJ3', // Tatenda Nhira
    'V7ZApiuTNXhNXuyf6apbCK0OUi73', // Kudzai Shamba
    'm1SP3l0c70QxlqqJYKh82dh0tvx2', // Louis Phillips
    'ugFgtxTnL7WAuykBhn9t7inReRL2', // Tanille Kafesu
    'xkZqJDhzBbeJQLOLv5bSqS4FGNr2', // Joseph - Private
    'rnc2vbiJEsaTXdpniJoZjUBQgA82', // Joseph - Meetshepherd
    '0qmcgfbNb5MMcGyvCIykxO4mjqf2', // Olivia Karoline Fløe Lyng - Antler
    'R6W6BLiTE7a9noZZpGqAzwrCi2h1', // Ege Akinci - Antler
    '3Ahj08lDgZcLG0PO33a4SgYWVTj1', // Jose Almanza - Antler
    'g2fZq8CMhEMIBu0VqxFuE9a7rR83', // Matthias Breil - Antler
    'uiODB9ZlE7WKyCPSUwwOPJAM6tv1', // Nils Lange - Antler
    '59Puvj7FIYMW4mVDeCuROO6EAoF2', // Julia Nilsson - julia@antler.co
    'brh9TBHa1hfz2WVwxTMPtwrYIwn2', // Markus Erik Lukk - markus.lukk@antler.co
    'kzfjToBPoMSXbsg2ZUsPcnL66Ap2', // Hedvig Henrekson - hedvig.henrekson@antler.co
    '9WENCobCyZQIsSebopiC0YumTkB2', // Clara Reich Zackrisson - clara.reich@antler.co
    'yoED2wGqT5UVdTWudx2WIA5rvnp1', // Tage Ringstad - tage.ringstad@antler.co
    '8bPjKwKNCBdwiaHMp0WrYtDxaf83', // Leo Philippsthal - leo.philippsthal@antler.co
    'H7lLHcc9NmMHZgqn4sYhIOA5UoE3', // Kim Hansert - kim
    // 'LbFmRzo6niXcFms4XmHVMtzCeNN2',
  ],
} as const;
