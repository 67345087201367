import React from 'react';
import { surface } from '../colors/COLORS';

interface Props {
  fill?: string,
}

const Chat18x18 = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.04347 1.50006C6.40976 1.48948 3.96416 2.85514 2.59545 5.09996C1.22656 7.34507 1.13466 10.1419 2.35324 12.4717L2.49111 12.7405C2.54381 12.8393 2.55398 12.9459 2.52344 13.0444C2.32653 13.5647 2.15352 14.1267 2.01547 14.6976L2.00082 14.8206C2.00082 15.3946 2.30712 15.7891 2.91503 15.7756L3.01626 15.7634C3.56848 15.6415 4.11233 15.4844 4.64445 15.293C4.71499 15.2752 4.83052 15.2821 4.93478 15.3248L5.44668 15.6145C5.44783 15.618 5.4487 15.6207 5.45406 15.6238L5.48841 15.6359C8.24471 17.0852 11.6109 16.6853 13.9498 14.6308C16.289 12.576 17.115 9.29247 16.0257 6.37778C14.9365 3.4634 12.1586 1.52295 9.04347 1.50006ZM8.82561 2.54917L9.03627 2.54587C11.7173 2.56626 14.1081 4.23636 15.0454 6.74416C15.9825 9.25168 15.2719 12.0765 13.2591 13.8446L13.094 13.9848C11.147 15.5834 8.44366 15.9056 6.17452 14.81L5.95872 14.7018L5.96797 14.7031L5.95432 14.6993L5.63758 14.5176C5.52353 14.4531 5.43306 14.4043 5.36014 14.3692C5.01277 14.226 4.66016 14.2049 4.3285 14.2962L4.01454 14.404C3.80704 14.4726 3.60279 14.5345 3.39955 14.5905L3.10325 14.6679L3.03267 14.9436C3.16064 14.4143 3.32103 13.8934 3.51292 13.3837C3.63639 12.9886 3.59864 12.5928 3.41816 12.2551L3.28247 11.9904C2.2322 9.98231 2.31126 7.57633 3.48897 5.64477C4.63003 3.77331 6.64121 2.61167 8.82561 2.54917ZM4.85595 9.00003C4.85595 8.52738 5.23939 8.14456 5.71197 8.14456C6.18455 8.14456 6.56799 8.52738 6.56799 9.00003C6.56799 9.47269 6.18455 9.85551 5.71197 9.85551C5.23939 9.85551 4.85595 9.47269 4.85595 9.00003ZM8.18361 9.00003C8.18361 8.52738 8.56705 8.14456 9.03963 8.14456C9.51221 8.14456 9.89565 8.52738 9.89565 9.00003C9.89565 9.47269 9.51221 9.85551 9.03963 9.85551C8.56705 9.85551 8.18361 9.47269 8.18361 9.00003ZM12.3673 8.14456C11.8947 8.14456 11.5113 8.52738 11.5113 9.00003C11.5113 9.47269 11.8947 9.85551 12.3673 9.85551C12.8399 9.85551 13.2233 9.47269 13.2233 9.00003C13.2233 8.52738 12.8399 8.14456 12.3673 8.14456Z" fill={fill} />
  </svg>
);

Chat18x18.defaultProps = {
  fill: surface,
};

export default Chat18x18;
