/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { Node, Schema } from 'prosemirror-model';
import {
  PluginSpec, Plugin, NodeSelection, EditorState,
} from 'prosemirror-state';
import {
  findDomRefAtPos,
  findParentNodeOfType,
  findParentNodeOfTypeClosestToPos,
  findPositionOfNodeBefore,
} from '@meetshepherd/prosemirror-utils';
import { EditorView } from 'prosemirror-view';
import mentionsAdapter from '../suggestions/mentions-adapter';

function isMention(node?: Node | null): boolean {
  return node?.type.name === 'resolvedMention';
}

const isPreviousNodeAMention = (node?: Node | null): boolean => {
  if (node?.text?.startsWith('@') && !node?.text?.includes(' ')) return true;
  if (node?.type.name === 'resolvedMention') return true;

  return false;
};

function stillExists(view: EditorView, prevNode?: Node | null): boolean {
  return !!view.dom.querySelector(`.resolved-mention[uid="${prevNode?.attrs?.uid}"]`);
}

function exhaustPromotion(view: EditorView, prevState: EditorState, prevNode: Node) {
  const { tr } = view.state;
  const resolvedMentionType = (view.state.schema as Schema).nodes.resolvedMention;

  /**
   * The start position is fetched as follows:
   * 1. Find the position of the node before
   *    Since this doesn't exactly point to the
   *    position of the mention, we need to do
   *    some extra steps.
   * 2. We need to substract 1 to at least "hit"
   *    inside the mention.
   * *. This could introduce problems if empty ('') names
   *    are ever used.
   * 3. Transform this position into a DOM node.
   *    This, almost always, will be the resolved
   *    but unpromoted mention. This mention still
   *    hasn't received its promotion chance.
   * 4. Find the state pos of the DOM node. This points
   *    right at the *inclusive* beginning of the mention.
   *    This means that we need to subtract 1 from the
   *    pos to get the correct position for substitution.
   */
  const startPos = view.posAtDOM(
    view.domAtPos(
      findPositionOfNodeBefore(
        prevState.selection,
      )! - 1,
    ).node,
    0,
  ) - 1;

  if (startPos < 0) return;

  const endPos = prevState.selection.$anchor.pos;

  /* Perform a replacement. This copy is identical, besides the promote-chance, which is now true. */
  tr.replaceWith(
    startPos,
    endPos,
    resolvedMentionType.create({
      ...prevNode!.attrs,
      'promote-chance': true,
    }, [view.state.schema.text(prevNode!.attrs.name || prevNode!.attrs.email)]),
  );
  view.dispatch(tr);
}

const mentionPromotion: PluginSpec = {
  // eslint-disable-next-line arrow-body-style
  view: () => {
    return {
      update: (view: any, prevState: any) => {
        const { state } = view;

        const node = state.selection.$anchor.nodeBefore;
        const prevNode = prevState.selection.$anchor.nodeBefore;

        /* If the current node is a mention */
        if (isMention(node) && !prevNode?.attrs['promote-chance']) {
          /* And the previous node is a mention */
          if (isPreviousNodeAMention(prevNode)) {
            /* But they aren't the same mention */
            if (!node!.eq(prevNode!) && stillExists(view, prevNode)) {
              mentionsAdapter.setShowPromotionMenu(false);
              try {
                exhaustPromotion(view, prevState, prevNode!);
              } catch (e) {
                console.error('Failed to exhaust promotion (1) !');
              }
            }
            mentionsAdapter.setShowPromotionMenu(true);
            /* Else, do nothing, since they're the same mention */
          }
          /* Else, do nothing, since we've just entered a new mention */
        /* If the previous node was a mention, exhaust the promotion chance */
        } else if (isMention(prevNode) && !prevNode?.attrs['promote-chance'] && stillExists(view, prevNode)) {
          mentionsAdapter.setShowPromotionMenu(false);
          try {
            exhaustPromotion(view, prevState, prevNode!);
          } catch (e) {
            console.error('Failed to exhaust promotion (2) !');
          }
        } else if (!stillExists(view, prevNode)) {
          mentionsAdapter.setShowPromotionMenu(false);
        }
      },
    };
  },
};

export default new Plugin(mentionPromotion);
